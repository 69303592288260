import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { TextArea, FormField } from 'components/theme/form'
import * as Yup from 'yup'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { Rfp } from './rfp.d'

import { sendRfpResponseEmail } from './sendRfpResponseEmail'
import Modal from 'components/Modal'
import { Button } from 'components/theme'
import RfpPreview from 'views/lawfirms/Rfp/RfpForms/RfpPreview'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'

type ResponseValues = {
  first: string
  last: string
  email: string
  organization: string
  id: number
  response: string
}

const submitRfpResponse = async (
  { first, last, email, organization, id, response }: ResponseValues,
  rfp: Rfp,
  toggleModal: () => void
) => {
  try {
    await sendRfpResponseEmail(first, last, email, organization, id, response, rfp)
    toggleModal()
    toast.success('Request Sent!')
  } catch (error) {
    toast.error('Something went wrong, please try again')
  }
}

const ResponseForm: React.FC<{
  rfp: Rfp
  toggleModal: () => void
}> = ({ rfp, toggleModal }) => {
  const { viewer } = useViewer()
  const { organization } = useOrg()
  const { id, first, last, email } = viewer
  return (
    <Formik
      onSubmit={(values) => submitRfpResponse(values, rfp, toggleModal)}
      initialValues={{
        response: '',
        id: id || 0,
        first,
        last,
        email,
        organization: organization?.name || 'not provided',
      }}
      validationSchema={Yup.object().shape({ response: Yup.string().required('Required!') })}
    >
      <Form>
        <FormField>
          <TextArea rows={23} placeholder="Craft your response here..." name="response" />
        </FormField>
        <Submit type="submit" color="#9013FE">
          Send
        </Submit>
      </Form>
    </Formik>
  )
}

const MessageModal: React.FC<{
  isShowing: boolean
  toggleModal: () => void
  rfp: Rfp
}> = ({ isShowing, toggleModal, rfp }) => {
  const [responseFormIsOpen, setResponseFormIsOpen] = useState<boolean>(false)
  return (
    <Modal
      isShowing={isShowing}
      toggleModal={toggleModal}
      width={responseFormIsOpen ? '1000px' : '500px'}
    >
      <Container wide={responseFormIsOpen}>
        <Left>
          <RfpPreview {...rfp} />
          {!responseFormIsOpen && (
            <Button color="#9013FE" onClick={() => setResponseFormIsOpen(true)}>
              Respond to Rfp
            </Button>
          )}
          {responseFormIsOpen && (
            <Cancel
              onClick={() => {
                setResponseFormIsOpen(false)
                toggleModal()
              }}
            >
              cancel
            </Cancel>
          )}
        </Left>
        {responseFormIsOpen && (
          <Right>
            <h2>Re: {rfp.title || 'Request for Proposal'}</h2>
            <ResponseForm rfp={rfp} toggleModal={toggleModal} />
          </Right>
        )}
      </Container>
    </Modal>
  )
}

const Container = styled.div<{ wide: boolean }>`
  display: flex;
  ${({ wide }) =>
    wide
      ? `
      min-width: 400px; 
    `
      : ``}
`

const Cancel = styled(Button)`
  /* width: min-content; */
`

const Submit = styled(Button)`
  margin-top: 12px;
`

const Left = styled.div`
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Right = styled.div`
  max-width: 450px;
  width: 100%;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: space-between;
  height: 100%;
`

export default MessageModal
