import { Company } from './types/companyType'
import { parseTerms } from 'data/taxonomy/parseTerms'
import { GqlCurrentOrgFragment, OrganizationType } from 'generated/graphql'
import { parseMembers } from './parseMembers'

export const parseCompany = (orgData: GqlCurrentOrgFragment): Company => {
  const {
    id,
    name,
    slug,
    createdAt,
    updatedAt,
    locations,
    details,
    useCases,
    testMode,
    termToOrganizations,
    usersToOrganizations,
    appMetadata,
  } = orgData

  return {
    id,
    name,
    slug,
    type: OrganizationType.Company,
    createdAt,
    updatedAt,
    locations,
    details,
    useCases,
    testMode,
    terms: parseTerms(termToOrganizations),
    members: parseMembers(usersToOrganizations),
    appMetadata,
  }
}
