import { useQuery } from '@tanstack/react-query'
import { useOrg } from 'data/hooks'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { GqlGetListingsQuery } from 'generated/graphql'

export const useListings = () => {
  const request = useGql()
  const { organization } = useOrg()
  const { data, ...other } = useQuery(['listings', organization?.id], async () => {
    if (!organization?.id) return { productListings: [], organizationListings: [] }

    const response = await request<GqlGetListingsQuery>(GET_LISTINGS, {
      organizationId: organization?.id,
    })

    if (response) {
      const { productListings, organizationListings } = response

      return { productListings, organizationListings }
    }

    return { productListings: [], organizationListings: [] }
  })

  return {
    ...other,
    productListings: data?.productListings,
    organizationListings: data?.organizationListings,
  }
}

const GET_LISTINGS = gql`
  query GetListings($organizationId: UUID) {
    productListings(condition: { organizationId: $organizationId }) {
      appMetadata
      association {
        details
        id
        name
        slug
      }
      id
      type
      state
      show
      product {
        id
        name
        details
        type
        slug
      }
    }
    organizationListings(condition: { organizationId: $organizationId }) {
      type
      status
      association {
        id
        name
        slug
      }
      show
    }
  }
`
