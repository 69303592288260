import React from 'react'
import styled from '@emotion/styled'
import { useAllTerms } from 'data/hooks'

type Product = {
  id: number
  name: string
}

type Props = {
  step?: number
  title: string
  companyDescription: string
  categories: string
  products: Product[]
  seatsRequested: string
  practiceGroups: string[]
  budget: string
  responses: string
  requiredProvisions: string[]
  notes: string
  deployments: string[]
  endDate: string
  startDate: string
  location: string
}

const RfpPreview: React.FC<Props> = ({
  step = 5,
  title,
  requiredProvisions,
  categories,
  responses,
  budget,
  notes,
  companyDescription,
  seatsRequested,
  practiceGroups,
  products,
  deployments,
  endDate,
  startDate,
  location,
}) => {
  const { terms, isLoading } = useAllTerms()
  const wantToHearFrom = () => {
    const mapTools = () => {
      return (
        <ul>
          {products.map((product: Product, i: number) => (
            <li key={`list-${product.name}-${i}`}>{product.name}</li>
          ))}
        </ul>
      )
    }

    if (step === 3 && products.length === 0) {
      return (
        <>
          <LineTitle>Products they'd like to hear from:</LineTitle>
          <p>
            <ul>
              <li>
                <Placeholder>[ these product(s)... ]</Placeholder>
              </li>
            </ul>
          </p>
        </>
      )
    } else if (products.length > 0) {
      return (
        <>
          <LineTitle>Products they'd like to hear from:</LineTitle>
          <p>{mapTools()}</p>
        </>
      )
    }
  }

  const provisions = () => {
    const mapProvisions = () =>
      requiredProvisions.map((provision: string, i: number) => (
        <li key={`provision-${provision}-${i}`}>
          {provision || <Placeholder>{`[ provision ${i + 1} ]`}</Placeholder>}
        </li>
      ))

    if (step === 3 && requiredProvisions.length === 0) {
      return (
        <>
          <LineTitle>Provisions / Requests</LineTitle>
          <p>
            <ul>
              <li>
                <Placeholder>[ provisions ]</Placeholder>
              </li>
            </ul>
          </p>
        </>
      )
    } else if (requiredProvisions.length > 0) {
      return (
        <>
          <LineTitle>Provisions / Requests</LineTitle>
          <p>
            <ul>{mapProvisions()}</ul>
          </p>
        </>
      )
    }
  }

  const printPracticeGroups = () => {
    if (isLoading) return
    const groups = practiceGroups.reduce((groups: string[], group: any) => {
      if (isNaN(group) && typeof group === 'string') groups.push(group)
      if (!isNaN(group) && terms) {
        const foundTerm = terms.find((term: any) => term.id === parseInt(group, 10))
        groups.push(foundTerm?.name || '')
      }
      return groups
    }, [])
    if (groups.length === 1) {
      return `for its ${groups[0]} practice group`
    } else if (groups.length > 0) {
      const oxfordize = groups.splice(groups.length - 2, 2).join(' and ')
      return ` its ${[...groups, oxfordize].join(', ')} practice group${
        practiceGroups.length > 1 && 's'
      }.`
    } else {
      return ''
    }
  }

  const a_or_an = (word: string, lowercase?: boolean) => {
    const vowels = ['A', 'E', 'I', 'O', 'U']
    const firstLetter = word[0] ? word[0].toUpperCase() : 'x'
    if (lowercase) return vowels.includes(firstLetter) ? 'an' : 'a'
    return vowels.includes(firstLetter) ? 'An' : 'A'
  }

  const printDate = (date: string) => {
    const YyyyMmDd = date.split('-')
    YyyyMmDd.push(YyyyMmDd[0])
    YyyyMmDd.shift()
    return YyyyMmDd.join('/')
  }

  const parseCategories = () => {
    if (isNaN(parseInt(categories)) && typeof categories === 'string') {
      return categories
    } else {
      if (!terms) return ''
      const foundTerm = terms.find((category: any) => category.id === parseInt(categories))
      return foundTerm?.name || ''
    }
  }

  return (
    <div>
      <h2>{title ? title : step === 0 && <Placeholder>[ Title ]</Placeholder>}</h2>
      <p>
        {a_or_an(companyDescription)}{' '}
        {companyDescription ? (
          companyDescription
        ) : step === 0 ? (
          <Placeholder>[ Company Description ]</Placeholder>
        ) : (
          ` company `
        )}{' '}
        is looking for{step === 0 && !categories && '...'}
        {(step >= 1 || categories) && (
          <>
            {categories ? (
              <>{` ${parseCategories()} tools`}&nbsp;</>
            ) : step > 1 ? (
              ` tools `
            ) : (
              <>
                <Placeholder> [ type of product ]</Placeholder> tools&nbsp;
              </>
            )}
          </>
        )}
        {(step > 1 || practiceGroups) && practiceGroups.length > 0 ? (
          `${printPracticeGroups()}`
        ) : step === 3 ? (
          <>
            for its <Placeholder>[ practice group(s) ]</Placeholder> practice group.
          </>
        ) : (
          ``
        )}
      </p>
      {step > 0 && <hr />}
      {(seatsRequested || step === 1) && (
        <OneLine>
          <LineTitle>Total Seats:</LineTitle>&nbsp;
          <p>{seatsRequested || (step === 1 && <Placeholder>[ total ]</Placeholder>)}</p>
        </OneLine>
      )}
      {(budget || step === 1) && (
        <OneLine>
          <LineTitle>Budget:</LineTitle>&nbsp;
          <p>{budget || (step === 1 && <Placeholder>[ budget ]</Placeholder>)}</p>
        </OneLine>
      )}
      {(responses || step === 1) && (
        <OneLine>
          <LineTitle>Maximum # of responses:</LineTitle>&nbsp;
          <p>{responses || (step === 1 && <Placeholder>[ responses ]</Placeholder>)}</p>
        </OneLine>
      )}
      {(startDate || endDate || step === 2) && (
        <OneLine>
          <LineTitle>Timeline:</LineTitle>&nbsp;
          <p>
            {printDate(startDate) || (step === 2 ? <Placeholder>[ start ]</Placeholder> : 'TBD')} -{' '}
            {printDate(endDate) || (step === 2 ? <Placeholder>[ end ]</Placeholder> : 'TBD')}
          </p>
        </OneLine>
      )}
      {(location || step === 2) && (
        <OneLine>
          <LineTitle>Location(s):</LineTitle>&nbsp;
          <p>{location || (step === 2 && <Placeholder>[ location(s) ]</Placeholder>)}</p>
          {/* <p>{location || (step === 2 && <Placeholder>[ location ]</Placeholder>)}</p> */}
        </OneLine>
      )}
      {step > 1 && (location || startDate || endDate) && <hr />}
      {(notes || step === 3) && (
        <OneLine>
          <LineTitle>Use Case/Notes:</LineTitle>&nbsp;
          <p>{notes || (step === 3 && <Placeholder>[ notes ]</Placeholder>)}</p>
        </OneLine>
      )}
      {(deployments.length > 0 || step === 3) && (
        <OneLine>
          <LineTitle>Desired Deployment(s):</LineTitle>&nbsp;
          <p>
            {deployments.length > 0
              ? deployments.join(', ')
              : step === 3 && <Placeholder>[ deployment(s) ]</Placeholder>}
          </p>
        </OneLine>
      )}
      {wantToHearFrom()}
      {provisions()}
    </div>
  )
}

const LineTitle = styled.h4`
  margin: 0;
`

const Placeholder = styled.i`
  word-wrap: none;
`

const OneLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
`

export default RfpPreview
