import defaultMenuItems, { servicesRoutes } from './productSettingPages'
import styled from '@emotion/styled'
import { CollapsingTabs } from '@theoremlts/theorem-design'
import { useViewer } from 'context/viewerContext'
import { useProductSettings } from './useProductSettings'

type MenuItem = {
  text: string
  url: string
  exact?: boolean
  paidUser?: boolean
}

type ParsedMenuItem = {
  text: string
  url: string
  exact?: boolean
}

function ProductMenu() {
  const { paymentStatus } = useViewer()
  const { isService } = useProductSettings()

  const routes = isService ? servicesRoutes : defaultMenuItems

  const menuItems = routes
    .map(({ text, url, exact, paidUser }: MenuItem) => {
      if (paidUser && paymentStatus === 'NOT_ACTIVE') return

      return {
        text,
        exact,
        url,
        // url: `${matchedRoute}${url ? `/${url}` : ''}`,
        // url: `${path}/product/settings/${productId}`,
      }
    })
    .filter((menuItem) => !!menuItem)

  return (
    <ShrinkWrapper>
      <CollapsingTabs menuItems={menuItems as ParsedMenuItem[]} />
    </ShrinkWrapper>
  )
}

const ShrinkWrapper = styled.div`
  position: relative;
  transition: transform 500ms, opacity 500ms, margin-bottom 500ms;
  z-index: 1;
`

export default ProductMenu
