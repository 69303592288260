import { useState } from 'react'
import styled from '@emotion/styled'
import { useWizardModal } from 'components/modals/WizardModal'
import { useOrg, useProductListings } from 'data/hooks'
import { Product } from 'data/types'
import { ProductType } from 'generated/graphql'
import SelectProduct from './SelectProduct'
import CreateProduct from './CreateProduct'

const ProductFlow = () => {
  const { data } = useWizardModal()
  const { productListings } = useProductListings()
  const { vendor, lawfirm } = useOrg()
  const [isCreateProduct, setIsCreateProduct] = useState(false)

  const products = vendor ? vendor.products : lawfirm?.products

  const options: Product[] | undefined = products?.filter(
    ({ id }) =>
      !productListings?.some(
        ({ product, association }) => product.id === id && association.slug === data.slug
      )
  )

  const hasProduct = options?.some(({ type }) => ProductType.Software === type)

  // if has product then select product otherwise then create product
  const clickCreateProduct = () => {
    setIsCreateProduct(true)
  }

  return (
    <Root>
      {hasProduct && !isCreateProduct ? (
        <SelectProduct setCreateProduct={clickCreateProduct} />
      ) : (
        <CreateProduct />
      )}
    </Root>
  )
}

const Root = styled.div``

export default ProductFlow
