const API_BASE = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://m0qbfdtry8.execute-api.us-west-2.amazonaws.com/dev'
// localhost:3001
import { Rfp } from './rfp.d'

export const sendRfpResponseEmail = (
  first: string,
  last: string,
  email: string,
  organization: string,
  id: number,
  response: string,
  rfp: Rfp
) => {
  const data = {
    vendorFirst: first,
    vendorLast: last,
    vendorEmail: email,
    companyName: organization,
    vendorId: id,
    rfpId: rfp.id,
    rfpResponse: response,
    rfpTitle: rfp.title,
  }
  return fetch(`${API_BASE}/share-ecosystem/rfp-response`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}