import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface Variables {
  productId: number
  details: any
}

export const useMutateProductDetails = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { productId, details } = variables

      await request(UPDATE_VENDOR_PRODUCT_DETAILS, {
        id: productId,
        details,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

export const UPDATE_VENDOR_PRODUCT_DETAILS = gql`
  mutation UpdateVendorProductDetails($id: Int!, $details: JSON) {
    updateProduct(input: { patch: { details: $details }, id: $id }) {
      clientMutationId
    }
  }
`
