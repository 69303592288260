import React, { useState, useEffect } from 'react'
import { TechstackProduct } from './types'
import { useAllTeam, useTechstack, useTechstackFilter } from 'data/hooks'
import { MemberStatus } from 'generated/graphql'

type Option = {
  text: string
  value?: string | number
  key: string | number
}

type InitialState = {
  loading: boolean
  techstack: TechstackProduct[]
  onSearch: (searchValue: string) => void
  onSelectUser: (selectedUser: number) => void
  onChangeLocation: (selectedLocation: string) => void
  onSelectPracticeGroup: (selectedPracticeGroup: number) => void
  onSelectRoleGroup: (selectedRoleGroup: number) => void
  orgUserOptions: Option[]
  practiceGroupOptions: Option[]
  rolesGroupOptions: Option[]
}

const FirmAppsContext = React.createContext({} as InitialState)

export const FirmAppsProvider = ({ children }: { children: React.ReactNode }) => {
  const [, setSelectedLocation] = useState<string>('')
  const [selectedPracticeGroup, setSelectedPracticeGroup] = useState<number | undefined>()
  const [selectedRoleGroup, setSelectedRoleGroup] = useState<number | undefined>()
  const [techstack, setTechstack] = useState<TechstackProduct[]>([])
  const [search, setSearch] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<number | undefined>()
  const { techstack: techstackTermsData, isLoading: techstackTermsLoading } = useTechstack()
  const { techstack: techstackData } = useTechstackFilter(selectedUser ? [selectedUser] : undefined)
  const { team, isLoading: teamLoading } = useAllTeam({ status: MemberStatus.Active })
  const [orgUserOptions, setOrgUserOptions] = useState<Option[]>([])
  const [practiceGroupOptions, setPracticeGroupOptions] = useState<Option[]>([])
  const [rolesGroupOptions, setRolesOptions] = useState<Option[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!techstackTermsData || techstackTermsLoading) return
    const practiceGroups = techstackTermsData.flatMap(({ product }) =>
      product?.termToProducts
        .filter(({ term }) => term?.taxonomyId === 13)
        .map(({ term }) => ({
          value: term?.id,
          text: term?.name,
          key: `${term?.id}-${term?.taxonomyId}`,
        }))
    )

    const roles = techstackTermsData.flatMap(({ product }) =>
      product?.termToProducts
        .filter(({ term }) => term?.taxonomyId === 8)
        .map(({ term }) => ({
          value: term?.id,
          text: term?.name,
          key: `${term?.id}-${term?.taxonomyId}`,
        }))
    )

    const uniquePracticeGroups = practiceGroups.reduce(
      (acc: any, cur: any) => [...acc.filter((obj: any) => obj.value !== cur.value), cur],
      []
    )
    const uniqueRoles = roles.reduce(
      (acc: any, cur: any) => [...acc.filter((obj: any) => obj.value !== cur.value), cur],
      []
    )

    setPracticeGroupOptions([
      ...uniquePracticeGroups,
      { text: 'Clear Practice Groups', value: null },
    ])

    setRolesOptions([...uniqueRoles, { text: 'Clear Roles', value: null }])
  }, [techstackTermsLoading, JSON.stringify(techstackTermsData)])

  useEffect(() => {
    if (teamLoading || !team) return

    const teamOptions = team.map(({ name, userId }) => ({
      text: name,
      value: userId,
      key: `${name}-${userId}`,
    }))
    setOrgUserOptions([
      ...teamOptions,
      { text: 'Clear Users', value: undefined, key: 'clear-users' },
    ])
  }, [teamLoading, JSON.stringify(team)])

  useEffect(() => {
    if (!techstackData || teamLoading || !team || !techstackData) return

    const techstacksData = techstackData?.map(
      ({ id, product, addedBy, access, usersToTechstacks, metadata }: any) => {
        let user
        if (!addedBy) {
          const matchedUser = team?.find(({ role }) => role === 'OWNER')
          user = matchedUser
        } else {
          const matchedUser = team?.find(({ userId }) => userId === addedBy)
          user = matchedUser
        }
        return { ...product, techstackId: id, user, access, usersToTechstacks, metadata }
      }
    )

    const practiceGroupFilteredTechstack = selectedPracticeGroup
      ? techstacksData.filter((techstack: any) =>
          techstack.termToProducts.some(({ termId }: any) => termId === selectedPracticeGroup)
        )
      : techstacksData

    const rolesFilteredTechstack = selectedRoleGroup
      ? practiceGroupFilteredTechstack.filter((techstack: any) =>
          techstack.termToProducts.some(({ termId }: any) => termId === selectedRoleGroup)
        )
      : practiceGroupFilteredTechstack

    if (search.length > 0) {
      const query = search.toLowerCase()
      const filteredTechstack = rolesFilteredTechstack.filter(
        (item: any) => item.name.toLowerCase().indexOf(query) >= 0
      )
      setTechstack(filteredTechstack)
    } else {
      setTechstack(rolesFilteredTechstack)
    }

    setLoading(false)
  }, [
    search,
    JSON.stringify(techstackData),
    JSON.stringify(team),
    teamLoading,
    selectedPracticeGroup,
  ])

  const onSearch = (searchValue: string) => setSearch(searchValue)
  const onSelectUser = (selectedUser: number) => setSelectedUser(selectedUser)
  const onChangeLocation = (selectedLocation: string) => setSelectedLocation(selectedLocation)
  const onSelectPracticeGroup = (selectedPracticeGroup: number) =>
    setSelectedPracticeGroup(selectedPracticeGroup)

  const onSelectRoleGroup = (selectedRole: number) => setSelectedRoleGroup(selectedRole)

  return (
    <FirmAppsContext.Provider
      value={{
        loading,
        techstack,
        onSearch,
        onSelectUser,
        onChangeLocation,
        onSelectPracticeGroup,
        onSelectRoleGroup,
        orgUserOptions,
        practiceGroupOptions,
        rolesGroupOptions,
      }}
    >
      {children}
    </FirmAppsContext.Provider>
  )
}

export const useFirmApps = () => React.useContext(FirmAppsContext)
