import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { TermToRfps, Rfp, RfpProductInfo } from './rfp.d'
import { parseISO, format } from 'date-fns'

import PulsatingLoader from 'components/PulsatingLoader'
import { BoxWrapper, Button } from 'components/theme'
import MessageModal from './MessageModal'
import UpgradeModal from './UpgradeModal'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { useAllRfps } from 'data/hooks'

const RfpInbox = () => {
  const [rfps, setRfps] = useState<Rfp[]>([])
  const [viewRfp, setViewRfp] = useState<number | undefined>(undefined)
  const { organization } = useOrg()
  const { isAdmin, paymentStatus, viewerLoading } = useViewer()
  const { rfps: rfpData, isLoading, isError } = useAllRfps()

  const parseTermsNames = (taxId: number, termData: TermToRfps) => {
    return termData.reduce((parsedTerms: string[], { term }: any) => {
      if (term.taxonomyId === taxId) parsedTerms.push(term.name)
      return parsedTerms
    }, [])
  }

  const parseRfps = (): Rfp[] => {
    return (
      rfpData?.map(({ details, termToRfps, rfpToProducts, createdAt, id, user }: any) => {
        const {
          title,
          requiredProvisions,
          responses,
          budget,
          notes,
          companyDescription,
          seatsRequested,
          deployments,
          endDate,
          startDate,
          location,
        } = details
        return {
          id,
          createdAt,
          title,
          requiredProvisions,
          responses,
          budget,
          notes,
          companyDescription,
          seatsRequested,
          deployments,
          endDate,
          startDate,
          location,
          author: user,
          practiceGroups: parseTermsNames(13, termToRfps),
          categories: parseTermsNames(14, termToRfps).join(', '),
          products: rfpToProducts.map(({ product }: any) => product),
        }
      }) || []
    )
  }

  const makeProductPills = (products: RfpProductInfo[]) => {
    return products.map((product) => {
      if (
        organization?.type === 'VENDOR' &&
        organization?.products.some(({ id }) => id === product.id)
      ) {
        return <SelectedPill>{product.name}</SelectedPill>
      } else {
        return <Pill>{product.name}</Pill>
      }
    })
  }

  const printRfpList = () => {
    return rfps.map(({ createdAt, title, id, budget, products }) => (
      <TableRow key={`rfp-${id}`}>
        <td>{title}</td>
        <td>{format(parseISO(createdAt), 'MMM dd, yyyy')}</td>
        <LongCell>{makeProductPills(products)}</LongCell>
        <td>{budget}</td>
        <td>
          <ViewButton onClick={() => setViewRfp(id)}>view</ViewButton>
        </td>
      </TableRow>
    ))
  }

  const sortRfps = (rfps: Rfp[]) => {
    if (organization?.type !== 'VENDOR') return rfps
    return rfps.sort((rfp1, rfp2) => {
      const rfp1Products = rfp1.products.map(({ id }) => id)
      const rfp2Products = rfp2.products.map(({ id }) => id)
      if (organization.products.some(({ id }) => rfp1Products.includes(id))) return -1
      if (organization.products.some(({ id }) => rfp2Products.includes(id))) return 1
      return 0
    })
  }

  useEffect(() => {
    if (isLoading || isError) return
    const parsedRfps = parseRfps()
    const sortedRfps = sortRfps(parsedRfps)
    setRfps(sortedRfps)
  }, [JSON.stringify(rfpData)])

  if (isLoading)
    return (
      <RfpBox>
        <PulsatingLoader />
      </RfpBox>
    )
  if (isError) return <RfpBox>Something went wrong, please try again</RfpBox>
  if (rfps.length === 0)
    return (
      <RfpBox>
        <h3>No Requests For Proposal have been sent</h3>
      </RfpBox>
    )
  if (isAdmin && paymentStatus !== 'ACTIVE' && !viewerLoading) return <UpgradeModal />
  return (
    <>
      <MessageModal
        isShowing={viewRfp ? true : false}
        toggleModal={() => viewRfp && setViewRfp(undefined)}
        rfp={{ ...(rfps.find((rfp) => rfp.id === viewRfp) || ({} as Rfp)) }}
      />
      <BoxWrapper>
        <table style={{ borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th>
                <TableTitle>Requests For Proposal</TableTitle>
              </th>
            </tr>
            <TableRow>
              <TableHead>Title</TableHead>
              <TableHead>Date Added</TableHead>
              {/* <TableHead>Start Date</TableHead>
              <TableHead>End Date</TableHead> */}
              <TableHead>Requested Contacts</TableHead>
              <TableHead>Budget</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </thead>
          <tbody>{printRfpList()}</tbody>
        </table>
      </BoxWrapper>
    </>
  )
}

const Pill = styled.div`
  border: 1px solid #9013fe;
  margin: 2px;
  color: #9013fe;
  border-radius: 10px;
  font-size: 12px;
  padding: 2px 4px;
  white-space: nowrap;
  width: min-content;
`

const SelectedPill = styled(Pill)`
  background-color: #9013fe;
  color: white;
`

const maxWidth = css`
  max-width: 800px;
`

const nameStyles = css`
  flex: 0 0 40%;
`

const tableHeaderStyles = css`
  font-weight: 500;
`

const LongCell = styled.td`
  width: 230px;
  display: flex;
  flex-wrap: wrap;
`

const TableHead = styled.td`
  /* ${nameStyles} */
  ${tableHeaderStyles}
`

const RfpBox = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 30px;
  min-height: 206px;
  background: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
`

const TableTitle = styled.h3`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  ${maxWidth}
`

const TableRow = styled.tr`
  border-bottom: 1px solid #e8ecee;

  > td {
    padding: 12px;
  }
`

const ViewButton = styled(Button)`
  margin-left: 48px;
`

export default RfpInbox
