import { useViewer } from 'context/viewerContext'
import { UserStatus } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useSetUserStatus = (): {
  setUserStatus: (newUserStatus: UserStatus) => Promise<void>
} => {
  const { viewer } = useViewer()
  const request = useGql()
  const queryClient = useQueryClient()
  const mutateUserStatus = useMutation(
    async (newUserStatus: UserStatus) => {
      await request(SET_STATUS, { userId: viewer.id, status: newUserStatus })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['viewer']),
    }
  )

  const setUserStatus = async (newUserStatus: UserStatus): Promise<void> => {
    await mutateUserStatus.mutateAsync(newUserStatus)
  }

  return { setUserStatus }
}

export const SET_STATUS = gql`
  mutation SetUserStatus($userId: Int!, $status: UserStatus) {
    updateUser(input: { patch: { status: $status }, id: $userId }) {
      clientMutationId
    }
  }
`
