import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { OrganizationType, Product } from 'data/types'
import { useOrg } from 'data/organization/useOrg'
import { useViewer } from 'context/viewerContext'
import { useProduct } from 'data/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { ProductType } from 'generated/graphql'

// TODO CHANGE THIS ONE TO ORGANIZATION
export const useProductSettings = () => {
  const { paymentStatus, isSuperAdmin } = useViewer()
  const { organization, organizationLoading } = useOrg()
  const { productId: productIdParam } = useParams<{ productId: string }>()
  const [showModal, setShowModal] = useState(false)
  const productId = parseInt(productIdParam as string, 10)
  const { product, isLoading: isProductLoading } = useProduct(productId, isSuperAdmin)
  const queryClient = useQueryClient()

  const getOrgProduct = (productId: number) => {
    if (
      !organization ||
      !(
        organization.type === OrganizationType.Lawfirm ||
        organization.type === OrganizationType.Vendor
      )
    )
      return

    return organization.products.find((product) => product.id === productId)
  }

  const productSettings: Product | undefined = isSuperAdmin ? product : getOrgProduct(productId)

  const onComplete = () => {
    if (paymentStatus === 'NOT_ACTIVE') setShowModal(true)

    if (isSuperAdmin && product) queryClient.invalidateQueries(['product', product.id])

    toast.success('Product has been updated')
  }

  const toggleModal = () => {
    setShowModal((prevVal) => !prevVal)
  }

  return {
    productId,
    product: productSettings,
    isService: productSettings?.type === ProductType.Service,
    loading: organizationLoading || (isSuperAdmin && isProductLoading),
    onComplete,
    showModal,
    toggleModal,
  }
}
