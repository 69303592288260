import styled from '@emotion/styled'
import { useGetAssocToOrgs } from 'data/associationToOrganization/hooks'
import { LineItemTable } from '@theoremlts/theorem-design'
import NetworkRows from './NetworkRows'
import { FullPageLoading } from 'components/PulsatingLoader'

const NetworkOrgs = () => {
  const { data, isLoading } = useGetAssocToOrgs()

  if (isLoading) return <FullPageLoading />

  return (
    <Root>
      <LineItemTable
        headerItems={['Organization', 'Email Domain', 'Action']}
        lineItems={<NetworkRows organizations={data} />}
      />
    </Root>
  )
}

const Root = styled.div``

export default NetworkOrgs
