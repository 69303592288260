import { useQuery } from '@tanstack/react-query'
import { GqlSearchByProductIdsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useProductsByIds = (productIds: number[]) => {
  const request = useGql()
  const { data, ...other } = useQuery(['productsByIds'], async () => {
    const response = await request<GqlSearchByProductIdsQuery>(SEARCH_BY_PRODUCT_IDS, {
      productIds,
    })

    return response.productIds
  })

  return { products: data, ...other }
}

export const SEARCH_BY_PRODUCT_IDS = gql`
  query SearchByProductIds($productIds: [Int!]!) {
    productIds(ids: $productIds) {
      id
      name
      details
    }
  }
`
