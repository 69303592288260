import ImageBodyLink from './ImageBodyLink'
import image from 'assets/marketing/resume-folder-rafiki.png'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const CreateRfp = () => {
  const baseOrgUrl = useBaseOrgUrl()

  return (
    <ImageBodyLink
      image={image}
      title="Create your First RFP"
      Message="Let us bring technology to you - Streamline procurement and bundle demos as you vet solutions."
      routerLink={{
        to: `${baseOrgUrl}/rfp`,
        text: 'Request a Proposal',
      }}
    />
  )
}

export default CreateRfp
