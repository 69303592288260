// SHARED COMPONENTS
import EditTeam from 'components/settings/Team'
// ASSOCIATION COMPONENTS
import EditUser from 'views/associations/Settings/EditUser'
import EditAssociation from 'views/associations/Settings/EditAssociation'
import EditBranding from 'views/associations/Settings/EditBranding'
// LAWFIRM COMPONENTS
import ProfileUserForm from 'views/lawfirms/Profile/ProfileUserForm'
import ProfileAssociations from 'views/lawfirms/Profile/ProfileAssociations'
import ProfileLawfirmForm from 'views/lawfirms/Profile/ProfileLawfirmForm'
import ProfileLocationForm from 'views/lawfirms/Profile/ProfileLocationForm'
import ProfileBillingForm from 'views/lawfirms/Profile/ProfileBillingForm'
import ConnectDetails from 'views/lawfirms/Profile/ConnectDetails'
// VENDOR COMPONENTS
import EditVendorUser from 'components/vendors/EditVendorUser'
import EditVendor from 'components/vendors/EditVendor'
import EditProducts from 'components/vendors/EditProducts'
import EditLocations from 'components/settings/Locations'
import DiversityCert from 'components/settings/DiversityCert'
import CompanySettingsForm from 'views/company/settings/CompanySettingsForm'

export type MenuRoute = {
  url: string
  text: string
  element: any
  roleAccess?: string[]
  exact?: boolean
}

type Routes = {
  VENDOR: MenuRoute[]
  LAWFIRM: MenuRoute[]
  COMPANY: MenuRoute[]
  ASSOCIATION: MenuRoute[]
  PERSONAL: MenuRoute[]
  OTHER: MenuRoute[]
}

const routes = (url: string): Routes => ({
  VENDOR: [
    {
      url: `${url}/settings`,
      text: 'User',
      element: <EditVendorUser />,
      exact: true,
    },
    {
      url: `${url}/settings/company`,
      text: 'Company',
      element: <EditVendor />,
    },
    {
      url: `${url}/settings/team`,
      text: 'Team',
      element: <EditTeam />,
    },
    {
      url: `${url}/settings/product`,
      text: 'Products',
      element: <EditProducts />,
    },
    {
      url: `${url}/settings/location`,
      text: 'Locations',
      element: <EditLocations />,
    },
    {
      url: `${url}/settings/certifications`,
      text: 'Diversity Certifications',
      element: <DiversityCert />,
    },
  ],
  LAWFIRM: [
    {
      url: `${url}/settings`,
      text: 'User',
      element: <ProfileUserForm />,
    },
    {
      url: `${url}/settings/company`,
      text: 'Company',
      element: <ProfileLawfirmForm />,
      roleAccess: ['OWNER', 'ADMIN', 'SUPER_ADMIN'],
    },
    {
      url: `${url}/settings/location`,
      text: 'Location',
      element: <ProfileLocationForm />,
      roleAccess: ['OWNER', 'SUPER_ADMIN'],
    },
    {
      url: `${url}/settings/billing`,
      text: 'Billing',
      element: <ProfileBillingForm />,
      roleAccess: ['OWNER', 'SUPER_ADMIN'],
    },
    {
      url: `${url}/settings/team`,
      text: 'Team',
      element: <EditTeam />,
    },
    {
      url: `${url}/settings/associations`,
      text: 'Associations',
      element: <ProfileAssociations />,
    },
    {
      url: `${url}/settings/connect`,
      text: 'Connect',
      element: <ConnectDetails />,
      roleAccess: ['OWNER', 'SUPER_ADMIN'],
    },
  ],
  COMPANY: [
    {
      url: `${url}/settings`,
      text: 'User',
      element: <ProfileUserForm />,
    },
    {
      url: `${url}/settings/company`,
      text: 'Company',
      element: <CompanySettingsForm />,
      roleAccess: ['OWNER', 'SUPER_ADMIN'],
    },
    {
      url: `${url}/settings/location`,
      text: 'Location',
      element: <ProfileLocationForm />,
      roleAccess: ['OWNER', 'SUPER_ADMIN'],
    },
    {
      url: `${url}/settings/billing`,
      text: 'Billing',
      element: <ProfileBillingForm />,
      roleAccess: ['OWNER', 'SUPER_ADMIN'],
    },
    {
      url: `${url}/settings/team`,
      text: 'Team',
      element: <EditTeam />,
    },
    {
      url: `${url}/settings/associations`,
      text: 'Associations',
      element: <ProfileAssociations />,
    },
    {
      url: `${url}/settings/connect`,
      text: 'Connect',
      element: <ConnectDetails />,
      roleAccess: ['OWNER', 'SUPER_ADMIN'],
    },
  ],
  ASSOCIATION: [
    {
      url: `${url}/settings`,
      text: 'User',
      element: <EditUser />,
    },
    {
      url: `${url}/settings/company`,
      text: 'Company',
      element: <EditAssociation />,
      roleAccess: ['OWNER', 'SUPER_ADMIN', 'ADMIN'],
    },
    {
      url: `${url}/settings/team`,
      text: 'Members',
      element: <EditTeam />,
      roleAccess: ['OWNER', 'SUPER_ADMIN', 'ADMIN'],
    },
    {
      url: `${url}/settings/branding`,
      text: 'Branding',
      element: <EditBranding />,
      roleAccess: ['OWNER', 'SUPER_ADMIN', 'ADMIN'],
    },
  ],
  PERSONAL: [],
  OTHER: [],
})

export default routes
