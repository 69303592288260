import React, { useEffect, useState } from 'react'
import { breakpoint } from 'services/theming'
import { Button } from 'components/theme'
import PrevalentLogoImg from 'assets/prevalent/prevalent-logo.svg'
import styled from '@emotion/styled'
import PrevalentProductsModal from './PrevalentProductsModal'
import { useOrg } from 'data/organization/useOrg'
import _ from 'lodash'

const PrevalentBox: React.FC = () => {
  const { vendor } = useOrg()
  const [hasPendingProducts, setHasPendingProducts] = useState(true)
  const [isShowModal, setIsShowModal] = useState(false)

  useEffect(() => {
    const prevalentProducts = vendor?.products || []

    const hasPendingProducts = prevalentProducts.reduce((prev, current) => {
      if (prev) return true
      const { sigLite, sigCore } = _.get(current, 'details.prevalent', {
        sigLite: { status: 'INACTIVE' },
        sigCore: { status: 'INACTIVE' },
      })

      return sigLite.status === 'PENDING' || sigCore.status === 'PENDING'
    }, false)

    setHasPendingProducts(hasPendingProducts)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleModal = () => {
    setIsShowModal((prevVal) => !prevVal)
  }

  return (
    <Box>
      <Top>
        <Logo src={PrevalentLogoImg} />
        <TopContent>
          <Title>Prevalent</Title>
          <Type>TPRM Solution</Type>
          <OutLink
            href="https://www.prevalent.net/use-cases/prevalent-for-vendors/"
            target="_blank"
          >
            More info
          </OutLink>
        </TopContent>
      </Top>
      <Text>
        Secure success with a standardized assessment from Prevalent, Inc. Earn a badge and
        highlighted position within our ecosystem.
      </Text>
      <Bottom>
        {hasPendingProducts && <PendingText>PENDING</PendingText>}
        <CtaButton onClick={toggleModal}>PURCHASE</CtaButton>
      </Bottom>
      <PrevalentProductsModal isShowModal={isShowModal} toggleModal={toggleModal} />
    </Box>
  )
}

const Box = styled.div`
  background: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  order: 1;
`

const PendingText = styled.div`
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 18px;
  color: #777;
`

const TopContent = styled.div`
  font-size: 16px;
  line-height: 1.5;
`

const OutLink = styled.a``

const Title = styled.strong``

const Type = styled.div``

const Top = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.img`
  width: 100%;
  max-width: 110px;
  margin-right: 39px;

  ${breakpoint.desktop} {
    margin-right: 20px;
  }
`

const Text = styled.p`
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  width: 90%;
  max-width: 350px;
`

const CtaButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
`

export default PrevalentBox
