import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface Variables {
  productId: number
  deletedTermIds: number[]
  createdTermIds: number[]
}
export const useMutateTermsToProduct = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { productId, deletedTermIds, createdTermIds } = variables

      await request(CREATE_TERMS_TO_PRODUCT, { termIds: createdTermIds, productId })
      await request(DELETE_TERMS_TO_PRODUCT, { termIds: deletedTermIds, productId })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']), // TODO: figure out how to optimize this so that we don't target the entire viewer
    }
  )
}

const CREATE_TERMS_TO_PRODUCT = gql`
  mutation CreateTermsToProduct($termIds: [Int!]!, $productId: Int!) {
    createTermsToProduct(input: { termIds: $termIds, productId: $productId }) {
      clientMutationId
    }
  }
`

const DELETE_TERMS_TO_PRODUCT = gql`
  mutation DeleteTermsToProduct($termIds: [Int!]!, $productId: Int!) {
    deleteTermsToProduct(input: { termIds: $termIds, productId: $productId }) {
      clientMutationId
    }
  }
`
