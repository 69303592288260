import React from 'react'
import styled from '@emotion/styled'
import TheoremLogo from 'assets/purple-logo.png'

function SignupHeader() {
  return (
    <Root>
      <Logo src={TheoremLogo} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
`

const Logo = styled.img`
  max-width: 50px;
`

export default SignupHeader
