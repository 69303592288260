import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { Seller, Invoices } from './types'

interface Props {
  loading: boolean
  sellers: Seller[]
  selectSeller: (sellerId: string) => () => void
}

const Sellers: React.FC<Props> = ({ loading, sellers, selectSeller }) => {
  return (
    <Root>
      {!loading && sellers.length > 0 &&
      <Title>Vendors you have purchased from</Title>
      }
      {!loading && sellers.length === 0 &&
        <ZeroTitle>Vendors you have purchased from will appear here once you've made your first purchase</ZeroTitle>
      }
      {sellers.length > 0 && (
        <SellersList>
          {loading && (
            <LoadingWrapper>
              <Loader name="spinner" spin pulse />
            </LoadingWrapper>
          )}
          {!loading &&
            sellers.length > 0 &&
            sellers.map((seller) => (
              <SellerBox key={seller.id} onClick={selectSeller(seller.id)}>
                <SellerImage image={seller.organization.details.squareLogoUrl} />
                <div>
                  <SellerName>{seller.organization.name}</SellerName>
                  <InvoiceNote>
                    Total of <strong>{calculateInvoiceTotal(seller.invoices)}</strong> invoice
                    {calculateInvoiceTotal(seller.invoices) > 0 && 's'}
                  </InvoiceNote>
                </div>
                <CaretIcon name="caret-right" />
              </SellerBox>
            ))}
        </SellersList>
      )}
    </Root>
  )
}

const calculateInvoiceTotal = (invoices: Invoices) => {
  return invoices.data.length
}

const Root = styled.div``

const Title = styled.h2`
  font-size: 22px;
  margin-bottom: 20px;
`

const ZeroTitle = styled(Title)`
  text-align: center;
`

const SellerBox = styled.div`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #fff;
  transition: 200ms background;
  border-bottom: 1px solid #cacaca;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background: #f3f3f3;
  }
`

const SellerName = styled.div`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.02rem;
`

const InvoiceNote = styled.div`
  font-style: italic;
  color: #454a54;
  padding-top: 2px;
`

const SellersList = styled.div`
  max-width: 750px;
  overflow: hidden;
  border: 1px solid #cacaca;
  border-radius: 6px;
`

const SellerImage = styled.div<{ image: string }>`
  background-image: url(${({ image }) => image});
  border: 1px solid #bbb;
  background-size: cover;
  border-radius: 4px;
  margin-right: 12px;
  display: block;
  width: 35px;
  min-width: 35px;
  height: 35px;
`

const CaretIcon = styled(Icon)`
  margin-left: auto;
  font-size: 20px;
  color: #4c4c52;
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
`
const Loader = styled(Icon)`
  font-size: 20px;
  color: #888;
`

export default Sellers
