import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useLazyApi } from 'hooks/useApi'
import Sellers from './Sellers'
import SellerDetails from './SellerDetails'
import { Seller } from './types'

const ConnectDetails: React.FC = () => {
  const [getInvoices] = useLazyApi('stripe/connect/get-invoices')
  const [sellers, setSellers] = useState<Seller[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [currentSeller, setCurrentSeller] = useState<Seller | null>(null)

  useEffect(() => {
    ;(async () => {
      const { invoices } = await getInvoices()
      setSellers(invoices)
      setLoading(false)
    })()
  }, [])

  const selectSeller = (sellerId: string) => () => {
    const seller = sellers.find((seller) => seller.id === sellerId)
    setCurrentSeller(seller || null)
  }

  const removeSeller = () => {
    setCurrentSeller(null)
  }

  return (
    <Root>
      {currentSeller ? (
        <SellerDetails seller={currentSeller} removeSeller={removeSeller} />
      ) : (
        <Sellers sellers={sellers} loading={loading} selectSeller={selectSeller} />
      )}
    </Root>
  )
}

const Root = styled.div``

export default ConnectDetails
