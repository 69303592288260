import { useCreateProductWizard } from 'context/CreateProductWizardContext'
import { useLocation } from 'react-router-dom'
import BasicInfoForm from 'components/forms/product/BasicInfoForm'
import { FlexLoading } from 'components/PulsatingLoader'
import styled from '@emotion/styled'
import _ from 'lodash'
import { useOrg } from 'data/organization/useOrg'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'

function BasicProductInfo() {
  const { currentProductId } = useCreateProductWizard()
  const { vendor, lawfirm, organization, organizationLoading } = useOrg()
  const location = useLocation()
  const marketplaceUrl = useMarketplaceUrl()

  if (organizationLoading) return <FlexLoading />

  const locationState = location?.state as any

  const productType = locationState?.productType || 'SOFTWARE'
  const isService = productType === 'SERVICE'

  const product = vendor
    ? vendor?.products.find((product) => product.id === currentProductId)
    : lawfirm?.products.find((product) => product.id === currentProductId)

  localStorage.setItem('productId', product?.id.toString() || '')

  const initialValues = {
    name: product?.name || '',
    url: _.get(product, 'details.url', '') || '',
    excerpt: _.get(product, 'excerpt', '') || '',
    logoFile: _.get(product, 'details.logoUrl', []),
    squareLogoFile: product?.details?.squareLogoUrl || organization?.details.logo || [],
    testMode: vendor?.testMode === true,
    productType,
  }

  const onComplete = (productId: number) => {
    window.location.href = `${marketplaceUrl}returnTo=/admin/product/${productId}/edit/basic`

    if (productId) localStorage.setItem('productId', productId.toString())

    // goNextStep()
  }

  return (
    <Root>
      <BasicInfoForm
        initialValues={initialValues}
        onComplete={onComplete}
        product={product}
        isService={isService}
      />
    </Root>
  )
}

const Root = styled.div`
  padding: 40px;
  display: flex;
  justify-content: center;
`

export default BasicProductInfo
