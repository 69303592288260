import styled from '@emotion/styled'
import Modal from 'components/Modal'
import { Formik, Form, Field } from 'formik'
import { Button } from 'components/theme'
import { MARKETPLACE_SELLER_AGREEMENT } from 'services/globalAssets'

interface Props {
  showModal: boolean
  toggleModal: () => void
  onSubmit: () => void
}

const SellerTermsModal = ({ showModal, toggleModal, onSubmit }: Props) => {
  return (
    <Modal toggleModal={toggleModal} isShowing={showModal}>
      <Root>
        <TermsTitle>Seller Program Terms &amp; Conditions</TermsTitle>
        <p>
          We have updated Theorem's Marketplace Seller Agreement. Changes are effective for all
          sellers as of December 1, 2021. You are required to read and agree to all these terms and
          conditions before continuing to be able to access Theorem Marketplace Seller features. By
          clicking below you agree and accept the terms and conditions of the Theorem services.
        </p>
        <Formik initialValues={{ acceptSellerAgreement: false }} onSubmit={onSubmit}>
          {({ values }) => (
            <Form>
              <Field type="checkbox" name="acceptSellerAgreement" />
              &nbsp;
              <label htmlFor="acceptSellerAgreement">
                I've read and agree with Theorem's{' '}
                <a target="_blank" href={MARKETPLACE_SELLER_AGREEMENT}>
                  Marketplace Seller Agreement
                </a>
              </label>
              <br />
              <br />
              <DoneButton type="submit" disabled={Object.values(values).includes(false)}>
                Purchase
              </DoneButton>
            </Form>
          )}
        </Formik>
      </Root>
    </Modal>
  )
}

const Root = styled.div``

const TermsTitle = styled.h2`
  font-size: 27px;
  margin: 0 0 16px;
  padding: 0;
`

const DoneButton = styled(Button)`
  background-color: #498de6;
  color: white;
  width: 100%;
  border-radius: 3px;

  &:hover {
    color: #498de6;
    background-color: white;
  }

  &:disabled {
    border-color: #498de6;
    color: white;
    opacity: 0.4;

    &:hover {
      color: white;
      background-color: #498de6;
    }
  }
`

export default SellerTermsModal
