import React from 'react'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { css } from '@emotion/react'
import { useViewer } from 'context/viewerContext'
import { useCreateUserToTechstack } from 'data/hooks'

interface Props {
  access: string
  techstackId: number
  productName: string
}

const TechstackTooltip: React.FC<Props> = ({ techstackId, productName }) => {
  const { viewer } = useViewer()
  const createUserToTechstack = useCreateUserToTechstack()

  const addToApps = () => {
    createUserToTechstack.mutate(techstackId)
    toast.success(`Added ${productName} to your apps`)
  }

  const hasTechstackInList = viewer.techstack.some(
    (techstack) => techstackId === techstack.techstackId
  )

  return (
    <Root>
      <ItemLink
        href="https://airtable.com/embed/shrdVdxkew8dWXCcj?backgroundColor=cyan"
        target="_blank"
      >
        Review Vendor
      </ItemLink>
      {!hasTechstackInList && <Item onClick={addToApps}>Add to my apps</Item>}
    </Root>
  )
}

const itemStyles = css`
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  background: white;
  transition: background 0.3s;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;

  &:hover {
    background: #eee;
  }
`

const Root = styled.div``

const ItemLink = styled.a`
  ${itemStyles}
`

const Item = styled.div`
  ${itemStyles}
`

export default TechstackTooltip
