import styled from '@emotion/styled'
import { useOrgById } from 'data/hooks'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import UpdateOrgForm from './UpdateOrgForm'
import { markdownToDraft } from 'markdown-draft-js'
import { EditorState, convertFromRaw } from 'draft-js'

const UpdateBasicOrg = () => {
  const { orgId } = useParams()
  const { company, isLoading } = useOrgById(orgId)

  const initialValues = {
    name: company?.name || '',
    website: company?.details?.website || '',
    domain: company?.domain || '',
    excerpt: company?.excerpt || '',
    description: EditorState.createEmpty(),
    type: company?.type,
    subtype: company?.subtype,
    logo: company?.details?.logo || [],
    wideLogo: company?.details?.wideLogo || [],
  }

  if (company?.description) {
    const contentState = convertFromRaw(markdownToDraft(company.description))
    initialValues.description = EditorState.createWithContent(contentState)
  }

  const onComplete = () => {
    toast.success(`${company?.name} updated`)
  }

  if (!company || !orgId || isLoading) return null

  return (
    <Root>
      <UpdateOrgForm
        orgId={orgId}
        slug={company?.slug}
        onComplete={onComplete}
        initialValues={initialValues}
      />
    </Root>
  )
}

const Root = styled.div``

export default UpdateBasicOrg
