import React, { useEffect, createContext, useContext, useState, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const STEP_NAME = ['first', 'second', 'third', 'forth', 'thank-you']
const initialData = [
  {
    // step 1
    formData: {
      email: '',
      offering: 'no',
      sigLite: false,
      sigCore: false,
    },
    completed: false,
    title: 'Confirm Selected App',
  },
  {
    // step 2
    formData: {
      productName: [],
      firstName: '',
      lastName: '',
      companyName: '',
      title: '',
      phone: '',
      currentTprm: 'no',
      usingTprm: 'no',
      tprmDescription: '',
    },
    completed: false,
    title: 'Your Information',
  },
  {
    // step 3
    formData: {
      name: '',
      country: [{ value: 'US', label: 'United States' }],
      postalCode: '',
      couponCode: '',
      discount: 0,
    },
    completed: false,
    title: 'Your Payment Info',
  },
  {
    // step 4
    formData: {},
    completed: false,
    title: 'Review Purchase',
  },
]

export const WizardContext = createContext({} as any)

interface Props {
  children: ReactNode
}

export const WizardProvider = ({ children }: Props) => {
  const [stepIndex, setStepIndex] = useState(0)
  const baseOrgUrl = useBaseOrgUrl()
  const [wizardData, setWizardData] = useState(initialData)
  const [renderRedirect, setRenderRedirect] = useState(false) // skip the first render
  const navigate = useNavigate()

  useEffect(() => {
    if (baseOrgUrl && renderRedirect) {
      const newUrl = `${baseOrgUrl}/prevalent/${STEP_NAME[stepIndex]}`
      navigate(newUrl)
    }
  }, [stepIndex, history, baseOrgUrl, renderRedirect])

  const updateWizardData = (newData: any) => {
    setWizardData((prevData) => {
      prevData[stepIndex].completed = true
      prevData[stepIndex].formData = newData

      return prevData
    })
  }

  const getStepData = () => {
    return wizardData[stepIndex]
  }

  const nextStep = () => {
    setStepIndex((prevStep) => (prevStep < STEP_NAME.length ? prevStep + 1 : prevStep))
    if (!renderRedirect) setRenderRedirect(true)
  }

  const prevStep = () => {
    setStepIndex((prevStep) => prevStep - 1)
  }

  const jumpToStep = (index: number) => {
    if (renderRedirect) setStepIndex(index)
  }

  return (
    <WizardContext.Provider
      value={{
        jumpToStep,
        nextStep,
        prevStep,
        stepIndex,
        getStepData,
        updateWizardData,
        wizardData,
      }}
    >
      {children}
    </WizardContext.Provider>
  )
}

export const useWizardContext = () => useContext(WizardContext)
