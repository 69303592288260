import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  id: number
  logo: string
  name?: string
  order: number
}

export const useUpdateVendorStackshareLogo = () => {
  const request = useGql()

  return useMutation(async ({ id, logo, name, order }: Variables) => {
    await request(UPDATE_VENDOR_STACKSHARE_LOGO, { id, logo, name, order })
  })
}

const UPDATE_VENDOR_STACKSHARE_LOGO = gql`
  mutation UpdateVendorStackshareLogo($id: Int!, $logo: String, $name: String, $order: Int) {
    updateVendorStackshareLogo(
      input: { patch: { logo: $logo, name: $name, order: $order }, id: $id }
    ) {
      clientMutationId
    }
  }
`
