import VendorForm from 'components/forms/VendorForm'
import { toast } from 'react-toastify'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useOrg } from 'data/organization/useOrg'

function EditVendor() {
  const { vendor, organizationLoading } = useOrg()

  if (organizationLoading) return <FullPageLoading />

  const initialValues = {
    name: vendor?.name || '',
    website: vendor?.details.website || '',
    logo: vendor?.details.logo || [],
    businessStatus: vendor?.details.businessStatus || '',
    companySize: vendor?.details.companySize || '',
    yearFounded: vendor?.details.yearFounded || '',
    vendorType: vendor?.details.vendorType || 'Software Provider',
  }

  const onComplete = () => {
    toast.success('Vendor has been updated')
  }

  return <VendorForm initialValues={initialValues} onComplete={onComplete} />
}

export default EditVendor
