// dependencies
import React, { useReducer, useState } from 'react'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
// components
import { RfpStart, RfpTerms, RfpProvisions, RfpPreview, RfpDateLocation } from './RfpForms'
import { Button, BoxWrapper } from 'components/theme'
// definitions
import { Rfp } from '.'
import { useRedirect } from 'hooks/useRedirect'
import { useCreateRfp } from 'data/hooks'

const updateRfp = (state: Rfp, update: { name: string; payload: any }) => {
  return { ...state, [update.name]: update.payload }
}

const RfpStepper = () => {
  const [showInstructions, setShowInstructions] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState(0)
  const createRfp = useCreateRfp()

  const redirect = useRedirect()

  const initialRfp: Rfp = {
    title: '',
    companyDescription: '',
    categories: '',
    products: [],
    budget: '',
    responses: '',
    seatsRequested: '',
    practiceGroups: [],
    requiredProvisions: [],
    notes: '',
    deployments: [],
    startDate: '',
    endDate: '',
    location: '',
  }

  const sampleData: Rfp = {
    title: 'Sample RFP',
    companyDescription: 'Am Law 200 firm specializing in Cross-Border Transactions',
    categories: '772',
    products: [
      { id: 0, name: 'dealcloser' },
      { id: 0, name: 'Litera Transact' },
      { id: 0, name: 'Transactionaly' },
      { id: 0, name: 'Anduin Transactions' },
    ],
    budget: '$50,000',
    responses: '5',
    notes:
      'The transaction management tools should integrate with iManage and work seamlessly with our product suite and Single-Sign On. Esignature and deal room task management are a major plus!',
    requiredProvisions: [
      'No limitation of liability provisions for potential Data Breach',
      "Acceptance of Theorem's Enterprise Contract Standard.",
    ],
    deployments: [],
    practiceGroups: ['1220'],
    seatsRequested: '100',
    startDate: '03/22/2021',
    endDate: '06/14/2021',
    location: 'Denver, CO',
  }

  const [fullRfp, updateFullRfp] = useReducer(updateRfp, initialRfp)

  const addProvision = () => {
    const payload = [...fullRfp.requiredProvisions, '']
    updateFullRfp({ name: 'requiredProvisions', payload })
  }

  const removeProvision = (index: number) => {
    const payload = fullRfp.requiredProvisions
    payload.splice(index, 1)
    updateFullRfp({ name: 'requiredProvisions', payload })
  }

  const updateProvision = (index: number, value: string) => {
    const payload = fullRfp.requiredProvisions
    payload[index] = value
    updateFullRfp({ name: 'requiredProvisions', payload })
  }

  const updatePartOfRfp = (name: string, payload: any) => {
    updateFullRfp({ name, payload })
  }

  const updateManyPartsOfRfp = (values: Rfp) => {
    const rfpValues = Object.keys(values)
    rfpValues.forEach((value) => updatePartOfRfp(value, values[value]))
  }

  const resetRfp = () => {
    updateManyPartsOfRfp(initialRfp)
    setCurrentStep(0)
  }

  const onSubmitRfp = async (values: Rfp, { resetForm }: any) => {
    try {
      await createRfp.mutateAsync(values)

      resetForm()
      redirect('rfp-success')
    } catch (e) {
      console.error(e)
      toast.error('There was an error, please try again later.')
    }
  }

  const renderFormStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <RfpStart
            initialValues={fullRfp}
            onSubmit={() => setCurrentStep((prevVal) => prevVal + 1)}
            updateStepper={updatePartOfRfp}
            FormStepperLinks={FormStepperLinks}
          />
        )
      case 1:
        return (
          <RfpTerms
            initialValues={fullRfp}
            onSubmit={() => setCurrentStep((prevVal) => prevVal + 1)}
            updateStepper={updatePartOfRfp}
            FormStepperLinks={FormStepperLinks}
          />
        )
      case 2:
        return (
          <RfpDateLocation
            initialValues={fullRfp}
            onSubmit={() => setCurrentStep((prevVal) => prevVal + 1)}
            updateStepper={updatePartOfRfp}
            FormStepperLinks={FormStepperLinks}
          />
        )
      case 3:
        return (
          <RfpProvisions
            initialValues={fullRfp}
            updateStepper={updatePartOfRfp}
            onSubmit={() => onSubmitRfp(fullRfp, { resetForm: resetRfp })}
            addProvision={addProvision}
            removeProvision={removeProvision}
            updateProvision={updateProvision}
            FormStepperLinks={FormStepperLinks}
          />
        )
    }
  }

  const instructions = (
    <div>
      <h3>Instructions</h3>
      <p>
        This brief questionnaire will guide you through the process of preparing your RFP. The
        questions will only take a few minutes to complete.
      </p>
      <p>
        As you complete each question, your RFP will appear on the right. A sample RFP is shown here
        for your information.
      </p>
      <p>
        Once submitted, we will review the RFP and match you with providers you have selected and
        other suggestions from our vetted vendor network that meet your requirements. This typically
        occurs within 1-3 business days and you will be notified by email throughout the process.
      </p>
      <p>
        RFPs are shared with vendors to help teams like yours find companies and tools that are a
        good fit for your specific needs. Include any and all information you feel is necessary–
        <b>out of respect for your teams' privacy</b>, only the information you include in the RFP
        builder will be shared with vendors.
      </p>
    </div>
  )

  const FormStepperLinks: React.FC<{ isSubmitting?: boolean }> = ({ isSubmitting }) => (
    <FormSteppers>
      <StepperLink
        type="button"
        onClick={() => {
          setCurrentStep((prevStep) => prevStep - 1)
        }}
        disabled={currentStep === 0}
        style={currentStep === 0 ? { color: '#607d8b' } : {}}
      >
        previous
      </StepperLink>
      {` | `}
      {currentStep < 3 && <StepperLink type="submit">next</StepperLink>}
      {currentStep === 3 && (
        <SubmitButton disabled={isSubmitting} loading={isSubmitting} type="submit">
          Send
        </SubmitButton>
      )}
    </FormSteppers>
  )

  return (
    <BoxWrapper>
      <SideBySide>
        <FormContainer>
          <StepperHead>
            <SectionTitle>RFP Builder</SectionTitle>
            <StepperLink
              onClick={() => {
                setShowInstructions((prevVal) => !prevVal)
              }}
            >
              {showInstructions ? `Back to Use Case` : `Instructions`}
            </StepperLink>
          </StepperHead>
          {showInstructions ? instructions : renderFormStep()}
        </FormContainer>
        <PreviewContainer>
          <StepperHead>
            <SectionTitle>{showInstructions ? `Sample RFP` : `Your RFP (preview)`}</SectionTitle>
          </StepperHead>
          {showInstructions ? (
            <RfpPreview {...sampleData} />
          ) : (
            <RfpPreview step={currentStep} {...fullRfp} />
          )}
        </PreviewContainer>
      </SideBySide>
    </BoxWrapper>
  )
}

const SideBySide = styled.div`
  display: flex;
  width: 100%;
`

const FormContainer = styled.div`
  width: 55%;
`

const StepperHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`

const SectionTitle = styled.div`
  color: #616161;
  font-size: 0.9em;
`

const PreviewContainer = styled.div`
  margin: 0 32px;
  width: 45%;
`

const FormSteppers = styled.nav`
  margin-top: 8px;
`

const StepperLink = styled.button`
  background: none;
  border: none;
  color: #2196f3;
`

const SubmitButton = styled(Button)`
  background: #a333c8;
  border-color: #a333c8;
  color: white;
  margin-right: 16px;

  &:disabled,
  &[disabled] {
    border: 2px solid '#9e9e9e';
    color: '#9e9e9e';

    &:hover {
      background: transparent;
      cursor: not-allowed;
    }
  }
`

export default RfpStepper
