import React from 'react'
import SalesForm from 'components/forms/product/SalesForm'
import PricingPlanLoader from 'components/forms/product/SalesForm/PricingPlanLoader'
import { FlexLoading } from 'components/PulsatingLoader'
import { useProductSettings } from '../useProductSettings'
import { markdownToDraft } from 'markdown-draft-js'
import { EditorState, convertFromRaw } from 'draft-js'
import styled from '@emotion/styled'
import VendorUpgradeModal from 'components/modals/VendorUpgradeModal'
import _ from 'lodash'

function Sales() {
  const { product, loading, onComplete, showModal, toggleModal, isService } = useProductSettings()

  const initialValues = {
    pricingPlans: _.get(product, 'details.pricingPlans', []),
    pricingDescription: getPricingDescription(product),
    planDescription: getPlanDescription(product),
    startingPrice: _.get(
      product,
      'details.startingPrice',
      _.get(product, 'details.startingMonthlyPrice', '')
    ),
    startingPriceInterval: _.get(product, 'details.startingPriceInterval', 'monthly'),
    startingPriceIsPerUser: _.get(product, 'details.startingPriceIsPerUser', false),
    hasFreeTrial: _.get(product, 'details.hasFreeTrial', ''),
    hasFreeVersion: _.get(product, 'details.hasFreeVersion', ''),
    hasPerUser: _.get(product, 'details.hasPerUser', ''),
    ctaButtonLink: _.get(product, 'details.ctaButtonLink', ''),
    ctaButtonText: _.get(product, 'details.ctaButtonText', ''),
  }

  if (loading || !product) return <FlexLoading />

  return (
    <>
      {!isService && (
        <>
          <H3>Pricing Plans</H3>
          <PricingPlanLoader pricingPlans={initialValues.pricingPlans} product={product} />
        </>
      )}
      <H3>General</H3>
      <SalesForm
        initialValues={initialValues}
        onComplete={onComplete}
        product={product}
        isService={isService}
      />
      <VendorUpgradeModal showModal={showModal} toggleModal={toggleModal} />
    </>
  )
}

const getPricingDescription = (product: any) => {
  const parseProductDescription = _.get(product, 'details.pricingDescription', '')

  return extractMarkdown(parseProductDescription)
}

const getPlanDescription = (product: any) => {
  const markdown = _.get(product, 'details.planDescription', '')

  return extractMarkdown(markdown)
}

const extractMarkdown = (markdown: any) => {
  const editorContent = markdown
    ? EditorState.createWithContent(convertFromRaw(markdownToDraft(markdown)))
    : EditorState.createEmpty()

  return editorContent
}

const H3 = styled.h3`
  margin-top: 0;
`

export default Sales
