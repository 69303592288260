// ALL
export const TERMS_AND_CONDITIONS = 'https://www.theoremlegal.com/legal'
export const PRIVACY_POLICY = 'https://www.theoremlegal.com/privacy'
export const SUBSCRIPTION_AGREEMENT =
  'https://res.cloudinary.com/dh1giwabb/image/upload/v1638856749/Terms%20and%20Conditions/Theorem_Subscription_Agreement_FINAL_ydsy9e.pdf'

// VENDOR
export const MARKETPLACE_SELLER_AGREEMENT =
  'https://res.cloudinary.com/dh1giwabb/image/upload/v1638857070/Terms%20and%20Conditions/Theorem_Marketplace_Seller_Agreement_FINAL_uflocx.pdf'

// LAWFIRM

// test
//test 2
// ASSOCIATIONS #agreements by Network
export const NETWORK_AGREEMENTS = ''
