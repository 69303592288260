import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useCreateUserToTechstack = () => {
  const request = useGql()
  const { viewer } = useViewer()
  const queryClient = useQueryClient()

  return useMutation(
    async (techstackId: number) => {
      await request(CREATE_USER_TO_TECHSTACK, {
        userId: viewer.id,
        techstackId,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['viewer']),
    }
  )
}

const CREATE_USER_TO_TECHSTACK = gql`
  mutation CreateUserToTechstack($userId: Int!, $techstackId: Int!) {
    createUsersToTechstack(
      input: { usersToTechstack: { userId: $userId, techstackId: $techstackId } }
    ) {
      clientMutationId
    }
  }
`
