import { ProductState } from 'generated/graphql'
import { ViewerPersonalTechstack } from './viewerTypes'

interface GqlPersonalTechstack {
  productId: number
  product?: {
    id: number
    name: string
    slug: string
    details: any
    state: ProductState
    show: boolean
    excerpt: string
    updatedAt: any
    createdAt: any
    testMode: boolean
  }
}

export const parsePersonalTechstack = (
  personalTechstacks: GqlPersonalTechstack[]
): ViewerPersonalTechstack[] => {
  return personalTechstacks.map(({ product }) => product) as ViewerPersonalTechstack[]
}
