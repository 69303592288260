import { GqlMember, Member } from './types/orgType'
import { getLocalTime } from 'services/helper'

export const parseMembers = (usersToOrganization: GqlMember[]): Member[] => {
  return usersToOrganization
    .map((member) => {
      if (!member.user) return
      return {
        id: member.userId,
        role: member.role,
        status: member.status,
        email: member.user.email,
        jobTitle: member.jobTitle,
        first: member.user.first,
        last: member.user.first,
        name:
          member.user.first || member.user.last
            ? `${member.user.first || ''} ${member.user.last || ''}`
            : '',
        headshot: member.user.details.imageUrl || '',
        activity: member.user.updatedAt ? getLocalTime(member.user.updatedAt) : '',
        user: member.user,
      }
    })
    .filter((member: Member | undefined): member is Member => !!member) as Member[]
}
