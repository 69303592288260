import React from 'react'
import { useField } from 'formik'
import styled from '@emotion/styled'
import Label from './Label'

interface Props {
  placeholder?: string
  description?: any
  helpContent?: any
  isSubmitting?: boolean
  label?: string
  required?: boolean
  rows?: number
  name: string
  [key: string]: any
}
function TextArea({
  placeholder = '',
  description = '',
  helpContent = '',
  isSubmitting = false,
  label = '',
  required = false,
  rows = 2,
  ...props
}: Props) {
  const [field, meta] = useField(props)
  const errorText = !isSubmitting && meta.error && meta.touched ? meta.error : ''

  return (
    <>
      <label>
        <Label required={required} helpContent={helpContent}>
          {label}
        </Label>
        <Description>{description}</Description>
        <StyledTextArea placeholder={placeholder} {...field} rows={rows} />
      </label>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </>
  )
}

const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 50px;
  margin: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0.78571429em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;

  &:placeholder {
    color: rgba(191, 191, 191, 0.87);
  }

  &:focus {
    color: rgba(0, 0, 0, 0.95);
    border-color: #85b7d9;
    border-radius: 0.28571429rem;
    background: #fff;
    box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.35) inset;
    -webkit-appearance: none;
  }
`

const ErrorText = styled.div`
  color: #f44336;
  font-weight: 400;
  margin-top: 6px;
`

const Description = styled.div`
  color: #888;
  font-weight: 400;
  margin-bottom: 4px;
`

export default TextArea
