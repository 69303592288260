import styled from '@emotion/styled'

interface Props {
  name: string
  excerpt: string
  logoUrl: any
}
const AppBox = ({ name, excerpt, logoUrl }: Props) => {
  return (
    <Root>
      <AppContent>
        {typeof logoUrl === 'string' && <Logo src={logoUrl} />}
        {typeof logoUrl === 'object' && logoUrl.length > 0 && (
          <Logo src={URL.createObjectURL(logoUrl[0].file)} alt="Logo" />
        )}
        <BoxTitle>{name}</BoxTitle>
        <Excerpt>{excerpt}</Excerpt>
      </AppContent>
    </Root>
  )
}

const Root = styled.div`
  width: 33%;
  min-width: 280px;
  text-align: left;

  @media (max-width: 1300px) {
    width: 50%;
  }
  @media (max-width: 850px) {
    width: 100%;
    padding-right: 0;
  }
`

const AppContent = styled.div`
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 4px;
  border: transparent 2px solid;
  background: white;
  transition: border 250ms;
  height: 100%;

  &:hover {
    border: #4cd0d1 2px solid;
  }
`

const Logo = styled.img`
  max-width: 60px;
`

const BoxTitle = styled.h3`
  margin-top: 10px;
`

const Excerpt = styled.p`
  font-size: 14px;
  color: #676773;
  line-height: 1.5;
`

export default AppBox
