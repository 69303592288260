import React from 'react'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'

import { FormProps, DispatchInput } from '.'
import Label from 'components/theme/form/Label'
import { FormField } from 'components/theme/form'


const RfpDateLocation: React.FC<FormProps> = ({
  FormStepperLinks,
  updateStepper,
  onSubmit,
  initialValues,
}) => {
  return (
    <>
      <FormTitle>Logistics</FormTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <FormField>
            <Label>Expected Start Date</Label>
            <DispatchInput type="date" updateStepper={updateStepper} name="startDate" />
          </FormField>
          <FormField>
            <Label>Closing Date for Rfp/Project</Label>
            <DispatchInput type="date" updateStepper={updateStepper} name="endDate" />
          </FormField>
          <FormField>
            <Label>Location(s) Where Work is to be Performed</Label>
            <DispatchInput name="location" updateStepper={updateStepper} />
          </FormField>
          <FormStepperLinks />
        </Form>
      </Formik>
    </>
  )
}

const FormTitle = styled.h3`
  margin: 0 0 16px;
  font-size: 1.6em;
`

export default RfpDateLocation
