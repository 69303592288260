import styled from '@emotion/styled'
import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Icon } from 'components/theme'
import MenuItem from './MenuItem'
import MenuOutLink from './MenuOutLink'
import SecondaryMenu from './SecondaryMenu'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
import MarketPlaceMenu from './MarketplaceMenu'

interface Props {
  closeMobileMenu: () => void
}
const LawfirmMenu = ({ closeMobileMenu }: Props) => {
  const baseOrgUrl = useBaseOrgUrl()
  const { isAdmin } = useViewer()
  const marketplaceUrl = useMarketplaceUrl(true)
  // const { organization } = useOrg()
  // const marketplaceUrl = useMarketplaceUrl(true)

  let techstackSecondaryMenu = [
    { title: 'My Stack (Personal)', link: `${baseOrgUrl}/your-apps` },
    { title: 'Company Stack (Team)', link: `${baseOrgUrl}/your-apps` },
    // {
    //   title: 'StackShare (Community)',
    //   link:
    //     organization?.type === OrganizationType.Association
    //       ? `${marketplaceUrl}returnTo=/stackshare/network/${organization?.slug}`
    //       : `${marketplaceUrl}returnTo=/stackshare`,
    //   isOutLink: true,
    // },
  ]

  if (isAdmin) {
    techstackSecondaryMenu = [
      ...techstackSecondaryMenu,
      { title: 'Edit Company Stack', link: `${baseOrgUrl}/techstack-management` },
    ]
  }

  return (
    <Root>
      <MenuLi>
        <MenuItem to={`${baseOrgUrl}/your-apps`} onClick={closeMobileMenu}>
          Tech Stacks <DownCaret name="caret-down" />
        </MenuItem>
        <SecondaryMenu closeMobileMenu={closeMobileMenu} pages={techstackSecondaryMenu} />
      </MenuLi>

      <MenuLi>
        <MenuOutLink href={`${marketplaceUrl}returnTo=/admin/listings`} onClick={closeMobileMenu}>
          Marketing <DownCaret name="caret-down" />
        </MenuOutLink>
        <SecondaryMenu
          closeMobileMenu={closeMobileMenu}
          pages={[
            {
              title: 'Products',
              link: `${marketplaceUrl}returnTo=/admin/product`,
              isOutLink: true,
            },
            {
              title: 'Listings',
              link: `${marketplaceUrl}returnTo=/admin/listings`,
              isOutLink: true,
            },
          ]}
        />
      </MenuLi>
      <MenuLi>
        <MenuItem to={`${baseOrgUrl}/rfp`} onClick={closeMobileMenu}>
          Solutions <DownCaret name="caret-down" />
        </MenuItem>
        <SecondaryMenu
          closeMobileMenu={closeMobileMenu}
          pages={[{ title: 'RFP', link: `${baseOrgUrl}/rfp` }]}
        />
      </MenuLi>
      <MenuLi>
        <MenuOutLink href={`${marketplaceUrl}`}>
          Marketplace <DownCaret name="caret-down" />
        </MenuOutLink>
        <MarketPlaceMenu />
      </MenuLi>
    </Root>
  )
}

const Root = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: block;
    width: 100%;
  }
`

const DownCaret = styled(Icon)`
  font-size: 16px;
  margin-left: 4px;
`

const MenuLi = styled.li`
  position: relative;

  &:hover {
    & > a:after {
      transform: scaleY(1);
    }

    & > div:first-of-type {
      display: block;
    }
  }

  ${({ theme }) => theme.breakpoint.mobile} {
    &:first-of-type a {
      border-top: 0;
    }
  }
`

export default LawfirmMenu
