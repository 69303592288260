import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'
import { useParams } from 'react-router'
import AccCompanyPlans from './AccCompanyPlans'
import ClocCompanyPlans from './ClocCompanyPlans'
import EltaCompanyPlans from './EltaCompanyPlans'

const CompanyPlans = () => {
  const { slug } = useParams()

  return (
    <Root>
      <BoxWrapper>
        {slug === 'acc' && <AccCompanyPlans />}
        {slug === 'cloc' && <ClocCompanyPlans />}
        {slug === 'elta' && <EltaCompanyPlans />}
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

export default CompanyPlans
