import { useQuery, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { GqlAssocHomeSectionsQuery } from 'generated/graphql'
import { useOrg } from 'data/organization/useOrg'

export const useAssocHomes = () => {
  const request = useGql()
  const { organization } = useOrg()
  const queryClient = useQueryClient()
  const { data, ...other } = useQuery(
    ['assocHome'],
    async () => {
      const response = await request<GqlAssocHomeSectionsQuery>(ASSOC_HOME_SECTIONS, {
        organizationId: organization?.id,
      })

      const assocHomes = response.assocHomes

      assocHomes.forEach((assocHome) => {
        queryClient.setQueryData(['assocHome', assocHome.id], assocHome)
      })

      return assocHomes
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  )

  return { assocHome: data, ...other }
}

const ASSOC_HOME_SECTIONS = gql`
  query AssocHomeSections($organizationId: UUID) {
    assocHomes(condition: { organizationId: $organizationId }, orderBy: ORDER_ASC) {
      id
      details
      type
      updatedAt
      createdAt
      order
    }
  }
`
