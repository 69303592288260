import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'
import { useOrg } from 'data/hooks'
import { GqlOrganizationListingQuery } from 'generated/graphql'
import PulsatingLoader from 'components/PulsatingLoader'
import companyListingData from './companyListingData'
import { useNavigate, useParams } from 'react-router'
import BasicCompanyForm, { Values } from 'components/forms/BasicCompanyForm'
import { Term } from 'data/types'
import BackButton from 'components/theme/BackButton'

const parseTermId = (selectedTaxonomyId: number, terms?: Term[]) => {
  if (!terms || terms.length === 0) return

  const term = terms.find(({ taxonomyId }) => taxonomyId === selectedTaxonomyId)

  if (!term) return

  return term.id
}

const EditCompanyListing = () => {
  const { slug } = useParams()
  const companyListing = companyListingData.find((listing) => slug === listing.slug)
  const associationId = companyListing?.associationId
  const { organization, organizationLoading } = useOrg()
  const navigate = useNavigate()

  const parseInitialValues = (data?: GqlOrganizationListingQuery): Values => {
    const listingDetails = data?.organizationListing

    if (!listingDetails) {
      return {
        name: organization?.name || '',
        website: organization?.details.website,
        wideLogo: organization?.details.wideLogo,
        logo: organization?.details.logo,
        companyType: parseTermId(17, organization?.terms),
        employeeSize: parseTermId(19, organization?.terms),
        specialty: organization?.terms
          .filter(({ taxonomyId }) => taxonomyId === 13)
          .map(({ id }) => id),
      }
    }

    return {
      name: organization?.name || '',
      website: organization?.details.website,
      wideLogo: organization?.details.wideLogo,
      logo: organization?.details.logo,
      companyType: parseTermId(17, organization?.terms),
      employeeSize: parseTermId(19, organization?.terms),
      specialty: organization?.terms
        .filter(({ taxonomyId }) => taxonomyId === 13)
        .map(({ id }) => id),
    }
  }

  const onComplete = async () => {
    // go to company listing
    navigate(`../company-plans/${slug}`)
  }

  return (
    <Root>
      <BoxWrapper>
        <BackButton />
        <h2>Enter Basic Company Details</h2>
        <p>Complete company listing after purchase.</p>
        {organizationLoading ? (
          <PulsatingLoader />
        ) : (
          <BasicCompanyForm
            initialValues={parseInitialValues()}
            associationId={associationId}
            onComplete={onComplete}
            submitButtonText="Save & Continue"
          />
        )}
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

export default EditCompanyListing
