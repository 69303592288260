import React, { useEffect, useCallback, KeyboardEvent } from 'react'
import { useField } from 'formik'
import styled from '@emotion/styled'
import { Label } from 'components/theme/form'

const VALID_FIRST = /^[1-9]{1}$/
const VALID_NEXT = /^[0-9]{1}$/
const DELETE_KEY_CODE = 'Backspace'

const MoneyInput = ({
  label,
  disabled = false,
  hasConnectAccount = false,
  currency = 'USD',
  ...props
}: any) => {
  const [, meta, helpers] = useField(props)
  const { value } = meta

  useEffect(() => {
    if (disabled) helpers.setValue('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  const valueAbsTrunc = Math.trunc(Math.abs(value))
  if (value !== valueAbsTrunc || !Number.isFinite(value) || Number.isNaN(value)) {
    // throw new Error(`invalid value property`)
  }

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const { key } = e
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && key !== DELETE_KEY_CODE)
      ) {
        return
      }

      const valueString = value ? value.toString() : '0'
      let nextValue

      if (key !== DELETE_KEY_CODE) {
        const nextValueString = value === 0 ? key : `${valueString}${key}`
        nextValue = Number.parseInt(nextValueString, 10)
      } else {
        const nextValueString = valueString.slice(0, -1)
        nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10)
      }

      helpers.setValue(nextValue)
    },
    [value, helpers]
  )

  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, [])

  const numericValue = value || 0 // if null value default to 0
  const valueDisplay = (numericValue / 100).toLocaleString('en-US', {
    style: 'currency',
    currency,
  })

  return (
    <div>
      {label && (
        <Label
          helpContent={
            hasConnectAccount &&
            label !== 'Starting Price' && (
              <>
                When a price is associated with a Stripe price plan users can buy this product
                directly on Theorem.
              </>
            )
          }
        >
          {label}
        </Label>
      )}
      <Input
        inputMode="numeric"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={valueDisplay}
        disabled={disabled}
      />
    </div>
  )
}

const Input = styled.input`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  padding: 9.5px 14px;
  width: 100%;
  z-index: 100;
`

export default MoneyInput
