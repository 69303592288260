import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Icon, Button } from 'components/theme'

const SearchBar: React.FC<{ onChange: (query: string) => void }> = ({ onChange }) => {
  const [query, setQuery] = useState<string>('')

  return (
    <Wrapper>
      <SearchWrapper>
        <SearchInput
          placeholder={'Search User Stories'}
          type="text"
          value={query}
          onChange={(event) => {
            setQuery(event.target.value)
            onChange(event.target.value)
          }}
        />
        <SearchIcon name="search" />
      </SearchWrapper>
      <ClearSearch
        type="button"
        onClick={() => {
          setQuery('')
          onChange('')
        }}
        disabled={query === ''}
      >
        Clear
      </ClearSearch>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const SearchWrapper = styled.div`
  position: relative;
`

const ClearSearch = styled(Button)`
  color: #aaa;
  border-color: #aaa;
  &:hover {
    border-color: #aaa;
    color: white;
    background-color: #aaa;
    &:disabled {
      color: #aaa;
    }
  }
`

const SearchIcon = styled(Icon)`
  color: rgb(215, 171, 239);
  position: absolute;
  top: 17px;
  right: 27px;
`

export const SearchInput = styled.input`
  /* border: 1px solid rgb(215, 171, 239); */
  border: 2px solid #aaa;
  border-radius: 15px;
  height: 32px;
  margin: 8px 0;
  padding: 4px 24px;
  display: flex;
  align-items: center;
  width: 200px;
  margin-right: 8px;
  position: relative;
  /* &::placeholder {
    color: rgb(215, 171, 239);
  } */
`

export default SearchBar
