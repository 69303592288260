import React from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { isUsingProductionServer } from 'services/helper'
import { useUserSetup } from 'context/userSetupContext'

const MONTHLY_PLAN = isUsingProductionServer ? 'price_1J07CGJl1YuJqgKxjOXAu1jd' : 'personal-monthly'
const YEARLY_PLAN = isUsingProductionServer
  ? 'price_1J07CGJl1YuJqgKxr1rLbbb7'
  : 'price_1IzsfOJl1YuJqgKxkpkRhjd3'

type Props = {
  sectionRef: any
}
const PlanSelections = ({ sectionRef }: Props) => {
  const { amplitude } = useUserSetup()
  const navigate = useNavigate()

  const onPriceClick = (priceId: string, freeTrialDays: number) => () => {
    amplitude?.logEventWithSavedProps('select_plan', {
      action: 'selection',
      organization_type: 'PERSONAL',
      user_choice: 'premium',
    })
    amplitude?.logEventWithSavedProps('select_plan', {
      action: 'selection',
      organization_type: 'PERSONAL',
      user_choice: 'premium',
    })
    navigate('/setup/personal-checkout', {
      state: {
        priceId: priceId,
        freeTrialDays,
      },
    })
  }

  return (
    <Root ref={sectionRef as any}>
      <Plans>
        <Plan>
          <PlanTitle>MONTHLY</PlanTitle>

          <PlanButton onClick={onPriceClick(MONTHLY_PLAN, 0)}>
            <PlanCost>$25</PlanCost>
          </PlanButton>

          <ul>
            <li>Billed monthly</li>
            <li>Renews at $25/month</li>
            <li>Annual Commitment*</li>
          </ul>
        </Plan>
        <Plan>
          <PlanTitle>ANNUAL</PlanTitle>

          <PlanButton onClick={onPriceClick(YEARLY_PLAN, 0)}>
            <PlanCost>$240</PlanCost>
          </PlanButton>

          <ul>
            <li>20% off | Save $60.00</li>
            <li>Renews at $240</li>
            <li>Annual Commitment*</li>
          </ul>
        </Plan>
      </Plans>
    </Root>
  )
}

const Root = styled.div`
  padding: 20px;

  li {
    font-size: 16px;
    margin-bottom: 4px;
  }

  ul {
    margin-top: 24px;
  }
`

const Plans = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
`

const Plan = styled.div`
  width: 50%;
  padding: 20px;
`

const PlanTitle = styled.div`
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 12px;
`

const PlanButton = styled.a`
  border-radius: 100px;
  padding: 12px;
  display: block;
  border: 1px solid #ab47bc;
  text-align: center;
  color: #ab47bc;
  transition: all 250ms;

  &:hover {
    border: 1px solid #ab47bc;
    background: #ab47bc;
    color: white;
  }
`

const PlanCost = styled.div`
  font-size: 18px;
  font-weight: 600;
`

export default PlanSelections
