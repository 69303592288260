import React, { useState } from 'react'
import styled from '@emotion/styled'
import { StepComponent } from 'components/modals/StepModal'
import { useMatchedOrg } from './useMatchedOrg'
import { toast } from 'react-toastify'
import { useLazyApi } from 'hooks/useApi'
import { useCreateUserToOrg } from 'data/hooks'
import { UserRole } from 'generated/graphql'

export const OrganizationSlide = ({ onSkip, onFinish }: StepComponent) => {
  const { matchedOrg } = useMatchedOrg()
  const [isFinished, setIsFinished] = useState(false)
  const createUserToOrg = useCreateUserToOrg()
  const [requestInviteEmail] = useLazyApi('company/request')

  if (!matchedOrg) return null

  useMatchedOrg
  const usersInOrg = matchedOrg.usersToOrganizations

  const addToOrg = async () => {
    if (usersInOrg.length === 0) {
      await createUserToOrg.mutateAsync({
        organizationId: matchedOrg.id,
        role: UserRole.Owner,
      })
    } else {
      await requestInviteEmail({ organizationId: matchedOrg.id })
      toast.success(
        `An admin from ${matchedOrg.name} will review your request to let you in. You’ll be notified upon acceptance.`
      )
    }

    setIsFinished(true)
  }

  const logo = matchedOrg?.details.logo

  return (
    <Root>
      <Title>We found your firm&rsquo;s workspace</Title>
      <Subtitle>Your email matched an authorized firm domain.</Subtitle>
      <Box>
        <Logo src={logo} />
        <Name>{matchedOrg.name}</Name>
        <Type>{matchedOrg.type}</Type>
      </Box>

      {isFinished ? (
        <Text>
          {usersInOrg.length === 0
            ? `You have been added to {matchedOrg?.name}`
            : `An admin from ${matchedOrg.name} will review your request to let you in. You’ll be notified upon acceptance.`}
        </Text>
      ) : (
        <Text>
          Click to add this workspace. You can choose between your organizations in the main menu.
        </Text>
      )}

      <ButtonWrapper>
        {isFinished ? (
          <ActionButton onClick={onFinish}>Get Started</ActionButton>
        ) : (
          <>
            <SkipButton onClick={onSkip}>Skip</SkipButton>
            <ActionButton onClick={addToOrg}>Join</ActionButton>
          </>
        )}
      </ButtonWrapper>
    </Root>
  )
}

const Root = styled.div`
  padding: 28px;
  text-align: center;
`

const Title = styled.h1`
  color: #4d4d4d;
  font-size: 28px;
  margin-bottom: 4px;
`

const Subtitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: #707070;
`

const Box = styled.div`
  background: white;
  border-radius: 6px;
  padding: 16px 16px 12px;
  min-width: 300px;
  display: inline-block;
  margin-bottom: 24px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
`

const Logo = styled.img`
  max-width: 200px;
  width: 100%;
`

const Name = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 8px;
  color: #39393a;
`

const Type = styled.div``

const Text = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #707070;
`

const ButtonWrapper = styled.div`
  text-align: right;
  padding-top: 20px;
  border-top: 1px solid #c6c6c6;
`

const SkipButton = styled.button`
  background: transparent;
  border: none;
  font-weight: 500;
  font-size: 22px;
  color: #505050;
`

const ActionButton = styled.button`
  margin-left: 40px;
  border: none;
  background: #8774d8;
  color: white;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 22px;
  border-radius: 100px;
`

export default OrganizationSlide
