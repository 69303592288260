import SalesModal from './SalesModal'
import { fixCurrencyDisplay } from 'services/helper'
import { Product } from 'interfaces'

interface Props {
  isShowing: boolean
  toggleModal: () => void
  product?: Product
  freeTrialDays?: number
  features?: string[]
  accountId?: string
  onClick: () => void
}

function TestDriveModal({
  isShowing,
  toggleModal,
  product,
  features,
  freeTrialDays,
  // accountId,
  onClick,
}: Props) {
  if (!product) return null

  const highlights = []

  if (product.excerpt && product.excerpt.length > 0) {
    highlights.push({
      name: 'Description',
      description: product.excerpt,
    })
  }

  if (product.bestFor && product.bestFor.length > 0) {
    highlights.push({
      name: 'Best For',
      description: product.bestFor?.map(({ name }) => name).join(', ') || '',
    })
  }

  if (product.practiceGroups && product.practiceGroups.length > 0) {
    highlights.push({
      name: 'Practice Group',
      description: product.practiceGroups?.map(({ name }) => name).join(', ') || '',
    })
  }

  const hasFreeTrial = !!freeTrialDays

  let title = `Purchase ${product.name}`
  let subtitle = `Price from ${fixCurrencyDisplay(parseInt(product.details.startingPrice, 10))}/mo`
  let buttonText = 'Purchase'
  let noteText = ''

  if (hasFreeTrial) {
    title = `${freeTrialDays} Day Free Trial ${product.name}`
    subtitle = `Price after trial: from ${fixCurrencyDisplay(
      parseInt(product.details.startingPrice, 10)
    )}/mo`
    buttonText = 'Start Trial'
    noteText =
      'Serious buyers only. Card required for test drive. Cancel anytime before trial ends.'
  }

  return (
    <SalesModal
      isShowing={isShowing}
      toggleModal={toggleModal}
      title={title}
      subtitle={subtitle}
      iconImage={product.details.squareLogoUrl}
      videoUrl={product.details.demoVideo || ''}
      imageUrl={product.details.slides?.[0] || ''}
      features={{
        title: `Everything you get with your purchase`,
        items: features || [],
      }}
      noteText={noteText}
      highlights={highlights}
      buttonText={buttonText}
      onClick={onClick}
    />
  )
}

export default TestDriveModal
