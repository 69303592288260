import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { useAppMetadata } from 'data/viewer/useAppMetadata'
import { useSwitchOrg } from 'data/viewer/useSwitchOrg'
import { OrganizationType, UserRole } from 'generated/graphql'

interface Props {
  id: string
  name: string
  type: OrganizationType
  role: UserRole
  logo: string
}

const DOMAIN =
  process.env.NODE_ENV === 'development'
    ? `https://ecosystem.theorem.local:3300`
    : 'https://ecosystem.theoremlegal.com'

const OrgBlock = ({ id, name, type, role, logo }: Props) => {
  const switchOrg = useSwitchOrg()
  const { data: appMetadata } = useAppMetadata()

  const hideVendorGettingStarted = appMetadata?.hideVendorGettingStarted
  const hideLawfirmGettingStarted = appMetadata?.hideLawfirmGettingStarted
  const hideAssocGettingStarted = appMetadata?.hideAssocGettingStarted
  const hideCompanyGettingStarted = appMetadata?.hideCompanyGettingStarted

  const selectOrg = async () => {
    let redirectUrl = DOMAIN

    const isVendorGettingStarted = type === OrganizationType.Vendor && !hideVendorGettingStarted
    const isLawfirmGettingStarted = type === OrganizationType.Lawfirm && !hideLawfirmGettingStarted
    const isAssocGettingStarted = type === OrganizationType.Association && !hideAssocGettingStarted
    const isCompanyGettingStarted = type === OrganizationType.Company && !hideCompanyGettingStarted

    if (
      isVendorGettingStarted ||
      isLawfirmGettingStarted ||
      isAssocGettingStarted ||
      isCompanyGettingStarted
    ) {
      redirectUrl = `${redirectUrl}/getting-started`
    } else {
      if (
        type === OrganizationType.Association &&
        (role === UserRole.Owner || role === UserRole.Admin)
      ) {
        redirectUrl = `${redirectUrl}/admin`
      }
    }

    switchOrg.mutate(id, {
      onSuccess: () => (window.location.href = redirectUrl),
    })
  }

  return (
    <Root onClick={selectOrg}>
      <LogoBlock logo={logo} />
      <ContentWrapper>
        <Name>{name}</Name>
        <BottomContent>
          <AssociationType>{type.toLowerCase()}</AssociationType>
          {/*

          <MemberCount>
            {memberCount} member{memberCount > 1 && 's'}
          </MemberCount>
          */}
        </BottomContent>
      </ContentWrapper>
      <ArrowIcon name="arrow-right" />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
  align-items: center;
  background: white;
  transition: background 200ms;
  cursor: pointer;

  &:hover {
    background: #f8f8f8;
  }

  &:last-of-type {
    border-bottom: 0;
  }
`

const ContentWrapper = styled.div`
  margin-left: 24px;
`

const Name = styled.div`
  color: #1f2324;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.02rem;
  margin-bottom: 8px;
`

const BottomContent = styled.div``

const AssociationType = styled.span`
  text-transform: capitalize;
  color: #3b4143;
  font-size: 16px;
`

const ArrowIcon = styled(Icon)`
  margin-left: auto;
  font-size: 14px;
`

const LogoBlock = styled.div<{ logo: string }>`
  border-radius: 4px;
  background-image: url(${({ logo }) => logo});
  background-color: ${({ logo }) => (logo ? 'white' : '#e1e1e1')};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 52px;
  height: 52px;
`

export default OrgBlock
