import React from 'react'
import { useField } from 'formik'
import styled from '@emotion/styled'
import { COUNTRY_CODES } from 'model/static'
import { FormLabel, FieldGroup, InputStyle } from './styles'

interface Props {
  name: string
  disabled?: boolean
}

function CountrySelect(props: Props) {
  const [field, , helpers] = useField(props)

  const onChange = (e: any) => {
    helpers.setValue(e.target.value)
  }

  return (
    <Root>
      <FormLabel>Country</FormLabel>
      <SelectInput value={field.value} onChange={onChange}>
        {COUNTRY_CODES.map((country, index) => (
          <option key={index} value={country.ccode}>
            {country.cname}
          </option>
        ))}
      </SelectInput>
    </Root>
  )
}

const Root = styled.div`
  ${FieldGroup}
`

const SelectInput = styled.select`
  ${InputStyle}
`

export default CountrySelect
