import { library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'

import {
  faHome,
  faUser,
  faCog,
  faSpinner,
  faEdit,
  faClock,
  faDollarSign,
  faCheck,
  faStar,
  faHandHoldingUsd,
  faBolt,
  faSortDown,
  faSort,
  faSortUp,
  faGhost,
  // Editor Icons
  faHeading,
  faQuestionCircle,
  faQuoteRight,
  faListOl,
  faLightbulb,
  faListUl,
  faBold,
  faItalic,
  faChevronRight,
  faChevronLeft,
  faBullhorn,
  faUnderline,
  faChalkboardTeacher,
  faMinus,
  faPlus,
  faLaptopCode,
  faChartBar,
  faEnvelope,
  faHandshake,
  faQrcode,
  faSitemap,
  faCodeBranch,
  faCaretDown,
  faCaretRight,
  faChartPie,
  faTrophy,
  faBrain,
  faCommentsDollar,
  faFileInvoiceDollar,
  faCrown,
  faArrowCircleRight,
  faTag,
  faFilePdf,
  faTimes,
  faCubes,
  faIdBadge,
  faMedal,
  faPaperPlane,
  faCheckCircle,
  faAngleLeft,
  faAngleDown,
  faEllipsisH,
  faEllipsisV,
  faSearch,
  faList,
  faExternalLinkAlt,
  faAngleRight,
  faTasks,
  faEye,
  faHourglassHalf,
  faStore,
  faExclamationTriangle,
  faLayerGroup,
  faToolbox,
  faTools,
  faArrowRight,
  faLevelUpAlt,
  faUserCog,
  faProjectDiagram,
  faBell,
  faExchangeAlt,
  faShoppingCart,
  faLandmark,
  faBuilding,
  faUserTie,
  faDesktop,
  faUserPlus,
  faPenToSquare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

export const fontAwesomeIconList: any[] = [
  faHome,
  faUser,
  faSearch,
  faCog,
  faEdit,
  faSpinner,
  faClock,
  faDollarSign,
  faCheck,
  faBolt,
  faGhost,
  faStar,
  faLightbulb,
  faChalkboardTeacher,
  // Editor Icons
  faQuestionCircle,
  faHeading,
  faChartBar,
  faQuoteRight,
  faListOl,
  faList,
  faBold,
  faItalic,
  faBullhorn,
  faBrain,
  faUnderline,
  faSortDown,
  faSort,
  faSortUp,
  faMinus,
  faPlus,
  faLaptopCode,
  faEnvelope,
  faHandshake,
  faHandHoldingUsd,
  faListUl,
  faSitemap,
  faQrcode,
  faCodeBranch,
  faCaretDown,
  faCaretRight,
  faChartPie,
  faTrophy,
  faCommentsDollar,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faFileInvoiceDollar,
  faCrown,
  faArrowCircleRight,
  faTag,
  faFilePdf,
  faTimes,
  faCubes,
  faIdBadge,
  faMedal,
  faPaperPlane,
  faCheckCircle,
  faExternalLinkAlt,
  faEllipsisH,
  faEllipsisV,
  faTasks,
  faEye,
  faChevronRight,
  faChevronLeft,
  faHourglassHalf,
  faStore,
  faExclamationTriangle,
  faLayerGroup,
  faToolbox,
  faTools,
  faArrowRight,
  faLevelUpAlt,
  faUserCog,
  faProjectDiagram,
  faBell,
  faExchangeAlt,
  faShoppingCart,
  faLandmark,
  faBuilding,
  faUserTie,
  faDesktop,
  faUserPlus,
  faLinkedinIn,
  faTwitter,
  faPenToSquare,
  faTrash,
]

export const initFontAwesome = () => library.add(...fontAwesomeIconList)
