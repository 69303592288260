import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import AppMarketplaceImage from 'assets/menu/app-marketplace.png'
import LawfirmImage from 'assets/menu/lawfirm-marketplace.svg'
import SolutionsImage from 'assets/menu/solutions.svg'
import AccSvg from 'assets/networks/acc/acc-square.svg'
import ClocSvg from 'assets/networks/cloc/cloc-blue-icon.svg'
import EltaPng from 'assets/networks/elta/elta-pink.png'
import InhouseConnectSvg from 'assets/networks/in-house-connect/blue-logo.jpg'
import { useOrg } from 'data/hooks'
import { OrganizationType } from 'generated/graphql'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'

const MarketplaceMenu = () => {
  const { organization } = useOrg()
  const marketplaceUrl = useMarketplaceUrl()
  const { user: auth0User } = useAuth0()

  const domain =
    process.env.NODE_ENV === 'development'
      ? `https://ecosystem.theorem.local:3300`
      : 'https://ecosystem.theoremlegal.com'
  let loginUrl = `${domain}/api/auth/login?`

  if (auth0User?.org_id) {
    loginUrl = `${loginUrl}organization=${auth0User?.org_id}&`
  }

  const organizationType = organization?.type

  return (
    <Root>
      <Wrapper>
        {organizationType === OrganizationType.Company ||
        organizationType === OrganizationType.Association ? (
          <Container>
            <AnchorItem href={marketplaceUrl}>
              <Icon src={AppMarketplaceImage} />
              <Right>
                <Name>App Marketplace</Name>
                <Text>Search our LegalTech App Store</Text>
              </Right>
            </AnchorItem>
            <AnchorItem href={`${loginUrl}returnTo=/lawfirms`}>
              <Icon src={LawfirmImage} />
              <Right>
                <Name>Law Firm Marketplace</Name>
                <Text>Discover Firm Innovation</Text>
              </Right>
            </AnchorItem>
            <AnchorItem href={`${loginUrl}returnTo=/services`}>
              <Icon src={SolutionsImage} />
              <Right>
                <Name>Solutions Directory</Name>
                <Text>Find your perfect service provider match</Text>
              </Right>
            </AnchorItem>
          </Container>
        ) : (
          <Container>
            <AnchorItem href={`${loginUrl}returnTo=/a/acc`}>
              <Icon src={AccSvg} />
              <Right>
                <Name>ACC</Name>
                <Text>Association of Corporate Counsel</Text>
              </Right>
            </AnchorItem>
            <AnchorItem href={`${loginUrl}returnTo=/a/cloc`}>
              <Icon src={ClocSvg} />
              <Right>
                <Name>CLOC</Name>
                <Text>Corporate Legal Ops Consortium</Text>
              </Right>
            </AnchorItem>
            <AnchorItem href={`${loginUrl}returnTo=/a/in-house-connect`}>
              <Icon src={InhouseConnectSvg} />
              <Right>
                <Name>IHC</Name>
                <Text>In-House Connect</Text>
              </Right>
            </AnchorItem>
            <AnchorItem href={`${loginUrl}returnTo=/a/elta`}>
              <Icon src={EltaPng} />
              <Right>
                <Name>ELTA</Name>
                <Text>European Legal Tech Association</Text>
              </Right>
            </AnchorItem>
          </Container>
        )}
      </Wrapper>
    </Root>
  )
}

const Root = styled.div<{ isShow?: boolean }>`
  display: none;
  position: absolute;
  top: ${({ theme }) => theme.topBarHeight};
  left: 0;
  padding-top: 8px;
  min-width: 340px;
  text-align: left;
`

const Icon = styled.img`
  max-width: 52px;
  width: 100%;
`

const Right = styled.div`
  margin-left: 12px;
  white-space: nowrap;
`

const Name = styled.div`
  font-weight: 600;
  letter-spacing: 0.02rem;
`

const Text = styled.div`
  font-weight: 400;
  font-size: 13px;
`

const AnchorItem = styled.a`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  color: #585a5f;
  padding: 8px 12px;
  transition: 250ms background, 250ms color;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;

  &:hover {
    background: #eee;
    color: #222;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  ${({ theme }) => theme.breakpoint.mobile} {
    padding-left: 8px;
  }
`

const Wrapper = styled.div`
  min-width: 340px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid #d5d5d5;

  ${({ theme }) => theme.breakpoint.mobile} {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    min-width: none;
  }
`

const Container = styled.div`
  border-radius: 6px;
  overflow: hidden;

  ${({ theme }) => theme.breakpoint.mobile} {
    border-radius: 0;
  }
`

export default MarketplaceMenu
