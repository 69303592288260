import styled from '@emotion/styled'
import popularApps from './popularApps'
import AppBox from './AppBox'

function PreSelectedAppBoxes({ hideTitle = false }: { hideTitle?: boolean }) {
  return (
    <Root>
      {!hideTitle && (
        <Title>
          Select from <strong>popular apps</strong> below
        </Title>
      )}
      <Apps>
        {popularApps.map((app) => (
          <AppBox key={app.id} {...app} />
        ))}
      </Apps>
    </Root>
  )
}

const Root = styled.div`
  margin-top: 32px;
`

const Title = styled.div`
  font-size: 28px;

  margin-bottom: 30px;
`

const Apps = styled.div`
  display: grid;
  grid-gap: 30px 30px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
`

export default PreSelectedAppBoxes
