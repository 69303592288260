import React from 'react'
import { Formik, Form } from 'formik'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { FormField, TextInput, TextArea } from 'components/theme/form'
import Button from 'components/theme/Button'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { extractPictureUrl } from 'services/image'
import HelpPopupContent from 'components/HelpPopContent'
import ExcerptDescription from 'components/ExcerptDescription'
import * as yup from 'yup'
import PreviewBox from 'components/global/PreviewBox'
import PromptIfDirty from 'components/forms/PromptIfDirty'
import { useUpdateBasicInfo } from './useUpdateBasicInfo'

const defaultValues = {
  name: '',
  url: '',
  excerpt: '',
  logoFile: [],
  squareLogoFile: [],
}

const validationSchema = yup.object().shape({
  name: yup.string().min(1, 'Too Short').required('Required'),
  excerpt: yup.string().max(135, 'Must be 135 characters or less').required('Required').nullable(),
  url: yup.string().min(1, 'Required').required('Required').nullable(),
})

function BasicInfoForm({
  initialValues = defaultValues,
  product,
  submitButtonText = 'NEXT (SELECT CATEGORY)',
  onComplete,
  isService = false,
  removePreview = false,
}: any) {
  const updateBasicInfo = useUpdateBasicInfo(product)

  const onSubmit = async ({ name, url, excerpt, logoFile, squareLogoFile }: any) => {
    const logoUrl = await extractPictureUrl(logoFile)
    const squareLogoUrl = await extractPictureUrl(squareLogoFile)

    updateBasicInfo.mutate(
      {
        productId: product?.id,
        name,
        url,
        excerpt,
        logoUrl,
        squareLogoUrl,
        type: initialValues.productType,
      },
      {
        onSuccess: (productId) => {
          onComplete(productId)
        },
      }
    )
  }

  return (
    <Root>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ values, isSubmitting, isValid }) => (
          <>
            {!removePreview && (
              <PreviewBox
                name={values.name}
                excerpt={values.excerpt}
                squareLogo={values.squareLogoFile}
                logo={values.logoFile}
              />
            )}
            <FormRoot>
              <PromptIfDirty />
              <FormField>
                <TextInput
                  name="name"
                  label={isService ? 'Service Name' : 'Product Name'}
                  placeholder={isService ? 'Service Name' : 'Product Name'}
                  icon="user"
                  isSubmitting={isSubmitting}
                  required
                />
              </FormField>
              <FormField>
                <TextInput
                  name="url"
                  label="Website URL"
                  placeholder="https://www.example.com"
                  icon="linkify"
                  isSubmitting={isSubmitting}
                  required
                />
              </FormField>
              <FormField>
                <TextArea
                  label="Short Description"
                  name="excerpt"
                  description={<ExcerptDescription description={values.excerpt} maxLength={135} />}
                  placeholder="Short intro description"
                  isSubmitting={isSubmitting}
                  required
                />
              </FormField>
              <FormField>
                <SplitColumn>
                  <WideLogo>
                    <UploadSingleAsset
                      label="Wide Logo"
                      name="logoFile"
                      selectedImage={values.logoFile}
                      helpContent={<HelpPopupContent />}
                      styles={css`
                        max-width: none;
                      `}
                      fluidWidth
                    >
                      Upload Wide Logo
                    </UploadSingleAsset>
                  </WideLogo>
                  <SmallLogo>
                    <UploadSingleAsset
                      label="Square Logo"
                      name="squareLogoFile"
                      helpContent={<HelpPopupContent />}
                      selectedImage={values.squareLogoFile}
                      required
                    >
                      Square Logo
                    </UploadSingleAsset>
                  </SmallLogo>
                </SplitColumn>
              </FormField>
              <FormField>
                <ButtonContainer>
                  <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                    {submitButtonText}
                  </Button>
                </ButtonContainer>
              </FormField>
            </FormRoot>
          </>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
`

const FormRoot = styled(Form)`
  max-width: 760px;
  width: 100%;
  margin-bottom: 25px;
`

const SplitColumn = styled.div`
  display: flex;
`

const WideLogo = styled.div`
  flex: 1;
  margin-right: 20px;
`

const SmallLogo = styled.div`
  width: 150px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
  text-align: right;
`

export default BasicInfoForm
