import { Routes, Route } from 'react-router-dom'
import TechstackManagement from 'views/lawfirms/TechstackManagement'
import { WrappedEditorList, WrappedStoryEditor } from 'components/UserStories'
import { RfpStepper } from 'views/lawfirms/Rfp'
import RfpSuccess from 'views/lawfirms/Rfp/RfpSuccess'
import TestDrive from 'views/lawfirms/TestDrive'
import TestDriveUserInfo from 'views/lawfirms/TestDrive/TestDriveUserInfo'
import TestDriveCheckout from 'views/lawfirms/TestDrive/TestDriveCheckout'
import TestDriveSuccess from 'views/lawfirms/TestDrive/TestDriveSuccess'
import Settings from 'views/setup/settings'
import DiscoverOrganizations from 'components/DiscoverOrganization'
import AssociationTechstack from 'views/associations/AssociationTechstack'
import AssociationApps from 'views/associations/AssociationApps'
import AssociationGettingStarted from 'components/marketing/GettingStarted/AssociationGettingStarted'
import AssocAdmin from 'views/associations/AssocAdmin'
import RedirectOut from 'components/association/RedirectOut'
import Techhub from 'views/Techhub'
import RootContent from 'components/containers/RootContent'
import EditHome from 'views/associations/EditHome'

function AssociationRoutes() {
  return (
    <Routes>
      <Route element={<RootContent />}>
        <Route path="/" element={<RedirectOut />} />
        <Route path="your-apps" element={<AssociationApps />} />
        <Route path="techstack-management" element={<TechstackManagement />} />
        <Route path="edit-techstack" element={<AssociationTechstack />} />
        <Route path="edit-home" element={<EditHome />} />
        <Route path="admin/*" element={<AssocAdmin />} />
        <Route path="user-stories/:storyId/edit" element={<WrappedStoryEditor />} />
        <Route path="user-stories" element={<WrappedEditorList />} />
        <Route path="user-stories/create" element={<WrappedStoryEditor />} />
        <Route path="techhub" element={<Techhub />} />
        <Route path="rfp" element={<RfpStepper />} />
        <Route path="rfp-success" element={<RfpSuccess />} />
        <Route path="test-drive/plan-details" element={<TestDriveUserInfo />} />
        <Route path="test-drive/checkout-success" element={<TestDriveSuccess />} />

        <Route path="test-drive/checkout" element={<TestDriveCheckout />} />

        <Route path="test-drive" element={<TestDrive />} />

        <Route path="discover-organizations" element={<DiscoverOrganizations />} />

        <Route path="settings/*" element={<Settings />} />
        <Route path="getting-started" element={<AssociationGettingStarted />} />
      </Route>

      {/*
      <PrivateRoute
        title="User Story Editor"
        wrapInSidebarTemplate
        path="user-stories/:storyId/edit"
        element={<WrappedStoryEditor />}
      />

      <PrivateRoute
        title="User Story Editor"
        wrapInSidebarTemplate
        exact
        path="user-stories"
        component={WrappedEditorList}
      />

      <PrivateRoute
        title="User Story Editor"
        wrapInSidebarTemplate
        exact
        path="user-stories/create"
        component={WrappedStoryEditor}
      />

      <PrivateRoute title="Techhub" wrapInSidebarTemplate path="techhub" component={Techhub} />

      <PrivateRoute
        title="Request For Proposal"
        wrapInSidebarTemplate
        path="rfp"
        component={RfpStepper}
      />

      <PrivateRoute title="RFP" wrapInSidebarTemplate path="rfp-success" component={RfpSuccess} />
      <PrivateRoute
        title="Test Drive"
        path="test-drive/plan-details"
        component={TestDriveUserInfo}
      />

      <PrivateRoute
        title="Test Drive"
        path="test-drive/checkout-success"
        component={TestDriveSuccess}
      />

      <PrivateRoute title="Test Drive" path="test-drive/checkout" component={TestDriveCheckout} />

      <PrivateRoute
        title="Test Drive"
        wrapInSidebarTemplate
        path="test-drive"
        component={TestDrive}
      />

      <PrivateRoute
        exact
        title="Request access to an organization"
        path="discover-organizations"
        wrapInSidebarTemplate
        component={DiscoverOrganizations}
      />

      <PrivateRoute wrapInSidebarTemplate path="tech-stack" component={TechStack} />
      <PrivateRoute wrapInSidebarTemplate path="settings" component={Settings} />
      <PrivateRoute
        exact
        title="Getting Started"
        path="getting-started"
        wrapInSidebarTemplate
        component={AssociationGettingStarted}
      />
      */}
    </Routes>
  )
}

export default AssociationRoutes
