export default [
  {
    id: 780,
    title: 'NetDocuments',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/NetDocuments.png',
  },
  {
    id: 934,
    title: 'iManage',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/iManage.png',
  },
  {
    id: 787,
    title: 'Slack',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/Slack.png',
  },
  {
    id: 773,
    title: 'Microsoft Teams',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/Microsoft%20Teams.png',
  },
  {
    id: 774,
    title: 'Microsoft Office 365',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/Microsoft%20Office%20365.png',
  },
  {
    id: 688,
    title: 'HighQ',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/HighQ%20Dataroom.png',
  },
  {
    id: 707,
    title: 'Bloomberg Law',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/Bloomberg%20Law.png',
  },
  {
    id: 740,
    title: 'Westlaw Edge',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/Westlaw.png',
  },
  {
    id: 750,
    title: 'Lexis Advance',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/Lexis%20Advance.png',
  },
  {
    id: 790,
    title: 'DocuSign',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/DocuSign.png',
  },
  {
    id: 770,
    title: 'Salesforce',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/Salesforce.png',
  },
  {
    id: 779,
    title: 'Asana',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/Asana.png',
  },
  /* {
    id: 943,
    title: 'Tessian',
    logo: 'https://theorem-public-files.s3.us-west-2.amazonaws.com/vendors/Tessian.png',
  }, */
]
