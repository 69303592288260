import styled from '@emotion/styled'
import { useWizardModal } from './WizardModalContext'

const WizardMain = () => {
  const { step: Component } = useWizardModal()

  return <Root>{Component}</Root>
}

const Root = styled.div`
  padding: 20px;
  min-width: 580px;
`

export default WizardMain
