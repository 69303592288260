import { useEffect, useState } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure } from 'react-instantsearch-hooks-web'
import { FullPageLoading } from 'components/PulsatingLoader'
import { SelectTechStackProvider } from './SelectTechStackContext'
import PreSelectedAppBoxes from './PreSelectedAppBoxes'
// import SelectedApps from './SelectedApps'
import SubmitTechStackButton from './SubmitTechStackButton'
// import AppSelection from './AppSelection'
import SearchBar from './SearchBar'
import styled from '@emotion/styled'
import Toggle from 'components/theme/form/Toggle'
import CsvActions from '../CsvTechstack/CsvActions'
import { useProductsByIds } from 'data/hooks'

const algoliaClient = algoliasearch('0K3B9U0LC8', 'c597a04244e1170d74f9555a71db711e')

const searchClient: any = {
  ...algoliaClient,
  search(requests: any) {
    if (requests.every(({ params }: any) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      })
    }

    return algoliaClient.search(requests)
  },
}

function SelectTechStack({
  initialSelectedAppIds,
  orgTechstack = [],
  onSubmit,
  errorList = [],
  csvLoading = false,
  onSelect = (id: any) => console.warn(`onSelect wasn't used ${id}`),
  isPersonalTechstack = false,
  hideRecommendations = false,
  clearCsv = () => console.warn('clearCsv was not provided to <SelectTechStack />'),
  plainSearch = false,
  showAdvancedView = false,
  toggleAdvancedView = () => console.warn('SelectTechStack is not setup to toggleAdvancedView'),
  fileAction = (file: any) =>
    console.warn(`the uploader was run but no action was provided for the file: ${file}`),
}: any) {
  const { products, isLoading } = useProductsByIds(initialSelectedAppIds)
  const [isDataSet, setIsDataSet] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (isLoading || isDataSet) return
    setIsDataSet(true)
  }, [isLoading])

  if (!isDataSet) return <FullPageLoading />

  const initialSelectedApps =
    products &&
    products.map((product: any) => {
      return {
        id: product.id,
        title: product.name,
        logo: product.details.squareLogoUrl,
      }
    })

  return (
    <Root>
      <SelectTechStackProvider
        initialSelectedApps={initialSelectedApps}
        orgTechstack={orgTechstack}
      >
        <InstantSearch
          searchClient={searchClient /*searchClient */}
          stalledSearchDelay={2000}
          indexName="theorem_ecosystem"
        >
          <Configure hitsPerPage={12} distinct />
          <SearchBar withoutSelections={plainSearch} onSelect={onSelect} />
          <Flex justifyRight={hideRecommendations}>
            {!hideRecommendations && (
              <h2 style={{ margin: 0 }}>
                Select from our <strong>popular apps</strong> below
              </h2>
            )}

            {hideRecommendations && toggleAdvancedView && (
              <ToggleWrapper>
                <Toggle on={showAdvancedView} title="Show Advanced" toggle={toggleAdvancedView} />
              </ToggleWrapper>
            )}

            {hideRecommendations && fileAction && (
              <CsvActions
                clearCsv={clearCsv}
                fileAction={fileAction}
                errorList={errorList}
                csvLoading={csvLoading}
              />
              // <UploadButton accept=".csv" fileAction={fileAction}>Upload .csv</UploadButton>
            )}

            <SubmitTechStackButton onSubmit={onSubmit} />
          </Flex>
          {!hideRecommendations && <PreSelectedAppBoxes hideTitle />}
        </InstantSearch>
        {/* <SelectedApps /> */}
      </SelectTechStackProvider>
      {!isPersonalTechstack && !hideRecommendations && (
        <OrgMessage>
          <ColorLegend /> <span> Your organization's techstack</span>
        </OrgMessage>
      )}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  z-index: 0;
`

const ToggleWrapper = styled.div`
  margin-right: 32px;
`

const OrgMessage = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
`

const ColorLegend = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #dccce6;
  margin-right: 12px;
  transform: translateY(-1px);
  z-index: 0;
`

const Flex = styled.div<{ justifyRight: boolean }>`
  margin-top: 20px;
  display: flex;
  justify-content: ${({ justifyRight }) => (justifyRight ? 'flex-end' : 'space-between')};
  align-items: center;
`

export default SelectTechStack
