import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { Button } from 'components/theme'
import { TextInput, FormField } from 'components/theme/form'
import { Formik, Form } from 'formik'
import axios from 'axios'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { logEvent } from '@theoremlts/theorem-design'
import { isUsingProductionServer } from 'services/helper'

const getAudience = (isLocal = false) => {
  if (process.env.NODE_ENV !== 'development' && isUsingProductionServer) {
    return 'https://m0qbfdtry8.execute-api.us-west-2.amazonaws.com/dev'
  }

  if (isLocal) {
    return 'http://localhost:3001'
  }

  return 'https://qrr3vzquxi.execute-api.us-west-2.amazonaws.com/staging'
}

const validationSchema = yup.object().shape({
  email: yup.string().email().required('Required'),
})

function VerifyEmail() {
  const { logout } = useAuth0()
  const [isResendScreen, setIsResendScreen] = useState(false)

  const switchToResendScreen = () => {
    setIsResendScreen(true)
  }

  const onSubmitResend = async ({ email }: { email: string}) => {
    try {
      await axios({
        url: `${getAudience()}/auth0/resend-verification`,
        method: 'post',
        data: { email },
      })
      toast.success('Resend Email has been sent')
      setIsResendScreen(false)
    } catch (error) {
      toast.error('There was an error on resend email.')
    }
  }

  return (
    <Root>
      <LogoutButton onClick={() => {
        logEvent('log_out')
        logout()
      }} type="button">
        Logout
      </LogoutButton>
      {isResendScreen ? (
        <Box isTextLeft>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={onSubmitResend}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <p>Confirm email for resend verification email</p>
                <FormField>
                  <TextInput label="Email" name="email" placeholder="name@email.com" />
                </FormField>
                <Button type="submit" disabled={isSubmitting || !isValid}>
                  Resend
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      ) : (
        <VerifyBox>
          <Box>
            <IconWrapper>
              <EmailIcon name="envelope" />
            </IconWrapper>
            <Title>Verify your email address</Title>
            <Line />
            <Text>
              In order to start using your Theorem account, you need to confirm your email address.
            </Text>
          </Box>
          <Resend>
            Didn't recieve the verification email? <a onClick={switchToResendScreen}>Resend</a>
          </Resend>
        </VerifyBox>
      )}
    </Root>
  )
}

const Root = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #efefef;
  position: relative;
`

const VerifyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Box = styled.div<{ isTextLeft?: boolean }>`
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  padding: 40px;
  border-radius: 6px;
  text-align: ${({ isTextLeft }) => (isTextLeft ? 'left' : 'center')};
`

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
`

const Text = styled.p`
  font-size: 16px;
  color: #6f717d;
  margin-top: 20px;
`

const EmailIcon = styled(Icon)`
  font-size: 45px;
  color: white;
`

const IconWrapper = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin: 0 auto;
  background: #9013fe;
`

const Line = styled.hr`
  border: none;
  border-bottom: 1px solid #ddd;
`

const LogoutButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const Resend = styled.div`
  padding-top: 12px;
`

export default VerifyEmail
