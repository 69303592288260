import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { GqlGetOrgByIdQuery } from 'generated/graphql'

export const useOrgById = (id?: string) => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['company', id],
    async () => {
      const response = await request<GqlGetOrgByIdQuery>(GET_ORGANIZATION_BY_ID, { id })

      return response.organization
    },
    {
      enabled: !!id,
    }
  )

  return { company: data, ...other }
}

const GET_ORGANIZATION_BY_ID = gql`
  query GetOrgById($id: UUID!) {
    organization(id: $id) {
      id
      name
      slug
      type
      details
      domain
      createdAt
      excerpt
      description
      testMode
      subtype
      termToOrganizations {
        term {
          id
          name
          slug
          taxonomyId
          updatedAt
          createdAt
          order
        }
        termId
      }
      termToVendorStackshares {
        id
        term {
          id
          name
          slug
          taxonomyId
        }
        order
      }
      vendorStackshareLogos {
        id
        logo
        name
        order
      }
    }
  }
`
