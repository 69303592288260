import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useLazyApi } from 'hooks/useApi'
import TestDriveForm from './TestDriveForm'
import { useNavigate, useParams } from 'react-router'
import PulsatingLoader from 'components/PulsatingLoader'
import { BoxWrapper } from 'components/theme'
import { Values } from './types'
import { useTestDriveTaxonomy } from './useTestDriveTaxonomy'
import { useViewer } from 'context/viewerContext'
import { useTestDrive, useUpdateTestDrive } from 'data/hooks'

const TestDriveEdit = () => {
  const { accountId } = useViewer()
  const [getProduct] = useLazyApi('stripe/product/retrieve')
  const { testDriveId } = useParams<{ testDriveId: string }>()
  const parsedTestDriveId = testDriveId ? parseInt(testDriveId, 10) : 0
  const { testDrive, isLoading } = useTestDrive(parsedTestDriveId)
  const [defaultValues, setDefaultValues] = useState<Values>()
  const updateTestDrive = useUpdateTestDrive()
  const { updateTermToTaxonomy } = useTestDriveTaxonomy()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoading || !testDrive) return

    const jobTitle = parseJobTitleIds(testDrive.termToTestDrives)
    const practiceGroup = parsePracticeGroupIds(testDrive.termToTestDrives)
    const lawfirmType = parseLawfirmTypeIds(testDrive.termToTestDrives)

    const defaultValues = {
      productId: testDrive.productId,
      priceId: testDrive.details.stripe.production.priceId,
      freeTrialDays: testDrive.details.stripe.freeTrialDays,
      isVendorInVendor: testDrive.details.isVendorInVendor,
      description: testDrive.details.description,
      features: testDrive.details.features,
      practiceGroup,
      jobTitle,
      lawfirmType,
    }

    setDefaultValues(defaultValues)
  }, [isLoading])

  const onSubmit = async (values: Values) => {
    const {
      //productId,
      priceId,
      freeTrialDays,
      features,
      jobTitle,
      practiceGroup,
      lawfirmType,
      description,
      isVendorInVendor,
    } = values

    try {
      const result = await getProduct({ priceId, accountId })

      await updateTermToTaxonomy(jobTitle, defaultValues?.jobTitle || [], parsedTestDriveId)
      await updateTermToTaxonomy(lawfirmType, defaultValues?.lawfirmType || [], parsedTestDriveId)
      await updateTermToTaxonomy(
        practiceGroup,
        defaultValues?.practiceGroup || [],
        parsedTestDriveId
      )

      const stripeProductId = result?.product?.id
      const stripeDetail = { priceId, productId: stripeProductId }
      const details = {
        stripe: {
          test: stripeDetail,
          production: stripeDetail,
          freeTrialDays,
          productName: result?.product?.name,
          accountId,
        },
        description,
        features,
        isVendorInVendor,
      }

      await updateTestDrive.mutateAsync({
        testDriveId: parsedTestDriveId,
        details,
      })

      navigate('../..')
    } catch (error) {
      console.error(error)
    }
  }

  if (!defaultValues) return <PulsatingLoader />

  return (
    <Root>
      <BoxWrapper>
        <Title>Edit Test Drive</Title>
        <TestDriveForm defaultValues={defaultValues} onSubmit={onSubmit} buttonText="Update" />
      </BoxWrapper>
    </Root>
  )
}

const parseJobTitleIds = (terms: any) => {
  const jobTitleIds = terms
    .filter(({ term }: any) => term.taxonomyId === 8)
    .map(({ term }: any) => term.id)

  return jobTitleIds
}

const parsePracticeGroupIds = (terms: any) => {
  const jobTitleIds = terms
    .filter(({ term }: any) => term.taxonomyId === 13)
    .map(({ term }: any) => term.id)

  return jobTitleIds
}

const parseLawfirmTypeIds = (terms: any) => {
  const lawfirmTypeIds = terms
    .filter(({ term }: any) => term.taxonomyId === 17)
    .map(({ term }: any) => term.id)

  return lawfirmTypeIds
}

const Root = styled.div``
const Title = styled.h1``

export default TestDriveEdit
