import { isUsingProductionServer } from 'services/helper'

// Prevalent SIG Core
export const PREVALENT_SIG_CORE_PRODUCT_ID = isUsingProductionServer
  ? 'prod_JLoFhhf7cjCuNE'
  : 'prod_JLoEGHNP1szO5a'
export const PREVALENT_SIG_CORE_PRICE_ID = isUsingProductionServer
  ? 'price_1Ij6dGJl1YuJqgKx3LtTGH5e'
  : 'price_1Ij6bzJl1YuJqgKxdOc6MzwT'

export const PREVALENT_SIG_CORE_FEATURES = [
  'Complete a single self-assessment to share with all of your customers',
  'Industry-standard questionnaires to satisfy customer requirements',
  'Simple interface with built-in workflows',
  'External risk intelligence for insights into potential threats',
]

// Prevalent SIG Lite
export const PREVALENT_SIG_LITE_PRODUCT_ID = isUsingProductionServer
  ? 'prod_JLoI3YY1ZCwc82'
  : 'prod_JLoHp1IUk3kURL'
export const PREVALENT_SIG_LITE_PRICE_ID = isUsingProductionServer
  ? 'price_1Ij6fQJl1YuJqgKxQzX4rjtX'
  : 'price_1Ij6fDJl1YuJqgKxjEL2F19g'
export const PREVALENT_SIG_LITE_FEATURES = [
  'Complete a single self-assessment to share with all of your customers',
  'Industry-standard questionnaires to satisfy customer requirements',
  'Simple interface with built-in workflows',
  'External risk intelligence for insights into potential threats',
]
