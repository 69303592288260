import { forwardRef } from 'react'
import styled from '@emotion/styled'
import { useField } from 'formik'
import ReactDatePicker from 'react-datepicker'
import { Icon } from 'components/theme'
import moment from 'moment'

function DatePicker({ label, ...props }: any) {
  const [, meta, helpers] = useField(props)

  const setDate = (date: any) => {
    helpers.setValue(date)
  }

  const CustomDateButton = forwardRef(({ onClick }: any, ref: any) => (
    <DateButton onClick={onClick} ref={ref}>
      <ClockIcon name="clock" /> {label} | {moment(meta.value).format('MM/DD/YYYY')}
    </DateButton>
  ))

  return (
    <Root>
      <ReactDatePicker
        customInput={<CustomDateButton />}
        selected={meta.value}
        onChange={setDate}
      />
    </Root>
  )
}

const Root = styled.div`
  margin-right: 20px;
`

const DateButton = styled.button`
  display: inline-block;
  padding: 8px 12px;
  color: white;
  border: 2px solid #ab47bc;
  background: #ab47bc;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  transition: color 250ms, background 250ms, border 250ms;

  &:hover {
    border-color: #ab47bc;
    color: #ab47bc;
    background: transparent;
  }
`

const ClockIcon = styled(Icon)`
  margin-right: 8px;
`

export default DatePicker
