import { Formik, Form } from 'formik'
import { Switch, FieldRow, TextInput, Radio } from 'components/theme/form'
import Button from 'components/theme/Button'
import styled from '@emotion/styled'
import SigLiteLogo from 'assets/prevalent/sig-lite.svg'
import SigCoreLogo from 'assets/prevalent/sig-core.svg'
import PrevalentLogoImg from 'assets/prevalent/prevalent-logo.svg'
import * as yup from 'yup'
import Modal, { useModal } from 'components/Modal'
import { useWizardContext } from '../prevalentContext'
import { useViewer } from 'context/viewerContext'
import PulsatingLoader from 'components/PulsatingLoader'

const validationSchema = yup.object().shape({
  email: yup.string().email('Must be a valid email').required('Required'),
})

const InfoStep = () => {
  const { nextStep, getStepData, updateWizardData, loading } = useWizardContext()
  const { showModal, toggle } = useModal()
  const { viewer } = useViewer()

  const step = getStepData()

  const onSuccess = (values: any) => {
    updateWizardData(values)
    nextStep()
  }

  return (
    <Wrapper>
      {loading ? (
        <PulsatingLoader />
      ) : (
        <Formik
          initialValues={{ ...step.formData, email: viewer.email }}
          validationSchema={validationSchema}
          onSubmit={onSuccess}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <FieldRow>
                <TextInput
                  name="email"
                  label="Confirm Email"
                  placeholder="name@company.com"
                  required
                />
              </FieldRow>
              <OfferingContainer>
                <OfferingText>
                  Are you offering E-Discovery, Human Resource, or Translation Services?
                </OfferingText>
                <RadiosWrapper>
                  <RadioWrapper>
                    <Radio name="offering" value="yes" label="yes" />
                  </RadioWrapper>
                  <RadioWrapper>
                    <Radio name="offering" value="no" label="no" />
                  </RadioWrapper>
                </RadiosWrapper>
                <Ask onClick={toggle}>Why do we ask?</Ask>

                <Modal isShowing={showModal} toggleModal={toggle} width="620px">
                  <ModalTitle>
                    Vendors offering E-Discovery, Human Resource or Translation Services
                  </ModalTitle>
                  <ModalContent>
                    <p>
                      are required under the Prevalent Legal Vendor Network (LVN) to complete a SIG
                      Core Level Assessment.
                    </p>
                    <p>
                      Vendors who DO NOT offer these types of services are able to purchase the SIG
                      Lite assessment. By indicating you offer these services SIG Lite will no
                      longer be avaliable for purchase.
                    </p>
                  </ModalContent>
                </Modal>
              </OfferingContainer>
              <FieldRow>
                <Grid>
                  <GridHeaderColumn>Product</GridHeaderColumn>
                  <GridHeaderColumn>Cost</GridHeaderColumn>
                  <GridHeaderColumn>Select</GridHeaderColumn>
                  <GridColumn greyOut={values.offering === 'yes'}>
                    <ProductWrapper>
                      <ProductImage src={SigLiteLogo} />
                    </ProductWrapper>
                  </GridColumn>
                  <GridColumn greyOut={values.offering === 'yes'}>$250</GridColumn>
                  <GridColumn greyOut={values.offering === 'yes'}>
                    <Switch disabled={values.offering === 'yes'} name="sigLite" />
                  </GridColumn>
                  <GridColumn>
                    <ProductWrapper>
                      <ProductImage src={SigCoreLogo} />
                    </ProductWrapper>
                  </GridColumn>
                  <GridColumn>$500</GridColumn>
                  <GridColumn>
                    <Switch name="sigCore" />
                  </GridColumn>
                </Grid>
              </FieldRow>
              <Button type="submit" disabled={!(values.sigCore || values.sigLite) || isSubmitting}>
                Continue
              </Button>
            </Form>
          )}
        </Formik>
      )}
      <ImageWrapper>
        <PrevalentLogo src={PrevalentLogoImg} />
      </ImageWrapper>
    </Wrapper>
  )
}

const Ask = styled.div`
  margin: 0 auto;
  color: #32a9f1;
  font-weight: 500;
  cursor: pointer;
`

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

const ProductWrapper = styled.div``

const ProductImage = styled.img`
  max-width: 150px;
  width: 100%;
`

const ImageWrapper = styled.div`
  text-align: right;
`

const PrevalentLogo = styled.img`
  width: 100%;
  max-width: 200px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`

const GridColumn = styled.div<{ greyOut?: any }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  font-size: 18px;

  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  &:nth-of-type(3n + 1) {
    border-left: 1px solid #ddd;
  }

  filter: ${({ greyOut }) => (greyOut ? 'grayscale(100%)' : 'none')};
  opacity: ${({ greyOut }) => (greyOut ? '.7' : '1')};
`

const GridHeaderColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background: #eee;
  padding: 20px 0;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;

  &:first-of-type {
    border-left: 1px solid #ddd;
  }
`

const OfferingText = styled.div`
  font-size: 16px;
  margin-right: 8px;
`

const OfferingContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`

const RadiosWrapper = styled.div`
  display: flex;
`

const RadioWrapper = styled.div`
  padding: 4px;
`

const ModalTitle = styled.h1`
  padding: 10px 20px 0;
  text-align: center;
`

const ModalContent = styled.div`
  font-size: 18px;
  padding: 0px 20px 0;
  text-align: center;
`

export default InfoStep
