export default [
  {
    priceId: 'price_1LpweeEPUy7BhonONhTZhRwR',
    testPriceId: 'price_1LpwGTEPUy7BhonOuPZsQzJn',
    accountId: 'acct_1LmhKjEPUy7BhonO',
    associationId: 'd3691636-3f0b-4ac4-9c2a-8bc61dee5ed5',
    slug: 'acc',
  },
  {
    priceId: 'price_1L5YqMCldCjodC13FXwF40GA',
    testPriceId: 'price_1LpwGTEPUy7BhonOuPZsQzJn',
    accountId: 'acct_1BtTBjCldCjodC13',
    associationId: '5a172a54-130e-4cf6-8416-625f73950d98',
    slug: 'cloc',
  },
  {
    priceId: 'price_1L5YqMCldCjodC13FXwF40GA',
    testPriceId: 'price_1LpwGTEPUy7BhonOuPZsQzJn',
    accountId: 'acct_1JG3TYDVZW692Fnq',
    associationId: '6419f925-a0b2-40cc-9fdf-68400767dc41',
    slug: 'elta',
  },
]
