import ImageBodyLink from './ImageBodyLink'
import image from 'assets/marketing/profile-data-rafiki.png'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const ProductProfiles = () => {
  const baseOrgUrl = useBaseOrgUrl()

  return (
    <ImageBodyLink
      image={image}
      title="Finish Setting up your products"
      Message="Add content to your product listings including descriptions, media, target pricing and target customers."
      routerLink={{
        to: `${baseOrgUrl}`,
        text: 'Sell Product',
      }}
    />
  )
}

export default ProductProfiles
