import { useQuery } from '@tanstack/react-query'
import { GqlOrganizationByDomainQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useGetOrgByDomain = (domain: string) => {
  const request = useGql()
  const { data, ...other } = useQuery(['orgByDomain', domain], async () => {
    const response = await request<GqlOrganizationByDomainQuery>(GET_ORGANIZATION_BY_DOMAIN, {
      domain,
    })

    return response.organizations?.[0]
  })

  return {
    organization: data,
    ...other,
  }
}

export const GET_ORGANIZATION_BY_DOMAIN = gql`
  query OrganizationByDomain($domain: String) {
    organizations(condition: { domain: $domain }) {
      id
      details
      name
      usersToOrganizations {
        role
        status
        userId
      }
    }
  }
`
