import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Dropdown } from 'semantic-ui-react'
import type { Update } from './CsvTechstack'
import MoneyInput from 'components/theme/form/MoneyInput2'
import { Button } from 'components/theme'
import { css, Theme } from '@emotion/react'
import { Access, Stage } from 'hooks/lawfirm/useUpdateTechstack'
import { v4 as uuid } from 'uuid'
import slugify from 'slugify'
import { useAllTeam } from 'data/hooks'

const stageOptions = [
  'Investigation',
  'Under Review',
  'Pilot',
  'Contract Review',
  'Practice Rollout',
  'Region Rollout',
  'Active',
].map((text) => ({
  value: slugify(text.toUpperCase(), { replacement: '_' }),
  text,
  key: uuid(),
}))

type Props = {
  update: React.Dispatch<Update>
  updatedData?: Update
  isAdvancedView?: boolean
  product: {
    id: number
    name: string
    slug?: string
    details: {
      [key: string]: any
      squareLogo: string
      logoUrl: string
    }
  }
  addedBy?: { first: string; last: string }
  techstack: {
    id?: number
    access: Access
    stage: Stage
    ownedBy?: number
    seats?: number
    metadata?: {
      [key: string]: any
      contractValue?: number
      renewalDate?: string
    }
  }
}

const StackRow: React.FC<Props> = ({
  product,
  techstack,
  addedBy,
  update,
  isAdvancedView,
  updatedData,
}) => {
  const [contractValue, setContractValue] = useState<number | undefined>(
    techstack.metadata?.contractValue || undefined
  )
  const [renewalDate, setRenewalDate] = useState<string | undefined>(
    techstack.metadata?.renewalDate || undefined
  )
  const [seats, setSeats] = useState<number | undefined>(techstack.seats)
  const [access, setAccess] = useState<Access>(techstack.access)
  const [stage, setStage] = useState<Stage>(techstack.stage)
  const [ownedBy, setOwnedBy] = useState<number | undefined>(techstack.ownedBy)
  const [loading, setLoading] = useState<boolean>(true)
  const { team } = useAllTeam()

  const getUpdateContents = () => ({
    product,
    techstack: {
      id: techstack.id,
      stage,
      access,
      ownedBy,
      seats,
      metadata: {
        contractValue,
        renewalDate,
      },
    },
  })

  useEffect(() => {
    if (loading) return setLoading(false)
    const { product, techstack } = getUpdateContents()
    update({
      action: updatedData?.action !== 'ADD' ? 'UPDATE' : 'ADD',
      product,
      techstack,
    })
  }, [contractValue, renewalDate, access, stage, ownedBy, seats])

  const authOptions = ['User', 'Organization', 'Hide'].map((auth) => ({
    value: auth.toUpperCase(),
    text: auth,
    key: `${auth}${product.id}${techstack?.id}`,
  }))

  const teamMemberOptions =
    team?.map(({ userId, name, email }) => {
      return {
        value: userId,
        text: name,
        key: email,
      }
    }) || []

  return (
    <tr
      style={{
        backgroundColor:
          updatedData?.action === 'ADD'
            ? 'rgba(139, 195, 74, .2)'
            : updatedData?.action === 'DELETE'
            ? 'rgba(233, 30, 99, .2)'
            : updatedData?.action === 'UPDATE'
            ? '#c3b74a33'
            : '',
      }}
    >
      <td>
        <Product>
          <Logo
            src={product.details.squareLogoUrl || product.details.logoUrl}
            alt={`${product.name}'s logo`}
          />
          {product.name}
        </Product>
      </td>
      {isAdvancedView && (
        <>
          <Td
            minWidth={170}
            edited={
              !!updatedData?.techstack.metadata?.contractValue &&
              updatedData?.techstack.metadata?.contractValue !== contractValue
            }
          >
            <MoneyInput
              value={updatedData?.techstack.metadata?.contractValue || contractValue}
              placeholder="$1234.50 (optional)"
              onChange={setContractValue}
            />
          </Td>
          <Td
            edited={
              !!updatedData?.techstack.metadata?.renewalDate &&
              updatedData?.techstack.metadata?.renewalDate !== renewalDate
            }
          >
            <CustomInput
              type="date"
              value={updatedData?.techstack.metadata?.renewalDate || renewalDate}
              onChange={(event) => setRenewalDate(event.target.value)}
            />
          </Td>
          <Td
            minWidth={140}
            edited={!!updatedData?.techstack.seats && updatedData?.techstack.seats !== seats}
          >
            <CustomInput
              type="number"
              value={updatedData?.techstack.seats || seats}
              onChange={(event) => setSeats(parseInt(event.target.value, 10))}
              min="0"
              placeholder="&#8734;"
            />
          </Td>
          <Td edited={!!updatedData?.techstack.stage && updatedData?.techstack.stage !== stage}>
            <StageWrapper>
              <Dropdown
                options={stageOptions}
                value={updatedData?.techstack.stage || stage}
                onChange={(_1, { value }) => setStage(value as unknown as Stage)}
                selection
              />
            </StageWrapper>
          </Td>
          <Td edited={!!updatedData?.techstack.access && updatedData?.techstack.access !== access}>
            <AccessWrapper>
              <Dropdown
                options={authOptions}
                value={updatedData?.techstack.access || access}
                onChange={(_1, { value }) => {
                  debugger
                  setAccess(value as unknown as Access)
                }}
                selection
              />
            </AccessWrapper>
          </Td>
          <Td>
            <Dropdown
              options={teamMemberOptions}
              defaultValue={updatedData?.techstack.ownedBy || ownedBy}
              onChange={(_1, { value }) => {
                setOwnedBy(value as number)
              }}
              selection
            />
          </Td>
        </>
      )}
      <td>
        {(addedBy?.first || addedBy?.last) && (
          <Person>
            {addedBy?.first} {addedBy?.last}
          </Person>
        )}
      </td>
      <td>
        <AlignRight>
          <SmallButton
            onClick={() => {
              const { product, techstack } = getUpdateContents()
              update({
                action:
                  updatedData?.action !== 'DELETE' || !updatedData?.action ? 'DELETE' : 'UPDATE',
                product,
                techstack,
              })
            }}
          >
            {updatedData?.action === 'DELETE' ? 'Cancel' : 'Delete'}
          </SmallButton>
        </AlignRight>
      </td>
    </tr>
  )
}

const Logo = styled.img`
  height: 50px;
  margin-right: 16px;
`

const Td = styled.td<{ edited?: boolean; minWidth?: number }>`
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}` : '')}
  > * {
    ${({ edited }) => (edited ? 'border: 2px solid #498DE6; border-radius: 5px;' : '')}
  }
`

const Product = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
`

const CustomInput = styled.input`
  width: 100%;
  padding: 7.11px 14px;
  height: 38px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease, border-color 0.1s ease;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  flex: 1 0 auto;

  &::-webkit-input-placeholder {
    color: #616161;
  }

  &:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

const Person = styled.span`
  font-weight: bold;
  white-space: nowrap;
`

const AccessWrapper = styled.div`
  min-width: 100px;
`

const StageWrapper = styled.div`
  min-width: 130px;
`

const AlignRight = styled.div`
  text-align: right;
`

const red = ({ theme }: { theme: Theme }) =>
  css`
    ${theme.color.red}
  `

const SmallButton = styled(Button)`
  padding: 4px 12px;
  border-color: ${red};
  color: ${red};

  &:hover {
    background: ${red};
  }
`

export default StackRow
