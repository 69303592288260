/* import {
  saveObject as algoliaSaveObject,
  partialUpdateObject as algoliaPartialUpdateObject,
} from 'model/api/algolia' */
import _ from 'lodash'
import { useOrg } from 'data/organization/useOrg'
import { useViewer } from 'context/viewerContext'
// import algoliasearch from 'algoliasearch'

export const useAlgolia = () => {
  const { vendor } = useOrg()
  const { viewer } = useViewer()

  const parseVendor = () => {
    if (!vendor) return
    const { id, name, locations } = vendor
    const parsedVendor = {
      id,
      name,
      locations: locations.map(({ city, state, country }) => ({ city, state, country })),
    }
    return parsedVendor
  }

  const mapProductToAlgolia = ({
    id,
    name,
    slug,
    excerpt,
    details,
    show = true,
    testMode,
    ...product
  }: any) => {
    const logo = details.squareLogoUrl || details.logoUrl || ''
    const categories = parseTermsData(product, 14)
    const parsedVendor = parseVendor()
    const subcategories = parseTermsData(product, 15)
    const bestFor = product.bestFor?.map(({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    }))
    const practiceGroups = parseTermsData(product, 13)
    const { sigLiteStatus, sigCoreStatus, hasPrevalent } = parsePrevalent(details)
    const roles = parseTermsData(product, 8)
    const languages = parseTermsData(product, 4)
    const integratesWith = parseIntegrations(product)
    const prices = parsePrices(details)
    const availableIn = parseTermsData(product, 3)
    const certs = parseDiversityCerts(product)
    const features = parseFeatures(product)

    return {
      objectID: id,
      description: excerpt,
      title: name,
      slug,
      logo,
      show,
      testMode: testMode === undefined ? viewer.testMode : testMode,
      vendor: parsedVendor,
      categories,
      subcategories,
      bestFor,
      practiceGroups,
      sigLite: sigLiteStatus !== 'INACTIVE',
      sigCore: sigCoreStatus !== 'INACTIVE',
      hasPrevalent,
      _tags: features,
      roles,
      languages,
      integratesWith,
      prices,
      availableIn,
      diversityCertifications: certs,
    }
  }

  const saveObject = async ({ id, ...other }: any) => {
    const product = _.find(vendor?.products, { id })
    const algoliaProduct = mapProductToAlgolia({ id, ...product, ...other })
    console.log(algoliaProduct)
    // await algoliaSaveObject(JSON.stringify(algoliaProduct))
  }

  const partialUpdateObject = async ({ id, ...data }: any) => {
    console.log(id, data)
    /* const updateData = {
      ...data,
      objectID: id,
    } */
    // await algoliaPartialUpdateObject(JSON.stringify(updateData))
  }

  return { saveObject, partialUpdateObject }
}

const parseDiversityCerts = (product: any) => {
  const vendorData = _.get(product, 'productToOrganizations', [])
  return vendorData.reduce((certs: any, { organization }: any) => {
    const { diversityCertifications } = organization
    if (!diversityCertifications.length) return certs
    diversityCertifications.forEach(({ name }: any) => {
      certs.push(name)
    })
    return certs
  }, [])
}

const parseIntegrations = (productData: any) => {
  const integrationsData = _.get(productData, 'productToIntegrations', [])
  const integrations = integrationsData.map(({ integration }: any) => integration.name)
  return integrations
}

const parseTermsData = ({ termToProducts }: any, taxId: any) => {
  return (
    termToProducts
      ?.filter(({ term: { taxonomy } }: any) => taxonomy.id === taxId)
      ?.map(({ term: { id, name } }: any) => ({ id, name })) || []
  )
}

const parsePrevalent = (details: any) => {
  const sigLiteStatus = _.get(details, 'prevalent.sigLite.status', 'INACTIVE')
  const sigCoreStatus = _.get(details, 'prevalent.sigCore.status', 'INACTIVE')

  const hasPrevalent =
    sigLiteStatus === 'COMPLETE' ||
    sigCoreStatus === 'COMPLETE' ||
    sigLiteStatus === 'PENDING' ||
    sigCoreStatus === 'PENDING'

  return { sigLiteStatus, sigCoreStatus, hasPrevalent }
}

const parseFeatures = (product: any) => {
  const featuresData = _.get(product, 'features', [])
  const features = featuresData.map(({ name }: any) => name)

  return features.length > 0 ? features.join(', ') : ''
}

const parsePriceType = (priceType: any) => {
  switch (priceType) {
    case 'monthly':
      return 'monthly'
    case 'annually':
      return 'annual'
    case 'annuallyOnly':
      return 'annual'
    case 'oneTime':
      return 'one time'
    default:
      return ''
  }
}

const parsePriceTag = (priceTag: any) => {
  switch (priceTag) {
    case 'hasFreeTrial':
      return 'Free Trial'
    case 'hasPerUser':
      return 'Per User'
    case 'hasFreeVersion':
      return 'Free Version'
  }
}

const parsePrices = (details: any) => {
  const pricingPlans = _.get(details, 'pricingPlans', [])
  const { hasPerUser, hasFreeTrial, hasFreeVersion } = details
  const tags: any = { hasPerUser, hasFreeTrial, hasFreeVersion }
  const priceTags = Object.keys(tags)
    .filter((tag: any) => tags[tag])
    .map((tag: any) => parsePriceTag(tag))
  return pricingPlans.reduce((plans: any, { price, price2, priceType }: any) => {
    const newPlans = plans
    newPlans.push({
      price: Math.trunc(price / 100), // ignoring the cents value
      priceTags,
      priceType: parsePriceType(priceType),
    })
    price2 &&
      newPlans.push({
        price: Math.trunc(price2 / 100),
        priceTags,
        priceType: 'monthly',
      })

    return plans
  }, [])
}
