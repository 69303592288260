import ReactDOM from 'react-dom/client'

import './index.css'
import 'typeface-roboto'
import 'react-popper-tooltip/dist/styles.css'
import 'semantic-ui-css/semantic.min.css'
import 'instantsearch.css/themes/algolia.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { initFontAwesome } from 'services/fontAwesome'
import { toast } from 'react-toastify'
import { logEvent } from '@theoremlts/theorem-design'

import { Auth0Provider } from '@auth0/auth0-react'
import auth0Config from 'services/auth0Config'

import App from 'components/App'

initFontAwesome()

toast.configure({
  position: 'top-center',
  autoClose: 4000,
})

const CLOC_ORG_ID = 'org_7IefMs3ethVVq9Ue'
const ACC_ORG_ID = 'org_aFpdEXpP9TfFPLF6'
const IN_HOUSE_CONNECT_ORG_ID = 'org_SwBJRkmT1wowTFaK'

const urlSearchParams = new URLSearchParams(window.location.search)
const params = Object.fromEntries(urlSearchParams.entries())

logEvent('start_site', { location: window.location.pathname })

let organization = params.organization
if (window.location.pathname === '/cloc') organization = CLOC_ORG_ID
if (window.location.pathname === '/acc') organization = ACC_ORG_ID
if (window.location.pathname === '/in-house-connect') organization = IN_HOUSE_CONNECT_ORG_ID

const rootElement = document.getElementById('root')

if (rootElement === null) throw new Error('Root container missing in index.html')

const root = ReactDOM.createRoot(rootElement)

root.render(
  <Auth0Provider
    domain={auth0Config.domain}
    clientId={auth0Config.clientId}
    audience={'https://api.theoremlegal.com'}
    redirectUri={params.redirect_uri || window.location.origin}
    organization={organization}
    invitation={params.invitation}
    useRefreshTokens={true}
    login_hint={params.email}
  >
    <App />
  </Auth0Provider>
)
