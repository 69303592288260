import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import RichEditor from 'components/fields/RichEditor'
import PricingPlanLoader from 'components/forms/product/SalesForm/PricingPlanLoader'
import { FullPageLoading } from 'components/PulsatingLoader'
import { BoxWrapper, Button, Icon } from 'components/theme'
import { FormField, Label, TextArea, TextInput, Dropdown } from 'components/theme/form'
import { useUpdateProductListing } from 'data/productListing/hooks/useUpdateProductListing'
import { Form, Formik } from 'formik'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useProductListingEditor, ProductListingValues } from './useProductListingEditor'
import PdfUploader from 'components/fields/PdfUploader'

const DOMAIN =
  process.env.NODE_ENV === 'development'
    ? `https://ecosystem.theorem.local:3300`
    : 'https://ecosystem.theoremlegal.com'

const ctaTypeOptions = [
  { key: 'demo', text: 'Request Demo', value: 'demo' },
  { key: 'contact', text: 'Contact Us', value: 'contact' },
  { key: 'custom', text: 'Custom', value: 'custom' },
]

const ProductListingEditor = () => {
  const baseOrgUrl = useBaseOrgUrl()
  const { productListing, initialValues, isService, isLoading } = useProductListingEditor()
  const updateProductListing = useUpdateProductListing(productListing?.id)
  const { user: auth0User } = useAuth0()

  // load the product listing ID,
  const onSubmit = async (values: ProductListingValues) => {
    await updateProductListing.mutateAsync(values)
    toast.success('Your listing has been updated!')
  }

  const routeType = isService ? 'service' : 'product'
  let previewUrl = `${DOMAIN}/api/auth/login?returnTo=/cloc/${routeType}/${productListing?.product?.slug}`

  if (auth0User?.org_id) previewUrl = `${previewUrl}&organization=${auth0User.org_id}`

  return (
    <Root>
      <BoxWrapper>
        <Top>
          <Link to={`${baseOrgUrl}/product-listing`}>
            <Icon name="chevron-left" /> View all Listings
          </Link>
          <Button component="a" href={previewUrl}>
            View Listing
          </Button>
        </Top>
        <FormWrapper>
          {isLoading && <FullPageLoading />}
          {!isLoading && (
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ values, setFieldValue }) => (
                <Form>
                  <Title>
                    Edit Listing for <span>{productListing?.product?.name}</span>
                  </Title>
                  <SubTitle>
                    Listings associated with: <span>{productListing?.association?.name}</span>
                  </SubTitle>
                  {isService ? (
                    <>
                      <Content>
                        When you{' '}
                        <a href="https://help.theoremlegal.com/" target="_blank">
                          associate a service to a listing
                        </a>
                        , you are adding a special instance of that service, which is unique to that
                        listing. Use the editor below to override the default service information
                        for the following fields. Otherwise the template information you added to
                        this service in your service library will display.{' '}
                        <Link to={`${baseOrgUrl}/product/settings/${productListing?.productId}`}>
                          You can edit and manage default service details within your product
                          library
                        </Link>
                        .
                      </Content>
                    </>
                  ) : (
                    <>
                      <Content>
                        When you{' '}
                        <a href="https://help.theoremlegal.com/" target="_blank">
                          associate a product to a listing
                        </a>
                        , you are adding a special instance of that product, which is unique to that
                        listing. Use the editor below to override the default product information
                        for the following fields. Otherwise the template information you added to
                        this product in your product library will display.{' '}
                        <Link to={`${baseOrgUrl}/product/settings/${productListing?.productId}`}>
                          You can edit and manage default product details within your product
                          library
                        </Link>
                        .
                      </Content>
                      <Description>
                        Customize the price and term information for this product listing. These
                        pricing plans are unique to this listing.
                      </Description>
                    </>
                  )}
                  {isService ? (
                    <>
                      <FormField>
                        <Label>Pricing Description</Label>
                        <RichEditor
                          editorState={values.pricingDescription}
                          onChange={setFieldValue}
                          name="pricingDescription"
                        />
                      </FormField>
                      <FormField>
                        <TextInput
                          name="ctaButtonLink"
                          label="CTA Button Link"
                          placeholder="https://www.example.com"
                          icon="linkify"
                        />
                      </FormField>
                      <FormField>
                        <TextInput
                          name="ctaButtonText"
                          label="CTA Button Text"
                          placeholder="Contact Us"
                        />
                      </FormField>
                    </>
                  ) : (
                    <FormField>
                      <PricingPlanLoader
                        pricingPlans={initialValues.pricingPlans}
                        productListing={productListing}
                        isProductListing
                      />
                    </FormField>
                  )}

                  <FormField>
                    <FormField>
                      <>
                        <Label>Sidebar CTA Type</Label>
                        <Dropdown
                          selection
                          fluid
                          style={{ minWidth: 190 }}
                          name="buttonType"
                          onChange={(_e: any, { value }: any) => {
                            setFieldValue(`sidebarCta.type`, value)
                            console.log(value)
                            if (value === 'demo') {
                              console.log(value)
                              setFieldValue(`sidebarCta.text`, 'Demo Request')
                            } else if (value === 'contact') {
                              setFieldValue(`sidebarCta.text`, 'Contact')
                            } else if (value === 'custom') {
                              setFieldValue(`sidebarCta.text`, '')
                            }
                          }}
                          value={values.sidebarCta?.type}
                          options={ctaTypeOptions}
                        />
                      </>
                    </FormField>
                    {values.sidebarCta?.type === 'custom' && (
                      <FormField>
                        <Flex>
                          <Half>
                            <TextInput label="CTA Text" name={`sidebarCta.text`} />
                          </Half>
                          <Half>
                            <TextInput label="CTA Link" name={`sidebarCta.link`} />
                          </Half>
                        </Flex>
                      </FormField>
                    )}
                  </FormField>
                  <TitleDivider>Support Overrides</TitleDivider>
                  <FormField>
                    <TextInput
                      label="Email Support"
                      name="emailSupport"
                      placeholder="support@example.com"
                    />
                  </FormField>
                  <FormField>
                    <TextInput
                      label="Forward inquiries to"
                      placeholder="inquiry@example.com"
                      name="emailInquiry"
                      helpContent="Inquiries (Requests for demo and contact) will be forwarded to this address"
                    />
                  </FormField>
                  <TitleDivider>Legal Overrides</TitleDivider>
                  <FormField>
                    <PdfUploader name="eulaPdf" />
                  </FormField>
                  <FormField>
                    <TextInput
                      label="Privacy Policy URL"
                      placeholder="https://example.com/privacy"
                      name="privacyPolicyUrl"
                    />
                  </FormField>
                  <FormField>
                    <TextInput
                      label="Public Terms and Conditions"
                      placeholder="https://example.com/terms"
                      name="publicTermsUrl"
                    />
                  </FormField>

                  <TitleDivider>Video Override</TitleDivider>
                  <FormField>
                    <TextInput
                      name="demoVideo"
                      placeholder="Youtube, Vimeo, or Wistia link"
                      helpContent={
                        <div>
                          <HelpLine>
                            <strong>Youtube:</strong> https://www.youtube.com/watch?v=[VIDEO_ID]
                          </HelpLine>
                          <HelpLine>
                            <strong>Vimeo:</strong> https://vimeo.com/[VIDEO_ID]
                          </HelpLine>
                          <HelpLine>
                            <strong>Wistia:</strong> https://[SLUG].wistia.com/medias/[VIDEO_ID]
                          </HelpLine>
                        </div>
                      }
                      icon="linkify"
                      label="Video URL"
                    />
                  </FormField>
                  <TitleDivider>Description Overrides</TitleDivider>
                  <FormField>
                    <TextArea label="Short Description" name="excerpt" />
                  </FormField>
                  <FormField>
                    <Label>Description</Label>
                    <RichEditor
                      editorState={values.description}
                      onChange={setFieldValue}
                      name="description"
                    />
                  </FormField>
                  <Button type="submit">Submit</Button>
                </Form>
              )}
            </Formik>
          )}
        </FormWrapper>
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

const Flex = styled.div`
  display: flex;
  margin: 0 -8px;
`

const Half = styled.div`
  width: 50%;
  padding: 0 8px;
`

const Title = styled.h3`
  font-weight: 400;
  margin-bottom: 4px;
  margin-top: 28px !important;

  span {
    font-weight: 600;
    color: #000000de;
  }
`

const SubTitle = styled.h4`
  margin-top: 0;
  font-weight: 400;

  span {
    font-weight: 600;
    color: #000000de;
  }
`

const HelpLine = styled.div`
  white-space: nowrap;
`

const TitleDivider = styled.h3`
  border-bottom: 1px solid #808088;
  padding-bottom: 8px;
  color: #444;
`

const Content = styled.p``

const Description = styled.div``

const FormWrapper = styled.div`
  max-width: 760px;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`

export default ProductListingEditor
