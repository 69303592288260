import { useState, useEffect } from 'react'
import { useLazyApi } from 'hooks/useApi'
import axios from 'axios'

// TODO: convert strings into enum

type Props = {
  priceId: string
  accountId?: string
  productName?: string
}

type Tier = {
  flatAmount: number | null
  unitAmount: number
  upTo: number | null
}

type StripeProduct = {
  name: string
  active: boolean
  metadata: any
  images: string[]
  currency: string
  type: 'one_type' | 'recurring'
  amount: number
  interval: string // TODO: turn this into an enum
  intervalCount: number
  recurringUsageType: string
  billingScheme: string
  tiersMode: string | null
  tiers: Tier[] | null
}

const useCheckout = ({ priceId, accountId, productName }: Props) => {
  const [loading, setLoading] = useState(true)
  const [isLive, setIsLive] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [product, setProduct] = useState<StripeProduct>({} as StripeProduct)
  const [totalPrice, setTotalPrice] = useState(0)
  const [discountPrice, setDiscountPrice] = useState<number>()
  const [retrieveProduct] = useLazyApi('stripe/product/retrieve')

  useEffect(() => {
    const source = axios.CancelToken.source()
    ;(async () => {
      try {
        const { product, price } = await retrieveProduct({ priceId, accountId }, source)

        const newProduct = {
          name: productName || product.name,
          active: price.active,
          metadata: product.metadata,
          images: product.images,
          currency: price.currency,
          type: price.type,
          amount: price.unit_amount,
          interval: price.recurring?.interval || 'one_time',
          intervalCount: price.recurring?.interval_count || 1,
          recurringUsageType: price.recurring?.usage_type,
          billingScheme: price.billing_scheme,
          tiersMode: price.tiers_mode,
          tiers: price.billing_scheme === 'tiered' ? parseTiers(price.tiers) : null,
        }

        setIsLive(price.livemode)
        setProduct(newProduct)
        setCurrentPrice(newProduct)

        setLoading(false)
      } catch (e) {
        console.error(e)
      }
    })()

    return () => source.cancel()
  }, [])

  const onQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity)
    setCurrentPrice(product, newQuantity) // maybe use reducer
  }

  const setCurrentPrice = (product: StripeProduct, quantity = 1) => {
    if (product.billingScheme === 'tiered') {
      setTotalPrice(calculateTieredPricing(product.tiers, quantity))
    } else {
      setTotalPrice(calculateUnitPricing(product.amount, quantity))
    }
  }

  const applyPercentCoupon = (percentOff: number) => {
    const discount = (percentOff / 100) * totalPrice
    setDiscountPrice(totalPrice - discount)
  }

  const applyAmountCoupon = (amountOff: number) => {
    setDiscountPrice(totalPrice - amountOff)
  }

  return {
    loading,
    product,
    isLive,
    onQuantityChange,
    quantity,
    applyPercentCoupon,
    applyAmountCoupon,
    hasDiscount: !!discountPrice,
    totalPrice: typeof discountPrice === 'number' ? discountPrice : totalPrice,
  }
}

const calculateTieredPricing = (tiers: Tier[] | null, quantity: number) => {
  if (!tiers) return 0

  let stopCalculatingFlag = false

  const calculatedAmount = tiers.reduce((prev, tier, index) => {
    if (stopCalculatingFlag) return prev

    let totalPrice = tier.flatAmount !== null ? prev + tier.flatAmount : prev //always add the flat price to start

    const previousUpTo = index === 0 ? 0 : tiers[index - 1].upTo || 0 // if first one, then no subtraction

    if (tier.upTo === null || (tier.upTo !== null && quantity <= tier.upTo)) {
      totalPrice = totalPrice + tier.unitAmount * (quantity - previousUpTo)
    } else if (tier.upTo !== null && quantity > tier.upTo) {
      totalPrice = totalPrice = totalPrice + tier.unitAmount * (tier.upTo - previousUpTo)
    }

    if (tier.upTo !== null && quantity <= tier.upTo) stopCalculatingFlag = true

    return totalPrice
  }, 0)

  return calculatedAmount
}

const calculateUnitPricing = (amount: number, quantity: number) => {
  return amount * quantity
}

type StripeTiers = {
  unit_amount: number
  flat_amount: number | null
  up_to: number | null
}

const parseTiers = (stripeTiers: StripeTiers[]) => {
  return stripeTiers.map((tier) => ({
    unitAmount: tier.unit_amount,
    flatAmount: tier.flat_amount,
    upTo: tier.up_to,
  }))
}

export default useCheckout
