import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useGql } from 'hooks/useGql'
import { gql } from 'graphql-request'
import { GqlGetPriceTermsQuery } from 'generated/graphql'

export const usePriceTermDropdown = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useQuery(
    ['price-terms'],
    async () => {
      const data = await request<GqlGetPriceTermsQuery>(GET_PRICE_TERMS)
      const priceTermOptions = getTermOptions(data)

      return priceTermOptions
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
      staleTime: Infinity,
    }
  )
}

type Term = {
  id: number
  name: string
}

const getTermOptions = (termsData: GqlGetPriceTermsQuery) => {
  const terms = termsData?.terms || []

  return terms.map((term: Term) => ({
    key: term.id,
    text: term.name,
    value: term.id,
  }))
}

const GET_PRICE_TERMS = gql`
  query GetPriceTerms {
    terms(condition: { taxonomyId: 6 }) {
      id
      name
      slug
    }
  }
`
