import styled from '@emotion/styled'
import { Button } from 'components/theme'
import { useAlgoliaTechstack } from './AlgoliaTechstackContext'

interface Props {
  onSubmit: (ids: number[]) => void
}
const SaveButton = ({ onSubmit }: Props) => {
  const { state } = useAlgoliaTechstack()

  const onSave = () => {
    const ids = state.selectedApps.map(({ id }: { id: number }) => id)
    onSubmit(ids)
  }

  return (
    <ButtonWrapper>
      <Button onClick={onSave}>Save to My Apps</Button>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  text-align: right;
`

export default SaveButton
