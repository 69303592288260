import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  locationId: number
  city?: string
  state?: string
  country?: string
}
export const useUpdateLocation = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { locationId, city, state, country } = variables

      await request(UPDATE_LOCATION, {
        id: locationId,
        city,
        state,
        country,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

const UPDATE_LOCATION = gql`
  mutation UpdateLocation($id: Int!, $city: String!, $state: String!, $country: String!) {
    updateLocation(input: { patch: { city: $city, country: $country, state: $state }, id: $id }) {
      location {
        city
        country
        id
        isHeadquarter
        state
      }
    }
  }
`
