import { Navigate, Routes, Route } from 'react-router-dom'
import RootContent from 'components/containers/RootContent'
import ListCompany from './company/ListCompany'
import { useViewer } from 'context/viewerContext'
import styled from '@emotion/styled'
import CreateCompany from './company/CreateCompany'
import UpdateCompany from './company/UpdateCompany'
import VendorStackshare from './vendorStackshare/VendorStackshare'

const SuperAdmin = () => {
  const { isSuperAdmin } = useViewer()

  if (!isSuperAdmin) return <Navigate to="/" />

  return (
    <Root>
      <Routes>
        <Route element={<RootContent />}>
          <Route path="/companies/create" element={<CreateCompany />} />
          <Route path="/companies/update/:orgId/*" element={<UpdateCompany />} />
          <Route path="/companies" element={<ListCompany />} />
          <Route path="/vendor-stackshare/*" element={<VendorStackshare />} />
        </Route>
      </Routes>
    </Root>
  )
}

const Root = styled.div``

export default SuperAdmin
