export const CLOC_BLACK_LIST = [
  'steggles.com',
  'higherlogic.com',
  'lexfusion.com',
  'factor.law',
  'coparse.com',
  'lumosemarketplace.com',
  'legaltechnologyhub.com',
  'reynencourt.com',
  'legaltech.com',
  'lawnextmedia.com',
  'legal.io',

  'nishimura.com',
  'cliffordchance.com',
  'lw.com',
  'orrick.com',
  'paulweiss.com',
  'whitecase.com',
  'bakermckenzie.com',
  'stblaw.com',
  'paulhastings.com',
  'jurists.co.jp',
  'cravath.com',
  'davispolk.com',
  'debevoise.com',
  'freshfields.com',
  'gibsondunn.com',
  'linklaters.com',
  'cov.com',
  'cgsh.com',
  'ropesgray.com',
  'stblaw.com',
  'skadden.com',
  'slaughterandmay.com',
  'weil.com',
  'tmmbt.com',
]

export const BLOCKED_EMAILS = ['ambrogi@gmail.com', 'korr55@hotmail.com']

export const BLACK_LIST_EMAIL_DOMAINS = [
  // bad.org domains included
  'steggles',
  'higherlogic',
  'lexfusion',
  'factor',
  'coparse',
  'lumosemarketplace',
  'legaltechnologyhub',
  'reynencourt',
  'legaltech',
  'lawnextmedia',
  'legal',

  'nishimura',
  'cliffordchance',
  'lw',
  'orrick',
  'paulweiss',
  'whitecase',
  'bakermckenzie',
  'stblaw',
  'paulhastings',
  'jurists',
  'cravath',
  'davispolk',
  'debevoise',
  'freshfields',
  'gibsondunn',
  'linklaters',
  'cov',
  'cgsh',
  'ropesgray',
  'stblaw',
  'skadden',
  'slaughterandmay',
  'weil',
  '.biz',
  '.dev',
  'zerosystems',
  'knox-cap',
  '.ac',
  'legaltech',
  'tmmbt',

  // Default domains included
  'aol',
  'att',
  'comcast',
  'facebook',
  'gmail',
  'gmx',
  'googlemail',
  'google',
  'hotmail',
  'mac',
  'me',
  'mail',
  'msn',
  'live',
  'sbcglobal',
  'verizon',
  'yahoo',

  // Other global domains
  'email',
  'fastmail',
  'games',
  'hush',
  'hushmail',
  'icloud',
  'iname',
  'inbox',
  'lavabit',
  'love',
  'pobox',
  'protonmail',
  'tutanota',
  'tuta',
  'keemail',
  'rocketmail',
  'safe-mail',
  'wow',
  'ygm',
  'ymail', // Yahoo,
  'zoho',
  'yandex',

  // United States ISP domains
  'bellsouth',
  'charter',
  'cox',
  'earthlink',
  'juno',

  // British ISP domains
  'btinternet',
  'virginmedia',
  'blueyonder',
  'freeserve',
  'live',
  'ntlworld',
  'o2',
  'orange',
  'sky',
  'talktalk',
  'tiscali',
  'virgin',
  'wanadoo',
  'bt',

  // Domains used in Asia
  'sina',
  'qq',
  'naver',
  'hanmail',
  'daum',
  'nate',
  '163',
  'yeah',
  '126',
  '21cn',
  'aliyun',
  'foxmail',

  // French ISP domains
  'laposte',
  'sfr',
  'neuf',
  'free',

  /* German ISP domains */
  'online',
  't-online', // T-Mobile
  'web',

  // Italian ISP domains
  'libero',
  'virgilio',
  'tiscali',
  'alice',
  'tin',
  'poste',
  'teletu',

  // Russian ISP domains
  'rambler',
  'yandex',
  'ya',
  'list',

  // Belgian ISP domains
  'skynet',
  'voo',
  'tvcablenet',
  'telenet',

  // Argentinian ISP domains
  'fibertel',
  'speedy',
  'arnet',

  // Domains used in Mexico
  'prodigy',

  /* Domains used in Canada */
  'bell',
  'shaw',
  'sympatico',
  'rogers',

  // Domains used in Brazil
  'uol',
  'bol',
  'terra',
  'ig',
  'itelefonica',
  'r7',
  'zipmail',
  'globo',
  'globomail',
  'oi',
]
