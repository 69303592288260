import styled from '@emotion/styled'

const StatusPill = ({ status }: { status: string }) => {
  let color = 'grey'
  if (status === 'premium') color = '#FFC62C'
  if (status === 'basic' || status === 'essential') color = '#5EBBF5'
  if (status === 'pending') color = '#f91c1c'
  if (status === 'draft') color = '#BDBDBD'

  return (
    <Root>
      <Pill color={color}>{status}</Pill>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  justify-content: center;
`

const Pill = styled.div<{ color: string }>`
  color: white;
  border-radius: 100px;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  padding: 4px 20px;
  background: ${({ color }) => color};
  font-weight: bold;
`

export default StatusPill
