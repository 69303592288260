import { ReactNode, useState } from 'react'
import styled from '@emotion/styled'
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Logo from 'assets/theorem-logo-large.png'
// import STRIPE from 'assets/stripe.svg'
import CheckoutForm from './CheckoutForm'
import useCheckout from './useCheckout'
import { FullPageLoading } from 'components/PulsatingLoader'
import { CheckoutSubmit, CheckoutValues, Coupon } from 'interfaces'
import { fixCurrencyDisplay } from 'services/helper'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { FormikHelpers } from 'formik'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { STRIPE_API_KEY } from 'model/static'

type Props = {
  title?: string
  amount?: number
  priceId: string
  accountId?: string
  freeTrialDays?: number
  allowQuantityField?: boolean
  email?: string
  legalComponent?: ReactNode
  onSubmit: ({ paymentMethodId, quantity }: CheckoutSubmit) => Promise<void>
}

function Checkout({
  title,
  priceId,
  accountId,
  freeTrialDays,
  email,
  allowQuantityField = true,
  legalComponent,
  onSubmit,
}: Props) {
  const {
    product,
    loading,
    isLive,
    totalPrice,
    onQuantityChange,
    applyPercentCoupon,
    applyAmountCoupon,
    hasDiscount,
  } = useCheckout({
    priceId,
    accountId,
  })
  const elements = useElements()
  const stripe = useStripe()

  const handleSubmit = async (
    values: CheckoutValues,
    { setStatus, setErrors }: FormikHelpers<CheckoutValues>
  ) => {
    // TODO: We may dynamically add the customers existing payment method
    const paymentMethodResult = await stripe?.createPaymentMethod({
      type: 'card',
      card: elements?.getElement(CardElement) || { token: '' },
      billing_details: {
        name: values.name,
        email: email || '',
        address: {
          country: values.country,
        },
      },
    })

    if (elements && paymentMethodResult?.paymentMethod) {
      try {
        await onSubmit({
          paymentMethodId: paymentMethodResult.paymentMethod.id || '',
          quantity: values.quantity,
          coupon: values.coupon,
        })

        setStatus('success')
      } catch (error) {
        setErrors({ isCardApproved: error.raw.message })
      }
    } else {
      if (paymentMethodResult && paymentMethodResult.error) {
        setErrors({ isCardApproved: paymentMethodResult.error.message })
      }
    }
  }

  const onConfirmCoupon = ({ amountOff, percentOff }: Coupon) => {
    if (percentOff) applyPercentCoupon(percentOff)
    if (amountOff) applyAmountCoupon(amountOff)
  }

  if (loading) return <FullPageLoading />

  return (
    <Root>
      <Left>
        <InnerDetailsContainer>
          {/* <BackButton onClick={onBackClick} name={faArrowLeft} /> */}
          {!isLive && <TestFlag>TESTMODE</TestFlag>}
          <PlanName>{title || product.name}</PlanName>
          {freeTrialDays && <FreeTrialHeading>{freeTrialDays} days free</FreeTrialHeading>}
          <PriceHeader couponapplied={false}>
            <Price hasDiscount={hasDiscount}>
              {freeTrialDays && 'Then'} {fixCurrencyDisplay(totalPrice)}
            </Price>{' '}
            {product.interval !== 'one_time' && <Interval>per {product.interval}</Interval>}
          </PriceHeader>

          <ImageContainer>
            <ImageBox>
              <ImageWrapper>
                {product.images.length > 0 ? (
                  <LogoImage src={product.images[0]} />
                ) : (
                  <LogoImage src={Logo} />
                )}
              </ImageWrapper>
            </ImageBox>
          </ImageContainer>
        </InnerDetailsContainer>
      </Left>

      <Right>
        <CheckoutForm
          accountId={accountId}
          price={totalPrice}
          interval={product.interval}
          onSubmit={handleSubmit}
          onQuantityChange={onQuantityChange}
          onConfirmCoupon={onConfirmCoupon}
          freeTrialDays={freeTrialDays}
          allowQuantityField={allowQuantityField}
          legalComponent={legalComponent}
        />
      </Right>
    </Root>
  )
}

const CheckoutWrapper = (props: Props) => {
  const { accountId } = props
  const [stripePromise] = useState(() =>
    accountId
      ? loadStripe(STRIPE_API_KEY, { stripeAccount: accountId })
      : loadStripe(STRIPE_API_KEY)
  )

  return (
    <>
      <Elements stripe={stripePromise} />
      <Checkout {...props} />
    </>
  )
}

const LogoImage = styled.img`
  max-width: 140px;
  margin: auto;
`

const ImageBox = styled.div`
  position: relative;
  background: white;
  width: 100%;
  padding-top: 100%;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e0e0e0, 0 1px 1.5px 0 rgb(0 0 0 / 5%);
`

const ImageContainer = styled.div`
  max-width: 300px;
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`

// Use color below if form is submitable
// background: #e4e0f3;
const Left = styled(Container)`
  background: #f1f3f7;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
`

const Right = styled(Container)`
  justify-content: center;
  padding-right: 20px;
`

const FreeTrialHeading = styled.div`
  font-size: 34px;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  margin-bottom: 8px;
  letter-spacing: 0.02rem;
`

const InnerDetailsContainer = styled.div`
  width: 100%;
  max-width: 420px;
  margin-left: auto;
  margin-right: 60px;
`

const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100vh;
`

const PlanName = styled.div`
  margin-right: auto;
  margin-bottom: 8px;
  opacity: 0.8;
  color: #524d5d;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0.01rem;
`

const Price = styled.span<{ hasDiscount: boolean }>`
  font-size: 18px;
  font-weight: 600;
  ${({ hasDiscount }) => hasDiscount && 'color: #41c300;'}
`

const Interval = styled.div`
  transform: translateY(1px);
  font-size: 14px;
  margin-left: 4px;
  opacity: 0.8;
`

const PriceHeader = styled.div<{ couponapplied: boolean }>`
  display: flex;
  align-items: flex-end;
  font-size: 24px;
  font-weight: 600;
  color: ${({ couponapplied }) => (couponapplied ? '#1B5E20' : '#47565d')};
  margin-bottom: 32px;
  margin-right: auto;
`

const TestFlag = styled.div`
  display: inline-block;
  font-size: 18px;
  padding: 8px;
  border-radius: 2px;
  background: black;
  color: white;
  margin-bottom: 20px;
`

export default CheckoutWrapper
