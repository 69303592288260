import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useSearchOrgs } from 'data/organization/hooks'
import { LineItemTable } from '@theoremlts/theorem-design'
import NetworkAccessRows from './NetworkAccessRows'
import Searchbar from 'components/theme/form/Searchbar'
import { Icon } from 'components/theme'
import { css } from '@emotion/react'
import { OrganizationType } from 'generated/graphql'

interface Org {
  id: string
  name: string
  type: OrganizationType
  domain?: string
  logo?: string
  associationIds: string[]
}

const NetworkAccess = () => {
  const [searchString, setSearchString] = useState('')
  const [results, setResults] = useState<Org[]>([])
  const [firstSearched, setFirstSearch] = useState(false)
  const { orgs: searchedOrgs, isFetchedAfterMount, isRefetching } = useSearchOrgs({ searchString })

  useEffect(() => {
    if (!isFetchedAfterMount || isRefetching || !searchedOrgs) return

    if (!firstSearched) setFirstSearch(true)

    setResults(searchedOrgs)
  }, [isFetchedAfterMount, isRefetching])

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
  }

  return (
    <Root>
      <SearchWrapper>
        <Searchbar value={searchString} onChange={onSearchChange} />
      </SearchWrapper>
      {results.length > 0 && (
        <LineItemTable
          headerItems={['Organization', 'Email Domain', 'Action']}
          lineItems={<NetworkAccessRows organizations={results} />}
        />
      )}
      {firstSearched && results.length === 0 && (
        <NoSearchWrapper>
          <Title>No organizations found</Title>
        </NoSearchWrapper>
      )}
      {!firstSearched && (
        <NoSearchWrapper>
          <Title>
            <FlipUpArrow name="level-up-alt" /> Search for an organization{' '}
            <UpArrow name="level-up-alt" />
          </Title>
          <Content>
            To grant access to a Vendor or Lawfirm, simply search the organization by name above and
            click the grant access button.
          </Content>
        </NoSearchWrapper>
      )}
    </Root>
  )
}

const Content = styled.p`
  max-width: 450px;
  margin: 0 auto;
`

const Title = styled.h2`
  color: #444;
`

const arrowStyles = css`
  width: 11px !important;
  color: #35353a;
`

const FlipUpArrow = styled(Icon)`
  ${arrowStyles};
  transform: translateY(-4px) rotateY(180deg);
  margin-right: 4px;
`

const UpArrow = styled(Icon)`
  ${arrowStyles};
  transform: translateY(-4px);
  margin-left: 4px;
`

const Root = styled.div`
  min-height: 500px;
`

const SearchWrapper = styled.div`
  margin-bottom: 20px;
`

const NoSearchWrapper = styled.div`
  text-align: center;
`

export default NetworkAccess
