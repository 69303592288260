import React from 'react'
import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'

export { default as ListProducts } from './ListProducts'
export { default as CompanyStack } from './CompanyStack'
export { default as CreateRfp } from './CreateRfp'
export { default as CreateListing } from './CreateListing'
export { default as InviteTeam } from './InviteTeam'
export { default as MemberPortalSetup } from './MemberPortalSetup'
export { default as VisitLegalTechMarket } from './VisitLegalTechMarket'
export { default as HelpAndSupport } from './HelpAndSupport'

export { default as FirstDeal } from './FirstDeal'
export { default as ProductProfiles } from './ProductProfiles'
export { default as SellerAccount } from './SellerAccount'

type Props = {
  modules: React.FC[]
}

const Modules: React.FC<Props> = ({ modules }) => {
  const printModules = () =>
    modules.map((Module, i) => (
      <ModuleWrapper key={`association-getting-started-module-${i}`}>
        <Module />
      </ModuleWrapper>
    ))

  return <>{printModules()}</>
}

const ModuleWrapper = styled(BoxWrapper)`
  padding: 24px 32px;
  max-width: 900px;
  margin: 16px auto;
`

export default Modules
