import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface Variables {
  noviId: string
  organizationId: string
}
export const useCreateNoviOrg = () => {
  const request = useGql()
  const queryClient = useQueryClient()
  return useMutation(
    async (variables: Variables) => {
      const { noviId, organizationId } = variables
      await request(CREATE_NOVI_ORG, { noviId, organizationId })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['noviOrganization']),
    }
  )
}

const CREATE_NOVI_ORG = gql`
  mutation CreateNoviOrganization(
    $noviId: UUID!
    $organizationId: UUID!
    $status: NoviStatus = CURRENT
  ) {
    createNoviOrganization(
      input: {
        noviOrganization: { noviId: $noviId, organizationId: $organizationId, status: $status }
      }
    ) {
      clientMutationId
    }
  }
`
