import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useSetHeadquarter = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (locationId: number) => {
      await request(SET_HEADQUARTER, { id: locationId })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

export const SET_HEADQUARTER = gql`
  mutation SetHeadquarter($id: Int!) {
    updateLocation(input: { patch: { isHeadquarter: true }, id: $id }) {
      location {
        id
      }
    }
  }
`
