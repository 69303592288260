import styled from '@emotion/styled'
import { useAlgoliaTechstack } from './AlgoliaTechstackContext'

const NoAppsBox = () => {
  const { state } = useAlgoliaTechstack()

  if (state.selectedApps.length > 0) return null

  return (
    <Root>
      <NoStackBox>
        <p>Use the search bar above to add apps to your techstack for quick access. </p>{' '}
        <p>
          <OutLink href="https://www.theoremlegal.com/stackshare" target="_blank">
            What’s a techstack?
          </OutLink>{' '}
          Your stack profile is your tech resume and helps you receive better recommendations
          throughout the platform and marketplace.{' '}
        </p>
      </NoStackBox>
    </Root>
  )
}

const Root = styled.div`
  padding-top: 16px;
`

const NoStackBox = styled.div`
  background: #fbfbfb;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02rem;
  border-radius: 6px;
  padding: 16px 20px;
  margin-bottom: 16px;
`

const OutLink = styled.a`
  text-decoration: underline;
`

export default NoAppsBox
