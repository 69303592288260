import React from 'react'
import { toast } from 'react-toastify'
import { parseISO } from 'date-fns/esm'
import { RawUserStory } from 'model/graphql/queries/userStory'
import { StoryText, StoryContent } from 'components/UserStories'
import { Term } from 'interfaces'
import { BlankStory, Permissions, PermissionsMeta, StoryUpdates } from './Helpers'

class UserStory {
  [key: string]: any
  id: number
  when: string
  to: string
  stage: string[]
  feature: {
    type: number
    name?: string
  }
  organizationId: string
  ownerCreated: boolean
  product?: {
    name: string
    id: number
    logoUrl?: string
    slug?: string
    testMode?: boolean
  }
  author?: {
    id?: number
    first?: string
    last?: string
    testMode?: boolean
    avatar?: string
  }
  firmType?: Term
  practiceGroup?: Term
  role?: Term
  updatedAt?: any
  createdAt?: any
  meta: {
    [key: string]: Permissions | boolean | RawUserStory | BlankStory | string[]
    permissions: Permissions
    isEdit: boolean
    changes: string[]
    initialStory: RawUserStory | BlankStory
  }
  details: any
  userByAuthor: any
  termToUserStories: any

  constructor(story: RawUserStory | BlankStory, permissions: PermissionsMeta) {
    const {
      id,
      when,
      to,
      details,
      organizationId,
      ownerCreated,
      userByAuthor,
      product,
      termToUserStories,
      updatedAt,
      createdAt,
    } = story
    ;(this.id = id),
      (this.when = when),
      (this.to = to),
      (this.stage = details.stage || []),
      (this.feature = details.feature || { name: '', type: 0 }),
      (this.organizationId = organizationId),
      (this.ownerCreated = ownerCreated),
      (this.product = product
        ? {
            id: product.id,
            name: product.name,
            logoUrl: product.details.squareLogoUrl || product.details.logoUrl || '',
            slug: product.slug,
            testMode: product.testMode,
          }
        : undefined),
      (this.author =
        userByAuthor === null
          ? undefined
          : {
              id: userByAuthor.id,
              first: userByAuthor?.first,
              last: userByAuthor?.last,
              testMode: userByAuthor?.testMode || true,
              avatar: userByAuthor?.details?.imageUrl || '',
            }),
      (this.firmType = termToUserStories?.find(
        ({ term: { taxonomyId } }: any) => taxonomyId === 16
      )?.term),
      (this.practiceGroup = termToUserStories?.find(
        ({ term: { taxonomyId } }: any) => taxonomyId === 13
      )?.term),
      (this.role = termToUserStories?.find(
        ({ term: { taxonomyId } }: any) => taxonomyId === 8
      )?.term),
      (this.updatedAt = updatedAt ? parseISO(updatedAt) : undefined),
      (this.createdAt = createdAt ? parseISO(createdAt) : undefined),
      (this.meta = {
        permissions: this.determinePermissions(permissions, story),
        isEdit: false,
        changes: [],
        // `isEdit` may be useful as a database prop - vendors can potentially edit the same user stories at the same time
        initialStory: story, // needs to change on change // refetch all on change? could eliminate the need for the prop all together
      })
  }

  determinePermissions = (permissions: PermissionsMeta, story: RawUserStory | BlankStory) => {
    const { organizationType, orgId, userId, userRole } = permissions // current viewer org + id
    const { organizationId, userByAuthor } = story
    if (story.id === 93) return { view: true, edit: false }
    if (userRole === 'SUPER_ADMIN') return { view: true, edit: true }
    // story 93 is used for example in instructions
    switch (organizationType) {
      case 'COMPANY':
      case 'LAWFIRM':
      case 'PERSONAL':
        return {
          edit: !!userByAuthor && userId === userByAuthor.id,
          view: orgId === organizationId,
        }
      case 'VENDOR':
        return {
          view: orgId === organizationId,
          edit: orgId === organizationId,
        }
      case 'ASSOCIATION':
        const visible =
          story.organization?.associationToOrganizations.some(
            ({ associationId, status }: any) => associationId === orgId && status === 'ACTIVE'
          ) || false
        return { view: visible, edit: false }
      default:
        console.warn('Use cases are being parsed without an organizationType')
        return { view: false, edit: false }
    }
  }

  searchableAttributes = () => {
    const property = ['stage', 'feature', 'firmType', 'practiceGroup', 'role']
    const storyText = `when ${this.when} use ${this.product?.name} to ${this.to} `
    return property
      .reduce((searchString: string, prop: string) => {
        if (typeof this[prop] === 'string') return `${searchString} ${this[prop]}`
        if (Array.isArray(this[prop])) return `${searchString} ${this[prop].join(' ')}`
        return `${searchString} ${this[prop]?.name || ''}`
      }, storyText)
      .toLowerCase()
  }

  updateTerm = (
    update: { id: number; name: string } | number | undefined,
    field: 'role' | 'practiceGroup' | 'firmType'
  ): Term | undefined => {
    const newTerm = {
      id: 0,
      name: '',
      taxonomyId: field === 'practiceGroup' ? 13 : field === 'firmType' ? 16 : 8,
    }
    if (update === undefined) return undefined
    if (typeof update === 'number') {
      console.error('Terms should be updated with more than id')
    } else {
      newTerm.id = update.id
      newTerm.name = update.name
    }
    return newTerm
  }

  updateStory = (values: StoryUpdates) => {
    const allowedUpdates = [
      'when',
      'to',
      'stage',
      'featureType',
      'featureName',
      'firmType',
      'practiceGroup',
      'role',
      'product',
      // 'productName',
      // 'productId',
    ]
    const updates = Object.keys(values)
    updates.forEach((update) => {
      if (update === 'productName' || update === 'productId') {
        return undefined
      }
      if (!allowedUpdates.includes(update)) {
        toast.error(`"${update}" is not something that can be manually updated on a User Story`)
        throw Error(`There was an attempt to update a protected User Story value (${update})`)
      }
      if (!this.meta.isEdit) this.meta.isEdit = true
      if (!this.meta.changes.includes(update)) this.meta.changes.push(update)
      switch (update) {
        case 'practiceGroup':
        case 'firmType':
        case 'role':
          this[update] =
            typeof values[update] === 'number'
              ? this[update]
              : this.updateTerm(values[update], update)
          break
        case 'featureType':
        case 'featureName':
          this.feature = { name: values.featureName, type: values.featureType }
          break
        default:
          this[update] = values[update]
      }
    })
  }

  clearEdits = () => {
    if (!this.meta.isEdit || !this.id) return
    this.meta.changes.forEach((change) => {
      switch (change) {
        case 'stage':
          this[change] = this.meta.initialStory.details.stage || []
          break
        case 'featureName':
        case 'featureType':
          this.feature = this.meta.initialStory.feature
          break
        case 'practiceGroup':
        case 'firmType':
        case 'role':
          this[change] = this.meta.initialStory.termToUserStories?.find(
            ({ term: { taxonomyId } }: any) => {
              const taxonomy = change === 'practiceGroup' ? 13 : change === 'firmType' ? 16 : 8
              return taxonomy === taxonomyId
            }
          )?.term
          break
        case 'productName':
        case 'productId':
          this.product = this.meta.initialStory.product
            ? {
                id: this.meta.initialStory.product.id,
                name: this.meta.initialStory.product.name,
                logoUrl:
                  this.meta.initialStory.product.details.squareLogoUrl ||
                  this.meta.initialStory.product.details.logoUrl ||
                  '',
                slug: this.meta.initialStory.product.slug,
                testMode: this.meta.initialStory.product.testMode,
              }
            : undefined
          break
        default:
          this[change] = this.meta.initialStory[change]
      }
    })
    this.meta.changes = []
  }

  PrintElement: React.FC<{
    boldedKeywords?: boolean
    withPills?: boolean
    showEditButtons?: boolean
    hideProductLabels?: boolean
  }> = ({
    boldedKeywords = false,
    withPills = false,
    showEditButtons = true,
    hideProductLabels = false,
  }) => (
    <StoryText
      userStory={this}
      withPills={withPills}
      boldedKeywords={boldedKeywords}
      showEditButtons={showEditButtons}
      hideProductLabels={hideProductLabels}
    />
  )

  InProgressPreview: React.FC<{ step: number }> = ({ step }) => (
    <StoryContent userStory={this} step={step} inProgress boldedKeywords withPills />
  )

  CreateInitialValuesCollection = (productId: number | undefined) => [
    {
      when: this.when,
      productId: productId || this.product?.id,
      featureType: this.feature?.type || 0,
      featureName: this.feature?.name || '',
      to: this.to,
    },
    { stage: this.stage || [] },
    {
      practiceGroup: this.practiceGroup?.id,
      firmType: this.firmType?.id,
      role: this.role?.id,
    },
  ]

  makeUpdateVariables = () => {
    const { id, when, product, to, stage, feature, role, firmType, practiceGroup } = this
    const details = {} as { stage?: string[]; feature?: { type: number; name?: string } }
    if (stage) details.stage = stage
    if (feature) details.feature = feature
    const terms = []
    if (role) terms.push(role.id)
    if (firmType) terms.push(firmType.id)
    if (practiceGroup) terms.push(practiceGroup.id)
    return { id, details, productId: product?.id, to, when, terms }
  }

  makeNewStoryVariables = () => {
    const { product, when, to, feature, stage } = this
    const details = {} as { stage?: string[]; feature?: { type: number; name?: string } }
    if (stage) details.stage = stage
    if (feature) details.feature = feature
    return { productId: product?.id, when, to, details }
  }

  surfaceTerms = () => {
    return [this.role?.id, this.practiceGroup?.id, this.firmType?.id].filter(
      (term): term is number => !!term
    )
  }
}

export default UserStory
