export default [
  {
    key: 1376,
    text: 'Software Provider',
    value: 1376,
  },
  {
    key: 1342,
    text: 'ALSP',
    value: 1342,
  },
  {
    key: 1341,
    text: 'Consultant',
    value: 1341,
  },
  {
    key: 1343,
    text: 'LPO/Managed Services',
    value: 1343,
  },
  {
    key: 1392,
    text: 'Media/Publisher',
    value: 1392,
  },
]
