import { parseTaxonomy } from 'data/taxonomy/parseTaxonomy'
import { Product, ProductBase } from 'data/types'
import {
  GqlViewerProductFragment,
  GqlCurrentOrgFragment,
  GqlProductCoreFragment,
} from 'generated/graphql'

export const parseVendorProductsData = (
  orgData: GqlCurrentOrgFragment,
  orgId: string
): Product[] => {
  const products = orgData.productToOrganizations || []

  const fixedProducts = products
    .map(({ product }) => {
      if (!product) return

      const { id, name, slug, type, excerpt, description, show, details } = product

      return {
        id,
        name,
        slug,
        type,
        excerpt,
        description,
        show,
        details,
        clients: parseProductClients(product),
        features: parseProductFeatures(product),
        categories: parseProductCategories(product),
        subcategories: parseProductSubCategories(product),
        practiceGroups: parsePracticeGroups(product),
        bestFor: parseBestFor(product),
        countries: parseCountries(product),
        languages: parseLanguages(product),
        priceTerms: parsePriceTerms(product),
        integrations: parseIntegrations(product),
        competitors: parseCompetitors(product, orgId),
        deployments: parseDeployments(product),
        roles: parseRoles(product),
      }
    })
    .filter((product: Product | undefined): product is Product => !!product) as Product[]

  return fixedProducts
}

const isProductBase = (product: ProductBase | undefined): product is ProductBase => !!product
const formatSimpleProduct = (product: GqlProductCoreFragment | undefined) => {
  if (!product) return

  const { id, name, slug, type, excerpt, createdAt, updatedAt, details } = product

  return {
    id,
    name: name || '',
    slug: slug || '',
    excerpt: excerpt || '',
    type,
    createdAt,
    updatedAt,
    details,
  }
}

const parseProductClients = (product: GqlViewerProductFragment) => {
  const clients = product.productToClients || []

  return clients
    .filter(({ client }) => client)
    .map(({ client }) => ({ id: client?.id || 0, name: client?.name }))
}

const parseProductCategories = ({ termToProducts = [] }: GqlViewerProductFragment) =>
  parseTaxonomy(termToProducts, 14)
const parseProductSubCategories = ({ termToProducts = [] }: GqlViewerProductFragment) =>
  parseTaxonomy(termToProducts, 15)
const parseCountries = ({ termToProducts }: GqlViewerProductFragment) =>
  parseTaxonomy(termToProducts, 3)
const parseLanguages = ({ termToProducts }: GqlViewerProductFragment) =>
  parseTaxonomy(termToProducts, 4)
const parsePriceTerms = ({ termToProducts }: GqlViewerProductFragment) =>
  parseTaxonomy(termToProducts, 6)
const parseDeployments = ({ termToProducts }: GqlViewerProductFragment) =>
  parseTaxonomy(termToProducts, 7)
const parseRoles = ({ termToProducts }: GqlViewerProductFragment) =>
  parseTaxonomy(termToProducts, 8)
const parsePracticeGroups = ({ termToProducts }: GqlViewerProductFragment) =>
  parseTaxonomy(termToProducts, 13)
const parseBestFor = ({ termToProducts = [] }: GqlViewerProductFragment) =>
  parseTaxonomy(termToProducts, 16)

interface Feature {
  id: number
  name: string
  slug: string
}
const parseProductFeatures = ({ featureToProducts }: GqlViewerProductFragment): Feature[] => {
  const fixedProductFeature = featureToProducts
    .map(({ feature }) => {
      if (!feature) return
      return {
        id: feature.id,
        name: feature.name,
        slug: feature.slug,
      }
    })
    .filter((feature): feature is Feature => !!feature)

  return fixedProductFeature
}

const parseIntegrations = ({
  productToIntegrations = [],
}: GqlViewerProductFragment): ProductBase[] => {
  const fixedProductIntegrations = productToIntegrations
    .map(({ integration }) => {
      return formatSimpleProduct(integration)
    })
    .filter(
      (product: ProductBase | undefined): product is ProductBase => !!product
    ) as ProductBase[]

  return fixedProductIntegrations
}

const parseCompetitors = (
  { productToCompetitors = [] }: GqlViewerProductFragment,
  orgId: string
): ProductBase[] => {
  const unlockedCompetitors = productToCompetitors.filter(({ locked }) =>
    orgId === '795b04ad-bdfe-477d-8d1a-8763d5b02800' ? locked : !locked
  )
  const fixedProductCompetitors: ProductBase[] = unlockedCompetitors
    .map(({ competitor }) => {
      return formatSimpleProduct(competitor)
    })
    .filter(isProductBase) as ProductBase[]

  return fixedProductCompetitors
}
