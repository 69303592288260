import { useQuery } from '@tanstack/react-query'
import { GqlGetUserQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useUserById = (userId: number) => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['user', userId],
    async () => {
      const response = await request<GqlGetUserQuery>(GET_USER, { id: userId })
      return response.user
    },
    {
      staleTime: 1000 * 60 * 15,
      enabled: !!userId,
    }
  )

  return {
    user: data,
    ...other,
  }
}

const GET_USER = gql`
  query GetUser($id: Int!) {
    user(id: $id) {
      email
      first
      last
      issubscribed
      id
      details
      testMode
      usersToTechstacks {
        logins
        updatedAt
        techstack {
          id
          product {
            name
            slug
            details
            show
            termToProducts {
              term {
                name
                id
                taxonomyId
              }
            }
            id
          }
          organizationId
        }
      }
      termToUsers {
        term {
          taxonomyId
          name
          id
        }
      }
      location {
        city
        country
        state
      }
      usersToOrganizations {
        termByJobTitle {
          name
          id
        }
      }
      personalTechstacks {
        createdAt
        product {
          name
          slug
          details
          show
          termToProducts {
            term {
              name
              id
              taxonomyId
            }
          }
          id
        }
      }
    }
  }
`
