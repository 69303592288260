import React, { useState } from 'react'
import { Navigate } from 'react-router'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'

import { Popup } from 'semantic-ui-react'
import { Icon } from 'components/theme'
import { Member } from './types'
import RejectModal from 'components/modals/RejectModal'
import LawfirmUpgradeRequiredModal from 'components/modals/LawfirmUpgradeRequiredModal'
import YesOrNoModal from 'components/modals/YesOrNoModal'
import { useModal } from 'components/Modal'
import { useLazyApi } from 'hooks/useApi'
import type { ParsedMember } from './SuperAdmins'
import { useViewer } from 'context/viewerContext'
import { useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { OrganizationType } from 'generated/graphql'
import { useDeleteUserToOrg } from 'data/hooks'

type BaseProps = {
  onConfirmReject?: () => void
  onConfirmAllow?: () => void
}

interface AdminProps extends BaseProps {
  member: ParsedMember
  isAdmin: true
}

interface UserProps extends BaseProps {
  member: Member
  onConfirmReject?: () => void
  onConfirmAllow?: () => void
  isAdmin?: undefined
}

const ActionMenu: React.FC<AdminProps | UserProps> = ({
  member: { id, status, name, email },
  onConfirmReject,
  onConfirmAllow,
  // isAdmin,
}) => {
  const deleteUserToOrg = useDeleteUserToOrg()
  // const [addUserToTheorem] = useMutation(ADD_USER_TO_THEOREM)
  const [rejectedUser, setRejectedUser] = useState<number | string | null>(null)
  const [allowedUser, setAllowedUser] = useState<number | string | null>(null)
  const [redirect, setRedirect] = useState<boolean>(false)
  const [loadingResend, setLoadingResend] = useState<boolean>(false)
  const { showModal, toggle } = useModal()
  const { organization } = useOrg()
  const { viewer } = useViewer()
  const [resendInvite] = useLazyApi('auth0/resent-invite')
  const [deleteOrgInvitation] = useLazyApi('auth0/delete-invitation')
  const [removeAuth0User] = useLazyApi('auth0/remove-user')
  const queryClient = useQueryClient()

  const toggleRejectUser = (id?: number | string) =>
    id ? setRejectedUser(id) : setRejectedUser(null)
  const toggleAllowedUser = () => setAllowedUser(null)

  const removeUser = async () => {
    try {
      if (organization?.type === OrganizationType.Association) {
        if (typeof id === 'string') {
          await deleteOrgInvitation({ invitationId: id })
        } else {
          await removeAuth0User({ deletedUserId: id })
          if (id) await deleteUserToOrg.mutateAsync({ userId: id })
        }
      } else {
        if (id) {
          const userId = typeof id === 'string' ? parseInt(id, 10) : id
          await deleteUserToOrg.mutateAsync({ userId })
        }
      }

      onReject()
    } catch (error) {
      toast.warn('Something went wrong, please try again', { toastId: 'bad-delete-user' })
      console.error(error)
    }
  }

  const onReject = () => {
    toast.success(
      status === 'INVITED'
        ? `${name || email}'s invite has been cancelled.`
        : `${name || email} has been removed from ${organization?.name || 'the organization'}.`,
      { toastId: 'remove-user-success' }
    )
    onConfirmReject && onConfirmReject()
    if (id === viewer.id) {
      queryClient.invalidateQueries(['viewer'])
      setRedirect(true)
    }
  }

  const resendInviteClick = async () => {
    try {
      setLoadingResend(true)
      await resendInvite({ email, organizationName: organization?.name })
      toast.success(`Invited ${name || email} again!`)
    } catch {
      toast.warn('Something went wrong, please try again')
    }
    setLoadingResend(false)
  }

  // const restrictUser = async () => {
  //   if (!isAdmin) return console.error(`A regular user was treated as an admin`)
  //   const admin = member as ParsedMember
  //   try {
  //     await Promise.all(
  //       admin.organizations.map(async (org) => {
  //         return await deleteUserToOrg({ variables: { user: id, org } })
  //       })
  //     )
  //     toast.success('The admin was removed from all organizations')
  //   } catch (error) {
  //     toast.warn('Something went wrong while removing this admin')
  //     console.error(error)
  //   }
  //   try {
  //     await addUserToTheorem({ variables: { userId: id } })
  //     toast.success('The user was added to Theorem with regular permissions')
  //     onConfirmAllow && onConfirmAllow()
  //   } catch (error) {
  //     toast.warn('Something went wrong while adding the user back to Theorem')
  //     console.error(error)
  //   }
  // }

  const onAllow = () => {
    toast.success(`${name || email} has been added to ${organization?.name || 'the organization'}!`)
    onConfirmAllow && onConfirmAllow()
  }

  const removePrompt = () => {
    if (id === viewer.id) {
      return `Are you sure you want to remove yourself from this organization?`
    } else if (status === 'INVITED') {
      return `Are you sure you don't want ${name || email} invited to join ${
        organization?.name || 'the organization'
      }?`
    } else {
      return `Are you sure you'd like to revoke ${name || email}'s access to ${
        organization?.name || 'the organization'
      }?`
    }
  }

  const InviteOptions = (
    <>
      {organization?.type !== 'ASSOCIATION' && (
        <button onClick={resendInviteClick} disabled={loadingResend}>
          {loadingResend ? <Icon name="spinner" spin /> : 'Resend Invite'}
        </button>
      )}
      <button onClick={toggle}>Cancel Invite</button>
    </>
  )

  const RequestOptions = (
    <>
      <button onClick={() => setAllowedUser(id)}>Allow In</button>
      <button onClick={() => setRejectedUser(id)}>Reject</button>
    </>
  )

  const ActiveOption = <button onClick={toggle}>Remove User</button>
  // const RestrictOption = <button onClick={restrictUser}>Restrict User</button>

  const printOptions = () => {
    // if (isAdmin) return RestrictOption
    switch (status) {
      case 'INVITED':
        return InviteOptions
      case 'REQUEST':
        return RequestOptions
      case 'ACTIVE':
        return ActiveOption
    }
  }

  return (
    <>
      {redirect && <Navigate to="/select-organization" />}
      {rejectedUser && organization?.type !== OrganizationType.Association && (
        <RejectModal
          rejectedUser={rejectedUser}
          showModal={!!rejectedUser}
          toggleModal={toggleRejectUser}
          onConfirmClick={onReject}
        />
      )}
      {allowedUser && (
        <LawfirmUpgradeRequiredModal
          selectedUser={allowedUser}
          showModal={!!allowedUser}
          toggleModal={toggleAllowedUser}
          onSubmit={onAllow}
        />
      )}
      <YesOrNoModal
        showModal={showModal}
        toggle={toggle}
        noText="No"
        question={removePrompt()}
        onYes={removeUser}
      />
      <Popup
        trigger={
          <Ellipsis emphasis={status !== 'ACTIVE'}>
            <Icon name="ellipsis-v" />
          </Ellipsis>
        }
        position="bottom center"
        on="click"
        interactive={true}
        delayHide={1000}
      >
        <Popup.Content>
          <Options>{printOptions()}</Options>
        </Popup.Content>
      </Popup>
    </>
  )
}

const Ellipsis = styled.button<{ emphasis?: boolean }>`
  background-color: #f5f7f6;
  border: 1px solid #cecece;
  color: #707070;
  ${({ emphasis }) => emphasis && 'border: 2px solid #9013FE; color: #9013FE;'}
  padding: 3px 7px;
  border-radius: 5px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-around;
  margin: -0.833em -1em;

  button {
    padding: 10px 15px;
    border: none;
    border-bottom: 1px solid #ddd;
    background: white;
    transition: background 0.3s;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;

    &:hover {
      background: #eee;
      color: #4183c4;
    }
  }
`

// const ADD_USER_TO_THEOREM = gql`
//   mutation AddUserToTheorem($userId: Int!) {
//     createUsersToOrganization(
//       input: {
//         usersToOrganization: {
//           userId: $userId
//           organizationId: "dc6d31c7-40b1-4616-9d00-36695e03d515"
//           role: USER
//         }
//       }
//     ) {
//       clientMutationId
//     }
//   }
// `

export default ActionMenu
