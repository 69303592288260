import { useOrg } from 'data/organization/hooks'
import { GqlGetNewProductListingQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'

// NOTE: the issue with this is that it only grabs a single product listing. Will need to be refactored to support more, but this will suffice for CLOC
export const useGetNewProductListing = () => {
  const { organization } = useOrg()
  const request = useGql()
  const { data, ...other } = useQuery(['new-product-listing'], async () => {
    const result = await request<GqlGetNewProductListingQuery>(GET_NEW_PRODUCT_LISTING, {
      organizationId: organization?.id,
    })

    if (result?.productListings && result.productListings?.length > 0) {
      return result.productListings[0]
    }

    return
  })

  return { newProductListing: data, ...other }
}

const GET_NEW_PRODUCT_LISTING = gql`
  query GetNewProductListing($organizationId: UUID!) {
    productListings(
      orderBy: CREATED_AT_DESC
      first: 1
      condition: { organizationId: $organizationId }
    ) {
      id
      productId
    }
  }
`
