import styled from '@emotion/styled'
import { Formik, Form } from 'formik'
import { FormField, TextArea, Label, TrueFalse, TextInput } from 'components/theme/form'
import RichEditor from 'components/fields/RichEditor'
import ExcerptDescription from 'components/ExcerptDescription'
import { EditorState } from 'draft-js'
import Button from 'components/theme/Button'
import { useUpsertListingDetails } from 'data/hooks'
import { draftToMarkdown } from 'markdown-draft-js'
import { convertToRaw } from 'draft-js'
import { useQueryClient } from '@tanstack/react-query'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import HelpPopupContent from 'components/HelpPopContent'
import { css } from '@emotion/react'
import { extractPictureUrl } from 'services/image'

export interface Values {
  bannerImage: string[]
  excerpt: string
  description: EditorState
  demoVideo: string
  show: boolean
  emailSupport: string
  twitter: string
  linkedin: string
}

interface Props {
  initialValues: Values
  onComplete: () => void
}

const ListingDetailsForm = ({ initialValues, onComplete }: Props) => {
  const upsertListingDetails = useUpsertListingDetails()
  const queryClient = useQueryClient()

  const onSubmit = async (values: Values) => {
    const { excerpt, description, demoVideo, emailSupport, twitter, linkedin } = values

    const markdownDescription = draftToMarkdown(convertToRaw(description.getCurrentContent()))

    const extractedBannerImage = await extractPictureUrl(values.bannerImage)

    upsertListingDetails.mutate(
      {
        associationId: '',
        excerpt,
        description: markdownDescription,
        details: { demoVideo, emailSupport, twitter, linkedin, bannerImage: extractedBannerImage },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['org', 'listing-details'])
          onComplete()
        },
      }
    )
  }

  return (
    <Root>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue, isSubmitting, isValid }) => (
          <Form>
            <FormField>
              <TrueFalse name="show" label="Display Listing" text="Show" />
            </FormField>
            <FormField>
              <UploadSingleAsset
                label="Banner Image"
                name="bannerImage"
                selectedImage={values.bannerImage}
                helpContent={<HelpPopupContent />}
                imageStyles={css`
                  width: auto;
                  display: flex;
                  justify-content: center;
                `}
                styles={css`
                  max-width: none;
                `}
              >
                Upload Wide Banner Image
              </UploadSingleAsset>
            </FormField>
            <FormField>
              <TextArea
                label="Short Description"
                name="excerpt"
                description={<ExcerptDescription description={values.excerpt} maxLength={135} />}
                placeholder="Short intro description"
                isSubmitting={isSubmitting}
                required
              />
            </FormField>
            <FormField>
              <Label helpContent="">Main Description</Label>
              <RichEditor
                editorState={values.description}
                onChange={setFieldValue}
                name="description"
              />
            </FormField>
            <FormField>
              <TextInput
                name="demoVideo"
                placeholder="Youtube, Vimeo, or Wistia link"
                helpContent={
                  <div>
                    <HelpLine>
                      <strong>Youtube:</strong> https://www.youtube.com/watch?v=[VIDEO_ID]
                    </HelpLine>
                    <HelpLine>
                      <strong>Vimeo:</strong> https://vimeo.com/[VIDEO_ID]
                    </HelpLine>
                    <HelpLine>
                      <strong>Wistia:</strong> https://[SLUG].wistia.com/medias/[VIDEO_ID]
                    </HelpLine>
                  </div>
                }
                icon="linkify"
                label="Video URL"
              />
            </FormField>
            <FormField>
              <TextInput label="Phone Number" placeholder="(xxx)xxx-xxxx" name="phone" />
            </FormField>
            <FormField>
              <TextInput
                label="Email Support"
                placeholder="support@example.com"
                name="emailSupport"
              />
            </FormField>
            <FormField>
              <TextInput
                label="LinkedIn Profile"
                placeholder="https://www.linkedin.com/in/john-doe"
                name="linkedin"
              />
            </FormField>
            <FormField>
              <TextInput label="Twitter Handle" placeholder="@TheoremLTS" name="twitter" />
            </FormField>
            <FormField>
              <ButtonContainer>
                <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                  Submit
                </Button>
              </ButtonContainer>
            </FormField>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div`
  max-width: 760px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

const HelpLine = styled.div`
  white-space: nowrap;
`

export default ListingDetailsForm
