import React from 'react'
import styled from '@emotion/styled'
import type { SearchResult } from '.'

const ResultCard: React.FC<{ result: SearchResult; fullWidth?: boolean; onClick: () => void }> = ({
  result: { name, logo, type },
  fullWidth = false,
  onClick,
}) => (
  <Card fullWidth={fullWidth} onClick={onClick}>
    <CardContent>
      {logo ? <Avatar src={logo} /> : <NoLogo />}
      <BoxTitle>{name}</BoxTitle>
      {type !== 'OTHER' && <SubTitle>{type.toLowerCase()}</SubTitle>}
    </CardContent>
  </Card>
)

const Card = styled.div<{ fullWidth: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '33.3333%')};
  padding-right: ${({ fullWidth }) => (fullWidth ? '0' : '10px')};
  padding-left: ${({ fullWidth }) => (fullWidth ? '0' : '10px')};
  height: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  margin-bottom: 20px;

  @media (max-width: 1100px) {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
  }

  @media (max-width: 850px) {
    width: 100%;
    padding-right: 0;
  }
`

const Avatar = styled.img`
  width: 100px;
  height: 100px;
`

const NoLogo = styled.div`
  width: 100px;
  height: 100px;
  background: #ddd;
`

const CardContent = styled.div`
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  padding-bottom: 8px;
  border-radius: 4px;
  border: transparent 2px solid;
  background: white;
  transition: border 250ms;
  height: 100%;
  display: flex;
  flex-flow: column;
  cursor: pointer;
  align-items: center;

  &:hover {
    border: #4cd0d1 2px solid;
  }
`

const BoxTitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
`

const SubTitle = styled.h4`
  text-transform: capitalize;
  margin: 0;
  font-weight: 500;
  opacity: 0.8;
`

export default ResultCard
