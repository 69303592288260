import React from 'react'
import styled from '@emotion/styled'
import { MemberStatus, OrganizationType } from 'generated/graphql'
import { useOrg } from 'data/organization/useOrg'
import { useCreateAssocToOrg } from 'data/associationToOrganization/hooks'
import { useQueryClient } from '@tanstack/react-query'

interface Org {
  id: string
  name: string
  type: OrganizationType
  domain?: string
  logo?: string
  associationIds: string[]
}

interface Props {
  organizations?: Org[]
}

const NetworkAccessRows = ({ organizations }: Props) => {
  const { association } = useOrg()
  const createAssocToOrg = useCreateAssocToOrg()
  const queryClient = useQueryClient()

  const clickGrantAccess = (organizationId: string) => () => {
    if (!association) return

    createAssocToOrg.mutate(
      { organizationId, associationId: association.id, status: MemberStatus.Active },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['searchOrg'])
          queryClient.invalidateQueries(['org', 'assocToOrg'])
        },
      }
    )
  }

  return (
    <>
      {organizations?.map(({ id, name, type, domain, logo, associationIds }) => (
        <TableRow key={id}>
          <td>
            <OrgColumn>
              <Left>
                <Image src={logo} />
              </Left>
              <Right>
                <Name>{name}</Name>
                <Type>{type}</Type>
              </Right>
            </OrgColumn>
          </td>
          <td>
            <Domain>{domain || ''}</Domain>
          </td>
          <td>
            {associationIds.some((associationId) => associationId === association?.id) ? (
              <AddedText>Added</AddedText>
            ) : (
              <GrantText onClick={clickGrantAccess(id)}>Grant Access</GrantText>
            )}
          </td>
        </TableRow>
      ))}
    </>
  )
}

const TableRow = styled.tr``

const Image = styled.img`
  width: 48px;
`

const Domain = styled.div`
  font-weight: 500;
`

const OrgColumn = styled.div`
  display: flex;
  align-items: center;
`

const Name = styled.div`
  font-size: 16px;
`

const Type = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #555;
  letter-spacing: 0.5x;
`

const AddedText = styled.div`
  color: green;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
`

const GrantText = styled.button`
  color: #3f3fcf;
  font-weight: 500;
  background: #f9f9fb;
  border: 1px solid #c1c1c7;
  border-radius: 2px;
  letter-spacing: 0.5px;
  transition: background 250ms;
  white-space: nowrap;

  &:hover {
    background: #efefef;
  }
`

const Left = styled.div`
  margin-right: 12px;
`

const Right = styled.div``

export default NetworkAccessRows
