import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  [key: string]: any
}
function Icon({ name, ...props }: Props) {
  return <FontAwesomeIcon {...props} icon={name} />
}

export default Icon
