import styled from '@emotion/styled'
import { useViewer } from 'context/viewerContext'
import { UserRole } from 'generated/graphql'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Link } from 'react-router-dom'

const NoTechstack = () => {
  const baseOrgUrl = useBaseOrgUrl()
  const { viewer } = useViewer()

  return (
    <div>
      {viewer.role !== UserRole.User ? (
        <NoStackBox>
          We didn't find your firm's techstack or it hasn't been uploaded yet.{' '}
          <Link to={`${baseOrgUrl}/techstack-management`}>Add your firm's Apps</Link>
        </NoStackBox>
      ) : (
        <NoStackBox>
          We didn't find your firm's techstack or it hasn't been uploaded yet. Please request the
          admins of this account to upload the firm's techstack.
        </NoStackBox>
      )}

      <WhatIsTechstackBox>
        <OutLink href="https://www.theoremlegal.com/stackshare" target="_blank">
          What’s a techstack?
        </OutLink>{' '}
        Help your team discover your firm’s apps and receive better recommendations throughout the
        platform and marketplace.
      </WhatIsTechstackBox>
    </div>
  )
}

const NoStackBox = styled.div`
  background: #fbfbfb;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02rem;
  border-radius: 6px;
  padding: 16px 20px;
  margin-bottom: 16px;
`

const WhatIsTechstackBox = styled.div`
  color: white;
  background: #50c4f3;
  border-radius: 6px;
  padding: 16px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
`

const OutLink = styled.a`
  color: white;
  text-decoration: underline;
`

export default NoTechstack
