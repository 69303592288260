import { useEffect, useState } from 'react'
import { Option } from 'interfaces'
import { useAllTeam } from 'data/hooks'
import { MemberStatus } from 'generated/graphql'

const useActiveOrgUsers = () => {
  const { team, isLoading } = useAllTeam({ status: MemberStatus.Active })
  const [orgUserOptions, setOrgUserOptions] = useState<Option[]>([])

  useEffect(() => {
    if (!team || isLoading) return

    const users = team.map(({ name, first, userId }) => ({
      text: name,
      value: userId,
      key: `${first}-${userId}`,
    }))
    setOrgUserOptions([...users, { key: 'org-user-clear', text: 'Clear Users', value: undefined }])
  }, [isLoading, JSON.stringify(team)])

  return { orgUserOptions }
}

export default useActiveOrgUsers
