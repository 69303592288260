export interface TaxonomyTerm {
  id: number
  name: string
  slug: string
  taxonomyId: number
  taxonomyName: string
  taxonomySlug: string
}

const isTaxonomyTerm = (taxonomyTerm: TaxonomyTerm | undefined): taxonomyTerm is TaxonomyTerm => {
  return !!taxonomyTerm
}
interface TermToProduct {
  term?: {
    nodeId: string
    id: number
    name: string
    slug: string
    order?: number
    taxonomyId: number
    taxonomy?: { nodeId: string; id: number; name: string; slug: string }
  }
}
export const parseTaxonomy = (
  termToProducts: TermToProduct[],
  taxonomyId: number
): TaxonomyTerm[] => {
  if (!termToProducts) return []

  return termToProducts
    .map(({ term }) => {
      if (!term || !term.taxonomy) return
      return {
        id: term.id,
        name: term.name,
        slug: term.slug,
        taxonomyId: term.taxonomy?.id,
        taxonomyName: term.taxonomy?.name || '',
        taxonomySlug: term.taxonomy?.slug || '',
      }
    })
    .filter(isTaxonomyTerm)
    .filter((term) => term.taxonomyId === taxonomyId)
}
