import React from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'
import Modal from 'components/Modal'

type Props = {
  showModal: boolean
  question: string | React.ReactElement
  toggle: () => void
  onYes: () => void
  onNo?: () => void 
  yesText?: string
  noText?: string
}

const YesOrNoModal: React.FC<Props> = ({ showModal, toggle, question, onYes, onNo, yesText, noText }) => {
  return (
    <Modal isShowing={showModal} toggleModal={toggle}>
      <Contents>
        <p style={{textAlign: 'center'}}>{question}</p>
        <ButtonContainer>
          <YesButton
            onClick={() => {
              toggle()
              onYes()
            }}
          >
            {yesText ? yesText : "Yes"}
          </YesButton>
          <NoButton
            onClick={() => {
              toggle()
              onNo && onNo()
            }}
          >
            {noText ? noText : "Cancel"}
          </NoButton>
        </ButtonContainer>
      </Contents>
    </Modal>
  )
}

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
`

const NoButton = styled(Button)`
  color: #e53935;
  border-color: #e53935;
  &:hover {
    background: #e53935;
    border-color: #e53935;
  }
`

const YesButton = styled(Button)`
  margin-right: 12px;

  color: #43a047;
  border-color: #43a047;
  &:hover {
    background: #43a047;
    border-color: #43a047;
  }
`

export default YesOrNoModal
