import { useQuery } from '@tanstack/react-query'
import { GqlSearchByProductIdQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useProductById = (productId?: number) => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['productById', productId],
    async () => {
      const response = await request<GqlSearchByProductIdQuery>(SEARCH_BY_PRODUCT_ID, {
        productId,
      })

      return response.product
    },
    { staleTime: 1000 * 60 * 10, enabled: !!productId }
  )

  return { product: data, ...other }
}

export const SEARCH_BY_PRODUCT_ID = gql`
  query SearchByProductId($productId: Int!) {
    product(id: $productId) {
      id
      name
      details
    }
  }
`
