import { useField } from 'formik'
import { useEffect } from 'react'
import Label from './Label'
import MoneyInput2 from './MoneyInput2'

interface Props {
  label?: string
  name: string
  currencyValue: string
  disabled?: boolean
  hasConnectAccount?: boolean
  placeholder?: string
}
const MoneyInput2Formik = ({
  label,
  name,
  currencyValue,
  disabled,
  hasConnectAccount,
  placeholder,
}: Props) => {
  const [, meta, helpers] = useField({ name, type: 'text' })

  useEffect(() => {
    if (disabled) helpers.setValue(0)
  }, [disabled])

  const onChange = (value?: number) => {
    helpers.setValue(value)
  }

  return (
    <div>
      {label && (
        <Label
          helpContent={
            hasConnectAccount &&
            label !== 'Starting Price' &&
            'When a price is associated with a Stripe price plan users can buy this product directly on Theorem.'
          }
        >
          {label}
        </Label>
      )}
      <MoneyInput2
        value={meta.value}
        onChange={onChange}
        currencyValue={currencyValue}
        placeholder={placeholder}
      />
    </div>
  )
}

export default MoneyInput2Formik
