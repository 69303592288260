const grey100 = '#f5f5f5'
const grey900 = '#212121'
const blueGrey600 = '#546e7a'
const purple700 = '#512da8'
const primaryPurple = '#9013FE'
const primaryBlue = '#498DE6'
const buttonBlue = '#04A1FF'

export const breakpoint = {
  mobile: '@media (max-width: 768px)',
  menuLogoSwitch: '@media (max-width: 920px)',
  tablet: '@media (max-width: 1023px)',
  desktop: '@media (max-width: 1215px)',
  widescreen: '@media (max-width: 1407px)',
}

export default {
  color: {
    text: blueGrey600,
    heading: purple700,
    primaryPurple,
    primaryBlue,
    button: buttonBlue,
    buttonHover: '#3d78c5',
    red: ' #ff1744',
    rootBackground: grey100,
    error: '#f44336',
    disabled: '#9e9e9e',
    leftSidebar: {
      background: purple700,
      link: 'white',
      linkActive: '#f1f1f1',
      linkHover: '#f1f1f1',
    },
    topHeader: {
      background: grey900,
    },
  },
  topBarHeight: '52px',
  breakpoint,
}
