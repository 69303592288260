import { useEffect } from 'react'
import { useField } from 'formik'
import { Dropdown } from 'components/theme/form'
import { useLazyApi } from 'hooks/useApi'
import styled from '@emotion/styled'
import { fixCurrencyDisplay } from 'services/helper'
import { Label } from 'components/theme/form'
import { useViewer } from 'context/viewerContext'

const PriceIdInput = ({ disabled = false, ...props }: any) => {
  const [field, , helpers] = useField(props)
  const { accountId, stripeReady } = useViewer()
  const [getAllPrices, { data }] = useLazyApi(`/stripe/connect/get-all-prices`)

  const getOptions = () => {
    const options = data?.prices?.data?.map(
      ({ id, unit_amount, product, billing_scheme, currency, recurring }: any) => ({
        value: id,
        text: getOptionTitle(currency, unit_amount, billing_scheme, product, recurring),
      })
    )
    return options ? options : []
  }

  const getOptionTitle = (
    currency: any,
    unit_amount: any,
    billing_scheme: any,
    product: any,
    recurring: any
  ) => {
    const sign = currency === 'usd' ? '$' : currency
    const money = unit_amount ? fixCurrencyDisplay(parseInt(unit_amount, 10)) : billing_scheme
    return `${product.name} - ${money}${sign !== '$' && unit_amount ? ` ${sign} ` : ''}${
      recurring ? ` per ${recurring.interval}` : ' one time'
    }`
  }

  useEffect(() => {
    if (accountId) getAllPrices({ accountId })
  }, [accountId])

  useEffect(() => {
    if (disabled) helpers.setValue('')
  }, [disabled])

  return (
    <Wrapper>
      <Label>Stripe Price Id</Label>
      <AttachedDropdown
        disabled={disabled}
        options={getOptions()}
        placeholder="Select One"
        clearable
        {...field}
        {...props}
      />
      {stripeReady && (
        <StripeLink href="https://dashboard.stripe.com/" target="_blank">
          Go to Stripe
        </StripeLink>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StripeLink = styled.a`
  font-weight: 500;
  margin-top: 4px !important;
  display: block;
  text-align: right;
`

const AttachedDropdown = styled(Dropdown)`
  /* position: sticky;  */
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 9.5px 14px;
  width: 100%;
  z-index: 100;
  border-radius: 4px;
  /* border-top: none; */
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: 100;
`

export default PriceIdInput
