import styled from '@emotion/styled'

function AppBoxTooltip({ name }: { name: string }) {
  return (
    <Root>
      <TopItem>{name}</TopItem>
      <ItemLink
        href="https://airtable.com/embed/appT1qb4jWHcJDwF2/shrdVdxkew8dWXCcj?backgroundColor=cyan"
        target="_blank"
      >
        Review Vendor
      </ItemLink>
    </Root>
  )
}

const Root = styled.div``

const TopItem = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid #888;
  background: #eee;
  font-weight: 700;
  letter-spacing: 0.25px;
  white-space: nowrap;
`

const Item = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  background: white;
  transition: background 0.3s;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background: #eee;
  }
`

const ItemLink = styled.a`
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  background: white;
  transition: background 0.3s;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;

  &:hover {
    background: #eee;
  }
`

export default AppBoxTooltip
