import { Button } from 'components/theme'
import { Formik, Form } from 'formik'
import { Multiselect, FormField, TextInput, Label, Radio, Dropdown } from 'components/theme/form'
import { useAssocHome, useQueryProductsDropdown, useUpdateAssocHome } from 'data/hooks'
import styled from '@emotion/styled'
import PulsatingLoader from 'components/PulsatingLoader'
import { useCategoryDropdown } from 'hooks/useCategoryDropdown'

interface Props {
  assocHomeId: number
  onSuccess: () => void
}

interface Values {
  title?: string
  subtitle?: string
  sliderType: string
  categoryIds: string
  productIds?: number[]
}
const ProductsSliderForm = ({ assocHomeId, onSuccess }: Props) => {
  const { data: productOptions } = useQueryProductsDropdown()
  const { assocHome, isLoading } = useAssocHome(assocHomeId)
  const updateAssocHome = useUpdateAssocHome()
  const options = useCategoryDropdown()

  const handleCategoryIds = (categoryIds: string): number[] => {
    if (!categoryIds) return []
    const categoryIdArray = categoryIds.split(' ')
    return categoryIdArray.map((categoryId) => parseInt(categoryId, 10))
  }

  const onSubmit = async (values: Values) => {
    const categoryIds = handleCategoryIds(values.categoryIds)

    await updateAssocHome.mutateAsync({ id: assocHomeId, details: { ...values, categoryIds } })
    onSuccess()
  }

  if (isLoading) return <PulsatingLoader />

  const categoryIds = assocHome?.details.categoryIds ? assocHome?.details.categoryIds.join(' ') : ''

  return (
    <Formik initialValues={{ ...assocHome?.details, categoryIds }} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <FormField>
              <TextInput label="Title" name="title" />
            </FormField>
            <FormField>
              <TextInput label="Subtitle" name="subtitle" />
            </FormField>
            <Line />
            <FormField>
              <Label>Slider Type</Label>
              <RadioWrapper>
                <Radio name="sliderType" value="CUSTOM" label="Select Products" />
              </RadioWrapper>
              <RadioWrapper>
                <Radio
                  name="sliderType"
                  value="PREMIUM_PRODUCTS"
                  label="Premium Products Listing"
                />
              </RadioWrapper>
              <RadioWrapper>
                <Radio name="sliderType" value="CATEGORY" label="Products by Category" />
              </RadioWrapper>
            </FormField>
            {(values.sliderType === 'CUSTOM' || values.sliderType === 'CATEGORY') && (
              <FormField>
                <MultiSelectWrapper>
                  {values.sliderType === 'CUSTOM' ? (
                    <Multiselect
                      name="productIds"
                      label="Add Products to the slider"
                      options={productOptions}
                      value={values.productIds}
                    />
                  ) : (
                    <Dropdown
                      name="categoryIds"
                      label="Category"
                      options={options}
                      value={values.categoryIds}
                      setFieldValue={setFieldValue}
                      search
                    />
                  )}
                </MultiSelectWrapper>
              </FormField>
            )}
            <Button type="submit">Update</Button>
          </Form>
        )
      }}
    </Formik>
  )
}

const Line = styled.div`
  border: none;
  border-bottom: 1px solid #ccc;
  margin: 20px 0;
`

const RadioWrapper = styled.div`
  padding: 4px;
`

const MultiSelectWrapper = styled.div`
  padding-bottom: 170px;
`

export default ProductsSliderForm
