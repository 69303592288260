import PersonalTechstack from 'components/techstack/PersonalTechstack'
import { useSetUserStatus } from 'hooks/graphql'
import { useRedirect } from 'hooks/useRedirect'
import styled from '@emotion/styled'
import { useUserSetup } from 'context/userSetupContext'
import { useLazyApi } from 'hooks/useApi'
import { UserStatus } from 'generated/graphql'
import SetupHeader from 'components/global/SetupHeader'

const AssociationStack = () => {
  const { setUserStatus } = useSetUserStatus()
  const redirect = useRedirect()
  const { amplitude } = useUserSetup()
  const [welcomeEmail] = useLazyApi('company/welcome')

  const onFinish = async () => {
    amplitude
      ?.logEventWithSavedProps('set_personal_techstack', {
        action: 'update_information',
        organization_type: 'ASSOCIATION',
      })
      .completeSignUp({})
    setUserStatus(UserStatus.Active)
    await welcomeEmail()
    redirect('getting-started', { showWelcome: true })
  }

  return (
    <Root>
      <SetupHeader />
      <PersonalTechstack onFinish={onFinish} />
    </Root>
  )
}

const Root = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export default AssociationStack
