import { useState } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import SelectPlans from 'components/payment/SelectPlans'
import SellerTermsModal from 'components/modals/SellerTermsModal'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { productionData } from './vendorSubscriptionData'

interface Props {
  sectionRef: any
}

function UpgradeSubscription({ sectionRef }: Props) {
  const navigate = useNavigate()
  const baseOrgUrl = useBaseOrgUrl()
  const [selectedPriceId, setSelectedPriceId] = useState<string | null>(null)

  const onPriceClick = (priceId: string) => {
    setSelectedPriceId(priceId)
  }

  const goToCheckout = () => {
    navigate(`${baseOrgUrl}/upgrade/checkout`, {
      state: {
        priceId: selectedPriceId,
      },
    })
  }

  const toggleModal = () => {
    setSelectedPriceId(null)
  }

  return (
    <Root ref={sectionRef as any}>
      <Container>
        <SelectPlans
          title="Grow with the right plan."
          subtitle="Access Theorem’s most loved features - built on top of the Theorem Platform."
          onPriceClick={onPriceClick}
          planLists={productionData}
          hasIntervalSelector={true}
        />
      </Container>
      <SellerTermsModal
        showModal={!!selectedPriceId}
        toggleModal={toggleModal}
        onSubmit={goToCheckout}
      />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  padding: 60px 20px;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  max-width: 1140px;
  width: 100%;
`

export default UpgradeSubscription
