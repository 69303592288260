import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  productIds: number[]
  organizationId?: string
}
export const useCreateProductsToOrg = () => {
  const request = useGql()
  const { organization } = useOrg()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ productIds, organizationId }: Variables) => {
      await request(CREATE_PRODUCTS_TO_ORG, {
        productIds,
        organizationId: organizationId || organization?.id,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

const CREATE_PRODUCTS_TO_ORG = gql`
  mutation CreateProductsToOrganization($productIds: [Int!]!, $organizationId: UUID!) {
    createProductsToOrganization(
      input: { productIds: $productIds, organizationId: $organizationId }
    ) {
      clientMutationId
    }
  }
`
