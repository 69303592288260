import { useOrganizationListing } from 'data/hooks'
import { useParams } from 'react-router'
import { useListingStepper } from '../ListingStepperContext'
import companyListingData from '../companyListingData'

export const useCompanyListing = () => {
  const { productListings } = useListingStepper()
  const { slug } = useParams()
  const selectedCompanyListing = companyListingData.find((company) => company.slug === slug)
  const { orgListing } = useOrganizationListing(selectedCompanyListing?.associationId)

  const companyProducts = productListings
    ?.filter(({ association }) => association.slug === slug)
    .map(({ product }) => product)

  return { companyProducts, companyListing: orgListing }
}
