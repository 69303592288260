import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import FaIcon from 'components/theme/Icon'
import WithGreyHeader from 'components/containers/WithGreyHeader'
import PrivacyTerms from 'components/PrivacyTerms'
import { useUserSetup } from 'context/userSetupContext'
import {
  useAddUserToOrg,
  useAppMetadata,
  useChangeOrgType,
  useCreateOrg,
  useDeleteUserToOrg,
  useOrg,
} from 'data/hooks'
import { MemberStatus, OrganizationSubtype, OrganizationType, UserRole } from 'generated/graphql'
import { useViewer } from 'context/viewerContext'

const OrgSetupSelect = () => {
  const navigate = useNavigate()
  const { amplitude } = useUserSetup()
  const { organization } = useOrg()
  const createOrg = useCreateOrg()
  const changeOrgType = useChangeOrgType()
  const { data } = useAppMetadata()
  const addUserToOrg = useAddUserToOrg()
  const deleteUserToOrg = useDeleteUserToOrg()
  const { viewer } = useViewer()

  const setOrgSetup = async (type: OrganizationType, subtype: OrganizationSubtype) => {
    if (organization) {
      if (organization.type !== OrganizationType.Association)
        await changeOrgType.mutateAsync({ type, subtype })
    } else {
      await createOrg.mutateAsync({ type, subtype })
    }
  }

  const onVendorClicked = async () => {
    try {
      amplitude?.logEventWithSavedProps('choose_org_type', {
        organization_type: 'VENDOR',
        user_choice: 'vendor',
        action: 'selection',
      })
      await setOrgSetup(OrganizationType.Vendor, OrganizationSubtype.Software)

      if (data?.assocOrg && viewer.organizations.some(({ id }) => data?.assocOrg.id === id)) {
        await deleteUserToOrg.mutateAsync({ organizationId: data?.assocOrg.id })
      }
      // if there is a user_to_organization with app_metadata remove
      navigate('/setup/vendor-user')
    } catch (e) {
      console.error(e)
    }
  }

  const onLawfirmClicked = async () => {
    try {
      amplitude?.logEventWithSavedProps('choose_org_type', {
        organization_type: 'LAWFIRM',
        user_choice: 'legal',
        action: 'selection',
        is_owner: true, // this is a very isAdmin guess
      })

      await setOrgSetup(OrganizationType.Lawfirm, OrganizationSubtype.Lawfirm)

      if (data?.assocOrg) {
        await addUserToOrg.mutateAsync({
          organizationId: data?.assocOrg.id,
          role: UserRole.User,
          status: MemberStatus.Active,
        })
      }

      navigate('/setup/lawfirm-user', { state: { isAdmin: true } })
    } catch (e) {
      console.error(e)
    }
  }

  const onCompanyClick = async () => {
    try {
      await setOrgSetup(OrganizationType.Company, OrganizationSubtype.InHouse)

      if (data?.assocOrg) {
        await addUserToOrg.mutateAsync({
          organizationId: data?.assocOrg.id,
          role: UserRole.User,
          status: MemberStatus.Active,
        })
      }

      navigate('/setup/company-user', { state: { isAdmin: true } })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <WithGreyHeader
      title="Choose User Type"
      subTitle={`Let's make sure to send you to the right place`}
    >
      <div>
        <Wrapper>
          <StyledSegment onClick={onVendorClicked}>
            <IconContainer>
              <FaBoxIcon name="shopping-cart" />
            </IconContainer>
            <SegmentText>Vendor</SegmentText>
            <SegmentContent>Software &amp; Solutions Providers</SegmentContent>
          </StyledSegment>

          <StyledSegment onClick={onLawfirmClicked}>
            <IconContainer>
              <FaBoxIcon name="landmark" />
            </IconContainer>
            <SegmentText>Law Firm</SegmentText>
            <SegmentContent>Attorneys &amp; Legal Practices</SegmentContent>
            {/* <HoverWrapper>Request access</HoverWrapper> */}
          </StyledSegment>

          <StyledSegment onClick={onCompanyClick}>
            <IconContainer>
              <FaBoxIcon name="building" />
            </IconContainer>
            <SegmentText>In-House</SegmentText>
            <SegmentContent>Corporate Legal Departments</SegmentContent>
            {/* <HoverWrapper>Request access</HoverWrapper> */}
          </StyledSegment>
        </Wrapper>
        <PrivacyTerms />
      </div>
    </WithGreyHeader>
  )
}

const FaBoxIcon = styled(FaIcon)`
  font-size: 28px;
`

const HoverWrapper = styled.div`
  background: rgba(73, 141, 230, 0.9);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 350ms;
`

const IconContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  opacity: 0.8;
`

const StyledSegment = styled.div`
  position: relative;
  margin: 0px !important;
  margin: 0 10px 20px !important;
  width: 300px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  transition: 300ms;
  text-align: center;
  border-radius: 4px;
  border: 2px solid transparent;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;

  &:hover {
    border: 2px solid #4cd0d1;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);

    ${HoverWrapper} {
      opacity: 1;
    }
  }

  @media (max-width: 490px) {
    width: 140px !important;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1024px;
  margin-left: -10px;
  margin-right: -10px;
`

const SegmentText = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #53545a;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 10px;

  @media (max-width: 490px) {
    font-size: 0.8rem;
  }
`

const SegmentContent = styled.div`
  color: #666;
`

export default OrgSetupSelect
