import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { useParams } from 'react-router'
import styled from '@emotion/styled'
import UserStory from 'interfaces/userStory/UserStory'
import { useStories } from 'context/userStoriesContext'
import { useModal } from 'components/Modal'
import YesOrNoModal from 'components/modals/YesOrNoModal'
import { MainStoryForm, StageForm, UserDescriptionForm } from './StoryUpdaters'
import UserStoryInstructions from './StoryUpdaters/Instructions'
import {
  mainStoryValidation,
  stageValidation,
  userDescriptionValidation,
} from './StoryUpdaters/validationSchemas'

import { BoxWrapper, Button } from 'components/theme'
import FormStepper from './StoryUpdaters/FormStepper'
import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const StoryEditor: React.FC = () => {
  const { storyId, productId } = useParams<{ storyId: string; productId: string }>()
  const { createStory, updateStory, getStory, blankStory, loading: storiesLoading } = useStories()

  const [Story, setStory] = useState<UserStory>(blankStory)
  const [loading, setLoading] = useState<boolean>(true)
  const [showInstructions, setShowInstructions] = useState(false)
  const baseOrgUrl = useBaseOrgUrl()
  const { isSuperAdmin } = useViewer()
  const { showModal, toggle } = useModal()
  const navigate = useNavigate()
  const location = useLocation()

  const locationState = location.state as any

  useEffect(() => {
    if (storiesLoading) return
    const story = getStory(storyId)
    setStory(story)
    setLoading(false)
  }, [storiesLoading])

  const returnToStoryList = () => {
    let link
    if (productId && isSuperAdmin) {
      link = `${baseOrgUrl}/admin/product/settings/${productId}/user-stories`
    } else if (productId && !isSuperAdmin) {
      link = `${baseOrgUrl}/product/settings/${productId}/user-stories`
    } else if (isSuperAdmin) {
      link = `${baseOrgUrl}/admin/user-stories`
    } else {
      link = `${baseOrgUrl}/user-stories`
    }
    navigate(link)
  }
  const FormChildren: ReactElement[] = [
    <MainStoryForm onChange={Story.updateStory} />,
    <StageForm onChange={Story.updateStory} />,
    <UserDescriptionForm onChange={Story.updateStory} />,
  ]
  const initialValues: Array<{ [key: string]: any }> = Story.CreateInitialValuesCollection(
    locationState?.productId
  )
  const validators = [mainStoryValidation, stageValidation, userDescriptionValidation]
  const onSubmit = (values: any) => Story.updateStory(values)
  const onFinalSubmit = (values: any) => {
    Story.updateStory(values)
    if (Story.id === 0) {
      createStory(Story)
      returnToStoryList()
    } else {
      updateStory(Story)
      returnToStoryList()
    }
  }

  console.log(loading || storiesLoading)
  if (loading || storiesLoading) return <></>

  return (
    <>
      <YesOrNoModal
        question={
          <>
            Are you sure you want to go back?
            <br />
            Any changes you've made will be lost.
          </>
        }
        onYes={() => {
          Story.clearEdits()
          returnToStoryList()
        }}
        showModal={showModal}
        toggle={toggle}
      />
      <ExitButton
        onClick={() => {
          Story.meta.changes.length > 0 ? toggle() : returnToStoryList()
        }}
      >
        Back to User Stories
      </ExitButton>
      <EditorBody>
        <UseCaseBuilderHead>
          <SectionTitle>USER STORY EDITOR</SectionTitle>
          <StepperLink onClick={() => setShowInstructions((prev) => !prev)}>
            {showInstructions ? `Back to Use Case` : `Instructions`}
          </StepperLink>
        </UseCaseBuilderHead>
        <FormStepper
          onSubmit={onSubmit}
          onFinalSubmit={onFinalSubmit}
          FormChildren={showInstructions ? UserStoryInstructions : FormChildren}
          initialValues={initialValues}
          validators={validators}
          RightSide={showInstructions ? getStory(93).InProgressPreview : Story.InProgressPreview}
        />
      </EditorBody>
    </>
  )
}

export const WrappedStoryEditor: React.FC = () => (
  <BoxWrapper>
    <StoryEditor />
  </BoxWrapper>
)

const ExitButton = styled(Button)`
  font-size: 0.9em;
  color: #607d8b;
  border-color: #607d8b;
  background: none;
  padding: 8px 16px;
  opacity: 0.4;
  margin-top: -40px;
  margin-bottom: 28px;

  &:hover {
    background: #607d8b;
    border-color: #607d8b;
    color: white;
  }
`

const EditorBody = styled.div`
  display: flex;
  flex-direction: column;
`

const UseCaseBuilderHead = styled.div`
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%;
  margin-bottom: 24px;
`

const SectionTitle = styled.div`
  color: #616161;
  font-size: 0.9em;
  font-weight: 800;
`

const StepperLink = styled.button`
  background: none;
  border: none;
  color: #2196f3;
`

export default StoryEditor
