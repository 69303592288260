import Button from 'components/theme/Button'
import styled from '@emotion/styled'

function AddButton({ updatePlan }: any) {
  return (
    <Root
      type="button"
      onClick={(e: any) => {
        updatePlan('ADD')
        e.currentTarget.blur()
      }}
    >
      + ADD PRICING PLAN
    </Root>
  )
}

const Root = styled(Button)`
  margin-top: 12px;
`

export default AddButton
