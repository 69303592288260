import { GqlCurrentOrgFragment, OrganizationType } from 'generated/graphql'
import { Association } from './types/associationType'
import { parseTerms } from 'data/taxonomy/parseTerms'
import { parseMembers } from './parseMembers'

export const parseAssociationData = (associationData: GqlCurrentOrgFragment): Association => {
  const {
    id,
    name,
    slug,
    auth0OrgId,
    createdAt,
    updatedAt,
    details,
    locations,
    testMode,
    useCases,
    termToOrganizations,
    usersToOrganizations,
    appMetadata,
  } = associationData

  return {
    id,
    name,
    slug,
    type: OrganizationType.Association,
    auth0OrgId,
    createdAt,
    updatedAt,
    details,
    locations,
    testMode,
    useCases,
    terms: parseTerms(termToOrganizations),
    members: parseMembers(usersToOrganizations),
    appMetadata,
  }
}
