import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'components/theme'

interface Props {
  iconName: string
  iconNumber: number
  text: string | React.ReactNode
}

const NextStep: React.FC<Props> = ({ iconName, iconNumber, text }) => {
  return (
    <Step>
      <StepCircle>
        <StepIcon name={iconName} color="#555" />
        <StepNumber>{iconNumber}</StepNumber>
      </StepCircle>
      <StepText>{text}</StepText>
    </Step>
  )
}

const Step = styled.div`
  width: 33.3333%;
  display: flex;
  margin-bottom: 20px;
  padding: 12px 0;
  text-align: center;
  border-right: 1px dashed #8774d8;
  align-items: center;
  flex-direction: column;

  &:last-of-type {
    margin-bottom: 0;
    border-right: 0;
  }
`

const StepIcon = styled(Icon)`
  font-size: 40px;
  color: #a37abe;
`

const StepText = styled.div`
  color: #4d4d4d;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
`

const StepNumber = styled.div`
  position: absolute;
  top: 69%;
  left: 69%;
  color: white;
  border-radius: 999px;
  background: #8774d8;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
`

const StepCircle = styled.div`
  background: white;
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  margin-right: 12px;
  margin-bottom: 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
`

export default NextStep
