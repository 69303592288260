import React from 'react'
import styled from '@emotion/styled'
import RocketSvg from './golden-rocket.svg'
import { Icon } from 'components/theme'

const UpgradePersonal: React.FC = () => {
  return (
    <Root>
      <Header>
        <Title>Don't miss out</Title>
        <Subtitle>Sign up with your work email for more features</Subtitle>
        <ImagePng src={RocketSvg} />
        <UpgradeButton href="https://share.hsforms.com/1pylb9NU7Re6M_C6scaUaIQ3p9i3">
          Upgrade
        </UpgradeButton>
      </Header>
      <CtaBox>
        <CtaTitle>Upgrade for access to</CtaTitle>
        <InstructionsWrapper>
          <Instructions>
            <CircleIcon name="arrow-circle-right" />{' '}
            <Text>Enhanced marketplace content and personalization</Text>
          </Instructions>
          <Instructions>
            <CircleIcon name="arrow-circle-right" />{' '}
            <Text>Engage with vendors using simple RFP</Text>
          </Instructions>
          <Instructions>
            <CircleIcon name="arrow-circle-right" />{' '}
            <Text>Record vendor reviews and use cases</Text>
          </Instructions>
          <Instructions>
            <CircleIcon name="arrow-circle-right" />{' '}
            <Text>Collaborate and share tech stack data</Text>
          </Instructions>
          <Instructions>
            <CircleIcon name="arrow-circle-right" /> <Text>Manage access to your tech stack</Text>
          </Instructions>
          <Instructions>
            <CircleIcon name="arrow-circle-right" />{' '}
            <Text>One-click trials and entry level offers through Theorem TestDrive</Text>
          </Instructions>
        </InstructionsWrapper>
      </CtaBox>
    </Root>
  )
}

const Root = styled.div``

const Header = styled.div`
  text-align: center;
`

const Title = styled.h2``
const Subtitle = styled.p`
  font-size: 18px;
  margin-bottom: 0;
`

const ImagePng = styled.img`
  display: block;
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
`

const CtaBox = styled.div`
  border: 1px solid green;
  border-radius: 6px;
  padding: 20px;
  background: #24ab1417;
  margin-top: 32px;
`

const CtaTitle = styled.h1`
  color: #61a75d;
  font-size: 20px;
  margin-bottom: 10px;
`

const InstructionsWrapper = styled.div`
  max-width: 670px;
  margin: 0 auto;
`

const Instructions = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
  text-align: left;
  display: flex;
`

const CircleIcon = styled(Icon)`
  color: #61a75d;
  transform: translateY(3px);
`

const Text = styled.div`
  margin-left: 8px;
  line-height: 1.4;
`

const UpgradeButton = styled.a`
  border-radius: 100px;
  display: inline-block;
  padding: 20px 45px;
  color: white;
  font-size: 28px;
  font-weight: 600;
  margin: 0 auto;
  background: #e1c21a;

  &:hover {
    color: white;
  }
`

export default UpgradePersonal
