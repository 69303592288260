import styled from '@emotion/styled'
import { Avatar } from 'components/user'
import { useViewer } from 'context/viewerContext'
import { useLogout } from 'hooks/useLogout'

const UserBlock = () => {
  const { viewer } = useViewer()
  const logout = useLogout()

  return (
    <Root>
      <Avatar user={viewer} />
      <Email>{viewer.email}</Email>
      <LogoutSwitch onClick={() => logout()} type="button">
        Switch
      </LogoutSwitch>
    </Root>
  )
}

const Root = styled.div`
  align-items: center;
  border: 1px solid #cecece;
  border-radius: 999px;
  background: white;
  display: flex;
  padding: 12px;
  margin-bottom: 32px;
`

const Email = styled.div`
  line-height: 1;
  color: #2b2828;
  font-size: 16px;
  font-weight: 500;
  padding-left: 20px;
  letter-spacing: 0.02rem;
`

const LogoutSwitch = styled.button`
  padding-left: 16px;
  line-height: 1;
  color: #498de6;
  font-size: 16px;
  background: transparent;
  border: none;
`

export default UserBlock
