import { useTaxonomyTerm } from 'data/hooks'
import { useEffect, useState } from 'react'

interface Option {
  key: string
  text: string
  value: number[]
}
export const useCategoryDropdown = () => {
  const { terms: mainTerms, isLoading: isMainLoading } = useTaxonomyTerm(14) // Main category
  const { terms: secondaryTerms, isLoading: isSecondaryLoading } = useTaxonomyTerm(15) // Main category
  const [options, setOptions] = useState<Option[]>([])

  useEffect(() => {
    if (isMainLoading || isSecondaryLoading || !mainTerms || !secondaryTerms) return

    const categoryMap = new Map()

    mainTerms.forEach((term) => {
      categoryMap.set(term.name, {
        key: term.name,
        text: term.name,
        value: [term.id],
      })
    })

    secondaryTerms.forEach((term) => {
      if (categoryMap.has(term.name)) {
        const matchedCategory = categoryMap.get(term.name)
        matchedCategory.value
        return categoryMap.set(term.name, {
          ...matchedCategory,
          value: [...matchedCategory.value, term.id],
        })
      }

      categoryMap.set(term.name, {
        key: term.name,
        text: term.name,
        value: [term.id],
      })
    })

    const options = [...categoryMap]
      .map(([, value]) => ({ ...value, value: value.value.join(' ') }))
      .sort((a: any, b: any) => a.text.localeCompare(b.text))

    setOptions(options)
  }, [isMainLoading, isSecondaryLoading])

  return options
}
