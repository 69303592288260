import styled from '@emotion/styled'
import { useField } from 'formik'

const TieredLastInput = ({ index, updateTableValues, ...props }: any) => {
  const [field] = useField(props)
  return (
    <Input
      {...field}
      onBlur={(event: any) => {
        const valueAsNumber = Number(event.currentTarget.value)
        const correctedValue =
          valueAsNumber && valueAsNumber >= index * 2 + 1 ? valueAsNumber : index * 2 + 1
        updateTableValues(index, correctedValue)
      }}
    />
  )
}

const Input = styled.input`
  max-width: 80px;
`

export default TieredLastInput
