import React from 'react'
import StyleButton from './StyleButton'

const BLOCK_TYPES = [
  { label: 'H3', icon: 'heading', style: 'header-three' },
  { label: 'Blockquote', icon: 'quote-right', style: 'blockquote' },
  { label: 'UL', icon: 'list-ul', style: 'unordered-list-item' },
  { label: 'OL', icon: 'list-ol', style: 'ordered-list-item' },
]

function BlockStyleControls({ editorState, onToggle }: any) {
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
          icon={type.icon ? type.icon : ''}
        />
      ))}
    </div>
  )
}

export default BlockStyleControls
