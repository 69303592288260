import styled from '@emotion/styled'
import { Button } from 'components/theme'

interface Props {
  title: string
  text: string
  image: string
  outLink?: { href: string; text: string }
  routerLink?: { to: string; text: string; outLink?: boolean }
}
const TopCtaSection = ({ title, text, outLink, routerLink, image }: Props) => {
  return (
    <Root>
      <Left>
        <h2>{title}</h2>
        <Content>{text}</Content>
        <Bottom>
          {outLink && (
            <OutLink href={outLink.href} target="_blank">
              {outLink.text}
            </OutLink>
          )}
          {routerLink && !routerLink.outLink && (
            <Button component="link" to={routerLink.to}>
              {routerLink.text}
            </Button>
          )}
          {routerLink && routerLink.outLink && (
            <Button component="a" href={routerLink.to}>
              {routerLink.text}
            </Button>
          )}
        </Bottom>
      </Left>
      <Right>
        <Image src={image} />
      </Right>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
`

const OutLink = styled.a`
  margin-right: 20px;
`

const Content = styled.p`
  font-size: 16px;
  max-width: 420px;
`

const Left = styled.div`
  width: 60%;
`

const Right = styled.div`
  width: 40%;
  text-align: center;
`

const Image = styled.img`
  max-width: 270px;
  max-height: 150px;
  width: 100%;
`

export default TopCtaSection
