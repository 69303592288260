import { Location } from 'interfaces'
import styled from '@emotion/styled'
import { Dropdown, Input, DropdownItemProps } from 'semantic-ui-react'
import { useFirmApps } from './firmAppsContext'
import { useOrg } from 'data/organization/useOrg'

const FirmAppsFilters = () => {
  const { organization } = useOrg()
  const {
    onSearch,
    onChangeLocation,
    onSelectPracticeGroup,
    onSelectRoleGroup,
    onSelectUser,
    orgUserOptions,
    practiceGroupOptions,
    rolesGroupOptions,
  } = useFirmApps()

  return (
    <SearchBarWrapper>
      <Input placeholder="Search for app..." fluid onChange={(_1, { value }) => onSearch(value)} />
      <SelectRow>
        <SelectColumn>
          <Dropdown
            placeholder="Location"
            options={getLocationsOptions(organization?.locations || [])}
            onChange={(_1, { value }) => onChangeLocation(value as string)}
            selection
            fluid
          />
        </SelectColumn>

        <SelectColumn>
          <Dropdown
            placeholder="Practice Group"
            options={practiceGroupOptions}
            onChange={(_1, { value }) => onSelectPracticeGroup(value as number)}
            selection
            fluid
          />
        </SelectColumn>

        <SelectColumn>
          <Dropdown
            placeholder="Role"
            options={rolesGroupOptions}
            onChange={(_1, { value }) => onSelectRoleGroup(value as number)}
            selection
            fluid
          />
        </SelectColumn>

        <SelectColumn>
          <Dropdown
            placeholder="Users"
            options={orgUserOptions}
            onChange={(_1, { value }) => onSelectUser(value as number)}
            selection
            fluid
          />
        </SelectColumn>
      </SelectRow>
    </SearchBarWrapper>
  )
}

const getLocationsOptions = (locations: Location[] | undefined): DropdownItemProps[] => {
  if (locations) {
    const locationOptions: DropdownItemProps[] = locations.map((location) => ({
      key: location.id ? location.id.toString() : '0',
      text: `${location.city} ${location.country}`,
      value: location.id ? location.id.toString() : '0',
    }))
    return [{ key: 'all', text: 'All Locations', value: 'all' }, ...locationOptions]
  }
  return []
}

const SearchBarWrapper = styled.div`
  margin-bottom: 20px;
`

const SelectRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
  margin: 0 -8px;
`

const SelectColumn = styled.div`
  width: 25%;
  padding: 8px;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoint.tablet} {
    width: 50%;
  }

  ${({ theme }) => theme.breakpoint.mobile} {
    width: 100%;
  }
`

export default FirmAppsFilters
