import { useEffect } from 'react'
import styled from '@emotion/styled'
import { Routes, Route } from 'react-router'
import ListingNetworks from './ListingNetworks'
import ListingType from './ListingType'
import EditCompanyListing from './EditCompanyListing'
import CurrentListings from './CurrentListings'
import { ListingStepperProvider } from './ListingStepperContext'
import CreateProduct from './CreateProduct'
import CreateService from './CreateService'
import SelectProduct from './SelectProduct'
import ProductPlans from './ProductPlans'
import CompanyPlans from './ProductPlans/CompanyPlans'
import StepperControls from './StepperControls'
import ProductSuccess from './success/ProductSuccess'
import ServiceSuccess from './success/ServiceSuccess'
import CompanySuccess from './success/CompanySuccess'
import CompanyListing from './CompanyListing'

const ListingStepper = () => {
  useEffect(() => window.scrollTo(0, 0), [])

  return (
    <Root>
      <ListingStepperProvider>
        <Routes>
          <Route path="/" element={<CurrentListings />} />
          <Route element={<StepperControls />}>
            <Route path="networks" element={<ListingNetworks />} />
            <Route path="company-listing/:slug" element={<CompanyListing />} />
            <Route path="listing-type/:slug" element={<ListingType />} />
            <Route path="edit-company/:slug" element={<EditCompanyListing />} />
            <Route path="create-company/:slug" element={<EditCompanyListing />} />
            <Route path="create-product/:slug" element={<CreateProduct />} />
            <Route path="create-service/:slug" element={<CreateService />} />
            <Route path="select-plan/:slug" element={<EditCompanyListing />} />
            <Route path="product-plans/:slug" element={<ProductPlans />} />
            <Route path="company-plans/:slug" element={<CompanyPlans />} />
            <Route path="select-product/:slug" element={<SelectProduct />} />
            <Route path="select-service/:slug" element={<SelectProduct />} />
            <Route path="success-product/:slug" element={<ProductSuccess />} />
            <Route path="success-service/:slug" element={<ServiceSuccess />} />
            <Route path="success-company/:slug" element={<CompanySuccess />} />
          </Route>
        </Routes>
      </ListingStepperProvider>
    </Root>
  )
}

const Root = styled.div``

export default ListingStepper
