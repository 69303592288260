import React from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'

const Certifications = ({ certifications, add }: any) => {
  const printCertificationRows = () =>
    certifications.map(({ name, provider, description }: any, i: number) => (
      <Row key={`certification-${i}`}>
        <td>{description}</td>
        <td>
          {name}
          {provider && <>({provider})</>}
        </td>
      </Row>
    ))

  return (
    <>
      <Table>
        <Row>
          <Header>Description</Header>
          <Header>Certification</Header>
        </Row>
        {printCertificationRows()}
      </Table>
      <NewButton onClick={add}>Add a Certification</NewButton>
    </>
  )
}

const Table = styled.table`
  border-radius: 10px;
  width: min-content;
  padding: 0;
  margin: 16px auto;
`

const Header = styled.th`
  text-align: left;
`

const Row = styled.tr`
  > td,
  ${Header} {
    background: #e9edec;
    border: 1px solid white;
    padding: 4px 16px;
    white-space: nowrap;
    margin: 0;
  }
`

const NewButton = styled(Button)`
  background: #498de6;
  border-color: #498de6;
  color: white;
  margin-top: 12px;
  width: min-content;
  white-space: nowrap;
  margin: 0 auto;
`

export default Certifications
