import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  termId: number
  organizationId: string
  order?: number
}
export const useCreateTermToVendorStackshare = () => {
  const request = useGql()
  return useMutation(async ({ termId, organizationId, order }: Variables) => {
    await request(CREATE_TERM_TO_VENDOR_STACKSHARE, { termId, organizationId, order })
  })
}

const CREATE_TERM_TO_VENDOR_STACKSHARE = gql`
  mutation CreateTermToVendorStackshare($termId: Int!, $organizationId: UUID!, $order: Int) {
    createTermToVendorStackshare(
      input: {
        termToVendorStackshare: { termId: $termId, organizationId: $organizationId, order: $order }
      }
    ) {
      termToVendorStackshare {
        id
      }
    }
  }
`
