import styled from '@emotion/styled'
import Button from 'components/theme/Button'
import { Formik, Form } from 'formik'
import { TextInput } from 'components/theme/form'
import { useNavigate } from 'react-router-dom'
import { useLazyApi } from 'hooks/useApi'
import { useAuth0 } from '@auth0/auth0-react'
import { useUserSetup } from 'context/userSetupContext'
import { logEvent } from '@theoremlts/theorem-design'
import { useViewer } from 'context/viewerContext'
import { useDeleteUser } from 'data/user/hooks/useDeleteUser'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  email: yup.string().email('Not a valid email').required('Required'),
})

function InviteAdmin() {
  const { viewer } = useViewer()
  const [inviteAdmin] = useLazyApi('company/invite-admin')
  const deleteUser = useDeleteUser()
  const navigate = useNavigate()
  const { logout } = useAuth0()
  const { amplitude } = useUserSetup()

  const onAdminSubmit = async ({ email }: { email: string }) => {
    await inviteAdmin({ email })
    amplitude?.logEventWithSavedProps('invite_admin', {})

    if (viewer) await deleteUser.mutateAsync(viewer.id)

    logEvent('log_out')
    logout()
    amplitude?.completeSignUp({ on_complete: 'admin_invite' })
    // TODO: push them to a nicer page other than signin
    navigate('/', { state: { message: 'Your admin will receive the email shortly.' } })
  }

  return (
    <Root>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={onAdminSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Title>Invite your company&apos;s admin to claim this profile.</Title>
            <InputContainer>
              <TextInput name="email" label="Admin Email" placeholder="name@example.com" required />
            </InputContainer>

            <ButtonContainer>
              <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                Invite
              </Button>
              <ReminderText>We&apos;ll add you as a team member after they sign up.</ReminderText>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 28px;
`

const Root = styled.div`
  display: flex;
  justify-content: center;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

const InputContainer = styled.div`
  max-width: 405px;
`

const ReminderText = styled.div`
  padding-top: 16px;
  color: #777;
`

export default InviteAdmin
