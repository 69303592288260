import styled from '@emotion/styled'
import LogoImg from 'assets/logo.png'
import UserBlock from './UserBlock'
import OrgBlocks from './OrgBlocks'
import { useAppMetadata, useMutateViewer } from 'data/hooks'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import LogoutButton from 'components/theme/LogoutButton'
// import OtherOrgsBlock from './OtherOrgsBlock'

const SelectOrganization = () => {
  const { data, isLoading } = useAppMetadata()
  const mutateViewer = useMutateViewer()

  useEffect(() => {
    if (!data || isLoading) return

    if (data && data.hasOwnProperty('loginMessage')) {
      toast.success(data.loginMessage)
      mutateViewer.mutate({ deleteAppMetadataKeys: ['loginMessage'] })
    }
  }, [JSON.stringify(data), isLoading])

  return (
    <Root>
      <Top>
        <LogoutButton />
        <Logo src={LogoImg} />
      </Top>
      <CenteredContainer>
        <UserBlock />
        <SelectOrgText>Select an organization to launch</SelectOrgText>
        <OrgBlocks />
        {/* <OtherOrgsBlock /> */}
      </CenteredContainer>
    </Root>
  )
}

const Root = styled.div`
  background: #f7f7f7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Top = styled.div`
  margin: 32px auto auto;
`

const Logo = styled.img`
  height: 28px;
  display: block;
  margin: 0 auto 28px;
`

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: auto;
  padding-bottom: 60px;
  width: 650px;
  max-width: 100%;
  padding: 0 20px;
`

const SelectOrgText = styled.div`
  color: #38393b;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
`

export default SelectOrganization
