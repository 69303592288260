import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetUserStatus } from 'hooks/graphql'
import { MemberStatus, OrganizationType, UserStatus } from 'generated/graphql'
import { useAppMetadata, useOrg, useUpdateMemberStatus } from 'data/hooks'

export const useAgreeTerms = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { organization } = useOrg()
  const { setUserStatus } = useSetUserStatus()
  const updateMemberStatus = useUpdateMemberStatus()
  const navigate = useNavigate()
  const { data: appMetadata } = useAppMetadata()

  const orgType = organization?.type

  const onAgree = async () => {
    if (organization) {
      setIsSubmitting(true)

      await updateMemberStatus.mutateAsync({
        status: MemberStatus.Active,
        organizationId: organization?.id,
      })

      if (appMetadata.setup === 'SELECT_ROLE') {
        await setUserStatus(UserStatus.SelectRole)
        return navigate(`/setup/role-check`)
      }

      if (orgType === OrganizationType.Lawfirm) {
        await setUserStatus(UserStatus.CreateUser)
        return navigate(`/setup/lawfirm-user`)
      } else if (orgType === OrganizationType.Vendor) {
        await setUserStatus(UserStatus.CreateUser)
        return navigate(`/setup/vendor-user`)
      } else if (orgType === OrganizationType.Company) {
        await setUserStatus(UserStatus.CreateUser)
        return navigate(`/setup/company-user`)
      } else {
        // association user
        await setUserStatus(UserStatus.SelectRole)
        return navigate(`/setup/role-check`)
      }
    }

    if (appMetadata?.matchedOrg) {
      await setUserStatus(UserStatus.SetOrg)
      return navigate(`/setup/match-organization`)
    }

    if (appMetadata?.matchedProducts) {
      await setUserStatus(UserStatus.SetProducts)
      return navigate(`/setup/match-products`)
    }

    await setUserStatus(UserStatus.SelectRole)
    return navigate(`/setup/role-check`)
  }

  return { onAgree, isSubmitting }
}
