import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlCreateLocationMutation } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  city: string
  country: string
  state: string
  isHeadquarter?: boolean
  organizationId?: string
}
export const useCreateLocation = () => {
  const request = useGql()
  const { organization } = useOrg()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { city, country, state, organizationId, isHeadquarter = false } = variables

      try {
        const response = await request<GqlCreateLocationMutation>(CREATE_LOCATION, {
          city,
          country,
          state,
          isHeadquarter,
          organizationId: organizationId || organization?.id,
        })

        return response.createLocation?.location?.id
      } catch (e) {
        console.log(e)
        debugger
        throw e
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

export const CREATE_LOCATION = gql`
  mutation CreateLocation(
    $city: String
    $country: String
    $state: String
    $organizationId: UUID!
    $isHeadquarter: Boolean
  ) {
    __typename
    createLocation(
      input: {
        location: {
          city: $city
          country: $country
          state: $state
          organizationId: $organizationId
          isHeadquarter: $isHeadquarter
        }
      }
    ) {
      clientMutationId
      __typename
      location {
        id
      }
    }
  }
`
