import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Icon } from 'components/theme'
import _ from 'lodash'

type Props = {
  startPage?: number
  pageCount: number
  onChange?: (page: number) => void
  runOnStartUp?: boolean
}

const Pagination: React.FC<Props> = ({
  startPage = 1,
  pageCount,
  onChange,
  runOnStartUp = false,
}) => {
  const [page, setPage] = useState<number>(startPage)
  const [startingUp, setStartingUp] = useState<boolean>(!runOnStartUp)
  let list: number[] = []
  let startTrunc = false
  let endTrunc = false

  useEffect(() => {
    if (startingUp) return setStartingUp(false)
    if (!onChange) return
    onChange(page)
  }, [page])

  if (!pageCount) return <></>

  const jumpPage = (pageNumber: number) => setPage(pageNumber)

  const nextPage = () => setPage((prev) => (prev < pageCount ? prev + 1 : prev))

  const prevPage = () => setPage((prev) => (prev > 1 ? prev - 1 : prev))

  if (pageCount >= 7) {
    //allow for truncated pagination

    if (page <= 4) {
      list = _.range(1, 6)
      // range is 1 - 5
      // no initial truncation
      endTrunc = true
    } else if (page === 4 && pageCount === 7) {
      list = _.range(1, 8)
      // range is 1 - 7
      // no initial or end truncation
    } else {
      // add initial truncation
      startTrunc = true
      if (page + 3 === pageCount) {
        list = _.range(pageCount - 5, pageCount + 1)
      } else if (page + 3 > pageCount) {
        list = _.range(pageCount - 4, pageCount + 1)
      } else {
        list = _.range(page - 2, page + 3)
        endTrunc = true
      }
    }
  } else {
    list = _.range(1, pageCount + 1)
  }

  return (
    <Root>
      <Prev onClick={prevPage}>
        <CaratIconLeft name="angle-left" /> Prev
      </Prev>
      {startTrunc && (
        <>
          <Page onClick={() => jumpPage(1)} currentPage={false}>
            1
          </Page>
          <Truncation>&hellip;</Truncation>
        </>
      )}
      {list.map((pageNumber) => (
        <Page
          key={pageNumber}
          onClick={() => jumpPage(pageNumber)}
          currentPage={page === pageNumber}
        >
          {pageNumber}
        </Page>
      ))}
      {endTrunc && (
        <>
          <Truncation>&hellip;</Truncation>
          <Page onClick={() => jumpPage(pageCount)} currentPage={false}>
            {pageCount}
          </Page>
        </>
      )}
      <Next onClick={nextPage}>
        Next <CaratIconRight name="angle-right" />
      </Next>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`

const pageStyles = css`
  background: none;
  border: none;
  padding: 8px;
  font-size: 16px;
  font-weight: 300;
  border-radius: 4px;
  transition: color 250ms, background 250ms;
  display: flex;
  align-items: center;
  &:hover {
    color: #b86be0;
    background: #f4ebf9;
  }
`

const Prev = styled.button`
  ${pageStyles}
  font-weight: 400;
  margin-right: 10px;
`

const Next = styled.button`
  ${pageStyles}
  font-weight: 400;
  margin-left: 10px;
`

const Page = styled.button<{ currentPage: boolean }>`
  ${pageStyles}
  margin: 0 4px;
  ${({ currentPage }) => currentPage && 'color: #b86be0; background: #f4ebf9; font-weight: 500;'}
`

const Truncation = styled.span`
  padding: 4px;
  margin: 0 4px;
`

const CaratIconRight = styled(Icon)`
  width: 8px;
  margin-left: 8px;
  color: #b86be0;
`

const CaratIconLeft = styled(Icon)`
  width: 8px;
  margin-right: 8px;
  color: #b86be0;
`

export default Pagination
