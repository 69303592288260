import styled from '@emotion/styled'
import { BoxWrapper, Icon } from 'components/theme'
import TheoremLogoPng from 'assets/purple-logo.png'
import AccLogo from 'assets/networks/acc/acc-square.svg'
import ClocBlueSvg from 'assets/networks/cloc/cloc-blue-icon.svg'
import EltaLogo from 'assets/networks/elta/elta-pink.png'
import { useRedirect } from 'hooks/useRedirect'
import { useState } from 'react'
import NetworkSelectedListingWizard from '../ListingWizard/NetworkSelectedListingWizard'
import { useViewer } from 'context/viewerContext'

const networkCards = [
  {
    logo: AccLogo,
    name: 'Association of Corporate Counsel',
    slug: 'acc',
    link: 'listing-type/acc',
  },
  {
    logo: ClocBlueSvg,
    name: 'Corporate Legal Ops Consortium',
    slug: 'cloc',
    link: 'listing-type/cloc',
  },
  {
    logo: EltaLogo,
    name: 'European Legal Tech Association',
    slug: 'elta',
    link: 'listing-type/elta',
  },
]

const MainNetworks = () => {
  const { paymentStatus } = useViewer()
  const redirect = useRedirect()
  const [selectedSlug, setSelectedSlug] = useState<string | undefined>()

  const onNetworkCardClick = (slug: string | undefined) => () => {
    if (slug) setSelectedSlug(slug)
  }

  return (
    <Root>
      {selectedSlug && (
        <NetworkSelectedListingWizard
          closeModal={() => setSelectedSlug(undefined)}
          networkSlug={selectedSlug}
        />
      )}
      <BoxWrapper>
        <Wrapper>
          <Title>Available Networks</Title>
          {paymentStatus !== 'ACTIVE' && (
            <TheoremProPlan onClick={() => redirect('theorem-pro-upgrade')}>
              <TheoremLogo src={TheoremLogoPng} />
              <div>
                <SmallTitle>Get Theorem PRO Membership</SmallTitle>
                <Text>Universal Basic Listing + Big Savings</Text>
              </div>
              <Button>Upgrade &amp; Save</Button>
              <AngleRight name="angle-right" />
            </TheoremProPlan>
          )}
          <Networks>
            {networkCards.map(({ logo, slug, name, link }) => (
              <Column key={name}>
                <NetworkCard>
                  <NetworkLogo src={logo} />
                  <NetworkName>{name}</NetworkName>
                  <AngleRight name="angle-right" />
                  <CardLink onClick={onNetworkCardClick(slug)} />
                </NetworkCard>
              </Column>
            ))}
          </Networks>
        </Wrapper>
      </BoxWrapper>
    </Root>
  )
}

const Column = styled.div`
  width: 50%;
  padding: 0 8px 16px;
`

const CardLink = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`

const Root = styled.div`
  padding-top: 20px;
`

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

const AngleRight = styled(Icon)`
  font-size: 20px;
`

const Title = styled.h2`
  margin-bottom: 16px;
  text-align: center;
`

const TheoremLogo = styled.img`
  max-width: 30px;
  margin-right: 20px;
`

const TheoremProPlan = styled.div`
  display: flex;
  border: 2px solid #6ab9eb;
  border-radius: 6px;
  align-items: center;
  padding: 12px 20px;
  transition: background 250ms;
  cursor: pointer;
  background: #edf4fd;
`

const SmallTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`

const Text = styled.div``

const Networks = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  margin-left: -8px;
  margin-right: -8px;
`

const NetworkLogo = styled.img`
  max-height: 30px;
`

const Button = styled.div`
  background: #ffba00;
  letter-spacing: 0.02rem;
  border-radius: 100px;
  padding: 4px 20px;
  font-weight: bold;
  color: white;
  margin-left: auto;
  margin-right: 20px;
`

const NetworkName = styled.div`
  font-weight: 500;
  margin-left: 12px;
  margin-right: auto;
`

const NetworkCard = styled.div`
  position: relative;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 6px;
  align-items: center;
  padding: 12px 20px;
  transition: background 250ms;
  cursor: pointer;

  &:hover {
    background: #edf4fd;
  }
`

export default MainNetworks
