import { GqlCurrentOrgFragment, OrganizationType } from 'generated/graphql'
import { parseVendor } from './parseVendor'
import { parseLawfirm } from './parseLawfirm'
import { parseCompany } from './parseCompany'
import { parseAssociationData } from './parseAssociation'
import { parsePersonal } from './parsePersonal'

export const parseOrg = (orgData?: GqlCurrentOrgFragment) => {
  if (!orgData) return

  const { type } = orgData

  if (type === OrganizationType.Vendor) {
    return parseVendor(orgData)
  } else if (type === OrganizationType.Lawfirm) {
    return parseLawfirm(orgData)
  } else if (type === OrganizationType.Company) {
    return parseCompany(orgData)
  } else if (type === OrganizationType.Association) {
    return parseAssociationData(orgData)
  } else if (type === OrganizationType.Personal) {
    return parsePersonal(orgData)
  }
}
