import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useSetUserStatus } from 'hooks/graphql'
import { useNavigate } from 'react-router'
import { useUserSetup } from 'context/userSetupContext'
import NextSteps from '../NextSteps'
import { logEvent } from '@theoremlts/theorem-design'
import { MemberStatus, UserStatus } from 'generated/graphql'
import { useGetUserOrgStatus, useOrg } from 'data/hooks'

const RequestInvite: React.FC = () => {
  const { organization } = useOrg()
  const { logout } = useAuth0()
  const { setUserStatus } = useSetUserStatus()
  const { userStatus, isLoading } = useGetUserOrgStatus()
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const { amplitude } = useUserSetup()

  if (isLoading) return <FullPageLoading />

  const onContinue = () => {
    if (submitting) return
    setSubmitting(true)

    try {
      setUserStatus(UserStatus.CreateUser)
      amplitude?.logEventWithSavedProps('request_approval', {})
      navigate('/setup/lawfirm-user')
    } catch (e) {
      setSubmitting(false)
      console.error('there was an error with signup')
    }
  }

  if (userStatus !== MemberStatus.Active)
    amplitude
      ?.logEventWithSavedProps('awaiting_approval', {})
      .completeSignUp({ on_complete: 'awaiting_approval' })

  return (
    <Root>
      <LogoutButton
        onClick={() => {
          logEvent('log_out')
          logout()
        }}
      >
        Logout
      </LogoutButton>

      {userStatus === MemberStatus.Active ? (
        <NextSteps
          orgName={organization?.name || ''}
          isSubmitting={submitting}
          continueClick={onContinue}
        />
      ) : (
        <TextCenter>
          <Title>Your request has been sent 👍</Title>
          <Content>
            Your workspace administrator will be notified.
            <br />
            You'll receive an email as soon as you've been accepted.
          </Content>
          <Box>
            <BoxText>👋 Want to user Theorem with a different team?</BoxText>
            <Button onClick={logout}>Create another workspace</Button>
          </Box>
          <BottomText>You will need to sign up with another email domain*</BottomText>
        </TextCenter>
      )}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

// old

const LogoutButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const Title = styled.h1`
  margin-bottom: 0;
`

const TextCenter = styled.div`
  text-align: center;
`

const Box = styled.div`
  display: flex;
  background: #f8f6ff;
  border-radius: 6px;
  align-items: center;
  padding: 20px;
`

const BoxText = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-right: 16px;
`

const Content = styled.h3`
  margin-bottom: 24px;
`

const BottomText = styled.div`
  font-size: 14px;
  margin-top: 16px;
`

export default RequestInvite
