import React from 'react'
import styled from '@emotion/styled'
import ComingSoon from 'components/layout/ComingSoon'
import DealsImage from './vendor-workflow.png'

function DealsComingSoon() {
  return (
    <Root>
      <ComingSoon
        title="Your no code third-party app integration & automation platform"
        image={DealsImage}
      >
        No more one-off integrations. Your App use case knowledge base. Build your own full stack
        solutions. Coming Q2-Q3
      </ComingSoon>
    </Root>
  )
}

const Root = styled.div``

export default DealsComingSoon
