import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { TextInput, FormField, Dropdown, TextArea, Label } from 'components/theme/form'
import { Button } from 'components/theme'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { Formik, Form } from 'formik'
import { extractPictureUrl } from 'services/image'
import * as yup from 'yup'
import { useOrg } from 'data/organization/useOrg'
import { useSuperAdminUpdateOrg } from 'data/hooks'
import { OrganizationSubtype, OrganizationType } from 'generated/graphql'
import ExcerptDescription from 'components/ExcerptDescription'
import RichEditor from 'components/fields/RichEditor'
import { draftToMarkdown } from 'markdown-draft-js'
import { EditorState, convertToRaw } from 'draft-js'

const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Required'),
  logo: yup.mixed(),
})

interface Values {
  name: string
  website: string
  domain: string
  excerpt?: string
  description?: any
  wideLogo: any
  logo: any
  type?: OrganizationType
  subtype?: OrganizationSubtype
}

const defaultValues = {
  name: '',
  website: '',
  domain: '',
  excerpt: '',
  description: EditorState.createEmpty(),
  type: OrganizationType.Company,
  subtype: OrganizationSubtype.InHouse,
  logo: [],
  wideLogo: [],
}

const UpdateOrgForm = ({
  orgId,
  initialValues = defaultValues,
  onComplete,
}: {
  orgId: string
  slug: string
  initialValues?: Values
  onComplete: () => void
}) => {
  const { organization } = useOrg()
  const updateOrg = useSuperAdminUpdateOrg()

  const onSubmit = async ({
    name,
    website,
    domain,
    logo,
    type,
    subtype,
    excerpt,
    description,
    companySize,
  }: any) => {
    const logoUrl = await extractPictureUrl(logo)
    const markdownDescription = draftToMarkdown(convertToRaw(description.getCurrentContent()))

    await updateOrg.mutateAsync({
      id: orgId,
      name,
      domain,
      type,
      subtype,
      excerpt,
      description: markdownDescription,
      details: {
        ...organization?.details,
        website,
        logo: logoUrl,
        companySize,
      },
    })

    onComplete()
  }

  return (
    <Root>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <FormField>
              <SplitColumn>
                <WideLogo>
                  <UploadSingleAsset
                    label="Wide Logo"
                    name="wideLogo"
                    selectedImage={values.wideLogo}
                    styles={css`
                      max-width: none;
                    `}
                    fluidWidth
                  >
                    Upload Wide Logo
                  </UploadSingleAsset>
                </WideLogo>
                <SmallLogo>
                  <UploadSingleAsset
                    label="Square Logo"
                    name="logo"
                    selectedImage={values.logo}
                    required
                  >
                    Square Logo
                  </UploadSingleAsset>
                </SmallLogo>
              </SplitColumn>
            </FormField>
            <FormField>
              <TextInput label="Company Name" name="name" placeholder="My Company Inc." required />
            </FormField>
            <FormField>
              <TextInput label="Email Domain" name="domain" placeholder="example.com" required />
            </FormField>
            <FormField>
              <Dropdown
                fluid
                selection
                label="Type"
                setFieldValue={setFieldValue}
                options={companyType}
                value={values.type}
                name="type"
                placeholder="Select Company Type"
              />
            </FormField>
            <FormField>
              <Dropdown
                fluid
                selection
                label="Subtype"
                setFieldValue={setFieldValue}
                options={companySubtype}
                value={values.subtype}
                name="subtype"
                placeholder="Select Company Subtype"
              />
            </FormField>
            <FormField>
              <TextInput label="Website" name="website" placeholder="https://example.com" />
            </FormField>
            <FormField>
              <TextArea
                label="Excerpt"
                name="excerpt"
                description={<ExcerptDescription description={values.excerpt} maxLength={135} />}
              />
            </FormField>
            <FormField>
              <Label helpContent="">Main Description</Label>
              <RichEditor
                editorState={values.description}
                onChange={setFieldValue}
                name="description"
              />
            </FormField>
            <ButtonContainer>
              <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                Update
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

export const companyType = [
  { text: 'Vendor', value: 'VENDOR' },
  { text: 'Law firm', value: 'LAWFIRM' },
  { text: 'Company', value: 'COMPANY' },
  { text: 'Association', value: 'ASSOCIATION' },
]

export const companySubtype = [
  { text: 'Software', value: 'SOFTWARE' },
  { text: 'consultant', value: 'CONSULTANT' },
  { text: 'ALSP', value: 'ALSP' },
  { text: 'Law Firm', value: 'LAWFIRM' },
  { text: 'In House', value: 'IN_HOUSE' },
]

const Root = styled.div`
  max-width: 760px;
`

const SplitColumn = styled.div`
  display: flex;
`

const WideLogo = styled.div`
  flex: 1;
  margin-right: 20px;
`

const SmallLogo = styled.div`
  width: 150px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

export default UpdateOrgForm
