import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  productId: number
  description: string
}
export const useUpdateProductDescription = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { productId, description } = variables

      await request(UPDATE_VENDOR_PRODUCT, {
        id: productId,
        description,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

const UPDATE_VENDOR_PRODUCT = gql`
  mutation UpdateProductDescription($id: Int!, $description: String) {
    updateProduct(input: { patch: { description: $description }, id: $id }) {
      clientMutationId
    }
  }
`
