import StyleButton from './StyleButton'

const INLINE_STYLES = [
  { label: 'Bold', icon: 'bold', style: 'BOLD' },
  { label: 'Italic', icon: 'italic', style: 'ITALIC' },
  { label: 'Underline', icon: 'underline', style: 'UNDERLINE' },
]

function InlineStyleControls(props: any) {
  const currentStyle = props.editorState.getCurrentInlineStyle()

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
    </div>
  )
}

export default InlineStyleControls
