// import styled from '@emotion/styled'
import { Button, Icon } from 'components/theme'
import { Formik, Form, FieldArray } from 'formik'
import {
  Multiselect,
  FormField,
  Label,
  Radio,
  TextArea,
  TextInput,
  Dropdown,
} from 'components/theme/form'
import { useQueryProductsDropdown } from 'data/hooks'
import { useUpdateAssocHome, useAssocHome } from 'data/hooks'
import PulsatingLoader from 'components/PulsatingLoader'
import styled from '@emotion/styled'
import { v4 as uuid } from 'uuid'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { extractPictureUrl } from 'services/image'

interface Props {
  assocHomeId: number
  onSuccess: () => void
}

interface Slide {
  id: string
  slideType: string
  title: string
  text: string
  link: string
  image: string
  productId: number
}

interface Values {
  productIds: number[]
  sliderType: string
  slides: Slide[]
}
const LargeProductForm = ({ assocHomeId, onSuccess }: Props) => {
  const { data: productOptions } = useQueryProductsDropdown()
  const { assocHome, isLoading } = useAssocHome(assocHomeId)
  const updateAssocHome = useUpdateAssocHome()

  const onSubmit = async (values: Values) => {
    let details = values

    if (values.slides.length > 0) {
      const slides = await Promise.all(
        values.slides.map(async (slide) => {
          const image = await extractPictureUrl(slide.image)

          return {
            ...slide,
            image,
          }
        })
      )

      details = { ...details, slides }
    }

    await updateAssocHome.mutateAsync({ id: assocHomeId, details })
    onSuccess()
  }

  if (isLoading) return <PulsatingLoader />

  return (
    <Formik initialValues={assocHome?.details} onSubmit={onSubmit}>
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <FormField>
            <Label>Slider Type</Label>
            <RadioWrapper>
              <Radio name="sliderType" value="ALL_PRODUCTS" label="All Product Listing" />
            </RadioWrapper>
            <RadioWrapper>
              <Radio name="sliderType" value="PREMIUM_PRODUCTS" label="Premium Products Listing" />
            </RadioWrapper>
            <RadioWrapper>
              <Radio name="sliderType" value="SELECTED_PRODUCTS" label="Selected Products" />
            </RadioWrapper>
            <RadioWrapper>
              <Radio name="sliderType" value="CUSTOM" label="Custom" />
            </RadioWrapper>
          </FormField>
          {values.sliderType === 'CUSTOM' && (
            <FieldArray name="slides">
              {({ push, remove }) => {
                return (
                  <SlidesWrapper>
                    {values.slides.length > 0 &&
                      values.slides.map((slide, index) => (
                        <SlideWrapper key={slide.id}>
                          <FormField>
                            <SlideTypeWrapper>
                              <Selection>
                                <RadioWrapper>
                                  <Radio
                                    name={`slides.${index}.slideType`}
                                    value="CUSTOM"
                                    label="Custom"
                                  />
                                </RadioWrapper>
                                <RadioWrapper>
                                  <Radio
                                    name={`slides.${index}.slideType`}
                                    value="PRODUCT"
                                    label="Product"
                                  />
                                </RadioWrapper>
                              </Selection>
                              <ActionWrapper>
                                <CloseWrapper onClick={() => remove(index)}>
                                  <CloseIcon name="close" />
                                </CloseWrapper>
                              </ActionWrapper>
                            </SlideTypeWrapper>
                            {slide.slideType === 'PRODUCT' && (
                              <SlideContentWrapper>
                                <MultiSelectWrapper>
                                  <Dropdown
                                    name={`slides.${index}.productId`}
                                    options={productOptions}
                                    setFieldValue={setFieldValue}
                                    value={values.slides[index].productId}
                                    label="Product"
                                    search
                                  />
                                </MultiSelectWrapper>
                              </SlideContentWrapper>
                            )}
                            {slide.slideType === 'CUSTOM' && (
                              <SlideContentWrapper>
                                <FormField>
                                  <TextInput name={`slides.${index}.title`} label="Title" />
                                </FormField>
                                <FormField>
                                  <TextArea name={`slides.${index}.text`} label="Text" />
                                </FormField>
                                <FormField>
                                  <TextInput name={`slides.${index}.link`} label="Link" />
                                </FormField>
                                <FormField>
                                  <UploadSingleAsset
                                    label="Image"
                                    name={`slides.${index}.image`}
                                    selectedImage={values.slides[index].image}
                                  />
                                </FormField>
                              </SlideContentWrapper>
                            )}
                          </FormField>
                          <FormField></FormField>
                        </SlideWrapper>
                      ))}

                    <AddSlideButton
                      type="button"
                      onClick={() => {
                        push({ id: uuid(), slideType: 'CUSTOM' })
                      }}
                      disabled={isSubmitting}
                    >
                      Add Slide +
                    </AddSlideButton>
                  </SlidesWrapper>
                )
              }}
            </FieldArray>
          )}
          {values.sliderType === 'SELECTED_PRODUCTS' && (
            <FormField>
              <MultiSelectWrapper>
                <Multiselect
                  name="productIds"
                  label="Add Products to the slider"
                  options={productOptions}
                  value={values.productIds}
                />
              </MultiSelectWrapper>
            </FormField>
          )}
          <ButtonWrapper>
            <Button type="submit">Update</Button>
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  )
}

const MultiSelectWrapper = styled.div`
  padding-bottom: 170px;
`

const RadioWrapper = styled.div`
  padding: 4px;
`

const AddSlideButton = styled.button`
  border-radius: 99px;
  font-weight: 500;
  letter-spacing: 0.02rem;
  background: ${({ theme }) => theme.color.button};
  color: white;
  border: none;
  padding: 4px 8px;

  &:hover {
    background: ${({ theme }) => theme.color.buttonHover};
  }
`

const ButtonWrapper = styled.div`
  padding-top: 28px;
`

const SlidesWrapper = styled.div`
  border-top: 1px solid #ccc;
  padding-top: 20px;
`

const SlideWrapper = styled.div`
  border: 1px solid #afb9cb;
  border-radius: 6px;
  margin-bottom: 16px;
  overflow: hidden;
`

const SlideContentWrapper = styled.div`
  padding: 8px 12px 12px;
`

const SlideTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  background: #f1f2f7;
  border-bottom: 1px solid #afb9cb;
`

const CloseIcon = styled(Icon)``

const CloseWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`

const Selection = styled.div``

const ActionWrapper = styled.div``

export default LargeProductForm
