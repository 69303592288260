import React, { useEffect } from 'react'
import { useField } from 'formik'
import styled from '@emotion/styled'

const DispatchInput = (props: any) => {
  const [field, meta] = useField(props)

  useEffect(() => {
    props.updateStepper(props.name, meta.value)
  }, [meta.value])

  // if (props.dates) return <CustomInput {...field} {...props} type="date "/>

  return props.area ? (
    <CustomTextArea {...field} {...props} />
  ) : (
    <CustomInput {...field} {...props} />
  )
}

export const DispatchFieldArrayInput = (props: any) => {
  const [field, meta] = useField(props)

  useEffect(() => {
    props.updateProvision(props.index, meta.value)
  }, [meta.value])

  return <CustomInput {...field} {...props} />
}

const CustomInput = styled.input`
  width: 100%;
  padding: 9.5px 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease, border-color 0.1s ease;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  flex: 1 0 auto;

  &::-webkit-input-placeholder {
    color: #616161;
  }

  &:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

const CustomTextArea = styled.textarea`
  width: 100%;
  min-height: 50px;
  margin: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0.78571429em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;

  &:placeholder {
    color: rgba(191, 191, 191, 0.87);
  }

  &:focus {
    color: rgba(0, 0, 0, 0.95);
    border-color: #85b7d9;
    border-radius: 0.28571429rem;
    background: #fff;
    box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.35) inset;
    -webkit-appearance: none;
  }
`

export default DispatchInput
