import React from 'react'
import styled from '@emotion/styled'

function ThankYou() {
  return (
    <Root>
      <Title>Thank You!</Title>
      <Content>You will receive an email from Prevalent shortly.</Content>
    </Root>
  )
}

const Root = styled.div`
  text-align: center;
`

const Title = styled.h1``

const Content = styled.p``

export default ThankYou
