import Button from 'components/theme/Button'
import { Dropdown } from 'semantic-ui-react'
import { Formik, Form } from 'formik'
import DropdownAllowAdditions from 'components/fields/DropdownAllowAdditions'
import { Label, FormField } from 'components/theme/form'
import { FlexLoading } from 'components/PulsatingLoader'
import { newDeletedList } from 'services/helper'
import styled from '@emotion/styled'
import * as yup from 'yup'

import PromptIfDirty from 'components/forms/PromptIfDirty'
import { useFeaturesDropdown } from './hooks/useFeaturesDropdown'
import { useTaxonomyDropdowns } from './hooks/useTaxonomyDropdowns'
import { useMutateFeatures } from './hooks/useMutateFeatures'
import { useMutateTermsToProduct } from 'data/hooks'

const validationSchema = yup.object().shape({
  productCategories: yup.array().min(1).max(1).required(),
  productSubcategories: yup.array().min(1).max(7).required(),
  practiceGroups: yup.array().max(10),
  bestFor: yup.array().max(5),
  country: yup.array().max(3),
  features: yup.array().min(1).max(5).required(),
})

const defaultValues = {
  productCategories: [],
  productSubcategories: [],
  practiceGroups: [],
  countries: [],
  languages: [],
  features: [],
  bestFor: [],
  deployments: [],
  roles: [],
}

function CategoriesForm({
  currentProductId,
  onComplete,
  initialValues = defaultValues,
  submitButtonText = 'NEXT (ADD DESCRIPTION)',
  isService = false,
}: any) {
  const { data: featuresDropdown, isLoading: featuresLoading } = useFeaturesDropdown()

  const {
    categoryDropdown,
    subcategoryDropdown,
    practiceGroupDropdown,
    bestForDropdown,
    deploymentsDropdown,
    rolesDropdown,
    countryDropdown,
    languageDropdown,
    isLoading: isTaxonomyDropdownsLoading,
  } = useTaxonomyDropdowns()
  const mutateFeatures = useMutateFeatures(currentProductId)
  const mutateTermsToProduct = useMutateTermsToProduct()

  if (featuresLoading || isTaxonomyDropdownsLoading) {
    return <FlexLoading />
  }

  const onSubmit = async ({
    features,
    bestFor,
    productCategories,
    productSubcategories,
    practiceGroups,
    countries,
    languages,
    deployments,
    roles,
  }: any) => {
    try {
      const currentTerms = [
        ...bestFor,
        ...productCategories,
        ...productSubcategories,
        ...practiceGroups,
        ...countries,
        ...languages,
        ...deployments,
        ...roles,
      ]

      const initialTerms = [
        ...initialValues.bestFor,
        ...initialValues.productCategories,
        ...initialValues.productSubcategories,
        ...initialValues.practiceGroups,
        ...initialValues.countries,
        ...initialValues.languages,
        ...initialValues.deployments,
        ...initialValues.roles,
      ]

      const [createdTermIds, deletedTermIds] = newDeletedList(initialTerms, currentTerms)

      const isServiceHasServicesTerm = isService && !initialTerms.some((termId) => termId === 682)

      await mutateTermsToProduct.mutateAsync({
        productId: currentProductId,
        createdTermIds: isServiceHasServicesTerm ? [...createdTermIds, 682] : createdTermIds,
        deletedTermIds,
      })
      await mutateFeatures.mutateAsync({ features, initialValue: initialValues.features })
    } catch (e) {
      console.error(e)
    }

    onComplete()
  }

  return (
    <Root>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ values, setFieldValue, isSubmitting, isValid }) => (
          <Form>
            <PromptIfDirty />
            <FormField>
              <Label required>{isService ? 'Service' : 'Application'} Category</Label>
              <Dropdown
                fluid
                selection
                search
                onChange={(_e, { value }) => setFieldValue('productCategories', [value])}
                options={categoryDropdown}
                value={
                  typeof values.productCategories === 'object'
                    ? values.productCategories[0]
                    : values.productCategories
                }
                name="productCategories"
                placeholder={`Type of ${isService ? 'Service' : 'Application'}`}
              />
            </FormField>

            <FormField>
              <Label required>{isService ? 'Service' : 'Application'} Sub Category (up to 7)</Label>
              <Dropdown
                fluid
                selection
                search
                multiple
                onChange={(_e, { value }) => setFieldValue('productSubcategories', value)}
                options={subcategoryDropdown}
                value={values.productSubcategories}
                name="productSubcategories"
                placeholder={`Type of ${isService ? 'Service' : 'Application'}`}
              />
            </FormField>

            <FormField>
              <Label helpContent="Recommendation: Select/indicate Transactional or Litigation">
                {isService ? 'Type of Practice' : 'Target Practice Group (up to 10)'}
              </Label>
              <Dropdown
                fluid
                selection
                search
                multiple
                onChange={(_e, { value }) => setFieldValue('practiceGroups', value)}
                options={practiceGroupDropdown}
                value={values.practiceGroups}
                name="practiceGroups"
                placeholder={`Practice ${isService ? '' : 'Groups'}`}
              />
            </FormField>

            <FormField>
              <Label>Best For (up to 5)</Label>
              <Dropdown
                fluid
                selection
                search
                multiple
                onChange={(_e, { value }) => setFieldValue('bestFor', value)}
                options={bestForDropdown}
                value={values.bestFor || []}
                name="bestFor"
                placeholder="Best For"
              />
            </FormField>

            {!isService && (
              <FormField>
                <Label>Deployments</Label>
                <Dropdown
                  fluid
                  selection
                  search
                  multiple
                  onChange={(_e, { value }) => setFieldValue('deployments', value)}
                  options={deploymentsDropdown}
                  value={values.deployments || []}
                  name="deployments"
                  placeholder="Deployments"
                />
              </FormField>
            )}

            <FormField>
              <Label>Roles</Label>
              <Dropdown
                fluid
                selection
                search
                multiple
                value={values.roles || []}
                onChange={(_e, { value }) => setFieldValue('roles', value)}
                options={rolesDropdown}
                name="roles"
                placeholder="Roles"
              />
            </FormField>

            <FormField>
              <Label helpContent="Task related keywords." required>
                Keywords (up to 5)
              </Label>
              <DropdownAllowAdditions
                name="features"
                defaultValue={values.features}
                options={featuresDropdown}
                placeholder="Task keywords"
                onChange={setFieldValue}
              />
            </FormField>

            <FormField>
              <Label helpContent="Where product is sold, leave blank for all.">Target market</Label>
              <Dropdown
                fluid
                selection
                search
                multiple
                name="countries"
                value={values.countries}
                options={countryDropdown}
                placeholder="Country (leave blank for all)"
                onChange={(_e, { value }) => setFieldValue('countries', value)}
              />
            </FormField>

            <FormField>
              <Label helpContent="Supported">Languages</Label>
              <Dropdown
                fluid
                selection
                search
                multiple
                name="languages"
                defaultValue={values.languages}
                options={languageDropdown}
                placeholder="Defaults to English"
                onChange={(_e, { value }) => setFieldValue('languages', value)}
              />
            </FormField>

            <FormField>
              <ButtonContainer>
                <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                  {submitButtonText}
                </Button>
              </ButtonContainer>
            </FormField>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

// const parseFeatures = (product) => {
//   const features = featuresData.map(({ name }) => name)

//   return features.length > 0 ? features.join(', ') : ''
// }

const Root = styled.div`
  max-width: 760px;
  width: 100%;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

export default CategoriesForm
