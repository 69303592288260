import Hero from 'components/marketing/Hero'
import HeroImage from 'assets/marketing/lawfirm/theorem-cloud.png'

type Props = {
  executeScroll: () => void
}
const PlansHero = ({ executeScroll }: Props) => {
  return (
    <Hero
      title="You’re Missing out!"
      subtitle="Access Premium Features"
      text="Upgrade for more power &amp; access premium team features."
      buttonText="Upgrade Today"
      image={HeroImage}
      executeScroll={executeScroll}
    />
  )
}

export default PlansHero
