import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'
import ComingSoonWidget from './ComingSoonWidget'
import UpgradeToPremiumBox from './UpgradeToPremiumBox'
import TermScoutBox from './TermScoutBox'
import PrevalentBox from './PrevalentBox'
import OfferADealWidget from './OfferADealWidget'
import EltaWidget from './EltaWidget'

// TODO: this function basically finds if one product that is pending exist. If one exist then we
// switch the activate button to pending. We need to make sure that if there is still an inactive
// product, then keep the ACTIVATE button going even if a product is pending. this will do for now
// due to a need of functionality push.
function PrevalentWidget() {
  return (
    <Root>
      <Content>Product profile upgrades for security, compliance and growth.</Content>
      <BoxesWrapper>
        <PrevalentBox />

        <UpgradeToPremiumBox />

        <TermScoutBox />

        <ComingSoonWidget
          icon=""
          title="Theorem SDK"
          subtitle="Universal API"
          content="Theorem aims to provide limitless connection to all the apps and tools available on our ecosystem."
        />

        <OfferADealWidget />

        <ComingSoonWidget
          icon=""
          title="Theorem Workbench"
          subtitle="NoCode Workflow"
          content="Build matter based tech stacks and help clients automate interactions across their stack with your solutions built right in. Automation and retention are the new growth engines!"
        />
        <EltaWidget />
      </BoxesWrapper>
    </Root>
  )
}

const Root = styled.div``

const BoxesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px 16px;

  ${breakpoint.desktop} {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 940px) {
    grid-template-columns: auto;
  }
`

const Content = styled.div`
  color: #545454;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.5;
`

export default PrevalentWidget
