import styled from '@emotion/styled'
import { useWizardModal } from 'components/modals/WizardModal'
import AccCompanyPlans from './AccCompanyPlans'
import ClocCompanyPlans from './ClocCompanyPlans'
import EltaCompanyPlans from './EltaCompanyPlans'

const CompanyPlans = () => {
  const { data } = useWizardModal()

  const { networkSlug } = data

  return (
    <Root>
      {networkSlug === 'acc' && <AccCompanyPlans />}
      {networkSlug === 'cloc' && <ClocCompanyPlans />}
      {networkSlug === 'elta' && <EltaCompanyPlans />}
    </Root>
  )
}

const Root = styled.div``

export default CompanyPlans
