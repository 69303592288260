import { Fragment } from 'react'
import styled from '@emotion/styled'
import Icon from 'components/theme/Icon'
import { useWizardContext } from './prevalentContext'

const CurrentStepper = () => {
  const { stepIndex, jumpToStep, wizardData } = useWizardContext()

  const onStepClick = (index: any) => () => {
    if (stepIndex < 4) {
      jumpToStep(index)
    }
  }

  return (
    <Root>
      <StepperWrapper>
        <StepperContainer>
          {wizardData.map((step: any, index: number) => (
            <Fragment key={`step-icon-${index}`}>
              <StepIcon
                onClick={onStepClick(index)}
                active={stepIndex === index}
                filled={stepIndex > index}
              >
                {wizardData[index].completed && <CheckIcon name="check" />}
              </StepIcon>
              {index < wizardData.length - 1 && <StepLine active={index < stepIndex} />}
            </Fragment>
          ))}
        </StepperContainer>
      </StepperWrapper>
      {stepIndex < 4 && <Title>{wizardData[stepIndex].title}</Title>}
    </Root>
  )
}

const Root = styled.div`
  margin-bottom: 40px;
`

const StepperWrapper = styled.div`
  margin: 0 auto;
  max-width: 240px;
`

const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StepIcon = styled.div<{ active: boolean; filled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 3px solid ${(props) => (props.active || props.filled ? '#b86be0' : '#bdbdbd')};
  background: ${(props) => (props.filled ? '#b86be0' : 'white')};

  &:nth-of-type(3) {
    border: 3px solid ${(props) => (props.active || props.filled ? '#948ddb' : '#bdbdbd')};
    background: ${(props) => (props.filled ? '#948ddb' : 'white')};
  }

  &:nth-of-type(5) {
    border: 3px solid ${(props) => (props.active || props.filled ? '#70aed6' : '#bdbdbd')};
    background: ${(props) => (props.filled ? '#70aed6' : 'white')};
  }

  &:nth-of-type(7) {
    border: 3px solid ${(props) => (props.active || props.filled ? '#4cd0d1' : '#bdbdbd')};
    background: ${(props) => (props.filled ? '#4cd0d1' : 'white')};
  }
  cursor: pointer;
`

const StepLine = styled.div<{ active: boolean }>`
  height: 4px;
  flex: 1 1 0;
  background: rgb(184, 107, 224);
  background: ${(props) =>
    props.active
      ? 'linear-gradient(90deg, rgba(184,107,224,1) 0%, rgba(148,141,219,1) 100%)'
      : '#bdbdbd'};

  &:nth-of-type(4) {
    background: rgb(148, 141, 219);
    background: ${(props) =>
      props.active
        ? 'linear-gradient(90deg, rgba(148,141,219,1) 0%, rgba(112,174,214,1) 100%)'
        : '#bdbdbd'};
  }

  &:nth-of-type(6) {
    background: rgb(112, 174, 214);
    background: ${(props) =>
      props.active
        ? 'linear-gradient(90deg, rgba(112,174,214,1) 0%, rgba(76,208,209,1) 100%)'
        : '#bdbdbd'};
  }
`

const CheckIcon = styled(Icon)`
  color: white;
  width: 16px;
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 20px;
  text-align: center;
`

export default CurrentStepper
