import { FlexLoading } from 'components/PulsatingLoader'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { Dropdown } from 'semantic-ui-react'
import { Formik, Form } from 'formik'
import { TextInput, FormField } from 'components/theme/form'
import Button from 'components/theme/Button'
import Label from 'components/theme/form/Label'
import styled from '@emotion/styled'
import { useTaxonomyDropdown } from 'hooks/useTaxonomyDropdown'
import { useMutateViewer } from 'data/viewer/useMutateViewer'
import * as yup from 'yup'

export const defaultValues = {
  first: '',
  last: '',
  jobTitle: '',
  orgJobTitle: '',
  fileUrl: [],
}

const validationSchema = yup.object().shape({
  first: yup.string().trim().required('Required').nullable(),
  last: yup.string().trim().required('Required').nullable(),
})

function VendorUserForm({
  onComplete,
  initialValues = defaultValues,
  submitButtonText = 'CREATE VENDOR',
  showImageUpload = true,
}: any) {
  const mutateViewer = useMutateViewer()
  const { options, isLoading: taxonomyLoading } = useTaxonomyDropdown({ taxonomyId: 9 })

  const onSubmit = async ({
    first,
    last,
    jobTitle,
    orgJobTitle,
    fileUrl,
    linkedin,
    twitter,
  }: any) => {
    mutateViewer.mutateAsync({ first, last, jobTitle, orgJobTitle, fileUrl, linkedin, twitter })
    onComplete()
  }

  if (taxonomyLoading) return <FlexLoading />

  return (
    <Formik
      initialValues={{ ...initialValues }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isValid, isSubmitting }) => (
        <Form>
          {showImageUpload && (
            <FormField>
              <UploadSingleAsset
                label="Profile Image"
                name="fileUrl"
                selectedImage={values.fileUrl}
              >
                Upload Profile Image
              </UploadSingleAsset>
            </FormField>
          )}
          <FormField>
            <SplitInput>
              <Left>
                <TextInput label="First Name" placeholder="John" name="first" required />
              </Left>
              <Right>
                <TextInput label="Last Name" placeholder="Doe" name="last" required />
              </Right>
            </SplitInput>
          </FormField>
          <FormField>
            <TextInput label="Job Title" placeholder="job title..." name="jobTitle" />
          </FormField>
          <FormField>
            <Label required>Role</Label>
            <Dropdown
              fluid
              selection
              search
              options={options}
              name="orgJobTitle"
              value={values.orgJobTitle}
              onChange={(_e, { value }) => setFieldValue('orgJobTitle', value)}
            />
          </FormField>
          <FormField>
            <SplitInput>
              <Left>
                <TextInput
                  label="LinkedIn Profile"
                  placeholder="https://www.linkedin.com/in/john-doe"
                  name="linkedin"
                />
              </Left>
              <Right>
                <TextInput label="Twitter Handle" placeholder="@TheoremLTS" name="twitter" />
              </Right>
            </SplitInput>
          </FormField>
          <ButtonContainer>
            <Button disabled={!isValid || isSubmitting} loading={isSubmitting} type="submit">
              {submitButtonText}
            </Button>
          </ButtonContainer>
        </Form>
      )}
    </Formik>
  )
}

const ButtonContainer = styled.div`
  margin-top: 32px;
`

const SplitInput = styled.div`
  display: flex;
`

const Left = styled.div`
  width: 50%;
  margin-right: 10px;
`

const Right = styled.div`
  width: 50%;
  margin-left: 10px;
`

export default VendorUserForm
