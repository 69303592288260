import React from 'react'
import AdminApps from './AdminApps'
import { Root, Description, ProductsBox, BoxTitle } from './VendorProfile'


const AdminProfile:React.FC = () => {

  // REDIRECT IF NOT THE RIGHT KIND OF USER 
  return (
    <Root>
      <BoxTitle>All Products</BoxTitle>
      <Description>Click below to create or edit products. Any products you create here will <b>not</b> be in test mode. Products made in other editors will be in test mode.</Description>
      <ProductsBox>
        <AdminApps />
      </ProductsBox>
    </Root>
  )
}

export default AdminProfile
