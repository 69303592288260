import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { ErrorText, FormTitle, Description } from './styles'
import DropdownAllowAdditions from 'components/fields/DropdownAllowAdditions'
import { StoryUpdates } from 'interfaces/userStory/Helpers'
import { useOrg } from 'data/organization/useOrg'

const StageForm: React.FC<{ onChange: (values: StoryUpdates) => void }> = ({ onChange }) => {
  const { organization } = useOrg()
  const { values, errors, touched, initialValues, setFieldValue } = useFormikContext<{
    stage: string[]
  }>()

  useEffect(() => {
    onChange(initialValues)
  }, [JSON.stringify(initialValues)])

  const defaultOptions = [
    'Client Prospecting',
    'Client Intake',
    'Performing the Work',
    'Managing the Work',
    'Firm Governance',
  ]

  const getInitialStageOptions = (stage: string[], customValues: string[] = []) => {
    const customs = customValues.filter((value) => !stage.includes(value))
    return [...stage, ...customs].map((stageValue) => ({
      key: stageValue,
      text: stageValue,
      value: stageValue,
    }))
  }

  return (
    <>
      <FormTitle>Stage</FormTitle>
      <Description>
        At what stage of {organization?.type === 'VENDOR' ? 'your clients' : 'your'} workflow is
        this product most useful?
        <br />
        <br />
        Leave blank if not applicable.
      </Description>
      <DropdownAllowAdditions
        name="stage"
        placeholder=""
        defaultValue={values.stage}
        options={getInitialStageOptions(defaultOptions, initialValues.stage)}
        onChange={(name: any, value: any) => {
          setFieldValue(name, value)
          initialValues.stage = value
        }}
      />
      {errors.stage && touched.stage && <ErrorText>{errors.stage}</ErrorText>}
    </>
  )
}

export default StageForm
