import React from 'react'
import styled from '@emotion/styled'
import { useStories } from 'context/userStoriesContext'
import FoldoutStory from 'components/UserStories/FoldoutStory'

const UserStories: React.FC<{ userId: number }> = ({ userId }) => {
  const { authorSpecificStories } = useStories()

  if (authorSpecificStories(userId).length === 0)
    return <Text>This user hasn't created any user stories</Text>

  return (
    <>
      {authorSpecificStories(userId).map((Story) => (
        <FoldoutStory Story={Story} />
      ))}
    </>
  )
}

const Text = styled.div`
  text-align: center;
  font-size: 16px;
  opacity: 0.6;
  font-weight: 600;
  margin-top: 24px;
`

export default UserStories
