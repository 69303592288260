import { useState } from 'react'
import { Dropdown } from 'semantic-ui-react'

function DropdownAllowAdditions({
  name,
  defaultValue = [],
  options: newOptions,
  placeholder,
  turnOffAdditions = false,
  onChange,
}: any) {
  const [options, setOptions] = useState(newOptions)
  const [currentValue, setCurrentValue] = useState(defaultValue)

  /* useEffect(() => {
    if (currentValue.some((value) => !options.includes(value))) {

      const newAdditions = currentValue.filter((value) => !options.includes(value))
      newAdditions.forEach((addition) => handleAddition('', { value: addition }))
    }
  }, []) */

  const handleAddition = (_e: any, { value }: any) => {
    setOptions((prevOptions: any) => [
      {
        text: value,
        key: value,
        value,
      },
      ...prevOptions,
    ])
  }

  const handleChange = (_e: any, { value }: any) => {
    setCurrentValue(value)
    onChange(name, value)
  }

  return (
    <Dropdown
      options={options}
      name={name}
      placeholder={placeholder}
      search
      selection
      fluid
      multiple
      allowAdditions={!turnOffAdditions}
      value={currentValue}
      onAddItem={handleAddition}
      onChange={handleChange}
    />
  )
}

export default DropdownAllowAdditions
