import React, { useEffect } from 'react'
import { useField } from 'formik'
import { Dropdown as SemanticDropdown } from 'semantic-ui-react'
import Label from './Label'

function Multiselect({
  options = [],
  label = '',
  required = false,
  updateStepper = undefined,
  placeholder = '',
  ...props
}: any) {
  const [, meta, helpers] = useField(props)

  useEffect(() => {
    if (updateStepper) updateStepper(meta.value)
  }, [JSON.stringify(meta.value)])

  const { value } = meta
  const { setValue } = helpers

  return (
    <>
      <label>
        {label && <Label required={required}>{label}</Label>}
        <SemanticDropdown
          fluid
          selection
          multiple
          search
          onChange={(_e, { value }) => setValue(value)}
          options={options}
          name={props.name}
          value={value}
          placeholder={placeholder}
        />
      </label>
    </>
  )
}

export default Multiselect
