import React, { useState } from 'react'
import { toast } from 'react-toastify'
import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'
import { BarWrapper } from 'components/forms/SelectTechStack/SearchBar'
import Pagination from './Pagination'
import ResultCard from './ResultCard'
import ConnectOrganizationModal from './ConnectOrganizationModal'
import { useParams } from 'react-router'
import { useCreateUserToOrg, useSearchOrgs } from 'data/hooks'
import { OrganizationType, UserRole } from 'generated/graphql'

export type SearchResult = {
  name: string
  slug: string
  type: OrganizationType
  domain: string
  isClaimed: boolean
  logo: string
  id: string
}

const DiscoverOrganizations: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedOrg, setSelectedOrg] = useState<SearchResult | null>(null)
  const { orgType } = useParams<{ orgType?: string }>()
  const [orgTypeFilter, setOrgTypeFilter] = useState<string | undefined>(
    orgType ? orgType.toUpperCase() : undefined
  )
  const [pageCount, setPageCount] = useState<number>(0)
  const createUserToOrg = useCreateUserToOrg()
  const { orgs } = useSearchOrgs({
    searchString: searchQuery,
    type: orgTypeFilter,
    page: pageCount,
  })

  const getNextSetOfResults = (page: number) => {
    setPageCount(page)
  }

  const printResults = (results: SearchResult[]) => {
    return results.map((result, i) => (
      <ResultCard key={`result-${i}`} result={result} onClick={() => setSelectedOrg(result)} />
    ))
  }

  return (
    <Box>
      <Container>
        {selectedOrg && (
          <ConnectOrganizationModal
            organization={selectedOrg}
            toggle={() => setSelectedOrg(null)}
            isShowing={!!selectedOrg}
            adminJoin={async () => {
              try {
                await createUserToOrg.mutateAsync({
                  organizationId: selectedOrg.id,
                  role: UserRole.SuperAdmin,
                })
                toast.success(`You joined ${selectedOrg.name}`)
              } catch (error) {
                toast.warn(`Something went wrong joining ${selectedOrg.name}, please try again`)
                console.error(error)
              }
            }}
          />
        )}
        <Head>
          <Bar>
            <Search
              type="search"
              placeholder="Search organizations by name"
              onChange={({ target: { value } }) => {
                setSearchQuery(value)
              }}
            />
          </Bar>
          <Dropdown
            value={orgTypeFilter}
            onChange={({ target: { value } }) => setOrgTypeFilter(value)}
          >
            <option value={undefined}>Organization Type</option>
            <option value="LAWFIRM">Law Firm</option>
            <option value="VENDOR">Vendor</option>
            <option value="ASSOCIATION">Association</option>
          </Dropdown>
        </Head>
        {orgs && orgs.length > 0 && <Results>{printResults(orgs)}</Results>}
        <Pagination pageCount={pageCount} onChange={getNextSetOfResults} />
      </Container>
    </Box>
  )
}

const Dropdown = styled.select`
  margin-right: 8px;
  height: 32px;
  border-radius: 10px;
`

const Head = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 15px;
  width: 100%;
  padding: 0 10px;
`

const Results = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  margin-left: -10px;
  margin-right: -10px;
`

const Box = styled(BoxWrapper)`
  padding: 20px;
  min-height: 600px;
`

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Bar = styled(BarWrapper)`
  height: 32px;
  margin-right: 8px;
`

const Search = styled.input`
  width: 100%;
  margin: 0;
  border: none;
`

export default DiscoverOrganizations
