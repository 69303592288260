import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Tooltip } from 'components/theme'
import { ParseError } from './CsvToTechstack'
import { Icon } from 'components/theme'
import CsvMenu from './CsvMenu'
import { ErrorList, HowTo } from './modalContent'
import Modal from 'components/Modal'

type Modals = 'ERROR_LIST' | 'HOW_TO'

type CsvProps = {
  fileAction: (file: File | null) => void
  errorList?: ParseError[]
  clearCsv: () => void
  csvLoading: boolean
}

const CsvActions: React.FC<CsvProps> = ({ csvLoading, clearCsv, fileAction, errorList = [] }) => {
  const [file, setFile] = useState<File | null>(null)
  const [whichModal, setModal] = useState<Modals | null>(null)

  useEffect(() => {
    fileAction(file)
  }, [file, fileAction])

  const determineColor = () => {
    if (errorList.length > 0) return 'red'
    if (file) return 'green'
    return '#498de6'
  }

  return (
    <>
      <Tooltip
        tooltip={
          <CsvMenu
            activeFile={!!file}
            removeFile={() => {
              clearCsv()
              setFile(null)
            }}
            errorList={errorList}
            setModal={setModal}
          />
        }
        placement="bottom"
      >
        <input
          // has to go here because if part of the tooltip it will be removed from DOM
          id="file-upload"
          type="file"
          accept=".csv"
          style={{ display: 'none' }}
          onChange={(event: any) => event.target.files && setFile(event.target.files[0])}
        />
        <CsvTab color={determineColor()} id="csv-action-tab">
          {csvLoading ? (
            <Icon name="spinner" spin />
          ) : (
            <>
              {file ? file.name : 'CSV Actions'}
              {errorList.length > 0 && <Count>{errorList.length}</Count>}
            </>
          )}
        </CsvTab>
      </Tooltip>
      <Modal
        isShowing={whichModal !== null}
        toggleModal={() => setModal(null)}
        showClose={false}
        removePadding
      >
        {whichModal === 'ERROR_LIST' && <ErrorList errorList={errorList} />}
        {/*
{whichModal === 'HOW_TO' && <HowTo />}
        */}
        <HowTo />
      </Modal>
    </>
  )
}

const Count = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  border-radius: 50%;
  background-color: #f44336;
  color: white;
  font-size: 10px;
  margin-left: 8px;
`

const CsvTab = styled.div<{ color?: string }>`
  display: flex;
  color: ${({ color }) => (color ? color : '#498de6')};
  border: 2px solid ${({ color }) => (color ? color : '#498de6')};
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: transparent;
  transition: color 250ms, background 250ms, border 250ms, width 250ms;
  line-height: 1;
  outline: 0;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 16px;
  height: 49px;
  align-items: center;
  width: min-content;
  white-space: nowrap;

  &:hover {
    background: ${({ color }) => (color ? color : '#498de6')};
    color: white;
  }

  &:disabled,
  &[disabled] {
    border: 2px solid #9e9e9e;
    color: #9e9e9e;

    &:hover {
      background: transparent;
      cursor: not-allowed;
    }
  }
`

export default CsvActions
