import styled from '@emotion/styled'

const MenuOutLink = styled.a<{ hideactive?: string }>`
  display: block;
  height: ${({ theme }) => theme.topBarHeight};
  padding: 0 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: ${({ theme }) => theme.topBarHeight};
  position: relative;
  white-space: nowrap;
  color: #333333;

  &:after {
    display: ${({ hideactive }) => (hideactive ? 'none' : 'block')};
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.color.primaryPurple};
    height: 4px;
    width: 100%;
    transform-origin: center bottom;
    transform: scaleY(0);
    transition: transform 250ms;
  }

  &.active,
  &:hover {
    color: #333333;

    &:after {
      transform: scaleY(1);
    }
  }

  ${({ theme }) => theme.breakpoint.mobile} {
    height: 48px;
    line-height: 48px;
    padding-left: 28px;
    width: 100%;
    font-size: 20px;
    border-top: 2px solid #c1c1c1;
    letter-spacing: 0.02rem;

    &:before {
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      top: calc(50% - 3px);
      left: 10px;
      background: #9013fe;
      border-radius: 100px;
    }

    &:after {
      display: none;
    }

    &:hover {
      background: #eee;
    }
  }
`

export default MenuOutLink
