import React from 'react'
import styled from '@emotion/styled'
import { Formik, Form, FieldArray } from 'formik'
import {
  FormField,
  Label,
  TextArea,
  TextInput,
  TrueFalse,
  StripePriceIdInput,
} from 'components/theme/form'
import { Button } from 'components/theme'
import { Dropdown } from 'semantic-ui-react'
import * as yup from 'yup'
import { useTaxonomyDropdown } from 'hooks/useTaxonomyDropdown'
import PulsatingLoader from 'components/PulsatingLoader'
import { Values, Feature } from './types'
import TestDrivePreview from 'components/global/TestDrivePreview'
import { useOrg } from 'data/organization/useOrg'

const validationSchema = yup.object().shape({
  priceId: yup.string().required('Required').nullable(),
})

interface Props {
  defaultValues: Values
  onSubmit: (values: Values) => void
  buttonText?: string
}

const TestDriveForm: React.FC<Props> = ({ defaultValues, onSubmit, buttonText = 'Create' }) => {
  const { vendor } = useOrg()
  const { options: jobTitleOptions, isLoading: jobTitleLoading } = useTaxonomyDropdown({
    taxonomyId: 8,
  })
  const { options: lawfirmTypeOptions, isLoading: lawfirmLoading } = useTaxonomyDropdown({
    taxonomyId: 17,
  })
  const { options: practiceGroupOptions, isLoading: practiceGroupLoading } = useTaxonomyDropdown({
    taxonomyId: 13,
  })

  const productOptions = vendor?.products.map((product) => ({
    key: product.id,
    text: product.name,
    value: product.id,
  }))

  const getProduct = (productId?: number): any => {
    if (!productId) return
    return vendor?.products && vendor?.products.find((product) => product.id === productId)
  }

  const getProductName = (productId?: number) => {
    const product = getProduct(productId)

    if (!product) return ''

    return product.name
  }

  const getSquareLogo = (productId?: number) => {
    const product = getProduct(productId)

    if (!product) return ''

    return product.details.squareLogoUrl
  }

  if (jobTitleLoading || practiceGroupLoading || lawfirmLoading) return <PulsatingLoader />

  return (
    <Root>
      <Formik
        initialValues={{ ...defaultValues }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Container>
            <FormWrapper>
              <Form>
                <FormField>
                  <Label>Select Product for Test Drive</Label>
                  <Dropdown
                    selection
                    fluid
                    search
                    name="productId"
                    placeholder="Select Product for test drive"
                    label="Select Product for test drive"
                    onChange={(_e, { value }) => setFieldValue('productId', value)}
                    options={productOptions}
                    value={values.productId}
                  />
                </FormField>

                <FormField>
                  <TrueFalse
                    helpContent="Select this if your offer requires the input of another vendor from our ecosystem in order to complete the order (eg. you offer review, assessment or resale)"
                    label="Is Vendor in vendor"
                    text="Yes"
                    name="isVendorInVendor"
                  />
                </FormField>

                <FormField>
                  <Label>Stripe Price Id</Label>
                  <StripePriceIdInput name="priceId" />
                </FormField>

                <FormField>
                  <TextInput label="Free Trial Days" name="freeTrialDays" />
                </FormField>

                <UnderlineDivider>Who do you want to offer this to</UnderlineDivider>
                <FormField>
                  <Label>Lawfirm Type</Label>
                  <Dropdown
                    selection
                    fluid
                    search
                    multiple
                    name="lawfirmType"
                    placeholder="Lawfirm Type"
                    label="Lawfirm Type"
                    onChange={(_e, { value }) => setFieldValue('lawfirmType', value)}
                    options={lawfirmTypeOptions}
                    value={values.lawfirmType}
                  />
                </FormField>
                <FormField>
                  <Label>Lawfirm Job Title</Label>
                  <Dropdown
                    selection
                    fluid
                    search
                    multiple
                    name="jobTitle"
                    placeholder="Job Title"
                    label="Job Title"
                    onChange={(_e, { value }) => setFieldValue('jobTitle', value)}
                    options={jobTitleOptions}
                    value={values.jobTitle}
                  />
                </FormField>
                <FormField>
                  <Label>Practice Group</Label>
                  <Dropdown
                    selection
                    fluid
                    search
                    multiple
                    name="practiceGroup"
                    placeholder="Practice Group"
                    label="Practice Group"
                    onChange={(_e, { value }) => setFieldValue('practiceGroup', value)}
                    options={practiceGroupOptions}
                    value={values.practiceGroup}
                  />
                </FormField>
                <UnderlineDivider>Content</UnderlineDivider>
                <FormField>
                  <TextArea
                    label="Description"
                    name="description"
                    placeholder="Test Drive / Offer short description"
                  />
                </FormField>
                <UnderlineDivider>Features</UnderlineDivider>
                <FieldArray
                  name="features"
                  render={(arrayHelpers) => (
                    <div>
                      {values.features && (
                        <>
                          {values.features.map((_features: Feature, index: number) => (
                            <Resource key={`${JSON.stringify(_features)}`}>
                              <ResourceText>
                                <TextInput
                                  label="Text"
                                  placeholder="Feature Name"
                                  name={`features[${index}].name`}
                                />
                              </ResourceText>
                              <ResourceButton>
                                <Button
                                  type="button"
                                  onClick={() => {
                                    console.log(index)
                                    arrayHelpers.remove(index)
                                  }}
                                >
                                  -
                                </Button>
                              </ResourceButton>
                            </Resource>
                          ))}
                        </>
                      )}
                      {values.features.length < 7 && (
                        <AddButton type="button" onClick={() => arrayHelpers.push({ name: '' })}>
                          + Add Feature
                        </AddButton>
                      )}
                    </div>
                  )}
                />

                <FormField>
                  <ButtonContainer>
                    <Button
                      disabled={isSubmitting || !isValid}
                      loading={isSubmitting}
                      type="submit"
                    >
                      {buttonText}
                    </Button>
                  </ButtonContainer>
                </FormField>
              </Form>
            </FormWrapper>
            <TestDrivePreview
              name={getProductName(values.productId)}
              excerpt={values.description}
              image={getSquareLogo(values.productId)}
              freeTrialDays={values.freeTrialDays}
            />
          </Container>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div``

const ButtonContainer = styled.div`
  margin-top: 40px;
`

const Resource = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 12px;
`

const ResourceText = styled.div`
  flex: 1 1 0px;
  margin-right: 15px;
`

const ResourceButton = styled.div`
  flex: 0 0 auto;
  text-align: right;
`

const AddButton = styled(Button)`
  padding: 4px 16px;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  margin-top: 24px;
`

const UnderlineDivider = styled.div`
  border-bottom: 1px solid #999;
  padding-bottom: 4px;
  margin-bottom: 16px;
  color: #616161;
  font-size: 15px;
  font-weight: 500;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const FormWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  padding-right: 28px;
`

export default TestDriveForm
