import styled from '@emotion/styled'
import { useAlgoliaTechstack } from './AlgoliaTechstackContext'

const SelectedApps = () => {
  const { state, dispatch } = useAlgoliaTechstack()

  const toggleSelection = (id: number) => () => {
    dispatch({
      type: 'TOGGLE_APP_SELECTION',
      payload: { id },
    })
  }

  return (
    <Products>
      {state.selectedApps.map(({ id, title, logo }: any) => (
        <ProductLine key={`${id}-${title}--UserOrgTechstack`} onClick={toggleSelection(id)}>
          <Left>
            <Logo src={logo} />
            <ProductName>{title}</ProductName>
          </Left>
          <DeleteButton>DELETE</DeleteButton>
        </ProductLine>
      ))}
    </Products>
  )
}

const DeleteButton = styled.div`
  border: 2px solid ${({ theme }) => theme.color.red};
  border-radius: 100px;
  padding: 4px 12px;
  letter-spacing: 0.02rem;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.red};
  transition: background 200ms, color 200ms;
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

const Products = styled.div``

const ProductLine = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background 250ms;

  &:hover {
    background: #ffeff1;
    ${DeleteButton} {
      color: white;
      background: ${({ theme }) => theme.color.red};
    }
  }
`

const ProductName = styled.div`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.02rem;
`

const Logo = styled.img`
  width: 40px;
  margin-right: 16px;
`

export default SelectedApps
