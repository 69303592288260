import styled from '@emotion/styled'
import { Button } from 'components/theme'
import noListingSvg from 'assets/listing-image.svg'
import { useRedirect } from 'hooks/useRedirect'

const NoListing = () => {
  const redirect = useRedirect()

  const createProduct = async () => {
    localStorage.setItem('productId', '')
    redirect('listing-stepper/networks')
  }

  return (
    <Root>
      <Row>
        <Left>
          <h2>Publish your first listing!</h2>
          <Content>
            This is where you can create listings on Theorem networks and attach products from your
            product library to your listings.
          </Content>
          <MoreInfo>
            Want to learn more?{' '}
            <a
              href="https://help.theoremlegal.com/kb/en/article/app-marketplace-listing-your-product-or-service"
              target="_blank"
            >
              Read this article
            </a>
          </MoreInfo>
        </Left>
        <ImageWrapper>
          <Image src={noListingSvg} />
        </ImageWrapper>
      </Row>
      <Button onClick={createProduct}>Continue</Button>
    </Root>
  )
}

const Root = styled.div`
  text-align: center;
`

const Row = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  margin-bottom: 20px;
`

const Content = styled.p`
  font-size: 16px;
`

const MoreInfo = styled.p`
  font-weight: 500;
`

const Left = styled.div`
  width: 60%;
`

const Image = styled.img`
  max-width: 270px;
  width: 100%;
`

const ImageWrapper = styled.div`
  width: 40%;
  text-align: center;
`

export default NoListing
