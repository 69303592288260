import styled from '@emotion/styled'
import Button from 'components/theme/Button'
import { useNavigate } from 'react-router'

export default function ConnectSuccess() {
  const navigate = useNavigate()

  return (
    <Container>
      <Icon>
        <span role="img" aria-label="suprise">
          🎉
        </span>
      </Icon>
      <Title>Your stripe account is good to go!</Title>

      <BackButton onClick={() => navigate('/vendor-profile')}>Back to Profile</BackButton>
    </Container>
  )
}

const BackButton = styled(Button)`
  margin-top: 30px;
`
const Icon = styled.span`
  font-size: 80px;
`

const Title = styled.p`
  font-size: 20px;
  margin-top: 50px;
  font-weight: 500;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`
