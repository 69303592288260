import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'
import AddButton from './AddButton'
import Modal, { useModal } from 'components/Modal'
import PricingPlan from './PricingPlan'
import { fixCurrencyDisplay } from 'services/helper'
import { useAlgolia } from 'hooks/useAlgolia'
import { Product } from 'data/types'
import { useUpdateProductListing } from 'data/productListing/hooks/useUpdateProductListing'
import { useMutateProductDetails } from 'data/hooks'

type Plan = {
  stripeId: string | null
  name: string
  price: string
  price2: string
  priceType: string
  buttonType: string
  priceRange: string
  buttonText: string
  buttonUrl: string
  color: string
  pricePeriod: string
  tieredBillingPeriod?: string
  isPerUser: boolean
  isRecommended: boolean
  hasDiscountCode: boolean
  discountCode: string
  tieredPrices?: any[]
  fields: any[]
}

const initialPlan = {
  stripeId: null,
  name: '',
  price: '',
  price2: '',
  currency: 'USD',
  currency2: 'USD',
  priceType: 'monthly',
  buttonType: 'none',
  priceRange: 'months',
  buttonText: '',
  buttonUrl: '',
  color: '#333',
  pricePeriod: '',
  isPerUser: true,
  isRecommended: false,
  hasDiscountCode: false,
  discountCode: '',
  fields: [],
  tieredBillingPeriod: 'Monthly',
  tieredPrices: [],
}

const PricingPlanLoader: React.FC<{
  pricingPlans: Plan[]
  product?: Product
  productListing?: any
  isProductListing?: boolean
}> = ({ product, pricingPlans, productListing, isProductListing = false }) => {
  const [plans, setPlans] = useState<Plan[]>(pricingPlans)
  const [selectedPlan, setSelectedPlan] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const mutateProductDetails = useMutateProductDetails()
  const updateProductListing = useUpdateProductListing(productListing?.id, true)
  const { showModal: showPlan, toggle: togglePlan } = useModal()
  const { showModal: showDelete, toggle: toggleDelete } = useModal()
  const { partialUpdateObject } = useAlgolia()

  useEffect(() => {
    if (loading) return setLoading(false)
    if (!product && !pricingPlans) return

    try {
      if (isProductListing) {
        if (!productListing) return

        const productListingValues = {
          ...productListing.details,
          pricingPlans: plans,
        }
        updateProductListing.mutate(productListingValues)
      } else {
        if (!product) return
        mutateProductDetails.mutate({
          productId: product.id,
          details: {
            ...product.details,
            pricingPlans: plans,
          },
        })
      }

      const algoliaPlans = plans.map(({ price, isPerUser, priceType, hasDiscountCode }) => {
        const { hasFreeVersion, hasFreeTrial } = product?.details || {
          hasFreeTrial: false,
          hasFreeVersion: false,
        }
        const roundedPrice = `${price}`.substring(0, `${price}`.length - 2)
        const priceTags = []
        if (isPerUser) priceTags.push('Per User')
        if (hasFreeVersion) priceTags.push('Free Version')
        if (hasFreeTrial) priceTags.push('Free Trial')
        if (hasDiscountCode) priceTags.push('Free Discount')
        return { price: roundedPrice, priceTags, priceType }
      })
      partialUpdateObject({ id: product?.id, prices: algoliaPlans })
    } catch (error) {
      console.error(error)
    }
  }, [plans, product?.id])

  const openPlanEditor = (planIndex: number) => {
    setSelectedPlan(planIndex)
    togglePlan()
  }

  const closePlanEditor = () => {
    setSelectedPlan(null)
    togglePlan()
  }

  const startDeletePlan = (planIndex: number) => {
    setSelectedPlan(planIndex)
    toggleDelete()
  }

  const translatePriceType = (term: string) => {
    switch (term) {
      case '3months':
        return 'every 3 months'
      case '6months':
        return 'every 6 months'
      case 'annuallyOnly':
        return 'annual'
      case 'annually':
        return 'annual/monthly'
      case 'tiered':
        return 'Graduated'
      case 'oneTime':
        return 'One Time'
      default:
        return term
    }
  }

  const updatePlan = async (
    action: 'UPDATE' | 'DELETE' | 'ADD',
    index: number | null,
    plan?: any
  ) => {
    const planCopy = [...plans]
    switch (action) {
      case 'ADD':
        setLoading(true)
        togglePlan()
        break
      case 'DELETE':
        setSelectedPlan(null)
        index !== null ? planCopy.splice(index, 1) : console.error('missing index on delete plan')
        break
      case 'UPDATE':
        if (typeof index === 'number' && plan) {
          setSelectedPlan(null)
          planCopy.splice(index, 1, plan)
          togglePlan()
        } else {
          console.error('missing index on save plan')
        }
    }
    setPlans(planCopy)
  }

  const printPlanRows = () =>
    plans.map(
      (
        {
          name,
          priceType,
          pricePeriod,
          buttonType,
          color,
          price,
          price2,
          tieredPrices,
          tieredBillingPeriod,
        },
        i
      ) => (
        <tr key={`plan-${i}`}>
          <FirstCell color={color}>{name || `Plan ${i + 1}`}</FirstCell>
          <td>
            {priceType === 'tiered' ? `graduated ${pricePeriod}` : translatePriceType(priceType)}
          </td>
          <td>
            {priceType === 'tiered' && tieredPrices
              ? `Starts at ${fixCurrencyDisplay(
                  Number(tieredPrices[0].perUnit)
                )} per seat + ${fixCurrencyDisplay(Number(tieredPrices[0].flatFee))} ${
                  tieredBillingPeriod ? tieredBillingPeriod : ''
                }`
              : priceType === 'annually' && price2
              ? `${fixCurrencyDisplay(Number(price))}/yr - ${fixCurrencyDisplay(Number(price2))}/mo`
              : fixCurrencyDisplay(Number(price))}
          </td>
          <td>{buttonType}</td>
          <EditCell onClick={() => openPlanEditor(i)}>edit</EditCell>
          <EditCell onClick={() => startDeletePlan(i)}>delete</EditCell>
        </tr>
      )
    )

  return (
    <>
      <Modal
        isShowing={showPlan}
        toggleModal={closePlanEditor}
        showClose={false}
        width="900px"
        outerWrapperClose={false}
        removePadding
      >
        {selectedPlan !== null ? (
          <PricingPlan
            resource={plans[selectedPlan]}
            updatePlan={updatePlan}
            index={selectedPlan}
            closePlan={closePlanEditor}
          />
        ) : (
          <PricingPlan
            resource={initialPlan}
            updatePlan={updatePlan}
            index={plans.length}
            closePlan={closePlanEditor}
          />
        )}
      </Modal>
      <Modal isShowing={showDelete} toggleModal={toggleDelete}>
        <p>
          Are you sure you want to delete{' '}
          {selectedPlan && plans[selectedPlan]?.name ? (
            <strong>{plans[selectedPlan].name}</strong>
          ) : (
            <>this plan</>
          )}
          ?
        </p>
        <ButtonContainer>
          <YesButton
            onClick={() => {
              toggleDelete()
              updatePlan('DELETE', selectedPlan)
            }}
          >
            Yes
          </YesButton>
          <NoButton
            onClick={() => {
              toggleDelete()
              setSelectedPlan(null)
            }}
          >
            Cancel
          </NoButton>
        </ButtonContainer>
      </Modal>
      <Table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Billing Period</th>
            <th>Price</th>
            <th>Action Button</th>
          </tr>
        </thead>
        <tbody>
          {plans.length === 0 && (
            <tr>
              <td colSpan={5}>Add your first pricing plan!</td>
            </tr>
          )}
          {printPlanRows()}
        </tbody>
      </Table>
      <ButtonWrapper>
        <AddButton updatePlan={updatePlan} />
      </ButtonWrapper>
    </>
  )
}

const ButtonContainer = styled.div`
  display: flex;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 760px;
`

const NoButton = styled(Button)`
  color: #e53935;
  border-color: #e53935;
  &:hover {
    background: #e53935;
    border-color: #e53935;
  }
`

const YesButton = styled(Button)`
  margin-right: 12px;

  color: #43a047;
  border-color: #43a047;
  &:hover {
    background: #43a047;
    border-color: #43a047;
  }
`

const Table = styled.table`
  width: 100%;
  max-width: 760px;
  border-collapse: collapse;
  margin-bottom: 3px;
  margin-left: 0.5px;
  margin-top: 25px;

  th,
  td {
    font-size: 14px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    line-height: 1.15;
    text-align: center;
    padding: 11px 14px;
    width: min-content;
  }
`

const EditCell = styled.td`
  border: none !important;
  width: min-content !important;
  color: #2196f3;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    font-size: 14px;
    color: #673ab7;
    font-style: italic;
  }
`

const FirstCell = styled.td<{ color: string }>`
  border-left: 4px solid ${({ color }) => color} !important;
  padding: 0 !important;
`

export default PricingPlanLoader
