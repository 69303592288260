import React, { useEffect, ReactNode } from 'react'
import { Viewer } from 'data/types'
import { useViewerData } from 'data/viewer/useViewerData'
import { logUser } from '@theoremlts/theorem-design'
import { UserRole, UserStatus } from 'generated/graphql'
import VerifyEmail from 'components/global/VerifyEmail'
import BlockedEmail from 'components/global/BlockedEmail'
import { useAuth0 } from '@auth0/auth0-react'

export enum ViewerSubscriptions {
  VendorPro = 'VENDOR_PRO',
  VendorEnterprise = 'VENDOR_ENTERPRISE',
  VendorStartup = 'VENDOR_STARTUP',
  EcosystemUser = 'ECOSYSTEM_USER',
  LawfirmPro = 'LAWFIRM_PRO',
  CustomCore = 'CUSTOM_CORE',
  Free = 'FREE',
}

type InitialState = {
  viewer: Viewer
  viewerLoading: boolean
  isSuperAdmin: boolean
  isAdmin: boolean
  isActive: boolean
  stripeReady: boolean
  paymentStatus: string
  subscription: ViewerSubscriptions
  accountId?: string
  refetch: () => void
}

const ViewerContext = React.createContext({} as InitialState)

export const ViewerProvider = ({ children }: { children?: ReactNode }) => {
  const { data, isLoading, error, isError, refetch } = useViewerData()
  const { isAuthenticated } = useAuth0()

  const viewer = data?.viewer

  useEffect(() => {
    if (!viewer?.id) return
    logUser({ userId: viewer.id })
  }, [viewer?.id])

  useEffect(() => {
    if (!viewer?.status) return
    logUser({ status: viewer.status as any })
  }, [viewer?.status])

  useEffect(() => {
    if (isLoading || isError) return

    if (!viewer?.id) {
      debugger
      location.reload()
    }
  }, [viewer?.id, isLoading])

  const checkForUntrackedUser = () => {
    if (process.env.NODE_ENV === 'development') return false
    const filteredEmails = [
      'theoremlegal',
      'theoremplatform',
      'theoremfirm',
      'legaltheorem',
      'theoremlts',
      'albertdeguz',
      'theremvendor',
    ]
    return filteredEmails.some((domain) => viewer?.email.includes(domain))
  }

  useEffect(() => {
    if (!viewer?.role) return
    logUser({ role: viewer.role as any, optOut: checkForUntrackedUser() })
  }, [viewer?.role])

  useEffect(() => {
    if (!data || !viewer?.currentOrganization) return
    const org = viewer.organizations.filter(({ id }) => id === viewer.currentOrganization)
    if (org.length != 1) return
    logUser({ organization_type: org[0].type as any })
  }, [viewer?.currentOrganization])

  if (error) {
    if (error.error === 'unauthorized') {
      if (error.message.includes("Public domains aren't allowed at this time"))
        return <BlockedEmail />
      return <VerifyEmail />
    }
  }

  const isSuperAdmin = !!viewer?.email.includes('@theoremlegal.com')
  const isAdmin =
    viewer?.role === UserRole.Owner ||
    viewer?.role === UserRole.Admin ||
    viewer?.role === UserRole.SuperAdmin

  return (
    <ViewerContext.Provider
      value={{
        viewerLoading: isLoading && isAuthenticated,
        viewer: viewer as Viewer,
        stripeReady: !!data?.stripeReady,
        paymentStatus: data?.paymentStatus || 'NOT_ACTIVE',
        subscription: data?.subscription || ViewerSubscriptions.Free,
        accountId: data?.accountId,
        isActive: viewer?.status === UserStatus.Active,
        isSuperAdmin,
        isAdmin,
        refetch,
      }}
    >
      {children}
    </ViewerContext.Provider>
  )
}

export const useViewer = () => React.useContext(ViewerContext)
