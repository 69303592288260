import styled from '@emotion/styled'
import { Button, Icon } from 'components/theme'
import { breakpoint } from 'services/theming'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const OfferADealWidget = () => {
  const baseOrgUrl = useBaseOrgUrl()

  return (
    <Box>
      <Top>
        <IconWrapper>
          <BoxIcon name="handshake" />
        </IconWrapper>
        <TopContent>
          <Title>Offer a deal</Title>
        </TopContent>
      </Top>
      <Text>
        Offer a deal or test drive of your product directly to firms in the Theorem Ecosystem.{' '}
      </Text>
      <Bottom>
        <CtaButton component="link" to={`${baseOrgUrl}/test-drive`}>
          Get Started
        </CtaButton>
      </Bottom>
    </Box>
  )
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  ${breakpoint.desktop} {
    margin-right: 20px;
  }
`

const Box = styled.div`
  background: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  order: 3;

  ${breakpoint.desktop} {
    order: 2;
  }
`

const TopContent = styled.div`
  font-size: 16px;
  line-height: 1.5;
`

const Title = styled.strong``

const Top = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
`

const BoxIcon = styled(Icon)`
  font-size: 60px;
  color: #4e4f5a;
`

const Text = styled.p`
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  width: 90%;
  max-width: 350px;
`

const CtaButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
`

export default OfferADealWidget
