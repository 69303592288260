import React, { useEffect } from 'react'
import { useField } from 'formik'
import styled from '@emotion/styled'
import { FieldGroup, FormLabel, InputStyle } from './styles'

interface Props {
  name: string
  label: string
  disabled?: boolean
}

function TextInput({ disabled = false, label, ...props }: Props) {
  const [field, meta, helpers] = useField(props)

  useEffect(() => {
    if (disabled) helpers.setValue('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  return (
    <Root>
      <LabelWrapper>
        <FormLabel>{label}</FormLabel>

        {meta.touched && meta.error && <Error>{meta.error}</Error>}
      </LabelWrapper>
      <Input {...field} {...props} />
    </Root>
  )
}

const Root = styled.div`
  ${FieldGroup}
`

const Input = styled.input`
  ${InputStyle}
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Error = styled.div`
  font-weight: 500;
  color: #ff1744;
  letter-spacing: 0.02rem;
`

export default TextInput
