import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import CurrencyInput from 'react-currency-input-field'

interface Props {
  count: number | string
  label: string
  width?: string
  loading?: boolean
  currency?: boolean
}

const SubscriptionBox: React.FC<Props> = ({
  count,
  label,
  width = '33.3333%',
  loading,
  currency,
}) => {
  return (
    <Root width={width}>
      {!loading ? (
        <Count>
          {!currency ? count : <Currency prefix="$" disabled decimalScale={2} value={count} />}
        </Count>
      ) : (
        <Icon name="spinner" spin />
      )}
      <Label>{label}</Label>
    </Root>
  )
}

const Currency = styled(CurrencyInput)`
  color: black;
  text-align: center;
  background: none;
  border: none;
`

const Root = styled.div<{ width?: string }>`
  display: flex;
  width: ${({ width }) => width};
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  border-right: solid 1px #e8ecee;
  padding: 0 16px;
  height: 80px;

  &:last-of-type {
    border-right: none;
  }
`

const Count = styled.div`
  color: #273240;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
`

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #9ca6af;
  text-align: center;
`

export default SubscriptionBox
