import styled from '@emotion/styled'
import React from 'react'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import { getCurrencySymbol } from 'services/helper'

interface Props {
  value?: number
  onChange: (value: number | undefined) => void
  placeholder?: string
  currencyValue?: string
}

const MoneyInput: React.FC<Props> = ({ value, onChange, currencyValue, placeholder = '' }) => {
  const parsedValue = value ? value / 100 : ''
  const onValueChange: CurrencyInputProps['onValueChange'] = (value): void => {
    if (typeof value === 'string' && !Number.isNaN(Number(value))) {
      const stripeFormat = value ? Number(value) * 100 : Number(value)
      onChange(stripeFormat)
    } else {
      onChange(0)
    }
  }

  return (
    <CurrencyTextInput
      intlConfig={{ locale: 'en-US', currency: currencyValue || 'USD' }}
      placeholder={placeholder || getCurrencySymbol(currencyValue || 'USD')}
      onValueChange={onValueChange}
      defaultValue={parsedValue} // value should be devided before it's passed here.
      value={parsedValue}
    />
  )
}

const CurrencyTextInput = styled(CurrencyInput)`
  width: 100%;
  padding: 9.5px 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease, border-color 0.1s ease;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  flex: 1 0 auto;

  &::-webkit-input-placeholder {
    color: #616161;
  }

  &:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

export default MoneyInput
