import React from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { isUsingProductionServer } from 'services/helper'
import { useSetUserStatus } from 'hooks/graphql'
import { useUserSetup } from 'context/userSetupContext'
import { UserStatus } from 'generated/graphql'

// const ECOSYSTEM_PLAN = isUsingProductionServer ? 'ecosystem_user_yearly' : 'ecosystem_user_yearly'
const FIRM_PLAN = isUsingProductionServer ? 'lawfirm_org_yearly_tier' : 'lawfirm_org_yearly_tier'

type Props = {
  sectionRef: any
}
const PlanSelections = ({ sectionRef }: Props) => {
  const navigate = useNavigate()
  const { setUserStatus } = useSetUserStatus()
  const { amplitude } = useUserSetup()

  const onPriceClick = (priceId: string, freeTrialDays: number) => async () => {
    localStorage.removeItem('stripeCheckoutSessionId')
    await setUserStatus(UserStatus.LawfirmPayment)
    amplitude?.logEventWithSavedProps('select_plan', {
      action: 'selection',
      user_choice: 'premium',
    })
    navigate('/setup/lawfirm-checkout', {
      state: {
        priceId: priceId,
        freeTrialDays,
      },
    })
  }

  const onFreeClick = async () => {
    await setUserStatus(UserStatus.CreateUser)
    navigate('/setup/lawfirm-user')
    amplitude?.logEventWithSavedProps('select_plan', { action: 'selection', user_choice: 'free' })
  }

  return (
    <Root ref={sectionRef as any}>
      <Plans>
        <Plan>
          <PlanTitle>BASIC</PlanTitle>

          <PlanButton onClick={onFreeClick}>
            <PlanCost>FREE</PlanCost>
          </PlanButton>
          <ul>
            <li>
              <b>Up to 10 users</b>
            </li>
            <li>Marketplace access</li>
          </ul>
        </Plan>
        <Plan>
          <PlanTitle>PREMIUM</PlanTitle>

          <PlanButton onClick={onPriceClick(FIRM_PLAN, 0)}>
            <PlanCost>$2,000</PlanCost>
          </PlanButton>
          <ul>
            <li>+ $240 USD per user</li>
            <li>Renews at $2000+ User Licenses</li>
            <li>Annual Commitment*</li>
          </ul>
        </Plan>
        <Plan>
          <PlanTitle>ENTERPRISE</PlanTitle>

          <PlanButton
            onClick={() => {
              amplitude?.logEventWithSavedProps('select_plan', {
                action: 'selection',
                user_choice: 'free',
              })
            }}
            href="https://share.hsforms.com/1pylb9NU7Re6M_C6scaUaIQ3p9i3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PlanCost>CONTACT US</PlanCost>
          </PlanButton>
          <ul>
            <li>Tiered user pricing</li>
          </ul>
        </Plan>
      </Plans>
    </Root>
  )
}

const Root = styled.div`
  padding: 20px;

  li {
    font-size: 16px;
    margin-bottom: 4px;
  }

  ul {
    margin-top: 24px;
  }
`

const Plans = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
`

const Plan = styled.div`
  width: 50%;
  padding: 20px;
`

const PlanTitle = styled.div`
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 12px;
`

const PlanButton = styled.a`
  border-radius: 100px;
  padding: 12px;
  display: block;
  border: 1px solid #ab47bc;
  text-align: center;
  color: #ab47bc;
  transition: all 250ms;

  &:hover {
    border: 1px solid #ab47bc;
    background: #ab47bc;
    color: white;
  }
`

const PlanCost = styled.div`
  font-size: 18px;
  font-weight: 600;
`

export default PlanSelections
