import React from 'react'
import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'

type Props = {
  selectable?: boolean
  children: React.ReactNode
}

const CardContainer = ({ children, selectable }: Props) => (
  <Card selectable={selectable}>{children}</Card>
)

const Card = styled.div<{ selectable?: boolean }>`
  padding: 16px 10px 8px;
  margin: 0px 8px 12px;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  position: relative;
  max-width: 484px;
  /* width: 45%; */

  ${breakpoint.tablet} {
    width: 90%;
    margin: 0 auto 12px auto;
  }

  &:hover {
    cursor: pointer;
    border: 2px solid rgb(76, 208, 209);
  }
`

export default CardContainer
