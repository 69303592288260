import React from 'react'
import styled from '@emotion/styled'

interface Props {
  id?: number
  name: string
  excerpt?: string
  image: string
  vendorName?: string
  freeTrialDays?: number
  onClick?: (id: number) => void
}
const TestDriveBox: React.FC<Props> = ({
  id,
  name,
  excerpt,
  image,
  vendorName,
  freeTrialDays,
  onClick,
}) => {
  return (
    <Root>
      <AppContent
        onClick={() => {
          if (onClick && id) onClick(id)
        }}
      >
        <BoxType>{freeTrialDays ? 'Try' : 'Get'}</BoxType>
        <Logo src={image} />
        <BoxTitle>{name}</BoxTitle>
        {vendorName && <VendorName>by {vendorName}</VendorName>}
        <Excerpt>{excerpt}</Excerpt>
      </AppContent>
    </Root>
  )
}

const Root = styled.div`
  width: 33.3333%;
  padding: 0 10px;
  margin-bottom: 20px;
  color: #333;

  @media (max-width: 1100px) {
    width: 50%;
  }

  @media (max-width: 850px) {
    width: 100%;
  }
`

const AppContent = styled.div`
  position: relative;
  border: 1px solid #bdbdbd;
  padding: 15px;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Logo = styled.img`
  max-width: 60px;
`

const BoxTitle = styled.h3`
  margin-top: 10px;
`

const BoxType = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  background: #00a2ff;
  color: white;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1;
  padding: 4px 8px;
  border-radius: 1000px;
`

const Excerpt = styled.p`
  font-size: 14px;
  color: #676773;
  line-height: 1.5;
`

const VendorName = styled.div`
  transform: translateY(-8px);
  color: #777;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 12px;
`

export default TestDriveBox
