import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { BoxWrapper, Button, Icon } from 'components/theme'
import { getLocalTime } from 'services/helper'
import { css } from '@emotion/react'
import BrowserImage from './browser-image.png'
import NoTestDriveImg from './no-testdrive.png'
import { isUsingStagingServer } from 'services/helper'
import TestDriveModal from 'components/modals/TestDriveModal'
import { FullPageLoading } from 'components/PulsatingLoader'
import DeleteModal from 'components/modals/DeleteModal'
import Tooltip from 'components/theme/Tooltip'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useDeleteTestDrive, useTestDrives } from 'data/hooks'

interface TermToProduct {
  term: { taxonomyId: number; name: string }
}

const TestDriveList = () => {
  const { testDrives, isLoading: testDriveLoading } = useTestDrives()
  const deleteTestDriveGql = useDeleteTestDrive()

  const { stripeReady } = useViewer()
  const { vendor } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()
  const [viewTestDrive, setViewTestDrive] = useState<any>()
  const [isShowModal, setIsShowModal] = useState(false)
  const [deleteTestDriveId, setDeleteTestDriveId] = useState<number | null>(null)
  const [loading, setLoading] = useState(true)
  const [hasEula, setHasEula] = useState(true)

  const product = vendor && vendor.products.length > 0 ? vendor.products[0] : null

  useEffect(() => {
    if (testDriveLoading) return

    if (vendor && vendor?.products.length > 0) {
      if (
        product &&
        !product.details.eulaUrl &&
        !product.details.eulaPdf &&
        !product.details.publicTermsUrl
      ) {
        setHasEula(false)
      }
    }
    setLoading(false)
  }, [testDriveLoading])

  if (!stripeReady)
    return (
      <Root>
        <BoxWrapper>
          <Title>Add an Offer or Test Drive</Title>
          <Content>
            Become a seller to post one-click trials
            <br />
            and special offers for your product(s)
          </Content>
          <Image src={BrowserImage} />
          <div>
            <UpgradeButton component="link" to={`${baseOrgUrl}/upgrade/setup/1`}>
              Setup Account
            </UpgradeButton>
          </div>
        </BoxWrapper>
      </Root>
    )

  const showTestDriveModal = (testDrive: any) => () => {
    const { product, details, termToTestDrives } = testDrive

    const environment = isUsingStagingServer ? 'test' : 'production'

    const data: any = {
      product: {
        ...product,
        bestFor:
          product?.termToProducts
            ?.filter(({ term }: TermToProduct) => term.taxonomyId === 16)
            .map(({ term }: TermToProduct) => ({ name: term.name })) || [],
        practiceGroups:
          product?.termToProducts
            ?.filter(({ term }: TermToProduct) => term.taxonomyId === 13)
            .map(({ term }: TermToProduct) => ({ name: term.name })) || [],
      },
      stripeProductId: details?.stripe?.[environment].productId,
      priceId: details?.stripe?.[environment].priceId,
      priceId2: details?.stripe?.[environment].priceId2,
      freeTrialDays: details?.stripe?.freeTrialDays,
      accountId: details?.stripe?.accountId,
      description: details?.description,
      features: details?.features?.map(({ name }: any) => name) || [],
      terms: getTerms(termToTestDrives),
    }

    setViewTestDrive(data)
    setIsShowModal(true)
  }

  const deleteTestDrive = (testDriveId: number | null) => async () => {
    if (!testDriveId) return
    await deleteTestDriveGql.mutateAsync(testDriveId)

    setDeleteTestDriveId(null)
  }

  const hideDeleteModal = () => {
    setDeleteTestDriveId(null)
  }

  const showDeleteModal = (testDriveId: number) => () => {
    setDeleteTestDriveId(testDriveId)
  }

  const toggleModal = () => {
    setIsShowModal((prevIsShowModal) => !prevIsShowModal)
  }

  const onModalButtonClick = () => {
    console.log('do nothing')
  }

  if (!testDrives || loading) return <FullPageLoading />

  if (!hasEula)
    return (
      <div>
        <BoxWrapper>
          <h3>Seems like you haven't uploaded the legal stuff.</h3>
          <p>Please upload your Terms of Service and EULA before you add a Test Drive</p>
          <Button component="link" to={`${baseOrgUrl}/product/settings/${product?.id}/legal`}>
            Add the legal stuff
          </Button>
        </BoxWrapper>
      </div>
    )
  return (
    <Root>
      <BoxWrapper>
        {testDrives.length === 0 ? (
          <Center>
            <Title>Add a testdrive or make an offer</Title>
            <NoTdImage src={NoTestDriveImg} />
            <AddButton component="link" to={`create`}>
              + Add Test Drive
            </AddButton>
          </Center>
        ) : (
          <div>
            <TableTitle>
              <span>Your test drives</span>
              <Button component="link" to={`create`}>
                Create New Test Drive
              </Button>
            </TableTitle>
            <ListHead>
              <HName>Stripe Product Name</HName>
              <HFreeTrialDays>Free Trial Days</HFreeTrialDays>
              <HCreated>Created</HCreated>
            </ListHead>
            <List>
              {testDrives.map((testDrive) => (
                <TestDrive key={`testdrive-${testDrive.id}`}>
                  <Name>{testDrive.details.stripe.productName}</Name>
                  <FreeTrialDays>{testDrive.details.stripe.freeTrialDays}</FreeTrialDays>
                  <Created>{getLocalTime(testDrive.createdAt)}</Created>
                  <ButtonWrapper>
                    <Tooltip placement="top" trigger="hover" tooltip="View">
                      <EditButton onClick={showTestDriveModal(testDrive)}>
                        <Icon name="eye" />
                      </EditButton>
                    </Tooltip>
                    <Tooltip placement="top" trigger="hover" tooltip="Edit">
                      <EditButton component="link" to={`edit/${testDrive.id}`}>
                        <Icon name="edit" />
                      </EditButton>
                    </Tooltip>
                    <Tooltip placement="top" trigger="hover" tooltip="Delete">
                      <DeleteButton onClick={showDeleteModal(testDrive.id)}>
                        <Icon name="minus" />
                      </DeleteButton>
                    </Tooltip>
                  </ButtonWrapper>
                </TestDrive>
              ))}
            </List>
          </div>
        )}
      </BoxWrapper>
      <TestDriveModal
        onClick={onModalButtonClick}
        isShowing={isShowModal}
        toggleModal={toggleModal}
        product={viewTestDrive?.product}
        features={viewTestDrive?.features}
        freeTrialDays={viewTestDrive?.freeTrialDays}
      />
      <DeleteModal
        showModal={!!deleteTestDriveId}
        toggleModal={hideDeleteModal}
        onConfirmClick={deleteTestDrive(deleteTestDriveId)}
      />
    </Root>
  )
}

type TermsData = {
  term: {
    id: number
    name: string
    slug: string
    taxonomyId: number
    taxonomy: {
      id: number
      name: string
      slug: string
    }
  }
}

const getTerms = (terms: TermsData[]) => {
  return terms.map(({ term }) => term)
}

const maxWidth = css`
  max-width: 800px;
`
const Root = styled.div``

const Center = styled.div`
  text-align: center;
`

const AddButton = styled(Button)`
  font-size: 20px;
  padding: 12px 28px;
`

const Title = styled.div`
  color: #512da8;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
  max-width: 760px;
  margin: 0 auto 16px;
`

const NoTdImage = styled.img`
  width: 100%;
  display: block;
  margin: 20px auto;
  max-width: 200px;
`

const Image = styled.img`
  width: 100%;
  display: block;
  margin: 0 auto;
  max-width: 400px;
`

const Content = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  max-width: 600px;
  margin: 0 auto 32px;
`

const TableTitle = styled.h3`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  ${maxWidth}
`

const List = styled.div`
  display: block;
  ${maxWidth}
`

const ListHead = styled.div`
  display: flex;
  border-bottom: 1px solid #e8ecee;
  padding-bottom: 12px;
  ${maxWidth}
`

const TestDrive = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e8ecee;
`

const nameStyles = css`
  flex: 0 0 40%;
`

const tableHeaderStyles = css`
  font-weight: 500;
`

const Name = styled.div`
  ${nameStyles}
`
const HName = styled.div`
  ${nameStyles}
  ${tableHeaderStyles}
`

const freeTrialStyles = css`
  flex: 0 0 20%;
`

const FreeTrialDays = styled.div`
  ${freeTrialStyles}
`
const HFreeTrialDays = styled.div`
  ${freeTrialStyles}
  ${tableHeaderStyles}
`

const createdStyles = css`
  flex: 0 0 20%;
`
const Created = styled.div`
  ${createdStyles}
`
const HCreated = styled.div`
  flex: 0 0 40%;
  ${tableHeaderStyles}
`

const ButtonWrapper = styled.div`
  flex: 0 0 20%;
  text-align: right;
  display: flex;
`

const EditButton = styled(Button)`
  margin-left: 8px;
  padding: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #607d8b;
  border-color: #607d8b;
  height: 36px;
  width: 36px;

  &:hover {
    color: white;
    background: #607d8b;
    border-color: #607d8b;
  }
`

const DeleteButton = styled(Button)`
  color: #f44336;
  border-color: #f44336;
  margin-left: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 36px;
  width: 36px;

  &:hover {
    color: white;
    background: #f44336;
    border-color: #f44336;
  }
`

const UpgradeButton = styled(Button)`
  color: #c3621c;
  background-color: #ffd700;
  border-color: #ffd700;
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 20px;
  margin: 40px auto 0;
  padding: 16px 32px;
  width: 234px;
  text-align: center;

  &:hover {
    color: #c3621c;
    border-color: #c3621c;
    background-color: transparent;
  }
`

export default TestDriveList
