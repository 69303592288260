import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { Avatar } from 'components/user'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/hooks'

const UserMenuTrigger = () => {
  const { organization } = useOrg()
  const { viewer } = useViewer()

  return (
    <Root>
      <OrganizationName title={organization?.name}>{organization?.name}</OrganizationName>
      <Avatar user={viewer} profileDisabled />
      <CaretIcon name="caret-down" />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.topBarHeight};
  padding: 0 16px;
`

const OrganizationName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  margin-right: 12px;
  max-width: 130px;

  ${({ theme }) => theme.breakpoint.menuLogoSwitch} {
    display: none;
  }
`

const CaretIcon = styled(Icon)`
  font-size: 16px;
  margin-left: 12px;
`

export default UserMenuTrigger
