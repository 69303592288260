import { useEffect, useRef } from 'react'
import FeatureBlock from 'components/marketing/FeatureBlock'
import Hero from 'components/marketing/Hero'
import styled from '@emotion/styled'

import MarketLaunchSvg from 'assets/marketing/market-launch.svg'
import SecurePng from 'assets/marketing/secure-checkout.png'
// import TheoremTransactPng from 'assets/marketing/plug-in-workflow.png'
import VendorDashboardPng from 'assets/marketing/vendor-dashboard.png'
import TheoremConnectPng from 'assets/marketing/vendor/computer-screen.svg'
// import PlanSelection from 'components/marketing/PlanSelection'
import UpgradeSubscription from './UpgradeSubscription'

import ThreeBlocks from 'components/marketing/ThreeBlocks'
import QualityReachSvg from 'assets/marketing/vendor/quality-reach.svg'
import EarnMoneySvg from 'assets/marketing/vendor/earn-money.svg'
import ConnectedNetwrokSvg from 'assets/marketing/vendor/connected-network.svg'
import LargeContentImage from 'components/marketing/LargeContentImage'

import WindowsAmicoSvg from 'assets/marketing/vendor/windows-amico.svg'

// const plans = [
//   {
//     title: 'MONTHLY',
//     priceId: 'price_1I7UbPJl1YuJqgKxQk5zGkWk',
//     cost: '$50',
//     features: ['Billed monthly', 'Renews at $50/month', 'Annual Commitment*'],
//   },
//   {
//     title: 'ANNUAL',
//     priceId: 'price_1I7UbPJl1YuJqgKxdTtNs8L4',
//     cost: '$500',
//     features: ['20% off | Save $60.00', 'Renews at $500', 'Annual Commitment*'],
//   },
// ]

const VendorMarketing = () => {
  const ref = useRef<any>(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const executeScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <Root>
      <Hero
        title="Connect &amp; Save with PRO"
        subtitle="Access premium features"
        buttonText="Become a PRO member"
        text={
          <div>
            <p style={{ fontSize: '18px' }}>
              Priority access, support, exclusive deals, discounts and more.
            </p>
            <p style={{ fontWeight: 500 }}>
              + Save up to 20% on network purchases and listing plans You could save up to $750 per
              listing*
            </p>
          </div>
        }
        image={MarketLaunchSvg}
        executeScroll={executeScroll}
      />
      <ThreeBlocks
        title="Why people are choosing Theorem PRO"
        blocks={[
          {
            title: 'Quality Reach',
            content:
              'Leverage our network of verified prospects. Create custom outreach and CTAs to access new audiences.',
            image: QualityReachSvg,
          },
          {
            title: 'Earn Money',
            content:
              'Close more deals and grow your revenue with our marketing and monetization tools.',
            image: EarnMoneySvg,
          },
          {
            title: 'Connected network',
            content:
              'Pain-free publishing to all our connected networks. Manage all your listings with localized support.',
            image: ConnectedNetwrokSvg,
          },
        ]}
      />
      <LargeContentImage
        title="With a PRO plan, your team can..."
        content={
          <LargeContentWrapper>
            <SmallTitle>List on every Theorem Network</SmallTitle>
            <p>
              Manage all your product listings from one place and get access to all our included
              networks
            </p>
            <ListTitle>Networks Included w/ Theorem Pro.</ListTitle>
            <ul>
              <li>Theorem – The Core Network</li>
              <li>ELTA - European Legal Tech Association</li>
              <li>ALITA - Asia-Pacific Legal Innovation and Technology Association</li>
              <li>ARK KM - Knowledge Management, AM law 100 & 200</li>
              <li>CIO Network - Information and Analytics Officers</li>
              <li>CISO network - security professionals</li>
              <li>Save big on listings like CLOC</li>
            </ul>
          </LargeContentWrapper>
        }
        image={WindowsAmicoSvg}
      />
      <FeatureBlocks>
        <Title>Access premium features</Title>

        <FeatureBlock
          type="Secure Checkout"
          title="Accept Payments with Confidence"
          content="Enable One-Click Secure Payment Processing and Receive Direct Payouts. Create Unlimited Plans, Special Offers & Free Trials. Send custom quotes, and invoice buyers directly."
          image={SecurePng}
        />

        <FeatureBlock
          type="Vendor Intelligence"
          title="Know your buyers and target competitors"
          content="Access page analytics and competitive intelligence with comparison features and full marketplace access."
          image={VendorDashboardPng}
          flip={true}
        />

        <FeatureBlock
          type="Theorem Transact"
          title="Transact With Buyers Directly with RFP"
          content="We make targeting, connecting and negotiating with outside organizations and clients simple with easy RFP and private offer tools"
          image={TheoremConnectPng}
        />

        {/*
        <FeatureBlock
          type="(Coming Soon) Theorem Connect"
          title="Easily Connect Your Business Apps"
          content="Connect your Business Apps to Streamline Inbound Inquiries and your sales and marketing tasks."
          image={TheoremTransactPng}
          flip={true}
        />
      */}
      </FeatureBlocks>
      <UpgradeSubscription sectionRef={ref} />
    </Root>
  )
}

const Root = styled.div`
  background: white;
`

const Title = styled.h3`
  font-size: 32px;
  text-align: center;
`

const FeatureBlocks = styled.div`
  padding: 60px 0;
  background: #f4f4f4;
`

const LargeContentWrapper = styled.div`
  li {
    font-size: 18px;
    margin-bottom: 8px;
  }

  p {
    font-size: 18px;
    margin-bottom: 24px;
    color: #555;
    max-width: 520px;
  }
`

const ListTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`

const SmallTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`

export default VendorMarketing
