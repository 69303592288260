import React, { useState } from 'react'
import styled from '@emotion/styled'
import { usePopper } from 'react-popper'

const ARROW_WIDTH = 16
const HALF_ARROW_WIDTH = ARROW_WIDTH / 2

const TEST_MODE = false

interface Props {
  tooltip: any
  placement?: any
  hideArrow?: boolean
  [key: string]: any
}
const Tooltip: React.FC<Props> = ({
  children,
  tooltip,
  // hideArrow = false,
  placement = 'auto',
  ...props
}) => {
  const [referenceElement, setReferenceElement] = useState<any>(null)
  const [popperElement, setPopperElement] = useState<any>(null)
  const [arrowElement, setArrowElement] = useState<any>(null)
  const [visible, setVisible] = useState(TEST_MODE || false)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: 'offset', options: { offset: [0, 0] } },
      { name: 'arrow', options: { element: arrowElement } },
    ],
  })

  return (
    <>
      <Trigger
        ref={setReferenceElement}
        onMouseOver={() => setVisible(true)}
        onMouseLeave={() => setVisible(TEST_MODE || false)}
      >
        {children}
        {visible && (
          <TooltipContainer
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            {...props}
          >
            <ToolTipBox>
              <ToolTipContent>{tooltip}</ToolTipContent>
              <ArrowWrapper ref={setArrowElement} style={styles.arrow}>
                <Arrow />
              </ArrowWrapper>
            </ToolTipBox>
          </TooltipContainer>
        )}
      </Trigger>
    </>
  )
}

const ToolTipContent = styled.div`
  overflow: hidden;
  border-radius: 3px;
`

const ArrowWrapper = styled.div`
  width: ${HALF_ARROW_WIDTH}px;
  height: ${ARROW_WIDTH}px;
  overflow: hidden;
`

const Arrow = styled.div`
  &,
  &:before {
    position: absolute;
    width: ${ARROW_WIDTH}px;
    height: ${ARROW_WIDTH}px;
  }

  &:before {
    content: '';
    width: ${ARROW_WIDTH * Math.sin(Math.PI / 4)}px;
    height: ${ARROW_WIDTH * Math.sin(Math.PI / 4)}px;
    transform: translate(-50%, -50%) rotate(45deg);
    top: 50%;
    left: 50%;
    background: white;
    border: 1px solid silver;
  }
`

const ToolTipBox = styled.div`
  background: white;
  border: 1px solid silver;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  display: flex;
  transition: opacity 0.3s;
  z-index: 9999;
  flex-direction: column;
  position: relative;
`

const TooltipContainer = styled.div`
  transition: opacity 0.3s;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;

  &[data-popper-placement^='top'] {
    padding-bottom: ${HALF_ARROW_WIDTH + 2}px;

    ${ArrowWrapper} {
      width: ${ARROW_WIDTH}px;
      height: ${HALF_ARROW_WIDTH}px;
      bottom: -${HALF_ARROW_WIDTH}px;
    }

    ${Arrow} {
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
        top: 0;
        left: 50%;
        right: auto;
        bottom: auto;
      }
    }
  }

  &[data-popper-placement^='bottom'] {
    padding-top: ${HALF_ARROW_WIDTH + 2}px;

    ${ArrowWrapper} {
      width: ${ARROW_WIDTH}px;
      height: ${HALF_ARROW_WIDTH}px;
      top: -${HALF_ARROW_WIDTH}px;
    }
  }

  &[data-popper-placement^='left'] {
    padding-right: ${HALF_ARROW_WIDTH + 2}px;
    ${ArrowWrapper} {
      right: -${HALF_ARROW_WIDTH}px;
    }

    ${Arrow} {
      &:before {
        transform: translate(50%, -50%) rotate(45deg);
        left: auto;
        right: 100%;
      }
    }
  }

  &[data-popper-placement^='right'] {
    padding-left: ${HALF_ARROW_WIDTH + 2}px;

    ${ArrowWrapper} {
      left: -${HALF_ARROW_WIDTH}px;
    }
  }
`

const Trigger = styled.span`
  display: inline-block;
  position: relative;
`

export default Tooltip
