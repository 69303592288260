import React from 'react'
import styled from '@emotion/styled'
import { Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import { Button } from 'components/theme'
import Label from 'components/theme/form/Label'
import { CertProps } from './DiversityCert.d'

const FormTwo: React.FC<CertProps> = ({ updateCert, goToNextStep }) => {
  
  const determineProvider = (certType: string) => {
    switch (certType) {
      case 'MBE':
        return 'NMSDC'
      case 'WBE':
      case 'WOSB':
        return 'WBENC'
      case 'LGBTBE':
        return 'NGLCC'
      case 'DOBE':
        return 'Disability:In'
      case 'SDVOSB':
      case 'VOB':
      case 'VOSB':
        return 'Disability:In'
      default:
        return ''
    }
  }

  const determineCertificateName = (certType: string) => {
    switch (certType) {
      case 'MBE':
        return 'Minority Business Enterprise'
      case 'WBE':
        return "Women's Business Enterprise"
      case 'WOSB':
        return "Women-Owned Small Business"
      case 'LGBTBE':
        return 'LGBT Business Enterprise'
      case 'DOBE':
        return 'Disability-Owned Business Enterprise'
      case 'SDVOSB':
        return 'Service-Disabled Veteran-Owned Small Business'
      case 'VOB':
        return 'Veteran Owned Business'
      case 'VOSB':
        return 'Veteran-Owned Small Business'
      case 'none':
        return 'Self Identifying'
      default:
        return ''
    }
  }

  return (
    <Two>
      <Formik
        initialValues={{
          certType: '',
          certificateName: '',
          certificateProvider: '',
          certificateNumber: '',
          expirationDate: '',
        }}
        validationSchema={yup.object({
          certType: yup.string().required('Please make a selection'),
          certificateName: yup.string(),
          certificateProvider: yup.string(),
          certificateNumber: yup.string(),
          expirationDate: yup.string(),
        })}
        onSubmit={({
          certType,
          certificateName,
          certificateProvider,
          certificateNumber,
          expirationDate,
        }) => {
          const intuitedProvider = certType !== 'other' ? determineProvider(certType) : undefined
          const intuitedName = certType !== 'other' ? determineCertificateName(certType) : undefined
          updateCert({
            certType,
            certificateName: intuitedName || certificateName,
            certificateProvider: intuitedProvider || certificateProvider,
            certificateNumber,
            expirationDate,
          })
          goToNextStep()
        }}
      >
        {({ values, errors, touched, setFieldValue }) => {

          const clearCustomFields = () => {
            values.certificateName && setFieldValue('certificateName', '')
            values.certificateProvider && setFieldValue('certificateProvider', '')
            values.certificateNumber && setFieldValue('certificateNumber', '')
            values.expirationDate && setFieldValue('expirationDate', '')
          }

          return (
            <Form>
              <LabelLine>
                <Title>Add Certification</Title>
                <ErrorText>{errors.certType && touched.certType && errors.certType}</ErrorText>
              </LabelLine>
              <RadioGroup role="group" aria-labelledby="my-radio-group">
                <SelectOption>
                  <Field type="radio" name="certType" value={'MBE'} onClick={clearCustomFields} />
                  <RadioLabel>
                    Minority Business Enterprise (
                    <a href="https://nmsdc.org/" target="_blank" rel="noreferrer">
                      NMSDC
                    </a>
                    )
                  </RadioLabel>
                </SelectOption>
                <SelectOption>
                  <Field type="radio" name="certType" value={'WBE'} onClick={clearCustomFields} />
                  <RadioLabel>
                    Women's Business Enterprise (
                    <a href="https://www.wbenc.org/" target="_blank" rel="noreferrer">
                      WBENC
                    </a>
                    )
                  </RadioLabel>
                </SelectOption>
                <SelectOption>
                  <Field type="radio" name="certType" value={'WOSB'} onClick={clearCustomFields} />
                  <RadioLabel>
                    Women-Owned Small Business (
                    <a href="https://www.wbenc.org/" target="_blank" rel="noreferrer">
                      WBENC
                    </a>
                    )
                  </RadioLabel>
                </SelectOption>
                <SelectOption>
                  <Field
                    type="radio"
                    name="certType"
                    value={'LGBTBE'}
                    onClick={clearCustomFields}
                  />
                  <RadioLabel>
                    LGBT Business Enterprise (
                    <a href="https://www.nglcc.org/" target="_blank" rel="noreferrer">
                      NGLCC
                    </a>
                    )
                  </RadioLabel>
                </SelectOption>
                <SelectOption>
                  <Field type="radio" name="certType" value={'DOBE'} onClick={clearCustomFields} />
                  <RadioLabel>
                    Disability-Owned Business Enterprise (
                    <a href="https://disabilityin.org/" target="_blank" rel="noreferrer">
                      Disability:In
                    </a>
                    )
                  </RadioLabel>
                </SelectOption>
                <SelectOption>
                  <Field
                    type="radio"
                    name="certType"
                    value={'SDVOSB'}
                    onClick={clearCustomFields}
                  />
                  <RadioLabel>
                    Service-Disabled Veteran-Owned Small Business (
                    <a href="https://nvbdc.org/" target="_blank" rel="noreferrer">
                      NVBDC
                    </a>
                    )
                  </RadioLabel>
                </SelectOption>
                <SelectOption>
                  <Field type="radio" name="certType" value={'VOB'} onClick={clearCustomFields} />
                  <RadioLabel>
                    Veteran Owned Business (
                    <a href="https://nvbdc.org/" target="_blank" rel="noreferrer">
                      NVBDC
                    </a>
                    )
                  </RadioLabel>
                </SelectOption>
                <SelectOption>
                  <Field type="radio" name="certType" value={'VOSB'} onClick={clearCustomFields} />
                  <RadioLabel>
                    Veteran-Owned Small Business (
                    <a href="https://nvbdc.org/" target="_blank" rel="noreferrer">
                      NVBDC
                    </a>
                    )
                  </RadioLabel>
                </SelectOption>
                <HR />
                <SelectOption>
                  <Field type="radio" name="certType" value={'other'} />
                  <RadioLabel>I have a different certification</RadioLabel>
                </SelectOption>
                {values.certType === 'other' && (
                  <OptionalFields>
                    <TextWrapper>
                      <LabelLine>
                        <Label>Certificate Name</Label>
                      </LabelLine>
                      <TextField type="text" name="certificateName" placeholder="" />
                    </TextWrapper>
                    <TextWrapper>
                      <LabelLine>
                        <Label>Certificate Provider</Label>
                      </LabelLine>
                      <TextField type="text" name="certificateProvider" placeholder="" />
                    </TextWrapper>
                    <TextWrapper>
                      <LabelLine>
                        <Label>Certificate Number</Label>
                      </LabelLine>
                      <TextField type="text" name="certificateNumber" placeholder="" />
                    </TextWrapper>
                    <TextWrapper>
                      <LabelLine>
                        <Label>Expiration Date</Label>
                      </LabelLine>
                      <TextField type="text" name="expirationDate" placeholder="" />
                    </TextWrapper>
                  </OptionalFields>
                )}
                <SelectOption>
                  <Field type="radio" name="certType" value={'none'} onClick={clearCustomFields} />
                  <RadioLabel>I don't have a certification, I'd like to self identify</RadioLabel>
                </SelectOption>
              </RadioGroup>
              <SubmitWrapper>
                <SubmitButton type="submit">Save</SubmitButton>
              </SubmitWrapper>
            </Form>
          )}}
      </Formik>
    </Two>
  )
}

const Title = styled.h4`
  margin: 12px 0;
`

const Two = styled.div`
  width: 70%;
  margin: 32px auto;
`

const HR = styled.hr`
  width: 100%;
`

const SubmitButton = styled(Button)`
  background: #a333c8;
  border-color: #a333c8;
  color: white;
  margin-top: 16px;
`

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const OptionalFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`

const TextWrapper = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`

const ErrorText = styled.div`
  color: #f44336;
  font-weight: 400;
`

const RadioLabel = styled.div`
  margin-left: 8px;
`

const SelectOption = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`

const TextField = styled(Field)`
  width: 100%;
  padding: 9.5px 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease, border-color 0.1s ease;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  flex: 1 0 auto;

  &::-webkit-input-placeholder {
    color: #616161;
  }

  &:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

const LabelLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
`
const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export default FormTwo
