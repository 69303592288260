import React from 'react'
import styled from '@emotion/styled'
import { BoxWrapper, Button } from 'components/theme'
import { FormField, Label } from 'components/theme/form'
import { useOrg } from 'data/hooks'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Dropdown, DropdownItemProps } from 'semantic-ui-react'
import { useListingStepper } from '../ListingStepperContext'
import ActiveProducts from './ActiveProducts'
import BackButton from 'components/theme/BackButton'
import { useCreateFreeProductListing } from '../useCreateFreeProductListing'
import { toast } from 'react-toastify'

const SelectProduct = () => {
  const { productListings, orgListings } = useListingStepper()
  const { vendor, lawfirm } = useOrg()
  const [productId, setProductId] = useState<number | undefined>()
  const navigate = useNavigate()
  const { slug } = useParams()
  const location = useLocation()
  const createFreeProductListing = useCreateFreeProductListing()

  const isService = location.pathname.includes('select-service')

  const products = vendor ? vendor.products : lawfirm?.products

  const hasOrgListing = orgListings?.some(({ association }) => slug === association.slug)

  const options: DropdownItemProps[] =
    products
      ?.filter(
        ({ id }) =>
          !productListings?.some(
            ({ product, association }) => product.id === id && association.slug === slug
          )
      )
      .map(({ details, name, id }) => ({
        image: details.squareLogoUrl,
        text: name,
        value: id,
      })) || []

  const selectProductClick = async () => {
    if (hasOrgListing && productId) {
      await createFreeProductListing(productId)
      toast.success(`Added ${isService ? 'service' : 'product'} to listing`)
      navigate(`..`)
    } else {
      navigate(`../product-plans/${slug}`, {
        state: { productId },
      })
    }
  }

  return (
    <BoxWrapper>
      <BackButton />
      <Root>
        {options.length > 0 ? (
          <>
            {isService ? (
              <>
                <Title>Select or Create Service</Title>
                <Content>
                  Select or create a new non-legal service to associate with this listing. Not for
                  Law practices*
                </Content>
              </>
            ) : (
              <>
                <Title>Select or Create Product</Title>
                <Content>Select or create a new product to associate with this listing</Content>
              </>
            )}
            <Wrapper>
              <Label>Available in {isService ? 'Service' : 'Product'} Library</Label>
              <FixDropdown>
                <FormField>
                  <Dropdown
                    fluid
                    selection
                    onChange={(_e, { value }) => setProductId(value as number)}
                    options={options}
                    name="productId"
                  />
                </FormField>
              </FixDropdown>
              <Button onClick={selectProductClick} disabled={!productId}>
                Continue with this {isService ? 'Service' : 'Product'}
              </Button>
              <Line>
                <OrLine>Or</OrLine>
              </Line>
              <Button
                component="link"
                to={`../create-${isService ? 'service' : 'product'}/${slug}`}
              >
                Create new {isService ? 'Service' : 'Product'} Listing
              </Button>
            </Wrapper>
          </>
        ) : (
          <>
            <Title>Create {isService ? 'Service' : 'Product'}</Title>
            <CenterButton>
              <Button
                component="link"
                to={`../create-${isService ? 'service' : 'product'}/${slug}`}
              >
                Create new {isService ? 'Service' : 'Product'} Listing
              </Button>
            </CenterButton>
          </>
        )}
        <ActiveProducts />
      </Root>
    </BoxWrapper>
  )
}

const Title = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`

const CenterButton = styled.div`
  text-align: center;
`

const Content = styled.p``

const Root = styled.div`
  max-width: 500px;
  margin: 0 auto;
`

const Wrapper = styled.div``

const Line = styled.div`
  text-align: center;
  border-bottom: 2px solid #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  margin-top: 8px;
`

const OrLine = styled.span`
  background: white;
  transform: translateY(50%);
  font-weight: bold;
  color: #333;
  padding: 0 8px;
`

const FixDropdown = styled.div`
  .divider.text {
    display: flex;
  }
`

export default SelectProduct
