import styled from '@emotion/styled'
import { Formik, FieldArray, Form } from 'formik'
import { Button } from 'components/theme'
import * as yup from 'yup'
import LocationItem from './LocationBlock'
import { Label } from 'components/theme/form'
import { Dropdown } from 'semantic-ui-react'

const validationSchema = yup.object().shape({})

function LocationForm({
  defaultValues,
  onSubmit,
  onDelete,
  updateHeadquarters,
  locationsOptions = [],
  onSubmitButtonText = 'Save Locations',
}: any) {
  return (
    <Root>
      <Formik
        initialValues={{ ...defaultValues }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ values, setFieldValue, isValid, isSubmitting }) => (
          <Form>
            <FormField>
              <Label required>Headquarter</Label>
              <Dropdown
                fluid
                selection
                onChange={(_e, { value }) => setFieldValue('headquarterId', value)}
                options={locationsOptions}
                value={values.headquarterId}
                name="headquarterId"
              />
            </FormField>
            <FieldArray
              name="locations"
              render={(arrayHelpers) => (
                <LocationsWrapper>
                  {values.locations?.map((location: any, index: number) => (
                    <LocationItem
                      isHeadquarter={location.isHeadquarter}
                      updateHeadquarters={updateHeadquarters}
                      key={`${location}-${index}`}
                      index={index}
                      onDelete={() => {
                        arrayHelpers.remove(index)
                        onDelete(index)
                      }}
                    />
                  ))}
                  <SmallButton
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        city: '',
                        state: [],
                        country: [],
                        id: '',
                      })
                    }
                  >
                    + Add location
                  </SmallButton>
                </LocationsWrapper>
              )}
            />
            <FormField>
              <Button disabled={isSubmitting || !isValid} loading={isSubmitting} primary>
                {onSubmitButtonText}
              </Button>
            </FormField>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div``

const SmallButton = styled(Button)`
  border: none;
  &:hover {
    border: none;
  }
`

const LocationsWrapper = styled.div`
  margin-bottom: 30px;
`

const FormField = styled.div`
  margin-bottom: 20px;
`

export default LocationForm
