import { Button } from 'components/theme'
import { Formik, Form, FieldArray } from 'formik'
import { FormField, TextInput, Dropdown } from 'components/theme/form'
import { useAssocHome, useQueryProductsDropdown, useUpdateAssocHome } from 'data/hooks'
import styled from '@emotion/styled'
import PulsatingLoader from 'components/PulsatingLoader'
import { css } from '@emotion/react'

interface Props {
  assocHomeId: number
  onSuccess: () => void
}

interface Values {
  title?: string
  subtitle?: string
  productIds: number[]
}

const ProductGridForm = ({ assocHomeId, onSuccess }: Props) => {
  const { assocHome, isLoading } = useAssocHome(assocHomeId)
  const { data: productOptions } = useQueryProductsDropdown()
  const updateAssocHome = useUpdateAssocHome()

  const onSubmit = async (values: Values) => {
    await updateAssocHome.mutateAsync({ id: assocHomeId, details: { ...values } })
    onSuccess()
  }

  if (isLoading) return <PulsatingLoader />

  return (
    <Formik initialValues={{ ...assocHome?.details }} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <FormWrapper>
              <FormField>
                <TextInput label="Title" name="title" />
              </FormField>
              <FormField>
                <TextInput label="Subtitle" name="subtitle" />
              </FormField>
              <Line />
              <FieldArray
                name="productIds"
                render={(arrayHelpers) => (
                  <FormField>
                    {values.productIds && values.productIds.length > 0 ? (
                      values.productIds.map((productId, index) => (
                        <ProductFieldWrapper key={index}>
                          <DropdownWrapper>
                            <Dropdown
                              name={`productIds.${index}`}
                              label="Product"
                              options={productOptions}
                              setFieldValue={setFieldValue}
                              value={productId}
                              search
                            />
                          </DropdownWrapper>
                          <DeleteButton
                            type="button"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          >
                            -
                          </DeleteButton>
                          <InsertButton
                            type="button"
                            onClick={() => arrayHelpers.insert(index + 1, null)} // insert an empty string at a position
                          >
                            +
                          </InsertButton>
                        </ProductFieldWrapper>
                      ))
                    ) : (
                      <AddProductButton type="button" onClick={() => arrayHelpers.push(null)}>
                        Add a product
                      </AddProductButton>
                    )}
                  </FormField>
                )}
              />
              <ButtonWrapper>
                <Button type="submit">Update</Button>
              </ButtonWrapper>
            </FormWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}

const ButtonWrapper = styled.div`
  padding-top: 20px;
`

const Line = styled.div`
  border: none;
  border-bottom: 1px solid #ccc;
  margin: 20px 0;
`

const FormWrapper = styled.div`
  padding-bottom: 150px;
`

const ProductFieldWrapper = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;
  border-bottom: 1px solid #eee;
`

const DropdownWrapper = styled.div`
  flex: 1 1 auto;
`

const AddProductButton = styled.button`
  font-size: 14px;
  font-weight: 500;
  padding: 4px 16px;
  border-radius: 100px;
  border: none;
  background: ${({ theme }) => theme.color.primaryBlue};
  color: white;
`

const buttonStyles = css`
  width: 38px;
  height: 38px;
  border: none;
  color: white;
  border-radius: 100px;
  font-weight: 500;
  font-size: 18px;
  margin-top: 25px;
`

const InsertButton = styled.button`
  ${buttonStyles}
  margin-left: 8px;
  background: ${({ theme }) => theme.color.primaryBlue};
`

const DeleteButton = styled.button`
  ${buttonStyles}
  margin-left: 16px;
  background: ${({ theme }) => theme.color.red};
`

export default ProductGridForm
