import { useOrg } from 'data/hooks'
import { useGql } from 'hooks/useGql'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

interface Variables {
  associationId: string
  excerpt?: string
  description?: string
  details: any
}
export const useUpsertListingDetails = () => {
  const { organization } = useOrg()
  const request = useGql()

  return useMutation(async (variables: Variables) => {
    if (!organization) return

    const { excerpt, description, associationId, details } = variables

    await request(UPSERT_LISTING_DETAILS, {
      organizationId: organization.id,
      associationId,
      excerpt,
      description,
      details,
    })
  })
}

const UPSERT_LISTING_DETAILS = gql`
  mutation UpsertOrganizationListing(
    $organizationId: UUID!
    $associationId: UUID!
    $excerpt: String
    $description: String
    $details: JSON
  ) {
    upsertOrganizationListing(
      input: {
        organizationListing: {
          organizationId: $organizationId
          associationId: $associationId
          excerpt: $excerpt
          description: $description
          details: $details
        }
      }
      where: { organizationId: $organizationId, associationId: $associationId }
    ) {
      clientMutationId
    }
  }
`
