import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  logo: string
  name?: string
  order: number
  organizationId: string
}
export const useCreateVendorStackshareLogo = () => {
  const request = useGql()
  return useMutation(async (variables: Variables) => {
    const response = await request(CREATE_VENDOR_STACKSHARE_LOGO, variables)

    return response
  })
}

const CREATE_VENDOR_STACKSHARE_LOGO = gql`
  mutation CreateVendorStackshareLogo(
    $logo: String
    $name: String
    $order: Int
    $organizationId: UUID!
  ) {
    createVendorStackshareLogo(
      input: {
        vendorStackshareLogo: {
          organizationId: $organizationId
          logo: $logo
          name: $name
          order: $order
        }
      }
    ) {
      vendorStackshareLogo {
        id
      }
    }
  }
`
