import { useState } from 'react'
import styled from '@emotion/styled'
import { Dropdown, Input } from 'semantic-ui-react'
import { useAssociationStackshare } from './associationStackshareContext'

const AssociationFilters = () => {
  const {
    orgUserOptions,
    practiceGroupOptions,
    roleOptions,
    onSearchChange,
    onUserChange,
    onPracticeGroupChange,
    onRoleChange,
  } = useAssociationStackshare()
  const [, setSelectedLocation] = useState<string>('')

  const onChangeLocation = (_1: any, { value }: any) => setSelectedLocation(value as string)

  return (
    <SearchBarWrapper>
      <Input
        placeholder="Search for app..."
        fluid
        onChange={(_1, { value }) => onSearchChange(value)}
      />
      <SelectRow>
        <SelectColumn>
          <Dropdown
            placeholder="Location"
            options={[{ key: 'coming-soon-location', text: 'Coming Soon', value: undefined }]}
            onChange={onChangeLocation}
            selection
            fluid
          />
        </SelectColumn>

        <SelectColumn>
          <Dropdown
            placeholder="Practice Group"
            options={practiceGroupOptions}
            onChange={(_1, { value }) => {
              if (value === undefined || typeof value === 'number') onPracticeGroupChange(value)
            }}
            selection
            fluid
          />
        </SelectColumn>

        <SelectColumn>
          <Dropdown
            placeholder="Role"
            options={roleOptions}
            onChange={(_1, { value }) => onRoleChange(value as number)}
            selection
            fluid
          />
        </SelectColumn>

        <SelectColumn>
          <Dropdown
            placeholder="Users"
            options={orgUserOptions}
            onChange={(_1: any, { value }) => {
              if (value === undefined || typeof value === 'number') onUserChange(value)
            }}
            selection
            fluid
          />
        </SelectColumn>
      </SelectRow>
    </SearchBarWrapper>
  )
}

const SelectRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
  margin: 0 -8px;
`

const SelectColumn = styled.div`
  width: 25%;
  padding: 8px;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoint.tablet} {
    width: 50%;
  }

  ${({ theme }) => theme.breakpoint.mobile} {
    width: 100%;
  }
`
const SearchBarWrapper = styled.div`
  margin-bottom: 28px;
`

export default AssociationFilters
