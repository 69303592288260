import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import clocBlueSvg from 'assets/cloc/cloc-blue.svg'
import clocGoldSvg from 'assets/cloc/cloc-gold.svg'
import { useState } from 'react'
import { useOrg, useNoviOrganization, useCreateProductListing } from 'data/hooks'
import { Button } from 'components/theme'
import { isUsingProductionServer } from 'services/helper'
import { format } from 'date-fns'
import { useLazyApi } from 'hooks/useApi'
import { useViewer } from 'context/viewerContext'
import { Link, useLocation } from 'react-router-dom'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useProductListingCheckout } from '../useProductListingCheckout'
import { ProductState } from 'generated/graphql'

// const networkPriceIds = [
//   {
//     network: 'cloc',
//     priceIds: [
//       {
//         name: 'essential',
//         production: 'price_1KeQl2CldCjodC13wMzwDACb',
//         development: 'price_1KjrmCHIw3FF5Jrc2CWeynkm',
//       },
//       {
//         name: 'premium',
//         production: 'price_1KeQmDCldCjodC13DePzyHad',
//         development: 'price_1KjrpbHIw3FF5JrcDx0lnY5j',
//       },
//       {
//         name: 'enterprise',
//         production: 'price_1L5YqMCldCjodC13FXwF40GA',
//         development: 'price_1L5YFiHIw3FF5JrcjqGZpMPr',
//       },
//     ],
//   },
// ]

const BASIC_PRICE_ID = isUsingProductionServer
  ? 'price_1KeQl2CldCjodC13wMzwDACb'
  : 'price_1KjrmCHIw3FF5Jrc2CWeynkm'

const PREMIUM_PRICE_ID = isUsingProductionServer
  ? 'price_1KeQmDCldCjodC13DePzyHad'
  : 'price_1KjrpbHIw3FF5JrcDx0lnY5j'

const ASSOCIATION_ID = '5a172a54-130e-4cf6-8416-625f73950d98'
// const ACCOUNT_ID = isUsingProductionServer ? 'acct_1BtTBjCldCjodC13' : 'acct_1Ii7RTHIw3FF5Jrc' // TheoremLTS test account

const ClocPlans = () => {
  const { organization } = useOrg()
  const { noviOrganization } = useNoviOrganization()
  const [clocListing, setClocListing] = useState<any>(organization?.appMetadata?.clocListing)
  const [freeTrial, setFreeTrial] = useState<boolean>(
    organization?.appMetadata?.hasClocTrial || false
  )

  const productListingCheckout = useProductListingCheckout()
  const createProductListing = useCreateProductListing()
  const [getHasClocMembership] = useLazyApi('company/has-cloc-membership')
  const [hasMembership, setHasMembership] = useState(false)
  const { paymentStatus } = useViewer()
  const baseOrgUrl = useBaseOrgUrl()
  const location = useLocation()

  const isDiscount = noviOrganization?.status === 'CURRENT' || hasMembership
  const isActiveTheorem = paymentStatus === 'ACTIVE'
  // const isDiscount = true
  // const isActiveTheorem = true

  useEffect(() => {
    ;(async () => {
      const hasClocMembership = await getHasClocMembership()
      setHasMembership(hasClocMembership)
    })()
  }, [])

  const locationState = location.state as any

  const productId = locationState.productId

  const onBoxClick = (priceId: string) => async () => {
    const listingType = priceId === BASIC_PRICE_ID ? 'essential' : 'premium'

    console.log(listingType, isDiscount)
    if (priceId === BASIC_PRICE_ID && isActiveTheorem) {
      console.log('has discount')
      // await createProductListing.mutateAsync({
      //   associationId: ASSOCIATION_ID,
      //   productId,
      //   type: 'essential',
      //   state: ProductState.Active,
      // })
    } else {
      await productListingCheckout({
        priceId,
        productId,
        listingType,
        isClocDiscount: isDiscount,
      })
    }
  }

  return (
    <Root>
      {clocListing && (
        <div>
          <TextCenter>
            <ClocLogo src={clocBlueSvg} />
          </TextCenter>
          <ClocListing>
            We’ve Identified an active listing on <strong>CLOC’s Directory</strong>. Enter your
            payment details on the next screen to complete the transfer. You will NOT be charged
            until the renewal date on File -{' '}
            <strong>{format(clocListing?.expiredDate * 1000, 'MM/dd/yyyy')}</strong> - and will be
            notified in advance of renewal
          </ClocListing>
          <TextCenter>
            <Button onClick={() => setClocListing(null)}>Continue</Button>
          </TextCenter>
        </div>
      )}
      {freeTrial && (
        <div>
          <TextCenter>
            <ClocLogo src={clocBlueSvg} />
          </TextCenter>
          <ClocListing>
            We’ve Identified you have a free trial with <strong>CLOC’s Directory</strong>. Enter
            your payment details on the next screen to complete the transfer. You will NOT be
            charged until after the 90 day free trial is over.
          </ClocListing>
          <TextCenter>
            <Button onClick={() => setFreeTrial(false)}>Continue</Button>
          </TextCenter>
        </div>
      )}
      {!(clocListing || freeTrial) && (
        <Container>
          <MainTitle>
            {isDiscount ? 'Select a Listing Plan' : 'Purchase Listing | CLOC MEMBERS SAVE 20%'}
          </MainTitle>
          {isDiscount ? (
            <GreenText>
              Congrats! We found an active CLOC corporate membership for this email account.
              Discount Applied.
            </GreenText>
          ) : isActiveTheorem ? (
            <>
              <NonDiscountText>Discount requires active CLOC corporate membership.</NonDiscountText>
              <ActiveTheoremDiscount>
                Congrats! You still Save 15% with your PRO membership.
              </ActiveTheoremDiscount>
            </>
          ) : (
            <>
              <NonDiscountText>Discount requires active CLOC corporate membership.</NonDiscountText>
              <TheoremProDiscount>
                Or, <Link to={`${baseOrgUrl}/upgrade`}>Save 15% with Theorem PRO</Link>
              </TheoremProDiscount>
            </>
          )}
          <Boxes>
            <Column>
              <Box onClick={onBoxClick(BASIC_PRICE_ID)}>
                <Logo src={clocBlueSvg} />
                <Title>Essentials</Title>
                <List>
                  <Item>Listing Management Portal</Item>
                  <Item>CLOC Badge on Listing</Item>
                  <Item>Enhanced Search Placement</Item>
                  <Item>RFP Participation</Item>
                  <Item>Marketplace Insights Newsletter</Item>
                </List>
                <p>i.e. “Product X Is now available through the CLOC Network”</p>
                {isDiscount || isActiveTheorem ? (
                  <>
                    <PriceWrapper>
                      <DiscountPrice>Free with Pro</DiscountPrice>
                    </PriceWrapper>
                  </>
                ) : (
                  <PriceWrapper>
                    <Price>$625</Price>
                  </PriceWrapper>
                )}
              </Box>
            </Column>
            <Column>
              <Box onClick={onBoxClick(PREMIUM_PRICE_ID)}>
                <Logo src={clocGoldSvg} />
                <Title>Premium</Title>
                <List>
                  <Item>Everything in Essential, Plus;</Item>
                  <Item>Premium Gold CLOC Badge</Item>
                  <Item>Top-of-Search Placement</Item>
                  <Item>Featured Home Page Placement</Item>
                  <Item>Highlighted Offers &amp; Test Drives in TechHub</Item>
                  <Item>Featured promotion in Theorem Outreach</Item>
                  <Item>New | Legal Ops Premium Categories</Item>
                </List>
                {isDiscount ? (
                  <>
                    <PriceWrapper>
                      <DiscountPrice>$2,500</DiscountPrice>
                      <OldPrice>$3,125</OldPrice>
                    </PriceWrapper>
                    <Discount>20% Off Applied!</Discount>
                  </>
                ) : isActiveTheorem ? (
                  <>
                    <PriceWrapper>
                      <DiscountPrice>$2,656.25</DiscountPrice>
                      <OldPrice>$3,125</OldPrice>
                    </PriceWrapper>
                    <Discount>15% Off Applied!</Discount>
                  </>
                ) : (
                  <PriceWrapper>
                    <Price>$3,125</Price>
                  </PriceWrapper>
                )}
              </Box>
            </Column>
          </Boxes>
        </Container>
      )}
    </Root>
  )
}

const Root = styled.div``

const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
`

const TheoremProDiscount = styled.p`
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 16px;
  color: #43ba1a;
  margin-top: 8px;

  a {
    color: #43ba1a;
    text-decoration: underline;

    &:hover {
      color: #333;
    }
  }
`

const ActiveTheoremDiscount = styled.div`
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 8px;
  font-size: 16px;
  color: #43ba1a;
`

const MainTitle = styled.h2`
  text-align: center;
  margin-bottom: 0px;
`

const ClocListing = styled.div`
  font-size: 18px;
  line-height: 28px;
  margin: 20px auto;
  max-width: 690px;
`

const ClocLogo = styled.img`
  max-width: 170px;
`

const TextCenter = styled.div`
  text-align: center;
`

const Boxes = styled.div`
  display: flex;
  margin-left: -16px;
  margin-right: -16px;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: block;
  }
`

const Column = styled.div`
  display: flex;
  padding: 0 16px;
  width: 50%;

  ${({ theme }) => theme.breakpoint.mobile} {
    width: 100%;
    margin: 0 auto 24px;
    max-width: 300px;
  }
`

const Logo = styled.img`
  max-width: 120px;
`

const GreenText = styled.p`
  color: #43ba1a;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.02rem;
`

const NonDiscountText = styled.p`
  text-align: center;
  color: #e16703;
  font-size: 16px;
  margin-bottom: 0;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 20px;
  cursor: pointer;
  border: transparent 2px solid;
  transition: border 250ms;

  &:hover {
    border: #4cd0d1 2px solid;
  }
`

const Title = styled.h4`
  font-size: 20px;
  margin-top: 16px;
`

const List = styled.ul`
  padding-left: 16px;
  margin-bottom: 20px;
`

const Item = styled.li`
  font-size: 14px;
  margin-bottom: 4px;
  letter-spacing: 0.02rem;
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-bottom: 4px;
`

const Price = styled.div`
  font-size: 20px;
  font-weight: 600;
`

const DiscountPrice = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-right: 12px;
`

const OldPrice = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-decoration: line-through;
  color: grey;
`

const Discount = styled.div`
  color: #43ba1a;
  font-weight: 500;
`

export default ClocPlans
