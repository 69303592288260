import styled from '@emotion/styled'
import { Button } from 'components/theme'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Link } from 'react-router-dom'
import { breakpoint } from 'services/theming'

const EltaWidget = () => {
  const baseOrgUrl = useBaseOrgUrl()

  return (
    <Box>
      <Top>
        <IconWrapper>
          <Logo src="https://res.cloudinary.com/dh1giwabb/image/upload/v1634305743/smnvtpiiubej8zfepugq.jpg" />
        </IconWrapper>
        <TopContent>
          <Title>ELTA</Title>
          <Type>Legal Network</Type>
        </TopContent>
      </Top>
      <Text>
        Join the ELTA Network and feature your product(s) on their network’s custom ecosystem. ELTA
        Membership & Seller Account required.
      </Text>
      <Bottom>
        <MemberLink to={`${baseOrgUrl}/discover-organizations/association`}>
          Already a member?
        </MemberLink>
        <CtaButton component="link" to={`${baseOrgUrl}/discover-organizations/association`}>
          Join
        </CtaButton>
      </Bottom>
    </Box>
  )
}

const MemberLink = styled(Link)`
  font-style: italic;
  color: #555;
  font-weight: 600;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  ${breakpoint.desktop} {
    margin-right: 20px;
  }
`

const Logo = styled.img`
  width: 100%;
  max-width: 80px;
`

const Box = styled.div`
  background: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  order: 3;

  ${breakpoint.desktop} {
    order: 2;
  }
`

const TopContent = styled.div`
  font-size: 16px;
  line-height: 1.5;
`

const Title = styled.strong``
const Type = styled.div``

const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
`

const Text = styled.p`
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  width: 90%;
  max-width: 350px;
`

const CtaButton = styled(Button)`
  margin-right: 0;
  margin-left: 16px;
`

export default EltaWidget
