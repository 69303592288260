import styled from '@emotion/styled'
import { Button } from 'components/theme'
import { useRedirect } from 'hooks/useRedirect'
import ProductImage from './product-wrapper.svg'
import BaseModal from 'components/modals/BaseModal'

const ProductServiceModal = ({ toggleModal }: { toggleModal: () => void }) => {
  const redirect = useRedirect()
  return (
    <BaseModal toggleModal={toggleModal}>
      <Root>
        <Title>Product and Service Listings:</Title>
        <p>Create a single page for one of your organization’s solutions</p>
        <ImageBox>
          <Image src={ProductImage} />
        </ImageBox>
        <SmallTitle>Features</SmallTitle>
        <FeatureList>
          <FeatureItem>Add a standalone page for product details and information.</FeatureItem>
          <FeatureItem>Showcase product details such as reviews, media, and features.</FeatureItem>
          <FeatureItem>Generate high-quality leads for a specific product.</FeatureItem>
        </FeatureList>
        <ButtonWrapper>
          <GetStartedButton onClick={() => redirect('listing-stepper/networks')}>
            Get Started
          </GetStartedButton>
        </ButtonWrapper>
      </Root>
    </BaseModal>
  )
}

const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: 20px;
`

const GetStartedButton = styled(Button)`
  width: 100%;
  max-width: 200px;
`

const Root = styled.div`
  padding: 24px;
  max-width: 500px;
  margin: 0 auto;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`

const ImageBox = styled.div`
  background: #f9fafb;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 20px;
`

const Image = styled.img`
  max-height: 105px;
`

const SmallTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
`

const FeatureList = styled.ul``

const FeatureItem = styled.li``

export default ProductServiceModal
