import styled from '@emotion/styled'
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

interface DropzoneFieldProps {
  onFileChange: (files: File[]) => void
  fluidWidth?: boolean
  setImage?: (image: string) => void
  children: React.ReactNode
  defaultValue?: string | null
}

const DropzoneField = ({
  onFileChange,
  fluidWidth,
  setImage,
  defaultValue,
  children,
}: DropzoneFieldProps) => {
  const [previewImage, setPreviewImage] = useState<string | null>(
    typeof defaultValue === 'string' ? defaultValue : null
  )

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      const reader = new FileReader()

      reader.onloadend = () => {
        setPreviewImage(reader.result as string)
        if (setImage) setImage(reader.result as string)
      }

      if (file) {
        reader.readAsDataURL(file)
      }

      onFileChange(acceptedFiles)
    },
    [onFileChange]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg', '.svg'],
    },
    multiple: false,
  })

  const removeFile = () => {
    setPreviewImage(null)
    onFileChange([])
  }

  return (
    <>
      {previewImage && previewImage !== null ? (
        <ImageContainer>
          <DeleteButton onClick={removeFile} />
          <Image src={previewImage} alt="Preview" width="200" />
        </ImageContainer>
      ) : (
        <Root {...getRootProps()} fluidWidth={!!fluidWidth}>
          <div>
            <input {...getInputProps()} />
          </div>
          {children}
        </Root>
      )}
    </>
  )
}

const Root = styled.div<{ fluidWidth: boolean }>`
  max-width: ${({ fluidWidth }) => (fluidWidth ? 'none' : '150px')};
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgba(34, 36, 38, 0.5);
  background: #fcfcfc;
  color: #646571;
  border-radius: 3px;
  cursor: copy;
`

const DeleteButton = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 1px;
  right: 1px;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  cursor: pointer;
  display: none;

  &:before,
  &:after {
    top: 50%;
    left: 50%;
    content: '';
    width: 14px;
    height: 3px;
    background: #333;
    position: absolute;
    border-radius: 10px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    ${DeleteButton} {
      display: block;
    }
  }
`

const Image = styled.img`
  width: 100%;
  max-width: 100%;
  max-height: 130px;
`

export default DropzoneField
