import { useAuth0 } from '@auth0/auth0-react'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/hooks'
import { OrganizationType } from 'generated/graphql'

export const useMarketplaceUrl = (removeReturnTo?: boolean) => {
  const { organization } = useOrg()
  const { viewer } = useViewer()
  const { user: auth0User } = useAuth0()

  const domain =
    process.env.NODE_ENV === 'development'
      ? `https://ecosystem.theorem.local:3300`
      : 'https://ecosystem.theoremlegal.com'

  let marketplaceUrl = `${domain}/api/auth/login?`

  if (!removeReturnTo) {
    if (organization?.type === OrganizationType.Company) {
      const association = viewer.organizations.find(
        (organization) => organization.type === OrganizationType.Association
      )

      if (association) marketplaceUrl = `${marketplaceUrl}returnTo=/a/${association.slug}&`
    }

    if (organization?.type === OrganizationType.Association) {
      marketplaceUrl = `${marketplaceUrl}returnTo=/a/${organization?.slug}&`
    }
  }

  if (auth0User?.org_id) {
    marketplaceUrl = `${marketplaceUrl}organization=${auth0User?.org_id}&`
  }

  return marketplaceUrl
}
