import { MemberStatus, OrganizationType, UserRole, UserStatus } from 'generated/graphql'
import { Product, UserProduct } from 'interfaces/product/product.interface'

export interface UserOrganization {
  id: string
  name: string
  slug: string
  updatedAt: string
  createdAt: string
  details: any
  type: OrganizationType
  role: UserRole
  jobTitle: number
  status: MemberStatus
  memberCount: number
}

interface MatchedOrganization {
  role: UserRole
  organization: UserOrganization
}

export interface UserDetails {
  matchedProducts?: UserProduct[]
  matchedOrganizations?: MatchedOrganization[]
  [key: string]: any
}

interface Term {
  id: number
  name: string
  taxonomyId: number
}

export interface UserTechstack {
  // products included in techstack
  [key: string]: any
  id: number
  name: string
  details: any
  slug: string
  show: boolean
  terms: Array<{
    name: string
    id: number
    taxonomyId: number
  }>
  techstackId: number
  logins: number
  lastLogin: string
}

export interface UserProfileTechstack {
  // products included in techstack
  [key: string]: any
  id: number
  name: string
  details: any
  slug: string
  show: boolean
  terms: Term[]
  techstackId?: number
  logins?: number
  lastLogin: string
}

export interface UserPracticeGroups {
  id: number
  name: string
}

export interface User {
  id: number | null
  first: string
  last: string
  email: string
  jobTitle: number
  role: UserRole | null // dynamically added depending on organization
  details: UserDetails
  status: UserStatus
  currentOrganization?: string
  organizations: UserOrganization[]
  techstack: UserTechstack[]
  practiceGroups: UserPracticeGroups[]
  personalTechstack: Product[]
  terms: Term[]
}

export enum ViewerSubscriptions {
  VendorPro = 'VENDOR_PRO',
  VendorEnterprise = 'VENDOR_ENTERPRISE',
  VendorStartup = 'VENDOR_STARTUP',
  EcosystemUser = 'ECOSYSTEM_USER',
  LawfirmPro = 'LAWFIRM_PRO',
  CustomCore = 'CUSTOM_CORE',
  Free = 'FREE',
}
