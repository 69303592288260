import { useMutation } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useUpdateUserToOrgLocation = () => {
  const request = useGql()
  const { viewer } = useViewer()
  const { organization } = useOrg()

  return useMutation(async (locationId: number) => {
    await request(UPDATE_USER_TO_ORG_LOCATION, {
      locationId,
      orgId: organization?.id,
      userId: viewer.id,
    })
  })
}

const UPDATE_USER_TO_ORG_LOCATION = gql`
  mutation updateUserToOrganizationLocation($orgId: UUID!, $userId: Int!, $locationId: Int!) {
    updateUsersToOrganization(
      input: { patch: { locationId: $locationId }, userId: $userId, organizationId: $orgId }
    ) {
      clientMutationId
    }
  }
`
