import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'
import { FullPageLoading } from 'components/PulsatingLoader'
import { breakpoint } from 'services/theming'
import { Product } from 'interfaces'
import TestDriveModal from 'components/modals/TestDriveModal'
import { useNavigate } from 'react-router-dom'
import { isUsingStagingServer } from 'services/helper'
import TestDriveBox from './TestDriveBox'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useTestDriveList } from 'data/hooks'

type TestDrive = {
  product: Product
  stripeProductId: string
  priceId: string
  priceId2?: string
  freeTrialDays?: number
  features: string[]
  accountId?: string
  description: string
  isVendorToVendor: boolean
}

interface TermToProduct {
  term: { taxonomyId: number; name: string }
}

function TestDrive() {
  const { testDrives, isLoading } = useTestDriveList()
  const [testDrive, setTestDrive] = useState<TestDrive[]>([])
  const [testDrivesForYou, setTestDrivesForYou] = useState<TestDrive[]>([])
  const [testDrivesForOrg, setTestDrivesForOrg] = useState<TestDrive[]>([])
  const [offersForYou, setOffersForYou] = useState<TestDrive[]>([])
  const [offersForOrg, setOffersForOrg] = useState<TestDrive[]>([])
  const [isShowModal, setIsShowModal] = useState(false)
  const [modalData, setModalData] = useState<TestDrive | undefined>()
  const { viewer } = useViewer()
  const { organization } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoading) return
    const environment = isUsingStagingServer ? 'test' : 'production'

    const testDriveData = testDrives ? testDrives.filter(({ testMode }: any) => !testMode) : []

    const newTestDrive = testDriveData
      .filter(({ details }: any) => {
        if (environment === 'test') return true
        return details?.stripe?.[environment]
      })
      .map(({ product, details, termToTestDrives }: any) => {
        // this is a hack to allow production details into test
        const stripeEnvDetails = details?.stripe?.[environment]
        const stripeDetails =
          !stripeEnvDetails && environment === 'test'
            ? details?.stripe?.production
            : stripeEnvDetails

        return {
          product: {
            ...product,
            bestFor:
              product?.termToProducts
                ?.filter(({ term }: TermToProduct) => term.taxonomyId === 16)
                .map(({ term }: TermToProduct) => ({ name: term.name })) || [],
            practiceGroups:
              product?.termToProducts
                ?.filter(({ term }: TermToProduct) => term.taxonomyId === 13)
                .map(({ term }: TermToProduct) => ({ name: term.name })) || [],
          },
          stripeProductId: stripeDetails?.productId,
          priceId: stripeDetails?.priceId,
          priceId2: stripeDetails?.priceId2,
          freeTrialDays: details?.stripe?.freeTrialDays,
          accountId: details?.stripe?.accountId,
          description: details?.description,
          features: details?.features?.map(({ name }: any) => name) || [],
          isVendorToVendor: details?.isVendorToVendor,
          terms: getTerms(termToTestDrives),
        }
      })

    setTestDrive(newTestDrive)
    setForUser(newTestDrive)
    setForOrg(newTestDrive)
  }, [isLoading, JSON.stringify(testDrives)])

  const setForUser = (newTestDrive: any) => {
    const testDrivesForYou = newTestDrive.filter((testDrive: any) => {
      return testDrive.terms.some(
        (term: any) =>
          term.id === viewer.jobTitle ||
          viewer.practiceGroups.some((practiceGroup) => practiceGroup.id === term.id)
      )
    })

    const testDrivesFreeTrial = testDrivesForYou.filter((testDrive: any) => {
      return testDrive.freeTrialDays
    })

    setTestDrivesForYou(testDrivesFreeTrial)

    const offersForYou = testDrivesForYou.filter((testDrive: any) => {
      return !testDrive.freeTrialDays
    })

    setOffersForYou(offersForYou)
  }

  const setForOrg = (newTestDrive: any) => {
    const testDrivesForOrg = newTestDrive.filter((testDrive: any) => {
      return testDrive.terms.some((term: any) =>
        organization?.terms.some((lawfirmTerm) => lawfirmTerm.id === term.id)
      )
    })

    const testDrivesFreeTrial = testDrivesForOrg.filter((testDrive: any) => {
      return testDrive.freeTrialDays
    })

    setTestDrivesForOrg(testDrivesFreeTrial)

    const offersForOrg = testDrivesForOrg.filter((testDrive: any) => {
      return !testDrive.freeTrialDays
    })

    setOffersForOrg(offersForOrg)
  }

  if (isLoading) return <FullPageLoading />

  const showModal = (testDrive: TestDrive) => {
    setIsShowModal(true)
    setModalData(testDrive)
  }

  const toggleModal = () => {
    setIsShowModal((prevIsShowModal) => !prevIsShowModal)
  }

  const onModalButtonClick = () => {
    navigate(`${baseOrgUrl}/test-drive/plan-details`, {
      state: {
        productId: modalData?.product.id,
        stripeProductId: modalData?.stripeProductId || '',
        priceId: modalData?.priceId || '',
        freeTrialDays: modalData?.freeTrialDays,
        accountId: modalData?.accountId,
        isVendorToVendor: modalData?.isVendorToVendor,
      },
    })
  }

  return (
    <Root>
      {testDrivesForYou.length > 0 && (
        <>
          <Title>Test Drives for you</Title>
          <BoxWrapper>
            <TestDriveBlock>
              <TestDriveList>
                {testDrivesForYou.map(({ product, ...testDrive }) => (
                  <TestDriveBox
                    key={product.id}
                    id={product.id}
                    image={product.details.squareLogoUrl || product.details.logoUrl || ''}
                    name={product.name}
                    freeTrialDays={testDrive.freeTrialDays}
                    excerpt={testDrive.description || product.excerpt}
                    onClick={() => showModal({ ...testDrive, product })}
                  />
                ))}
              </TestDriveList>
            </TestDriveBlock>
          </BoxWrapper>
        </>
      )}
      {offersForYou.length > 0 && (
        <>
          <Title>Offers for you</Title>
          <BoxWrapper>
            <TestDriveBlock>
              <TestDriveList>
                {offersForYou.map(({ product, ...testDrive }) => (
                  <TestDriveBox
                    key={product.id}
                    id={product.id}
                    image={product.details.squareLogoUrl || product.details.logoUrl || ''}
                    name={product.name}
                    freeTrialDays={testDrive.freeTrialDays}
                    excerpt={testDrive.description || product.excerpt}
                    onClick={() => showModal({ ...testDrive, product })}
                  />
                ))}
              </TestDriveList>
            </TestDriveBlock>
          </BoxWrapper>
        </>
      )}
      {testDrivesForOrg.length > 0 && (
        <>
          <Title>Test Drives for your organization</Title>
          <BoxWrapper>
            <TestDriveBlock>
              <TestDriveList>
                {testDrivesForOrg.map(({ product, ...testDrive }) => (
                  <TestDriveBox
                    key={product.id}
                    id={product.id}
                    image={product.details.squareLogoUrl || product.details.logoUrl || ''}
                    name={product.name}
                    freeTrialDays={testDrive.freeTrialDays}
                    excerpt={testDrive.description || product.excerpt}
                    onClick={() => showModal({ ...testDrive, product })}
                  />
                ))}
              </TestDriveList>
            </TestDriveBlock>
          </BoxWrapper>
        </>
      )}
      {offersForOrg.length > 0 && (
        <>
          <Title>Offers for your organization</Title>
          <BoxWrapper>
            <TestDriveBlock>
              <TestDriveList>
                {offersForOrg.map(({ product, ...testDrive }) => (
                  <TestDriveBox
                    key={product.id}
                    id={product.id}
                    image={product.details.squareLogoUrl || product.details.logoUrl || ''}
                    name={product.name}
                    freeTrialDays={testDrive.freeTrialDays}
                    excerpt={testDrive.description || product.excerpt}
                    onClick={() => showModal({ ...testDrive, product })}
                  />
                ))}
              </TestDriveList>
            </TestDriveBlock>
          </BoxWrapper>
        </>
      )}
      <Title>All Test Drives and offers</Title>
      <BoxWrapper>
        <TestDriveList>
          {testDrive.map(({ product, ...testDrive }) => (
            <TestDriveBox
              key={product.id}
              id={product.id}
              image={product.details.squareLogoUrl || product.details.logoUrl || ''}
              name={product.name}
              freeTrialDays={testDrive.freeTrialDays}
              excerpt={testDrive.description || product.excerpt}
              onClick={() => showModal({ ...testDrive, product })}
            />
          ))}
        </TestDriveList>
      </BoxWrapper>
      <TestDriveModal
        onClick={onModalButtonClick}
        isShowing={isShowModal}
        toggleModal={toggleModal}
        product={modalData?.product}
        features={modalData?.features}
        freeTrialDays={modalData?.freeTrialDays}
      />
    </Root>
  )
}

type TermsData = {
  term: {
    id: number
    name: string
    slug: string
    taxonomyId: number
    taxonomy: {
      id: number
      name: string
      slug: string
    }
  }
}

const getTerms = (terms: TermsData[]) => {
  return terms.map(({ term }) => term)
}

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  max-width: 1200px;
  margin: 32px auto 20px;
  width: 100%;
`

const Root = styled.div`
  padding: 0px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${breakpoint.mobile} {
    padding: 0;
  }
`

const TestDriveList = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
`

const TestDriveBlock = styled.div`
  margin-bottom: -20px;
`

export default TestDrive
