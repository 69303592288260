import { useQuery } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlHasOrganizationListingsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useHasOrganizationListings = () => {
  const request = useGql()
  const { organization } = useOrg()
  const { data, ...other } = useQuery(
    ['hasOrgListing', organization?.id],
    async () => {
      const response = await request<GqlHasOrganizationListingsQuery>(HAS_ORG_LISTINGS, {
        organizationId: organization?.id,
      })

      return response.organizationListings
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  )

  return { orgListings: data, ...other }
}

const HAS_ORG_LISTINGS = gql`
  query HasOrganizationListings($organizationId: UUID!) {
    organizationListings(
      condition: { organizationId: $organizationId }
      filter: { connectPurchaseId: { isNull: false } }
    ) {
      associationId
      association {
        slug
        name
        details
        id
      }
    }
  }
`
