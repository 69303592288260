import AppBox from 'components/AppBox'
import styled from '@emotion/styled'

function PreviewBox({
  name,
  excerpt,
  squareLogo,
  logo,
}: {
  name: string
  excerpt: string
  squareLogo: any
  logo: any
}) {
  return (
    <Root>
      <PreviewContainer>
        <Box>
          <PreviewTitle>Preview Card</PreviewTitle>
          <AppBox name={name} excerpt={excerpt} logoUrl={selectLogoUrl(squareLogo, logo)} />
        </Box>
      </PreviewContainer>
    </Root>
  )
}

const selectLogoUrl = (squareLogo: any, logo: any) => {
  if (squareLogo && typeof squareLogo === 'string') {
    return squareLogo
  }
  if (squareLogo && typeof squareLogo === 'object' && squareLogo.length > 0) {
    return squareLogo
  }

  return logo
}

const Root = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const PreviewContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 0px;
  text-align: center;
  position: relative;
  max-width: 760px;
`

const Box = styled.div`
  position: relative;
  border: 1px solid #7e57c2;
  padding: 20px;
  border-radius: 12px;
  flex: 0 0 0;
`

const PreviewTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  color: #7e57c2;
  text-transform: uppercase;
  background: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
`

export default PreviewBox
