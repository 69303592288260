import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useSetUserStatus } from 'hooks/graphql'
import { useLazyApi } from 'hooks/useApi'
import getStripeProductKey from './getStripeProductKey'
import Button from 'components/theme/Button'
import { SUBSCRIPTION_AGREEMENT, TERMS_AND_CONDITIONS } from 'services/globalAssets'
import { UserStatus } from 'generated/graphql'
import { useMutateViewer } from 'data/viewer/useMutateViewer'
import { useAppMetadata } from 'data/viewer/useAppMetadata'
import OrgBlock from './OrgBlock'

// This is a pretty hefty page only after clicking the launch button. Some factors we have to deal
// with are the fact that the user who isn't part of the org can also create a location on this page.
// The idea to handle that is to allow the user to visually create the location, and cache that value
// locally and only when the user clicks Launch, all the data will be injected into the database.
function FoundOrganization() {
  const mutateViewer = useMutateViewer()
  const [loading, setLoading] = useState(true)
  const { setUserStatus } = useSetUserStatus()
  const [stripeKey, setStripeKey] = useState<string | null>(null)
  const { data: appMetadata, isLoading } = useAppMetadata()

  const navigate = useNavigate()

  const [getOrganizationPlan] = useLazyApi('stripe/organization/get-organization-plan')
  const [requestInviteEmail] = useLazyApi('company/request')

  const selectedOrganization = appMetadata.matchedOrg

  useEffect(() => {
    ;(async () => {
      const { stripeProductId } = await getOrganizationPlan()

      const stripeKey = getStripeProductKey(stripeProductId)
      setStripeKey(stripeKey)
      setLoading(false)
    })()
  }, [])

  const redirectToRoles = async () => {
    mutateViewer.mutate(
      {
        details: {
          personalType: 'OTHER',
        },
      },
      {
        onSuccess: () => {
          setUserStatus(UserStatus.SelectRole)
          navigate('/setup/personal-user')
        },
      }
    )
  }

  const requestForInvite = async () => {
    await requestInviteEmail({
      organizationId: selectedOrganization.id,
    })

    await setUserStatus(UserStatus.RequestScreen)

    navigate('/setup/request')
  }

  if (loading || isLoading) return <FullPageLoading />

  return (
    <>
      <Root>
        <Container>
          <Title>
            {stripeKey === 'ECOSYSTEM_USER'
              ? 'Owner need to allow you in'
              : 'Confirm & Select Location'}
          </Title>
          <Subtitle>Looks like we found your Organization! Welcome.</Subtitle>

          <SelectionContainer>
            {stripeKey === 'ECOSYSTEM_USER' ? (
              <div>
                <p>
                  It looks like this owner needs to upgrade their account to let you in. Please
                  contact the owner.
                </p>
                <Button onClick={requestForInvite}>Request for Invite</Button>
              </div>
            ) : (
              <OrgBlock />
            )}
          </SelectionContainer>
        </Container>
        <BottomText>
          Looking for a different workspace? You can 
          <RedirectLink onClick={redirectToRoles}>try another email address</RedirectLink> or ask
          your Workspace Administrator for an invitation. Use a public email domain to sign up as a
          personal user. By clicking "launch" above you agree to the{' '}
          <a href={TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">
            Theorem Terms of Service
          </a>{' '}
          &amp;{' '}
          <a href={SUBSCRIPTION_AGREEMENT} target="_blank" rel="noopener noreferrer">
            Subscription Agreement
          </a>
          .
        </BottomText>
      </Root>
    </>
  )
}

const BottomText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  max-width: 600px;
  padding-top: 20px;
  color: #595969;
`

const RedirectLink = styled.a`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f7f7f7;
  align-items: center;
  justify-content: center;
`

const SelectionContainer = styled.div`
  align-items: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
`

const Container = styled.div`
  max-width: 600px;
  width: 100%;
`

const Title = styled.div`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 30px;
`

const Subtitle = styled.div`
  font-size: 22px;
  margin-bottom: 24px;
  color: #555;
`

export default FoundOrganization
