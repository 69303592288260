import React from 'react'
import { FlexLoading } from 'components/PulsatingLoader'
import { FormDescription } from 'components/theme/form'
import _ from 'lodash'
import { useProductSettings } from '../useProductSettings'
import LegalForm from 'components/forms/product/LegalForm'
import VendorUpgradeModal from 'components/modals/VendorUpgradeModal'

function Legal() {
  const { product, loading, onComplete, showModal, toggleModal } = useProductSettings()

  const initialValues = {
    privacyPolicyUrl: _.get(product, 'details.privacyPolicyUrl', ''),
    publicTermsUrl: _.get(product, 'details.publicTermsUrl', ''),
    eulaPdf: _.get(product, 'details.eulaPdf', null),
    eulaUrl: product?.details.eulaUrl || '',
    pickedTerms: product?.details.pickedTerms || '',
  }

  if (!product || loading) return <FlexLoading />

  return (
    <>
      <FormDescription>
        Select controlling terms and conditions for{' '}
        <a
          href={`https://ecosystem.theoremlegal.com/product/${product?.slug}/terms`}
          target="_blank"
          rel="noopener noreferrer"
        >
          your terms page
        </a>
      </FormDescription>
      <LegalForm initialValues={initialValues} onComplete={onComplete} product={product} />
      <VendorUpgradeModal showModal={showModal} toggleModal={toggleModal} />
    </>
  )
}

export default Legal
