import noListingSvg from 'assets/listing-image.svg'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
import TopCtaSection from './TopCtaSection'

const ListProducts = () => {
  const marketplaceUrl = useMarketplaceUrl(true)

  return (
    <TopCtaSection
      title="List your products / services"
      text="Create your product library and connect listings across our marketplace."
      image={noListingSvg}
      outLink={{
        href: 'https://help.theoremlegal.com/kb/en/article/law-firm-products',
        text: 'Watch Vendor Tutorial',
      }}
      routerLink={{
        to: `${marketplaceUrl}returnTo=/admin/listings`,
        text: 'Create Listing',
        outLink: true,
      }}
    />
  )
}

export default ListProducts
