import { useQuery } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlGetLocationsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useGetLocations = () => {
  const request = useGql()
  const { organization } = useOrg()

  const { data, ...other } = useQuery(
    ['location'],
    async () => {
      const response = await request<GqlGetLocationsQuery>(GET_LOCATIONS, {
        organizationId: organization?.id,
      })

      return response?.locations
    },
    {
      staleTime: 60 * 60 * 1000 * 15,
      refetchOnWindowFocus: false,
    }
  )

  return {
    locations: data,
    ...other,
  }
}

export const GET_LOCATIONS = gql`
  query GetLocations($organizationId: UUID!) {
    locations(condition: { organizationId: $organizationId }) {
      city
      country
      id
      state
    }
  }
`
