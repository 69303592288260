import { useEffect } from 'react'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useAuth0 } from '@auth0/auth0-react'

function Signup() {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    ;(async () => {
      await loginWithRedirect({
        appState: { targetUrl: '/' },
        screen_hint: 'signup',
      })
    })()
  }, [loginWithRedirect])

  return <FullPageLoading />
}

export default Signup
