import { useEffect } from 'react'
import { useTrackingChartContext } from 'context/trackingChartContext'
import { useChartJs } from 'hooks/useChartJs'
import ChartCard from 'components/charts/ChartCard'
import { useLazyApi } from 'hooks/useApi'
import _ from 'lodash'

const options = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

function ProductCtaChart() {
  const {
    startDate,
    endDate,
    currentLoadingQueue,
    setCurrentLoadingQueue,
    productToAnalyze: product,
  } = useTrackingChartContext()
  const { ctx, setChartData } = useChartJs({ type: 'horizontalBar', options })
  const [getProductCta] = useLazyApi('tracking/amplitude/product-cta')

  useEffect(() => {
    const init = async () => {
      const result = await getProductCta({ startDate, endDate, id: product })

      const series = _.get(result, 'series', [])
      const seriesLabels = _.get(result, 'seriesLabels', [])

      const totalCount = series.map((val: number[]) => val.reduce((a, b) => a + b, 0))
      const datasets = [
        {
          label: 'Pricing Plan Clicks',
          data: totalCount,
          borderWidth: 1,
          backgroundColor: ['#5062D5', '#9446D3', '#3ACFB2', '#122AC0', '#6C09BD', '#00B793'],
        },
      ]
      const labels = seriesLabels.map((val: any[]) => val[1])
      setChartData({ datasets, labels })
      setCurrentLoadingQueue((prevVal: number) => prevVal + 1)
    }

    if (currentLoadingQueue === 7) {
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentLoadingQueue])

  return <ChartCard title="Pricing Hits" ctx={ctx} />
}

export default ProductCtaChart
