import { useQuery } from '@tanstack/react-query'
import { GqlGetTestDriveListQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { isUsingStagingServer } from 'services/helper'

export const useTestDriveList = () => {
  const request = useGql()

  const { data, ...other } = useQuery(['testDrive', 'list'], async () => {
    const response = await request<GqlGetTestDriveListQuery>(GET_TEST_DRIVE, {
      isTestMode: isUsingStagingServer,
    })

    return response.testDrives
  })

  return {
    testDrives: data,
    ...other,
  }
}

const GET_TEST_DRIVE = gql`
  query getTestDriveList($isTestMode: Boolean) {
    testDrives(orderBy: ORDER_ASC, filter: { testMode: { lessThanOrEqualTo: $isTestMode } }) {
      createdAt
      id
      order
      productId
      termId
      updatedAt
      details
      testMode
      product {
        id
        name
        slug
        details
        excerpt
      }
      term {
        id
        name
        slug
        taxonomyId
      }
      termToTestDrives {
        term {
          id
          name
          slug
          taxonomyId
          taxonomy {
            id
            name
            slug
          }
        }
      }
    }
  }
`
