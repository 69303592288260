import React from 'react'
import styled from '@emotion/styled'
import { Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import { FormField } from 'components/theme/form'
import Label from 'components/theme/form/Label'
import { Button } from 'components/theme'
import { CertProps } from './DiversityCert.d'


const FormOne: React.FC<CertProps> = ({ updateCert, goToNextStep }) => (
  <One>
    <h4>Tell us about the diversity of your business.</h4>
    <Description>
      Selecting the diversity group(s) of you business lets interested Enterprise clients easily
      find you.
    </Description>
    <Formik
      initialValues={{ businessName: '', businessIsDescribed: '', descriptor: '' }}
      validationSchema={yup.object().shape({
        businessName: yup.string().max(100).required('Required'),
        businessIsDescribed: yup.string().required('Please make a selection'),
        descriptor: yup.string().when('businessIsDescribed', {
          is: 'true',
          then: yup.string().required(), 
          otherwise: yup.string().optional()
        }),
      })}
      onSubmit={({ businessName, businessIsDescribed, descriptor }) => {
        updateCert({ businessName, businessIsDescribed, descriptor })
        goToNextStep()
      }}
    >
      {({ errors, touched, setFieldValue, values }) => {
        const handleCheckBoxFunction = () => {
          if (values.businessIsDescribed !== 'true') setFieldValue('businessIsDescribed', 'true')
        }
        return (
          <Form>
            <FormField>
              <LabelLine>
                <Label 
                  required 
                //   helpContent="Make sure this matches the name on your tax returns"
                >
                  Business Name
                </Label>
                <ErrorText>
                  {errors.businessName && touched.businessName && errors.businessName}
                </ErrorText>
              </LabelLine>
              <TextField type="text" label="Business Name" name="businessName" placeholder="As it appears on your tax forms" />
            </FormField>
            <RadioGroup role="group" aria-labelledby="my-radio-group">
              <SelectOption>
                <Field
                  type="radio"
                  name="businessIsDescribed"
                  value={'true'}
                />
                <RadioLabel>My business is:</RadioLabel>
              </SelectOption>
              <BusinessDescriptors role="group" aria-labelledby="my-check-group">
                <SelectOption>
                  <Field
                    type="radio"
                    name="descriptor"
                    value="Disability owned"
                    disabled={values.businessIsDescribed === 'undisclosed'}
                    onClick={handleCheckBoxFunction}
                  />
                  <SelectLabel>Disability owned</SelectLabel>
                </SelectOption>
                <SelectOption>
                  <Field
                    type="radio"
                    name="descriptor"
                    value="LGBTQ+ owned"
                    disabled={values.businessIsDescribed === 'undisclosed'}
                    onClick={handleCheckBoxFunction}
                  />
                  <SelectLabel>LGBTQ+ owned</SelectLabel>
                </SelectOption>
                <SelectOption>
                  <Field
                    type="radio"
                    name="descriptor"
                    value="Minority owned"
                    disabled={values.businessIsDescribed === 'undisclosed'}
                    onClick={handleCheckBoxFunction}
                  />
                  <SelectLabel>Minority owned</SelectLabel>
                </SelectOption>
                <SelectOption>
                  <Field
                    type="radio"
                    name="descriptor"
                    value="Veteran owned"
                    disabled={values.businessIsDescribed === 'undisclosed'}
                    onClick={handleCheckBoxFunction}
                  />
                  <SelectLabel>Veteran owned</SelectLabel>
                </SelectOption>
                <SelectOption>
                  <Field
                    type="radio"
                    name="descriptor"
                    value="Women owned"
                    disabled={values.businessIsDescribed === 'undisclosed'}
                    onClick={handleCheckBoxFunction}
                  />
                  <SelectLabel>Women owned</SelectLabel>
                </SelectOption>
                <ErrorText>
                  {errors.descriptor && touched.descriptor && errors.descriptor}
                </ErrorText>
              </BusinessDescriptors>
              <HR />
              <SelectOption>
                <Field
                  type="radio"
                  name="businessIsDescribed"
                  value={'undisclosed'}
                  onClick={() => {
                    setFieldValue('descriptor', 'undisclosed')
                  }}
                />
                <LabelLine>
                    <RadioLabel>I don't want to disclose this information</RadioLabel>
                    <ErrorText>
                    {errors.businessIsDescribed &&
                        touched.businessIsDescribed &&
                        errors.businessIsDescribed}
                    </ErrorText>
                </LabelLine>
              </SelectOption>
            </RadioGroup>
            <SubmitWrapper>
              <SubmitButton type="submit">Next</SubmitButton>
            </SubmitWrapper>
          </Form>
        )
      }}
    </Formik>
  </One>
)

const One = styled.div`
  width: min-content;
  margin: 32px auto;
`

const TextField = styled(Field)`
  width: 100%;
  padding: 9.5px 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease, border-color 0.1s ease;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  flex: 1 0 auto;

  &::-webkit-input-placeholder {
    color: #616161;
  }

  &:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

const LabelLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const Description = styled.p`
  white-space: nowrap;
`

const SubmitButton = styled(Button)`
  background: #a333c8;
  border-color: #a333c8;
  color: white;
`

const ErrorText = styled.div`
  color: #f44336;
  font-weight: 400;
`

const HR = styled.hr`
  width: 100%;
`

const BusinessDescriptors = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  > * {
    margin-bottom: 8px;
  }
`

const SelectOption = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`

const SelectLabel = styled.div`
  font-weight: 500;
  margin-left: 8px;
`
const RadioLabel = styled.div`
  margin-left: 8px;
`

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export default FormOne
