import styled from '@emotion/styled'

const ProductSelector = () => {
  return (
    <Root>
      <div>ProductSelector</div>
    </Root>
  )
}

const Root = styled.div``

export default ProductSelector
