import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useViewer } from 'context/viewerContext'
import { logEvent } from '@theoremlts/theorem-design'

export const useLogout = () => {
  const { logout, getIdTokenClaims } = useAuth0()
  const { viewer } = useViewer()
  const [auth0OrgId, setAuth0OrgId] = useState<string>()

  useEffect(() => {
    ;(async () => {
      const claims = await getIdTokenClaims()
      setAuth0OrgId(claims?.org_id)
    })()
  }, [])

  const theoremLogout = () => {
    logEvent('log_out')

    let returnTo = `${window.location.origin}`

    if (viewer?.email) returnTo = `${returnTo}?email=${encodeURIComponent(viewer.email)}`
    if (auth0OrgId) {
      returnTo = `${returnTo}&organization=${auth0OrgId}`
    }

    logout({ returnTo })
  }

  return theoremLogout
}
