import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  id: number
  details?: any
  productId?: number
  to?: string
  when?: string
  terms: number[]
}
export const useUpdateUserStory = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { id, details, productId, to, when, terms } = variables
      await request(UPDATE_USER_STORY, {
        id,
        details,
        productId,
        to,
        when,
        terms,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['userStory']),
    }
  )
}

const UPDATE_USER_STORY = gql`
  mutation UpdateUserStory(
    $id: Int!
    $details: JSON
    $productId: Int
    $to: String
    $when: String
    $terms: [Int]!
  ) {
    updateUserStory(
      input: { patch: { details: $details, productId: $productId, to: $to, when: $when }, id: $id }
    ) {
      clientMutationId
    }
    replaceTermToUserStory(input: { ids: $terms, story: $id }) {
      clientMutationId
    }
  }
`
