import { Dropdown } from 'semantic-ui-react'
import { useField } from 'formik'
import Label from 'components/theme/form/Label'
import styled from '@emotion/styled'

function PriceRangeDropdown({ ...props }: any) {
  const [, meta, helpers] = useField(props)

  return (
    <Root>
      <Label>Unit</Label>
      <Dropdown
        selection
        fluid
        name="priceRange"
        onChange={(_1, { value }) => helpers.setValue(value)}
        value={meta.value}
        options={customPeriodOptions}
      />
    </Root>
  )
}

const Root = styled.div`
  min-width: 140px;
`

const customPeriodOptions = [
  {
    key: 'months',
    text: 'months',
    value: 'months',
  },
  {
    key: 'years',
    text: 'years',
    value: 'years',
  },
]

export default PriceRangeDropdown
