import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { Input } from 'semantic-ui-react'
import styled from '@emotion/styled'
import Label from './Label'

interface Props {
  icon?: string
  placeholder?: string
  isSubmitting?: boolean
  label?: string
  dropdown?: any
  required?: boolean
  disabled?: boolean
  helpContent?: any
  type?: string
  name: string
  [key: string]: any
}
function TextInput({
  icon = '',
  placeholder = '',
  isSubmitting = false,
  label = '',
  dropdown = null,
  required = false,
  disabled = false,
  helpContent = '',
  type = 'text',
  ...props
}: Props) {
  const [field, meta, helpers] = useField(props)
  const [value, setValue] = useState(meta.value)
  const errorText = !isSubmitting && meta.error && meta.touched ? meta.error : ''

  useEffect(() => {
    if (disabled) helpers.setValue('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  const onBlur = (e: any) => {
    helpers.setValue(e.target.value.trim())
  }

  return (
    <>
      <Root>
        {label && (
          <Label required={required} helpContent={helpContent}>
            {label}
          </Label>
        )}
        {icon ? (
          <Input
            placeholder={placeholder}
            error={!!errorText}
            icon={icon}
            iconPosition="left"
            fluid
            {...field}
          />
        ) : (
          <>
            {dropdown ? (
              <Input
                action={dropdown}
                actionPosition="left"
                placeholder={disabled ? '-' : placeholder}
                error={!!errorText}
                disabled={disabled}
                fluid
                {...field}
              />
            ) : (
              <MyInput
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onBlur={onBlur}
                type={type}
              />
            )}
          </>
        )}
      </Root>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </>
  )
}

const MyInput = styled.input`
  width: 100%;
  padding: 9.5px 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease, border-color 0.1s ease;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  flex: 1 0 auto;

  &::-webkit-input-placeholder {
    color: #bbb;
  }

  &:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

const Root = styled.div`
  width: 100%;
  .ui.disabled.input,
  .ui.input:not(.disabled) input[disabled] {
    opacity: 1;
  }
`

const ErrorText = styled.div`
  color: #f44336;
  font-weight: 400;
  margin-top: 6px;
`

export default TextInput
