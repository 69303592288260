import { useState } from 'react'
import styled from '@emotion/styled'
import { useGetAllCompanies } from 'data/hooks'
import { getLocalTime } from 'services/helper'
import Pagination from 'components/DiscoverOrganization/Pagination'
import { Button } from 'components/theme'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
import Select from 'react-dropdown-select'
import { Link } from 'react-router-dom'

const ITEM_NUMBER = 50

const FindVendorStackshare = () => {
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [companyType, setCompanyType] = useState<string | undefined>(undefined)
  const { companies, totalCount } = useGetAllCompanies({
    offset: page * ITEM_NUMBER,
    first: ITEM_NUMBER,
    search,
    type: companyType,
  })
  const marketplaceUrl = useMarketplaceUrl(true)

  const totalPages = typeof totalCount === 'number' ? Math.ceil(totalCount / ITEM_NUMBER) : 0

  const changePage = (page: number) => {
    setPage(page - 1)
  }

  return (
    <Root>
      <Top>
        <TotalCompanies>{totalCount} companies</TotalCompanies>
      </Top>
      <Filters>
        <SearchBar
          value={search}
          placeholder="Search by name"
          onChange={(e) => {
            setSearch(e.target.value)
            setPage(0)
          }}
        />
        <SelectWrapper>
          <Select
            options={[
              { label: 'All', value: '' },
              { label: 'Law Firm', value: 'LAWFIRM' },
              { label: 'Vendor', value: 'VENDOR' },
              { label: 'Company', value: 'COMPANY' },
              { label: 'Association', value: 'ASSOCIATION' },
            ]}
            placeholder="Select Company Type"
            onChange={(values) => {
              const value = values[0].value

              if (value) setCompanyType(value)
              else setCompanyType(undefined)
            }}
          />
        </SelectWrapper>
      </Filters>
      <Table>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {companies?.map(({ id, slug, name, details }) => (
            <Row key={`company-${id}`}>
              <td>
                <a
                  href={`${marketplaceUrl}returnTo=/stackshare/vendor/${slug}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Name>
                    <Logo src={details.logo} />
                    {name}
                  </Name>
                </a>
              </td>
              <td>
                <Link to={`../edit/${id}`}>Edit</Link>
              </td>
            </Row>
          ))}
        </tbody>
      </Table>
      <Pagination startPage={page} pageCount={totalPages} onChange={changePage} />
    </Root>
  )
}

const Root = styled.div``

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

const SearchBar = styled.input`
  width: 100%;
  max-width: 300px;
  marign-right: 16px;
`

const Filters = styled.div`
  margin-bottom: 20px;
  display: flex;
`

const SelectWrapper = styled.div`
  padding-left: 20px;
  width: 100%;
  max-width: 300px;
`
const CreateOrgButton = styled(Button)``

const TotalCompanies = styled.div`
  font-weight: 600;
`

const Table = styled.table`
  width: 100%;
  td {
    vertical-align: middle;
    padding: 4px;
  }
`

const Name = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0.02rem;
`

const Row = styled.tr`
  &:nth-of-type(even) {
    background-color: white;
  }
`

const Logo = styled.img`
  max-height: 30px;
  display: block;
  margin-right: 16px;
`

export default FindVendorStackshare
