import React from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { ViewType, Association } from './useProfileAssociation'
import { useSwitchOrg } from 'data/viewer/useSwitchOrg'
import { useAddUserToOrg } from 'data/hooks'

const AssocBox: React.FC<Association> = ({ id, name, viewType }) => {
  const addUserToOrg = useAddUserToOrg()
  const switchOrg = useSwitchOrg()
  const navigate = useNavigate()

  const requestAssocToOrg = async () => {
    await addUserToOrg.mutateAsync({
      organizationId: id,
    })
  }

  const switchToAssoc = () => {
    switchOrg.mutate(id, {
      onSuccess: () => {
        navigate(`/association/${id}`)
        history.go(0)
      },
    })
  }

  return (
    <>
      {viewType === ViewType.INACTIVE && (
        <Box onClick={requestAssocToOrg}>
          <TopText>Join</TopText>
          <Name>{name}</Name>
        </Box>
      )}
      {viewType === ViewType.MEMBER && (
        <Box onClick={switchToAssoc}>
          <TopText>Switch to</TopText>
          <Name>{name}</Name>
        </Box>
      )}
    </>
  )
}

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  height: 200px;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background 250ms;
  margin-right: 20px;
  margin-bottom: 20px;

  &:hover {
    background: #efefef;
  }
`

const TopText = styled.div`
  font-size: 24px;
  margin-bottom: 12px;
`

const Name = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin-left: 8px;
`

export default AssocBox
