import React from 'react'
import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'

interface Props {
  type: string
  title: string
  content: string
  image: string
  flip?: boolean
}

const FeatureBlock: React.FC<Props> = ({ type, title, content, image, flip = false }) => {
  return (
    <Root>
      <Wrapper>
        <InnerWrapper flip={flip}>
          <ContentWrapper flip={flip}>
            <Type>{type}</Type>
            <Title>{title}</Title>
            <Content>{content}</Content>
          </ContentWrapper>
          <ImageWrapper image={image} />
        </InnerWrapper>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  padding: 28px 20px;

  ${breakpoint.mobile} {
    padding: 16px 20px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  box-shadow: 0px 20px 44px rgb(23 23 23 / 15%);
  border-radius: 10px;
`

const InnerWrapper = styled.div<{ flip: boolean }>`
  display: flex;
  flex-direction: ${({ flip }) => (flip ? 'row-reverse' : 'row')};
  align-items: stretch;
  border-radius: 10px;
  overflow: hidden;
  min-height: 500px;

  ${breakpoint.mobile} {
    min-height: 0;
  }
`

const ContentWrapper = styled.div<{ flip: boolean }>`
  padding: 60px 44px 32px 40px;
  width: 50%;
  background: white;
  border-${({ flip }) => (flip ? 'left' : 'right')}: 1px solid #efefef;

  ${breakpoint.mobile} {
    padding: 40px 32px;
    width: 100%;
  }
`

const Type = styled.div`
  letter-spacing: 1px;
  font-weight: 500;
  color: grey;
  text-transform: uppercase;
`

const Title = styled.h3`
  font-size: 28px;
`

const Content = styled.div`
  font-size: 18px;
  line-height: 1.65;
  color: #555;
`

const ImageWrapper = styled.div<{ image: string }>`
  background-image: url('${({ image }) => image}');
  background-size: cover;
  background-position: center;
  width: 50%;

  ${breakpoint.mobile} {
    display: none;
  }
`

export default FeatureBlock
