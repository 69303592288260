import { useEffect } from 'react'
import Button from 'components/theme/Button'
import { useLazyApi } from 'hooks/useApi'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
// import { useAmplitudeSignUp } from 'context/amplitudeSignupContext'
import { useUserSetup } from 'context/userSetupContext'
import { MemberStatus, OrganizationType, UserStatus } from 'generated/graphql'
import { useViewer } from 'context/viewerContext'
import { useAppMetadata } from 'data/viewer/useAppMetadata'
import {
  useCreateNoviOrg,
  useMatchUserToOrg,
  useNoviOrganization,
  useCreateAssocToOrg,
  useCreateUserToOrg,
} from 'data/hooks'

const OrgBlock = () => {
  const navigate = useNavigate()
  const [addTeammate] = useLazyApi('stripe/organization/add-teammate')
  const { amplitude } = useUserSetup()
  const { viewer, refetch } = useViewer()
  const { data: appMetadata } = useAppMetadata()
  const createAssocToOrg = useCreateAssocToOrg()
  const createNoviOrg = useCreateNoviOrg()
  const selectedOrganization = appMetadata.matchedOrg
  const { noviOrganization } = useNoviOrganization(selectedOrganization?.id)
  const matchUserToOrganization = useMatchUserToOrg()
  const createUsersToOrg = useCreateUserToOrg()

  useEffect(() => {
    refetch()
  }, [])

  const onSubmit = async () => {
    const userRole = appMetadata.matchedOrgRole

    await matchUserToOrganization.mutateAsync({
      userId: viewer.id,
      organizationId: selectedOrganization.id,
      role: userRole,
      userStatus: UserStatus.CreateUser,
    })

    amplitude?.logEventWithSavedProps('set_location', { action: 'update_information' })

    if (appMetadata.noviOrg && !noviOrganization) {
      await createNoviOrg.mutateAsync({
        organizationId: selectedOrganization.id,
        noviId: appMetadata.noviOrg.id,
      })
    }

    if (selectedOrganization.type === OrganizationType.Vendor) {
      if (appMetadata.assocOrg) {
        await createAssocToOrg.mutateAsync({
          associationId: appMetadata.assocOrg.id,
          organizationId: selectedOrganization.id,
          status: MemberStatus.Active,
        })
      }
      navigate('/setup/vendor-user')
    } else {
      if (appMetadata.assocOrg) {
        await createUsersToOrg.mutateAsync({ organizationId: appMetadata.assocOrg.id })
      }
      // add seat if second user
      if (userRole !== 'OWNER') {
        await addTeammate()
      }

      if (selectedOrganization.type === OrganizationType.Lawfirm) {
        // create user to organization here
        navigate('/setup/lawfirm-user')
      } else {
        navigate('/setup/company-user')
      }
    }
  }

  return (
    <Root onClick={onSubmit}>
      <LogoBlock logo={selectedOrganization.details.logo} />
      <ContentWrapper>
        <Name>{selectedOrganization.name}</Name>
        <BottomContent>
          <AssociationType>{selectedOrganization.type.toLowerCase()}</AssociationType>
          {/*

          <MemberCount>
            {memberCount} member{memberCount > 1 && 's'}
          </MemberCount>
          */}
        </BottomContent>
      </ContentWrapper>
      <Button>Launch</Button>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
  align-items: center;
  background: white;
  transition: background 200ms;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);

  &:hover {
    background: #fcfcfc;
  }

  &:last-of-type {
    border-bottom: 0;
  }
`

const ContentWrapper = styled.div`
  margin-left: 24px;
  margin-right: auto;
`

const Name = styled.div`
  color: #1f2324;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.02rem;
  margin-bottom: 8px;
`

const BottomContent = styled.div``

const AssociationType = styled.span`
  text-transform: capitalize;
  color: #3b4143;
  font-size: 16px;
`

const LogoBlock = styled.div<{ logo: string }>`
  border-radius: 4px;
  background-image: url(${({ logo }) => logo});
  background-color: ${({ logo }) => (logo ? 'white' : '#e1e1e1')};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 52px;
  height: 52px;
`

export default OrgBlock
