import { Route, Routes } from 'react-router-dom'
import AccAuthSelect from './AccAuthSelect'
import AccSignupForm from './AccSignupForm'
import EmailSent from './EmailSent'

const AccLogin = () => {
  return (
    <Routes>
      <Route path="/" element={<AccAuthSelect />} />
      <Route path="signup" element={<AccSignupForm />} />
      <Route path="email-sent" element={<EmailSent />} />
    </Routes>
  )
}

export default AccLogin
