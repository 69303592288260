import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { useRedirect } from 'hooks/useRedirect'
import BaseModal from './BaseModal'

interface Props {
  toggleModal: () => void
}
const ProductTypeModal = ({ toggleModal }: Props) => {
  const redirect = useRedirect()

  const onSoftwareClick = () => {
    redirect('product/new', { productType: 'SOFTWARE' })
    // create product and default it to software
  }

  const onServiceClick = () => {
    redirect('product/new', { productType: 'SERVICE' })
    // create product and default it to software
  }

  // const name
  return (
    <BaseModal toggleModal={toggleModal}>
      <Root>
        <MainTitle>Product Type</MainTitle>
        <Cards>
          <Column>
            <Card onClick={onSoftwareClick}>
              <CardIcon name="desktop" />
              <Title>Software</Title>
            </Card>
          </Column>
          <Column>
            <Card onClick={onServiceClick}>
              <CardIcon name="user-tie" />
              <Title>Service</Title>
            </Card>
          </Column>
        </Cards>
      </Root>
    </BaseModal>
  )
}

const MainTitle = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
`

const Root = styled.div`
  padding: 40px 20px;
  width: 400px;
`

const Column = styled.div`
  width: 50%;
  padding: 0 20px;
`

const Card = styled.div`
  border: 2px solid #0000;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px;
  text-align: center;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    border: 2px solid rgb(76, 208, 209);
  }
`

const CardIcon = styled(Icon)`
  font-size: 32px;
  color: #444;
`

const Cards = styled.div`
  display: flex;
`

const Title = styled.h3`
  margin-top: 12px;
`

export default ProductTypeModal
