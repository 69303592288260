import styled from '@emotion/styled'
import { CollapsingTabs } from '@theoremlts/theorem-design'
import { BoxWrapper } from 'components/theme'
import { Route, Routes } from 'react-router'
import NetworkAccess from './NetworkAccess'
import NetworkOrgs from './NetworkOrgs'

type ParsedMenuItem = {
  text: string
  url: string
  exact?: boolean
}

export const menuItems = [
  {
    text: 'Network Orgs',
    url: '',
    component: <NetworkOrgs />,
    exact: true,
  },
  {
    text: 'Network Access',
    url: 'network-access',
    component: <NetworkAccess />,
    exact: true,
  },
]
const AssocAdmin = () => {
  // const { url: matchedUrl, path } = useMatch()

  const fixedMenuItems = menuItems.map(({ url, ...menuItem }) => ({
    ...menuItem,
    // url: url ? `${matchedUrl}/${url}` : matchedUrl,
    url,
  }))
  return (
    <Root>
      <BoxWrapper>
        <CollapsingTabs menuItems={fixedMenuItems as ParsedMenuItem[]} />
        <Routes>
          {menuItems.map((item, index) => (
            <Route key={index} path={item.url} element={item.component} />
          ))}
        </Routes>
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

export default AssocAdmin
