import { extractDomainFromEmail, isTestEmail } from 'services/helper'
import { useViewer } from 'context/viewerContext'
import { useOrg, useSwitchOrg } from 'data/hooks'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { CREATE_USER_TO_ORGANIZATION } from 'data/usersToOrganization/hooks/useCreateUserToOrg'
import {
  OrganizationType,
  OrganizationSubtype,
  UserRole,
  GqlCreateOrganizationMutation,
} from 'generated/graphql'

interface Variables {
  type: OrganizationType
  subtype: OrganizationSubtype
  name?: string | null
  details?: any
}
export const useCreateOrg = () => {
  const request = useGql()
  const switchOrg = useSwitchOrg()
  const queryClient = useQueryClient()
  const { viewer } = useViewer()
  const { organization } = useOrg()

  return useMutation(
    async (variables: Variables) => {
      if (organization) return

      const { type, subtype, name, details } = variables

      const domain = extractDomainFromEmail(viewer.email)
      const testMode = isTestEmail(viewer.email)

      const data = await request<GqlCreateOrganizationMutation>(CREATE_ORGANIZATION, {
        name: name || '',
        type,
        subtype,
        domain,
        details,
        testMode,
      })

      const organizationId = data?.createOrganization?.organization?.id || ''

      const jobTitle = viewer.details?.setupJobTitleId
      await request(CREATE_USER_TO_ORGANIZATION, {
        userId: viewer.id,
        organizationId,
        role: UserRole.Owner,
        jobTitle,
      })
      await switchOrg.mutateAsync(organizationId)

      return organizationId
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization(
    $name: String
    $type: OrganizationType!
    $subtype: OrganizationSubtype
    $domain: String
    $details: JSON
    $testMode: Boolean
  ) {
    __typename
    createOrganization(
      input: {
        organization: {
          details: $details
          domain: $domain
          name: $name
          type: $type
          subtype: $subtype
          testMode: $testMode
        }
      }
    ) {
      organization {
        id
      }
    }
  }
`
