import React, { useReducer, useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { useUpdateTechstack, OrgTechstack, Access, Stage } from 'hooks/lawfirm/useUpdateTechstack'
import CsvToTeckstack from './CsvToTechstack'
import type { ParseError } from './CsvToTechstack'

import SelectTechStack from 'components/forms/SelectTechStack/SelectTechStack'
import { LineItemTable } from '@theoremlts/theorem-design'
import StackRow from './StackRow'
import Pagination from 'components/DiscoverOrganization/Pagination'
import { useViewer } from 'context/viewerContext'
import { useAllProducts, useProductById } from 'data/hooks'

export type Update = {
  action?: 'ADD' | 'UPDATE' | 'DELETE'
  csvUpdate?: boolean
  product: {
    id: number
    name: string
    details: {
      [key: string]: any
      squareLogo: string
      logoUrl: string
    }
  }
  techstack: {
    id?: number
    access: Access
    stage: Stage
    ownedBy?: number
    seats?: number
    metadata?: {
      [key: string]: any
      contractValue?: number
      renewalDate?: string
      //   authorizedHide?: 'AUTHORIZED' | 'HIDE'
    }
  }
}

export type GqlUpdate = {
  productId: number
  techstackId?: number
  contractValue?: number
  renewalDate?: string
  access?: Access
  stage?: Stage
  ownedBy?: number
  seats?: number
  action: 'ADD' | 'UPDATE' | 'DELETE' | undefined
  techstack?: {
    [key: string]: any
  }
}

type State = { [key: string]: Update | undefined }

const clearCsv = (state: State) => {
  const updates = Object.values(state)
  const updatesWithoutCsv = updates.filter((update) => !!update && !update.csvUpdate)
  return updatesWithoutCsv.reduce((newState, update) => {
    return { ...newState, [update?.product.id || 'error-on-clear-csv']: update }
  }, {} as State)
}

const reducer = (state: State, update: Update | 'CLEAR CSV' | 'REBOOT'): State => {
  if (update === 'CLEAR CSV') return clearCsv(state)
  if (update === 'REBOOT') return {}
  switch (update.action) {
    case 'ADD':
    case 'UPDATE':
      if (!update.product?.id) return state
      return { ...state, [`${update.product.id}`]: update }
    case 'DELETE':
      const existingChange = state[`${update.product.id}`]
      if (existingChange?.action === 'ADD') return { ...state, [`${update.product.id}`]: undefined }
      return { ...state, [`${update.product.id}`]: update }
    // I don't remember why only 'ADD' gets set undefined
    default:
      return state
  }
}

interface Props {
  onSubmit: (values: GqlUpdate[]) => Promise<boolean>
  isAdvancedView?: boolean
}

const CsvTechstack = ({ onSubmit, isAdvancedView = false }: Props) => {
  const { products, isLoading: productsLoading } = useAllProducts()
  const [productId, setProductId] = useState<number>()
  const { product, isLoading: productLoading } = useProductById(productId)

  const { viewer } = useViewer()

  const [csvLoading, setCsvLoading] = useState<boolean>(false)
  const { fullOrganizationTechstack, loading } = useUpdateTechstack()
  const [updates, makeUpdate] = useReducer(reducer, {} as State)
  const [visibleStacks, setVisibleStacks] = useState<OrgTechstack[]>([])
  const [newStacks, setNewStacks] = useState<Update[]>([])
  const [csvErrors, setCsvErrors] = useState<ParseError[]>([])
  const [showAdvancedView, setShowAdvancedView] = useState(isAdvancedView)

  const toggleAdvancedView = () => {
    setShowAdvancedView((prevVal) => !prevVal)
  }

  const selectProductRange = (offset: number) => {
    const range = fullOrganizationTechstack.slice((offset - 1) * 10, (offset - 1) * 10 + 10)
    setVisibleStacks(range)
  }

  const parseAndSubmit = async () => {
    const data = Object.values(updates).filter((update) => !!update) as Update[]
    const parsedUpdates = data.map((item) => ({
      action: item.action,
      productId: item.product.id,
      techstackId: item.techstack.id,
      contractValue: item.techstack.metadata?.contractValue,
      renewalDate: item.techstack.metadata?.renewalDate,
      access: item.techstack.access,
      stage: item.techstack.stage,
      ownedBy: item.techstack.ownedBy,
      seats: item.techstack.seats,
      addedBy: viewer?.id,
    }))
    const success = await onSubmit(parsedUpdates)
    if (success) {
      newStacks.length > 0 && setNewStacks([])
      csvErrors.length > 0 && setCsvErrors([])
      makeUpdate('REBOOT')
      toast.success('Changes to the techstack have been saved!')
    } else {
      toast.error('Something went wrong, please try again.')
    }
  }

  useEffect(() => {
    if (loading || !fullOrganizationTechstack) return
    selectProductRange(1)
  }, [loading, JSON.stringify(fullOrganizationTechstack)])

  useEffect(() => {
    setCsvLoading(false)
  }, [JSON.stringify(updates), JSON.stringify(csvErrors)])

  useEffect(() => {
    const products = Object.keys(updates)
    const newProducts = products.filter((product) => updates[product]?.action === 'ADD')
    const newProductsData = newProducts.map((product) => updates[product])
    // TODO: look here this is where the searchbar finds the  product
    setNewStacks(newProductsData as unknown as Update[])
  }, [JSON.stringify(updates)])

  useEffect(() => {
    if (productLoading || !product) return
    makeUpdate({
      action: 'ADD',
      product,
      techstack: { access: 'AUTHORIZED', stage: 'ACTIVE' },
    })
  }, [productLoading, JSON.stringify(product)])

  const selectProduct = async (id: number) => {
    if (fullOrganizationTechstack.some(({ product }) => product.id === id)) {
      return toast.warn('This product is already in your techstack')
    }
    setProductId(id)
  }

  const onFileAction = useCallback(
    (file: File | null) => {
      const CsvUpdate = (val: any) => {
        if (val.errorType) return setCsvErrors((prev) => [...prev, val])
        const action =
          fullOrganizationTechstack.some(({ product }) => product.id === val.product.id) ||
          updates[val.product.id]
            ? 'UPDATE'
            : 'ADD'
        makeUpdate({
          action,
          csvUpdate: true,
          product: val.product,
          techstack: val.techstack,
        })
      }
      if (!products || productsLoading) return
      file && setCsvLoading(true)
      if (products) CsvToTeckstack(file, products, CsvUpdate)
    },
    [products]
  )

  const makeLineItems = (stacks: OrgTechstack[] | Update[] = visibleStacks) => {
    return stacks.map(({ product, techstack, addedBy }: any) => (
      <StackRow
        updatedData={updates[product.id]}
        update={makeUpdate}
        product={product}
        addedBy={addedBy}
        techstack={techstack}
        isAdvancedView={showAdvancedView}
        key={`stack-${product.id}`}
      />
    ))
  }

  const headerItems = showAdvancedView
    ? [
        'Product',
        'Contract Value',
        'Renewal Date',
        'Number of Seats',
        'Stage',
        'Permissions',
        'Owned By',
        'Added By',
        '',
      ]
    : ['Product', 'Added By', '']

  // if (csvLoading) return <>loading</>

  return (
    <Root>
      <SearchBarWrapper>
        <SelectTechStack
          initialSelectedAppIds={[]}
          onSubmit={parseAndSubmit}
          onSelect={selectProduct}
          csvLoading={csvLoading}
          clearCsv={() => {
            newStacks.length > 0 && setNewStacks([])
            csvErrors.length > 0 && setCsvErrors([])
            makeUpdate('CLEAR CSV')
          }}
          showAdvancedView={showAdvancedView}
          fileAction={onFileAction}
          errorList={csvErrors}
          toggleAdvancedView={toggleAdvancedView}
          hideRecommendations
          plainSearch
        />
      </SearchBarWrapper>
      {visibleStacks.length > 0 || newStacks.length > 0 ? (
        <TableWidth showAdvancedView={showAdvancedView}>
          <LineItemTable
            headerItems={headerItems}
            lineItems={
              <>
                {makeLineItems(newStacks)}
                {makeLineItems()}
              </>
            }
          />
        </TableWidth>
      ) : (
        <NoStackMessage>
          Use the Search bar or CSV ACTIONS to add to your firms techstack
        </NoStackMessage>
      )}
      <Pagination
        pageCount={Math.ceil((fullOrganizationTechstack.length - 1) / 10)}
        onChange={selectProductRange}
      />
    </Root>
  )
}

const Root = styled.div`
  margin: 16px auto;
`

const SearchBarWrapper = styled.div`
  margin-bottom: 32px;
`

const TableWidth = styled.div<{ showAdvancedView: boolean }>`
  max-width: ${({ showAdvancedView }) => (showAdvancedView ? 'none' : '800px')};
  margin: 0 auto;
  overflow-x: scroll;
`

const NoStackMessage = styled.div`
  background: #fbfbfb;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02rem;
  max-width: 800px;
  border-radius: 6px;
  padding: 16px 20px;
  margin: 0 auto 16px;
`

export default CsvTechstack
