import { Navigate } from 'react-router-dom'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useViewer } from 'context/viewerContext'
import { UserStatus } from 'generated/graphql'
// import { getUserRole } from 'services/SessionManager'

export default function HomepageRedirectTemp() {
  const { viewer, viewerLoading } = useViewer()

  if (viewerLoading) return <FullPageLoading />

  const { organizations } = viewer
  if (viewer.status === UserStatus.Active && organizations.length > 0) {
    return <Navigate to={`/select-organization`} />
    /* const organizationType = currentOrganizationId
      ? organizations.filter((organization) => organization.id === currentOrganizationId)[0]?.type
      : organizations[0].type

    if (organizationType === OrganizationType.VENDOR) {
      return <Navigate to={`/vendor/${currentOrganizationId}`} />
    }

    if (organizationType === OrganizationType.LAWFIRM) {
      return <Navigate to={`/lawfirm/${currentOrganizationId}`} />
    }

    if (organizationType === OrganizationType.ASSOCIATION) {
      if (user && user.details.hideAssocGettingStarted)
        return <Navigate to={`/association/${currentOrganizationId}`} />
      else return <Navigate to={`/association/${currentOrganizationId}`} />
    } */
  }

  if (viewer.status === UserStatus.Active) {
    return <Navigate to={`/personal`} />
  }
  if (viewer.status === UserStatus.SetOrg) {
    return <Navigate to="/setup/match-organization" />
  }
  if (viewer.status === UserStatus.SetProducts) {
    return <Navigate to="/setup/match-products" />
  }

  return <Navigate to="/setup/role-check" />
}
