import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { MemberStatus } from 'generated/graphql'

const domain =
  process.env.NODE_ENV === 'development'
    ? `https://ecosystem.theorem.local:3300`
    : 'https://ecosystem.theoremlegal.com'

interface Props {
  id: string
  name: string
  slug: string
  status: MemberStatus
  logo: string
}
const AssocBox = ({ name, slug, logo }: Props) => {
  return (
    <Root>
      <Logo logo={logo} />
      <Name>{name}</Name>
      <Options>
        <MarketplaceOption href={`${domain}/api/auth/login?returnTo=/a/${slug}`} target="_blank">
          <OptionIcon name="store" /> View Marketplace
        </MarketplaceOption>
        <MarketplaceOption target="_blank">
          <OptionIcon name="cog" /> Network Management
        </MarketplaceOption>
      </Options>
    </Root>
  )
}

const Root = styled.div`
  border-radius: 6px;
  width: 33.3333%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: 16px;
`

const Name = styled.div`
  font-weight: 500;
  letter-spacing: 0.02rem;
  text-align: center;
  margin-bottom: 8px;
`

const Logo = styled.div<{ logo: string }>`
  background-image: url(${({ logo }) => logo});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 150px;
  width: 100%;
  border: none;
`

const Options = styled.div`
  border-top: 1px solid #ccc;
  padding-top: 8px;
`

const OptionIcon = styled(Icon)`
  width: 14px !important;
  margin-right: 4px;
`

const MarketplaceOption = styled.a`
  display: block;
  color: #9e69d3;
  font-weight: 500;
  margin-bottom: 4px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

export default AssocBox
