import styled from '@emotion/styled'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import CardsContainer from './CardsContainer'
import { BoxWrapper } from 'components/theme'
import { AssocHomeEditorProvider } from './assocHomeEditorContext'
import ViewHomeButton from './ViewHomeButton'

const EditHome = () => {
  return (
    <Root>
      <BoxWrapper>
        <DndProvider backend={HTML5Backend}>
          <AssocHomeEditorProvider>
            <ViewHomeButton />
            <CardsContainer />
          </AssocHomeEditorProvider>
        </DndProvider>
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

export default EditHome
