import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useDeleteTestDrive = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (testDriveId: number) => {
      await request(DELETE_TEST_DRIVE, { testDriveId })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['testDrive']),
    }
  )
}

const DELETE_TEST_DRIVE = gql`
  mutation DeleteTestDrive($testDriveId: Int!) {
    deleteTestDrive(input: { id: $testDriveId }) {
      deletedTestDriveNodeId
    }
  }
`
