import React from 'react'
import styled from '@emotion/styled'
import { TextInput } from 'components/theme/form'
import MoneyInput from 'components/theme/form/MoneyInput'
import PriceTypeDropdown from './PriceTypeDropdown'
import PriceRangeDropdown from './PriceRangeDropdown'
import TieredTable from './TieredTable'
import PriceIdInput from 'components/theme/form/PriceIdInput'
import { useViewer } from 'context/viewerContext'
import MoneyCurrencyFieldFormik from 'components/theme/form/MoneyCurrencyFieldFormik'
import MoneyInput2Formik from 'components/theme/form/MoneyInput2Formik'

const disablePrice = (priceType: any) => priceType === 'free' || priceType === 'callForPricing'

const getLabel = (priceType: any) => {
  switch (priceType) {
    case 'annuallyOnly':
    case 'annually': // annual and monthly
      return 'Annual Price'
    case 'dual':
      return 'Billed Annually'
    case 'monthly': // monthlyOnly
      return 'Monthly Price'
    default:
      return 'Price'
  }
}

const TemplatedPlanInput = ({ resource, hasConnectAccount, setFieldValue }: any) => (
  <>
    {!disablePrice(resource.priceType) && (
      <Row>
        <Column>
          <MoneyCurrencyFieldFormik
            label={getLabel(resource.priceType)}
            inputName="price"
            currencyName="currency"
            disabled={disablePrice(resource.priceType)}
            hasConnectAccount={hasConnectAccount}
          />
        </Column>
        {hasConnectAccount && ( // stripe account status
          <Column>
            <PriceIdInput
              name={`stripeId`}
              onChange={(_e: any, { value }: any) => setFieldValue(`stripeId`, value)}
              disabled={disablePrice(resource.priceType)}
              style={{ zIndex: 101 }}
            />
          </Column>
        )}
      </Row>
    )}

    {(resource.priceType === 'annually' || resource.priceType === 'dual') && (
      <Row>
        <Column>
          <MoneyInput2Formik
            label="Month-to-month"
            name="price2"
            currencyValue={resource.currency}
            disabled={disablePrice(resource.priceType)}
            hasConnectAccount={hasConnectAccount}
          />
        </Column>
        {hasConnectAccount && (
          <Column>
            <PriceIdInput
              name={`stripeId2`}
              onChange={(_e: any, { value }: any) => setFieldValue(`stripeId2`, value)}
              disabled={disablePrice(resource.priceType)}
            />
          </Column>
        )}
      </Row>
    )}
  </>
)

const CustomPlanInput = ({ resource, hasConnectAccount, setFieldValue }: any) => (
  <CustomWrapper>
    <CustomTopLine>
      <SmallColumn>
        <MoneyInput
          hasConnectAccount={hasConnectAccount}
          label={getLabel(resource.priceType)}
          name={`price`}
          disabled={disablePrice(resource.priceType)}
        />
      </SmallColumn>
      <SmallColumn>
        <TextInput label="Range" placeholder="" name={`pricePeriod`} />
      </SmallColumn>
      <SmallColumn>
        <PriceRangeDropdown name={`priceRange`} />
      </SmallColumn>
    </CustomTopLine>
    {hasConnectAccount && (
      <PriceIdInput
        name={`stripeId`}
        onChange={(_e: any, { value }: any) => setFieldValue(`stripeId`, value)}
        disabled={disablePrice(resource.priceType)}
      />
    )}
  </CustomWrapper>
)

function PriceFields({ resource, index, setFieldValue }: any) {
  const { stripeReady } = useViewer()

  return (
    <Root extended={resource.priceType === 'tiered'}>
      <PriceTypeDropdown name={`priceType`} />
      {resource.priceType !== 'custom' && resource.priceType !== 'tiered' && (
        <TemplatedPlanInput
          resource={resource}
          index={index}
          hasConnectAccount={stripeReady}
          setFieldValue={setFieldValue}
        />
      )}
      {resource.priceType === 'custom' && (
        <CustomPlanInput
          resource={resource}
          index={index}
          hasConnectAccount={stripeReady}
          setFieldValue={setFieldValue}
        />
      )}
      {resource.priceType === 'tiered' && (
        <TieredTable setFieldValue={setFieldValue} resource={resource} planIndex={index} />
      )}
    </Root>
  )
}

const Column = styled.div`
  flex: 1 1 0;
  padding: 0 8px;
`

const SmallColumn = styled.div`
  flex: 1 1 0;
  padding: 0 4px;
`

const Row = styled.div`
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
`

const Root = styled.div<{ extended: boolean }>`
  position: relative;
  z-index: 1000;
  > * {
    margin-left: 0;
  }
  ${({ extended }) => {
    return extended
      ? `
      flex-direction: column;
      ${InputWrapper}, ${SecondWrapper} {
        margin: 0;
        padding: 0;
      }
      ${InputWrapper} {
        margin-top: 16px;
      }
    `
      : ``
  }}
`

const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

const CustomWrapper = styled.div`
  ${InputWrapper} {
    margin-left: 0;
  }
`

const CustomTopLine = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 16px;
  margin-left: -4px;
  margin-right: -4px;
`

const SecondWrapper = styled.div`
  flex: 1 1 0;
`

export default PriceFields
