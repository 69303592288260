import axios from 'axios'

export const handleImageUpload = async (image: any) => {
  const data = new FormData()
  data.append('file', image)
  data.append('upload_preset', 'werevamp')
  data.append('cloud_name', 'dh1giwabb')
  const res = await axios.post('https://api.cloudinary.com/v1_1/dh1giwabb/image/upload', data)

  return res.data.secure_url || res.data.url
}

export const extractPictureUrl = async (fileUrl?: string | { file: File }[] | File[]) => {
  let profilePic = ''

  if (typeof fileUrl === 'string') {
    profilePic = fileUrl
  } else if (typeof fileUrl === 'object') {
    if (fileUrl.length) {
      const file = fileUrl[0]
      if ('file' in file) {
        profilePic = await handleImageUpload(file.file)
      } else {
        profilePic = await handleImageUpload(file as File)
      }
    } else {
      profilePic = ''
    }
  }

  return profilePic
}
