import type { Identifier, XYCoord } from 'dnd-core'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import styled from '@emotion/styled'

import { ItemTypes } from './ItemTypes'
import { Icon } from 'components/theme'
import { useAssocHomeEditor } from './assocHomeEditorContext'
import { SectionLayoutType } from 'generated/graphql'

export interface CardProps {
  id: number | string
  type: SectionLayoutType
  index: number
  setModal: (assocHomeId: number | string) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

const SectionCard = ({ id, type, index, setModal }: CardProps) => {
  const { moveSection, deleteSection } = useAssocHomeEditor()
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveSection(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return (
    <Root ref={ref} opacity={opacity} data-handler-id={handlerId}>
      <TopBar>
        <CardTitle>{sectionTypeName[type].title}</CardTitle>
        <IconWrapper>
          <EditIcon name="pen-to-square" onClick={() => setModal(id)} />
          <DeleteIcon name="trash" onClick={() => deleteSection(id)} />
        </IconWrapper>
      </TopBar>
      <DescriptionBox>{sectionTypeName[type].description}</DescriptionBox>
    </Root>
  )
}

const sectionTypeName = {
  [SectionLayoutType.Basic]: {
    title: 'Basic',
    description: 'Title and subtitle',
  },
  [SectionLayoutType.LargeProduct]: {
    title: 'Large Product Slider',
    description:
      'Large slider that takes up the entire section where you can select premium product listing, selected specific products, or create your own custom slide.',
  },
  [SectionLayoutType.ProductsSlider]: {
    title: 'Products Slider',
    description:
      'Slider that displays 3 product cards. You can select by product or select products by category.',
  },
  [SectionLayoutType.ProductsGrid]: {
    title: 'Products Grid',
    description: 'Manually select products to display them in a grid view.',
  },
  [SectionLayoutType.ProductType]: {
    title: 'Product Type Selector',
    description: 'User can select to go to the all software page or all services page.',
  },
  [SectionLayoutType.CategoryList]: {
    title: 'Category List',
    description:
      'Select the category which will display a list of a random 5 products from that category.',
  },
  [SectionLayoutType.TheoremFeatures]: {
    title: 'Theorem Features',
    description: 'Easy way for users see what other features they can use on Theorem',
  },
}

const Root = styled.div<{ opacity: number }>`
  width: 100%;
  opacity: ${({ opacity }) => opacity};
  background: #fafafa;
  border: 1px solid #c3c3c4;
  margin-bottom: 20px;
  background-color: white;
  cursor: move;
  border-radius: 6px;
  position: relative;
  min-height: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c3c3c4;
  width: 100%;
  padding: 8px 16px;
  background: #f1f2f5;
`

const DescriptionBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  padding: 20px;
`

const IconWrapper = styled.div``

const EditIcon = styled(Icon)`
  cursor: pointer;
`

const DeleteIcon = styled(Icon)`
  margin-left: 16px;
  cursor: pointer;
`

const CardTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`

export default SectionCard
