import React from 'react'
import UserForm, { initialValues } from 'components/forms/personal/UserForm'
import { useSetUserStatus } from 'hooks/graphql'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useUserSetup } from 'context/userSetupContext'
import { UserStatus } from 'generated/graphql'
import { useViewer } from 'context/viewerContext'
import { Viewer } from 'data/types'
import { useAppMetadata } from 'data/hooks'
import SetupHeader from 'components/global/SetupHeader'

const AssociationUser = () => {
  const { viewer } = useViewer()
  const { setUserStatus } = useSetUserStatus()
  const navigate = useNavigate()
  const { amplitude } = useUserSetup()
  const { data: appMetadata } = useAppMetadata()

  const defaultValues = loadInitialValues(viewer)

  const isClocSignup = appMetadata?.assocOrg?.id === '5a172a54-130e-4cf6-8416-625f73950d98'

  const onSubmit = async () => {
    await setUserStatus(isClocSignup ? UserStatus.Active : UserStatus.AssocStack)

    const pathname = appMetadata.assocOrg ? '/setup/network-success' : `/setup/association-stack`

    amplitude?.logEventWithSavedProps('set_user_info', {
      action: 'update_information',
      organization_type: 'ASSOCIATION',
    })

    navigate(pathname)
  }

  return (
    <Root isClocSignup={isClocSignup}>
      <SetupHeader />
      <FormContainer>
        <UserForm defaultValues={defaultValues} onSubmit={onSubmit} />
      </FormContainer>
    </Root>
  )
}

const Root = styled.div<{ isClocSignup: boolean }>`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const FormContainer = styled.div`
  width: 100%;
  max-width: 760px;
  padding: 40px;
  margin: 0 auto;
`

const loadInitialValues = (user: Viewer) => {
  const { first, last, jobTitle, details } = user

  return {
    ...initialValues,
    firstName: first || '',
    lastName: last || '',
    jobTitle: jobTitle,
    fileUrl: details.imageUrl,
    twitter: details.twitter || '',
    linkedin: details.linkedin || '',
    practiceGroup: user.practiceGroups.map(({ id }) => id),
  }
}

export default AssociationUser
