import ImageBodyLink from './ImageBodyLink'
import image from 'assets/marketing/profile-data-rafiki.png'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const MemberPortalSetup = () => {
  const baseOrgUrl = useBaseOrgUrl()

  return (
    <ImageBodyLink
      image={image}
      title="Update Your Profile"
      Message="Set up your profile and highlight the tools you use in the first stack share for legal technology."
      routerLink={{ to: `${baseOrgUrl}/your-apps`, text: 'Edit Profile' }}
    />
  )
}

export default MemberPortalSetup
