import styled from '@emotion/styled'
import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Link } from 'react-router-dom'
import ProIconSvg from 'assets/upgrade/pro-icon.svg'

const UpsellTheoremPro = () => {
  const { paymentStatus } = useViewer()
  const baseOrgUrl = useBaseOrgUrl()

  if (paymentStatus === 'ACTIVE')
    return (
      <ActiveRoot>
        Congrats on being a <ProIcon src={ProIconSvg} />! One account for all your listings.
      </ActiveRoot>
    )

  return (
    <Root>
      <div>
        Go <ProIcon src={ProIconSvg} />! Universal Listing on all our networks + Save 20% off
        Premium Upgrades &amp; Data
      </div>
      <UpgradeButton to={`${baseOrgUrl}/theorem-pro-upgrade`}>Upgrade to Pro</UpgradeButton>
    </Root>
  )
}

const ProIcon = styled.img`
  width: 24px;
  transform: translateY(3px);
`

const Root = styled.div`
  max-width: 1200px;
  margin: 0 auto 16px;
  padding: 16px 28px;
  background: #f0f4ff;
  border: 3px solid #6ab9eb;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ActiveRoot = styled.div`
  max-width: 1200px;
  margin: 0 auto 16px;
  padding: 16px 28px;
  background: #fffce8;
  border: 3px solid #edc84a;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
`

const UpgradeButton = styled(Link)`
  background: #ffc62c;
  color: white;
  font-weight: 600;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 20px;
  margin-left: 20px;
  white-space: nowrap;
`

export default UpsellTheoremPro
