import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'
import { Term } from 'data/types'
import { GqlTermsByTaxonomyIdsQuery } from 'generated/graphql'

export const useTaxonomyDropdowns = () => {
  const request = useGql()
  const { data, isLoading } = useQuery(
    ['terms-dropdown'],
    async () => {
      const data = await request<GqlTermsByTaxonomyIdsQuery>(TERM_BY_TAXONOMY_IDS, {
        taxonomyIds: [19, 14, 15, 13, 16, 7, 8, 3, 4],
      })

      const terms = data.termsByTaxonomyIds as Term[]

      const filterByTaxonomyId = filterTerms(terms)

      const employeeSizeDropdown = filterByTaxonomyId(19)
      const categoryDropdown = filterByTaxonomyId(14)
      const subcategoryDropdown = filterByTaxonomyId(15)
      const practiceGroupDropdown = filterByTaxonomyId(13)
      const bestForDropdown = filterByTaxonomyId(16)
      const deploymentsDropdown = filterByTaxonomyId(7)
      const rolesDropdown = filterByTaxonomyId(8)
      const countryDropdown = filterByTaxonomyId(3)
      const languageDropdown = filterByTaxonomyId(4)

      return {
        terms,
        employeeSizeDropdown,
        categoryDropdown,
        subcategoryDropdown,
        practiceGroupDropdown,
        bestForDropdown,
        deploymentsDropdown,
        rolesDropdown,
        countryDropdown,
        languageDropdown,
      }
    },
    {
      staleTime: 1000 * 600,
    }
  )

  return { ...data, isLoading }
}

interface Option {
  key: string
  text: string
  value: number
}

const filterTerms =
  (terms: Term[]) =>
  (taxonomyId: number): Option[] => {
    return terms
      .filter((term) => term.taxonomyId === taxonomyId)
      .map((term) => ({
        key: `term-${term.id}`,
        text: term.name,
        value: term.id,
      }))
  }

const TERM_BY_TAXONOMY_IDS = gql`
  query TermsByTaxonomyIds($taxonomyIds: [Int!]!) {
    termsByTaxonomyIds(taxonomyIds: $taxonomyIds) {
      id
      name
      slug
      taxonomyId
    }
  }
`
