// import { useFormikContext } from 'formik'
// import { usePrompt } from 'hooks/router/usePrompt'

const PromptIfDirty = () => {
  // const formik = useFormikContext()
  // const dirtyForm = formik.dirty && formik.submitCount === 0
  // usePrompt('Are you sure you want to leave? You have unsaved changes.', dirtyForm)

  return <div></div>
}

export default PromptIfDirty
