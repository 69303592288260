import styled from '@emotion/styled'
import Button from 'components/theme/Button'

function ProceedConfirm() {
  return (
    <Root>
      <Container>
        <Text>
          Make sure to{' '}
          <Outlink href="https://ecosystem.theoremlegal.com" target="_blank">
            browse our platform
          </Outlink>{' '}
          and claim an existing profile for your product.
          <CantFind>Can&apos;t find your product?</CantFind>
        </Text>
        <ButtonContainer>
          <Button component="link" to="/vendor/create">
            Proceed
          </Button>
        </ButtonContainer>
      </Container>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  position: relative;
`

const Container = styled.div`
  max-width: 650px;
`

const Text = styled.div`
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 28px;
`

const Outlink = styled.a`
  color: #9013fe;
  font-weight: 500;
`

const ButtonContainer = styled.div``

const CantFind = styled.div`
  margin-top: 12px;
  font-weight: 500;
`

export default ProceedConfirm
