import styled from '@emotion/styled'
import PulsatingLoader from 'components/PulsatingLoader'
import useProfileAssociation from './useProfileAssociation'
import AssocBox from './AssocBox'

const ProfileAssociations = () => {
  const { associations, loading } = useProfileAssociation()

  if (loading) return <PulsatingLoader />

  return (
    <Root>
      {associations.length === 0 ? (
        <div>
          <h3>Your organization is not part of an association</h3>
        </div>
      ) : (
        associations.map((association) => <AssocBox key={association.id} {...association} />)
      )}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default ProfileAssociations
