import { withAuthenticationRequired } from '@auth0/auth0-react'
import LoadingPage from 'components/global/LoadingPage'
import { Outlet } from 'react-router'

const AuthWrapper = () => {
  return <Outlet />
}

export default withAuthenticationRequired(AuthWrapper, {
  onRedirecting: () => <LoadingPage />,
  returnTo: window.location.origin + window.location.pathname,
})
