import React, { useEffect } from 'react'
import { FullPageLoading } from 'components/PulsatingLoader'
import { toast } from 'react-toastify'

export default function AuthRedirect({ location, history }: any) {
  const { search } = location

  const getParams = () => {
    const params = new URLSearchParams(search)
    const code = params.get('code')
    const state = params.get('state')

    if (!code || !state) {
      toast.error('Invalid redirect. Please check your redirect params.')
      history.push('/')
    }

    return { code, state }
  }

  const requestConnectSetup = async () => {
    try {
      history.push('/vendor/connect/success')
    } catch (error) {
      toast.error(error.message)
      history.push('/')
    }
  }

  useEffect(() => {
    getParams()
    requestConnectSetup()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <FullPageLoading />
}
