import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useUserByEmail = (email: string) => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['user', email],
    async () => {
      const response = await request(GET_USER_BY_EMAIL, { email })

      return response
    },
    {
      staleTime: 1000 * 60 * 60 * 15,
      enabled: !!email,
    }
  )

  return { user: data, ...other }
}

const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String) {
    users(condition: { email: $email }) {
      id
    }
  }
`
