import { useQuery, useQueryClient } from '@tanstack/react-query'
import { VIEWER_GQL } from './viewerGql'
import { useGql } from 'hooks/useGql'
import { GqlViewerQuery, OrganizationType } from 'generated/graphql'
import { parseOrg } from 'data/organization/parseOrg'
import { parseViewer } from './parseViewer'
import axios, { AxiosRequestConfig } from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import { isUsingProductionServer } from 'services/helper'
import { Viewer, ViewerSubscriptions } from './viewerTypes'

const getAudience = (isLocal = false) => {
  if (process.env.NODE_ENV !== 'development' && isUsingProductionServer) {
    return 'https://m0qbfdtry8.execute-api.us-west-2.amazonaws.com/dev'
  }

  if (isLocal) {
    return 'http://localhost:3001'
  }

  return 'https://qrr3vzquxi.execute-api.us-west-2.amazonaws.com/staging'
}

interface ViewerData {
  viewer: Viewer | undefined
  stripeReady: boolean
  paymentStatus: string
  subscription: ViewerSubscriptions
  accountId?: string
}

export const useViewerData = () => {
  const request = useGql()
  const queryClient = useQueryClient()
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  return useQuery<ViewerData, any>(
    ['viewer'],
    async () => {
      try {
        const audience = getAudience()
        const token = await getAccessTokenSilently({
          audience,
          scope: 'openid profile email',
        })
        const gqlViewerQueryPromise = request<GqlViewerQuery>(VIEWER_GQL)
        const axiosOptions: AxiosRequestConfig = {
          method: 'post',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }

        const stripeSubscriptionUrl = `${audience}/stripe/subscription/user`
        const stripeAccountUrl = `${audience}/stripe/connect/get-account`

        const stripeSubscriptionPromise = axios({
          url: stripeSubscriptionUrl,
          ...axiosOptions,
        })
        const stripeAccountPromise = axios({
          url: stripeAccountUrl,
          ...axiosOptions,
        })

        const [viewerResults, stripeSubscriptionResults, stripeAccountResults] = await Promise.all([
          gqlViewerQueryPromise,
          stripeSubscriptionPromise,
          stripeAccountPromise,
        ])

        const stripeSubscriptionData = stripeSubscriptionResults.data
        const paymentStatus =
          stripeSubscriptionData.subscriptionStatus === 'active' ||
          stripeSubscriptionData.subscriptionStatus === 'trialing'
            ? 'ACTIVE'
            : 'NOT_ACTIVE'
        const subscription = stripeSubscriptionData?.productKey || 'FREE'

        const currentOrg = parseOrg(viewerResults.viewer?.organizationByCurrentOrganization)

        if (currentOrg?.type === OrganizationType.Vendor) {
          const { products } = currentOrg
          if (products.length > 0) {
            products.forEach((product) => {
              queryClient.setQueryData(['product', product.id], product)
            })
          }
        }

        queryClient.setQueryData(['org'], currentOrg)
        queryClient.setQueryData(['viewer', 'appMetadata'], viewerResults.viewer?.appMetadata)

        const viewer = parseViewer(viewerResults)

        const accountId = stripeAccountResults.data

        return {
          viewer,
          stripeReady: !!accountId,
          paymentStatus,
          subscription,
          accountId,
        }
      } catch (error) {
        console.log(error)

        /* if (error.error === 'login_required') {
          localStorage.clear()
          const history = createBrowserHistory()
          const path = _.get(history, 'location.pathname', '/')
          const redirectType =
            path === '/signup'
              ? {
                  appState: { targetUrl: '/' },
                  initialScreen: 'signUp',
                }
              : {
                  appState: { targetUrl: window.location.origin },
                }

          loginWithRedirect(redirectType)
        } */

        throw error
      } finally {
        console.timeEnd('viewer')
      }

      // organization
      // products
      // user
      // personalTechstack
      // userToOrg
      // [user, userId]
      // termToUser
      // [term, id]
      //
    },
    {
      staleTime: 1000 * 60 * 60,
      retry: 0,
      enabled: isAuthenticated,
    }
  )
}
