import styled from '@emotion/styled'
import { Icon } from '@theoremlts/theorem-design'
import { StepHeader } from 'interfaces'
import React from 'react'

interface Props extends StepHeader {
  isLarge: boolean
  isInactive: boolean
  index: number
}

const StepIcon: React.FC<Props> = ({ icon, iconTitle, index, isInactive, isLarge = false }) => {
  return (
    <Root>
      <IconWrapper isLarge={isLarge} isInactive={isInactive}>
        <TheIcon
          name={icon}
          islarge={isLarge ? isLarge.toString() : undefined}
          isinactive={isInactive ? isInactive.toString() : undefined}
        />
      </IconWrapper>
      <Title isLarge={isLarge} isInactive={isInactive}>
        {index}. {iconTitle}
      </Title>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
`

const iconColor = '#8C4FB9'
const TheIcon = styled(Icon)<{ islarge?: string; isinactive?: string }>`
  font-size: ${({ islarge }) => (islarge ? '32px' : '22px')};
  color: ${({ isinactive }) => (isinactive ? '#707070' : iconColor)};
`

const IconWrapper = styled.div<{ isLarge: boolean; isInactive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isLarge }) => (isLarge ? '80px' : '60px')};
  height: ${({ isLarge }) => (isLarge ? '80px' : '60px')};
  border-radius: 100px;
  border: ${({ isLarge }) => (isLarge ? 4 : 3)}px solid
    ${({ isInactive }) => (isInactive ? '#C4C4C4' : '#B586D8')};
  background: white;
`

const Title = styled.div<{ isLarge: boolean; isInactive: boolean }>`
  left: 50%;
  position: absolute;
  color: ${({ isInactive }) => (isInactive ? '#56565a' : iconColor)};
  bottom: -28px;
  font-weight: 600;
  font-size: ${({ isLarge }) => (isLarge ? '18px' : '16px')};
  transform: translateX(-50%);
  white-space: nowrap;
`

export default StepIcon
