import React, { useEffect, useState } from 'react'
import { AssociationTechstack, Option } from 'interfaces'
import { useAssociationTechstack } from 'data/hooks'
import useActiveOrgUsers from './useActiveOrgUsers'

type InitialState = {
  techstack: AssociationTechstack[]
  orgUserOptions: Option[]
  practiceGroupOptions: Option[]
  roleOptions: Option[]
  search: string
  onSearchChange: (newSearchValue: string) => void
  onUserChange: (newUserId: number | undefined) => void
  onPracticeGroupChange: (newPracticeGroup: number | undefined) => void
  onRoleChange: (newRole: number | undefined) => void
}

const AssociationStackshareContext = React.createContext({} as InitialState)

export const AssociationStackshareProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    techstack: fullTechstack,
    isLoading: initialLoading,
    isFetching,
  } = useAssociationTechstack()

  const [currentTechstack, setCurrentTechstack] = useState<AssociationTechstack[]>(
    fullTechstack || []
  )
  const { orgUserOptions } = useActiveOrgUsers()
  const [selectedUser, setSelectedUser] = useState<number | undefined>()
  const [selectedPracticeGroup, setSelectedPracticeGroup] = useState<number | undefined>()
  const [selectedRole, setSelectedRole] = useState<number | undefined>()
  const [practiceGroupOptions, setPracticeGroupOptions] = useState<Option[]>([])
  const [roleOptions, setRoleOptions] = useState<Option[]>([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (initialLoading || !fullTechstack || isFetching) return

    const practiceGroups = fullTechstack
      .filter(({ practiceGroups }) => practiceGroups.length)
      .flatMap(({ practiceGroups }) => practiceGroups)
      .filter(
        (value, index, array) => array.findIndex((arrVal) => arrVal.id === value.id) === index
      )
      .map(({ id, name }) => ({ key: `${name}-${id}`, text: name, value: id }))
      .sort((a, b) => {
        if (a.text < b.text) {
          return -1
        }
        if (a.text > b.text) {
          return 1
        }
        return 0
      })

    setPracticeGroupOptions([
      ...practiceGroups,
      { key: 'practice-group-clear', text: 'Clear Practice Group', value: undefined },
    ])

    const roles = fullTechstack
      .filter(({ roles }) => roles.length)
      .flatMap(({ roles }) => roles)
      .filter(
        (value, index, array) => array.findIndex((arrVal) => arrVal.id === value.id) === index
      )
      .map(({ id, name }) => ({ key: `${name}-${id}`, text: name, value: id }))
      .sort((a, b) => {
        if (a.text < b.text) {
          return -1
        }
        if (a.text > b.text) {
          return 1
        }
        return 0
      })

    setRoleOptions([...roles, { key: 'role-clear', text: 'Clear Role', value: undefined }])
  }, [initialLoading, isFetching])

  useEffect(() => {
    if (initialLoading || !fullTechstack || isFetching) return
    let filteredTechstack = fullTechstack

    if (selectedUser)
      filteredTechstack = filteredTechstack.filter(({ users }) =>
        users.some(({ id }) => id === selectedUser)
      )

    if (selectedPracticeGroup)
      filteredTechstack = filteredTechstack.filter(({ practiceGroups }) =>
        practiceGroups.some(({ id }) => id === selectedPracticeGroup)
      )

    if (selectedRole)
      filteredTechstack = filteredTechstack.filter(({ roles }) =>
        roles.some(({ id }) => id === selectedRole)
      )

    if (search.length > 0) {
      filteredTechstack = filteredTechstack.filter(({ product }) =>
        product.name.toLowerCase().includes(search.toLowerCase())
      )
    }

    setCurrentTechstack(filteredTechstack)
  }, [search, selectedUser, selectedPracticeGroup, selectedRole, initialLoading, isFetching])

  const onSearchChange = (newSearchValue: string) => {
    setSearch(newSearchValue)
  }

  const onUserChange = (newUserId: number | undefined) => {
    setSelectedUser(newUserId)
  }

  const onPracticeGroupChange = (newPracticeGroup: number | undefined) => {
    setSelectedPracticeGroup(newPracticeGroup)
  }

  const onRoleChange = (newRole: number | undefined) => {
    setSelectedRole(newRole)
  }

  return (
    <AssociationStackshareContext.Provider
      value={{
        techstack: currentTechstack,
        orgUserOptions,
        practiceGroupOptions,
        roleOptions,
        search,
        onSearchChange,
        onUserChange,
        onPracticeGroupChange,
        onRoleChange,
      }}
    >
      {children}
    </AssociationStackshareContext.Provider>
  )
}

export const useAssociationStackshare = () => React.useContext(AssociationStackshareContext)
