import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'
import { useViewer } from 'context/viewerContext'
import { PaymentSetup, SetupComplete } from './'

const StripeAccountStatus = () => {
  const { accountId } = useViewer()

  return (
    <BoxWrapper>
      {!accountId && (
        <TopLine>
          <StepTab selected={false}>
            <StepNumber selected={false}>1</StepNumber> agree to terms
          </StepTab>
          <StepTab selected={true}>
            <StepNumber selected={true}>2</StepNumber> set up payments
          </StepTab>
        </TopLine>
      )}
      <Container>{accountId ? <SetupComplete /> : <PaymentSetup />}</Container>
    </BoxWrapper>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const TopLine = styled.div`
  color: #b9b9b9;
  width: 32em;
  margin: -16px auto 32px;
  display: flex;
  justify-content: space-between;
`

const StepTab = styled.div<{ selected: boolean }>`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 80%;
  padding: 8px;
  marin-right: 16px;
  ${({ selected }) => !selected && 'opacity: .6;'}
  ${({ selected }) => selected && 'border-top: 1px solid #b9b9b9;'}
`

const StepNumber = styled.div<{ selected: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${({ selected }) => (selected ? 'white' : '#b9b9b9')};
  border: solid 2px #b9b9b9;
  color: ${({ selected }) => (selected ? '#b9b9b9' : 'white')};
  width: 32px;
  height: 32px;
  border-radius: 50px;
  font-size: 20px;
  line-height: 0;
  margin-right: 8px;
`

export default StripeAccountStatus
