import { useOrg } from 'data/organization/useOrg'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { Product } from 'data/types'

interface Variables {
  productId?: number
  name: string
  url: string
  excerpt: string
  logoUrl: string
  squareLogoUrl: string
  type: string
}
export const useUpdateBasicInfo = (product?: Product) => {
  const { organization } = useOrg()
  const { viewer, isSuperAdmin } = useViewer()
  const queryClient = useQueryClient()
  const request = useGql()

  const mutation = useMutation(
    async (variables: Variables) => {
      const { name, url, excerpt, productId, logoUrl, squareLogoUrl, type } = variables
      if (!organization) return

      if (productId) {
        await request(UPDATE_VENDOR_PRODUCT, {
          id: productId,
          name,
          excerpt,
          details: {
            ...product?.details,
            url,
            logoUrl,
            squareLogoUrl,
          },
        })
      } else {
        try {
          const productData: any = await request(CREATE_VENDOR_PRODUCT, {
            name,
            description: '',
            excerpt,
            testMode: isSuperAdmin ? false : viewer.testMode,
            type,
            details: {
              url,
              slides: [],
              logoUrl,
              squareLogoUrl,
              demoVideo: '',
              startingMonthlyPrice: '',
            },
          })

          const productId: any = productData.createProduct.product.id

          if (!isSuperAdmin)
            await request(CREATE_PRODUCT_TO_ORGANIZATION, {
              productId,
              organizationId: organization.id,
            })

          if (productId) localStorage.setItem('productId', productId.toString())
          return productId
        } catch (e) {
          console.log(e)
          debugger
          throw e
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['org'])
      },
    }
  )

  return mutation
}

const UPDATE_VENDOR_PRODUCT = gql`
  mutation UpdateVendorProduct($id: Int!, $excerpt: String, $name: String, $details: JSON) {
    updateProduct(
      input: { patch: { details: $details, name: $name, excerpt: $excerpt }, id: $id }
    ) {
      clientMutationId
    }
  }
`

const CREATE_PRODUCT_TO_ORGANIZATION = gql`
  mutation CreateProductToVendor($productId: Int!, $organizationId: UUID!) {
    productToVendor: createProductToOrganization(
      input: { productToOrganization: { productId: $productId, organizationId: $organizationId } }
    ) {
      clientMutationId
    }
  }
`

const CREATE_VENDOR_PRODUCT = gql`
  mutation CreateVendorProduct(
    $description: String
    $excerpt: String
    $name: String
    $details: JSON
    $testMode: Boolean
    $type: ProductType = SOFTWARE
  ) {
    createProduct(
      input: {
        product: {
          description: $description
          excerpt: $excerpt
          name: $name
          details: $details
          testMode: $testMode
          type: $type
        }
      }
    ) {
      product {
        id
        slug
      }
    }
  }
`
