import styled from '@emotion/styled'
import { useNavigate } from 'react-router'

const BackButton = () => {
  const navigate = useNavigate()
  return <BackButtonDiv onClick={() => navigate(-1)}>&#60; Back</BackButtonDiv>
}

const BackButtonDiv = styled.div`
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
`

export default BackButton
