import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import { BoxWrapper, Button, Icon } from 'components/theme'
import { useRedirect } from 'hooks/useRedirect'
import _ from 'lodash'

const CompanySuccess = () => {
  const redirect = useRedirect()
  const { user: auth0User } = useAuth0()

  const domain =
    process.env.NODE_ENV === 'development'
      ? `https://ecosystem.theorem.local:3300`
      : 'https://ecosystem.theoremlegal.com'
  let loginUrl = `${domain}/api/auth/login?`

  if (auth0User?.org_id) {
    loginUrl = `${loginUrl}organization=${auth0User?.org_id}&`
  }

  return (
    <BoxWrapper>
      <Root>
        <CheckCircle>
          <CheckIcon name="check" />
        </CheckCircle>
        <Title>Success! Your Account's been updated</Title>
        <Wrapper>
          <Content>
            <strong>Next Steps:</strong> The next step is to make sure your listing details are up
            to date. Visit your company listing to update your global company information or
            continue to the listing to add details specific to that company.
          </Content>
          <ButtonsWrapper>
            <ButtonFill
              component="a"
              href={`${loginUrl}returnTo=/admin/edit-company-listing/basic`}
            >
              Edit Company Listing
            </ButtonFill>
            <Button onClick={() => redirect('listing-stepper')}>View All Listings</Button>
          </ButtonsWrapper>
        </Wrapper>
      </Root>
    </BoxWrapper>
  )
}

const Root = styled.div``

const buttonColor = ({ color, theme }: any) => color || _.get(theme, 'color.primaryBlue', '#498DE6')
const ButtonFill = styled(Button)`
  background: ${buttonColor};
  color: white;
  margin-right: 20px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 460px;
`

const Title = styled.h1`
  text-align: center;
`

const Content = styled.div``

const CheckCircle = styled.div`
  width: 70px;
  height: 70px;
  border: 5px solid #57d20a;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

const CheckIcon = styled(Icon)`
  color: #0ecc00;
  font-size: 40px;
`

export default CompanySuccess
