import React from 'react'
import styled from '@emotion/styled'
import { TextInput } from 'components/theme/form'
import { Button, UnderlineTitle } from 'components/theme'
import { FieldArray } from 'formik'

function FieldItemField({ index, value }: any) {
  return (
    <FieldArray
      name={`fields`}
      render={(arrayHelpers) => (
        <>
          <UnderlineTitle helpContent="List plan specs">Features</UnderlineTitle>
          {value &&
            value.map((_: any, index2: any) => (
              <FieldItem key={`field-${index}-${index2}`}>
                <InputWrapper>
                  <TextInput
                    placeholder="Feature"
                    name={`fields[${index2}].name`}
                    value={value[index2].name}
                  />
                </InputWrapper>
                <ButtonWrapper>
                  <RemoveButton
                    type="button"
                    onClick={() => {
                      arrayHelpers.remove(index2)
                    }}
                  >
                    -
                  </RemoveButton>
                </ButtonWrapper>
              </FieldItem>
            ))}

          <AddButton type="button" onClick={() => arrayHelpers.push({ name: '' })}>
            + Add Feature
          </AddButton>
        </>
      )}
    />
  )
}

const RemoveButton = styled(Button)`
  color: #f44336;
  border-color: #f44336;

  &:hover {
    border-color: #f44336;
    background-color: #f44336;
  }
`

const AddButton = styled.a`
  padding: 4px 16px;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  margin-top: 4px;
  cursor: pointer;
`

const FieldItem = styled.div`
  display: flex;
  padding-bottom: 8px;
`

const InputWrapper = styled.div`
  flex: 1 1 0px;
  margin-right: 20px;
`

const ButtonWrapper = styled.div`
  flex: 0 0 auto;
`

export default FieldItemField
