import ImageBodyLink from './ImageBodyLink'
import image from 'assets/marketing/dropshipping-model-rafiki.png'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'

const VisitLegalTechMarket = () => {
  const marketplaceUrl = useMarketplaceUrl()

  return (
    <ImageBodyLink
      image={image}
      title="Visit the LegalTech Marketplace"
      Message="Discover the world of legal technology in one ecosystem."
      link={{
        to: marketplaceUrl,
        text: 'Browse Legal Tech',
      }}
    />
  )
}

export default VisitLegalTechMarket
