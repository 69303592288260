import { PRODUCT_CORE_FRAGMENT, VIEWER_PRODUCT_FRAGMENT } from 'data/fragments/product'
import { TERM_CORE_FRAGMENT } from 'data/fragments/term'
import { useOrg } from 'data/organization/useOrg'
import { GqlGetProductByIdQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'
import { parseProduct } from './parseProduct'

export const useProduct = (productId: number, enabled = true) => {
  const request = useGql()
  const { organization } = useOrg()

  const { data, ...other } = useQuery(
    ['product', productId],
    async () => {
      const data = await request<GqlGetProductByIdQuery>(PRODUCT_BY_ID, { productId })

      return data.product ? parseProduct(data.product, organization?.id) : undefined
    },
    {
      enabled,
      staleTime: 1000 * 60 * 60,
    }
  )

  return { product: data, ...other }
}

const PRODUCT_BY_ID = gql`
  query GetProductById($productId: Int!) {
    product(id: $productId) {
      ...ViewerProduct
    }
  }

  ${PRODUCT_CORE_FRAGMENT}
  ${VIEWER_PRODUCT_FRAGMENT}
  ${TERM_CORE_FRAGMENT}
`
