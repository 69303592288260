import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { getPersonalPlans } from 'model/api/stripe'
import LogoImg from 'assets/purple-logo.png'
import { breakpoint } from 'services/theming'
import PlanDescription from './PlanDescription'

function PaymentOptions() {
  const [plans, setPlans] = useState<any>([])

  useEffect(() => {
    const getPlans = async () => {
      const { plans } = await getPersonalPlans()

      setPlans(plans)
    }

    getPlans()
  }, [])

  return (
    <Root>
      <Logo src={LogoImg} />
      <Container>
        <Header>
          <Title>Choose a personal plan</Title>
          <Text>
            Every plan comes with Theorem&apos;s most loved features: Blah blah features listed
            (gotta rethink the content here)
          </Text>
        </Header>
        <Plans>
          {plans.length > 0 &&
            plans.map((plan: any) => <PlanDescription key={plan.id} plan={plan} />)}
        </Plans>
      </Container>
    </Root>
  )
}

const Logo = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  left: 20px;
`

// background: #fdf3ff;
const Root = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background: #f4eff5;
  line-height: 1;
  position: relative;
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
`

const Text = styled.div`
  font-size: 18px;
`

const Plans = styled.div`
  display: flex;
  margin: 0 -30px;

  ${breakpoint.tablet} {
    flex-direction: column;
  }
`

const Header = styled.div`
  padding: 50px 0 80px;
  text-align: center;
`

export default PaymentOptions
