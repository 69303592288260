import React, { useCallback, useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import styled from '@emotion/styled'

export const useConfirmationModal = ({
  text,
  onConfirm,
}: {
  text: string
  onConfirm: () => void
}) => {
  const [open, setOpen] = useState(false)

  const ConfirmationModal = useCallback(
    () => (
      <>
        {open && (
          <Modal size="tiny" closeIcon onClose={() => setOpen(false)} open={open}>
            <Modal.Content>
              <ContentContainer>
                <Text>{text}</Text>
                <ButtonContainer>
                  <div style={{ marginRight: 12 }}>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                  </div>
                  <Button
                    onClick={() => {
                      setOpen(false)
                      onConfirm()
                    }}
                    primary
                  >
                    Confirm
                  </Button>
                </ButtonContainer>
              </ContentContainer>
            </Modal.Content>
          </Modal>
        )}
      </>
    ),
    [open]
  )

  return { open, setOpen, Modal: ConfirmationModal }
}

const ButtonContainer = styled.div`
  display: flex;
`

const Text = styled.p``
const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  align-items: center;
`
