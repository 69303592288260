import * as yup from 'yup'

export const mainStoryValidation = yup.object().shape({
  when: yup.string().max(200).required('Required'),
  productId: yup.number().min(1, 'Required').required('Required'),
  featureName: yup.string(),
  featureType: yup.number().required('Required'),
  to: yup.string().max(200).required('Required'),
})

export const stageValidation = yup.object().shape({
  stage: yup.array().min(0).max(2),
})

export const userDescriptionValidation = yup.object().shape({
  firmType: yup.string(),
  role: yup.string(),
  practiceGroup: yup.number(),
})
