import { useOrg } from 'data/hooks'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface Variables {
  appMetadata?: { [key: string]: any }
  deleteAppMetadataKeys?: string[]
}
export const useUpdateOrgMetadata = () => {
  const { organization } = useOrg()
  const queryClient = useQueryClient()
  const request = useGql()

  return useMutation(
    async (variables: Variables) => {
      if (!organization) return

      const appMetadata = {
        ...organization.appMetadata,
        ...variables.appMetadata,
      }

      if (variables.deleteAppMetadataKeys) {
        variables.deleteAppMetadataKeys.forEach((key) => {
          delete appMetadata[key]
        })
      }
      await request(UPDATE_ORG_METADATA, {
        organizationId: organization.id,
        appMetadata,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

const UPDATE_ORG_METADATA = gql`
  mutation UpdateOrgAppMetadata($appMetadata: JSON, $organizationId: UUID!) {
    updateOrganization(input: { patch: { appMetadata: $appMetadata }, id: $organizationId }) {
      clientMutationId
    }
  }
`
