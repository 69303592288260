export default (stripeProductId: string) => {
  if (stripeProductId === 'prod_IivV6K4S9SPQ3M' || stripeProductId === 'prod_IlV5Q02ERRBZQI') {
    return 'ECOSYSTEM_USER'
  } else if (
    stripeProductId === 'prod_IivQ7N5Ns76tD7' ||
    stripeProductId === 'prod_IlVA50T5Cl4ED3'
  ) {
    return 'LAWFIRM_PRO'
  }

  return null
}
