import styled from '@emotion/styled'
import BaseModal from 'components/modals/BaseModal'
import { Button } from 'components/theme'
import TheoremLogoPng from 'assets/purple-logo.png'
import ProIconSvg from 'assets/upgrade/pro-icon.svg'
import { useMutateViewer } from 'data/hooks'

const ListingOfferModal = ({ toggleModal }: { toggleModal: () => void }) => {
  const mutateViewer = useMutateViewer()

  const onButtonClick = () => {
    mutateViewer.mutate(
      {
        appMetadata: {
          hideListingOfferModal: true,
        },
      },
      {
        onSuccess: () => toggleModal(),
      }
    )
  }

  return (
    <BaseModal toggleModal={toggleModal} width="500px">
      <Root>
        <ImageWrapper>
          <TheoremLogo src={TheoremLogoPng} />
          <ProIcon src={ProIconSvg} />
        </ImageWrapper>
        <Title>Introducing Theorem PRO</Title>
        <SubTitle>List everywhere for one price</SubTitle>
        <SmallTitle>What’s included:</SmallTitle>
        <ListWrapper>
          <FeatureList>
            <FeatureItem>All-in-One Subscription</FeatureItem>
            <FeatureItem>Universal Listing</FeatureItem>
            <FeatureItem>Advanced Seller tools</FeatureItem>
            <FeatureItem>Enhanced Profile Discounts</FeatureItem>
            <FeatureItem>Exclusive deals + More</FeatureItem>
          </FeatureList>
        </ListWrapper>
        <ButtonWrapper>
          <GetStartedButton onClick={onButtonClick}>Continue to listings</GetStartedButton>
        </ButtonWrapper>
      </Root>
    </BaseModal>
  )
}

const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`

const TheoremLogo = styled.img`
  margin-right: 20px;
  max-height: 50px;
`

const ProIcon = styled.img`
  max-height: 30px;
`

const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: 20px;
`

const GetStartedButton = styled(Button)`
  width: 100%;
  max-width: 200px;
  background: #ffc000;
  border: 2px solid #ffc000;

  &:hover {
    background: transparent;
    color: #ffc000;
  }
`

const Root = styled.div`
  padding: 32px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
`

const SubTitle = styled.div`
  text-align: center;
  font-size: 18px;
  margin-bottom: 24px;
`

const SmallTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-bottom: 16px;
`

const FeatureList = styled.ul`
  margin: 0 auto 28px;
`

const FeatureItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
`

export default ListingOfferModal
