import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import Button from 'components/theme/Button'
import { useSetUserStatus } from 'hooks/graphql'
import { useUserSetup } from 'context/userSetupContext'
import { MemberStatus, OrganizationSubtype, OrganizationType, UserStatus } from 'generated/graphql'
import { useAppMetadata } from 'data/viewer/useAppMetadata'
import {
  useCreateNoviOrg,
  useCreateAssocToOrg,
  useCreateProductsToOrg,
  useCreateOrg,
  useUpdateProductStatus,
} from 'data/hooks'
import styled from '@emotion/styled'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  ownedProducts: yup.array().min(1),
})

function SelectOwnedProducts() {
  const navigate = useNavigate()
  const [products, setProducts] = useState<any>([])
  const { setUserStatus } = useSetUserStatus()
  const { amplitude } = useUserSetup()
  const { data: appMetadata } = useAppMetadata()
  const createProductsToOrg = useCreateProductsToOrg()
  const createAssocToOrg = useCreateAssocToOrg()
  const createNoviOrg = useCreateNoviOrg()
  const createOrg = useCreateOrg()
  const updateProductStatus = useUpdateProductStatus()

  useEffect(() => {
    setProducts(appMetadata.matchedProducts)
  }, [])

  const connectUserToProducts = async ({ ownedProducts }: any) => {
    const organizationId = await createOrg.mutateAsync({
      type: OrganizationType.Vendor,
      subtype: OrganizationSubtype.Software,
    }) // TODO: This may be an issue because lawfirms can create orgs now
    if (appMetadata.assocOrg) {
      await createAssocToOrg.mutateAsync({
        organizationId,
        associationId: appMetadata.assocOrg.id,
        status: MemberStatus.Active,
      })
    }

    if (appMetadata.noviOrg) {
      await createNoviOrg.mutateAsync({
        organizationId,
        noviId: appMetadata.noviOrg.id,
      })
    }

    const productIds = ownedProducts.map((productId: string) => parseInt(productId, 10))

    await updateProductStatus.mutateAsync({ productIds, status: 'DRAFT' })
    await createProductsToOrg.mutateAsync({ productIds, organizationId })

    amplitude?.logEventWithSavedProps('set_self_admin_on_products', {
      action: 'selection',
    })
    setUserStatus(UserStatus.CreateUser)
    navigate('/setup/vendor-user')
  }

  if (products.length === 0) return <div />

  const ownedProducts = products.map(({ id }: any) => id.toString())
  return (
    <Root>
      <Formik
        ignoreLoading
        initialValues={{ ownedProducts }}
        onSubmit={connectUserToProducts}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ values, isSubmitting, isValid }) => (
          <Form>
            <Question>Are you the admin of the following product(s):</Question>
            <Checkboxes>
              {products.map((product: any, index: number) => (
                <CheckboxWrapper key={index}>
                  <label>
                    <Checkbox
                      type="checkbox"
                      name="ownedProducts"
                      value={product.id}
                      checked={values.ownedProducts.includes(product.id.toString())}
                    />
                    <ProductBox>
                      <LogoWrapper>
                        <Logo src={product.details.squareLogoUrl} />
                      </LogoWrapper>
                      <ProductName>{product.name}</ProductName>
                    </ProductBox>
                  </label>
                </CheckboxWrapper>
              ))}
            </Checkboxes>

            <Button type="submit" disabled={isSubmitting || !isValid} loading={isSubmitting}>
              Continue
            </Button>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  justify-content: center;
`

const Question = styled.div`
  font-size: 20px;
  margin-bottom: 28px;
`

const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
`

const ProductBox = styled.div`
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0.12);
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
`

const ProductName = styled.div`
  margin-top: 8px;
`

const Checkbox = styled(Field)`
  display: none;

  &:checked ~ ${ProductBox} {
    border: 2px solid ${({ theme }) => theme.color.primaryPurple};
    box-shadow: 0 0 5px rgba(144, 19, 254, 0.5);
  }
`

const LogoWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

const Logo = styled.img`
  width: 100%;
`

const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`

export default SelectOwnedProducts
