import { GqlProductListingQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'

// NOTE: the issue with this is that it only grabs a single product listing. Will need to be refactored to support more, but this will suffice for CLOC
export const useProductListing = (productListingId: number) => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['product-listing', productListingId],
    async () => {
      const result = await request<GqlProductListingQuery>(PRODUCT_LISTING, {
        productListingId,
      })

      return result?.productListing
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  )

  return { productListing: data, ...other }
}

const PRODUCT_LISTING = gql`
  query ProductListing($productListingId: Int!) {
    productListing(id: $productListingId) {
      details
      id
      productId
      product {
        name
        slug
        type
      }
      association {
        name
      }
    }
  }
`
