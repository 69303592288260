import { Routes, Route } from 'react-router-dom'

import YourApps from 'views/lawfirms/YourApps'
import TechstackManagement from 'views/lawfirms/TechstackManagement'
import UpdateTechStack from 'views/techStack/UpdateTechStack'
import { RfpStepper } from 'views/lawfirms/Rfp'
import TestDrive from 'views/lawfirms/TestDrive'
import TestDriveUserInfo from 'views/lawfirms/TestDrive/TestDriveUserInfo'
import TestDriveCheckout from 'views/lawfirms/TestDrive/TestDriveCheckout'
import TestDriveSuccess from 'views/lawfirms/TestDrive/TestDriveSuccess'
import RfpSuccess from 'views/lawfirms/Rfp/RfpSuccess'
import UpgradeSuccess from 'views/lawfirms/payment/UpgradeSuccess'
import UpgradeCancel from 'views/lawfirms/payment/UpgradeCancel'
import WorkflowsComingSoon from 'views/lawfirms/comingSoon/WorkflowsComingSoon'
import { WrappedEditorList, WrappedStoryEditor } from 'components/UserStories'
import DiscoverOrganizations from 'components/DiscoverOrganization'
import LawfirmGettingStarted from 'components/marketing/GettingStarted/LawfirmGettingStarted'
import Settings from 'views/setup/settings'
import UserFirmTechstack from 'views/lawfirms/techstack/UserFirmTechstack'
import LawfirmPlans from 'views/lawfirms/marketing/LawfirmPlans'
import UpgradeCheckout from 'views/lawfirms/payment/UpgradeCheckout'
import Techhub from 'views/Techhub'
import RootContent from 'components/containers/RootContent'

function CompanyRoutes() {
  return (
    <Routes>
      <Route element={<RootContent />}>
        <Route path="/" element={<YourApps />} />
        <Route path="your-apps" element={<YourApps />} />
        <Route path="settings/:slug" element={<Settings />} />
        <Route path="settings" element={<Settings />} />
        <Route path="edit-techstack/*" element={<UpdateTechStack />} />
        <Route path="user-firm-techstack" element={<UserFirmTechstack />} />
        <Route path="techstack-management" element={<TechstackManagement />} />
        <Route path="test-drive/plan-details" element={<TestDriveUserInfo />} />
        <Route path="test-drive/checkout-success" element={<TestDriveSuccess />} />
        <Route path="test-drive/checkout" element={<TestDriveCheckout />} />
        <Route path="test-drive" element={<TestDrive />} />
        <Route path="rfp" element={<RfpStepper />} />
        <Route path="rfp-success" element={<RfpSuccess />} />
        <Route path="workflows" element={<WorkflowsComingSoon />} />
        <Route path="user-stories/:storyId/edit" element={<WrappedStoryEditor />} />
        <Route path="user-stories" element={<WrappedEditorList />} />
        <Route path="user-stories/create" element={<WrappedStoryEditor />} />
        <Route path="upgrade/success" element={<UpgradeSuccess />} />
        <Route path="upgrade/cancel" element={<UpgradeCancel />} />
        <Route path="discover-organizations" element={<DiscoverOrganizations />} />
        <Route path="techhub" element={<Techhub />} />
        <Route path="getting-started" element={<LawfirmGettingStarted />} />
        <Route path="upgrade" element={<LawfirmPlans />} />
        <Route path="checkout" element={<UpgradeCheckout />} />
      </Route>
    </Routes>
  )
}

/*
<PrivateRoute title="Apps" wrapInSidebarTemplate path="/" exact component={YourApps} />
<PrivateRoute title="Apps" wrapInSidebarTemplate path="your-apps" component={YourApps} />

<PrivateRoute
  title="Settings"
  wrapInSidebarTemplate
  path="settings/:slug"
  component={Settings}
/>
<PrivateRoute title="Settings" wrapInSidebarTemplate path="settings" component={Settings} />
<PrivateRoute
  title="Edit Techstack"
  wrapInSidebarTemplate
  path="edit-techstack"
  component={UpdateTechStack}
/>

<PrivateRoute
  wrapInSidebarTemplate
  path="user-firm-techstack"
  component={UserFirmTechstack}
/>

<PrivateRoute
  title="Manage stack"
  wrapInSidebarTemplate
  path="techstack-management"
  component={TechstackManagement}
/>

<PrivateRoute
  title="Test Drive"
  path="test-drive/plan-details"
  component={TestDriveUserInfo}
/>

<PrivateRoute
  title="Test Drive"
  path="test-drive/checkout-success"
  component={TestDriveSuccess}
/>

<PrivateRoute title="Test Drive" path="test-drive/checkout" component={TestDriveCheckout} />

<PrivateRoute
  title="Test Drive"
  wrapInSidebarTemplate
  path="test-drive"
  component={TestDrive}
/>

<PrivateRoute
  title="Request For Proposal"
  wrapInSidebarTemplate
  path="rfp"
  component={RfpStepper}
/>

<PrivateRoute title="RFP" wrapInSidebarTemplate path="rfp-success" component={RfpSuccess} />

<PrivateRoute
  title="Workflows"
  wrapInSidebarTemplate
  path="workflows"
  component={WorkflowsComingSoon}
/>

<PrivateRoute
  title="TestCheckout"
  wrapInSidebarTemplate={false}
  path="test-checkout"
  component={TestCheckout}
/>

<PrivateRoute
  title="User Story Editor"
  wrapInSidebarTemplate
  path="user-stories/:storyId/edit"
  component={WrappedStoryEditor}
/>

<PrivateRoute
  title="User Story Editor"
  wrapInSidebarTemplate
  exact
  path="user-stories"
  component={WrappedEditorList}
/>

<PrivateRoute
  title="User Story Editor"
  wrapInSidebarTemplate
  exact
  path="user-stories/create"
  component={WrappedStoryEditor}
/>

<PrivateRoute
  title="Upgrade Success"
  wrapInSidebarTemplate
  path="upgrade/success"
  component={UpgradeSuccess}
/>

<PrivateRoute
  title="Upgrade Cancelled"
  wrapInSidebarTemplate
  path="upgrade/cancel"
  component={UpgradeCancel}
/>

<PrivateRoute
  exact
  title="Request access to an organization"
  path="discover-organizations"
  wrapInSidebarTemplate
  component={DiscoverOrganizations}
/>

<PrivateRoute
  exact
  title="Getting Started"
  path="getting-started"
  wrapInSidebarTemplate
  component={LawfirmGettingStarted}
/>

<PrivateRoute
  wrapInSidebarTemplate
  path="product/new"
  title="Add New Product"
  component={CreateNewProductWizard}
/>

<PrivateRoute
  exact
  title="Products"
  path="products"
  wrapInSidebarTemplate
  component={LawfirmProducts}
/>

<PrivateRoute
  title="Product Editor"
  wrapInSidebarTemplate
  path="product/settings/:productId"
  component={ProductSettings}
/>

<PrivateRoute
  title="Product Editor"
  wrapInSidebarTemplate
  path=":adminId/product/settings/:productId"
  component={ProductSettings}
/>

<PrivateRoute
  title="Listing Details"
  wrapInSidebarTemplate
  path="listing-details"
  component={ListingDetails}
/>

<PrivateRoute
  title="Listings"
  wrapInSidebarTemplate
  path="product-listing"
  component={ProductListing}
/>

<PrivateRoute title="Techhub" wrapInSidebarTemplate path="techhub" component={Techhub} />

<PrivateRoute title="Upgrade" path="upgrade" component={LawfirmPlans} />
<PrivateRoute title="Upgrade" path="checkout" component={UpgradeCheckout} />
 */

export default CompanyRoutes
