import { useState } from 'react'
import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'
import { Icon } from 'semantic-ui-react'
import SelectTechStack from 'components/forms/SelectTechStack'
import Modal from 'components/Modal'
import { useMutateIntegrations } from 'data/hooks'

function Integrations({ initialValues, productId, onComplete }: any) {
  const mutateIntegrations = useMutateIntegrations(productId)

  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const onSubmit = async (integrations: any) => {
    mutateIntegrations.mutate(
      {
        integrationIds: integrations,
        initialIntegrationIds: initialValues,
      },
      {
        onSuccess: () => onComplete(),
      }
    )
  }

  return (
    <Root>
      <AddAppWrapper>
        <AddAppButton onClick={toggleModal}>
          <Icon name="plus" />
        </AddAppButton>
        <AddAppText>Can&apos;t find an app?</AddAppText>
      </AddAppWrapper>
      <SelectTechStack initialSelectedAppIds={initialValues} onSubmit={onSubmit} />

      <Modal isShowing={showModal} toggleModal={toggleModal} width="600px">
        <iframe
          className="airtable-embed airtable-dynamic-height"
          title="airtable"
          src="https://airtable.com/embed/shrGDfEJnwsDh70Xf?backgroundColor=purple"
          frameBorder="0"
          width="100%"
          height="967"
        />
      </Modal>
    </Root>
  )
}

const Root = styled.div`
  position: relative;

  ${breakpoint.mobile} {
    padding: 20px 0 60px;
  }
`

const AddAppWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

const AddAppText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #555;
  display: block;
`

const AddAppButton = styled.div`
  display: block;
  color: #444;
  font-weight: 500;
  cursor: pointer;
  width: 35px !important;
  height: 35px !important;
  min-width: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 250ms, background 250ms;
  margin-right: 15px;

  &:hover {
    background: #fafafa;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
    color: #333;
  }

  .icon {
    margin: 0;
    line-height: 1;
  }
`

export default Integrations
