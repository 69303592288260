import { useLazyApi } from 'hooks/useApi'

interface PdfDetails {
  name: string
  url: string
  assetId: string
}

export const useUploadPdf = () => {
  const [loadMedia] = useLazyApi('media/pdf/upload', {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  const uploadPdf = async (pdf: any): Promise<PdfDetails> => {
    const formData = new FormData()
    formData.append('file', pdf)
    const cloudinaryData = await loadMedia(formData)

    const pdfDetails: PdfDetails = {
      name: pdf.name,
      url: cloudinaryData.url,
      assetId: cloudinaryData.asset_id,
    }

    return pdfDetails
  }

  return uploadPdf
}
