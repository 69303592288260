import { useAuth0 } from '@auth0/auth0-react'
import { GraphQLClient, RequestDocument, Variables } from 'graphql-request'
import config from 'services/auth0Config'

const endpoint = 'https://api.theoremlegal.com/graphql'
// const endpoint = 'http://localhost:8081/graphql'

const client = new GraphQLClient(endpoint)

export const useGql = () => {
  const { getAccessTokenSilently } = useAuth0()

  const request = async <T>(document: RequestDocument, variables?: Variables): Promise<T> => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        config: config.graphQlAudience,
      },
    })

    client.setHeader('authorization', `Bearer ${token}`)

    return client.request<T>(document, variables)
  }

  return request
}
