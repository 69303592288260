import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { Button, Icon } from 'components/theme'
import Toggle from 'components/theme/form/Toggle'
import NoTechstack from 'views/lawfirms/YourApps/FirmApps/NoTechstack'
import { useTechstack } from 'data/hooks'

interface UserOrgProduct {
  id: number
  name: string
  image: string
}

interface Props {
  onSubmit: (selectedTechstackIds: number[]) => Promise<void>
  userTechstackIds: number[]
}

const UserOrgTechstack: React.FC<Props> = ({ onSubmit, userTechstackIds }) => {
  const orgTechstack = useRef<UserOrgProduct[]>([])
  const [currentTechstack, setCurrentTechstack] = useState<UserOrgProduct[]>([])
  const [userSelected, setUserSelected] = useState(userTechstackIds)
  const [changesMade, setChangesMade] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const [isSelectionOnly, setIsSelectionOnly] = useState(false)
  const { techstack, isLoading: techstackLoading } = useTechstack()

  useEffect(() => {
    if (techstackLoading) return

    const parsedTechstack: UserOrgProduct[] = parseTechstack(techstack)
    const sortedTechstack = sortByName(parsedTechstack)
    orgTechstack.current = sortedTechstack
    setCurrentTechstack(sortedTechstack)
  }, [techstackLoading])

  useEffect(() => {
    let filteredTechstack = orgTechstack.current

    if (isSelectionOnly) {
      filteredTechstack = filteredTechstack.filter(({ id }) =>
        userSelected.some((selectedId) => selectedId === id)
      )
    }

    if (search) {
      filteredTechstack = filteredTechstack.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      )
    }

    setCurrentTechstack(filteredTechstack)
  }, [search, isSelectionOnly, JSON.stringify(userSelected)])

  const updateTechstack = () => {
    setChangesMade(false)
    onSubmit(userSelected)
  }

  const toggleSelection = (toggledId: number) => () => {
    setUserSelected((prevUserSelected) => {
      const index = prevUserSelected.findIndex((prevItem) => prevItem === toggledId)
      if (index === -1) return [...prevUserSelected, toggledId]
      return removeAtIndex(prevUserSelected, index)
    })
    setChangesMade(true)
  }

  const toggleSelectionFlag = () => {
    setIsSelectionOnly((prevVal) => !prevVal)
  }

  return (
    <div>
      <ButtonWrapper>
        <ToggleWrapper>
          <Toggle on={isSelectionOnly} title="Show selected only" toggle={toggleSelectionFlag} />
        </ToggleWrapper>
        <Button onClick={updateTechstack} disabled={!changesMade}>
          Save to My Apps
        </Button>
      </ButtonWrapper>
      <SearchWrapper>
        <Search onChange={(event) => setSearch(event.target.value)} placeholder="Search..." />
      </SearchWrapper>
      <Header>Your Organization’s Tool Box</Header>
      <Products>
        {currentTechstack.map(({ id, name, image }) => (
          <ProductLine key={`${id}-${name}--UserOrgTechstack`} onClick={toggleSelection(id)}>
            <CheckBoxBox>
              {userSelected.some((userTechstackId) => id === userTechstackId) && (
                <CheckIcon name="check" />
              )}
            </CheckBoxBox>
            <Logo src={image} />
            <ProductName>{name}</ProductName>
          </ProductLine>
        ))}
        {!techstackLoading && (!currentTechstack || currentTechstack.length === 0) && (
          <NoTechstackWrapper>
            <NoTechstack />
          </NoTechstackWrapper>
        )}
      </Products>
    </div>
  )
}

const removeAtIndex = (arr: number[], index: number): number[] => {
  const copy = [...arr]
  copy.splice(index, 1)
  return copy
}

const sortByName = (orgTechstack: UserOrgProduct[]) => {
  return [...orgTechstack].sort((a, b) => a.name.localeCompare(b.name))
}

interface Techstacks {
  __typename?: 'Techstack'
  id: number
  product?: {
    __typename?: 'Product'
    id: number
    details: any
    slug: string
    name: string
    termToProducts: Array<{
      __typename?: 'TermToProduct'
      term?: {
        __typename?: 'Term'
        id: number
        name: string
        slug: string
        createdAt: any
        taxonomy?: { __typename?: 'Taxonomy'; id: number; name: string; slug: string }
      }
    }>
  }
}
const parseTechstack = (techstacks?: Techstacks[]): UserOrgProduct[] => {
  if (!techstacks) return []

  return techstacks.map(({ id, product }) => {
    const name = product?.name || ''
    const details = product?.details

    return {
      id,
      name,
      image: details.squareLogoUrl || '',
    }
  })
}

const NoTechstackWrapper = styled.div`
  padding-top: 20px;
`

const ToggleWrapper = styled.div`
  margin-right: 32px;
`

const CheckBoxBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 3px solid #498de6;
  border-radius: 4px;
  margin-right: 16px;
  box-shadow: 0 0 0 0 rgba(73, 141, 230, 0);
  transition: box-shadow 250ms;

  &:hover {
    box-shadow: 0 0 1px 4px rgba(73, 141, 230, 0.4);
  }
`

const CheckIcon = styled(Icon)`
  color: #498de6;
  font-size: 12px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  text-align: right;
`

const Header = styled.div`
  text-align: left;
  letter-spacing: 0.02rem;
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
`

const Products = styled.div``

const ProductLine = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  &:hover {
    background: #fafafa;
  }
`

const ProductName = styled.div`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.02rem;
`

const Logo = styled.img`
  width: 40px;
  margin-right: 16px;
`

const SearchWrapper = styled.div`
  margin-bottom: 20px;
`

const Search = styled.input`
  width: 100%;
  padding: 9.5px 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease, border-color 0.1s ease;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  flex: 1 0 auto;

  &::-webkit-input-placeholder {
    color: #616161;
  }

  &:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

export default UserOrgTechstack
