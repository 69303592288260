import React from 'react'
import FeaturesForm from 'components/forms/product/FeaturesForm'
import { FlexLoading } from 'components/PulsatingLoader'
import _ from 'lodash'
import { FormDescription } from 'components/theme/form'
import { useProductSettings } from '../useProductSettings'
import VendorUpgradeModal from 'components/modals/VendorUpgradeModal'

function FeaturesSettings() {
  const { product, loading, onComplete, showModal, toggleModal } = useProductSettings()

  const initialValues = {
    features: _.get(product, 'details.features', []),
  }

  if (loading) return <FlexLoading />

  return (
    <>
      <FormDescription>This will display in our comparison engine</FormDescription>
      <FeaturesForm initialValues={initialValues} onComplete={onComplete} product={product} />
      <VendorUpgradeModal showModal={showModal} toggleModal={toggleModal} />
    </>
  )
}

export default FeaturesSettings
