import { RouteObject } from 'react-router-dom'
import { OrganizationType, UserRole } from 'generated/graphql'
import SettingsLayout from './SettingsLayout'

import vendorSettingRoutes from './vendorSettingRoutes'
import companySettingRoutes from './companySettingRoutes'
import lawfirmSettingRoutes from './lawfirmSettingRoutes'
import associationSettingRoutes from './associationSettingRoutes'

const baseRoute: RouteObject = {
  path: '/',
  element: <SettingsLayout />,
}

export interface MenuRoute extends RouteObject {
  text: string
  roleAccess?: UserRole[]
}

const generateRoute =
  (viewerRole?: UserRole) =>
  (routes: MenuRoute[]): RouteObject[] => {
    const childrenRoutes: RouteObject[] = routes
      .filter(({ roleAccess }) => {
        if (!roleAccess) return true

        return roleAccess.some((role) => role === viewerRole)
      })
      .map(({ path, element, index }) => ({
        path,
        element,
        index,
      }))
    return [
      {
        ...baseRoute,
        children: childrenRoutes,
      },
    ]
  }

export default (organizationType?: OrganizationType, viewerRole?: UserRole) => {
  const generateRouteByRole = generateRoute(viewerRole)

  switch (organizationType) {
    case OrganizationType.Vendor:
      return generateRouteByRole(vendorSettingRoutes)
    case OrganizationType.Company:
      return generateRouteByRole(companySettingRoutes)
    case OrganizationType.Lawfirm:
      return generateRouteByRole(lawfirmSettingRoutes)
    case OrganizationType.Association:
      return generateRouteByRole(associationSettingRoutes)
  }

  return generateRouteByRole(vendorSettingRoutes)
}
