import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { MemberStatus, UserRole } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  userId?: number
  role?: UserRole
  status?: MemberStatus
  organizationId?: string
}
export const useAddUserToOrg = () => {
  const request = useGql()
  const { organization } = useOrg()
  const { viewer } = useViewer()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { userId, role, status, organizationId } = variables

      await request(ADD_USER_TO_ORG, {
        userId: userId || viewer.id,
        role,
        status,
        organizationId: organizationId || organization?.id,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['viewer']),
    }
  )
}

const ADD_USER_TO_ORG = gql`
  mutation AddUserToOrg(
    $userId: Int!
    $organizationId: UUID!
    $role: UserRole = USER
    $status: MemberStatus = ACTIVE
  ) {
    upsertUsersToOrganization(
      input: {
        usersToOrganization: {
          userId: $userId
          organizationId: $organizationId
          role: $role
          status: $status
        }
      }
    ) {
      clientMutationId
    }
  }
`
