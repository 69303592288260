import styled from '@emotion/styled'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Icon } from 'components/theme'
import { useWizardModal, WizardStep } from './WizardModalContext'

const WizardSidebar = ({ steps }: { steps: WizardStep[] }) => {
  const { stepIndex, goToStep } = useWizardModal()

  const onItemClick = (stepItemIndex: number) => () => {
    if (stepIndex <= stepItemIndex) return
    goToStep(stepItemIndex)
  }

  return (
    <Root>
      {steps.map((step, index) => (
        <SidebarItem
          key={index}
          onClick={onItemClick(index)}
          currentStep={stepIndex === index}
          isCheck={stepIndex > index}
        >
          <Checkbox isCheck={stepIndex > index}>
            <CheckIcon name={faCheckCircle} ischeck={(stepIndex > index).toString()} />
          </Checkbox>
          <Right>
            <Title>{step.title}</Title>
            {step.subtitle && <Subtitle>{step.subtitle}</Subtitle>}
          </Right>
        </SidebarItem>
      ))}
    </Root>
  )
}

const Root = styled.div`
  background: #f4f4f4;
  padding: 40px 20px 20px;
  min-width: 240px;
`

const SidebarItem = styled.div<{ currentStep: boolean; isCheck: boolean }>`
  margin-bottom: 20px;
  display: flex;
  transition: opacity 0.3s ease;
  ${({ currentStep }) => !currentStep && 'opacity: .5;'}
  ${({ isCheck }) =>
    isCheck &&
    `
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  `}
`

const Checkbox = styled.div<{ isCheck: boolean }>`
  width: 16px;
  height: 16px;
  ${({ isCheck }) => !isCheck && 'border: 1px solid #707070;'}
  border-radius: 100px;
  transform: translateY(3px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const CheckIcon = styled(Icon)<{ ischeck: string }>`
  font-size: 17px;
  color: ${({ ischeck }) => (ischeck === 'true' ? '#8423F4' : 'transparent')};
`

const Right = styled.div`
  padding-left: 8px;
`

const Title = styled.div`
  color: #3b3b3b;
  font-size: 16px;
  font-weight: bold;
`

const Subtitle = styled.div`
  color: #7b7b7b;
  font-size: 14px;
`

export default WizardSidebar
