import React from 'react'
import { Form, Formik } from 'formik'
import { TextInput, FormField, Label, Dropdown } from 'components/theme/form'
import Button from 'components/theme/Button'
import ResourceField from './ResourcesField'
import PromptIfDirty from 'components/forms/PromptIfDirty'
import { useMutateProductDetails } from 'data/hooks'
import styled from '@emotion/styled'
import * as yup from 'yup'
import { useViewer } from 'context/viewerContext'

const defaultValues = {
  phone: '',
  emailSupport: '',
  linkedin: '',
  facebook: '',
  twitter: '',
  privacyPolicyUrl: '',
  publicTermsUrl: '',
  signInUrl: '',
  resources: [],
}

const validationSchema = yup.object().shape({
  phone: yup.string().nullable(),
  emailSupport: yup.string().nullable(),
  emailInquiry: yup.string().nullable(),
  linkedin: yup.string().nullable(),
  facebook: yup.string().nullable(),
  twitter: yup.string().nullable(),
  signInUrl: yup.string().nullable(),
})

const ctaTypeOptions = [
  { key: 'demo', text: 'Request Demo', value: 'demo' },
  { key: 'contact', text: 'Contact Us', value: 'contact' },
  { key: 'custom', text: 'Custom', value: 'custom' },
]

function ContactInfoForm({
  initialValues = defaultValues,
  product,
  submitButtonText = 'UPDATE',
  onComplete,
  isService,
}: any) {
  const mutateProductDetails = useMutateProductDetails()
  const { paymentStatus } = useViewer()

  const onSubmit = async ({
    phone,
    emailSupport,
    linkedin,
    facebook,
    twitter,
    signInUrl,
    emailInquiry,
    resources,
    sidebarCta,
  }: any) => {
    if (!product) return

    const details = {
      ...product?.details,
      phone,
      emailSupport,
      emailInquiry,
      linkedin,
      facebook,
      twitter,
      signInUrl,
      resources,
      sidebarCta,
    }
    await mutateProductDetails.mutateAsync({
      productId: product.id,
      details,
    })

    onComplete(product.id)
  }

  const isActivePayment = paymentStatus === 'ACTIVE'
  // const isActivePayment = true

  return (
    <Root>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ values, setFieldValue, isSubmitting, isValid }) => (
          <Form>
            <PromptIfDirty />
            {isActivePayment && (
              <>
                <FormField>
                  <FormField>
                    <>
                      <Label>Sidebar CTA Type</Label>
                      <Dropdown
                        selection
                        fluid
                        style={{ minWidth: 190 }}
                        name="buttonType"
                        onChange={(_e: any, { value }: any) => {
                          setFieldValue(`sidebarCta.type`, value)
                          console.log(value)
                          if (value === 'demo') {
                            console.log(value)
                            setFieldValue(`sidebarCta.text`, 'Demo Request')
                          } else if (value === 'contact') {
                            setFieldValue(`sidebarCta.text`, 'Contact')
                          } else if (value === 'custom') {
                            setFieldValue(`sidebarCta.text`, '')
                          }
                        }}
                        value={values.sidebarCta?.type}
                        options={ctaTypeOptions}
                      />
                    </>
                  </FormField>
                  {values.sidebarCta?.type === 'custom' && (
                    <FormField>
                      <Flex>
                        <Half>
                          <TextInput label="CTA Text" name={`sidebarCta.text`} />
                        </Half>
                        <Half>
                          <TextInput label="CTA Link" name={`sidebarCta.link`} />
                        </Half>
                      </Flex>
                    </FormField>
                  )}
                </FormField>
                <Line />
              </>
            )}
            <FormField>
              <TextInput label="Phone Number" placeholder="(xxx)xxx-xxxx" name="phone" />
            </FormField>
            <FormField>
              <TextInput
                label="Email Support"
                placeholder="support@example.com"
                name="emailSupport"
              />
            </FormField>
            <FormField>
              <TextInput
                label="Forward inquiries to"
                placeholder="inquiry@example.com"
                name="emailInquiry"
                helpContent="Inquiries (Requests for demo and contact) will be forwarded to this address"
              />
            </FormField>
            <FormField>
              <TextInput
                label="LinkedIn"
                placeholder="https://www.linkedin.com/company/{Public ID}"
                name="linkedin"
              />
            </FormField>
            <FormField>
              <TextInput
                label="Facebook"
                placeholder="https://www.facebook.com/{Public ID}"
                name="facebook"
              />
            </FormField>
            <FormField>
              <TextInput
                label="Twitter"
                placeholder="https://twitter.com/{Public ID}"
                name="twitter"
              />
            </FormField>
            {!isService && (
              <FormField>
                <TextInput
                  label="Sign In URL"
                  placeholder="https://example.com/sign-in"
                  name="signInUrl"
                />
              </FormField>
            )}
            <FormField>
              <ResourceField value={values.resources} setFieldValue={setFieldValue} />
            </FormField>
            <FormField>
              <ButtonContainer>
                <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                  {submitButtonText}
                </Button>
              </ButtonContainer>
            </FormField>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const Flex = styled.div`
  display: flex;
  margin: 0 -8px;
`

const Half = styled.div`
  width: 50%;
  padding: 0 8px;
`

const Root = styled.div`
  width: 100%;
  max-width: 760px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

const Line = styled.hr`
  border: none;
  border-bottom: 1px solid #bbb;
  margin-bottom: 12px;
`

export default ContactInfoForm
