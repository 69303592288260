import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { format, parseISO, isBefore } from 'date-fns'
import type { UserProfileTechstack, User } from 'interfaces/user/user.interface'
import Avatar from '../Avatar'
import { Button, Icon } from 'components/theme'
import { OrganizationType } from 'generated/graphql'
import { useOrg } from 'data/organization/useOrg'

type SortableAttributes = 'name' | 'lastLogin' | 'category' | 'logins'

const Techstack: React.FC<{ data: UserProfileTechstack[] }> = ({ data }) => {
  const [sortedTech, setSortedTech] = useState<UserProfileTechstack[]>(data)
  const { organization } = useOrg()

  useEffect(() => {
    const startSort = organization?.type === OrganizationType.Association ? 'name' : 'lastLogin'
    sortTechBy(startSort)
  }, [])

  const sortByString = (prop: string, a: UserProfileTechstack, b: UserProfileTechstack) => {
    if (a[prop] < b[prop]) return -1
    if (a[prop] > b[prop]) return 1
    return 0
  }

  const sortByNumber = (prop: string, a: UserProfileTechstack, b: UserProfileTechstack) => {
    if (a[prop] < b[prop]) return 1
    if (a[prop] > b[prop]) return -1
    return 0
  }

  const sortByDate = (a: UserProfileTechstack, b: UserProfileTechstack) => {
    const dateA = parseISO(a.lastLogin)
    const dateB = parseISO(b.lastLogin)
    if (isBefore(dateA, dateB)) return 1
    if (isBefore(dateB, dateA)) return -1
    return sortByString('name', a, b)
  }

  const sortByTerm = (taxId: number, a: UserProfileTechstack, b: UserProfileTechstack) => {
    const termA = a.terms.find(({ taxonomyId }) => taxonomyId === taxId)?.name
    const termB = b.terms.find(({ taxonomyId }) => taxonomyId === taxId)?.name
    if (!termA && !termB) return sortByString('name', a, b)
    if (!termA) return -1
    if (!termB) return 1
    if (termA < termB) return -1
    if (termA > termB) return 1
    return sortByString('name', a, b)
  }

  const sortTechBy = (prop: SortableAttributes) => {
    const tech = sortedTech.length ? sortedTech : data
    const sorted = [...tech].sort((a, b) => {
      switch (prop) {
        case 'lastLogin':
          return sortByDate(a, b)
        case 'category':
          return sortByTerm(14, a, b)
        case 'logins':
          return sortByNumber(prop, a, b)
        default:
          return sortByString(prop, a, b)
      }
    })
    setSortedTech(sorted)
  }

  const printStack = (stack: UserProfileTechstack[]) => {
    return stack.map(({ id, name, slug, details, logins, lastLogin, terms }, i) => (
      <ListRow key={`product-${id}-${i}`}>
        <Product>
          <Avatar
            user={
              {
                details: { imageUrl: details.squareLogoUrl || details.logoUrl },
              } as unknown as User
            }
            color="white"
          />
          <ProductName>{name}</ProductName>
        </Product>
        <td>{terms.find(({ taxonomyId }) => taxonomyId === 14)?.name}</td>
        {organization?.type !== 'ASSOCIATION' ? (
          <>
            <td>{format(parseISO(lastLogin), 'MMM dd, yyyy')}</td>
            <td>{logins || '0'}</td>
          </>
        ) : (
          <td>
            <SmallButton
              component="a"
              href={`https://ecosystem.theoremlegal.com/product/${slug}`}
              target="_blank"
            >
              <StoreIcon name="store" /> Marketplace
            </SmallButton>
          </td>
        )}
      </ListRow>
    ))
  }

  const HeadCell: React.FC<{ name: string; attribute: SortableAttributes }> = ({
    name,
    attribute,
  }) => {
    return (
      <Th onClick={() => sortTechBy(attribute)}>
        {name}
        <Arrow role="button" name="sort-down" />
      </Th>
    )
  }

  return (
    <ScrollWrap extended={sortedTech.length > 11}>
      <Table>
        <thead>
          <tr>
            <HeadCell name="Product" attribute="name" />
            <HeadCell name="Category" attribute="category" />
            {organization?.type !== 'ASSOCIATION' ? (
              <>
                <HeadCell name="Last Login" attribute="lastLogin" />
                <HeadCell name="Logins" attribute="logins" />
              </>
            ) : (
              <Th />
            )}
          </tr>
        </thead>
        <tbody>{printStack(sortedTech)}</tbody>
      </Table>
    </ScrollWrap>
  )
}

const Th = styled.th`
  text-align: left;
  letter-spacing: 0.02rem;
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
`

const Arrow = styled(Icon)`
  cursor: pointer;

  color: #5a5a5a;
  margin: -6px 0 0 4px;
`

const ScrollWrap = styled.div<{ extended: boolean }>`
  padding: 30px;
  ${({ extended }) =>
    extended
      ? `
    overflow: scroll;
    max-height: 600px;
    overflow-x: hidden;
  `
      : ''}
`

const StoreIcon = styled(Icon)`
  margin-right: 4px;
`

const SmallButton = styled(Button)`
  margin-left: 8px;
  padding: 4px 16px 4px 8px;
`

const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
`

const ListRow = styled.tr`
  border-bottom: 1px solid #eee;
`

const Product = styled.td`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  > * {
    margin: 8px;
  }
`

const ProductName = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.02rem;
`

export default Techstack
