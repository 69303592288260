import { useQuery } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlOrganizationListingByAssocIdQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useOrganizationListing = (associationId?: string) => {
  const request = useGql()
  const { organization } = useOrg()
  const { data, ...other } = useQuery(
    ['orgListing', organization?.id, associationId],
    async () => {
      const response = await request<GqlOrganizationListingByAssocIdQuery>(HAS_ORG_LISTINGS, {
        organizationId: organization?.id,
        associationId,
      })

      if (!response.organizationListings || response.organizationListings.length === 0) return

      return response.organizationListings[0]
    },
    {
      staleTime: 60 * 60 * 1000,
      enabled: !!associationId,
    }
  )

  return { orgListing: data, ...other }
}

const HAS_ORG_LISTINGS = gql`
  query OrganizationListingByAssocId($organizationId: UUID!, $associationId: UUID!) {
    organizationListings(
      condition: { organizationId: $organizationId, associationId: $associationId }
      filter: { connectPurchaseId: { isNull: false } }
    ) {
      associationId
      association {
        slug
        name
        details
        id
      }
      details
      description
      excerpt
      organization {
        details
        id
        name
        slug
        type
      }
    }
  }
`
