import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { newDeletedList } from 'services/helper'

interface Variables {
  clients: number[]
  initialValue: number[]
}

export const useMutateClients = (currentProductId: number) => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { clients, initialValue } = variables

      const [newClients, deletedClients] = newDeletedList(initialValue, clients)

      for (const clientId of newClients) {
        await request(CREATE_PRODUCT_TO_CLIENT, {
          clientId,
          productId: currentProductId,
        })
      }

      for (const clientId of deletedClients) {
        console.log({ clientId, currentProductId })
        await request(DELETE_PRODUCT_TO_CLIENT, {
          clientId,
          productId: currentProductId,
        })
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

const CREATE_PRODUCT_TO_CLIENT = gql`
  mutation CreateProductToClient($productId: Int!, $clientId: UUID!) {
    createProductToClient(
      input: { productToClient: { productId: $productId, clientId: $clientId } }
    ) {
      clientMutationId
    }
  }
`

const DELETE_PRODUCT_TO_CLIENT = gql`
  mutation DeleteProductToClient($productId: Int!, $clientId: UUID!) {
    deleteProductToClient(input: { productId: $productId, clientId: $clientId }) {
      clientMutationId
    }
  }
`
