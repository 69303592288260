import styled from '@emotion/styled'
import UserMenuDropdown from './UserMenuDropdown'
import UserMenuTrigger from './UserMenuTrigger'

const UserMenu = () => {
  return (
    <Root>
      <UserMenuTrigger />
      <UserMenuDropdown />
    </Root>
  )
}

const Root = styled.div`
  border-left: 1px solid #e8e8e8;

  &:hover {
    & > div:first-of-type {
      background: #eee;
    }

    & > div:nth-of-type(2) {
      display: block;
    }
  }
`

export default UserMenu
