import React from 'react'
import { Formik, Form } from 'formik'
import styled from '@emotion/styled'
import { TrueFalse } from 'components/theme/form'
import Button from 'components/theme/Button'
import _ from 'lodash'
import PrevalentLogoImg from 'assets/prevalent/prevalent-logo.svg'
import SigLiteLogo from 'assets/prevalent/sig-lite.svg'
import SigCoreLogo from 'assets/prevalent/sig-core.svg'
import moment from 'moment'
import { useAlgolia } from 'hooks/useAlgolia'
import { useLazyApi } from 'hooks/useApi'
import { useWizardContext } from '../prevalentContext'
import { useOrg } from 'data/organization/useOrg'
import PulsatingLoader from 'components/PulsatingLoader'
import { useMutateProductDetails } from 'data/hooks'

const InfoStep = () => {
  const { wizardData, updateWizardData, nextStep, loading } = useWizardContext()
  const mutateProductDetails = useMutateProductDetails()
  const { vendor } = useOrg()
  const { partialUpdateObject } = useAlgolia()
  const [prevalentCheckout] = useLazyApi('stripe/connect/prevalent-checkout')
  const selectedProductId = _.get(wizardData, '[1].formData.productName[0].value', {})

  const customerEmail = _.get(wizardData, '[0].formData.email', '')
  const purchaseSigLite = _.get(wizardData, '[0].formData.sigLite', false)
  const purchaseSigCore = _.get(wizardData, '[0].formData.sigCore', false)
  const paymentId = _.get(wizardData, '[2].formData.paymentId', '')
  const coupon = _.get(wizardData, '[2].formData.couponCode', '')
  const discount = _.get(wizardData, '[2].formData.discount', 0)

  const planCost = calculateCost(purchaseSigLite, purchaseSigCore, discount)
  const renewalDate = getRenewalDate()

  const product = _.find(vendor?.products, { id: selectedProductId })

  const onSuccess = async () => {
    const emailDetails = getEmailDetails(wizardData)

    await prevalentCheckout({
      customer_email: customerEmail,
      payment_id: paymentId,
      purchase_sig_lite: purchaseSigLite,
      purchase_sig_core: purchaseSigCore,
      email_details: JSON.stringify(emailDetails),
      coupon,
    })

    await mutateProductDetails.mutateAsync({
      productId: selectedProductId,
      details: {
        ...product?.details,
        prevalent: {
          purchasedPrevalent: true,
          sigCore: {
            purchaseDate: '',
            isPurchased: purchaseSigCore,
            status: purchaseSigCore ? 'PENDING' : 'INACTIVE',
          },
          sigLite: {
            purchaseDate: '',
            isPurchased: purchaseSigLite,
            status: purchaseSigLite ? 'PENDING' : 'INACTIVE',
          },
        },
      },
    })

    await partialUpdateObject({
      id: selectedProductId,
      sigLite: purchaseSigLite,
      sigCore: purchaseSigCore,
    })

    updateWizardData({})
    nextStep()
  }

  return (
    <>
      {loading ? (
        <PulsatingLoader />
      ) : (
        <Root>
          <Formik initialValues={{ terms: false }} onSubmit={onSuccess}>
            {({ values, isSubmitting }) => (
              <Form>
                <h3>Review your purchase</h3>
                <Grid>
                  <GridHeader>Product</GridHeader>
                  <GridItem>
                    <PlanLogos>
                      {purchaseSigCore && <ProductLogo src={SigCoreLogo} />}
                      {purchaseSigLite && <ProductLogo src={SigLiteLogo} />}
                    </PlanLogos>
                  </GridItem>

                  <GridHeader>Offer from</GridHeader>
                  <GridItem>
                    <PrevalentLogo src={PrevalentLogoImg} />
                  </GridItem>
                  <GridHeader>Cost</GridHeader>
                  <GridItem>
                    <PlanCost>${planCost}</PlanCost>
                  </GridItem>

                  <GridHeader>Renewal</GridHeader>
                  <GridItem>
                    <PlanInterval>{renewalDate}</PlanInterval>
                  </GridItem>
                </Grid>

                <TermsAgreement>
                  <TermsText>
                    I have read, understand, and accept the{' '}
                    <a
                      href="https://www.theoremlegal.com/legal"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Theorem Customer Terms of Service
                    </a>
                    ;{' '}
                    <a
                      href="https://www.theoremlegal.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Theorem Privacy Policy
                    </a>
                    ; and the{' '}
                    <a
                      href="https://www.prevalent.net/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Prevalent Terms and conditions
                    </a>
                    . By clicking Purchase below, I agree that Theorem is authorized to charge me
                    for all fees due during the Subscription Term and any renewal term. I certify
                    that I authorize to sign and enter into this binding legal contract for the
                    company or organization making this purchase.
                  </TermsText>
                  <TrueFalse name="terms" text="I agree to these terms." />
                </TermsAgreement>

                <Button
                  type="submit"
                  disabled={!values.terms || isSubmitting}
                  loading={isSubmitting}
                >
                  Purchase
                </Button>
              </Form>
            )}
          </Formik>
        </Root>
      )}
    </>
  )
}

const getRenewalDate = () => {
  return `${moment().format('MMMM Do YYYY')} - ${moment().add(1, 'year').format('MMMM Do YYYY')}`
}

const calculateCost = (purchaseSigLite: any, purchaseSigCore: any, discount = 0) => {
  let cost = 0
  if (purchaseSigLite) cost += 250
  if (purchaseSigCore) cost += 500

  const discountedCost = cost - (discount / 100) * cost

  return discount ? (
    <>
      {discountedCost} <SaveText>(you saved {discount}%)</SaveText>
    </>
  ) : (
    discountedCost
  )
}

const getEmailDetails = (wizardData: any) => {
  const userDetails = _.get(wizardData, '[1].formData', {})

  return {
    ...userDetails,
    productName: _.get(userDetails, 'productName[0].label', ''),
  }
}

const Root = styled.div`
  margin: 0 auto;
  max-width: 550px;
`

const TermsText = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 1.5;
`

const ProductLogo = styled.img`
  max-width: 120px;
  width: 100%;
  padding: 0 10px;
`

const PrevalentLogo = styled.img`
  width: 100%;
  max-width: 140px;
`

const PlanLogos = styled.div`
  display: flex;
  justify-content: center;
`

const PlanInterval = styled.div``

const PlanCost = styled.div`
  font-weight: 500;
  color: #555;
`

const TermsAgreement = styled.div`
  margin-bottom: 28px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  margin-bottom: 24px;
`

const GridHeader = styled.div`
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
`

const GridItem = styled.div`
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
`

const SaveText = styled.div`
  font-style: italic;
  color: #4caf50;
`

export default InfoStep
