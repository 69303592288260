import styled from '@emotion/styled'

export const BoxWrapper = styled.div`
  position: relative;
  background: white;
  border-radius: 6px;
  padding: 40px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`
