import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'

function ChartCard({ title = '', ctx }: { title: string, ctx: any }) {
  return (
    <Root>
      <Card>
        {title && <Title>{title}</Title>}
        <Canvas ref={ctx} />
      </Card>
    </Root>
  )
}

const Root = styled.div`
  width: 50%;
  padding: 10px;

  ${breakpoint.tablet} {
    width: 100%;
  }
`

const Canvas = styled.canvas`
  max-width: 100%;
`

const Card = styled.div`
  padding: 20px;
  border: 1px solid #aaa;
  border-radius: 6px;
  background: #fdfdfd;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`

export default ChartCard
