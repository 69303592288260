import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useUpdateLogins, useUserToTechstack } from 'data/hooks'

function AppBoxTooltip({
  appId,
  name,
  signInUrl = '',
  toggleModal = null,
  addExtraItems = false,
}: any) {
  const { userToTechstacks } = useUserToTechstack()
  const updateLogins = useUpdateLogins()

  const [logins, setLogins] = useState<number>(0)
  const [techstackId, setTechstackId] = useState<number>()

  useEffect(() => {
    if (!userToTechstacks) return
    if (!userToTechstacks.length) return
    const stack = userToTechstacks.find(({ techstack }) => techstack?.product?.id === appId)
    if (!stack) return
    const {
      logins,
      techstack: { id },
    } = stack
    if (isNaN(logins)) return
    setLogins(logins)
    setTechstackId(id)
  }, [JSON.stringify(userToTechstacks)])

  const clickToUpdate = () => {
    if (!techstackId) return
    updateLogins.mutate(
      { logins: logins, techstackId },
      {
        onSuccess: () => setLogins((prev: number) => prev + 1),
      }
    )
  }

  return (
    <Root>
      <TopItem>{name}</TopItem>
      {signInUrl && (
        <ItemLink href={signInUrl} target="_blank" onClick={clickToUpdate}>
          Sign In
        </ItemLink>
      )}
      <ItemLink
        href="https://airtable.com/embed/appT1qb4jWHcJDwF2/shrdVdxkew8dWXCcj?backgroundColor=cyan"
        target="_blank"
      >
        Review Vendor
      </ItemLink>
      {addExtraItems && (
        <>
          <Item>Add to my apps</Item>
          <Item>Test Drive</Item>
        </>
      )}
    </Root>
  )
}

const Root = styled.div``

const TopItem = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid #888;
  background: #eee;
  font-weight: 700;
  letter-spacing: 0.25px;
`

const Item = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  background: white;
  transition: background 0.3s;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;

  &:hover {
    background: #eee;
    color: #4183c4;
  }
`

const ItemLink = styled.a`
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  background: white;
  transition: background 0.3s;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;

  &:hover {
    background: #eee;
  }
`

export default AppBoxTooltip
