import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
import MenuItem from './MenuItem'
import MenuOutLink from './MenuOutLink'
import SecondaryMenu from './SecondaryMenu'
import MarketPlaceMenu from './MarketplaceMenu'

interface Props {
  closeMobileMenu: () => void
}
const VendorMenu = ({ closeMobileMenu }: Props) => {
  const baseOrgUrl = useBaseOrgUrl()
  const { isSuperAdmin } = useViewer()
  const marketplaceUrl = useMarketplaceUrl(true)

  let productsSecondaryPages = [
    { title: 'Products', link: `${marketplaceUrl}returnTo=/admin/product`, isOutLink: true },
    { title: 'Analytics', link: `${baseOrgUrl}/analytics` },
    { title: 'Deals', link: `${baseOrgUrl}/test-drive` },
  ]

  if (isSuperAdmin) {
    productsSecondaryPages = [
      ...productsSecondaryPages,
      { title: 'Super Admin', link: `${baseOrgUrl}/edit-products` },
    ]
  }

  return (
    <Root>
      <MenuLi>
        <MenuOutLink href={`${marketplaceUrl}returnTo=/admin/listings`} onClick={closeMobileMenu}>
          Listings <DownCaret name="caret-down" />
        </MenuOutLink>
        <SecondaryMenu
          closeMobileMenu={closeMobileMenu}
          pages={[
            {
              title: 'Listings',
              link: `${marketplaceUrl}returnTo=/admin/listings`,
              isOutLink: true,
            },
          ]}
        />
      </MenuLi>
      <MenuLi>
        <MenuOutLink href={`${marketplaceUrl}returnTo=/admin/product`} onClick={closeMobileMenu}>
          Products <DownCaret name="caret-down" />
        </MenuOutLink>
        <SecondaryMenu closeMobileMenu={closeMobileMenu} pages={productsSecondaryPages} />
      </MenuLi>
      <MenuLi>
        <MenuItem to={`${baseOrgUrl}/rfp-inbox`} onClick={closeMobileMenu}>
          RFP
        </MenuItem>
      </MenuLi>
      <MenuLi>
        <MenuOutLink href={`${marketplaceUrl}`}>
          Marketplace <DownCaret name="caret-down" />
        </MenuOutLink>
        <MarketPlaceMenu />
      </MenuLi>
      {/* !accountId && (
        <MenuLi>
          <MenuItem to={`${baseOrgUrl}/upgrade/setup/1`} onClick={closeMobileMenu}>
            Payments
          </MenuItem>
        </MenuLi>
      ) */}
    </Root>
  )
}

const Root = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: block;
    width: 100%;
  }
`

const DownCaret = styled(Icon)`
  font-size: 16px;
  margin-left: 4px;
`

const MenuLi = styled.li`
  position: relative;

  &:hover {
    & > a:after {
      transform: scaleY(1);
    }

    & > div:first-of-type {
      display: block;
    }
  }

  ${({ theme }) => theme.breakpoint.mobile} {
    &:first-of-type a {
      border-top: 0;
    }
  }
`

export default VendorMenu
