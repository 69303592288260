import styled from '@emotion/styled'
import { BoxWrapper, Button } from 'components/theme'
import { useListings, useOrg } from 'data/hooks'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Products from 'views/vendors/products/Products'
import ProductListingNetworks from '../ProductListingNetworks'
// import MarketplaceSellerAgreement from 'views/vendors/VendorProfile/MarketplaceSellerAgreement'
import NoListing from './NoListing'

interface Listing {
  id: number | string
  network: string
  networkSlug: string
  name: string
  listingType: string
  type: string
  status: string
  show: boolean
}

const ProductListingList = () => {
  const { vendor, lawfirm, organization } = useOrg()
  const { productListings, organizationListings, isLoading } = useListings()
  const baseOrgUrl = useBaseOrgUrl()
  const [listings, setListings] = useState<Listing[]>([])

  const products = vendor ? vendor.products : lawfirm && lawfirm.products
  const hasProductListing = productListings && productListings.length > 0

  useEffect(() => {
    if (isLoading) return
    let listing: Listing[] = []

    if (organization && organizationListings && organizationListings?.length > 0) {
      const parsedOrganizationListing = organizationListings.map(
        ({ association, status, type, show }) => ({
          id: organization.id,
          name: organization.name,
          listingType: 'COMPANY',
          network: association.name,
          networkSlug: association.slug,
          type,
          status,
          show,
        })
      )

      listing = [...listing, ...parsedOrganizationListing]
    }

    if (productListings && productListings?.length > 0) {
      const parsedProductListing = productListings.map(
        ({ association, product, type, show, state }) => ({
          id: product.id,
          name: product.name,
          type,
          network: association.name,
          networkSlug: association.slug,
          listingType: product.type,
          status: state,
          show,
        })
      )

      listing = [...listing, ...parsedProductListing]
    }

    setListings(listing)
  }, [isLoading])

  console.log(listings)

  return (
    <Root>
      {!isLoading && products && products.length === 0 && <Products />}

      {!isLoading && products && products.length > 0 && !hasProductListing && (
        <BoxWrapper>
          <NoListing />
        </BoxWrapper>
      )}

      {products && products.length > 0 && hasProductListing && (
        <BoxWrapper>
          <ButtonWrapper>
            <Title>Active Listings</Title>
            <Button component="link" to={`${baseOrgUrl}/product-listing/networks`}>
              Create New Listing
            </Button>
          </ButtonWrapper>
          <Table>
            <thead>
              <TopRow>
                <th>Listing</th>
                <th>What's Listed?</th>
                <th>Type</th>
                <th>Enhance</th>
                <th>Status</th>
                <th>Show</th>
                <th>Actions</th>
              </TopRow>
            </thead>
            <tbody>
              {listings?.map(({ id, name, type, status, network, show }) => (
                <tr key={id}>
                  <td>{name}</td>
                  <td>{network}</td>
                  <td>{type}</td>
                  <td>{status}</td>
                  <td>{show}</td>
                  <td>
                    <ActionsWrapper>
                      <ActionButton to={`${baseOrgUrl}/product-listing/${id}`}>view</ActionButton>{' '}
                      <ActionButton to={`${baseOrgUrl}/product/settings/${id}`}>edit</ActionButton>
                    </ActionsWrapper>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <ProductListingNetworks />
        </BoxWrapper>
      )}
    </Root>
  )
}

const Title = styled.h3`
  margin-bottom: 8px;
`

const TopRow = styled.tr`
  background: #f5f5f5;

  th {
    text-align: left;
    font-weight: 500;
  }
`

const Root = styled.div``

const Table = styled.table`
  border-collapse: collapse;
  max-width: 760px;
  width: 100%;
  border: 1px solid #ddd;
  border-top: 2px solid #9013fe;
  border-radius: 4px;

  th,
  td {
    padding: 12px 16px;
  }

  th {
    border-bottom: 1px solid #d1d1d1;
  }
`

const ActionsWrapper = styled.div`
  display: flex;
`

const ActionButton = styled(Link)`
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 4px 8px;
  margin-right: 8px;
  color: #333;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: right;
  margin-bottom: 20px;
`

export default ProductListingList
