import styled from '@emotion/styled'
import LogoutButton from 'components/theme/LogoutButton'
import theoremLogoPng from 'assets/purple-logo.png'

const SetupHeader = () => {
  return (
    <Root>
      <Logo src={theoremLogoPng} />
      <LogoutButton />
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
`

const Logo = styled.img`
  width: 32px;
  margin-top: 20px;
  margin-left: 20px;
`

export default SetupHeader
