import React, { useEffect } from 'react'
import { useField } from 'formik'
import styled from '@emotion/styled'

const Switch = ({ disabled = false, ...props }: any) => {
  const [, meta, helpers] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''

  useEffect(() => {
    if (disabled) {
      helpers.setValue(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  const toggleCheck = ({ target }: any) => {
    if (disabled) {
      helpers.setValue(false)
    } else {
      helpers.setValue(target.checked)
    }
  }

  return (
    <>
      <Wrapper isChecked={meta.value}>
        <CheckboxInput type="checkbox" checked={meta.value} onChange={toggleCheck} />
        <SwitchToggle isChecked={meta.value} />
      </Wrapper>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </>
  )
}

const ErrorText = styled.div`
  color: #f44336;
  font-weight: 400;
  margin-top: 6px;
`

const SwitchToggle = styled.div<{ isChecked: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 400px;
  background: ${(props) => (props.isChecked ? '#4cd0d1' : '#b69bc7')};
  transform: translateX(${(props) => (props.isChecked ? '20px' : '0')});
  transition: background 350ms, transform 250ms;
`

const Wrapper = styled.label<{ isChecked: boolean }>`
  width: 48px;
  display: block;
  background: ${(props) => (props.isChecked ? '#ececec' : '#dadada')};
  cursor: pointer;
  border-radius: 400px;
  border: 2px solid #888;
  padding: 2px;
  transition: background 250ms;

  &:hover {
    background: #ccc;

    ${SwitchToggle} {
      background: ${(props) => (props.isChecked ? '#1e9d9e' : '#a273bf')};
    }
  }
`

const CheckboxInput = styled.input`
  display: none;
`

export default Switch
