import styled from '@emotion/styled'
import { Formik } from 'formik'
import Button from 'components/theme/Button'
import { useTrackingChartContext } from 'context/trackingChartContext'
import DatePicker from './DatePicker'

function OptionsBar() {
  const { startDate, endDate, updateCharts } = useTrackingChartContext()

  const onSubmit = ({ startDate, endDate }: any) => {
    updateCharts({ startDate, endDate })
  }

  return (
    <Formik
      ignoreLoading
      initialValues={{ startDate, endDate }}
      onSubmit={onSubmit}
      validateOnMount
    >
      {() => (
        <Form>
          <DatePicker label="Start Date" name="startDate" />
          <DatePicker label="End Date" name="endDate" />
          <SubmitButton type="submit">Submit</SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

const SubmitButton = styled(Button)`
  margin-left: auto;
`

const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 24px;
`

export default OptionsBar
