import { useOrg } from 'data/organization/useOrg'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { newDeletedList } from 'services/helper'

interface Variables {
  integrationIds: number[]
  initialIntegrationIds: number[]
}
export const useMutateIntegrations = (productId: number) => {
  const request = useGql()
  const queryClient = useQueryClient()
  const { organization } = useOrg()

  return useMutation(
    async (variables: Variables) => {
      const { integrationIds, initialIntegrationIds } = variables

      const [newIntegrationIds, deletedIntegrationIds] = newDeletedList(
        initialIntegrationIds,
        integrationIds
      )

      await request(CREATE_PRODUCT_TO_INTEGRATIONS, {
        integrationIds: newIntegrationIds,
        productId,
        organizationId: organization?.id,
      })
      await request(DELETE_PRODUCT_TO_INTEGRATIONS, {
        integrationIds: deletedIntegrationIds,
        productId,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

const CREATE_PRODUCT_TO_INTEGRATIONS = gql`
  mutation CreateProductToIntegrations(
    $integrationIds: [Int!]!
    $productId: Int!
    $organizationId: UUID!
  ) {
    createProductToIntegrations(
      input: {
        integrationIds: $integrationIds
        productId: $productId
        organizationId: $organizationId
      }
    ) {
      clientMutationId
    }
  }
`

const DELETE_PRODUCT_TO_INTEGRATIONS = gql`
  mutation DeleteProductToIntegrations($integrationIds: [Int!]!, $productId: Int!) {
    deleteProductToIntegrations(input: { integrationIds: $integrationIds, productId: $productId }) {
      clientMutationId
    }
  }
`
