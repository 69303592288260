import { useState } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { useOrg } from 'data/organization/useOrg'
import { OrganizationType } from 'generated/graphql'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Link } from 'react-router-dom'
import SecondaryMenu from './GlobalMenu/SecondaryMenu'
import MarketplaceDropdown from './MarketplaceDropdown'
import InviteModal from 'components/modals/InviteModal'
import ReactDOM from 'react-dom'

const MenuIcons = () => {
  const [isInviteModal, setIsInviteModal] = useState(false)
  const [showMarketplaceDropdown, setShowMarketplaceDropdown] = useState(false)
  const baseOrgUrl = useBaseOrgUrl()
  const { organization } = useOrg()

  const isAllowInviteTeam =
    (organization?.type !== OrganizationType.Personal &&
      organization?.type !== OrganizationType.Association) ||
    organization?.type === OrganizationType.Association

  const toggleMarketplaceDropdown = () => {
    setShowMarketplaceDropdown((prevVal) => !prevVal)
  }

  return (
    <Root>
      {/*
      <IconWrapper showMobile>
        <MenuIcon name="bell" />
      </IconWrapper>
      */}
      {isAllowInviteTeam && (
        <IconWrapper onClick={() => setIsInviteModal(true)}>
          <MenuIcon name="user-plus" />
        </IconWrapper>
      )}
      <IconWrapper>
        <MenuIcon name="cog" />
        <IconLink to={`${baseOrgUrl}/settings`} />
      </IconWrapper>
      {organization?.type !== OrganizationType.Vendor && (
        <ClickWrapper onClick={toggleMarketplaceDropdown} showMobile>
          <MenuIcon name="store" />
          <MarketplaceDropdown isShow={showMarketplaceDropdown} />
        </ClickWrapper>
      )}
      <IconWrapper>
        <MenuIcon name="question-circle" />
        <IconAnchor href="https://share.hsforms.com/1mi8ic8YSRxGWt-8MP4E0PA3p9i3" target="_blank" />
        <SecondaryMenu
          pages={[
            { title: 'Getting Started', link: `${baseOrgUrl}/getting-started` },
            {
              title: 'Visit Help Center',
              link: 'https://help.theoremlegal.com/kb/en',
              isOutLink: true,
            },
            {
              title: 'Contact Support',
              link: 'https://share.hsforms.com/1mi8ic8YSRxGWt-8MP4E0PA3p9i3',
              isOutLink: true,
            },
          ]}
          isAlignRight
        />
      </IconWrapper>
      <InviteModal
        open={isInviteModal}
        onModalClose={() => {
          setIsInviteModal(false)
        }}
      />

      {showMarketplaceDropdown
        ? ReactDOM.createPortal(<ClickOutMenu onClick={toggleMarketplaceDropdown} />, document.body)
        : null}
    </Root>
  )
}

const linkWrapperStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const ClickOutMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: tranparent;
  z-index: 900;
`

const IconLink = styled(Link)`
  ${linkWrapperStyles}
`

const IconAnchor = styled.a`
  ${linkWrapperStyles}
`

const Root = styled.div`
  display: flex;
  padding-right: 4px;
`

const MenuIcon = styled(Icon)`
  color: #46484d;
  height: 20px !important;
  width: auto !important;
  transition: 250ms color;
`

const IconWrapper = styled.div<{ showMobile?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  text-align: center;
  height: ${({ theme }) => theme.topBarHeight};
  transition: 250ms background;
  cursor: pointer;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: ${({ showMobile }) => (showMobile ? 'flex' : 'none')};
  }

  &:hover {
    background: #eee;

    & > div:first-of-type {
      display: block;
      text-align: left;
    }

    ${MenuIcon} {
      color: ${({ theme }) => theme.color.primaryPurple};
    }
  }
`

const ClickWrapper = styled.div<{ showMobile?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  text-align: center;
  height: ${({ theme }) => theme.topBarHeight};
  transition: 250ms background;
  cursor: pointer;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: ${({ showMobile }) => (showMobile ? 'flex' : 'none')};
  }

  &:hover {
    background: #eee;

    ${MenuIcon} {
      color: ${({ theme }) => theme.color.primaryPurple};
    }
`

export default MenuIcons
