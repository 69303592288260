import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { UnderlinedTabCss } from '@theoremlts/theorem-design'
import { Tooltip } from 'components/theme'

const HowTo: React.FC = () => {
  const [from, setFrom] = useState<'EXISTING' | 'SAMPLE'>('EXISTING')

  useEffect(() => {
    // a quick option while waiting for library updates
    const existing = document.getElementById('EXISTING')
    const sample = document.getElementById('SAMPLE')
    console.log({ existing, sample })
    if (!existing || !sample) return
    if (from === 'EXISTING') {
      existing.classList.add('active')
      sample.classList.remove('active')
    } else {
      existing.classList.remove('active')
      sample.classList.add('active')
    }
  }, [from])

  return (
    <>
      <Nav>
        <Tab onClick={() => setFrom('EXISTING')} id="EXISTING">
          From Existing Csv
        </Tab>
        <Tab onClick={() => setFrom('SAMPLE')} id="SAMPLE">
          From Sample Csv
        </Tab>
      </Nav>
      <Content>
        {from === 'EXISTING' && (
          <ol>
            <li>Open the CSV you'd like to upload</li>
            <li>
              Make sure you're using the following column names. <b>Product Name</b> is required,
              any other names will be ignored:
              <ul>
                <li>Product Name</li>
                <li>Contract Value</li>
                <li>Number of Seats</li>
                <li>
                  <Tooltip tooltip="Must be formatted as 'yyyy-mm-dd">Renewal Date</Tooltip>
                </li>
                <li>
                  <Tooltip
                    tooltip={
                      <>
                        Accepted <b>Stages</b> are:
                        <br /> Active, Investigation, Under Review, Pilot, Contract Review, Practice
                        Rollout, and Region Rollout
                      </>
                    }
                  >
                    Stage
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    tooltip={
                      <>
                        Accepted values for <b>Access</b> are:
                        <br /> User, Organization, Review, Authorized, and Hide
                      </>
                    }
                  >
                    Access
                  </Tooltip>
                </li>
              </ul>
            </li>
            <li>
              In the Theorem App, hover over <b>CSV Actions</b>, select <b>Upload CSV</b>, choose
              your file
            </li>
          </ol>
        )}
        {from === 'SAMPLE' && (
          <ol>
            <li>
              Under <b>CSV Actions</b> select <b>Download Sample CSV</b>
            </li>
            <li>Add your data to the sample CSV and save it</li>
            <li>
              Under <b>CSV Actions</b> select <b>Upload CSV</b> and select your file
            </li>
          </ol>
        )}
      </Content>
    </>
  )
}

const Tab = styled.div`
  ${UnderlinedTabCss}
`

const Nav = styled.nav`
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #e9e9ed;
  padding-top: 32px;
  border-bottom: 2px solid #9e9e9e;
`

const Content = styled.div`
  padding: 12px 20px 32px 0;
  font-size: 16px;
  line-height: 1.6;

  li {
    margin-bottom: 4px;
  }

  ul {
    padding-top: 4px;
    padding-bottom: 8px;

    & > li {
      margin-bottom: 0;
    }
  }
`

export default HowTo
