import { useEffect } from 'react'
import styled from '@emotion/styled'
import Button from 'components/theme/Button'
import { BoxWrapper } from 'components/theme'
import MarketLaunchSvg from './market-launch.svg'
import { useLazyApi } from 'hooks/useApi'
import { useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { useRedirect } from 'hooks/useRedirect'

function VendorCheckoutSuccess() {
  const redirect = useRedirect()
  const { organization } = useOrg()
  const queryClient = useQueryClient()
  const [createSubscription] = useLazyApi('stripe/organization/handle-checkout')

  useEffect(() => {
    ;(async () => {
      const sessionId = getQueryParameter('session_id')

      // if (sessionId) localStorage.setItem('stripeCheckoutSessionId', sessionId) // store session id if still loading

      if (!sessionId) return

      await createSubscription({ organizationId: organization?.id, sessionId })

      localStorage.removeItem('stripeCheckoutSessionId')

      queryClient.invalidateQueries(['viewer'])

      redirect('upgrade/success')
    })()
  }, [])

  return (
    <BoxWrapper>
      <Container>
        <Title>Thank you for upgrading to PRO!</Title>
        <SubTitle>Finish setting up your account</SubTitle>
        <Image src={MarketLaunchSvg} />
        <LinkLine>
          <SetUpButton onClick={() => redirect('listing-stepper')}>Set Up Listings</SetUpButton>
        </LinkLine>
      </Container>
    </BoxWrapper>
  )
}

const getQueryParameter = (param: string) =>
  new URLSearchParams(document.location.search.substring(1)).get(param)

const LinkLine = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`

const SetUpButton = styled(Button)`
  background-color: #498de6;
  color: white;
  margin-right: 16px;

  &:hover {
    color: #498de6;
    background-color: white;
  }
`

const Image = styled.img`
  width: 350px;
`

const Title = styled.h1`
  color: #512da8;
  font-size: 30px;
  margin: 8px 0;
  font-family: 'Roboto', san-serif;
`

const SubTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export default VendorCheckoutSuccess
