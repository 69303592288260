import { Routes, Route } from 'react-router-dom'

import PaymentOptions from 'views/personal/payment/PaymentOptions'
import SuccessfulCheckout from 'views/personal/payment/SuccessfulCheckout'
import YourApps from 'views/personal/YourApps'

import UpdatePersonalUser from 'views/personal/UpdatePersonalUser'
import UpgradePersonal from 'views/personal/UpgradePersonal'
import DiscoverOrganizations from 'components/DiscoverOrganization'

function PersonalRoutes() {
  return (
    <Routes>
      <Route path="payment-options" element={<PaymentOptions />} />
      <Route path="checkout-success" element={<SuccessfulCheckout />} />
      <Route path="/" element={<UpdatePersonalUser />} />
      <Route path="your-apps" element={<YourApps />} />
      <Route path="settings" element={<UpdatePersonalUser />} />
      <Route path="/personal/discover-organizations" element={<DiscoverOrganizations />} />
      <Route path="upgrade" element={<UpgradePersonal />} />
    </Routes>
  )
}

export default PersonalRoutes
