import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  termIds: number[]
  testDriveId: number
}
export const useCreateTermsToTestDrive = () => {
  const request = useGql()

  return useMutation(async (variables: Variables) => {
    const { termIds, testDriveId } = variables
    await request(CREATE_TERMS_TO_TEST_DRIVE, { termIds, testDriveId })
  })
}

const CREATE_TERMS_TO_TEST_DRIVE = gql`
  mutation CreateTermsToTestDrive($termIds: [Int!]!, $testDriveId: Int!) {
    createTermsToTestDrive(input: { termIds: $termIds, testDriveId: $testDriveId }) {
      clientMutationId
    }
  }
`
