import { USER_CORE_FRAGMENT } from 'data/fragments/user'
import { ORG_CORE_FRAGMENT, VIEWER_ORG_FRAGMENT } from 'data/fragments/organization'
import { USER_TO_ORG_CORE_FRAGMENT } from 'data/fragments/userToOrganization'
import { PRODUCT_CORE_FRAGMENT, VIEWER_PRODUCT_FRAGMENT } from 'data/fragments/product'
import { TERM_CORE_FRAGMENT } from 'data/fragments/term'
import { gql } from 'graphql-request'

export const VIEWER_GQL = gql`
  query Viewer {
    viewer {
      ...UserCore
      appMetadata
      organizationByCurrentOrganization {
        ...CurrentOrg
      }
      location {
        id
        city
        country
        state
      }
      usersToOrganizations(condition: { status: ACTIVE }) {
        ...UserToOrgCore
        organization {
          ...OrgCore
          usersToOrganizations {
            status
            role
          }
        }
      }
      usersToTechstacks {
        logins
        updatedAt
        techstack {
          product {
            ...ProductCore
            termToProducts {
              term {
                ...TermCore
              }
            }
          }
          organizationId
          id
        }
      }
      termToUsers {
        term {
          ...TermCore
        }
        termId
      }
      personalTechstacks {
        productId
        product {
          ...ProductCore
        }
      }
    }
  }

  fragment CurrentOrg on Organization {
    ...OrgCore
    ...ViewerOrg
  }

  ${USER_CORE_FRAGMENT}
  ${ORG_CORE_FRAGMENT}
  ${VIEWER_ORG_FRAGMENT}
  ${VIEWER_PRODUCT_FRAGMENT}
  ${PRODUCT_CORE_FRAGMENT}
  ${USER_TO_ORG_CORE_FRAGMENT}
  ${TERM_CORE_FRAGMENT}
`
