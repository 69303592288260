import { useOrg } from 'data/organization/hooks'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'
import { TEAM_FRAGMENT } from './useTeam'
import { GqlGetAllTeamQuery, MemberStatus, UserRole } from 'generated/graphql'

interface UseAllTeam {
  status?: MemberStatus
  role?: UserRole
}

export const useAllTeam = ({ status, role }: UseAllTeam = {}) => {
  const request = useGql()
  const { organization } = useOrg()

  const { data, ...other } = useQuery(['team', status || 'ALL', role || 'ALL'], async () => {
    if (!organization) return

    const response = await request<GqlGetAllTeamQuery>(GET_WHOLE_TEAM, {
      organizationId: organization.id,
      status,
      role,
    })

    if (!response.usersToOrganizations || response.usersToOrganizations?.length === 0) return

    const team = response.usersToOrganizations.map(({ userId, user, role, status }) => ({
      userId,
      role,
      status,
      first: user?.first,
      last: user?.last,
      name: `${user?.first} ${user?.last}`,
      email: user?.email,
    }))

    return team
  })

  return { team: data, ...other }
}

const GET_WHOLE_TEAM = gql`
  ${TEAM_FRAGMENT}
  query GetAllTeam($organizationId: UUID!, $status: MemberStatus, $role: UserRole) {
    usersToOrganizations(
      condition: { organizationId: $organizationId, status: $status, role: $role }
    ) {
      ...TeamFragment
    }
  }
`
