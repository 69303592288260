import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import LOGO from 'assets/purple-logo.png'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'components/theme'
import { logEvent } from '@theoremlts/theorem-design'

export default function WithGreyHeader({
  children,
  title = '',
  subTitle = '',
  className = null,
  addBackLink = false,
}: any) {
  const navigate = useNavigate()
  const { logout } = useAuth0()

  return (
    <div className={className}>
      <HeaderContainer>
        <LogoutButton
          onClick={() => {
            logEvent('log_out')
            logout()
          }}
        >
          Logout
        </LogoutButton>
        {addBackLink && (
          <BackButton onClick={() => navigate(-1)} size="large" name="chevron left" />
        )}
        <StyledImage src={LOGO} />
        <Header>{title}</Header>
        <SubHeader>{subTitle}</SubHeader>
      </HeaderContainer>
      <Root>{children}</Root>
    </div>
  )
}

const BackButton = styled(Icon)`
  cursor: pointer !important;
  position: absolute !important;
  left: 16px !important;
  top: 70px !important;
`

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1024px;
  height: 100%;
  margin: auto;
  margin-top: 12px;
  padding: 16px;
  justify-content: center;
  align-items: center;
`

const Header = styled.h2`
  color: #49575d;
  margin-bottom: 5px;
`

const SubHeader = styled.h4`
  margin: 0 0 10px !important;
  font-weight: 300;
  color: #595f63;
`

const HeaderContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background-color: #f5f8fa;
  align-items: center;
  padding: 16px;
`

const LogoutButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const StyledImage = styled.img`
  max-width: 100px;
`
