import styled from '@emotion/styled'

export const ErrorText = styled.div`
  color: #f44336;
  font-weight: 400;
  margin-top: 6px;
`

export const FormTitle = styled.h3`
  margin: 0 0 16px;
  font-size: 1.6em;
`

export const Description = styled.p`
  font-size: 15px;
  margin-bottom: 28px;
`
