import { useEffect, useState } from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'

import FoundOrganization from 'views/setup/FoundOrganization'
import SelectProducts from 'views/setup/vendor/CheckMatchedProducts'
import CreateUser from 'views/setup/lawfirm/CreateUser'
import CreateLawfirm from 'views/setup/lawfirm/CreateLawfirm'
import CreateVendor from 'views/setup/vendor/CreateVendor'
import CreateVendorUser from 'views/setup/vendor/CreateUser'
import CreateProductWizard from 'views/vendors/CreateProductWizard'

import CreatePersonal from 'views/setup/personal/CreatePersonal'
import CreatePersonalTechStack from 'views/setup/personal/CreateTechStack'
import PersonalCheckout from 'views/setup/personal/PersonalCheckout'
import PersonalSuccess from 'views/setup/personal/PersonalSuccess'

import LawfirmPlans from 'views/setup/lawfirm/LawfirmMarketingPlans'
import LawfirmCheckout from 'views/setup/lawfirm/LawfirmStripeCheckout'
import RequestInvite from 'views/setup/lawfirm/RequestInvite'
import PersonalPlans from 'views/setup/personal/PersonalPlans'
import LawfirmUserTechstack from 'views/setup/lawfirm/LawfirmUserTechstack'
import LawfirmPersonalTechstack from 'views/setup/lawfirm/LawfirmPersonalTechstack'
import AssociationUser from 'views/setup/association/AssociationUser'
import AssociationStack from 'views/setup/association/AssociationStack'
import InviteScreen from 'views/InviteScreen'
import { useViewer } from 'context/viewerContext'
import { OrganizationType, UserStatus } from 'generated/graphql'
import { useOrg } from 'data/organization/useOrg'
import VendorToAssocSuccess from 'views/setup/vendor/VendorToAssocSuccess'
import OrgSetupSelect from 'views/setup/OrgSetupSelect'
import CompanySetupForm from 'views/setup/company/CompanySetupForm'
import TermsAndConditions from 'views/setup/TermsAndConditions'
import OrgToNetworkSuccess from 'views/setup/association/OrgToNetworkSuccess'

// Here are the possible setup combination
// 1. Vendor
//    a. New user no products
//      - Type: Owner
//      - Routes: [role-check, vendor-user-account, vendor-organization, product, integrations]
//      - New Organization
//
//    b. New user with Domain Match (if ever this happens)
//      - Type: Owner
//      - Routes: [select-organization, vendor-user-account, vendor-organization, product, integrations]
//
//    c. New user with products matching domain
//      - Type: Owner
//      - Routes: [select-product, vendor-user-account, vendor-organization, product, integrations]
//      - New Organization
//
//    d. Second user match domain -- domain match screen
//      - Type: User
//      - Routes: [select-organization, user-account]
//
//
// 2. Lawfirm
//    a. New user no match
//      - Type: Owner
//      - Routes: [role-check, user-account, lawfirm-organization, select-products]
//      - New Organization
//
//    b. New user match domain -- domain match screen
//      - Type: Owner
//      - Routes: [select-organization, lawfirm-user-account, lawfirm-organization, select-products]
//
//    c. Second user match domain -- domain match screen
//      - Type: User
//      - Routes: [select-organization, lawfirm-user-account, select-products]
//
//
// 3. Personal
//    a. New user no match
//      - Type: Personal
//      - Routes: [role-check, user-account, select-products]
//
//    b. Second user match personal Group -- domain match screen
//      - Type: Personal

function SetupRoutes() {
  const { viewer } = useViewer()
  const { organization } = useOrg()
  const [redirect, setRedirect] = useState('')

  useEffect(() => {
    if (viewer.status === UserStatus.Active) {
      if (organization?.type === OrganizationType.Lawfirm) {
        setRedirect(`/lawfirm/${organization?.id}`)
      } else if (organization?.type === OrganizationType.Vendor) {
        setRedirect(`/vendor/${organization?.id}`)
      } else if (organization?.type === OrganizationType.Association) {
        setRedirect(`/association/${organization?.id}`)
      } else {
        setRedirect('/personal')
      }
    }
  }, [])

  if (redirect) {
    return <Navigate to={redirect} />
  }

  return (
    <Routes>
      <Route path="role-check" element={<OrgSetupSelect />} />
      <Route path="match-products" element={<SelectProducts />} />
      <Route path="match-organization" element={<FoundOrganization />} />
      <Route path="create-user" element={<CreateUser />} />
      <Route path="lawfirm-user" element={<CreateUser />} />
      <Route path="lawfirm-organization" element={<CreateLawfirm />} />
      <Route path="lawfirm-personal-stack" element={<LawfirmPersonalTechstack />} />
      <Route path="lawfirm-user-techstack" element={<LawfirmUserTechstack />} />
      <Route path="lawfirm-plans" element={<LawfirmPlans />} />
      <Route path="lawfirm-checkout" element={<LawfirmCheckout />} />
      <Route path="lawfirm-checkout" element={<LawfirmCheckout />} />
      <Route path="vendor-user" element={<CreateVendorUser />} />
      <Route path="vendor-organization" element={<CreateVendor />} />
      <Route path="product" element={<CreateProductWizard />} />
      <Route path="company-user" element={<CreateUser />} />
      <Route path="company-organization" element={<CompanySetupForm />} />
      <Route path="personal-plans" element={<PersonalPlans />} />
      <Route path="personal-checkout" element={<PersonalCheckout />} />
      <Route path="personal-payment" element={<PersonalCheckout />} />
      <Route path="personal-user" element={<CreatePersonal />} />
      <Route path="personal-techstack" element={<CreatePersonalTechStack />} />
      <Route path="invite" element={<InviteScreen />} />
      <Route path="request" element={<RequestInvite />} />
      <Route path="association-user" element={<AssociationUser />} />
      <Route path="association-stack" element={<AssociationStack />} />
      <Route path="vendor-to-network-success" element={<VendorToAssocSuccess />} />
      <Route path="network-success" element={<OrgToNetworkSuccess />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="join" element={<InviteScreen />} />
      <Route path="personal-success/:sessionId" element={<PersonalSuccess />} />
    </Routes>
  )
}

export default SetupRoutes
