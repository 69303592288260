import { useOrg } from 'data/organization/hooks'
import { GqlGetActiveAssocUsersQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'
import { Term, User, AssociationTechstack } from 'interfaces'
import { ProductData } from 'interfaces/graphql'

export const useAssociationTechstack = () => {
  const { organization } = useOrg()
  const request = useGql()

  const { data, ...other } = useQuery(
    ['activeAssocUsers'],
    async () => {
      const response = await request<GqlGetActiveAssocUsersQuery>(GET_ACTIVE_ASSOC_USERS, {
        organizationId: organization?.id,
      })

      const usersTechstack = parseUsersTechstack(response)

      return usersTechstack
    },
    { staleTime: 60 * 60 * 1000 }
  )

  return { techstack: data, ...other }
}

const parseUsersTechstack = (data: any): AssociationTechstack[] => {
  // move user to organization
  const usersTechstack: UsersTechstackData[] = data.usersToOrganizations.map(({ user }: any) => {
    if (!user) return
    const personalTechstack = user.personalTechstacks.map(
      ({ product }: { product: ProductData }) => ({
        ...product,
        practiceGroups: parsePracticeGroups(product),
        roles: parseRoles(product),
      })
    )
    const otherOrgs = user.usersToTechstacks.map(
      ({ techstack: { product } }: { techstack: { product: ProductData } }) => ({
        ...product,
        practiceGroups: parsePracticeGroups(product),
        roles: parseRoles(product),
      })
    )
    const combinedProducts = [...personalTechstack, ...otherOrgs]
    const uniqueProducts = [
      ...new Map(combinedProducts.map((product) => [product.id, product])).values(),
    ]

    return { user, products: uniqueProducts }
  })

  const techstackList = organizeByProducts(usersTechstack)

  return techstackList
}

interface TechstackProductData extends ProductData {
  practiceGroups: Term[]
  roles: Term[]
}

interface UsersTechstackData {
  user: User
  products: TechstackProductData[]
}

const parsePracticeGroups = (product: ProductData) => {
  const practiceGroups = product.termToProducts
    .filter(({ term }) => term.taxonomyId === 13)
    .map(({ term }) => term)
  return practiceGroups
}

const parseRoles = (product: ProductData) => {
  const roles = product.termToProducts
    .filter(({ term }) => term.taxonomyId === 8)
    .map(({ term }) => term)
  return roles
}

const organizeByProducts = (usersTechstack: UsersTechstackData[]): AssociationTechstack[] => {
  const techstackMap = new Map()
  for (let i = 0; i < usersTechstack.length; ++i) {
    const { user, products } = usersTechstack[i]

    for (let x = 0; x < products.length; ++x) {
      if (techstackMap.has(products[x].id)) {
        const existingTechstackItem = techstackMap.get(products[x].id)
        techstackMap.set(products[x].id, {
          ...existingTechstackItem,
          users: [...existingTechstackItem.users, user],
        })
      } else {
        techstackMap.set(products[x].id, {
          product: products[x],
          practiceGroups: products[x].practiceGroups,
          roles: products[x].roles,
          users: [user],
        })
      }
    }
  }

  const techstackList = [...techstackMap.values()]

  return techstackList
}

const PRODUCT_FRAGMENT = gql`
  fragment ProductFields on Product {
    details
    name
    id
    slug
    excerpt
    termToProducts {
      term {
        id
        name
        taxonomy {
          id
          name
          slug
        }
        slug
        taxonomyId
      }
    }
    testDrives {
      id
      details
      termId
      termToTestDrives {
        term {
          id
          name
          slug
          taxonomyId
          taxonomy {
            id
            name
            slug
          }
        }
      }
    }
  }
`

export const GET_ACTIVE_ASSOC_USERS = gql`
  ${PRODUCT_FRAGMENT}
  query GetActiveAssocUsers($organizationId: UUID) {
    usersToOrganizations(condition: { status: ACTIVE, organizationId: $organizationId }) {
      role
      jobTitle
      userId
      user {
        email
        first
        id
        last
        createdAt
        updatedAt
        details
        personalTechstacks {
          product {
            ...ProductFields
          }
        }
        testMode
        usersToTechstacks {
          techstackId
          techstack {
            productId
            product {
              ...ProductFields
            }
          }
        }
      }
    }
  }
`
