import React, { useState } from 'react'
import styled from '@emotion/styled'
import ImageLinkBox from 'components/ImageLinkBox'
import { Popup } from 'semantic-ui-react'
import moment from 'moment'
import { useOrg } from 'data/organization/useOrg'
import { useViewer } from 'context/viewerContext'
import { BoxWrapper } from 'components/theme'
import NoProducts from './NoProducts'
import ProductTypeModal from 'components/modals/ProductTypeModal'

// TODO: this needs to be refacored
const AppBox = ({ product }: any) => {
  const { paymentStatus, accountId } = useViewer()

  const getState = () => {
    if (paymentStatus !== 'ACTIVE') return 'Needs upgrade'
    if (!accountId) return 'Finish payment setup'
    return 'Active'
  }

  return (
    <Popup
      key={`products-${product.id}`}
      trigger={
        <div>
          <StyledImageLinkBox
            id={product.id}
            image={product.details.squareLogoUrl || product.details.logoUrl}
            name={product.name}
            url={`https://ecosystem.theoremlegal.com/product/${product.slug}`}
          />
        </div>
      }
    >
      <Popup.Content>
        <div>
          <strong>State:</strong> {getState()}
        </div>
        <div>
          <strong>Created at:</strong> {moment(product.createdAt).format('l')}
        </div>
      </Popup.Content>
    </Popup>
  )
}

function Products() {
  const { isSuperAdmin } = useViewer()
  const { vendor, lawfirm } = useOrg()
  const [showModal, setShowModal] = useState(false)

  const products = vendor ? vendor.products : lawfirm && lawfirm.products
  //TODO: This needs to filter out products that are still in DRAFT

  return (
    <>
      {isSuperAdmin && (
        <Description>
          As a <b>theoremlegal</b> user, products you create here will be in <b>test mode</b>. Use
          the admin tools to create live listings.
        </Description>
      )}
      <BoxWrapper>
        {products && products.length === 0 && <NoProducts />}
        {products && products.length > 0 && (
          <ProductsWrapper>
            {products.map((product) => {
              return (
                <AppBoxContainer key={product.id}>
                  <AppBox product={product} />
                </AppBoxContainer>
              )
            })}
            <Popup trigger={<ProductsLink onClick={() => setShowModal(true)}>+</ProductsLink>}>
              <Popup.Content>Create new product or service</Popup.Content>
            </Popup>
          </ProductsWrapper>
        )}
      </BoxWrapper>
      {showModal && (
        <ProductTypeModal toggleModal={() => setShowModal((prevVal: boolean) => !prevVal)} />
      )}
    </>
  )
}

export const BoxTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #545454;
  max-width: 1200px;
  margin: 0 auto 20px;
`

export const Description = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  color: #636363;
`
const StyledImageLinkBox = styled(ImageLinkBox)`
  margin-bottom: 10px;
`

const AppBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ProductsLink = styled.div`
  border: 2px dashed #d3ced6;
  color: #8f5dd2;
  line-height: 1;
  width: 140px;
  height: 164px;
  min-height: 164px;
  border-radius: 4px;
  font-size: 30px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: #8f5dd2;
  }
`

const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default Products
