import { useQuery } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlOrgProductsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useOrgProducts = () => {
  const request = useGql()
  const { organization } = useOrg()
  const { data, ...other } = useQuery(
    ['orgProducts', organization?.id],
    async () => {
      if (!organization) return
      const response = await request<GqlOrgProductsQuery>(ORG_PRODUCTS, {
        organizationId: organization?.id,
      })

      return response.productToOrganizations.map(({ product }) => product)
    },
    { enabled: !!organization?.id }
  )

  return { products: data, ...other }
}

const ORG_PRODUCTS = gql`
  query OrgProducts($organizationId: UUID!) {
    productToOrganizations(condition: { organizationId: $organizationId }) {
      product {
        createdAt
        description
        details
        excerpt
        id
        name
        show
        slug
        state
        status
        type
        updatedAt
        productListings {
          associationId
          createdAt
          details
          id
          show
          state
          type
          updatedAt
          theoremPurchaseId
          connectPurchaseId
          appMetadata
          association {
            auth0OrgId
            id
            details
            name
            slug
            subtype
            type
          }
        }
      }
      productId
    }
  }
`
