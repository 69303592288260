import { BoxWrapper } from 'components/theme'
import { Route, Routes } from 'react-router'
import Checkout from './Checkout'
import ProductListingEditor from './ProductListingEditor'
import ProductListingList from './ProductListingList'
import ProductListingNetworks from './ProductListingNetworks'
import ProductSelector from './ProductSelector'

const ProductListing = () => {
  return (
    <Routes>
      <Route path="/" element={<ProductListingList />} />
      <Route
        path="networks"
        element={
          <BoxWrapper>
            <ProductListingNetworks />
          </BoxWrapper>
        }
      />
      <Route path="checkout/*" element={<Checkout />} />
      <Route path="product-select" element={<ProductSelector />} />
      <Route path=":productListingId" element={<ProductListingEditor />} />
    </Routes>
  )
}

export default ProductListing
