import SalesModal from './SalesModal'
import { useNavigate } from 'react-router-dom'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useTestDrive } from 'data/hooks'

interface Props {
  price: string
  priceId: string
  stripeProductId: string
  image: string
  productName: string
  isShowing: boolean
  toggleModal: () => void
  features: string[]
}

function PrevalentSalesModal({
  isShowing,
  toggleModal,
  image,
  priceId,
  stripeProductId,
  price,
  productName,
  features,
}: Props) {
  const baseOrgUrl = useBaseOrgUrl()
  const { testDrive, isLoading } = useTestDrive(8)
  const navigate = useNavigate()

  const onClick = () => {
    navigate(`${baseOrgUrl}/prevalent/plan-details`, {
      state: {
        productId: testDrive?.product.id,
        stripeProductId: stripeProductId || '',
        priceId: priceId || '',
      },
    })
  }

  if (isLoading) return <div>loading</div>

  const product = testDrive?.product

  if (!product) return <div>loading</div>

  const highlights = []

  if (product.excerpt && product.excerpt.length > 0) {
    highlights.push({
      name: 'Description',
      description: product.excerpt,
    })
  }

  if (product.bestFor && product.bestFor.length > 0) {
    highlights.push({
      name: 'Best For',
      description: product.bestFor?.join(', ') || '',
    })
  }

  if (product.practiceGroups && product.practiceGroups.length > 0) {
    highlights.push({
      name: 'Practice Group',
      description: product.practiceGroups?.join(', ') || '',
    })
  }

  return (
    <SalesModal
      isShowing={isShowing}
      toggleModal={toggleModal}
      title={`Purchase ${productName}`}
      subtitle={`Price from ${price}`}
      iconImage={image}
      videoUrl={product.details.demoVideo || ''}
      imageUrl={product.details.slides?.[0] || ''}
      features={{
        title: `Everything you get with your purchase`,
        items: features || [],
      }}
      noteText=""
      highlights={highlights}
      buttonText="Purchase"
      onClick={onClick}
    />
  )
}

export default PrevalentSalesModal
