import React, { useState } from 'react'
import styled from '@emotion/styled'
import UserRoleDropdown from 'components/modals/UserRoleDropdown'
import { MemberStatus, UserRole } from 'generated/graphql'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { useUpdateUserRole } from 'data/hooks'

interface Member {
  id: number
  role: UserRole
  status: MemberStatus
  jobTitle: string
  email: string
  name: string
  headshot: string
  activity: string
}

interface Props {
  member: Member
}
const UserRoleSelect: React.FC<Props> = ({ member }) => {
  const { viewer } = useViewer()
  const { organization } = useOrg()
  const updateUserRole = useUpdateUserRole()
  const [role, setRole] = useState(member.role)

  const onChange = async (value: UserRole) => {
    await updateUserRole.mutateAsync({
      userId: member.id,
      role: value,
    })

    setRole(value)
  }

  return (
    <Root>
      {viewer.role === UserRole.User || // cant change yourself
      member.role === UserRole.Owner || // cant change owner
      (member.role === UserRole.SuperAdmin && // cant change super admin
        organization?.id !== 'dc6d31c7-40b1-4616-9d00-36695e03d515') ? (
        member.role
      ) : (
        <UserRoleDropdown onChange={onChange} role={role} />
      )}
    </Root>
  )
}

const Root = styled.div`
  cursor: default;
`

export default UserRoleSelect
