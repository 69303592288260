import { newDeletedList } from 'services/helper'
import { useCreateTermsToTestDrive, useDeleteTermsToTestDrive } from 'data/hooks'

export const useTestDriveTaxonomy = () => {
  const createTermsToTestDrive = useCreateTermsToTestDrive()
  const deleteTermsToTestDrive = useDeleteTermsToTestDrive()

  const updateTermToTaxonomy = async (
    terms: number[],
    initialValue: number[],
    testDriveId: number
  ) => {
    const [newTerms, deletedTerms] = newDeletedList(initialValue, terms)

    await createTermsToTestDrive.mutateAsync({
      termIds: newTerms,
      testDriveId,
    })

    await deleteTermsToTestDrive.mutateAsync({
      termIds: deletedTerms,
      testDriveId,
    })
  }

  return { updateTermToTaxonomy }
}
