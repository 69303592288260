import React, { useState } from 'react'
import { format } from 'date-fns'
import styled from '@emotion/styled'
import UserStory from 'interfaces/userStory/UserStory'
import { Icon } from 'components/theme'

const FoldoutStory: React.FC<{ Story: UserStory; open?: boolean }> = ({ Story, open = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(open)

  return (
    <>
      <TitleBar isOpen={isOpen} onClick={() => setIsOpen((prev) => !prev)}>
        <OpenIcon name="chevron-right" isOpen={isOpen} />
        {Story.product?.logoUrl && (
          <img src={Story.product.logoUrl} alt={`${Story.product.name}s logo`} />
        )}
        <TitleText>
          <ProductName>{Story.product?.name}</ProductName>
          <DateText>{Story.updatedAt && format(Story.updatedAt, 'MMM dd, yyyy')}</DateText>
        </TitleText>
      </TitleBar>
      <Content isOpen={isOpen}>
        <Story.PrintElement showEditButtons={isOpen} withPills />
      </Content>
    </>
  )
}

const TitleText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ProductName = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.02rem;
`

const DateText = styled.div`
  opacity: 0.8;
`

const OpenIcon = styled(Icon)<{ isOpen: boolean }>`
  margin-right: 8px;
  cursor: pointer;
  transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : ``)};
  transition: transform 500ms;
`

const Content = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  overflow: hidden;
  width: 100%;
  border-bottom: ${({ isOpen }) => (isOpen ? '1px solid #ddd' : 'none')};
  padding: 20px;
`

const TitleBar = styled.div<{ isOpen: boolean }>`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  background: ${({ isOpen }) => (isOpen ? '#efefef' : '#fff')};

  > img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`

export default FoldoutStory
