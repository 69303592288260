import React, { createContext, useState, useContext, useEffect } from 'react'
import moment from 'moment'

export const TrackingChartContext = createContext({} as any)

// TODO: add a total count, unique count
interface Props {
  children: React.ReactNode
}
export const TrackingChartProvider = ({ children }: Props) => {
  const [productToAnalyze, setProductToAnalyze] = useState(0)
  const [currentLoadingQueue, setCurrentLoadingQueue] = useState(0)
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').toDate())
  const [endDate, setEndDate] = useState(moment().toDate())

  const updateCharts = ({ startDate, endDate }: any) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  useEffect(() => {
    if (currentLoadingQueue > 3) {
      setCurrentLoadingQueue(0)
    }
  }, [startDate, endDate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentLoadingQueue(0)
  }, [productToAnalyze])

  return (
    <TrackingChartContext.Provider
      value={{
        productToAnalyze,
        setProductToAnalyze,
        startDate,
        endDate,
        updateCharts,
        currentLoadingQueue,
        setCurrentLoadingQueue,
      }}
    >
      {children}
    </TrackingChartContext.Provider>
  )
}

export const useTrackingChartContext = () => useContext(TrackingChartContext)
