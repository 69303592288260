import styled from '@emotion/styled'
import { Route, Routes, Link, useParams } from 'react-router-dom'
import UpdateBasicOrg from './UpdateBasicOrg'
import UpdateSearchTerms from './UpdateSearchTerms'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
import { Icon } from 'components/theme'
import { useOrgById } from 'data/hooks'

const UpdateCompany = () => {
  const marketplaceUrl = useMarketplaceUrl(true)
  const { orgId } = useParams()
  const { company } = useOrgById(orgId)

  const slug = company?.slug

  return (
    <Root>
      <Links>
        <ul>
          <li>
            <Link to="./">Basic</Link>
          </li>
          <li>
            <Link to="./terms">Terms</Link>
          </li>
        </ul>
      </Links>
      <Pages>
        <Top>
          <Link to="../companies">
            <Icon name={faArrowLeft} /> Back to Companies
          </Link>
          <a href={`${marketplaceUrl}returnTo=/company/${slug}`} target="_blank" rel="noreferrer">
            View Company Page
          </a>
        </Top>
        <Routes>
          <Route path="/" element={<UpdateBasicOrg />} />
          <Route path="/terms" element={<UpdateSearchTerms />} />
        </Routes>
      </Pages>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  & > a {
    font-size: 16px;
    font-weight: 500;
  }
`

const Links = styled.div`
  width: 140px;
`

const Pages = styled.div`
  width: 100%;
`

export default UpdateCompany
