import styled from '@emotion/styled'
import { Route } from 'react-router-dom'
import { BoxWrapper } from 'components/theme'
import { WizardProvider } from './prevalentContext'
import CurrentStepper from './CurrentStep'
import First from './pages/First'
import Second from './pages/Second'
import Third from './pages/Third'
import Forth from './pages/Forth'
import ThankYou from './pages/ThankYou'

function PrevalentWizard() {
  return (
    <Root>
      <CurrentStepper />
      <Route path="first">
        <First />
      </Route>

      <Route path="second">
        <Second />
      </Route>

      <Route path="third">
        <Third />
      </Route>

      <Route path="forth">
        <Forth />
      </Route>

      <Route path="thank-you">
        <ThankYou />
      </Route>
    </Root>
  )
}

function ProviderWrapper() {
  return (
    <BoxWrapper>
      <WizardProvider>
        <PrevalentWizard />
      </WizardProvider>
    </BoxWrapper>
  )
}

const Root = styled.div``

export default ProviderWrapper
