import { gql } from 'graphql-request'

export const CURRENT_ORG_FRAGMENT = gql`
  fragment CurrentOrg on Organization {
    ...OrgCore
    ...ViewerOrg
  }
`
export const ORG_CORE_FRAGMENT = gql`
  fragment OrgCore on Organization {
    nodeId
    id
    slug
    details
    name
    nodeId
    type
    subtype
    domain
    updatedAt
    createdAt
    testMode
    auth0OrgId
    appMetadata
    mainTermId
  }
`

export const VIEWER_ORG_FRAGMENT = gql`
  fragment ViewerOrg on Organization {
    associationToOrganizations {
      associationId
      association {
        id
        name
        slug
        details
      }
    }
    productToOrganizations {
      product {
        ...ViewerProduct
      }
    }
    locations {
      city
      country
      id
      organizationId
      state
      isHeadquarter
    }
    useCases(orderBy: UPDATED_AT_DESC) {
      action
      id
      trigger
      practiceGroup
      ownerCreated
      product {
        name
        id
        slug
      }
      details
      feature
    }
    termToOrganizations {
      nodeId
      organizationId
      termId
      term {
        ...TermCore
      }
    }
    usersToOrganizations {
      jobTitle
      role
      status
      userId
      user {
        id
        email
        details
        first
        last
        updatedAt
      }
    }
  }
`
