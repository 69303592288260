import styled from '@emotion/styled'
import LawfirmForm from 'components/forms/LawfirmForm'
import { extractPictureUrl } from 'services/image'
import { useOrg } from 'data/organization/useOrg'
import { toast } from 'react-toastify'
import { useUpdateOrg, useMutateTermsToOrg } from 'data/hooks'
import { newDeletedList } from 'services/helper'

function ProfileCompanyForm() {
  const updateLawfirm = useUpdateOrg()
  const mutateTermsToOrg = useMutateTermsToOrg()
  const { lawfirm } = useOrg()

  const defaultValues = {
    name: lawfirm?.name || '',
    lawfirmType: lawfirm?.terms.find(({ taxonomyId }) => taxonomyId === 17)?.id || undefined,
    logoUrl: lawfirm?.details?.logo || '',
    lawfirmSize: lawfirm?.details?.lawfirmSize || '',
    jurisdictionRegion: lawfirm?.details?.jurisdictionRegion || '',
    specialty: lawfirm?.mainTermId || undefined,
    industry: lawfirm?.terms.find(({ taxonomyId }) => taxonomyId === 20)?.id || undefined,
  }

  const onSubmit = async ({
    name,
    lawfirmType,
    logoUrl,
    lawfirmSize,
    industry,
    specialty,
  }: any) => {
    const lawfirmLogo = await extractPictureUrl(logoUrl)

    console.log({ specialty })

    const details = {
      ...lawfirm?.details,
      logo: lawfirmLogo,
      lawfirmSize,
      // specialty,
    }

    await updateLawfirm.mutateAsync({
      name,
      details,
      mainTermId: specialty,
    })

    const initialList = [defaultValues.lawfirmType, defaultValues.industry]
    const currentList = [lawfirmType, industry]
    const [createdTermIds, deletedTermIds] = newDeletedList(initialList, currentList)

    await mutateTermsToOrg.mutateAsync({ createdTermIds, deletedTermIds })

    toast.success('Lawfirm details updated')
  }

  console.log(lawfirm?.terms.filter(({ taxonomyId }) => taxonomyId === 13))
  console.log(defaultValues)

  return (
    <Root>
      <LawfirmForm
        showLocation={false}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onSubmitButtonText="Update Company"
      />
    </Root>
  )
}

const Root = styled.div``

export default ProfileCompanyForm
