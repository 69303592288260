import { useNavigate } from 'react-router-dom'
import UserForm, { initialValues } from 'components/forms/personal/UserForm'
import { useSetUserStatus } from 'hooks/graphql'
import styled from '@emotion/styled'
import { useUserSetup } from 'context/userSetupContext'
import { useLazyApi } from 'hooks/useApi'
import { UserStatus } from 'generated/graphql'
import { useViewer } from 'context/viewerContext'
import SetupHeader from 'components/global/SetupHeader'

type User = {
  first: string
  last: string
  details: any
}

const loadInitialValues = (user: User) => {
  const { first, last, details } = user

  return {
    ...initialValues,
    firstName: first || '',
    lastName: last || '',
    fileUrl: details.imageUrl,
  }
}

function CreateUser() {
  const { viewer } = useViewer()
  const { setUserStatus } = useSetUserStatus()
  const navigate = useNavigate()
  const { amplitude } = useUserSetup()
  const [welcomeEmail] = useLazyApi('company/welcome')

  const defaultValues = loadInitialValues(viewer)

  const onSubmit = async () => {
    amplitude
      ?.logEventWithSavedProps('set_user_info', {
        action: 'update_information',
        organization_type: 'PERSONAL',
      })
      .completeSignUp({})
    await setUserStatus(UserStatus.Active)
    await welcomeEmail()

    navigate('/personal/settings')
  }

  return (
    <Root>
      <SetupHeader />
      <FormContainer>
        <Title>Create your account</Title>
        <UserForm
          defaultValues={{ ...defaultValues }}
          onSubmit={onSubmit}
          submitButtonText="Create Account"
        />
      </FormContainer>
    </Root>
  )
}

const Root = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const Title = styled.h1`
  margin-bottom: 32px;
`

const FormContainer = styled.div`
  max-width: 760px;
  padding: 60px 20px;
  margin: 0 auto;
  width: 100%;
`

export default CreateUser
