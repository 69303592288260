import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'
import PersonalTechstack from 'components/techstack/PersonalTechstack'
import { useNavigate } from 'react-router'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const AssociationTechstack = () => {
  const baseOrgUrl = useBaseOrgUrl()
  const navigate = useNavigate()

  const onFinish = () => {
    navigate(`${baseOrgUrl}/your-apps`)
  }

  return (
    <Root>
      <BoxWrapper>
        <TopSection>
          <Title>Select Your Tools</Title>
          <Subtitle>add the tools you use to help the community</Subtitle>
          <BlueBox>
            <BlueBoxTitle>Compare your tech stack with others in your industry</BlueBoxTitle>
            <BlueBoxText>
              See how your Tech Stack compares with others in your industry. This feature will
              become available once we have enough data from our network.
            </BlueBoxText>
          </BlueBox>
        </TopSection>
        <PersonalTechstack onFinish={onFinish} />
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div`
  min-height: 100vh;
`

const TopSection = styled.div`
  margin-bottom: 28px;
`

const Title = styled.h3`
  font-size: 28px;
  text-align: center;
  margin-bottom: 0;
`

const Subtitle = styled.h4`
  font-size: 18px;
  text-align: center;
  margin-top: 0;
  font-weight: 500;
`

const BlueBox = styled.div`
  background: #dfebff;
  border: 1px solid #9d9df9;
  border-radius: 4px;
  padding: 8px 16px;
  max-width: 800px;
  margin: 0 auto;
`

const BlueBoxTitle = styled.h5`
  margin-bottom: 0;
`

const BlueBoxText = styled.p``

export default AssociationTechstack
