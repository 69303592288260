import styled from '@emotion/styled'
import StripePlanDetails from 'components/forms/StripePlanDetails'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useNavigate, useLocation } from 'react-router-dom'

type Values = {
  selectedProduct: string | number
  firstName: string
  lastName: string
  companyName: string
  jobTitle: string
  phone: string
}

function TermScoutPlanDetails() {
  const { viewer } = useViewer()
  const { vendor } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()
  const location = useLocation()
  const navigate = useNavigate()

  const onSubmit = (values: Values) => {
    navigate(`${baseOrgUrl}/term-scout/checkout`, {
      state: {
        formValue: values,
        stripeInfo: location.state,
      },
    })
  }

  const initialValues = {
    selectedProduct: vendor?.products[0].id || '',
    firstName: viewer.first || '',
    lastName: viewer.last || '',
    email: viewer.email || '',
    companyName: vendor?.name || '',
    jobTitle: '',
    phone: '',
  }

  const productOptions = vendor?.products.map((product) => ({
    key: product.id,
    text: product.name,
    value: product.id,
  }))

  return (
    <Root>
      <div>
        <Title>Configure your plan details</Title>
        <Subtitle>
          We will pass these details onto the vendor. Make sure to fill out the correct point of
          contact.
        </Subtitle>
        <FormWrapper>
          <StripePlanDetails
            onSubmit={onSubmit}
            initialValues={initialValues}
            uniqueProductOptions={productOptions}
            isVendorToVendor={true}
          />
        </FormWrapper>
      </div>
    </Root>
  )
}

const Root = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 80px;
`

const Title = styled.h2`
  margin-bottom: 8px;
`

const Subtitle = styled.p`
  margin-bottom: 24px;
`

const FormWrapper = styled.div`
  max-width: 560px;
`

export default TermScoutPlanDetails
