import { useMutateOrg, useMutateTermsToOrg, useOrg, useUpsertListingDetails } from 'data/hooks'
import { newDeletedList } from 'services/helper'
import { Values } from './BasicCompanyForm'

interface Variables extends Values {
  associationId: string
  details?: any
}

export const useUpsertBasicCompany = (initialTerms: number[]) => {
  const upsertListingDetails = useUpsertListingDetails()
  const mutateTermsToOrg = useMutateTermsToOrg()
  const mutateOrg = useMutateOrg()
  const { organization } = useOrg()

  const upsertBasicCompany = async (variables: Variables) => {
    const {
      name,
      website,
      logo,
      wideLogo,
      companyType,
      employeeSize,
      specialty,
      associationId,
      details,
    } = variables

    let currentTerms = [companyType, employeeSize].filter(Boolean) as number[]

    if (specialty) currentTerms = [...currentTerms, ...specialty]

    const [createdTermIds, deletedTermIds] = newDeletedList(initialTerms, currentTerms)

    await mutateTermsToOrg.mutateAsync({
      createdTermIds,
      deletedTermIds,
    })

    await mutateOrg.mutateAsync({
      name,
      details: {
        ...organization?.details,
        website,
        logo,
        wideLogo,
      },
    })

    await upsertListingDetails.mutateAsync({
      associationId,
      details,
    })
  }

  return upsertBasicCompany
}
