import React from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'
import UserStory from 'interfaces/userStory/UserStory'
import { Link } from 'react-router-dom'
import { useStories } from 'context/userStoriesContext'
import { Button } from 'components/theme'
import StoryContent from './StoryContent'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

type Props = {
  userStory: UserStory
  boldedKeywords?: boolean
  withPills?: boolean
  hideProductLabels?: boolean
  showEditButtons?: boolean
}

const StoryText: React.FC<Props> = ({
  userStory,
  boldedKeywords = false,
  withPills = false,
  hideProductLabels = false,
  showEditButtons = true,
}) => {
  const baseOrgUrl = useBaseOrgUrl()
  const { deleteStory } = useStories()
  const { productId } = useParams<{ productId?: string }>()

  return (
    <Root>
      {userStory.product?.logoUrl && !hideProductLabels ? (
        <Logo src={userStory.product.logoUrl} alt={`${userStory.product.name}s logo`} />
      ) : (
        <div></div>
      )}
      <Body>
        <Title>
          {!hideProductLabels && userStory.product?.name && <H3>{userStory.product.name}</H3>}
          {userStory.meta.permissions.edit && (
            <Buttons show={showEditButtons}>
              <ActionLink
                to={
                  productId
                    ? `${baseOrgUrl}/product/settings/${productId}/user-stories/${userStory.id}/edit`
                    : `${baseOrgUrl}/user-stories/${userStory.id}/edit`
                }
              >
                <b>edit</b>
              </ActionLink>
              <DeleteButton onClick={() => deleteStory(userStory.id)}>-</DeleteButton>
            </Buttons>
          )}
        </Title>
        <StoryContent
          userStory={userStory}
          step={3}
          boldedKeywords={boldedKeywords}
          withPills={withPills}
        />
      </Body>
    </Root>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Buttons = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 12px;
`

const ActionLink = styled(Link)`
  color: #498de6;
  margin: 0 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const DeleteButton = styled(Button)`
  text-align: center;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  color: #e53935;
  border-color: #e53935;
  &:hover {
    background: #e53935;
    border-color: #e53935;
  }
`

const H3 = styled.h3`
  margin: 0;
`

const Root = styled.div`
  display: flex;
  margin: 5px;
  width: 100%;
`

const Logo = styled.img`
  width: 90px;
  height: 90px;
  margin: 5px 16px 0 0;
`

export default StoryText
