import { useViewer } from 'context/viewerContext'
import { MemberStatus } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface Variables {
  status: MemberStatus
  organizationId: string
}
export const useUpdateMemberStatus = () => {
  const request = useGql()
  const { viewer } = useViewer()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ status, organizationId }: Variables) => {
      if (!viewer) return

      await request(UPDATE_MEMBER_STATUS, {
        userId: viewer.id,
        organizationId,
        status,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['viewer']),
    }
  )
}

const UPDATE_MEMBER_STATUS = gql`
  mutation UpdateMemberStatus($userId: Int!, $organizationId: UUID!, $status: MemberStatus) {
    upsertUsersToOrganization(
      input: {
        usersToOrganization: { userId: $userId, organizationId: $organizationId, status: $status }
      }
      where: { organizationId: $organizationId, userId: $userId }
    ) {
      clientMutationId
    }
  }
`
