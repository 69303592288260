import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useModal } from 'components/Modal'
import { Button } from 'components/theme'
import ClocModal from './ClocModal'
import TheoremLogo from 'assets/purple-logo.png'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useOrg } from 'data/hooks'
import { OrganizationType } from 'generated/graphql'
import AccModal from './AccModal'

const ProductListingNetworks = () => {
  const { showModal, toggle } = useModal()
  const { showModal: showAccModal, toggle: toggleAccModal } = useModal()
  const [showTheorem, setShowTheorem] = useState(false)
  const baseOrgUrl = useBaseOrgUrl()
  const { organization } = useOrg()
  //figure it out here

  return (
    <>
      <Title>Available Listing Networks:</Title>
      <Subtitle>
        Each Network is entitled to its own terms and pricing{' '}
        <a href="https://help.theoremlegal.com/" target="_blank">
          Learn More about Listings
        </a>
      </Subtitle>
      <CardsWrapper>
        <Column>
          <Card onClick={toggle}>
            <Logo src="https://res.cloudinary.com/dh1giwabb/image/upload/v1636401038/lohkcj9blueoocbg64ka.png" />
            <BoxTitle>CLOC</BoxTitle>
          </Card>
        </Column>
        <Column>
          <Card onClick={toggleAccModal}>
            <Logo src="https://res.cloudinary.com/dh1giwabb/image/upload/v1664468286/drb72jk0swqzxwfjl0z6.jpg" />
            <BoxTitle>ACC</BoxTitle>
          </Card>
        </Column>
        {organization?.type === OrganizationType.Vendor && (
          <Column>
            <Card onClick={() => setShowTheorem((prevVal) => !prevVal)}>
              <TheoremLogoImg src={TheoremLogo} />
              <BoxTitle>Theorem</BoxTitle>
            </Card>
          </Column>
        )}
      </CardsWrapper>
      <ClocModal isShow={showModal} toggleModal={toggle} />
      <AccModal isShow={showAccModal} toggleModal={toggleAccModal} />
      {showTheorem && (
        <TheoremBox>
          <Left>
            <h2>Included in your Theorem Listing:</h2>
            <p>Manage your listings on every network in our ecosystem from one place</p>
            <p>
              <strong>Networks Included:</strong>
            </p>
            <ul>
              <li>Theorem – The Core Network</li>
              <li>ELTA - European Legal Tech Association</li>
              <li>ALITA - Asia-Pacific Legal Innovation and Technology Association</li>
              <li>ARK KM - Knowledge Management, AM law 100 & 200</li>
              <li>CIO Network - Information and Analytics Officers</li>
              <li>CISO network - security professionals</li>
              <li>Includes upcoming networks</li>
            </ul>
            <p>Continue to view plan features</p>
          </Left>
          <Button component="link" to={`${baseOrgUrl}/theorem-pro-upgrade`}>
            Purchase
          </Button>
        </TheoremBox>
      )}
    </>
  )
}

const Left = styled.div``

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: flex-wrap;
  margin: 0 -16px;
`

const Column = styled.div`
  max-width: 33.3333%;
  flex: 1 1 33.3333%;
  padding: 0 16px;
`

const Title = styled.h3`
  margin-bottom: 8px;
`

const Subtitle = styled.h4`
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 400;
`

const TheoremBox = styled.div`
  display: flex;
  margin-top: 32px;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 720px;
`

const Card = styled.div`
  width: 100%;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  padding-bottom: 8px;
  border-radius: 4px;
  border: transparent 2px solid;
  background: white;
  transition: border 250ms;
  height: 100%;
  display: flex;
  flex-flow: column;
  cursor: pointer;
  align-items: center;

  &:hover {
    border: #4cd0d1 2px solid;
  }
`

const Logo = styled.img`
  width: 100px;
  height: 100px;
`

const TheoremLogoImg = styled.img`
  width: 74px;
  margin-bottom: 10px;
  margin-top: 10px;
`

const BoxTitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
`

export default ProductListingNetworks
