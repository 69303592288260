import React from 'react'
import styled from '@emotion/styled'

type Props = {
  pdf: string | null | undefined
  url: string | null | undefined
}
const InvoiceTooltip: React.FC<Props> = ({ pdf, url }) => {
  if (!pdf && !url) return null
  return (
    <Root>
      {pdf && (
        <ItemLink href={pdf} download>
          Download PDF
        </ItemLink>
      )}
      {url && (
        <ItemLink href={url} rel="noopener noreferrer" target="_blank">
          View in browser
        </ItemLink>
      )}
    </Root>
  )
}

const Root = styled.div``

const ItemLink = styled.a`
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  background: white;
  transition: background 0.3s;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);

  &:hover {
    background: #eee;
  }
`

export default InvoiceTooltip
