import styled from '@emotion/styled'
import LogoImg from 'assets/white-logo.svg'
import SquareLogoImg from 'assets/white-icon-logo.svg'

const HelpPopupContent = () => (
  <Root>
    <div>
      <Text>Logo</Text>
      <LargeLogo src={LogoImg} />
    </div>
    <div>
      <Text>Square Logo</Text>
      <SquareLogo src={SquareLogoImg} />
    </div>
  </Root>
)

const Root = styled.div`
  display: flex;
`

const Text = styled.div`
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`

const LargeLogo = styled.img`
  height: 25px;
  margin-right: 16px;
`

const SquareLogo = styled.img`
  height: 25px;
`
export default HelpPopupContent
