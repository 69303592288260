import { useEffect } from 'react'
import { useTrackingChartContext } from 'context/trackingChartContext'
import { useChartJs } from 'hooks/useChartJs'
import ChartCard from 'components/charts/ChartCard'
import { useLazyApi } from 'hooks/useApi'
import _ from 'lodash'

function PlatformChart() {
  const {
    startDate,
    endDate,
    currentLoadingQueue,
    setCurrentLoadingQueue,
    productToAnalyze: product,
  } = useTrackingChartContext()
  const { ctx, setChartData } = useChartJs({ type: 'doughnut', options: {} })
  const [getPlatformType] = useLazyApi('tracking/amplitude/platform-type')

  useEffect(() => {
    const init = async () => {
      const result = await getPlatformType({ startDate, endDate, id: product })

      const series = _.get(result, 'series', [])
      const seriesLabels = _.get(result, 'seriesLabels', [])

      const totalCount = series.map((val: number[]) => val.reduce((a, b) => a + b, 0))
      const datasets = [
        {
          label: 'Platform Type',
          data: totalCount,
          borderWidth: 1,
          backgroundColor: ['#9446D3', '#3ACFB2', '#122AC0', '#6C09BD', '#00B793', '#5062D5'],
        },
      ]
      const labels = seriesLabels.map((val: any[]) => val[1])
      setChartData({ datasets, labels })
      setCurrentLoadingQueue((prevVal: number) => prevVal + 1)
    }

    if (currentLoadingQueue === 6) {
      init()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentLoadingQueue])

  return <ChartCard title="Device Type Count" ctx={ctx} />
}

export default PlatformChart
