import { useCreateProductWizard } from 'context/CreateProductWizardContext'
import ExtraForm from 'components/forms/product/ExtraForm'
import styled from '@emotion/styled'
import { useOrg } from 'data/organization/useOrg'
import _ from 'lodash'

let initialValues = {
  demoVideo: '',
  slides: [],
}

function ProductExtra() {
  const { currentProductId, goNextStep } = useCreateProductWizard()
  const { vendor } = useOrg()

  const product = _.find(vendor?.products, { id: currentProductId })

  initialValues = {
    ...initialValues,
    demoVideo: _.get(product, 'details.demoVideo', ''),
    slides: _.get(product, 'details.slides', []),
  }

  const onComplete = () => {
    goNextStep()
  }

  return (
    <Root>
      <ExtraForm initialValues={initialValues} onComplete={onComplete} product={product} />
    </Root>
  )
}

const Root = styled.div`
  padding: 40px;
  display: flex;
  justify-content: center;
`

export default ProductExtra
