import React from 'react'
import styled from '@emotion/styled'
import { AlgoliaTechstackProvider } from './AlgoliaTechstackContext'
import { InstantSearch, Configure } from 'react-instantsearch-hooks-web'
import algoliasearch from 'algoliasearch/lite'
import SearchBar from './SearchBar'
import SelectedApps from './SelectedApps'
import SaveButton from './SaveButton'
import NoAppsBox from './NoAppsBox'

interface Props {
  onSubmit: (selectedTechstackIds: number[]) => Promise<void>
  userTechstackIds: number[]
}

const algoliaClient = algoliasearch('0K3B9U0LC8', 'c597a04244e1170d74f9555a71db711e')

const searchClient: any = {
  search(requests: any) {
    if (requests.every(({ params }: any) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      })
    }

    return algoliaClient.search(requests)
  },
}

const AlgoliaTechstack: React.FC<Props> = ({ onSubmit, userTechstackIds }) => {
  return (
    <Root>
      <AlgoliaTechstackProvider initialSelectedApps={userTechstackIds}>
        <InstantSearch
          searchClient={searchClient}
          stalledSearchDelay={2000}
          indexName="theorem_ecosystem"
        >
          <Configure hitsPerPage={12} distinct />
          <SaveButton onSubmit={onSubmit} />
          <SearchWrapper>
            <SearchBar />
          </SearchWrapper>
          <Header>Your Apps</Header>
          <SelectedApps />
          <NoAppsBox />
        </InstantSearch>
      </AlgoliaTechstackProvider>
    </Root>
  )
}

const Root = styled.div`
  margin: auto;
  max-width: 800px;
`

const Header = styled.div`
  text-align: left;
  letter-spacing: 0.02rem;
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
`

const SearchWrapper = styled.div`
  margin-bottom: 20px;
`

export default AlgoliaTechstack
