import React from 'react'
import styled from '@emotion/styled'
import { Popup, Icon } from 'semantic-ui-react'

interface Props {
  children: React.ReactNode
  helpContent?: string
}
const UnderlineTitle = ({ children, helpContent = '' }: Props) => {
  return (
    <Root>
      {children}
      {helpContent && (
        <Popup
          inverted
          styles={{
            background: '#333',
          }}
          trigger={
            <div>
              <HelpIcon name="info circle" />
            </div>
          }
        >
          <Popup.Content>{helpContent}</Popup.Content>
        </Popup>
      )}
    </Root>
  )
}

const Root = styled.div`
  border-bottom: 1px solid #999;
  padding-bottom: 4px;
  margin-bottom: 16px;
  color: #616161;
  font-size: 15px;
  font-weight: 500;
  display: flex;
`

const HelpIcon = styled(Icon)`
  color: #00a2ff;
  margin-left: 8px !important;
`

export default UnderlineTitle
