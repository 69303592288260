import { useState } from 'react'

export const useTermsCheckbox = () => {
  const [selectedCheckbox, setSelectedCheckbox] = useState<number[]>([])

  const clickCheckbox = (value: any) => {
    const selectedIndex: number = value as number

    setSelectedCheckbox((prevSelection) =>
      prevSelection.includes(selectedIndex)
        ? prevSelection.filter((index) => index !== selectedIndex)
        : [...prevSelection, selectedIndex]
    )
  }

  return { selectedCheckbox, clickCheckbox }
}
