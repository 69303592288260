import React, { useState } from 'react'
import styled from '@emotion/styled'
import Button from 'components/theme/Button'
import { InputStyle, FormLabel } from './styles'
import { useLazyApi } from 'hooks/useApi'
import { useField } from 'formik'
import { Icon } from 'components/theme'
import { Coupon } from 'interfaces'

interface Props {
  name: string
  accountId?: string
  onConfirmCoupon?: (coupon: Coupon) => void
}
const CouponInput: React.FC<Props> = ({ accountId, onConfirmCoupon, ...props }) => {
  const [, meta, helpers] = useField(props)
  const [couponId, setCouponId] = useState('')
  const [loading, setLoading] = useState(false)
  const [couponText, setCouponText] = useState<string>()
  const [checkCoupon] = useLazyApi('stripe/product/coupon')

  const onCheck = async () => {
    setLoading(true)
    try {
      const { id, amount_off, percent_off, error } = await checkCoupon({ couponId, accountId })

      if (error) {
        helpers.setError('Coupon doesnt exist')
      } else {
        const coupon = { id, amountOff: amount_off, percentOff: percent_off }
        if (onConfirmCoupon) onConfirmCoupon(coupon)
        if (percent_off) setCouponText(`${percent_off}% off`)
        helpers.setValue(coupon)
      }
    } catch (error) {
      console.error({ error })
    }
    setLoading(false)
  }

  const { value, error } = meta

  return (
    <InputContainer>
      <FormLabel>Coupon code (Optional)</FormLabel>
      <CouponInputContainer>
        <InputWrapper>
          <Input
            type="text"
            value={couponId}
            onChange={(event) => setCouponId(event.target.value)}
          />

          {couponText && !error && <CouponSaved>{couponText}</CouponSaved>}
        </InputWrapper>
        <CheckCouponButton type="button" onClick={onCheck} isSuccess={!!value} loading={loading}>
          {value ? <SuccessIcon name="check-circle" /> : 'Check'}
        </CheckCouponButton>
      </CouponInputContainer>
      {error && <CouponError>{error}</CouponError>}
    </InputContainer>
  )
}

const InputContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CouponInputContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const CheckCouponButton = styled(Button)`
  margin-left: auto;
  height: 32px;
  margin: auto;
  margin-left: 24px;
  width: 88px;
  color: ${({ theme }: any) => theme.color.text};
  border: 2px solid ${({ theme }: any) => theme.color.text};
  &:hover {
    background: ${({ theme }: any) => theme.color.text};
    border: 2px solid ${({ theme }: any) => theme.color.text};
  }

  ${({ isSuccess }) =>
    isSuccess &&
    `
    color: white;
   background: #41c300 !important;
   border-color: #41c300 !important;
    `}
`

const CouponError = styled.div`
  color: #f44336;
  font-weight: 400;
  margin-top: 6px;
`

const CouponSaved = styled.div`
  color: #41c300;
  font-weight: 500;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`

const InputWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
`

const Input = styled.input`
  ${InputStyle}
`

const SuccessIcon = styled(Icon)`
  color: white;
  width: 28px !important;
`

export default CouponInput
