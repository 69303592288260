import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useOrg } from 'data/organization/useOrg'
import { OrganizationType } from 'generated/graphql'

type Props = {
  id: number
  image: string
  name: string
  url: string
  className?: string
  onClick?: (id: number) => void
}

function ImageLinkBox({ id, image, name, url, onClick, className }: Props) {
  const { organization } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()

  const organizationType = organization?.type

  return (
    <Root
      className={className}
      onClick={() => {
        if (onClick) onClick(id)
      }}
    >
      {(organizationType === OrganizationType.Vendor ||
        organizationType === OrganizationType.Lawfirm) && (
        <Options>
          <OptionLink to={`${baseOrgUrl}/product/settings/${id}`}>
            <Icon name="edit" />
          </OptionLink>
        </Options>
      )}
      <ImageContainer>
        <Image src={image} alt={name} />
      </ImageContainer>
      <Name>{name}</Name>
      {!onClick ? (
        organizationType === OrganizationType.Vendor ||
        organizationType === OrganizationType.Lawfirm ? (
          <OutLink to={`${baseOrgUrl}/product/settings/${id}`} />
        ) : (
          <OutLinkA href={url} />
        )
      ) : null}
    </Root>
  )
}

const Options = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background: white;
  z-index: 1;
  opacity: 0;
`

const Root = styled.div`
  position: relative;
  background: #fafafa;
  min-width: 140px;
  text-align: center;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0);
  transition: box-shadow 250ms, background 250ms;

  &:hover {
    background: #f4f4f4;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

    ${Options} {
      opacity: 1;
    }
  }
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 3px;
  padding: 10px;
  background-color: white;
  margin: 0 auto 5px;
`

const Image = styled.img`
  max-width: 100%;
`

const Name = styled.div`
  font-weight: 500;
`

const OutLink = styled(Link)`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const OutLinkA = styled.a`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const OptionLink = styled(Link)`
  color: #333;
`

export default React.memo(ImageLinkBox)
