import React, { useState } from 'react'
import Modal from 'components/Modal'
import styled from '@emotion/styled'
import TermScoutProductBox from './TermScoutProductBox'
import TermScoutSalesModal from 'components/modals/TermScoutSalesModal'
import {
  TS_STANDARD_PRODUCT_ID,
  TS_STANDARD_PRICE_ID,
  TS_STANDARD_FEATURES,
  TS_ADVANCED_PRODUCT_ID,
  TS_ADVANCED_PRICE_ID,
  TS_ADVANCED_FEATURES,
  TS_PRO_PRODUCT_ID,
  TS_PRO_PRICE_ID,
  TS_PRO_FEATURES,
} from './termScoutProductData'

interface Props {
  isShowModal: boolean
  toggleModal: () => void
}

type SalesModalData = {
  stripeProductId: string
  priceId: string
  price: string
  features: string[]
}
interface Modal extends SalesModalData {
  show: boolean
}

const TermScoutProducts: React.FC<Props> = ({ isShowModal, toggleModal }) => {
  const [modal, setModal] = useState<Modal>({
    show: false,
    priceId: '',
    stripeProductId: '',
    price: '',
    features: [],
  })

  const openSalesModal = ({ stripeProductId, priceId, price, features }: SalesModalData) => () => {
    setModal({ show: true, stripeProductId, priceId, price, features })
  }

  const toggleSalesModal = () => {
    setModal((prevModal) => ({
      show: !prevModal.show,
      stripeProductId: prevModal.stripeProductId,
      priceId: prevModal.priceId,
      price: prevModal.price,
      features: [],
    }))
  }

  return (
    <>
      <Modal isShowing={isShowModal} toggleModal={toggleModal} width={'1140px'}>
        <Root>
          <Title>Which product offer?</Title>
          <Boxes>
            <TermScoutProductBox
              title="Standard Review"
              content="TermScout reviews your standard terms and publishes the results on its site and on your Theorem page."
              price="99"
              onClick={openSalesModal({
                stripeProductId: TS_STANDARD_PRODUCT_ID,
                priceId: TS_STANDARD_PRICE_ID,
                price: '$99',
                features: TS_STANDARD_FEATURES,
              })}
            />
            <TermScoutProductBox
              title="Advanced Review"
              content="TermScout reviews your standard terms and grants you a 30 day period to review the results and, if desired, change your terms, before it publishes the results on its site and on your Theorem page."
              price="250"
              onClick={openSalesModal({
                stripeProductId: TS_ADVANCED_PRODUCT_ID,
                priceId: TS_ADVANCED_PRICE_ID,
                price: '$250',
                features: TS_ADVANCED_FEATURES,
              })}
            />
            <TermScoutProductBox
              title="Pro Review"
              content="TermScout reviews your standard terms and grants you a 30 day period to review the results. At the beginning of this period, TermScout provides a Ratings Improvement Report. If desired, you may change your terms and submit for re-review before TermScout publishes the results on its site and on your Theorem page."
              price="1,250"
              onClick={openSalesModal({
                stripeProductId: TS_PRO_PRODUCT_ID,
                priceId: TS_PRO_PRICE_ID,
                price: '$1,250',
                features: TS_PRO_FEATURES,
              })}
            />
          </Boxes>
        </Root>
      </Modal>
      <TermScoutSalesModal
        isShowing={modal.show}
        toggleModal={toggleSalesModal}
        priceId={modal.priceId}
        stripeProductId={modal.stripeProductId}
        features={modal.features}
        price={modal.price}
      />
    </>
  )
}

const Root = styled.div`
  padding: 20px 0 40px;
`

const Title = styled.h1`
  font-size: 32px;
  text-align: center;
`

const Boxes = styled.div`
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
`

export default TermScoutProducts
