import { Formik, Form } from 'formik'
import Button from 'components/theme/Button'
import { Label, FormField } from 'components/theme/form'
import { draftToMarkdown } from 'markdown-draft-js'
import { EditorState, convertToRaw } from 'draft-js'
import RichEditor from 'components/fields/RichEditor'
import * as yup from 'yup'
import styled from '@emotion/styled'
import PromptIfDirty from 'components/forms/PromptIfDirty'
import { useUpdateProductDescription } from 'data/hooks'

const defaultValues = {
  description: EditorState.createEmpty(),
}

const validationSchema = yup.object().shape({})

function DescriptionForm({
  initialValues = defaultValues,
  submitButtonText = 'NEXT (MORE INFO)',
  onComplete,
  productId,
}: any) {
  const updateProductDescription = useUpdateProductDescription()

  const onSubmit = async ({ description }: any) => {
    const markdownDescription = draftToMarkdown(convertToRaw(description.getCurrentContent()))

    updateProductDescription.mutate(
      {
        productId,
        description: markdownDescription,
      },
      {
        onSuccess: () => onComplete(),
      }
    )
  }

  return (
    <Root>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        ignoreLoading
        validateOnMount
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <PromptIfDirty />
            <FormField>
              <FormField>
                <Label helpContent="">Main Description</Label>
                <RichEditor
                  editorState={values.description}
                  onChange={setFieldValue}
                  name="description"
                />
              </FormField>
            </FormField>
            <FormField>
              <ButtonContainer>
                <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                  {submitButtonText}
                </Button>
              </ButtonContainer>
            </FormField>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  max-width: 760px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

export default DescriptionForm
