import React from 'react'
import styled from '@emotion/styled'
// import { fixCurrencyDisplay } from 'services/helper'

function StripeHeaderMetaData({ resource }: any) {
  const { priceType, priceRange, pricePeriod } = resource

  // const intervalText =
  getInterval(priceType, priceRange, pricePeriod)

  return <Root>Connected to Stripe</Root>
}

const getInterval = (priceType: any, priceRange: any, pricePeriod: any) => {
  const isYear = priceType === 'annually' || (priceType === 'custom' && priceRange === 'years')
  const intervalText = isYear ? 'Year' : 'Month'

  const intervalCount = getIntervalCount(priceType, pricePeriod)

  const finalText = intervalCount > 1 ? `${intervalCount} ${intervalText}s` : intervalText

  return finalText
}

const getIntervalCount = (priceType: any, pricePeriod: any) => {
  switch (priceType) {
    case 'custom':
      return pricePeriod

    case '3months':
      return 3

    case '6months':
      return 6

    case 'monthly':
    case 'annually':
    default:
      return 1
  }
}

const Root = styled.div`
  color: white;
  font-weight: bold;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.32);
  letter-spacing: 0.5px;
  font-size: 16px;
  margin-right: auto;
  margin-left: 15px;
`

export default StripeHeaderMetaData
