import React from 'react'
import { toast } from 'react-toastify'
import { usePersonalTechstack } from './usePersonalTechstack'
import { useQueryClient } from '@tanstack/react-query'
import AlgoliaTechstack from '../AlgoliaTechstack'

interface Props {
  onFinish?: () => void
}

const PersonalTechstack: React.FC<Props> = ({ onFinish }) => {
  const { onSubmit, userProductIds } = usePersonalTechstack()
  const queryClient = useQueryClient()

  const onPersonalTechstackSubmit = async (selectedProductIds: number[]) => {
    try {
      queryClient.invalidateQueries(['activeAssocUsers'])
      await onSubmit(selectedProductIds)
      toast.success('Success!', { toastId: 'personal-tech-submit' })
      onFinish && onFinish()
    } catch (error) {
      console.error(error)
      toast.error('Something went wrong', { toastId: 'personal-tech-submit-error' })
    }
  }

  return <AlgoliaTechstack onSubmit={onPersonalTechstackSubmit} userTechstackIds={userProductIds} />
}

export default PersonalTechstack
