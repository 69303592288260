import { useCallback, useState } from 'react'
import styled from '@emotion/styled'

import SectionCard from './SectionCard'
import AddSectionButton from './AddSectionButton'
import { useAssocHomeEditor, SectionLayout } from './assocHomeEditorContext'
import CardsEditorModal from './CardsEditorModal'

export interface ContainerState {
  cards: SectionLayout[]
}

const CardsContainer = () => {
  const { sections } = useAssocHomeEditor()
  const [selectedAssocHomeId, setSelectedAssocHomeId] = useState<number | string | undefined>()

  const setModal = (assocHomeId: number | string) => {
    setSelectedAssocHomeId(assocHomeId)
  }

  const removeSelectedAssocHomeId = () => {
    setSelectedAssocHomeId(undefined)
  }

  const renderCard = useCallback((card: SectionLayout, index: number) => {
    return (
      <SectionCard key={card.id} index={index} id={card.id} type={card.type} setModal={setModal} />
    )
  }, [])

  return (
    <Root>
      {sections.map((card, i) => renderCard(card, i))}
      <AddSectionButton />
      {selectedAssocHomeId && typeof selectedAssocHomeId === 'number' && (
        <CardsEditorModal
          assocHomeId={selectedAssocHomeId}
          removeSelectedAssocHomeId={removeSelectedAssocHomeId}
        />
      )}
    </Root>
  )
}

const Root = styled.div`
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export default CardsContainer
