import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface Variables {
  productId: number
  show: boolean
}
export const useToggleShowProduct = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      await request(TOGGLE_SHOW_PRODUCT, variables)
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

export const TOGGLE_SHOW_PRODUCT = gql`
  mutation ToggleShowProduct($productId: Int!, $show: Boolean) {
    updateProduct(input: { patch: { show: $show }, id: $productId }) {
      clientMutationId
    }
  }
`
