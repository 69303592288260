import styled from '@emotion/styled'
import WizardModal, { WizardStep } from 'components/modals/WizardModal'
import SelectPlan from '../ListingWizard/SelectPlan'

const steps: WizardStep[] = [
  {
    title: 'Select Plan',
    subtitle: 'Available Plans',
    component: <SelectPlan />,
  },
]

const TheoremProListingWizard = ({
  closeModal,
  networkSlug,
  productId,
  listingType,
  hasBasicListing = false,
}: {
  closeModal: () => void
  networkSlug: string
  productId: number
  listingType: string
  hasBasicListing?: boolean
}) => {
  const onClose = () => {
    closeModal()
  }

  const initialData = {
    networkSlug,
    productId,
    listingType,
    hasBasicListing,
  }

  return (
    <Root>
      <WizardModal steps={steps} onClose={onClose} initialData={initialData} />
    </Root>
  )
}

const Root = styled.div``

export default TheoremProListingWizard
