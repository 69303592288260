import React from 'react'
import HighlightBox from 'components/theme/HighlightBox'
import { Member } from 'data/types'
import styled from '@emotion/styled'
import { useViewer } from 'context/viewerContext'

interface Props {
  members: Member[]
}

const SubscriptionBoxes: React.FC<Props> = ({ members }) => {
  const { subscription } = useViewer()

  const currentMembersCount = members.filter(({ status }) => status === 'ACTIVE').length
  const pendingMembersCount = members.filter(
    ({ status }) => status === 'INVITED' || status === 'REQUEST'
  ).length

  const seatsAvaliable: string | number = subscription === 'FREE' ? 10 - currentMembersCount : '∞'

  return (
    <Root>
      <HighlightBox label="Members" count={currentMembersCount} />
      <HighlightBox label="Pending invites" count={pendingMembersCount} />
      <HighlightBox label="Seats available" count={seatsAvaliable} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1000px;
  margin-bottom: 20px;
`

export default SubscriptionBoxes
