import { useOrg } from 'data/hooks'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'
import { GqlGetNoviOrganizationQuery } from 'generated/graphql'

export const useNoviOrganization = (organizationId?: string) => {
  const request = useGql()
  const { organization } = useOrg()

  const { data, ...other } = useQuery(
    ['noviOrganization', organizationId || organization?.id],
    async () => {
      if (!organization && !organizationId) return
      const response = await request<GqlGetNoviOrganizationQuery>(GET_NOVI_ORGANIZATION, {
        organizationId: organizationId || organization?.id,
      })

      return response && response.noviOrganization
    },
    {
      staleTime: 60 * 60 * 1000,
      enabled: !!(organizationId || organization?.id),
    }
  )

  return { noviOrganization: data, ...other }
}

const GET_NOVI_ORGANIZATION = gql`
  query GetNoviOrganization($organizationId: UUID!) {
    noviOrganization(organizationId: $organizationId) {
      noviId
      status
    }
  }
`
