import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useDeleteUserStory = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (userStoryId: number) => {
      await request(DELETE_USER_STORY, { userStoryId })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['userStory']),
    }
  )
}

const DELETE_USER_STORY = gql`
  mutation DeleteUserStory($userStoryId: Int!) {
    __typename
    deleteUserStory(input: { id: $userStoryId }) {
      clientMutationId
    }
  }
`
