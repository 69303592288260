import { useOrg } from 'data/hooks'

export const useBaseOrgUrl = () => {
  const { organization } = useOrg()

  if (!organization) return '/'

  const baseUrl = `/${organization.type.toLowerCase()}/${organization.id}`

  return baseUrl
}
