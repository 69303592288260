import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  deletedTechstackIds: number[]
  createdTechstackIds: number[]
}
export const useMutateUsersToTechstacks = () => {
  const request = useGql()
  const { viewer } = useViewer()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { deletedTechstackIds, createdTechstackIds } = variables

      await request(CREATE_USERS_TO_TECHSTACKS, {
        techstackIds: createdTechstackIds,
        userId: viewer.id,
      })
      await request(DELETE_USERS_TO_TECHSTACKS, {
        techstackIds: deletedTechstackIds,
        userId: viewer.id,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['viewer']),
    }
  )
}

const CREATE_USERS_TO_TECHSTACKS = gql`
  mutation CreateUsersToTechstacks($techstackIds: [Int!]!, $userId: Int!) {
    createUsersToTechstacks(input: { techstackIds: $techstackIds, userId: $userId }) {
      clientMutationId
    }
  }
`

const DELETE_USERS_TO_TECHSTACKS = gql`
  mutation DeleteUsersToTechstacks($techstackIds: [Int!]!, $userId: Int!) {
    deleteUsersToTechstacks(input: { techstackIds: $techstackIds, userId: $userId }) {
      clientMutationId
    }
  }
`
