import styled from '@emotion/styled'
import { Icon, Button, Dropdown } from 'semantic-ui-react'
import { Formik, Form } from 'formik'
import { TextInput, Label, FormField } from 'components/theme/form'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import * as yup from 'yup'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useTaxonomyDropdown } from 'hooks/useTaxonomyDropdown'

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  jobTitle: yup.string().required('Required'),
  linkedin: yup.string().url(),
  twitter: yup.string(),
})

function UserForm({
  defaultValues,
  submitButtonText = 'Create User',
  onSubmit,
  showImageUpload = true,
}: any) {
  const { options: jobTitleOptions, isLoading: jobTitleLoading } = useTaxonomyDropdown({
    taxonomyId: 8,
  })
  const { options: practiceGroupOptions, isLoading: practiceGroupLoading } = useTaxonomyDropdown({
    taxonomyId: 13,
  })
  const { options: industryGroupOptions, isLoading: industryGroupLoading } = useTaxonomyDropdown({
    taxonomyId: 20,
  })

  if (jobTitleLoading || practiceGroupLoading || industryGroupLoading) return <FullPageLoading />

  const setOnChangeValue =
    (name: any, setFieldValue: any) =>
    (_e: any, { value }: any) => {
      setFieldValue(name, value)
    }

  return (
    <Root>
      <FormContainer>
        <Formik
          initialValues={{ ...defaultValues }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ values, setFieldValue, isSubmitting, isValid }) => (
            <Form>
              <FormField>
                <UploadSingleAsset
                  name="fileUrl"
                  label="Profile Picture"
                  selectedImage={values.fileUrl}
                >
                  <UploadContent>
                    <UploadIcon name="user" />
                    <span>Upload Picture</span>
                  </UploadContent>
                </UploadSingleAsset>
              </FormField>
              <FormField>
                <TextInput
                  name="firstName"
                  label="First Name"
                  placeholder="First Name..."
                  isSubmitting={isSubmitting}
                  required
                />
              </FormField>
              <FormField>
                <TextInput
                  name="lastName"
                  label="Last Name"
                  placeholder="Last name..."
                  isSubmitting={isSubmitting}
                  required
                />
              </FormField>
              <FormField>
                <Label required>Role</Label>
                <Dropdown
                  fluid
                  selection
                  search
                  clearable
                  value={values.jobTitle}
                  onChange={setOnChangeValue('jobTitle', setFieldValue)}
                  options={jobTitleOptions}
                  name="jobTitle"
                />
              </FormField>
              <FormField>
                <Label>Practice Group</Label>
                <Dropdown
                  fluid
                  selection
                  search
                  multiple
                  value={values.practiceGroup}
                  onChange={setOnChangeValue('practiceGroup', setFieldValue)}
                  options={practiceGroupOptions}
                  name="practiceGroup"
                />
              </FormField>
              <FormField>
                <Label>Industry</Label>
                <Dropdown
                  fluid
                  selection
                  search
                  multiple
                  value={values.industry}
                  onChange={setOnChangeValue('industry', setFieldValue)}
                  options={industryGroupOptions}
                  name="industry"
                />
              </FormField>
              <FormField>
                <SplitInput>
                  <Left>
                    <TextInput
                      label="LinkedIn Profile"
                      placeholder="https://www.linkedin.com/in/john-doe"
                      name="linkedin"
                    />
                  </Left>
                  <Right>
                    <TextInput label="Twitter Handle" placeholder="@TheoremLTS" name="twitter" />
                  </Right>
                </SplitInput>
              </FormField>
              <FormField>
                <Button
                  disabled={isSubmitting || !isValid}
                  loading={isSubmitting}
                  primary
                  type="submit"
                  icon
                  labelPosition="right"
                >
                  {submitButtonText}
                  <Icon name="user" />
                </Button>
              </FormField>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
`

const UploadContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const UploadIcon = styled(Icon)`
  font-size: 50px !important;
  height: 40px !important;
  margin-top: 10px !important;
  margin-right: 0 !important;
  opacity: 0.7 !important;
  color: #4d5065;
`

const FormContainer = styled.div``

const SplitInput = styled.div`
  display: flex;
`

const Left = styled.div`
  width: 50%;
  margin-right: 10px;
`

const Right = styled.div`
  width: 50%;
  margin-left: 10px;
`

export default UserForm
