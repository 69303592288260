import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Icon, Button } from 'components/theme'

import { css } from '@emotion/react'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { useRedirect } from 'hooks/useRedirect'
import { MemberStatus } from 'generated/graphql'

const TempButtons = () => {
  const [memberRequests, setMemberRequests] = useState<number>(0)
  const { isAdmin, accountId, paymentStatus } = useViewer()
  const redirect = useRedirect()
  const { organization, organizationLoading } = useOrg()

  const isNotUpgraded = paymentStatus !== 'ACTIVE'
  const isAdminLawfirm = organization?.type === 'LAWFIRM' && isAdmin
  const isAdminVendor = organization?.type === 'VENDOR' && isAdmin

  useEffect(() => {
    if (!isAdmin || organizationLoading || !organization?.id) return
    const requestUser = organization.members.filter(({ status }) => status === MemberStatus.Request)
    setMemberRequests(requestUser.length)
  }, [JSON.stringify(organization?.members)])

  return (
    <Root>
      {memberRequests > 0 && (
        <NotificationButton onClick={() => redirect('settings/team')}>
          {memberRequests} Request{memberRequests > 1 && 's'}
        </NotificationButton>
      )}

      {isAdminLawfirm && isNotUpgraded && (
        <PayButton onClick={() => redirect('theorem-pro-upgrade')}>
          <StarIcon name="star" /> <PayButtonText>Upgrade</PayButtonText>
        </PayButton>
      )}

      {isAdminVendor && isNotUpgraded && !accountId && (
        <PayButton onClick={() => redirect('theorem-pro-upgrade')}>
          <StarIcon name="star" /> <PayButtonText>Upgrade</PayButtonText>
        </PayButton>
      )}

      {isAdminVendor &&
        isNotUpgraded &&
        accountId && ( // not upgraded accountId
          <PayButton onClick={() => redirect('theorem-pro-upgrade')}>
            <StarIcon name="star" /> <PayButtonText>Upgrade</PayButtonText>
          </PayButton>
        )}

      {/* isAdminVendor &&
        !isNotUpgraded &&
        !accountId && ( // is upgrade no account Id red button
          <ActionRequiredButton onClick={() => redirect('upgrade/setup/1')}>
            <StarIcon name="exclamation-triangle" /> <PayButtonText>ACTION REQUIRED</PayButtonText>
          </ActionRequiredButton>
        ) */}

      {isAdminVendor &&
        !isNotUpgraded && ( // is upgraded with accountId. Maybe add a green button
          <PayButton>
            <PayButtonText>PRO LVN</PayButtonText>
          </PayButton>
        )}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
`

const buttonStyles = css`
  display: flex;
  align-items: center;
  padding-left: 10px;
  line-height: 1;
  margin-right: 10px;
`

const SellerButton = styled(Button)`
  ${buttonStyles}
  position: relative;
  z-index: 1;
  color: #c3621c;
  border-color: #c3621c;
  background-color: transparent;

  &:hover {
    color: #c3621c;
    border-color: #c3621c;
    background-color: transparent;
  }
`

const NotificationButton = styled(Button)`
  color: #f44336;
  background-color: white;
  border-color: #f44336;
  position: relative;
  z-index: 1;
  ${buttonStyles}

  &:hover {
    color: white;
    border-color: #f44336;
    background-color: #f44336;
  }
`

const PayButton = styled(Button)`
  position: relative;
  z-index: 1;
  color: #c3621c;
  background-color: #ffd700;
  border-color: #ffd700;
  ${buttonStyles}

  &:hover {
    color: #c3621c;
    border-color: #c3621c;
    background-color: transparent;
  }
`

const StarIcon = styled(Icon)`
  font-size: 12px;
`

const PayButtonText = styled.div`
  transform: translateY(1px);
  margin-left: 8px;
`

export default TempButtons
