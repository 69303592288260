import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import styled from '@emotion/styled'
import UserForm from 'components/forms/UserForm'
import _ from 'lodash'
import { newDeletedList } from 'services/helper'
import { useUserSetup } from 'context/userSetupContext'
import { useSetUserStatus } from 'hooks/graphql'
import { useViewer } from 'context/viewerContext'
import { MemberStatus, OrganizationType, UserRole, UserStatus } from 'generated/graphql'
import {
  useMutateViewer,
  useMutateTermsToViewer,
  useOrg,
  useAppMetadata,
  useUpdateMemberStatus,
} from 'data/hooks'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import SetupHeader from 'components/global/SetupHeader'
import { useLazyApi } from 'hooks/useApi'

const initialValues = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  fileUrl: [],
}

const loadInitialValues = (user: any) => {
  const { first, last, details, practiceGroups } = user
  const jobTitle = _.get(details, 'jobTitle', '')

  return {
    ...initialValues,
    firstName: first || '',
    lastName: last || '',
    jobTitle,
    practiceGroup: practiceGroups.length > 0 ? practiceGroups.map(({ id }: any) => id) : [],
    twitter: '',
    linkedin: '',
  }
}

function CreateUser() {
  const { viewer } = useViewer()
  const { amplitude } = useUserSetup()
  const [redirect, setRedirect] = useState<any>('')
  const mutateViewer = useMutateViewer()
  const { setUserStatus } = useSetUserStatus()
  const mutateTermsToViewer = useMutateTermsToViewer()
  const baseOrgUrl = useBaseOrgUrl()
  const { organization } = useOrg()
  const { data: appMetadata } = useAppMetadata()
  const updateMemberStatus = useUpdateMemberStatus()
  const [welcomeEmail] = useLazyApi('company/welcome')

  const defaultValues = loadInitialValues(viewer)

  const onSubmit = async (values: any) => {
    const { firstName, lastName, jobTitle, fileUrl, practiceGroup, twitter, linkedin } = values

    const [createdTermIds, deletedTermIds] = newDeletedList(
      defaultValues.practiceGroup as number[],
      practiceGroup as number[]
    )

    if (appMetadata.assocOrg) {
      await updateMemberStatus.mutateAsync({
        status: MemberStatus.Active,
        organizationId: appMetadata.assocOrg.id,
      })
    }
    // match lawfirm user to association if exist
    await mutateTermsToViewer.mutateAsync({ createdTermIds, deletedTermIds })

    await mutateViewer.mutateAsync({
      first: firstName,
      last: lastName,
      fileUrl,
      twitter,
      linkedin,
      orgJobTitle: jobTitle,
      details: {},
    })

    amplitude?.logEventWithSavedProps('set_user_info', { action: 'update_information' })
    if (viewer.role === UserRole.Owner) {
      setUserStatus(UserStatus.CreateOrg)

      if (organization?.type === OrganizationType.Lawfirm) {
        setRedirect({
          pathname: '/setup/lawfirm-organization',
          state: { isVendorWizard: true, isUser: true, isAdmin: viewer.role === UserRole.Owner },
        })
      } else {
        setRedirect({
          pathname: '/setup/company-organization',
          state: { isVendorWizard: true, isUser: true, isAdmin: viewer.role === UserRole.Owner },
        })
      }
    } else {
      setUserStatus(UserStatus.Active)
      await welcomeEmail({ networkName: appMetadata.assocOrg && appMetadata.assocOrg.name })

      const pathname = appMetadata.assocOrg
        ? '/setup/network-success'
        : `${baseOrgUrl}/getting-started`

      setRedirect({ pathname, state: { showSuccess: true } })
    }
  }

  if (redirect) {
    return <Navigate to={redirect} />
  }

  const isAdmin = !viewer.currentOrganization || viewer.role === UserRole.Owner

  isAdmin && amplitude?.saveTrackedProps({ is_owner: true })

  return (
    <Root>
      <SetupHeader />
      <FormContainer>
        <h1>Your Basic Information</h1>
        <UserForm
          defaultValues={{ ...defaultValues }}
          onSubmit={onSubmit}
          showImageUpload={false}
          submitButtonText="Create User"
        />
      </FormContainer>
    </Root>
  )
}

const Root = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const FormContainer = styled.div`
  width: 100%;
  max-width: 760px;
  padding: 40px;
  margin: 0 auto;
`

export default CreateUser
