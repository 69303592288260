import React, { useState, useReducer, useEffect } from 'react'
import { CertificationUpdate, FormOne, FormTwo, Certifications } from './DiversityCertForms'
import { toast } from 'react-toastify'
// import { useAlgolia } from 'hooks/useAlgolia'
import { useCreateDiversityCert, useDiversityCerts } from 'data/hooks'

enum CertStep {
  BUSINESS_INFO = 'BUSINESS_INFO',
  CERTIFICATION = 'CERTIFICATION',
  CERTIFIED = 'CERTIFIED',
  UPDATE_CERTIFICATIONS = 'UPDATE_CERTIFICATIONS',
  LOADING = 'LOADING',
}

type UpdateObject = {
  name: string
  update: string | undefined // it will never actually be undefined, but typescript is annoyed about it.
}

const reducer = (state: any, { name, update }: UpdateObject) => {
  return { ...state, [name]: update }
}

const DiversityCert: React.FC = () => {
  const [certStep, setCertStep] = useState<CertStep>(CertStep.LOADING)
  const [fullCertification, setFullCertification] = useReducer(reducer, {
    businessName: '',
    businessIsDescribed: '',
    descriptor: '',
    certType: '',
    certificateName: '',
    certificateNumber: '',
    certificateProvider: '',
    expirationDate: '',
  })
  const { diversityCert, isLoading } = useDiversityCerts()
  const createCertification = useCreateDiversityCert()
  // const { partialUpdateObject } = useAlgolia()

  useEffect(() => {
    if (!isLoading && diversityCert && diversityCert?.length > 0) {
      return setCertStep(CertStep.CERTIFIED)
    } else {
      setCertStep(CertStep.BUSINESS_INFO)
    }
  }, [isLoading])

  /*
  const getProductIds = () => {
    return vendor?.products.map((product) => product.id)
  }
*/

  // const makeAlgoliaUpdateObjects = (certs: string[]) => {
  //   return getProductIds()?.map(product => ({
  //     id: product,
  //     certification: certs
  //   }))
  // }

  useEffect(() => {
    if (certStep === CertStep.UPDATE_CERTIFICATIONS) {
      const {
        businessName,
        descriptor,
        certificateProvider,
        certificateName,
        certificateNumber,
        expirationDate,
      } = fullCertification
      createCertification.mutate(
        {
          taxName: businessName,
          certNumber: certificateNumber,
          description: descriptor,
          expirationDate: expirationDate,
          name: certificateName,
          provider: certificateProvider,
        },
        {
          onSuccess: () => {
            setCertStep(CertStep.CERTIFIED)

            toast.success(
              'Your certification was saved and will appear on your products in the Marketplace!'
            )
            // TODO: PLEASE DEAL WITH THIS LATER, no one is using so not important
            /*
            refetch()
              .then(({ data }) => {
                const certification = data.diversityCertifications
                  .map((cert: CertificationUpdate) => cert.description)
                  .filter((description: string) => description !== 'undisclosed')
                const products = getProductIds()
                products?.forEach((product) => {
                  partialUpdateObject({
                    id: product,
                    diversityCertification: certification,
                  })
                })
              })
              .catch((error) => {
                setCertStep(CertStep.BUSINESS_INFO)
                console.error(error)
                toast.warn('Something went wrong, please try again!')
              })
            */
          },
        }
      )
    }
  }, [certStep])

  const updateFullCertification = (values: CertificationUpdate) => {
    const updates = Object.keys(values)
    updates.forEach((update) => {
      setFullCertification({ name: update, update: values[update] })
    })
  }

  const addACertification = () => {
    setCertStep(CertStep.BUSINESS_INFO)
  }

  return (
    <>
      {certStep === CertStep.BUSINESS_INFO && !isLoading && (
        <FormOne
          updateCert={updateFullCertification}
          goToNextStep={() => {
            setCertStep(CertStep.CERTIFICATION)
          }}
        />
      )}
      {certStep === CertStep.CERTIFICATION && (
        <FormTwo
          updateCert={updateFullCertification}
          goToNextStep={() => {
            setCertStep(CertStep.UPDATE_CERTIFICATIONS)
          }}
        />
      )}
      {certStep === CertStep.CERTIFIED && (
        <Certifications add={addACertification} certifications={diversityCert} />
      )}
    </>
  )
}

export default DiversityCert
