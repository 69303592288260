import React, { useEffect } from 'react'
import { useField } from 'formik'
import styled from '@emotion/styled'
import { InputStyle, FormLabel, FieldGroup } from './styles'
import { css } from '@emotion/react'

interface Props {
  name: string
  disabled?: boolean
  onQuantityChange: (quantity: number) => void
}

function QuantityInput({ disabled = false, onQuantityChange, ...props }: Props) {
  const [field, meta, helpers] = useField(props)

  useEffect(() => {
    if (disabled) helpers.setValue(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  const onChange = (e: any) => {
    const value = e.target.value
    helpers.setValue(value)

    if (value && value > 0) {
      onQuantityChange(value || 1)
    } else if (value === 0) {
      onQuantityChange(1)
    }
  }

  const onBlur = (e: any) => {
    const value = e.target.value

    if (!value || value === 0 || value === '0') {
      helpers.setValue(1)
      onQuantityChange(1)
    }
  }

  const onPlusClick = () => {
    const prevValue = field.value
    const newValue = prevValue + 1
    helpers.setValue(newValue)
    onQuantityChange(newValue)
  }

  const onMinusClick = () => {
    const prevValue = field.value
    if (prevValue > 1) {
      const newValue = prevValue - 1
      helpers.setValue(newValue)
      onQuantityChange(newValue)
    }
  }

  return (
    <Root>
      <FormLabel>Quantity</FormLabel>
      <QuantityWrapper>
        <Minus onClick={onMinusClick} type="button">
          -
        </Minus>
        <Input type="number" min="1" {...field} {...props} onBlur={onBlur} onChange={onChange} />
        <Plus onClick={onPlusClick} type="button">
          +
        </Plus>
      </QuantityWrapper>

      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </Root>
  )
}

const Root = styled.div`
  ${FieldGroup}
`

const PlusMinus = css`
  border: none;
  width: 40px;
  background: #fafafa;
  font-size: 18px;
  font-weight: 600;
  transition: background 200ms, color 200ms;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: #a287b3;
    color: white;
  }
`

const Plus = styled.button`
  ${PlusMinus}
  border-left: 1px solid #e8e8e8;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`

const Minus = styled.button`
  ${PlusMinus}
  border-right: 1px solid #e8e8e8;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`

const QuantityWrapper = styled.div`
  display: inline-flex;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
  border-radius: 6px;
`

const Input = styled.input`
  ${InputStyle}

  text-align: center;
  box-shadow: none;
  max-width: 60px;
  border-radius: 0;

  position: relative;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Error = styled.div``

export default QuantityInput
