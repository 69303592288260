import BaseModal from '../BaseModal'
import styled from '@emotion/styled'
import { WizardModalProvider, WizardStep, Data } from './WizardModalContext'
import WizardSidebar from './WizardSidebar'
import WizardMain from './WizardMain'

interface WizardModalProps {
  steps: WizardStep[]
  currentStep?: number
  onClose: () => void
  initialData?: Data
}
const WizardModal = ({ steps, currentStep, onClose, initialData }: WizardModalProps) => {
  return (
    <WizardModalProvider
      currentStepIndex={currentStep}
      initialData={initialData}
      steps={steps}
      closeModal={onClose}
    >
      <BaseModal toggleModal={onClose}>
        <Root>
          <WizardSidebar steps={steps} />
          <WizardMain />
        </Root>
      </BaseModal>
    </WizardModalProvider>
  )
}

const Root = styled.div`
  display: flex;
  min-height: 500px;
`

export default WizardModal
