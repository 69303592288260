import { gql } from 'graphql-request'

export const USER_CORE_FRAGMENT = gql`
  fragment UserCore on User {
    id
    first
    email
    details
    createdAt
    last
    locationId
    status
    currentOrganization
    updatedAt
    createdAt
    testMode
    jobTitle
    role
  }
`
