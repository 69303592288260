import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useGql } from 'hooks/useGql'
import { gql } from 'graphql-request'
import { GqlOrganizationNamesQuery } from 'generated/graphql'

export const useClientDropdown = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useQuery(
    ['client-names'],
    async () => {
      const data = await request<GqlOrganizationNamesQuery>(GET_ORGANIZATION_NAMES)
      const productToClientOptions = getClientOptions(data)

      return productToClientOptions
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
      staleTime: 1000 * 60 * 60,
    }
  )
}

type Client = {
  id: number
  name: string
}

const getClientOptions = (clientsData: GqlOrganizationNamesQuery) => {
  const clients = clientsData?.organizations || []

  return clients.map((client: Client) => ({
    key: client.id,
    text: client.name,
    value: client.id,
  }))
}

export const GET_ORGANIZATION_NAMES = gql`
  query OrganizationNames {
    organizations(condition: { type: LAWFIRM }) {
      id
      name
    }
  }
`
