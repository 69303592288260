import React, { useState, useEffect } from 'react'
import routes, { MenuRoute } from './routes'
import { Outlet } from 'react-router-dom'
import { BoxWrapper, Button } from 'components/theme'
import { CollapsingTabs } from '@theoremlts/theorem-design'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useOrg } from 'data/organization/useOrg'
import { useViewer } from 'context/viewerContext'
import { useQueryClient } from '@tanstack/react-query'
import InviteModal from 'components/modals/InviteModal'
import styled from '@emotion/styled'

const SettingsLayout = () => {
  const [menuItems, setMenuItems] = useState<Array<{ url: string; text: string }>>([])
  const [MenuRoutes, setMenuRoutes] = useState<MenuRoute[]>([])
  const { organization } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()
  const { viewer } = useViewer()
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const queryClient = useQueryClient()

  const onInviteUsers = () => {
    queryClient.invalidateQueries(['org'])
  }

  const toggleInviteModal = () => {
    setInviteModalOpen((prevVal) => !prevVal)
  }

  const getAccessibleRoutes = (routes: MenuRoute[]) =>
    routes.filter(
      ({ roleAccess }) => !roleAccess || (viewer.role && roleAccess.includes(viewer.role))
    )

  useEffect(() => {
    if (!baseOrgUrl || !organization?.type) return
    const menus = routes(baseOrgUrl)
    setMenuRoutes(menus[organization.type])
  }, [baseOrgUrl, organization?.type, viewer.role])

  useEffect(() => {
    if (MenuRoutes.length === 0) return
    const menuItems = getAccessibleRoutes(MenuRoutes).map(({ url, text, exact }) => ({
      url,
      text,
      exact: exact?.toString(),
    }))
    setMenuItems(menuItems)
  }, [MenuRoutes])

  return (
    <BoxWrapper>
      <ButtonWrapper>
        <AddMemberButton onClick={toggleInviteModal}>+ New Member</AddMemberButton>
      </ButtonWrapper>
      {menuItems.length > 0 && <CollapsingTabs menuItems={menuItems} />}
      <Outlet />
      <InviteModal
        open={inviteModalOpen}
        onModalClose={toggleInviteModal}
        onInvite={onInviteUsers}
      />
    </BoxWrapper>
  )
}

const ButtonWrapper = styled.div`
  text-align: right;
  margin-bottom: -50px;
`

const AddMemberButton = styled(Button)`
  white-space: nowrap;
  padding: 4px 16px;
  height: 32px;
  margin: 8px 0;
`

export default SettingsLayout
