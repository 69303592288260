import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { useState } from 'react'
import AddMenu from './AddMenu'

const AddSectionButton = () => {
  const [isShowMenu, setIsShowMenu] = useState(false)

  const toggleMenu = () => {
    setIsShowMenu((prevVal) => !prevVal)
  }

  return (
    <Root>
      {isShowMenu && <AddMenu toggleMenu={toggleMenu} />}
      <AddButton onClick={toggleMenu}>
        <PlusIcon name="plus" />
      </AddButton>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
`

const AddButton = styled.div`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid #5d6079;
  border-radius: 999px;
  background: #9dcbe6;
  margin: 0 auto;
  display: flex;
  width: 48px;
  height: 48px;
`

const PlusIcon = styled(Icon)`
  font-size: 24px;
`

export default AddSectionButton
