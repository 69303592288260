import React from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'

type Props = {
  step: number
  maxStep: number
  isSubmitting?: boolean
  setStep: (step: number) => void
  altSubmitText?: string
}

const StepperButtons: React.FC<Props> = ({ step, maxStep, setStep, isSubmitting, altSubmitText }) => {
  return (
    <FormSteppers>
      {step !== 0 && (
      <>
        <StepperButton
          type="button"
          onClick={() => {
            const prevStep = step - 1
            setStep(prevStep)
          }}
          disabled={step === 0}
          style={step === 0 ? { color: '#607d8b' } : {}}
        >
          previous
        </StepperButton>
        {` | `}
      </>
      )}
      {step < maxStep && <StepperButton type="submit">next</StepperButton>}
      {step === maxStep && (
        <SubmitButton loading={isSubmitting} disabled={isSubmitting} type="submit">
          { altSubmitText || 'Submit' }
        </SubmitButton>
      )}
    </FormSteppers>
  )
}

const FormSteppers = styled.nav`
  margin-top: 8px;
`

const StepperButton = styled.button`
  background: none;
  border: none;
  color: #2196f3;
`

const SubmitButton = styled(Button)`
  background: #a333c8;
  border-color: #a333c8;
  color: white;
  margin-right: 16px;

  &:disabled,
  &[disabled] {
    border: 2px solid '#9e9e9e';
    color: '#9e9e9e';

    &:hover {
      background: transparent;
      cursor: not-allowed;
    }
  }
`

export default StepperButtons