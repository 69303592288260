import React, { ReactNode } from 'react'
import { useWizard } from 'hooks/useWizard'
import _ from 'lodash'
import { useMatch } from 'react-router-dom'
import { useViewer } from './viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { OrganizationType } from 'generated/graphql'

const CreateProductWizardContext = React.createContext({} as any)

interface Props {
  children: ReactNode
  pages: any
}
export const CreateProductWizardProvider = ({ children, pages }: Props) => {
  const { organization } = useOrg()
  const { isSuperAdmin, isActive } = useViewer()
  const match = useMatch(':adminId/product/new')
  const wizard = useWizard({ pages })

  const params = match?.params
  const storedProductId = localStorage.getItem('productId')

  let currentProductId

  if (storedProductId === 'null') {
    currentProductId = null
  } else if (storedProductId) {
    currentProductId = parseInt(storedProductId, 10)

    if (organization) {
      if (
        (organization.type === OrganizationType.Vendor ||
          organization.type === OrganizationType.Lawfirm) &&
        organization.products &&
        organization.products.length === 0
      ) {
        localStorage.setItem('productId', 'null')
        currentProductId = null
      }
    }
  } else {
    // HACK: Only commit this statement when in setup mode. If user is active they are not in setup
    if (!isActive) currentProductId = _.get(organization, 'products[0].id', null)
  }

  if (isSuperAdmin && params?.adminId) currentProductId = null

  return (
    <CreateProductWizardContext.Provider value={{ ...wizard, currentProductId }}>
      {children}
    </CreateProductWizardContext.Provider>
  )
}

export const useCreateProductWizard = () => React.useContext(CreateProductWizardContext)
