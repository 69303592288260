import styled from '@emotion/styled'

type Props<T> = {
  value: T
  isChecked?: boolean
  onChange: (value: any, checked: boolean) => void
}

const Switch = <T extends any>({ isChecked = false, value, onChange }: Props<T>) => {
  return (
    <Wrapper isChecked={isChecked}>
      <CheckboxInput
        type="checkbox"
        checked={isChecked}
        onChange={() => onChange(value, !isChecked)}
      />
      <SwitchToggle isChecked={isChecked} />
    </Wrapper>
  )
}

const SwitchToggle = styled.div<{ isChecked: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 400px;
  background: ${(props) => (props.isChecked ? '#4cd0d1' : '#b69bc7')};
  transform: translateX(${(props) => (props.isChecked ? '20px' : '0')});
  transition: background 350ms, transform 250ms;
`

const Wrapper = styled.label<{ isChecked: boolean }>`
  width: 48px;
  display: block;
  background: ${(props) => (props.isChecked ? '#ececec' : '#dadada')};
  cursor: pointer;
  border-radius: 400px;
  border: 2px solid #888;
  padding: 2px;
  transition: background 250ms;

  &:hover {
    background: #ccc;

    ${SwitchToggle} {
      background: ${(props) => (props.isChecked ? '#1e9d9e' : '#a273bf')};
    }
  }
`

const CheckboxInput = styled.input`
  display: none;
`

export default Switch
