import { Routes, Route } from 'react-router-dom'
import {
  CreateProductWizardProvider,
  useCreateProductWizard,
} from 'context/CreateProductWizardContext'
// import VendorHeader from 'components/global/VendorHeader'
import { Icon } from 'semantic-ui-react'
import styled from '@emotion/styled'
import BasicProductInfo from './BasicProductInfo'
import CategoriesForm from './CategoriesForm'
import DescriptionForm from './DescriptionForm'
import ExtraForm from './ExtraForm'

const pages: [string, any][] = [
  ['basic-info', BasicProductInfo],
  ['categories', CategoriesForm],
  ['description', DescriptionForm],
  ['extra', ExtraForm],
]

// const headerText = [
//   {
//     title: 'Required New Product Info',
//     subtitle: 'You can add more products later',
//   },
//   {
//     title: 'Add Product Category',
//     subtitle: 'Keywords should relate to legal tasks that your service optimizes',
//   },
//   {
//     title: 'Main Description',
//     subtitle: 'A place for you to tell your customers more info',
//   },
//   {
//     title: 'Additional info',
//     subtitle: 'Optional Info, we recommend images',
//   },
// ]

function CreateProductWizard({ className = undefined }: any) {
  const { atFirstStep, goPrevStep } = useCreateProductWizard()
  return (
    <Root className={className}>
      <BackButtonContainer>
        {!atFirstStep && (
          <BackButton onClick={goPrevStep}>
            <Icon name="angle left" />
            Back
          </BackButton>
        )}
      </BackButtonContainer>

      <Routes>
        {pages.map(([slug, Component]) => (
          <Route key={slug} path={slug}>
            <Component />
          </Route>
        ))}
      </Routes>
    </Root>
  )
}

const WithCreateProductWizardProvider = ({ showHeader = true, className = null }) => (
  <CreateProductWizardProvider pages={pages}>
    <CreateProductWizard className={className} showHeader={showHeader} />
  </CreateProductWizardProvider>
)

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const BackButtonContainer = styled.div`
  width: 100%;
  max-width: 760px;
  margin: 30px auto 0;
  padding: 0;
`

const BackButton = styled.button`
  background: transparent;
  border: none;
  color: #777;
  font-weight: 500;

  &:hover {
    color: #222;
  }
`

export default WithCreateProductWizardProvider
