import styled from '@emotion/styled'
import { SUBSCRIPTION_AGREEMENT, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from 'services/globalAssets'

export default function PrivacyTerms() {
  return (
    <PolicyText>
      <p>
        By creating a Theorem account, you’re agreeing to accept the <br />
        <a href={TERMS_AND_CONDITIONS}>Theorem Terms of Use</a> &amp;{' '}
        <a href={SUBSCRIPTION_AGREEMENT} target="_blank" rel="noopener noreferrer">
          Theorem Subscription Terms
        </a>
      </p>
      <hr />
      <p>
        We’re committed to your privacy. Theorem uses the information our users provide to us to
        contact you about our relevant content, products, and services. You may unsubscribe from
        these communications at any time. For more information, check out our{' '}
        <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </p>
    </PolicyText>
  )
}

const PolicyText = styled.div`
  max-width: 550px;
  margin: 20px auto 0;
  text-align: center;

  p {
    letter-spacing: 0.25px;
    line-height: 1.8;
  }

  a {
    font-weight: 600;
  }

  hr {
    margin-bottom: 15px;
    margin-top: 15px;
  }
`
