import { useState, useEffect } from 'react'
import { useTrackingChartContext } from 'context/trackingChartContext'
import NumberCard from 'components/charts/NumberCard'
import { useLazyApi } from 'hooks/useApi'
import _ from 'lodash'

function TotalPageHits() {
  const {
    startDate,
    endDate,
    currentLoadingQueue,
    setCurrentLoadingQueue,
    productToAnalyze: product,
  } = useTrackingChartContext()
  const [getProductPageHit] = useLazyApi('tracking/amplitude/product-page-hit')
  const [number, setNumber] = useState(0)

  useEffect(() => {
    if (currentLoadingQueue !== 0) return
    ;(async () => {
      const result = await getProductPageHit({ startDate, endDate, id: product })
      const series = _.get(result, 'series[0]', [])
      const totalHits = series.reduce((a: number, b: number) => a + b, 0)

      setNumber(totalHits)
      setCurrentLoadingQueue((prevVal: number) => prevVal + 1)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentLoadingQueue, product])

  return <NumberCard title="Total Page Views" number={number} />
}

export default TotalPageHits
