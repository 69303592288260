import React from 'react'
import theming from 'services/theming'
import GlobalStylesheet from 'services/globalStyles'
import { Global, ThemeProvider } from '@emotion/react'
import Routes from 'components/Routes'
import { BrowserRouter } from 'react-router-dom'
import { UserSetupProvider } from 'context/userSetupContext'
import { UserStoriesProvider } from 'context/userStoriesContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ViewerProvider } from 'context/viewerContext'
import { useAuth0 } from '@auth0/auth0-react'
import { FullPageLoading } from './PulsatingLoader'

function App() {
  const { isLoading } = useAuth0()

  if (isLoading) return <FullPageLoading />

  return (
    <QueryClientProvider client={new QueryClient()}>
      <BrowserRouter>
        <ViewerProvider>
          <React.StrictMode>
            <ThemeProvider theme={theming as any}>
              <UserSetupProvider>
                <Global styles={GlobalStylesheet} />
                <UserStoriesProvider>
                  <Routes />
                </UserStoriesProvider>
              </UserSetupProvider>
            </ThemeProvider>
          </React.StrictMode>
        </ViewerProvider>
      </BrowserRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
