import React from 'react'
import styled from '@emotion/styled'
import { Message } from 'semantic-ui-react'

export default function CustomMessage({
  className = null,
  title,
  text,
  error = false,
  success = false,
  info = false,
}: any) {
  return (
    <MessageContainer error={error} success={success} info={info} className={className}>
      <Message.Header>{title}</Message.Header>
      <Message.Content>{text}</Message.Content>
    </MessageContainer>
  )
}

const MessageContainer = styled(Message)`
  max-width: 700px !important;
  margin: auto !important;
  margin-top: 24px !important;
`
