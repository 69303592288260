import React from 'react'
import styled from '@emotion/styled'
import { Icon, Button, Dropdown } from 'semantic-ui-react'
import { Formik, Form } from 'formik'
import { TextInput, Label, FormField } from 'components/theme/form'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { useTaxonomyDropdown } from 'hooks/useTaxonomyDropdown'
import { FullPageLoading } from 'components/PulsatingLoader'
import * as yup from 'yup'
import { newDeletedList } from 'services/helper'
import { useMutateTermsToViewer, useMutateViewer } from 'data/hooks'

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
})

type Values = {
  firstName: string
  lastName: string
  jobTitle: string
  fileUrl: any
  twitter: string
  linkedin: string
  practiceGroup: number[]
  roles: number[]
  firmType: number[]
}

export const initialValues: Values = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  fileUrl: [],
  twitter: '',
  linkedin: '',
  practiceGroup: [],
  roles: [],
  firmType: [],
}

interface Props {
  defaultValues: Values
  submitButtonText?: string
  onSubmit: () => void
}

const UserForm: React.FC<Props> = ({
  defaultValues = initialValues,
  submitButtonText = 'Create User',
  onSubmit,
}) => {
  const mutateViewer = useMutateViewer()
  const mutateTermsToViewer = useMutateTermsToViewer()
  const { options: practiceGroupOptions, isLoading: practiceGroupLoading } = useTaxonomyDropdown({
    taxonomyId: 13,
  })
  const { options: rolesOptions, isLoading: rolesLoading } = useTaxonomyDropdown({
    taxonomyId: 8,
  })
  const { options: firmTypeOptions, isLoading: firmTypeLoading } = useTaxonomyDropdown({
    taxonomyId: 17,
  })

  if (practiceGroupLoading || rolesLoading || firmTypeLoading) return <FullPageLoading />

  const onSubmitForm = async (values: Values) => {
    const {
      firstName,
      lastName,
      jobTitle,
      fileUrl,
      twitter,
      linkedin,
      practiceGroup,
      firmType,
      roles,
    } = values

    const [createdTermIds, deletedTermIds] = newDeletedList(
      [...defaultValues.practiceGroup, ...defaultValues.roles, ...defaultValues.firmType],
      [...practiceGroup, ...roles, ...firmType]
    )

    await mutateTermsToViewer.mutateAsync({ deletedTermIds, createdTermIds })

    await mutateViewer.mutateAsync({
      first: firstName || '',
      last: lastName || '',
      jobTitle,
      fileUrl,
      linkedin,
      twitter,
    })

    onSubmit()
  }

  return (
    <Root>
      <FormContainer>
        <Formik
          initialValues={{ ...defaultValues }}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
        >
          {({ values, isSubmitting, setFieldValue, isValid }) => (
            <Form>
              <FormField>
                <UploadSingleAsset
                  name="fileUrl"
                  label="Profile Picture"
                  selectedImage={values.fileUrl}
                >
                  <UploadContent>
                    <UploadIcon name="user" />
                    <span>Upload Picture</span>
                  </UploadContent>
                </UploadSingleAsset>
              </FormField>

              <FormField>
                <TextInput
                  name="firstName"
                  label="First Name"
                  placeholder="First Name..."
                  required
                />
              </FormField>

              <FormField>
                <TextInput name="lastName" label="Last Name" placeholder="Last name..." required />
              </FormField>

              <FormField>
                <TextInput name="jobTitle" label="Job Title" placeholder="Job Title..." required />
              </FormField>

              <FormField>
                <Label>Roles</Label>
                <Dropdown
                  fluid
                  selection
                  search
                  value={values.roles}
                  onChange={(_e, { value }) => setFieldValue('roles', value)}
                  multiple
                  options={rolesOptions}
                  name="roles"
                />
              </FormField>

              <FormField>
                <Label>Practice Group</Label>
                <Dropdown
                  fluid
                  selection
                  search
                  value={values.practiceGroup}
                  onChange={(_e, { value }) => setFieldValue('practiceGroup', value)}
                  multiple
                  options={practiceGroupOptions}
                  name="practiceGroup"
                />
              </FormField>

              <FormField>
                <Label>Company Type</Label>
                <Dropdown
                  fluid
                  selection
                  search
                  value={values.firmType}
                  onChange={(_e, { value }) => setFieldValue('firmType', value)}
                  multiple
                  options={firmTypeOptions}
                  name="firmType"
                />
              </FormField>
              <FormField>
                <SplitInput>
                  <Left>
                    <TextInput
                      label="LinkedIn Profile"
                      placeholder="https://www.linkedin.com/in/john-doe"
                      name="linkedin"
                    />
                  </Left>
                  <Right>
                    <TextInput label="Twitter Handle" placeholder="@TheoremLTS" name="twitter" />
                  </Right>
                </SplitInput>
              </FormField>
              <FormField>
                <Button
                  disabled={isSubmitting || !isValid}
                  loading={isSubmitting}
                  primary
                  icon
                  labelPosition="right"
                  type="submit"
                >
                  {submitButtonText}
                  <Icon name="user" />
                </Button>
              </FormField>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
`

const UploadContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const UploadIcon = styled(Icon)`
  font-size: 50px !important;
  height: 40px !important;
  margin-top: 10px !important;
  margin-right: 0 !important;
  opacity: 0.7 !important;
  color: #4d5065;
`

const SplitInput = styled.div`
  display: flex;
`

const Left = styled.div`
  width: 50%;
  margin-right: 10px;
`

const Right = styled.div`
  width: 50%;
  margin-left: 10px;
`

const FormContainer = styled.div``

export default UserForm
