import { useOrgProducts } from 'data/hooks'

const networks = [
  { name: 'CLOC', slug: 'cloc' },
  { name: 'ACC', slug: 'acc' },
  { name: 'ELTA', slug: 'elta' },
]

export const useGetUnlistedProducts = () => {
  const { products, ...other } = useOrgProducts()

  if (!products) return { unlistedProducts: [], ...other }

  const unlistedProducts = networks.map(({ name, slug }) => {
    return {
      network: { name, slug },
      products: products.filter(
        ({ productListings }) =>
          !productListings.some(({ association }) => association.slug === slug)
      ),
    }
  })

  return { unlistedProducts, ...other }
}
