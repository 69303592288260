import styled from '@emotion/styled'
import React from 'react'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import { getCurrencySymbol } from 'services/helper'

interface Props {
  moneyValue: number | undefined
  currencyValue: string | undefined
  onMoneyChange: (value: number | undefined) => void
  onCurrencyChange: (value: string) => void
  placeholder?: string
}

const MoneyCurrencyField: React.FC<Props> = ({
  moneyValue,
  onMoneyChange,
  currencyValue,
  onCurrencyChange,
  placeholder = '',
}) => {
  const parsedValue = moneyValue ? moneyValue / 100 : ''

  const onValueChange: CurrencyInputProps['onValueChange'] = (moneyValue): void => {
    if (typeof moneyValue === 'string' && !Number.isNaN(Number(moneyValue))) {
      const stripeFormat = moneyValue ? Number(moneyValue) * 100 : Number(moneyValue)
      onMoneyChange(stripeFormat)
    } else {
      onMoneyChange(0)
    }
  }

  return (
    <Root>
      <CurrencyTextInput
        intlConfig={{ locale: 'en-US', currency: currencyValue || 'USD' }}
        placeholder={placeholder || getCurrencySymbol(currencyValue || 'USD')}
        onValueChange={onValueChange}
        defaultValue={parsedValue} // value should be devided before it's passed here.
        value={parsedValue}
      />
      <Select
        value={currencyValue}
        onChange={(e) => {
          onCurrencyChange(e.target.value)
        }}
      >
        {currencyOptions.map((option) => (
          <option value={option.value} key={option.value}>
            {option.value}
          </option>
        ))}
      </Select>
    </Root>
  )
}

const currencyOptions = [
  {
    value: 'USD',
    text: 'USD',
    longText: 'US Dollar',
  },
  {
    value: 'CAD',
    text: 'CAD',
    longText: 'Canadian Dollar',
  },
  {
    value: 'EUR',
    text: 'EUR',
    longText: 'Euro',
  },
  {
    value: 'GBP',
    text: 'GBP',
    longText: 'British Pound',
  },
  {
    value: 'CNY',
    text: 'CNY',
    longText: 'Chinese Yuan Renminbi',
  },
  {
    value: 'SGD',
    text: 'SGD',
    longText: 'Singapore Dollar',
  },
  {
    value: 'JPY',
    text: 'JPY',
    longText: 'Japanese Yen',
  },
  {
    value: 'AUD',
    text: 'AUD',
    longText: 'Australian Dollar',
  },
]

const Root = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  display: flex;
  padding-right: 4px;
`

const CurrencyTextInput = styled(CurrencyInput)`
  padding: 9.5px 14px;
  box-shadow: none;
  border: 0;
  outline: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease, border-color 0.1s ease;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  flex: 1 0 auto;

  &::-webkit-input-placeholder {
    color: #616161;
  }

  &:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

const Select = styled.select`
  border: 0;
  outline: 0;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0 8px;
  cursor: pointer;
`

export default MoneyCurrencyField
