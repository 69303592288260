import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { Field, useFormikContext } from 'formik'
import { FormField, Label, Dropdown } from 'components/theme/form'
import { FormTitle, Description, ErrorText } from './styles'
import ExcerptDescription from 'components/ExcerptDescription'
import { StoryUpdates } from 'interfaces/userStory/Helpers'
import { useOrg } from 'data/organization/useOrg'
import { useAllProducts } from 'data/hooks'

type RawProductOptions = Array<{
  [key: string]: any
  name: string
  id: number
}>

type Options = { text: string; key: string; value: number }

const MainStoryForm: React.FC<{ onChange: (values: StoryUpdates) => void }> = ({ onChange }) => {
  const [productOptions, setProductOptions] = useState<Options[]>([])
  const { products, isLoading: productsLoading } = useAllProducts()
  const { organization, vendor } = useOrg()
  const { setFieldValue, values, errors, touched, initialValues } = useFormikContext<{
    when: string
    productId: number
    featureType: number
    featureName: string
    to: string
  }>()
  const { productId } = useParams<{ productId: string }>()

  useEffect(() => {
    if (!productId || productOptions.length === 0) return
    const name = findProductName(Number(productId))
    setFieldValue('productId', Number(productId))
    onChange({ product: { name, id: Number(productId) } })
  }, [JSON.stringify(productOptions)])

  useEffect(() => {
    initialValues.featureName = values.featureName
  }, [values.featureName])

  useEffect(() => {
    initialValues.when = values.when
  }, [values.when])

  useEffect(() => {
    initialValues.to = values.to
  }, [values.to])

  useEffect(() => {
    onChange(initialValues)
  }, [JSON.stringify(initialValues)])

  const makeDropdownOptions = (optionsFromData: RawProductOptions) => {
    return optionsFromData.map(({ name, id }) => ({
      text: name,
      value: id,
      key: `option-${id}`,
    }))
  }

  const makeFeatureOptions = (features: string[]) => {
    return features.map((option, i: number) => ({
      text: option,
      value: option,
      key: `option-${option}-${i}`,
    }))
  }

  useEffect(() => {
    if (productsLoading) return
    const options =
      organization?.type === 'VENDOR' && vendor
        ? makeDropdownOptions(vendor.products)
        : makeDropdownOptions(products)
    setProductOptions(options)
  }, [productsLoading, vendor?.products])

  const findProductName = (id: number) => {
    if (!id) return ''
    const product = productOptions.find((product) => product.value === id)
    const productName = product?.text || ''
    return productName
  }

  const findProductFeatures = (productID: number) => {
    if (!productID || productsLoading) return []
    const allProducts: any =
      organization?.type === 'LAWFIRM' ? products : vendor ? vendor.products : []
    const product = allProducts.find(({ id }: any) => id === productID)
    if (product?.details.features) {
      return makeFeatureOptions(product.details.features)
    } else {
      return []
    }
  }

  const changeFeatureType = (newType: number) => {
    setFieldValue('featureName', '')
    setFieldValue('featureType', newType)
    initialValues.featureType = newType
  }

  if (productsLoading) return <></>

  return (
    <>
      <FormTitle>The User Story</FormTitle>
      <Description>
        Describe what triggers the use of this product (when and why it's used)
      </Description>
      <FormField>
        <Label required children={'When'} />
        <TextInput name="when" type="text" />
        <ExcerptDescription description={values.when} maxLength={200} />
        {errors.when && touched.when && <ErrorText>{errors.when}</ErrorText>}
      </FormField>
      <FormField>
        <Dropdown
          style={values.productId === 0 ? { color: '#616161' } : {}}
          name="productId"
          search={!productId}
          options={productOptions}
          label="Use"
          disabled={!!productId}
          placeholder="select product"
          value={productId ? Number(productId) : values.productId}
          setFieldValue={setFieldValue}
          onChange={(_e: any, { value }: { value: number }) => {
            setFieldValue('featureName', '')
            setFieldValue('featureType', 0)
            setFieldValue('productId', value)
            const name = products.find(({ id }: any) => id === value)?.name
            setFieldValue('productName', name)
            onChange({ product: { name: name || '', id: value } })
            initialValues.productId = value
            findProductName(value)
            findProductFeatures(value)
          }}
          required
        />
        {errors.productId && touched.productId && <ErrorText>{errors.productId}</ErrorText>}
      </FormField>
      <FormField>
        <RadioGroup role="group" aria-labelledby="my-radio-group">
          <RadioOption>
            <input
              type="radio"
              readOnly
              checked={values.featureType === 0}
              onClick={() => {
                changeFeatureType(0)
              }}
              value={0}
            />{' '}
            <RadioLabel
              onClick={() => {
                changeFeatureType(0)
              }}
            >
              None
            </RadioLabel>
          </RadioOption>
          <RadioOption>
            <input
              type="radio"
              readOnly
              checked={values.featureType === 1}
              disabled={values.productId === undefined}
              value={1}
              onClick={() => {
                if (values.productId !== undefined) changeFeatureType(1)
              }}
            />{' '}
            <RadioLabel
              style={values.productId === undefined ? { color: '#616161 !important' } : {}}
              onClick={() => {
                if (values.productId !== undefined) changeFeatureType(1)
              }}
            >
              Choose an existing feature
            </RadioLabel>
          </RadioOption>
          <RadioOption>
            <input
              type="radio"
              checked={values.featureType === 2}
              readOnly
              value={2}
              onClick={() => {
                changeFeatureType(2)
              }}
            />
            <RadioLabel
              onClick={() => {
                changeFeatureType(2)
              }}
            >
              Create a custom feature
            </RadioLabel>
          </RadioOption>
        </RadioGroup>
      </FormField>
      <FormField>
        {values.featureType === 1 && (
          <Dropdown
            style={values.featureName === '' ? { color: '#616161 !important' } : {}}
            name="featureName"
            search
            options={findProductFeatures(values.productId)}
            label=""
            setFieldValue={setFieldValue}
            value={values.featureName}
            onChange={(e: any, { value }: { value: string }) => {
              setFieldValue('featureName', value)
            }}
            placeholder="choose feature"
            disabled={values.productId === 0}
          />
        )}
        {values.featureType === 2 && (
          <TextInput name="featureName" placeholder="name a feature" type="text" />
        )}
      </FormField>
      <FormField>
        <Label required children={'To'} />
        <TextInput name="to" type="text" />
        <ExcerptDescription description={values.when} maxLength={200} />
        {errors.to && touched.to && <ErrorText>{errors.to}</ErrorText>}
      </FormField>
    </>
  )
}

const TextInput = styled(Field)`
  width: 100%;
  padding: 9.5px 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease, border-color 0.1s ease;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  flex: 1 0 auto;

  &::-webkit-input-placeholder {
    color: #616161;
  }

  &:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
`

const RadioOption = styled.div`
  display: flex;
  align-items: center;
`

const RadioLabel = styled.div`
  cursor: default;
  margin-left: 4px;
`

export default MainStoryForm
