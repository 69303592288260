import React from 'react'
import styled from '@emotion/styled'
import { FullPageLoading } from 'components/PulsatingLoader'
import { BoxWrapper } from 'components/theme'
import { useAssocToOrgByOrgId } from 'data/hooks'
import AssocBox from './AssocBox'

const VendorNetworks = () => {
  const { assocToOrg, isLoading } = useAssocToOrgByOrgId()

  if (!assocToOrg || isLoading) return <FullPageLoading />

  return (
    <Root>
      <BoxWrapper>
        {assocToOrg.length > 0 ? (
          <>
            <Title>Networks your organization is involved with:</Title>
            <AssocBoxes>
              {assocToOrg.map(({ association, status }) => (
                <AssocBox
                  key={association.id}
                  logo={association.details.logo}
                  status={status}
                  {...association}
                />
              ))}
            </AssocBoxes>
          </>
        ) : (
          <Title>You are not part of an network</Title>
        )}
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

const Title = styled.h3``

const AssocBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default VendorNetworks
