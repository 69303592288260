import { useEffect } from 'react'
import { useLazyApi } from 'hooks/useApi'
import styled from '@emotion/styled'
import SuccessImg from './success.png'
import { BoxWrapper } from 'components/theme'
import { useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { useRedirect } from 'hooks/useRedirect'

function UpgradeSuccess() {
  const redirect = useRedirect()
  const { organization } = useOrg()
  const queryClient = useQueryClient()
  const [createSubscription] = useLazyApi('stripe/organization/handle-checkout')

  useEffect(() => {
    ;(async () => {
      const sessionId = getQueryParameter('session_id')

      // if (sessionId) localStorage.setItem('stripeCheckoutSessionId', sessionId) // store session id if still loading

      if (!sessionId) return

      await createSubscription({ organizationId: organization?.id, sessionId })

      localStorage.removeItem('stripeCheckoutSessionId')

      queryClient.invalidateQueries(['viewer'])

      redirect('upgrade/success')
    })()
  }, [])

  return (
    <Root>
      <BoxWrapper>
        <Title>Congrats, You've upgraded!</Title>
        <Image src={SuccessImg} />
        <Subtitle>
          You can now <InviteButton>invite your team!</InviteButton>
        </Subtitle>
      </BoxWrapper>
    </Root>
  )
}

const getQueryParameter = (param: string) =>
  new URLSearchParams(document.location.search.substring(1)).get(param)

const Root = styled.div`
  text-align: center;
`

const Title = styled.h2``

const Image = styled.img`
  width: 100%;
  max-width: 420px;
`

const Subtitle = styled.p`
  font-size: 18px;
`

const InviteButton = styled.a``

export default UpgradeSuccess
