import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useSetUserStatus } from 'hooks/graphql'
import { useLazyApi } from 'hooks/useApi'
import { useNavigate } from 'react-router'
import { useUserSetup } from 'context/userSetupContext'
import NextSteps from './NextSteps'
import { Button } from 'components/theme'
import { logEvent } from '@theoremlts/theorem-design'
import { useAuth0 } from '@auth0/auth0-react'
import { useOrg } from 'data/organization/useOrg'
import { OrganizationType, UserStatus } from 'generated/graphql'
import { useViewer } from 'context/viewerContext'
import { useSwitchOrg } from 'data/viewer/useSwitchOrg'

interface Props {
  inviterOrgId?: string
  inviterOrgName?: string
  inviterOrgType?: OrganizationType
  inviterTermPdf?: string
}

// TODO: Refactor this so that we are passing these variables by prop. The issue right now is that
// we are also using this component in <Routes />. This component always needs a parent component
// that manages its props
const OrganizationInvite = ({
  inviterOrgId,
  inviterOrgName,
  inviterOrgType,
  inviterTermPdf,
}: Props) => {
  const { setUserStatus } = useSetUserStatus()
  const { organization } = useOrg()
  const [addTeammate] = useLazyApi('stripe/organization/add-teammate')
  const [submitting, setSubmitting] = useState(false)
  const switchOrg = useSwitchOrg()
  const { viewer } = useViewer()
  const navigate = useNavigate()
  const { amplitude } = useUserSetup()
  const { logout } = useAuth0()

  const orgName = inviterOrgName || organization?.name
  const orgType = inviterOrgType || organization?.type

  const continueClick = async () => {
    if (submitting || !inviterOrgId) return
    setSubmitting(true)

    try {
      await addTeammate({ orgId: inviterOrgId })
      await switchOrg.mutateAsync(inviterOrgId)

      if (viewer.status === UserStatus.Active) {
        navigate(`/${inviterOrgType?.toLowerCase()}/${inviterOrgId}`)

        return
      }

      if (orgType === OrganizationType.Lawfirm) {
        await setUserStatus(UserStatus.CreateUser)
        navigate(`/setup/lawfirm-user`)
      } else if (orgType === OrganizationType.Vendor) {
        await setUserStatus(UserStatus.CreateUser)
        navigate(`/setup/vendor-user`)
      } else if (orgType === OrganizationType.Company) {
        await setUserStatus(UserStatus.CreateUser)
        navigate(`/setup/company-user`)
      } else {
        await setUserStatus(UserStatus.AssocUser)
        navigate(`/setup/association-user`)
      }

      amplitude?.logEventWithSavedProps('approve_invite', {
        organization_type: orgType as any,
        flow: 'invite',
      })
    } catch (e) {
      setSubmitting(false)
      console.error('there was an error with signup')
    }
  }

  const legalLinks = getLegalLinks(orgType, inviterTermPdf)

  return (
    <Root>
      <LogoutButton
        onClick={() => {
          logEvent('log_out')
          logout()
        }}
      >
        Logout
      </LogoutButton>
      <NextSteps
        orgName={orgName || ''}
        isSubmitting={submitting}
        continueClick={continueClick}
        legalLinks={legalLinks}
      />
    </Root>
  )
}

interface LegalLink {
  text: string
  link: string
}
const getLegalLinks = (orgType: OrganizationType | undefined, termsPdf?: string): LegalLink[] => {
  const legalLinks: LegalLink[] = []
  if (orgType !== OrganizationType.Association && orgType !== OrganizationType.Lawfirm) {
    return legalLinks
  }

  if (termsPdf) {
    legalLinks.push({
      text: 'Association Terms',
      link: termsPdf,
    })
  }
  return legalLinks
}

const Root = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LogoutButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
`

export default OrganizationInvite
