import styled from '@emotion/styled'
import { Button } from 'components/theme'
import { Checkbox } from 'components/theme/form'
import { SUBSCRIPTION_AGREEMENT, TERMS_AND_CONDITIONS } from 'services/globalAssets'
import { useAppMetadata, useOrg } from 'data/hooks'
import { useTermsCheckbox } from './useTermsCheckbox'
import { useAgreeTerms } from './useAgreeTerms'
import TheoremLogoPng from 'assets/purple-logo.png'
import TheoremLogoLongWhite from 'assets/logo-long-white.svg'
import LogoutButton from 'components/theme/LogoutButton'
import PurpleLogoPng from 'assets/purple-logo.png'
import LineDividerSvg from 'assets/networks/line-divider.svg'

interface LegalLink {
  text: string
  link: string
}

const TermsAndConditions = () => {
  const { organization } = useOrg()
  const { data: appMetadata } = useAppMetadata()
  const { selectedCheckbox, clickCheckbox } = useTermsCheckbox()
  const { onAgree, isSubmitting } = useAgreeTerms()

  const associationTerms = appMetadata?.assocOrg?.details?.termsPdf?.url
  const companyName = appMetadata?.assocOrg?.name || organization?.name
  const logo = appMetadata?.assocOrg?.details?.logo || organization?.details?.logo

  return (
    <Root>
      <LogoutButton isWhite />
      <Container>
        <TheoremWhiteLogo src={TheoremLogoLongWhite} />
        <Wrapper>
          {logo ? (
            <LogoConnect>
              <TheoremLogo src={PurpleLogoPng} />
              <LineImage src={LineDividerSvg} />
              <AccLogo src={logo} />
            </LogoConnect>
          ) : (
            <Logo src={TheoremLogoPng} />
          )}
          <Title>{companyName ? 'Welcome to' : 'Welcome'}</Title>
          {companyName && <SubTitle>{companyName}&rsquo;s Workspace!</SubTitle>}

          <AgreementWrapper>
            <Top>
              <CheckboxDescription>By clicking accept you agree to the:</CheckboxDescription>
              <LegalCheckBoxes>
                <CheckboxWrapper>
                  <Checkbox value={0} onChange={clickCheckbox} />
                  <InlineLinks>
                    <InlineLink href={TERMS_AND_CONDITIONS} target="_blank">
                      Theorem User Terms
                    </InlineLink>{' '}
                    &nbsp;&amp;&nbsp;{' '}
                    <InlineLink href={SUBSCRIPTION_AGREEMENT} target="_blank">
                      Subscription Agreement
                    </InlineLink>
                  </InlineLinks>
                </CheckboxWrapper>

                {associationTerms && (
                  <CheckboxWrapper>
                    <Checkbox value={1} onChange={clickCheckbox} />
                    <LegalLink href={associationTerms} target="_blank">
                      Association Terms &amp; Conditions
                    </LegalLink>
                  </CheckboxWrapper>
                )}
              </LegalCheckBoxes>
            </Top>

            <ContinueButton
              loading={isSubmitting}
              onClick={onAgree}
              disabled={
                associationTerms ? selectedCheckbox.length !== 2 : selectedCheckbox.length !== 1
              }
            >
              ACCEPT
            </ContinueButton>
          </AgreementWrapper>
        </Wrapper>
      </Container>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(216deg, #00a2ffab 0%, #9d44d48f 100%);
  position: relative;
`

const Container = styled.div`
  width: 100%;
  text-align: center;
`

const Logo = styled.img`
  max-width: 80px;
  display: block;
  margin: 0 auto;
`

const TheoremWhiteLogo = styled.img`
  max-width: 200px;
  margin-bottom: 8px;
`

const Top = styled.div`
  margin-bottom: 32px;
  text-align: center;
`

const CheckboxDescription = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 16px;
  color: #464747;
`

const Wrapper = styled.div`
  background: white;
  margin: auto;
  border-radius: 6px;
  padding: 48px 20px;
  width: 100%;
  max-width: 730px;
  box-shadow: 0 0 20px rgb(0 0 0 / 15%);
`

const Title = styled.h1`
  color: #4d4d4d;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.02rem;
  margin-bottom: 0;
`

const SubTitle = styled.h2`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 500;
  color: #707070;
  max-width: 500px;
  margin: 0 auto;
`

const AgreementWrapper = styled.div`
  padding-top: 24px;
  text-align: center;
`

const ContinueButton = styled(Button)`
  color: white;
  border-color: #8774d8;
  background: #8774d8;
  font-size: 18px;
  letter-spacing: 2px;
  padding: 10px 32px;

  &:hover {
    background: transparent;
    color: #8774d8;
  }

  &:disabled,
  &[disabled] {
    border: 2px solid #9e9e9e;
    color: #9e9e9e;
    background: transparent;

    &:hover {
      background: transparent;
      cursor: not-allowed;
    }
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const LegalCheckBoxes = styled.div`
  text-align: center;
`

const InlineLink = styled.a`
  color: #8774d8;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02rem;
`

const InlineLinks = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
`

const LegalLink = styled.a`
  color: #8774d8;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
`

const LogoConnect = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 50px;
  margin: 0 auto 30px;
`

const TheoremLogo = styled.img`
  max-height: 40px;
  width: auto;
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
`

const LineImage = styled.img`
  width: 50px;
`

const AccLogo = styled.img`
  position: absolute;
  height: 40px;
  width: auto;
  left: 70px;
  top: 50%;
  transform: translateY(-50%);
`

export default TermsAndConditions
