import FirmAppsTable from './FirmAppsTable'
import { FirmAppsProvider } from './firmAppsContext'
import FirmAppsFilters from './FirmAppsFilters'

const FirmApps = () => {
  return (
    <FirmAppsProvider>
      <FirmAppsFilters />
      <FirmAppsTable />
    </FirmAppsProvider>
  )
}

export default FirmApps
