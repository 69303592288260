import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import Tooltip from 'components/theme/Tooltip'
import Modal, { useModal } from 'components/Modal'
import Profile from './Profile'

type Props = {
  popUp?: boolean
  color?: string
  large?: boolean
  profileDisabled?: boolean
  onClick?: () => void
  user: any
}

const Avatar: React.FC<Props> = ({ color = '#e1e1e1', large = false, user }) => {
  const imageUrl = user?.details?.imageUrl
  return (
    <AvatarIcon large={large} color={imageUrl ? 'transparent' : color}>
      {imageUrl ? (
        <AvatarImage image={imageUrl} />
      ) : (
        <DefaultAvatar large={large.toString()} color="rgba(0,0,0,.5)" name="user" />
      )}
    </AvatarIcon>
  )
}

const FunctionalAvatar: React.FC<Props> = ({
  popUp = false,
  profileDisabled = false,
  onClick,
  user,
  ...props
}) => {
  if (!user) return null
  const { first, last, id } = user
  const { showModal, toggle } = useModal()

  const ProfileModal = () => (
    <Modal width="800px" isShowing={showModal} toggleModal={toggle} removePadding>
      <Profile selectedId={id} />
    </Modal>
  )

  const onClickAvatar = () => {
    if (onClick) onClick()
    toggle()
  }

  if (profileDisabled) return <Avatar user={user} {...props} />

  if (!popUp)
    return (
      <>
        <ProfileModal />
        <ClickableAvatar onClick={onClickAvatar}>
          <Avatar user={user} {...props} />
        </ClickableAvatar>
      </>
    )
  return (
    <>
      <ProfileModal />
      <ClickableAvatar onClick={onClickAvatar}>
        <Tooltip
          tooltip={
            <UserName>
              {first} {last}
            </UserName>
          }
        >
          <Avatar user={user} {...props} />
        </Tooltip>
      </ClickableAvatar>
    </>
  )
}

const ClickableAvatar = styled.div`
  cursor: pointer;
`

const UserName = styled.div`
  white-space: nowrap;
  padding: 4px;
`

const AvatarIcon = styled.div<{ large: boolean; color: string }>`
  transition: width 1000ms, height 1000ms, border-radius 1000ms, top 1000ms, right 1000ms;
  right: ${({ large }) => (large ? '5px' : '0')};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ color }) => color};
  overflow: hidden;
  position: relative;
  width: ${({ large }) => (large ? '64px' : '32px')};
  height: ${({ large }) => (large ? '64px' : '32px')};
  border-radius: ${({ large }) => (large ? '64px' : '32px')};
`

const AvatarImage = styled.div<{ image: any }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const DefaultAvatar = styled(Icon)<{ large: string; color: string }>`
  position: absolute;
  transition: font-size 750ms;
  font-size: ${({ large }) => (large === 'true' ? 26 : 16)}px;
`

export default FunctionalAvatar
