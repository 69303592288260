import styled from '@emotion/styled'
import React from 'react'

interface Props {
  title?: string
  on: boolean
  toggle: () => void
}

const Toggle: React.FC<Props> = ({ on, toggle, title }) => {
  return (
    <ToggleWrapper>
      <ToggleTitle>{title}</ToggleTitle>
      <Wrapper isChecked={on}>
        <CheckboxInput type="checkbox" checked={on} onChange={toggle} />
        <SwitchToggle isChecked={on} />
      </Wrapper>
    </ToggleWrapper>
  )
}

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ToggleTitle = styled.span`
  font-weight: bold;
  margin-right: 8px;
`

const SwitchToggle = styled.div<{ isChecked: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 400px;
  background: ${(props) => (props.isChecked ? '#4cd0d1' : '#b69bc7')};
  transform: translateX(${(props) => (props.isChecked ? '20px' : '0')});
  transition: background 350ms, transform 250ms;
`

const Wrapper = styled.label<{ isChecked: boolean }>`
  width: 48px;
  display: block;
  background: ${(props) => (props.isChecked ? '#ececec' : '#dadada')};
  cursor: pointer;
  border-radius: 400px;
  border: 2px solid #888;
  padding: 2px;
  transition: background 250ms;

  &:hover {
    background: #ccc;

    ${SwitchToggle} {
      background: ${(props) => (props.isChecked ? '#1e9d9e' : '#a273bf')};
    }
  }
`

const CheckboxInput = styled.input`
  display: none;
`

export default Toggle
