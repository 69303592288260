import { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import Modules, {
  CreateRfp,
  InviteTeam,
  VisitLegalTechMarket,
  MemberPortalSetup,
  HelpAndSupport,
} from './modules'
import { useLocation } from 'react-router'
import WelcomeModal from './WelcomeModal'
import { useRedirect } from 'hooks/useRedirect'
import { useMutateViewer } from 'data/viewer/useMutateViewer'

interface Location {
  showWelcome: true
}
const AssociationGettingStarted = () => {
  const [isShowModal, setIsShowModal] = useState(true)
  const mutateViewer = useMutateViewer()
  const modules = [MemberPortalSetup, VisitLegalTechMarket, CreateRfp, InviteTeam, HelpAndSupport]
  const location = useLocation()
  const redirect = useRedirect()

  const locationState =
    typeof location.state === 'object' ? (location.state as Location) : { showWelcome: '' }

  const showWelcome = locationState?.showWelcome

  const stopShowingPage = async () => {
    mutateViewer.mutate(
      {
        appMetadata: {
          hideAssocGettingStarted: true,
        },
      },
      {
        onSuccess: () => redirect(),
      }
    )
  }

  const hideModal = useCallback(() => {
    setIsShowModal(false)
  }, [])

  return (
    <Wrapper>
      <Modules modules={modules} />
      <StopShowingPage onClick={stopShowingPage}>
        Stop showing the Getting Started page
      </StopShowingPage>
      {showWelcome && <WelcomeModal isShow={isShowModal} hide={hideModal} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: -32px;
`

const StopShowingPage = styled.a`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  font-size: 18px;
  text-align: center;
`

export default AssociationGettingStarted
