import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button, Icon } from 'components/theme'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import EltaLogo from 'assets/networks/elta/elta-pink.png'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { useWizardModal } from 'components/modals/WizardModal'

const ListingNetworks = () => {
  const { goNextStep, addData } = useWizardModal()
  const [showTheorem, setShowTheorem] = useState(false)
  const baseOrgUrl = useBaseOrgUrl()

  //figure it out here
  const networkCardClick = (networkSlug: string) => () => {
    addData({ networkSlug })
    goNextStep()
  }

  return (
    <Root>
      <Title>Select a Listing Plan:</Title>
      <Subtitle>
        Each Network is entitled to its own terms and pricing{' '}
        <a href="https://help.theoremlegal.com/kb/en/article/listing-your-products" target="_blank">
          Learn More about Listings
        </a>
      </Subtitle>
      <CardsWrapper>
        <Column>
          <NetworkCard onClick={networkCardClick('cloc')}>
            <LogoWrapper>
              <Logo src="https://res.cloudinary.com/dh1giwabb/image/upload/v1636401038/lohkcj9blueoocbg64ka.png" />
            </LogoWrapper>
            <BoxTitle>Corporate Legal Ops Consortium</BoxTitle>
            <AngleRight name={faAngleRight} />
          </NetworkCard>
        </Column>
        <Column>
          <NetworkCard onClick={networkCardClick('acc')}>
            <LogoWrapper>
              <Logo src="https://res.cloudinary.com/dh1giwabb/image/upload/v1664468286/drb72jk0swqzxwfjl0z6.jpg" />
            </LogoWrapper>
            <BoxTitle>Association of Corporate Counsel</BoxTitle>
            <AngleRight name={faAngleRight} />
          </NetworkCard>
        </Column>
        <Column>
          <NetworkCard onClick={networkCardClick('elta')}>
            <LogoWrapper>
              <Logo src={EltaLogo} />
            </LogoWrapper>
            <BoxTitle>European Legal Tech Association</BoxTitle>
            <AngleRight name={faAngleRight} />
          </NetworkCard>
        </Column>
        {/* organization?.type === OrganizationType.Vendor && (
          <Column>
            <CardDiv onClick={() => setShowTheorem((prevVal) => !prevVal)}>
              <TheoremLogoImg src={TheoremLogo} />
              <BoxTitle>Theorem</BoxTitle>
            </CardDiv>
          </Column>
        ) */}
      </CardsWrapper>
      {showTheorem && (
        <TheoremBox>
          <Left>
            <h2>Included in your Theorem Listing:</h2>
            <p>Manage your listings on every network in our ecosystem from one place</p>
            <p>
              <strong>Networks Included:</strong>
            </p>
            <ul>
              <li>Theorem – The Core Network</li>
              <li>ELTA - European Legal Tech Association</li>
              <li>ALITA - Asia-Pacific Legal Innovation and Technology Association</li>
              <li>ARK KM - Knowledge Management, AM law 100 & 200</li>
              <li>CIO Network - Information and Analytics Officers</li>
              <li>CISO network - security professionals</li>
              <li>Includes upcoming networks</li>
            </ul>
            <p>Continue to view plan features</p>
          </Left>
          <Button component="link" to={`${baseOrgUrl}/upgrade`}>
            Purchase
          </Button>
        </TheoremBox>
      )}
    </Root>
  )
}

const Root = styled.div``
const Left = styled.div``

const CardsWrapper = styled.div``

const Column = styled.div`
  padding: 0 0 20px;
`

const Title = styled.h3`
  margin-bottom: 8px;
`

const Subtitle = styled.h4`
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 400;
`

const TheoremBox = styled.div`
  display: flex;
  margin-top: 32px;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 720px;
`

const NetworkCard = styled.div`
  position: relative;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 6px;
  align-items: center;
  padding: 12px 20px;
  transition: background 250ms;
  cursor: pointer;

  &:hover {
    background: #edf4fd;
  }
`

const Logo = styled.img`
  width: 100%;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
`

const BoxTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 0 auto 0 12px;
`

const AngleRight = styled(Icon)`
  font-size: 20px;
`

export default ListingNetworks
