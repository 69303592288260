import styled from '@emotion/styled'
import { TextInput, FormField, Dropdown } from 'components/theme/form'
import { Button } from 'components/theme'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { Formik, Form } from 'formik'
import { extractPictureUrl } from 'services/image'
import * as yup from 'yup'
import { useOrg } from 'data/organization/useOrg'
import { useSuperAdminCreateOrg } from 'data/hooks'

const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Required'),
  logo: yup.mixed(),
})

const defaultValues = {
  name: '',
  website: '',
  logo: [],
}

const CreateOrganizationForm = ({
  initialValues = defaultValues,
  onComplete,
  className = null,
}: any) => {
  const { organization } = useOrg()
  const createOrg = useSuperAdminCreateOrg()

  const onSubmit = async ({ name, website, domain, logo, type, subtype, companySize }: any) => {
    const logoUrl = await extractPictureUrl(logo)

    await createOrg.mutateAsync({
      name,
      domain,
      type,
      subtype,
      details: {
        ...organization?.details,
        website,
        logo: logoUrl,
        companySize,
      },
    })

    onComplete()
  }

  return (
    <Root className={className}>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <FormField>
              <UploadSingleAsset label="Company Logo" name="logo" selectedImage={values.logo}>
                Add Company Logo
              </UploadSingleAsset>
            </FormField>
            <FormField>
              <TextInput label="Company Name" name="name" placeholder="My Company Inc." required />
            </FormField>
            <FormField>
              <TextInput label="Email Domain" name="domain" placeholder="example.com" required />
            </FormField>
            <FormField>
              <Dropdown
                fluid
                selection
                label="Type"
                setFieldValue={setFieldValue}
                options={companyType}
                value={values.type}
                name="type"
                placeholder="Select Company Type"
              />
            </FormField>
            <FormField>
              <Dropdown
                fluid
                selection
                label="Subtype"
                setFieldValue={setFieldValue}
                options={companySubtype}
                value={values.subtype}
                name="subtype"
                placeholder="Select Company Subtype"
              />
            </FormField>
            <ButtonContainer>
              <Button disabled={isSubmitting || !isValid} loading={isSubmitting}>
                Create
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

export const companyType = [
  { text: 'Vendor', value: 'VENDOR' },
  { text: 'Law firm', value: 'LAWFIRM' },
  { text: 'Company', value: 'COMPANY' },
  { text: 'Association', value: 'ASSOCIATION' },
]

export const companySubtype = [
  { text: 'Software', value: 'SOFTWARE' },
  { text: 'consultant', value: 'CONSULTANT' },
  { text: 'ALSP', value: 'ALSP' },
  { text: 'Law Firm', value: 'LAWFIRM' },
  { text: 'In House', value: 'IN_HOUSE' },
]

const Root = styled.div`
  max-width: 760px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

export default CreateOrganizationForm
