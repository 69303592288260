import React from 'react'
import ContactInfoForm from 'components/forms/product/ContactInfoForm'
import { FlexLoading } from 'components/PulsatingLoader'
import { FormDescription } from 'components/theme/form'
import { useProductSettings } from '../useProductSettings'
import _ from 'lodash'
import VendorUpgradeModal from 'components/modals/VendorUpgradeModal'

function ContactInfo() {
  const { product, loading, onComplete, showModal, toggleModal, isService } = useProductSettings()

  const initialValues = {
    phone: _.get(product, 'details.phone', ''),
    emailSupport: _.get(product, 'details.emailSupport', '') || '',
    emailInquiry: _.get(product, 'details.emailInquiry', ''),
    linkedin: _.get(product, 'details.linkedin', ''),
    facebook: _.get(product, 'details.facebook', ''),
    twitter: _.get(product, 'details.twitter', ''),
    signInUrl: _.get(product, 'details.signInUrl', ''),
    resources: _.get(product, 'details.resources', []),
    sidebarCta: _.get(product, 'details.sidebarCta', { type: 'demo' }),
  }

  if (loading) return <FlexLoading />

  return (
    <>
      <FormDescription>Info displayed on profile menu (recommended)</FormDescription>
      <ContactInfoForm
        initialValues={initialValues}
        onComplete={onComplete}
        product={product}
        isService={isService}
      />
      <VendorUpgradeModal showModal={showModal} toggleModal={toggleModal} />
    </>
  )
}

export default ContactInfo
