import styled from '@emotion/styled'
import Modules, {
  // SellerAccount,
  ProductProfiles,
  InviteTeam,
  // FirstDeal,
  HelpAndSupport,
  CreateListing,
} from './modules'
import { useRedirect } from 'hooks/useRedirect'
import { useMutateViewer } from 'data/viewer/useMutateViewer'

const VendorGettingStarted = () => {
  const mutateViewer = useMutateViewer()
  const modules = [
    CreateListing,
    ProductProfiles,
    // SellerAccount,
    // FirstDeal,
    InviteTeam,
    HelpAndSupport,
  ]
  const redirect = useRedirect()

  const stopShowingPage = async () => {
    mutateViewer.mutate(
      {
        appMetadata: {
          hideVendorGettingStarted: true,
        },
      },
      {
        onSuccess: () => redirect(),
      }
    )
  }

  return (
    <Wrapper>
      <Modules modules={modules} />
      <StopShowingPage onClick={stopShowingPage}>
        Stop showing the Getting Started page
      </StopShowingPage>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: -32px;
`

const StopShowingPage = styled.a`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  font-size: 18px;
  text-align: center;
`

export default VendorGettingStarted
