import styled from '@emotion/styled'
import BaseModal from 'components/modals/BaseModal'
import { useAssocHome } from 'data/hooks'
import PulsatingLoader from 'components/PulsatingLoader'
import { SectionLayoutType } from 'generated/graphql'
import LargeProductForm from './forms/LargeProductForm'
import ProductsSliderForm from './forms/ProductsSliderForm'
import ProductTypeForm from './forms/ProductTypeForm'
import CategoryListForm from './forms/CategoryListForm'
import { toast } from 'react-toastify'
import BasicForm from './forms/BasicForm'
import ProductGridForm from './forms/ProductsGridForm'

interface Props {
  assocHomeId: number
  removeSelectedAssocHomeId: () => void
}
const CardsEditorModal = ({ assocHomeId, removeSelectedAssocHomeId }: Props) => {
  const { assocHome } = useAssocHome(assocHomeId)

  const onSuccess = () => {
    toast.success('Update success!')
    removeSelectedAssocHomeId()
  }

  if (!assocHome) return <PulsatingLoader />

  const { type } = assocHome

  return (
    <BaseModal toggleModal={removeSelectedAssocHomeId} closeModalStyles={{ top: 8, fontSize: 20 }}>
      <Root>
        {(() => {
          if (type === SectionLayoutType.Basic)
            return (
              <div>
                <TopBar>Basic</TopBar>
                <BaseWrapper>
                  <BasicForm assocHomeId={assocHomeId} onSuccess={onSuccess} />
                </BaseWrapper>
              </div>
            )
          if (type === SectionLayoutType.LargeProduct)
            return (
              <div>
                <TopBar>Large Product Slider</TopBar>
                <BaseWrapper>
                  <LargeProductForm assocHomeId={assocHomeId} onSuccess={onSuccess} />
                </BaseWrapper>
              </div>
            )
          if (type === SectionLayoutType.ProductsSlider)
            return (
              <div>
                <TopBar>Products Slider</TopBar>
                <BaseWrapper>
                  <ProductsSliderForm assocHomeId={assocHomeId} onSuccess={onSuccess} />
                </BaseWrapper>
              </div>
            )
          if (type === SectionLayoutType.ProductType)
            return (
              <div>
                <TopBar>Product Type Selector</TopBar>
                <BaseWrapper>
                  <ProductTypeForm assocHomeId={assocHomeId} onSuccess={onSuccess} />
                </BaseWrapper>
              </div>
            )
          if (type === SectionLayoutType.CategoryList)
            return (
              <div>
                <TopBar>Category List</TopBar>
                <BaseWrapper>
                  <CategoryListForm assocHomeId={assocHomeId} onSuccess={onSuccess} />
                </BaseWrapper>
              </div>
            )
          if (type === SectionLayoutType.ProductsGrid)
            return (
              <div>
                <TopBar>Category List</TopBar>
                <BaseWrapper>
                  <ProductGridForm assocHomeId={assocHomeId} onSuccess={onSuccess} />
                </BaseWrapper>
              </div>
            )
          if (type === SectionLayoutType.TheoremFeatures)
            return (
              <div>
                <TopBar>Theorem Features</TopBar>
                <BaseWrapper>
                  <ProductGridForm assocHomeId={assocHomeId} onSuccess={onSuccess} />
                </BaseWrapper>
              </div>
            )

          return <div></div>
        })()}
      </Root>
    </BaseModal>
  )
}

const TopBar = styled.div`
  padding: 8px 20px;
  background: #cad5e6;
  border-bottom: 1px solid #a6aeb2;
  font-size: 16px;
  letter-spacing: 0.02rem;
  font-weight: 500;
`

const BaseWrapper = styled.div`
  padding: 20px;
`

const Root = styled.div`
  min-width: 600px;
  padding: 0;
`

export default CardsEditorModal
