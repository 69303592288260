import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { newDeletedList } from 'services/helper'
import { GqlCreateFeatureMutation } from 'generated/graphql'
import { gql } from 'graphql-request'

interface Variables {
  features: Array<number | string>
  initialValue: number[]
}
export const useMutateFeatures = (currentProductId: number) => {
  const request = useGql()
  const queryClient = useQueryClient()
  return useMutation(
    async (variables: Variables) => {
      const { features, initialValue } = variables

      const newFeatures = features.filter((feature) => typeof feature === 'string') as string[]
      const currentFeatureIds = features.filter(
        (feature) => typeof feature === 'number'
      ) as number[]

      if (newFeatures.length > 0) {
        for (const featureName of newFeatures) {
          const createdFeature = await request<GqlCreateFeatureMutation>(CREATE_FEATURE, {
            name: featureName.trim(),
          })
          const featureId = createdFeature.createFeature.feature.id
          currentFeatureIds.push(featureId)
        }
      }

      const [newFeatureIds, deletedFeaturesIds] = newDeletedList(initialValue, currentFeatureIds)

      if (newFeatureIds.length > 0) {
        for (const featureId of newFeatureIds) {
          await request(CREATE_FEATURE_TO_PRODUCT, {
            featureId,
            productId: currentProductId,
          })
        }
      }

      if (deletedFeaturesIds.length > 0) {
        for (const featureId of deletedFeaturesIds) {
          await request(DELETE_FEATURE_TO_PRODUCT, {
            featureId,
            productId: currentProductId,
          })
        }
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

const CREATE_FEATURE = gql`
  mutation CreateFeature($name: String!) {
    createFeature(input: { feature: { name: $name } }) {
      feature {
        id
      }
    }
  }
`

const DELETE_FEATURE_TO_PRODUCT = gql`
  mutation DeleteFeatureToProduct($featureId: Int!, $productId: Int!) {
    deleteFeatureToProduct(input: { featureId: $featureId, productId: $productId }) {
      clientMutationId
    }
  }
`

const CREATE_FEATURE_TO_PRODUCT = gql`
  mutation CreateFeatureToProduct($featureId: Int!, $productId: Int!) {
    createFeatureToProduct(
      input: { featureToProduct: { featureId: $featureId, productId: $productId } }
    ) {
      clientMutationId
    }
  }
`
