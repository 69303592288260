import CompetitorsForm from 'components/forms/product/CompetitorsForm'
import { useProductSettings } from '../useProductSettings'
import VendorUpgradeModal from 'components/modals/VendorUpgradeModal'

function Competitors() {
  const { product, onComplete, showModal, toggleModal } = useProductSettings()

  const initialValues = product?.competitors ? product?.competitors.map(({ id }) => id) : []

  return (
    <div>
      <CompetitorsForm
        productId={product?.id}
        initialValues={initialValues}
        onComplete={onComplete}
      />
      <VendorUpgradeModal showModal={showModal} toggleModal={toggleModal} />
    </div>
  )
}

export default Competitors
