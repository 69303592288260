import styled from '@emotion/styled'
import Image from './powerful-pana.svg'
import { BoxWrapper, Button, Icon } from 'components/theme'
import { useRedirect } from 'hooks/useRedirect'

function UpgradeCancel() {
  const redirect = useRedirect()

  return (
    <StyledBox>
      <h1>Access Premium Features &amp; Content</h1>
      <PayButton onClick={() => redirect('upgrade')}>
        <StarIcon name="star" /> <PayButtonText>Upgrade</PayButtonText>
      </PayButton>
      <StyledImage src={Image} />
    </StyledBox>
  )
}

const StyledBox = styled(BoxWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledImage = styled.img`
  width: 800px;
  max-height: 500px;
`

const PayButton = styled(Button)`
  color: #c3621c;
  background-color: #ffd700;
  border-color: #ffd700;
  display: flex;
  align-items: center;
  padding-left: 10px;
  line-height: 1;
  margin-right: 10px;

  &:hover {
    color: #c3621c;
    border-color: #c3621c;
    background-color: transparent;
  }
`

const PayButtonText = styled.div`
  transform: translateY(1px);
  margin-left: 8px;
`

const StarIcon = styled(Icon)`
  font-size: 12px;
`

export default UpgradeCancel
