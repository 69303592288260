import Checkout from 'components/payment/Checkout'
import { useNavigate, useLocation } from 'react-router-dom'
import { CheckoutSubmit } from 'interfaces'
import { useLazyApi } from 'hooks/useApi'
import { FullPageLoading } from 'components/PulsatingLoader'
import TestDriveLegal from './TestDriveLegal'
import { useOrg } from 'data/organization/useOrg'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useProduct } from 'data/hooks'

interface Location {
  formValue: {
    companyName: string
    email: string
    firstName: string
    lastName: string
    jobTitle: string
    phone: string
    selectedProduct?: number
  }
  stripeInfo: {
    priceId: string
    stripeProductId: string
    productId: number
    freeTrialDays?: number
    accountId?: string
  }
}

function TestDriveCheckout() {
  const location = useLocation()
  const navigate = useNavigate()
  const { organization } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()
  const [submitTestDriveCheckout] = useLazyApi('stripe/test-drive/checkout')

  const locationState = location.state as Location

  const { priceId, productId, freeTrialDays, accountId } = locationState.stripeInfo

  const { product, isLoading } = useProduct(productId)

  const { selectedProduct, email, firstName, lastName, jobTitle, phone, companyName } =
    locationState.formValue

  const onSubmit = async ({ paymentMethodId, quantity, coupon }: CheckoutSubmit) => {
    const result = await submitTestDriveCheckout({
      productId,
      accountId,
      forWhichProductId: selectedProduct || productId,
      organizationId: organization?.id,
      priceId,
      paymentMethodId,
      quantity,
      email,
      firstName,
      lastName,
      jobTitle,
      companyName,
      phone,
      coupon,
    })

    if (result.error) {
      throw result.error
    }

    navigate(`${baseOrgUrl}/test-drive/checkout-success`, {
      state: {
        productId,
        accountId,
        selectedProduct,
        priceId,
      },
    })
  }

  if (isLoading) return <FullPageLoading />

  if (!product) return null

  return (
    <Checkout
      priceId={priceId}
      onSubmit={onSubmit}
      email={email}
      freeTrialDays={freeTrialDays}
      accountId={accountId}
      legalComponent={<TestDriveLegal product={product} />}
    />
  )
}

export default TestDriveCheckout
