import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useDeleteUser = () => {
  const request = useGql()
  return useMutation(async (userId: number) => {
    await request(DELETE_USER, { id: userId })
  })
}

export const DELETE_USER = gql`
  mutation DeleteUser($id: Int!) {
    deleteUser(input: { id: $id }) {
      deletedUserNodeId
    }
  }
`
