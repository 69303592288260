import styled from '@emotion/styled'
import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Icon } from 'components/theme'
import MenuItem from './MenuItem'
import SecondaryMenu from './SecondaryMenu'

interface Props {
  closeMobileMenu: () => void
}
const CompanyMenu = ({ closeMobileMenu }: Props) => {
  const baseOrgUrl = useBaseOrgUrl()
  const { isAdmin } = useViewer()

  return (
    <Root>
      <MenuLi>
        <MenuItem to={baseOrgUrl} onClick={closeMobileMenu} end>
          Apps <DownCaret name="caret-down" />
        </MenuItem>
        <SecondaryMenu
          closeMobileMenu={closeMobileMenu}
          pages={[
            { title: 'My Apps', link: `${baseOrgUrl}/your-apps` },
            { title: 'Stack Share', link: `${baseOrgUrl}/your-apps` },
          ]}
        />
      </MenuLi>
      <MenuLi>
        <MenuItem to={`${baseOrgUrl}/techhub`} onClick={closeMobileMenu} hideactive="true">
          Techhub
        </MenuItem>
      </MenuLi>
      <MenuLi>
        <MenuItem to={`${baseOrgUrl}/rfp`} onClick={closeMobileMenu}>
          Operations <DownCaret name="caret-down" />
        </MenuItem>
        <SecondaryMenu
          closeMobileMenu={closeMobileMenu}
          pages={[
            { title: 'RFP', link: `${baseOrgUrl}/rfp` },
            { title: 'My Reviews', link: `${baseOrgUrl}/user-stories` },
          ]}
        />
      </MenuLi>
      {isAdmin && (
        <MenuLi>
          <MenuItem to={`${baseOrgUrl}/techstack-management`} onClick={closeMobileMenu}>
            My Company's Apps
          </MenuItem>
        </MenuLi>
      )}
    </Root>
  )
}

const Root = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: block;
    width: 100%;
  }
`

const DownCaret = styled(Icon)`
  font-size: 16px;
  margin-left: 4px;
`

const MenuLi = styled.li`
  position: relative;

  &:hover {
    & > a:after {
      transform: scaleY(1);
    }

    & > div:first-of-type {
      display: block;
    }
  }

  ${({ theme }) => theme.breakpoint.mobile} {
    &:first-of-type a {
      border-top: 0;
    }
  }
`

export default CompanyMenu
