import { useEffect } from 'react'
import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'
import ListingDetailsForm, { Values } from './ListingDetailsForm'
import { useListingDetails } from 'data/hooks'
import { GqlOrganizationListingQuery } from 'generated/graphql'
import PulsatingLoader from 'components/PulsatingLoader'
import { markdownToDraft } from 'markdown-draft-js'
import { EditorState, convertFromRaw } from 'draft-js'
import { toast } from 'react-toastify'

const ListingDetails = () => {
  const { data, isLoading } = useListingDetails()

  useEffect(() => window.scrollTo(0, 0), [])

  const parseInitialValues = (data?: GqlOrganizationListingQuery): Values => {
    const listingDetails = data?.organizationListing

    if (!listingDetails) {
      return {
        bannerImage: [],
        emailSupport: '',
        twitter: '',
        linkedin: '',
        excerpt: '',
        demoVideo: '',
        description: EditorState.createEmpty(),
        show: true,
      }
    }

    const markdownBlocks = markdownToDraft(listingDetails.description)
    const markdownState = convertFromRaw(markdownBlocks)

    return {
      bannerImage: listingDetails.details.bannerImage,
      excerpt: listingDetails.excerpt,
      emailSupport: listingDetails.details.emailSupport,
      twitter: listingDetails.details.twitter,
      linkedin: listingDetails.details.linkedin,
      demoVideo: listingDetails.details.demoVideo || '',
      description: EditorState.createWithContent(markdownState),
      show: true,
    }
  }

  const onComplete = () => {
    toast.success('Firms listing details have been updated')
  }

  return (
    <Root>
      <BoxWrapper>
        <h2>Your Company Listing Info</h2>
        <p>These details will display on the networks you are a part of.</p>
        {isLoading ? (
          <PulsatingLoader />
        ) : (
          <ListingDetailsForm initialValues={parseInitialValues(data)} onComplete={onComplete} />
        )}
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

export default ListingDetails
