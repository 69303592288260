import noListingSvg from 'assets/listing-image.svg'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
import TopCtaSection from './TopCtaSection'

const CreateListing = () => {
  const marketplaceUrl = useMarketplaceUrl(true)

  return (
    <TopCtaSection
      title="Welcome! Create a listing"
      text="Add your product library and connect them to listings across our marketplace"
      image={noListingSvg}
      outLink={{
        href: 'https://youtube.com/playlist?list=PL5DvS6vmvHFkmjri0wlpiF9dtTvBEixzR',
        text: 'Watch Tutorial Videos',
      }}
      routerLink={{
        to: `${marketplaceUrl}returnTo=/admin/listings`,
        text: 'Create Listing',
        outLink: true,
      }}
    />
  )
}

export default CreateListing
