import UniversalListingSvg from 'assets/upgrade/universal-listing.svg'
import SavingSvg from 'assets/upgrade/saving.svg'
import BarGraphSvg from 'assets/upgrade/bar-graph.svg'
import CodeCtaSvg from 'assets/upgrade/code-cta.svg'
import RfpSvg from 'assets/upgrade/rfp.svg'
import CreditCardSvg from 'assets/upgrade/credit-card.svg'

export default [
  {
    title: 'UNIVERSAL LISTING',
    subtitle:
      'Get listed on included networks + Save on Premium Listing Upgrades (CLOC, ACC + More)',
    content:
      'Manage all your product listings from one place and get listed on all our included networks',
    icon: UniversalListingSvg,
  },
  {
    title: 'EXCLUSIVE SAVINGS',
    subtitle: 'Save on enhanced listings, upgrades and promotions.',
    content: 'Up to 20% on promotion and premium listing plans – you could save $1,000s*',
    icon: SavingSvg,
  },
  {
    title: 'ACCOUNT ANALYTICS',
    subtitle: 'Know your buyers and target competitors',
    content: 'Access page analytics and competitive intelligence',
    icon: BarGraphSvg,
  },
  {
    title: 'CUSTOM CTA, FORMS, NOTIFICATIONS',
    subtitle: 'Add custom forms and resources to your listings',
    content: 'Get notified when activity occurs.',
    icon: CodeCtaSvg,
  },
  {
    title: 'RFP',
    subtitle: 'Transact With buyers directly with RFP',
    content:
      'Receive and respond to RFPs created by network buyers. Easy RFP and private offer tools',
    icon: RfpSvg,
  },
  {
    title: 'SECURE CHECKOUT',
    subtitle: 'Accept Payments with Confidence',
    content: 'Enable One-Click Secure Payment Processing and Receive Direct Payouts.',
    icon: CreditCardSvg,
  },
]
