import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'
import NextStep from './NextStep'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'
import { SUBSCRIPTION_AGREEMENT, TERMS_AND_CONDITIONS } from 'services/globalAssets'

interface LegalLink {
  text: string
  link: string
}

interface Props {
  orgName: string
  isSubmitting: boolean
  continueClick: () => void
  legalLinks?: LegalLink[]
}

const NextSteps: React.FC<Props> = ({ orgName, isSubmitting, continueClick, legalLinks = [] }) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState<number[]>([])

  const clickCheckbox = (_event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const { value } = data

    const selectedIndex: number = value as number

    setSelectedCheckbox((prevSelection) =>
      prevSelection.includes(selectedIndex)
        ? prevSelection.filter((index) => index !== selectedIndex)
        : [...prevSelection, selectedIndex]
    )
  }

  return (
    <Wrapper>
      <Title>Welcome to {orgName}'s Workspace!</Title>
      <SubTitle>
        The world of LegalTech Awaits &mdash; Just a few things your admin has requested to get you
        started
      </SubTitle>
      <Steps>
        <NextStep text="Set up your profile" iconName="id-badge" iconNumber={1} />
        <NextStep
          text={
            <>
              Add &amp; champion
              <br />
              your stack
            </>
          }
          iconName="medal"
          iconNumber={2}
        />
        <NextStep text="Invite your team &amp; collaborate" iconName="paper-plane" iconNumber={3} />
      </Steps>
      <ButtonWrapper>
        <Left>
          <CheckboxDescription>I have read and agree to the:</CheckboxDescription>
          <LegalCheckBoxes>
            <CheckboxWrapper>
              <Checkbox checked={selectedCheckbox.includes(0)} value={0} onChange={clickCheckbox} />
              <InlineLinks>
                <InlineLink href={TERMS_AND_CONDITIONS} target="_blank">
                  Theorem User Terms
                </InlineLink>{' '}
                &nbsp;&amp;&nbsp;{' '}
                <InlineLink href={SUBSCRIPTION_AGREEMENT} target="_blank">
                  Subscription Agreement
                </InlineLink>
              </InlineLinks>
            </CheckboxWrapper>
            {legalLinks.map(({ text, link }, index) => (
              <CheckboxWrapper key={text}>
                <Checkbox
                  checked={selectedCheckbox.includes(index + 1)}
                  value={index + 1}
                  onChange={clickCheckbox}
                />
                <LegalLink href={link} target="_blank">
                  {text}
                </LegalLink>
              </CheckboxWrapper>
            ))}
          </LegalCheckBoxes>
        </Left>
        <ContinueButton
          loading={isSubmitting}
          onClick={continueClick}
          disabled={selectedCheckbox.length !== legalLinks.length + 1}
        >
          ACCEPT INVITATION
        </ContinueButton>
      </ButtonWrapper>
    </Wrapper>
  )
}

const Left = styled.div`
  text-align: left;
`

const CheckboxDescription = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 12px;
`

const Wrapper = styled.div`
  background: linear-gradient(180deg, #ede3ff 0%, #ffffff 100%);
  margin: auto;
  border-radius: 6px;
  border: 1px solid #b9b9b9;
  padding: 48px 20px;
  width: 100%;
  max-width: 730px;
`

const Title = styled.h1`
  color: #4d4d4d;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.02rem;
  margin-bottom: 0;
`

const SubTitle = styled.h2`
  padding-bottom: 28px;
  margin-top: 16px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 500;
  color: #707070;
  max-width: 500px;
  margin: 16px auto 0;
`

const Steps = styled.div`
  display: flex;
  max-width: 620px;
  margin: 0 auto 20px;
`

const ButtonWrapper = styled.div`
  padding-top: 32px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #c6c6c6;
`

const ContinueButton = styled(Button)`
  color: white;
  border-color: #8774d8;
  background: #8774d8;
  font-size: 18px;
  letter-spacing: 2px;
  padding: 10px 32px;

  &:hover {
    background: transparent;
    color: #8774d8;
  }

  &:disabled,
  &[disabled] {
    border: 2px solid #9e9e9e;
    color: #9e9e9e;
    background: transparent;

    &:hover {
      background: transparent;
      cursor: not-allowed;
    }
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const LegalCheckBoxes = styled.div`
  text-align: left;
`

const InlineLink = styled.a`
  color: #8774d8;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
`

const InlineLinks = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
`
const LegalLink = styled.a`
  color: #8774d8;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
`

export default NextSteps
