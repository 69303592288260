import {
  useCreateVendorStackshareLogo,
  useDeleteVendorStackshareLogo,
  useUpdateVendorStackshareLogo,
} from 'data/hooks'
import { Company } from './EditVendorStackshareForm'
import { extractPictureUrl } from 'services/image'

export const useMutateVendorStackshareLogos = (orgId?: string) => {
  const createVendorStackshare = useCreateVendorStackshareLogo()
  const deleteVendorStackshare = useDeleteVendorStackshareLogo()
  const updateVendorStackshare = useUpdateVendorStackshareLogo()

  const mutateVendorStackshareLogos = async (initialCompanies: Company[], companies: Company[]) => {
    if (!orgId) return
    const deletedCompanies = initialCompanies.filter(
      ({ logoId }) => !companies.find((company) => logoId === company.logoId)
    )

    const fixCompanies = await Promise.all(
      companies.map(async (company, index) => {
        const logo = await extractPictureUrl(company.logo)
        return {
          ...company,
          logo,
          order: index,
        }
      })
    )

    const createdCompanies = fixCompanies.filter(({ logoId }) => !logoId)
    const updatedCompanies = fixCompanies.filter(({ logoId }) => logoId)

    await Promise.all([
      ...createdCompanies.map((company) =>
        createVendorStackshare.mutateAsync({
          organizationId: orgId,
          name: company.name,
          logo: company.logo,
          order: company.order,
        })
      ),
      ...updatedCompanies.map((company) => {
        if (!company.logoId) return

        return updateVendorStackshare.mutateAsync({
          id: company.logoId,
          name: company.name,
          logo: company.logo,
          order: company.order,
        })
      }),
      ...deletedCompanies.map(({ logoId }) => {
        if (!logoId) return
        return deleteVendorStackshare.mutateAsync(logoId)
      }),
    ])
  }

  return mutateVendorStackshareLogos
}
