import { parseTerms } from 'data/taxonomy/parseTerms'
import { GqlCurrentOrgFragment, OrganizationType } from 'generated/graphql'
import { parseVendorProductsData } from './parseVendorProducts'
import { parseMembers } from './parseMembers'
import { Vendor } from './types/vendorType'

export const parseVendor = (orgData: GqlCurrentOrgFragment): Vendor => {
  const {
    id,
    name,
    slug,
    locations,
    details,
    createdAt,
    updatedAt,
    useCases,
    testMode,
    termToOrganizations,
    usersToOrganizations,
    appMetadata,
    mainTermId,
  } = orgData

  return {
    id,
    name,
    slug,
    details,
    type: OrganizationType.Vendor,
    locations,
    createdAt,
    updatedAt,
    useCases: useCases,
    products: parseVendorProductsData(orgData, id),
    testMode,
    terms: parseTerms(termToOrganizations),
    members: parseMembers(usersToOrganizations),
    appMetadata,
    mainTermId,
  }
}
