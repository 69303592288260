import React from 'react'
import { useFormikContext } from 'formik'
import styled from '@emotion/styled'
import TaxDropdown from 'components/forms/TaxDropdown'
import { StoryUpdates } from 'interfaces/userStory/Helpers'

export const UserDescription: React.FC<{ onChange: (values: StoryUpdates) => void }> = ({
  onChange,
}) => {
  const { setFieldValue, values, initialValues } = useFormikContext<{
    firmType: number
    role: number
    practiceGroup: number
  }>()

  const updateTerm = (field: 'firmType' | 'role' | 'practiceGroup', event: any, value: any) => {
    const term = { id: value, name: event.target?.innerText || '' }
    onChange({ [field]: term })
    initialValues[field] = value
  }

  return (
    <>
      <FormTitle>Who is this Product For?</FormTitle>
      <Description>
        The "user description" helps to identify who could benefit from using this product.
      </Description>
      <TaxDropdown
        value={values.firmType}
        name="firmType"
        label="Company Type"
        taxonomyId={16}
        search
        hasBlankOption
        setFieldValue={setFieldValue}
        onChange={(event: any, value: any) => updateTerm('firmType', event, value)}
      />
      <TaxDropdown
        value={values.role}
        name="role"
        label="Role"
        taxonomyId={8}
        hasBlankOption
        search
        setFieldValue={setFieldValue}
        onChange={(event: any, value: any) => updateTerm('role', event, value)}
      />
      <TaxDropdown
        value={values.practiceGroup}
        name="practiceGroup"
        label="Practice Group"
        taxonomyId={13}
        hasBlankOption
        search
        setFieldValue={setFieldValue}
        onChange={(event: any, value: any) => updateTerm('practiceGroup', event, value)}
      />
    </>
  )
}

const FormTitle = styled.h3`
  margin: 0 0 16px;
  font-size: 1.6em;
`

const Description = styled.p`
  font-size: 15px;
  margin-bottom: 28px;
`

export default UserDescription
