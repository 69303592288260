import { EditorState, convertFromRaw } from 'draft-js'
import { markdownToDraft } from 'markdown-draft-js'
import { useProductListing } from 'data/hooks'
import { useParams } from 'react-router'

export interface ProductListingValues {
  description: any
  pricingDescription: any
  emailSupport: string
  excerpt: string
  pricingPlans: any
  ctaButtonLink: string
  ctaButtonText: string
  emailInquiry: string
  eulaPdf: any
  privacyPolicyUrl: string
  publicTermsUrl: string
  demoVideo: string
  sidebarCta: {
    type: string
    text?: string
    link?: string
  }
}

export const useProductListingEditor = () => {
  const { productListingId } = useParams<{ productListingId: string }>()
  const { productListing, isLoading } = useProductListing(parseInt(productListingId as string, 10))

  const details = productListing?.details

  const description = details?.description
    ? EditorState.createWithContent(convertFromRaw(markdownToDraft(details.description)))
    : EditorState.createEmpty()

  const pricingDescription = details?.pricingDescription
    ? EditorState.createWithContent(convertFromRaw(markdownToDraft(details.pricingDescription)))
    : EditorState.createEmpty()

  const initialValues: ProductListingValues = {
    description,
    pricingDescription,
    emailSupport: details?.emailSupport || '',
    excerpt: details?.excerpt || '',
    pricingPlans: details?.pricingPlans || [],
    ctaButtonLink: details?.ctaButtonLink || '',
    ctaButtonText: details?.ctaButtonText || '',
    emailInquiry: details?.emailInquiry || '',
    eulaPdf: details?.eulaPdf || '',
    privacyPolicyUrl: details?.privacyPolicyUrl || '',
    publicTermsUrl: details?.publicTermsUrl || '',
    demoVideo: details?.demoVideo || '',
    sidebarCta: details?.sidebarCta || { type: 'demo' },
  }

  return {
    productListing,
    initialValues,
    isLoading,
    isService: productListing?.product?.type === 'SERVICE',
  }
}
