import { UserRole, UserStatus } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface Variables {
  userId: number
  organizationId: string
  role: UserRole
  userStatus: UserStatus
}

export const useMatchUserToOrg = () => {
  const request = useGql()
  const queryClient = useQueryClient()
  return useMutation(
    async (variables: Variables) => {
      const { userId, organizationId, role, userStatus } = variables

      try {
        await request(MATCH_USER_TO_ORGANIZATION, {
          userId,
          organizationId,
          role,
          userStatus,
        })

        return variables
      } catch (e) {
        console.log(e)
        debugger
        throw e
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['viewer'])
        queryClient.invalidateQueries(['org'])
      },
    }
  )
}

export const MATCH_USER_TO_ORGANIZATION = gql`
  mutation MatchUserToOrganization(
    $userId: Int!
    $organizationId: UUID!
    $locationId: Int
    $role: UserRole
    $userStatus: UserStatus
  ) {
    updateUser(
      input: {
        patch: {
          locationId: $locationId
          currentOrganization: $organizationId
          status: $userStatus
        }
        id: $userId
      }
    ) {
      clientMutationId
    }
    createUsersToOrganization(
      input: {
        usersToOrganization: {
          userId: $userId
          organizationId: $organizationId
          locationId: $locationId
          role: $role
        }
      }
    ) {
      organization {
        id
        type
      }
    }
  }
`
