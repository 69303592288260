import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isUsingProductionServer } from 'services/helper'
import { CREATE_ORGANIZATION } from './useCreateOrg'

interface Variables {
  name?: string
  type?: string
  subtype?: string
  domain?: string
  details: {
    [key: string]: any
  }
}
export const useSuperAdminCreateOrg = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { name, type, subtype, domain, details } = variables

      await request(CREATE_ORGANIZATION, {
        name,
        type,
        subtype,
        domain,
        details,
        testMode: !isUsingProductionServer,
      })
    },
    { onSuccess: () => queryClient.invalidateQueries(['companies']) }
  )
}
