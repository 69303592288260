import React from 'react'
import styled from '@emotion/styled'

interface Props {
  name: string
  excerpt?: string
  image: string
  vendorName?: string
  freeTrialDays?: string
}
const PreviewBox: React.FC<Props> = ({ name, excerpt, image, vendorName, freeTrialDays }) => {
  return (
    <PreviewContainer>
      <Box>
        <PreviewTitle>Preview Card</PreviewTitle>
        <AppContent>
          <BoxType>{freeTrialDays ? 'Try' : 'Get'}</BoxType>
          <Logo src={image} />
          <BoxTitle>{name}</BoxTitle>
          {vendorName && <VendorName>by {vendorName}</VendorName>}
          <Excerpt>{excerpt}</Excerpt>
        </AppContent>
      </Box>
    </PreviewContainer>
  )
}

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 0px;
  text-align: center;
  position: relative;
  max-width: 760px;
`

const Box = styled.div`
  border: 1px solid #7e57c2;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  max-width: 280px;
`

const PreviewTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  color: #7e57c2;
  text-transform: uppercase;
  background: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
`

const AppContent = styled.div`
  position: relative;
  border: 1px solid #bdbdbd;
  padding: 15px;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: left;
`

const Logo = styled.img`
  max-width: 60px;
`

const BoxTitle = styled.h3`
  margin-top: 10px;
`

const BoxType = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  background: #00a2ff;
  color: white;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1;
  padding: 4px 8px;
  border-radius: 1000px;
`

const Excerpt = styled.p`
  font-size: 14px;
  color: #676773;
  line-height: 1.5;
`

const VendorName = styled.div`
  transform: translateY(-8px);
  color: #777;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 12px;
`

export default PreviewBox
