import { useNavigate } from 'react-router'
import OrganizationInvite from 'views/setup/OrganizationInvite'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useGetInviteOrg } from 'data/hooks'

const InviteScreen = () => {
  const navigate = useNavigate()
  const { inviteDetails, isLoading } = useGetInviteOrg()

  if (!isLoading && !inviteDetails) navigate('/')
  if (!inviteDetails) return <FullPageLoading />

  return (
    <OrganizationInvite
      inviterOrgId={inviteDetails.id}
      inviterOrgName={inviteDetails.name}
      inviterOrgType={inviteDetails.type}
      inviterTermPdf={inviteDetails.termsPdf}
    />
  )
}

export default InviteScreen
