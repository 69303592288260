import styled from '@emotion/styled'
import { Button } from 'components/theme'
import SuccessImage from 'assets/product-listing-success.png'
import { useGetNewProductListing, useUpdateOrgMetadata } from 'data/hooks'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useEffect } from 'react'

const CheckoutSuccess = () => {
  const { newProductListing } = useGetNewProductListing()
  const baseOrgUrl = useBaseOrgUrl()
  const updateOrgMetadata = useUpdateOrgMetadata()

  console.log('success')
  useEffect(() => {
    updateOrgMetadata.mutate({ deleteAppMetadataKeys: ['clocListing'] })
  }, [])

  return (
    <Root>
      <Image src={SuccessImage} />
      <Title>You have successfully connected a product listing.</Title>
      <Content>Jump into the listing editor to update your listing information.</Content>
      <Button
        component="link"
        to={`${baseOrgUrl}/product/settings/${newProductListing?.productId}`}
      >
        Continue
      </Button>
    </Root>
  )
}

const Root = styled.div`
  text-align: center;
`

const Title = styled.h3``

const Content = styled.div`
  margin-bottom: 24px;
`

const Image = styled.img`
  max-width: 180px;
`

export default CheckoutSuccess
