import styled from '@emotion/styled'
import { SectionLayoutType } from 'generated/graphql'
import { useAssocHomeEditor } from './assocHomeEditorContext'

interface Props {
  toggleMenu: () => void
}
const AddMenu = ({ toggleMenu }: Props) => {
  const { addSection } = useAssocHomeEditor()
  const addMenuClick = (type: SectionLayoutType) => () => {
    addSection(type)
    toggleMenu()
  }

  return (
    <Root>
      <MenuItem onClick={addMenuClick(SectionLayoutType.Basic)}>Basic</MenuItem>
      <MenuItem onClick={addMenuClick(SectionLayoutType.LargeProduct)}>
        Large Product Slider
      </MenuItem>
      <MenuItem onClick={addMenuClick(SectionLayoutType.ProductsSlider)}>Products Slider</MenuItem>
      <MenuItem onClick={addMenuClick(SectionLayoutType.ProductsGrid)}>Products Grid</MenuItem>
      <MenuItem onClick={addMenuClick(SectionLayoutType.ProductType)}>
        Product Type Selector
      </MenuItem>
      <MenuItem onClick={addMenuClick(SectionLayoutType.CategoryList)}>Category List</MenuItem>
      <MenuItem onClick={addMenuClick(SectionLayoutType.TheoremFeatures)}>
        Theorem Features
      </MenuItem>
    </Root>
  )
}

const Root = styled.div`
  transform: translateX(-50%);
  border: 1px solid #999;
  border-radius: 6px;
  position: absolute;
  background: white;
  overflow: hidden;
  bottom: 52px;
  left: 50%;
`

const MenuItem = styled.div`
  border-bottom: 1px solid #999;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;

  &:last-of-type {
    border-bottom: 0;
  }

  &:hover {
    background: #eaeaea;
  }
`

export default AddMenu
