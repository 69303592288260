import React from 'react'
import styled from '@emotion/styled'
import { Formik, Field, Form } from 'formik'
import { useLazyApi } from 'hooks/useApi'

import Button from 'components/theme/Button'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const PaymentSetup: React.FC = () => {
  const baseOrgUrl = useBaseOrgUrl()
  const [stripeConnect] = useLazyApi('stripe/connect/standard-account-onboarding')

  const path = `https://${
    process.env.NODE_ENV === 'development' ? 'theorem.local:3100' : 'app.theoremlegal.com'
  }`

  const connectToStripe = async () => {
    try {
      const response = await stripeConnect({
        refreshUrl: `${path}${baseOrgUrl}/upgrade/setup/2`,
        returnUrl: `${path}${baseOrgUrl}/stripe-account-status`,
      })

      window.location.assign(response.url)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Container>
      <CallToAction>
        <H4>Connect your bank account or debit card</H4>
        <p>
          We use Stripe to make sure you get paid on time and to keep you and your bank details
          secure.
        </p>
      </CallToAction>
      <Formik initialValues={{ picked: '' }} onSubmit={connectToStripe}>
        {({ values }) => (
          <StyledForm>
            <FormTitle>I'd like to set up payments as:</FormTitle>
            <OptionLine>
              <Field type="radio" name="picked" value="Stripe Standard" />
              <RadioInfo>
                <H4>A Stripe Standard Account</H4>
                <p>
                  Theorem uses Stripe connected accounts to support our customers with world-class,
                  secure payment processing and enable faster distribution of sales proceeds. You
                  can create a new Stripe account OR use your existing Stripe account.
                  This may require s multi-factor authentication via mobile devices or another
                  system. This is a password-based account.
                </p>
              </RadioInfo>
            </OptionLine>
            <a href="https://stripe.com/global" target="_blank">
              Available in the US and other supported countries (excluding preview or beta
              countries)
            </a>
            {/* <a>Find out more about what account type is right for you in our Help Center.</a> */}
            <SetUpButton type="submit" disabled={values.picked === ''}>
              set up payments
            </SetUpButton>
          </StyledForm>
        )}
      </Formik>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledForm = styled(Form)`
  margin-top: 16px;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 80%;
`

const OptionLine = styled(Form)`
  display: flex;
  margin-bottom: 16px;
  > * {
    margin-right: 8px;
  }
`

const RadioInfo = styled.div``

const H4 = styled.h4`
  margin: 0;
`

const CallToAction = styled.div`
  text-align: center;
  width: 50%;
  margin: 0 auto;
`

const FormTitle = styled.h5`
  margin: 16px 0;
`

const SetUpButton = styled(Button)`
  margin-top: 16px;
  align-self: center;
  background-color: #498de6;
  color: white;
  width: 15em;
  border-radius: 50px;

  &:hover {
    color: #498de6;
    background-color: white;
  }

  &:disabled {
    border-color: #498de6;
    color: white;
    opacity: 0.4;

    &:hover {
      color: white;
      background-color: #498de6;
    }
  }
`

export default PaymentSetup
