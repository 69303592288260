import styled from '@emotion/styled'
import { Route, Routes, Link } from 'react-router-dom'
import { BoxWrapper } from 'components/theme'
import UserFirmTechstack from 'views/lawfirms/techstack/UserFirmTechstack'
import TabsMenu from 'components/global/TabsMenu'
import PersonalTechstack from 'components/techstack/PersonalTechstack'
import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { UserRole } from 'generated/graphql'

function UpdateTechStack() {
  const { viewer } = useViewer()
  const baseOrgUrl = useBaseOrgUrl()

  const menu = [
    {
      url: `personal`,
      text: 'Add to Personal Stack',
      helpContent: 'Apps outside of those offered by your firm. Not shared to other firm users',
    },
    {
      url: '.',
      exact: true,
      text: 'Select from your Company’s toolbox',
    },
  ]

  return (
    <Root>
      <BoxWrapper>
        {(viewer.role === UserRole.Owner || viewer.role === UserRole.Admin) && (
          <Button to={`${baseOrgUrl}/techstack-management`}>Edit my Firm Apps</Button>
        )}
        <TabsMenu menuItems={menu} />
        <Routes>
          <Route path="/" element={<UserFirmTechstack />} />
          <Route path="personal" element={<PersonalTechstack />} />
        </Routes>
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div`
  min-height: 100vh;
`

const Button = styled(Link)`
  display: inline-block;
  border-radius: 100px;
  padding: 4px 12px;
  background: ${({ theme }) => theme.color.primaryBlue};
  color: white;
  font-weight: 500;
  position: absolute;
  right: 40px;
  top: 46px;

  &:hover {
    color: white;
  }
`

export default UpdateTechStack
