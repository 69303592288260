import React from 'react'
import styled from '@emotion/styled'

import { Formik, Form, Field } from 'formik'
import { Button } from 'components/theme'
import { MARKETPLACE_SELLER_AGREEMENT, SUBSCRIPTION_AGREEMENT } from 'services/globalAssets'
import { useRedirect } from 'hooks/useRedirect'

const TermsOfService: React.FC = () => {
  const redirect = useRedirect()

  return (
    <Container>
      <TermsTitle>Seller Program Terms & Conditions</TermsTitle>
      <p>
        We have updated Theorem's Marketplace Seller Agreement. Changes are effective for all
        sellers as of December 1, 2021. You are required to read and agree to all these terms and
        conditions before continuing to be able to access Theorem Marketplace Seller features. By
        clicking below you agree and accept the terms and conditions of the Theorem services.
      </p>
      <Formik
        initialValues={{ terms: false, privacy: false }}
        onSubmit={() => redirect('upgrade/setup/2')}
      >
        {({ values }) => (
          <Form>
            <Field type="checkbox" name="terms" />
            &nbsp;
            <label htmlFor="terms">
              I've read and agree with Theorem's{' '}
              <a target="_blank" href={SUBSCRIPTION_AGREEMENT}>
                Subscription Agreement
              </a>
            </label>{' '}
            <br />
            <br />
            <Field type="checkbox" name="privacy" />
            &nbsp;
            <label htmlFor="privacy">
              I've read and agree with Theorem's{' '}
              <a target="_blank" href={MARKETPLACE_SELLER_AGREEMENT}>
                Marketplace Seller Agreement
              </a>
            </label>
            <br />
            <br />
            <DoneButton type="submit" disabled={Object.values(values).includes(false)}>
              Done
            </DoneButton>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default TermsOfService

const TermsTitle = styled.h2`
  font-size: 2pxm;
  margin: 0 0 16px;
  padding: 0;
`

const Container = styled.div`
  text-align: justify;
  width: min-content;
  min-width: 34em;
`

const DoneButton = styled(Button)`
  background-color: #498de6;
  color: white;
  width: 100%;
  border-radius: 3px;

  &:hover {
    color: #498de6;
    background-color: white;
  }

  &:disabled {
    border-color: #498de6;
    color: white;
    opacity: 0.4;

    &:hover {
      color: white;
      background-color: #498de6;
    }
  }
`
