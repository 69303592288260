import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Header from './Header'
import Techstack from './Techstack'
import type { UserProfileTechstack, UserDetails } from 'interfaces/user/user.interface'
import { UserTechstackData } from 'hooks/useUserQuery/parseUserData'
import UserStories from './UserStories'
import { css } from '@emotion/react'
import { useUserById } from 'data/hooks'

type Terms = Array<{ name: string; id: number; taxonomyId: number }>
export type UserProfile = {
  id: number
  first: string
  last: string
  email: string
  issubscribed: boolean
  details: UserDetails
  testMode: boolean
  techstack: UserProfileTechstack[]
  terms: Terms
  orgJobTitle?: string
  location?: {
    city?: string
    state?: string
    country?: string
  }
}

export const getTaxonomyTerms = (terms: Terms, taxId: number) =>
  terms.filter(({ taxonomyId }) => taxonomyId === taxId)

const parseTechstack = (techstackData?: UserTechstackData[]) => {
  return (
    techstackData?.map(({ techstack, logins, updatedAt: lastLogin }) => {
      const { id: techstackId, product } = techstack
      const { id, name, details, slug, show, termToProducts } = product
      const terms = termToProducts.map(({ term: { name, id, taxonomyId } }) => ({
        name,
        id,
        taxonomyId,
      }))
      return {
        id,
        name,
        details,
        slug,
        show,
        terms,
        techstackId,
        logins,
        lastLogin,
      }
    }) || []
  )
}

interface PersonalTechstackData {
  __typename?: 'PersonalTechstack'
  createdAt: any
  product: {
    __typename?: 'Product'
    name: string
    slug: string
    details: any
    show: boolean
    id: number
    termToProducts: Array<{
      __typename?: 'TermToProduct'
      term: { __typename?: 'Term'; name: string; id: number; taxonomyId: number }
    }>
  }
}
const parsePersonalTechstack = (personalTechstackData?: PersonalTechstackData[]) =>
  personalTechstackData?.map(({ product, createdAt }) => {
    const { id, name, details, slug, show, termToProducts } = product
    const terms = termToProducts.map(({ term: { name, id, taxonomyId } }: any) => ({
      name,
      id,
      taxonomyId,
    }))
    return {
      id,
      name,
      details,
      slug,
      show,
      terms,
      lastLogin: createdAt,
    }
  }) || []

const Profile: React.FC<{ selectedId: number; defaultTab?: 'TECHSTACK' | 'USER_STORY' }> = ({
  selectedId,
  defaultTab = 'TECHSTACK',
}) => {
  const [parsedUser, setParsedUser] = useState<UserProfile | null>(null)
  const [tab, setTab] = useState<'TECHSTACK' | 'USER_STORY'>(defaultTab)
  const { user } = useUserById(selectedId)

  const pruneDuplicates = (data: UserProfileTechstack[]) =>
    data.reduce((noDuplicates: UserProfileTechstack[], stack: UserProfileTechstack) => {
      if (noDuplicates.some((item) => item.id === stack.id)) return noDuplicates
      noDuplicates.push(stack)
      return noDuplicates
    }, [])

  const getTechstack = () => {
    const parsedUserToTechstack = parseTechstack(user?.usersToTechstacks)
    const parsedPersonalTechstack = parsePersonalTechstack(user?.personalTechstacks)
    const techstack = pruneDuplicates([...parsedUserToTechstack, ...parsedPersonalTechstack])

    return techstack
  }
  useEffect(() => {
    if (!user) return
    interface Term {
      id: number
      name: string
      taxonomyId: number
    }
    const terms: Term[] = user?.termToUsers.map(
      ({ term: { name, id, taxonomyId } }: { term: Term }) => ({
        name,
        id,
        taxonomyId,
      })
    )
    const techstack = getTechstack()

    const userSelectedJobTitle = terms.filter(({ taxonomyId }) => taxonomyId === 8)
    const userOrgJobTitles = user?.usersToOrganizations
      .map(({ termByJobTitle }) => ({
        ...termByJobTitle,
      }))
      .filter(({ id }) => id)

    const jobTitleNames = [...userSelectedJobTitle, ...userOrgJobTitles]
      .filter(
        (value, index, array) => array.findIndex((arrVal) => arrVal.id === value.id) === index
      )
      .map(({ name }) => name)

    const jobTitle = jobTitleNames.join(', ')
    setParsedUser({ ...user, techstack, terms, orgJobTitle: jobTitle })
  }, [JSON.stringify(user)])

  if (!parsedUser) return <></>

  return (
    <>
      <Header user={parsedUser} />

      <Tabs>
        <Tab active={tab === 'TECHSTACK'} onClick={() => setTab('TECHSTACK')}>
          Techstack
        </Tab>
        <Tab active={tab === 'USER_STORY'} onClick={() => setTab('USER_STORY')}>
          User Stories
        </Tab>
      </Tabs>
      <Body>
        {tab === 'TECHSTACK' && <Techstack data={parsedUser.techstack} />}
        {tab === 'USER_STORY' && <UserStories userId={selectedId} />}
      </Body>
    </>
  )
}

const Body = styled.div`
  min-height: 170px;
`

const Tabs = styled.div`
  display: flex;
  padding-top: 12px;
  padding: 4px 30px 0px;
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
`

const tabStyles = css`
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #9013fe;
    opacity: 1;
    transition: 250ms opacity;
  }

  &.active:after,
  &:hover:after {
    opacity: 1;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.87);
  }
`

const Tab = styled.div<{ active: boolean }>`
  ${tabStyles}
  font-weight: ${({ active }) => (active ? 500 : 400)};

  &:after {
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
`

export default Profile
