import { useEffect, useState, useRef } from 'react'
import Chart from 'chart.js'

export const useChartJs = ({
  type = 'bar',
  options = defaultOptions,
}: {
  type: string
  options: any
}) => {
  const [chart, setChart] = useState<any>()
  const [labels, setLabels] = useState<any>([])
  const [datasets, setDatasets] = useState<any>({})
  const ctx = useRef(null)

  const setChartData = ({ labels, datasets }: any) => {
    setLabels(labels)
    setDatasets(datasets)
  }

  useEffect(() => {
    if (labels && datasets) {
      if (chart) chart.destroy()

      const newChart = new Chart(ctx.current as any, {
        type,
        data: {
          labels,
          datasets,
        },
        options,
      })

      setChart(newChart)
    }

    return () => {
      if (chart) chart.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, datasets])

  return { ctx, setChartData }
}

const defaultOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}
