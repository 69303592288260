import React from 'react'
import styled from '@emotion/styled'

function PlanDescription({ plan }: any) {
  const {
    metadata: { color, name },
    amount,
  } = plan

  const price = amount === 0 ? '$0' : `$${amount / 100}`

  return (
    <Root>
      <Title color={color}>{name || 'Title'}</Title>
      <Price color={color}>
        {price} <PriceUnit>/ year</PriceUnit>
      </Price>
      <Features>
        <Feature>First Feature</Feature>
        <Feature>Second Feature</Feature>
        <Feature>Third Feature</Feature>
      </Features>
      <ButtonContainer>
        <Button>Purchase</Button>
      </ButtonContainer>
    </Root>
  )
}

const Root = styled.div`
  background: white;
  margin: 30px;
  padding: 28px;
  border-radius: 20px;
  width: 33.3333%;
  line-height: 1;
  box-shadow: 0 5px 30px rgba(88, 28, 125, 0.2);
`

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin: 0 -28px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(88, 28, 125, 0.2);
  color: ${({ color }) => (color ? `${color}` : 'black')};
`

const Price = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: ${({ color }) => (color ? `${color}` : 'black')};
`

const PriceUnit = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  margin-left: 4px;
`

const Button = styled.button`
  padding: 10px 40px;
  border-radius: 100px;
  font-size: 20px;
  font-weight: bold;
  border: none;
  color: white;
  background: linear-gradient(140deg, rgba(144, 19, 254, 1) 0%, rgba(73, 141, 230, 1) 100%);
  letter-spacing: 0.3px;
  box-shadow: 0 5px 20px rgba(88, 28, 125, 0.35);
`

const Features = styled.div`
  padding: 20px 0 30px;
  text-align: center;
`

const Feature = styled.div`
  border-bottom: 1px solid rgba(88, 28, 125, 0.2);
  padding: 12px 0;

  &:last-child {
    border-bottom: none;
  }
`

const ButtonContainer = styled.div`
  text-align: center;
`

export default PlanDescription
