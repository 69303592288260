import React, { useEffect } from 'react'
import { FieldArray } from 'formik'
import { Dropdown, MoneyInput } from 'components/theme/form'
import { Icon } from 'components/theme'
import styled from '@emotion/styled'
import PriceIdInput from 'components/theme/form/PriceIdInput'
import TieredLastInput from './TieredLastInput'
import { useViewer } from 'context/viewerContext'
import CurrencyFieldFormik from 'components/theme/form/CurrencyFieldFormik'

const defaultTieredValues = [
  {
    firstUnit: 0,
    lastUnit: 1,
    perUnit: 0,
    flatFee: 0,
  },
  {
    firstUnit: 2,
    perUnit: 0,
    flatFee: 0,
  },
]

type Tier = {
  firstUnit: number
  lastUnit?: number
  perUnit: number
  flatFee: number
}

const TieredTable: React.FC<{ setFieldValue: any; resource: any; planIndex: number }> = ({
  setFieldValue,
  resource,
}) => {
  const { stripeReady } = useViewer()

  useEffect(() => {
    if (!resource.tieredPrices || resource.tieredPrices.length === 0) {
      setFieldValue(`tieredPrices`, defaultTieredValues)
    }
  }, [])

  const updateTableValues = (changedIndex: number, newValue?: number, deleted?: boolean) => {
    const correctedTiers: Tier[] = JSON.parse(JSON.stringify(resource.tieredPrices))
    if (newValue) correctedTiers[changedIndex].lastUnit = newValue
    if (deleted) correctedTiers.splice(changedIndex, 1)
    // formik doesn't setFieldValue fast enough for us to see change yet.
    for (let i = changedIndex; i >= 0; i--) {
      const previousTier = i !== 0 ? correctedTiers[i - 1] : undefined
      const currentTier = correctedTiers[i]
      if (currentTier.lastUnit && currentTier.firstUnit >= currentTier.lastUnit)
        currentTier.firstUnit = currentTier.lastUnit - 1
      if (
        previousTier &&
        previousTier.lastUnit &&
        currentTier.firstUnit - 1 !== previousTier.lastUnit
      )
        previousTier.lastUnit = currentTier.firstUnit - 1
      if (i === 0) currentTier.firstUnit = 0
      correctedTiers[i] = currentTier
      if (previousTier) correctedTiers[i - 1] = previousTier
    }
    // these overlap at the starting index, is that a problem?
    for (let i = changedIndex; i < correctedTiers.length; i++) {
      const nextTier = i + 1 < correctedTiers.length ? correctedTiers[i + 1] : undefined
      const currentTier = correctedTiers[i]
      if (currentTier.lastUnit && currentTier.firstUnit >= currentTier.lastUnit)
        currentTier.lastUnit = currentTier.firstUnit + 1
      if (nextTier && currentTier.lastUnit && currentTier.lastUnit + 1 !== nextTier.firstUnit)
        nextTier.firstUnit = currentTier.lastUnit + 1
      correctedTiers[i] = currentTier
      if (nextTier) correctedTiers[i + 1] = nextTier
    }
    correctedTiers.forEach(({ firstUnit, lastUnit }, i) => {
      if (firstUnit !== resource.tieredPrices[i].firstUnit)
        setFieldValue(`tieredPrices[${i}].firstUnit`, firstUnit)
      if (lastUnit !== resource.tieredPrices[i].lastUnit)
        setFieldValue(`tieredPrices[${i}].lastUnit`, lastUnit)
    })
  }

  const Tier = (index: number, arrayHelpers: any) => (
    <tr key={`tier-${index}`}>
      {index === 0 && <FirstTitle>For The First</FirstTitle>}
      {index > 0 && <TitleCell>For The Next</TitleCell>}
      <InputCell>
        <StyledInput
          name={`tieredPrices[${index}].firstUnit`}
          value={resource.tieredPrices[index].firstUnit}
          disabled
          inputMode="numeric"
        />
      </InputCell>
      <InputCell>
        {resource.tieredPrices[index].lastUnit !== undefined ? (
          <TieredLastInput
            name={`tieredPrices[${index}].lastUnit`}
            type="number"
            updateTableValues={updateTableValues}
            index={index}
          />
        ) : (
          <>&infin;</>
        )}
      </InputCell>
      <InputCell>
        <MoneyInput
          label=""
          style={{ border: 'none' }}
          name={`tieredPrices[${index}].perUnit`}
          currency={resource.currency}
        />
      </InputCell>
      <InputCell>
        <MoneyInput label="" name={`tieredPrices[${index}].flatFee`} currency={resource.currency} />
      </InputCell>
      <TierButtonWrapper>
        {index > 0 && index < resource.tieredPrices.length - 1 && (
          <Icon
            name="times"
            color="#f44336"
            onClick={(event: any) => {
              event.preventDefault()
              arrayHelpers.remove(index)
              updateTableValues(index, undefined, true)
            }}
          />
        )}
      </TierButtonWrapper>
    </tr>
  )

  return (
    <FieldArray
      name={`tieredPrices`}
      render={(arrayHelpers) => (
        <Root>
          <HelperActionsContainer>
            <Flex>
              <Half>
                <Dropdown
                  label="Billing Period"
                  name={`tieredBillingPeriod`}
                  onChange={(_e: any, { value }: any) =>
                    setFieldValue(`tieredBillingPeriod`, value)
                  }
                  style={{ zIndex: '101' }}
                  options={[
                    'Daily',
                    'Monthly',
                    'Weekly',
                    'Every 3 Months',
                    'Every 6 Months',
                    'Yearly',
                  ].map((unit) => ({ text: unit, value: unit }))}
                  defaultValue={resource.tieredBillingPeriod}
                  setFieldValue={setFieldValue}
                />
              </Half>
              <Half>
                <CurrencyFieldFormik label="Currency" name="currency" />
              </Half>
            </Flex>
          </HelperActionsContainer>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <FirstHead>First</FirstHead>
                  <Head>Last</Head>
                  <Head>Per Unit</Head>
                  <Head>Flat Fee</Head>
                </tr>
              </thead>
              <tbody>
                {resource.tieredPrices &&
                  resource.tieredPrices.map((_v: any, i: number) => Tier(i, arrayHelpers))}
              </tbody>
            </Table>
            <AddTierButton
              onClick={(event) => {
                event.preventDefault()
                setFieldValue(
                  `tieredPrices[${resource.tieredPrices.length - 1}].lastUnit`,
                  resource.tieredPrices[resource.tieredPrices.length - 1].firstUnit + 1
                )
                arrayHelpers.push({
                  firstUnit: resource.tieredPrices[resource.tieredPrices.length - 1].firstUnit + 2,
                  perUnit: 0,
                  flatFee: 0,
                })
              }}
            >
              + Add another tier
            </AddTierButton>
          </TableWrapper>

          {stripeReady && (
            <PriceIdInput
              name={`stripeId`}
              onChange={(_e: any, { value }: any) => setFieldValue(`stripeId`, value)}
            />
          )}
        </Root>
      )}
    />
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`

const Flex = styled.div`
  display: flex;
  margin: 0 -8px 16px;
  position: relative;
  z-index: 999;
`

const Half = styled.div`
  width: 50%;
  padding: 0 8px;
`

const StyledInput = styled.input`
  /* text-align: center; */
  max-width: 80px;
`

const Head = styled.th`
  background-color: #f2f2f2;
  border: 1px solid rgba(34, 36, 38, 0.15);
`

const TierButtonWrapper = styled.td`
  border: none !important;
  padding: 2px;
  min-width: 4px;
`

const HelperActionsContainer = styled.div`
  margin-top: 5px;
`

const FirstHead = styled.th`
  background-color: #f2f2f2;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px 0 0 0;
  padding: 8px 0px;
  white-space: nowrap;
`

const FirstTitle = styled.td`
  padding: 4px 2px;
  background-color: #f2f2f2;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px 0 0 0;
  white-space: nowrap;
`

const TitleCell = styled.td`
  background-color: #f2f2f2;
  border: 1px solid rgba(34, 36, 38, 0.15);
  white-space: nowrap;
  padding: 4px 4px;
`

const InputCell = styled.td`
  padding: 0;
  overflow: hidden;
  * {
    text-align: center !important;
    > input {
      padding: 0;
    }
  }
  input {
    padding: 0;
    background: transparent;
    margin: 0 auto;
    text-align: center;
    border: none !important;
    ::placeholder,
    :disabled,
    :active,
    :focus {
      text-align: center;
    }
    ::placeholder {
      opacity: 1;
      color: black !important;
    }
  }
`

const TableWrapper = styled.div`
  margin: 16px 0;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3px;
  margin-left: 0;

  th,
  td {
    font-size: 14px;
  }

  td {
    border: 1px solid rgba(34, 36, 38, 0.15);
    line-height: 1.2;
    text-align: center;
  }
`

const AddTierButton = styled.div`
  background: none;
  border: none;
  font-weight: 500;
  margin: 4px 0 8px;
  color: #4183c4;
  padding: 0;
  cursor: pointer;
`

export default TieredTable
