import { useField } from 'formik'
import Label from './Label'
import CurrencyField from './CurrencyField'

interface Props {
  label?: string
  name: string
}
const CurrencyFieldFormik = ({ label, name }: Props) => {
  const [, meta, helpers] = useField({ name })

  const onCurrencyChange = (value?: string) => {
    helpers.setValue(value)
  }

  return (
    <div>
      {label && <Label>{label}</Label>}
      <CurrencyField currencyValue={meta.value} onCurrencyChange={onCurrencyChange} />
    </div>
  )
}

export default CurrencyFieldFormik
