import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useLazyApi } from 'hooks/useApi'
import { FullPageLoading } from 'components/PulsatingLoader'
import { format } from 'date-fns'
import { Button } from 'components/theme'
import { fixCurrencyDisplay } from 'services/helper'
import { css } from '@emotion/react'
import { useOrg } from 'data/organization/useOrg'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

// TODO: only an owner can see this page
function ProfileBilling() {
  const [getBilling] = useLazyApi('stripe/organization/get-billing')
  const [getCustomerPortal] = useLazyApi('stripe/organization/get-customer-portal')
  const [billingData, setBillingData] = useState<any>({ loading: true })
  const { organization } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()

  useEffect(() => {
    ;(async () => {
      const billing = await getBilling({
        organizationId: organization?.id,
      })

      setBillingData({ ...billing, loading: false })
      // console.log(billing)
    })()
  }, [])

  if (billingData.loading) return <FullPageLoading />

  const renewalDate = billingData?.upcomingInvoice?.next_payment_attempt
    ? format(billingData?.upcomingInvoice?.next_payment_attempt * 1000, 'MMMM d, yyyy')
    : null

  const getCustomerPortalLink = async () => {
    const customerPortal = await getCustomerPortal({
      returnUrl: `${window.location.origin}${baseOrgUrl}/settings/billing`,
    })

    window.location.href = customerPortal.url
  }

  const { upcomingInvoice } = billingData

  return (
    <Root>
      <LinedBox>
        <BoxContent>
          <BoxTitle>Plan</BoxTitle>
          <Text>
            Your team is on the {billingData?.product?.name} plan. The next payment of{' '}
            {fixCurrencyDisplay(upcomingInvoice?.total)} will be occur on {renewalDate}.
          </Text>

          <TableHeader>
            <ItemHeader>Item</ItemHeader>
            <CountHeader>Count</CountHeader>
            <UnitPriceHeader>Unit Price</UnitPriceHeader>
            <PriceHeader>Price</PriceHeader>
          </TableHeader>
          <TableBody>
            {upcomingInvoice?.lines.data.map((invoiceItem: any, index: number) => (
              <Row key={index}>
                <Item>{invoiceItem.description}</Item>
                <Count>{invoiceItem.quantity}</Count>
                <UnitPrice>{fixCurrencyDisplay(invoiceItem.price.unit_amount)}</UnitPrice>
                <Price>{fixCurrencyDisplay(invoiceItem.amount)}</Price>
              </Row>
            ))}
          </TableBody>
          <TotalWrapper>
            <TotalName>TOTAL</TotalName>
            <TotalCost>{fixCurrencyDisplay(upcomingInvoice?.total || 0)}</TotalCost>
          </TotalWrapper>
        </BoxContent>
        <BoxFooter>
          <BillingPortalButton onClick={getCustomerPortalLink}>Billing Portal</BillingPortalButton>
        </BoxFooter>
      </LinedBox>
    </Root>
  )
}

const Root = styled.div``

const LinedBox = styled.div`
  border: 1px solid #eaeaea;
  border-radius: 6px;
  overflow: hidden;
  max-width: 800px;
`

const TableHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 4px;
`

const TableBody = styled.div`
  padding-top: 12px;
  min-height: 72px;
`

const header = css`
  text-transform: uppercase;
  color: #666;
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  letter-spacing: 0.02rem;
`

const leftMargin = css`
  margin-left: 24px;
  text-align: right;
`

const item = css`
  flex: 1;
  text-align: left;
`

const ItemHeader = styled.div`
  ${header}
  ${item}
`
const Item = styled.div`
  ${item}
  font-size: 12px;
`

const count = css`
  flex: 0 0 100px;
  ${leftMargin}
`
const CountHeader = styled.div`
  ${header}
  ${count}
`
const Count = styled.div`
  ${count}
`

const unitPriceCss = css`
  flex: 0 0 140px;
  ${leftMargin}
`
const UnitPriceHeader = styled.div`
  ${header}
  ${unitPriceCss}
`
const UnitPrice = styled.div`
  ${unitPriceCss}
`

const priceCss = css`
  flex: 0 0 60px;
  ${leftMargin}
`
const PriceHeader = styled.div`
  ${header}
  ${priceCss}
`
const Price = styled.div`
  ${priceCss}
`

const BoxContent = styled.div`
  padding: 24px;
`

const BoxTitle = styled.h3`
  font-size: 20px;
`

const Text = styled.p`
  margin-bottom: 24px;
`

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #eaeaea;
  padding-top: 12px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`

const TotalName = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: black;
`

const TotalCost = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: black;
`

const BillingPortalButton = styled(Button)`
  margin-left: auto;
`

const BoxFooter = styled.div`
  display: flex;
  border-top: 1px solid #eaeaea;
  background: #fafafa;
  padding: 12px 24px;
`

export default ProfileBilling
