import { useEffect } from 'react'
import { useLazyApi } from 'hooks/useApi'
import { FullPageLoading } from 'components/PulsatingLoader'
import styled from '@emotion/styled'
import { useOrg } from 'data/organization/useOrg'

function LawfirmCheckout() {
  const [getStripeSession] = useLazyApi('stripe/organization/get-lawfirm-upgrade-checkout')
  const { organization } = useOrg()

  useEffect(() => {
    ;(async () => {
      const { url } = await getStripeSession({
        organizationId: organization?.id,
      })

      window.location.replace(url)
    })()
  }, [])

  return (
    <Root>
      <FullPageLoading />
    </Root>
  )
}

const Root = styled.div``

export default LawfirmCheckout
