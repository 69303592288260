import { GqlAllProductsQuery } from 'generated/graphql'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'
import { GET_PRODUCTS } from './useAllProducts'
import _ from 'lodash'

export const useQueryProductsDropdown = (withImage?: boolean) => {
  const request = useGql()
  return useQuery(
    ['products', 'dropdown'],
    async () => {
      const data = await request<GqlAllProductsQuery>(GET_PRODUCTS)

      return getProductOptions(data, withImage)
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  )
}

interface Option {
  key: string
  text: string
  value: number
  image?: {
    avatar: boolean
    src: string
  }
}

const getProductOptions = (
  { products = [] }: GqlAllProductsQuery,
  withImage?: boolean
): Option[] => {
  return products
    .filter(({ name }) => name)
    .map((product) => {
      const { details } = product

      const productOptions: Option = {
        key: `product-${product.id}`,
        text: product.name,
        value: product.id,
      }

      if (withImage) {
        productOptions.image = {
          avatar: false,
          src: _.get(details, 'squareLogoUrl', false) || _.get(details, 'logoUrl', ''),
        }
      }
      return productOptions
    })
}
