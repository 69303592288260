import React, { useEffect, useState } from 'react'
import WelcomeImageSvg from './welcome-image.svg'
import styled from '@emotion/styled'
import { StepComponent } from 'components/modals/StepModal'
import { useMatchedOrg } from './useMatchedOrg'

interface Props extends StepComponent {
  title: string
  content: string
  buttonText: string
}

const GreetComponent: React.FC<Props> = ({ title, content, onSkip, onNext, onFinish }) => {
  const [goNextSlide, setGoNextSlide] = useState(false)
  const [buttonText, setButtonText] = useState('Continue')
  const { matchedOrg } = useMatchedOrg()

  useEffect(() => {
    if (!matchedOrg) return

    const usersInOrg = matchedOrg?.usersToOrganizations

    // TODO: if user is already part of firm make sure to skip this
    if (usersInOrg.length === 0) {
      // this user is the owner
      setButtonText('We found your firm')
    } else {
      // this user is the second user
      setButtonText('We found your firm')
    }

    setGoNextSlide(true)
  }, [JSON.stringify(matchedOrg)])

  const onSubmit = () => {
    if (goNextSlide) onNext()
    else onFinish()
  }

  return (
    <Container>
      <ImageWrapper>
        <WelcomeImage src={WelcomeImageSvg} />
      </ImageWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <Content>{content}</Content>
      </ContentWrapper>
      <ButtonWrapper>
        <SkipButton onClick={onSkip}>Skip</SkipButton>
        <ActionButton onClick={onSubmit}>{buttonText}</ActionButton>
      </ButtonWrapper>
    </Container>
  )
}

const ImageWrapper = styled.div`
  text-align: center;
  margin-bottom: -62px;
`

const WelcomeImage = styled.img`
  width: 440px;
`

const Container = styled.div`
  margin-top: -82px;
  padding: 0 28px 28px;
`

const Title = styled.h1`
  color: #4d4d4d;
  font-size: 49px;
  margin-bottom: 8px;
`

const Content = styled.p`
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
  color: #707070;
  max-width: 530px;
`

const ContentWrapper = styled.div`
  border-bottom: 1px solid #c6c6c6;
  text-align: left;
`

const ButtonWrapper = styled.div`
  text-align: right;
  padding-top: 20px;
`

const SkipButton = styled.button`
  background: transparent;
  border: none;
  font-weight: 500;
  font-size: 22px;
  color: #505050;
`

const ActionButton = styled.button`
  margin-left: 40px;
  border: none;
  background: #8774d8;
  color: white;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 22px;
  border-radius: 100px;
`

export default GreetComponent
