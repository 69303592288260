import styled from '@emotion/styled'
import MainUserMenuDropdown from './MainUserMenuDropdown'

const UserMenuDropdown = () => {
  // we will add logic in here to switch main user menu dropdown into something else. Maybe use a reducer
  return (
    <Root>
      <DropdownWrapper>
        <DropdownContainer>
          <MainUserMenuDropdown />
        </DropdownContainer>
      </DropdownWrapper>
    </Root>
  )
}

const Root = styled.div`
  display: none;
  position: absolute;
  top: ${({ theme }) => theme.topBarHeight};
  right: 0;
  padding-top: 8px;
  padding-right: 4px;
`

const DropdownContainer = styled.div`
  width: 350px;
  border-radius: 6px;
  overflow: hidden;
`

const DropdownWrapper = styled.div`
  width: 350px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`

export default UserMenuDropdown
