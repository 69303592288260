import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlCreateUserStoryMutation } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  userId: number
  details?: any
  productId?: number
  ownerCreated: boolean
  to?: string
  when?: string
}
export const useCreateUserStory = () => {
  const request = useGql()
  const queryClient = useQueryClient()
  const { organization } = useOrg()

  return useMutation(
    async (variables: Variables) => {
      const { userId, details, productId, ownerCreated, to, when } = variables

      const response = await request<GqlCreateUserStoryMutation>(CREATE_USER_STORY, {
        userId,
        details,
        organizationId: organization?.id,
        ownerCreated,
        productId,
        to,
        when,
      })

      return response.createUserStory?.userStory?.id
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['userStory']),
    }
  )
}

const CREATE_USER_STORY = gql`
  mutation CreateUserStory(
    $productId: Int!
    $when: String!
    $to: String!
    $ownerCreated: Boolean!
    $organizationId: UUID!
    $details: JSON
    $userId: Int!
  ) {
    createUserStory(
      input: {
        userStory: {
          productId: $productId
          when: $when
          to: $to
          ownerCreated: $ownerCreated
          organizationId: $organizationId
          details: $details
          author: $userId
        }
      }
    ) {
      userStory {
        id
      }
    }
  }
`
