import { useState, useEffect } from 'react'
import { useViewer } from 'context/viewerContext'
import { useAssocToOrgByOrgId } from 'data/hooks'

export enum ViewType {
  MEMBER,
  INACTIVE,
}

export interface Association {
  id: string
  name: string
  viewType: ViewType
}

const useProfileAssociation = () => {
  const { viewer } = useViewer()
  const { assocToOrg, isLoading: assocToOrgLoading } = useAssocToOrgByOrgId()
  const [associations, setAssociations] = useState<Association[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (assocToOrgLoading || !assocToOrg) return

    // const assocTo
    const associations: Association[] = assocToOrg.map(({ association }) => {
      const isUserMember = viewer.organizations.some((org) => {
        return association.id === org.id
      })

      return isUserMember
        ? { viewType: ViewType.MEMBER, ...association }
        : { viewType: ViewType.INACTIVE, ...association }
    })

    setAssociations(associations)
    setLoading(false)
  }, [assocToOrgLoading, JSON.stringify(viewer)])

  return { assocToOrgLoading, loading, associations }
}

export default useProfileAssociation
