import React from 'react'
import styled from '@emotion/styled'
import ComingSoon from 'components/layout/ComingSoon'
import DealsImage from './vendor-workflow.png'

function WorkflowsComingSoon() {
  return (
    <Root>
      <ComingSoon
        title="Your no code third-party app integration & automation platform"
        image={DealsImage}
      >
        <p>
          No more one-off integrations. Your App use case knowledge base. Build your own full stack
          solutions. Coming Q2-Q3
        </p>
        <div>
          <RequestButton
            href="https://share.hsforms.com/1pylb9NU7Re6M_C6scaUaIQ3p9i3"
            target="_blank"
            rel="noopener noreferrer"
          >
            Request BETA
          </RequestButton>
        </div>
      </ComingSoon>
    </Root>
  )
}

const Root = styled.div``

const RequestButton = styled.a`
  background: #ffd400;
  padding: 12px 24px;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  white-space: nowrap;
`

export default WorkflowsComingSoon
