import React, { useState } from 'react'
import styled from '@emotion/styled'
import { OrganizationType } from 'generated/graphql'
import { useDeleteAssocToOrg } from 'data/hooks'
import { useOrg } from 'data/organization/useOrg'
import { useQueryClient } from '@tanstack/react-query'
import ConfirmModal from 'components/modals/ConfirmModal'

interface Org {
  id: string
  name: string
  type: OrganizationType
  domain: string
  members: any
  logo?: string
}

interface Props {
  organizations?: Org[]
}

interface DeletedOrg {
  id: string
  name: string
}

const NetworkRows = ({ organizations }: Props) => {
  const { association } = useOrg()
  const deleteAssocToOrg = useDeleteAssocToOrg()
  const queryClient = useQueryClient()
  const [deletedOrg, setDeletedOrg] = useState<DeletedOrg | undefined>()

  const clickRemoveButton = (id: string, name: string) => () => {
    if (!association) return

    setDeletedOrg({ id, name })
  }

  const handleRemoveOrg = () => {
    if (!deletedOrg || !association) return

    deleteAssocToOrg.mutate(
      { organizationId: deletedOrg.id, associationId: association.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['org', 'assocToOrg'])
        },
        onSettled: () => {
          setDeletedOrg(undefined)
        },
      }
    )
  }

  const handleCancelRemoveOrg = () => {
    setDeletedOrg(undefined)
  }

  return (
    <>
      {organizations?.map(({ id, name, type, domain, logo }) => (
        <tr key={id}>
          <td>
            <OrgColumn>
              <Left>
                <Image src={logo} />
              </Left>
              <Right>
                <Name>{name}</Name>
                <Type>{type}</Type>
              </Right>
            </OrgColumn>
          </td>
          <td>
            <Domain>{domain}</Domain>
          </td>
          <td>
            <RemoveButton onClick={clickRemoveButton(id, name)}>Remove</RemoveButton>
          </td>
        </tr>
      ))}
      {deletedOrg && (
        <ConfirmModal onCancel={handleCancelRemoveOrg} onConfirm={handleRemoveOrg}>
          Are you sure you want to remove {deletedOrg.name} from your network?
        </ConfirmModal>
      )}
    </>
  )
}

const RemoveButton = styled.button`
  color: #d32f2f;
  font-weight: 500;
  background: #fbf4f4;
  border: 1px solid #cd8585;
  border-radius: 2px;
  letter-spacing: 0.5px;
  transition: background 250ms;
  white-space: nowrap;

  &:hover {
    background: #efefef;
  }
`

const Image = styled.img`
  width: 48px;
`

const OrgColumn = styled.div`
  display: flex;
  align-items: center;
`

const Name = styled.div`
  font-size: 16px;
`

const Type = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #555;
  letter-spacing: 0.5x;
`

const Domain = styled.div`
  font-weight: 500;
`

const Left = styled.div`
  margin-right: 12px;
`

const Right = styled.div``

export default NetworkRows
