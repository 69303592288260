import React from 'react'
import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'
import EcosystemPng from './ecosystem-marketplace-access.png'
import MarketPng from './market-moving-news.png'
import EntrepreneurPng from './entrepreneur-spotlight.png'

const PlanFeatures: React.FC = () => {
  return (
    <Root>
      <Title>Subscribe today and receive</Title>
      <Features>
        <Feature>
          <FeatureContainer>
            <Image src={EcosystemPng} />
            <FeatureContent>
              <FeatureTitle>Ecosystem and marketplace access</FeatureTitle>
              <FeatureText>
                Discover new tools and services. Compare tools side-by-side. Learn about tech
                stacks.
              </FeatureText>
            </FeatureContent>
          </FeatureContainer>
        </Feature>
        <Feature>
          <FeatureContainer>
            <Image src={MarketPng} />
            <FeatureContent>
              <FeatureTitle>Market-moving news</FeatureTitle>
              <FeatureText>
                Receive news highlights from key companies and legal tech players in Bi-weekly
                newsletters + market guides.
              </FeatureText>
            </FeatureContent>
          </FeatureContainer>
        </Feature>
        <Feature>
          <FeatureContainer>
            <Image src={EntrepreneurPng} />
            <FeatureContent>
              <FeatureTitle>Entrepreneur spotlights, interviews and events</FeatureTitle>
              <FeatureText>
                Receive news highlights from key companies and legal tech players in Bi-weekly
                newsletters + Buyer events and product guides
              </FeatureText>
            </FeatureContent>
          </FeatureContainer>
        </Feature>
      </Features>
    </Root>
  )
}

const Root = styled.div`
  padding: 60px 20px;
  background: #f2f2f2;

  ${breakpoint.mobile} {
    padding: 40px 20px;
  }
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 32px;
  font-size: 28px;
  letter-spacing: 0.02rem;

  ${breakpoint.mobile} {
    font-size: 20px;
  }
`

const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1024px;
  margin: 0 auto;
  align-items: stretch;
`

const Feature = styled.div`
  width: 33.3333%;
  padding: 0 20px;

  ${breakpoint.mobile} {
    width: 100%;
    max-width: 420px;
    margin: 0 auto 28px;

    &::last-of-type {
      margin-bottom: 0;
    }
  }
`

const FeatureTitle = styled.h4`
  font-size: 18px;
  letter-spacing: 0.02rem;
`

const FeatureText = styled.div`
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.02rem;
`

const FeatureContent = styled.div`
  flex: 1 1 0;
  background: white;
  padding: 16px;
  border-top: 1px solid #ddd;
`

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const Image = styled.img`
  max-width: 100%;
`

export default PlanFeatures
