import { useOrg } from 'data/organization/useOrg'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'
import { GqlGetAssocToOrgByAssocIdQuery, MemberStatus, OrganizationType } from 'generated/graphql'

interface Org {
  id: string
  name: string
  type: OrganizationType
  domain: string
  logo: string
  members: any
  status: MemberStatus
  createdAt: string
}
export const useGetAssocToOrgs = () => {
  const request = useGql()
  const { association } = useOrg()

  return useQuery(
    ['org', 'assocToOrg'],
    async () => {
      const results = await request<GqlGetAssocToOrgByAssocIdQuery>(GET_ASSOC_TO_ORG, {
        associationId: association?.id,
      })

      if (!results?.associationToOrganizations) return [] as Org[]

      const assocToOrg: Org[] = results?.associationToOrganizations
        .map(({ organization, status, createdAt }) => {
          if (!organization) return
          const { id, name, type, domain, details, usersToOrganizations } = organization
          return {
            id,
            name,
            type,
            domain,
            createdAt,
            status,
            logo: details?.logo || '',
            members: usersToOrganizations ? usersToOrganizations.length : 0,
          }
        })
        .filter((assocToOrg): assocToOrg is Org => !!assocToOrg)

      return assocToOrg
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  )
}

const GET_ASSOC_TO_ORG = gql`
  query GetAssocToOrgByAssocId($associationId: UUID) {
    associationToOrganizations(condition: { associationId: $associationId }) {
      status
      createdAt
      updatedAt
      organization {
        id
        name
        type
        domain
        slug
        details
        usersToOrganizations {
          userId
          status
        }
      }
    }
  }
`
