import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/hooks'
import { useLazyApi } from 'hooks/useApi'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import companyListingData from './companyListingData'

const isLocalDev = process.env.NODE_ENV === 'development'
const successDomain = isLocalDev ? 'https://theorem.local:3100' : 'https://app.theoremlegal.com'

export const useCompanyListingCheckout = () => {
  const [getStripeSession] = useLazyApi('stripe/connect/company-checkout-url')
  const { paymentStatus } = useViewer()
  const { organization } = useOrg()
  const { slug } = useParams()

  const isTheoremLvnDiscount = paymentStatus === 'ACTIVE'

  const getCheckout = async ({
    priceId,
    listingType,
    coupon,
    trialPeriodDays,
    isClocDiscount = false,
  }: {
    priceId: string
    listingType: string
    coupon?: string
    trialPeriodDays?: number
    isClocDiscount?: boolean
  }) => {
    const companyListing = companyListingData.find((listing) => slug === listing.slug)

    if (!companyListing) return

    const { accountId, associationId } = companyListing

    const { url } = await getStripeSession({
      priceId,
      accountId,
      organizationId: organization?.id,
      associationId,
      listingType,
      trialPeriodDays,
      coupon,
      isTheoremLvnDiscount,
      isClocDiscount,
      successUrl: `${successDomain}/${organization?.type.toLowerCase()}/${
        organization?.id
      }/listing-stepper/success-company/${slug}?session_id={CHECKOUT_SESSION_ID}`,
    })

    if (!url) {
      toast.error("We'er sorry, there was an issue. Please contact support.")
      return
    }

    window.location.replace(url)
  }

  return getCheckout
}
