// import Top from 'components/Top'
import { breakpoint } from 'services/theming'
// import { SliderMenu } from 'components/SliderMenu'
import styled from '@emotion/styled'
import TopBar from 'components/global/TopBar'
import { Outlet } from 'react-router'

type Props = {
  title?: string
}

const RootContent = ({ title = '' }: Props) => (
  <Root>
    <TopBar title={title} />
    <Wrapper>
      <Main className="main-container-wrap">
        <Outlet />
      </Main>
    </Wrapper>
  </Root>
)

const Wrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${breakpoint.mobile} {
    max-width: 100vw;
  }
`

const Main = styled.main`
  width: 100%;
  padding: 40px 30px 60px;
  background: #f5f7f6;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    outline: 1px solid #ccc;
  }

  ${breakpoint.mobile} {
    height: auto;
    min-height: calc(100vh - 55px);
    overflow-y: auto;
  }
`

// const LeftColumn = styled.aside`
//   max-width: 270px !important;
//   width: 100%;
//   height: 100vh;
//   overflow-y: auto;
//   background: white;
//   flex: 0 0 270px;

//   &::-webkit-scrollbar {
//     width: 8px;
//   }

//   &::-webkit-scrollbar-track {
//     box-shadow: none;
//     background: transparent;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: #ccc;
//     outline: 1px solid #ccc;
//   }

//   ${breakpoint.mobile} {
//     display: none;
//   }
// `

// const LeftContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   align-items: center;
//   border-right: 2px solid rgba(34, 36, 38, 0.15);
// `

const Root = styled.div`
  height: 100%;
  min-height: 100vh;
  padding-top: ${({ theme }) => theme.topBarHeight};
  display: flex;
  background-color: #f5f5f5;
`
export default RootContent
