import SalesModal from './SalesModal'
import { useNavigate } from 'react-router-dom'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useTestDrive } from 'data/hooks'

interface Props {
  price: string
  priceId: string
  stripeProductId: string
  isShowing: boolean
  toggleModal: () => void
  features: string[]
}

function TermScoutSalesModal({
  isShowing,
  toggleModal,
  priceId,
  stripeProductId,
  price,
  features,
}: Props) {
  const baseOrgUrl = useBaseOrgUrl()
  const { testDrive, isLoading: testDriveLoading } = useTestDrive(18)
  const navigate = useNavigate()

  const onClick = () => {
    navigate(`${baseOrgUrl}/term-scout/plan-details`, {
      state: {
        productId: testDrive?.product.id,
        stripeProductId: stripeProductId || '',
        priceId: priceId || '',
      },
    })
  }

  if (testDriveLoading) return <div>loading</div>

  const product = testDrive?.product

  if (!product) return <div>loading</div>

  const highlights = [
    {
      name: 'Description',
      description: product.excerpt,
    },
  ]

  if (product.bestFor && product.bestFor.length > 0) {
    highlights.push({
      name: 'Best For',
      description: product.bestFor?.join(', ') || '',
    })
  }

  if (product.practiceGroups && product.practiceGroups.length > 0) {
    highlights.push({
      name: 'Practice Group',
      description: product.practiceGroups?.join(', ') || '',
    })
  }

  return (
    <SalesModal
      isShowing={isShowing}
      toggleModal={toggleModal}
      title={`Purchase ${product.name}`}
      subtitle={`Price from ${price}`}
      iconImage="https://res.cloudinary.com/dh1giwabb/image/upload/v1612299991/v3khmvrt5cjtzw5gexzk.jpg"
      videoUrl={product.details.demoVideo || ''}
      imageUrl={product.details.slides?.[0] || ''}
      features={{
        title: `Everything you get with your purchase`,
        items: features || [],
      }}
      noteText=""
      highlights={highlights}
      buttonText="Purchase"
      onClick={onClick}
    />
  )
}

export default TermScoutSalesModal
