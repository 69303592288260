import EditTeam from 'components/settings/Team'
import ProfileUserForm from 'views/lawfirms/Profile/ProfileUserForm'
import ProfileAssociations from 'views/lawfirms/Profile/ProfileAssociations'
import ProfileLawfirmForm from 'views/lawfirms/Profile/ProfileLawfirmForm'
import ProfileLocationForm from 'views/lawfirms/Profile/ProfileLocationForm'
import ProfileBillingForm from 'views/lawfirms/Profile/ProfileBillingForm'
import ConnectDetails from 'views/lawfirms/Profile/ConnectDetails'

import { MenuRoute } from './settingsRoutes'
import { UserRole } from 'generated/graphql'

const lawfirmSettingRoutes: MenuRoute[] = [
  {
    index: true,
    text: 'User',
    element: <ProfileUserForm />,
  },
  {
    path: 'company',
    text: 'Company',
    element: <ProfileLawfirmForm />,
    roleAccess: [UserRole.Owner, UserRole.Admin, UserRole.SuperAdmin],
  },
  {
    path: 'location',
    text: 'Location',
    element: <ProfileLocationForm />,
    roleAccess: [UserRole.Owner, UserRole.Admin, UserRole.SuperAdmin],
  },
  {
    path: 'billing',
    text: 'Billing',
    element: <ProfileBillingForm />,
    roleAccess: [UserRole.Owner, UserRole.SuperAdmin],
  },
  {
    path: 'team',
    text: 'Team',
    element: <EditTeam />,
  },
  {
    path: 'associations',
    text: 'Associations',
    element: <ProfileAssociations />,
  },
  {
    path: 'connect',
    text: 'Connect',
    element: <ConnectDetails />,
    roleAccess: [UserRole.Owner, UserRole.SuperAdmin],
  },
]

export default lawfirmSettingRoutes
