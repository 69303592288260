import React from 'react'
import { markdownToDraft } from 'markdown-draft-js'
import { EditorState, convertFromRaw } from 'draft-js'
import DescriptionForm from 'components/forms/product/DescriptionForm'
import { FullPageLoading } from 'components/PulsatingLoader'
import _ from 'lodash'
import { useProductSettings } from '../useProductSettings'
import VendorUpgradeModal from 'components/modals/VendorUpgradeModal'

function ProductDescription() {
  const { product, loading, onComplete, showModal, toggleModal } = useProductSettings()

  const markdownBlocks = markdownToDraft(_.get(product, 'description', ''))
  const markdownState = convertFromRaw(markdownBlocks)

  const initialValues = {
    description: product ? EditorState.createWithContent(markdownState) : EditorState.createEmpty(),
  }

  if (loading) return <FullPageLoading />

  return (
    <>
      <DescriptionForm
        initialValues={initialValues}
        onComplete={onComplete}
        productId={product?.id}
        submitButtonText="Update"
      />
      <VendorUpgradeModal showModal={showModal} toggleModal={toggleModal} />
    </>
  )
}

export default ProductDescription
