import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

interface Props {
  memberCount: number
}

const TeamUpgradeCta: React.FC<Props> = ({ memberCount }) => {
  const baseOrgUrl = useBaseOrgUrl()

  return (
    <Root>
      {memberCount} of 10 members invited.{' '}
      <UpgradeLink to={`${baseOrgUrl}/upgrade`}>Upgrade for more power and control.</UpgradeLink>
    </Root>
  )
}

const Root = styled.div`
  border-radius: 6px;
  padding: 12px 20px;
  color: #f2a100;
  background: #fffede;
  margin-bottom: 20px;
  font-weight: 500;
  letter-spacing: 0.02rem;
`

const UpgradeLink = styled(Link)`
  font-weight: 600;
`

export default TeamUpgradeCta
