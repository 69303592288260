import { Lawfirm } from './types/lawfirmType'
import { parseTerms } from 'data/taxonomy/parseTerms'
import { GqlCurrentOrgFragment, OrganizationType } from 'generated/graphql'
import { parseMembers } from './parseMembers'
import { parseVendorProductsData } from './parseVendorProducts'

export const parseLawfirm = (orgData: GqlCurrentOrgFragment): Lawfirm => {
  const {
    id,
    name,
    slug,
    createdAt,
    updatedAt,
    locations,
    details,
    useCases,
    testMode,
    termToOrganizations,
    usersToOrganizations,
    appMetadata,
    mainTermId,
  } = orgData

  return {
    id,
    name,
    slug,
    type: OrganizationType.Lawfirm,
    createdAt,
    updatedAt,
    locations,
    details,
    useCases,
    testMode,
    products: parseVendorProductsData(orgData, id),
    terms: parseTerms(termToOrganizations),
    members: parseMembers(usersToOrganizations),
    appMetadata,
    mainTermId,
  }
}
