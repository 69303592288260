import React from 'react'
import styled from '@emotion/styled'
import UserForm, { initialValues } from 'components/forms/personal/UserForm'
import { BoxWrapper } from 'components/theme'
import { toast } from 'react-toastify'
import { useViewer } from 'context/viewerContext'

type User = {
  first: string
  last: string
  details: any
}

const loadInitialValues = (user: User) => {
  const { first, last, details } = user

  return {
    ...initialValues,
    firstName: first || '',
    lastName: last || '',
    fileUrl: details.imageUrl,
  }
}

const UpdatePersonalUser: React.FC = () => {
  const { viewer } = useViewer()

  const defaultValues = loadInitialValues(viewer)

  const onSubmit = async () => {
    toast.success('User is updated')
  }

  return (
    <Root>
      <BoxWrapper>
        <Title>Update Your account</Title>
        <FormContainer>
          <UserForm
            defaultValues={{ ...defaultValues }}
            onSubmit={onSubmit}
            submitButtonText="Update"
          />
        </FormContainer>
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
`

const Title = styled.h2`
  margin-bottom: 32px;
`

const FormContainer = styled.div`
  max-width: 760px;
`

export default UpdatePersonalUser
