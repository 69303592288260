import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { TextInput, FormField } from 'components/theme/form'
import { Button } from 'components/theme'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import PdfUploader from 'components/fields/PdfUploader'
import { extractPictureUrl } from 'services/image'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useUploadPdf } from 'hooks/utility/useUploadPdf'
import { useOrg } from 'data/organization/useOrg'
import { useUpdateOrg } from 'data/hooks'
import * as yup from 'yup'
import _ from 'lodash'

const validationSchema = yup.object().shape({
  overlayWidth: yup.string().test('is-number', 'The overlay width must be a number', (value) => {
    const justNumbers = /^\d+$/
    return value ? justNumbers.test(value) : true
  }),
})

interface Values {
  bannerOverlay?: any
  overlayWidth?: string
  bannerImage: any
  bannerText: string
  termsPdf: any
}

interface Props {
  initialValues: Values
  onComplete: () => void
}

const BrandingForm: React.FC<Props> = ({ initialValues, onComplete }) => {
  const { association } = useOrg()
  const [bannerTextIsText, setBannerTextIsText] = useState<boolean>(!initialValues.bannerText)
  const updateOrg = useUpdateOrg()
  const uploadPdf = useUploadPdf()

  const onSubmit = async ({
    bannerOverlay,
    bannerImage,
    bannerText,
    overlayWidth,
    termsPdf,
  }: Values) => {
    if (!association) return

    const bannerOverlayUrl = await extractPictureUrl(bannerOverlay)
    const bannerImageUrl = await extractPictureUrl(bannerImage)

    let newTermsPdf = null

    if (termsPdf && !_.has(termsPdf, 'url')) {
      newTermsPdf = await uploadPdf(termsPdf)
    } else if (termsPdf && _.has(termsPdf, 'url')) {
      newTermsPdf = termsPdf
    }

    await updateOrg.mutateAsync({
      details: {
        ...association.details,
        termsPdf: newTermsPdf,
        bannerImage: bannerImageUrl,
        bannerOverlay: bannerOverlayUrl,
        bannerText,
        overlayWidth,
      },
    })

    onComplete()
  }

  return (
    <Formik
      initialValues={{ ...initialValues }}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, isValid, setFieldValue, errors }) => (
        <Form>
          <FormField>
            <PdfUploader name="termsPdf" />
          </FormField>
          <FormField>
            <UploadSingleAsset
              label="Ecosystem Banner"
              name="bannerImage"
              selectedImage={values.bannerImage}
              imageStyles={css`
                width: auto;
                display: flex;
                justify-content: center;
              `}
              styles={css`
                max-width: none;
              `}
            >
              Add Ecosystem Banner
            </UploadSingleAsset>
          </FormField>

          <Label>
            <label htmlFor="textOrImage">
              <input
                type="checkbox"
                checked={bannerTextIsText}
                onChange={() => {
                  bannerTextIsText
                    ? setFieldValue('bannerText', '')
                    : setFieldValue('bannerOverlay', undefined)
                  setBannerTextIsText((prev) => !prev)
                }}
                id="textOrImage"
              />
              &nbsp; Use plain-text as an overlay for the ecosystem banner instead of an image
            </label>
          </Label>
          {bannerTextIsText ? (
            <FormField>
              <TextInput
                label="Ecosystem Banner Text"
                name="bannerText"
                placeholder={`${association?.name || 'Association'} Store`}
              />
            </FormField>
          ) : (
            <>
              <FormField>
                <UploadSingleAsset
                  label="Ecosystem Banner Overlay"
                  name="bannerOverlay"
                  selectedImage={values.bannerOverlay}
                  styles={css`
                    max-width: 240px;
                  `}
                >
                  <BannerOverlayText>Add Ecosystem Banner Overlay</BannerOverlayText>
                </UploadSingleAsset>
              </FormField>
              <FormField>
                <NumberWrapper>
                  <TextInput
                    type="number"
                    label="Ecosystem Banner Overlay Width"
                    name="overlayWidth"
                    placeholder={'450'}
                  />
                </NumberWrapper>
                <Error>{errors.overlayWidth && <>{errors.overlayWidth}</>}</Error>
              </FormField>
            </>
          )}

          <ButtonContainer>
            <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
              Update
            </Button>
          </ButtonContainer>
        </Form>
      )}
    </Formik>
  )
}

const Label = styled.div`
  font-weight: 500;
  margin-bottom: 8px;
`

const Error = styled.div`
  height: 14px;
  margin-top: 4px;
  margin-bottom: -10px;
  color: #f44336;
`

const BannerOverlayText = styled.div`
  white-space: nowrap;
  padding: 0 8px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

const NumberWrapper = styled.div`
  max-width: 200px;
`

export default BrandingForm
