import React, { useState, useContext, ReactNode } from 'react'

export interface WizardStep {
  title: string
  subtitle?: string
  component: ReactNode | ReactNode[]
  initialValues?: any
  isDone?: boolean
}

export interface Data {
  [key: string]: any
}

interface WizardModalContextProps {
  stepIndex: number
  goNextStep: () => void
  goMajorNextStep: () => void
  goPrevStep: () => void
  goToStep: (stepIndex: number) => void
  step: ReactNode
  data: Data
  addData: (data: Data) => void
  closeModal: () => void
}

const WizardModalContext = React.createContext<WizardModalContextProps | undefined>(undefined)

export const WizardModalProvider = ({
  children,
  steps,
  currentStepIndex = 0,
  currentSubStepIndex = 0,
  initialData = {},
  closeModal,
}: {
  children: React.ReactNode
  steps: WizardStep[]
  currentStepIndex?: number
  currentSubStepIndex?: number
  initialData?: Data
  closeModal: () => void
}) => {
  const [stepIndex, setStepIndex] = useState(currentStepIndex)
  const [subStepIndex, setSubStepIndex] = useState(currentSubStepIndex)
  const [data, setData] = useState<Data>(initialData)

  const step = steps[stepIndex]

  const goNextStep = () => {
    if (step.component instanceof Array) {
      if (subStepIndex === step.component.length - 1) {
        // if last substep
        setStepIndex((prev) => {
          if (prev === steps.length - 1) return prev
          setSubStepIndex(0)
          return prev + 1
        })
      } else {
        // increase substep
        setSubStepIndex((prev) => {
          return prev + 1
        })
      }
    } else {
      setStepIndex((prev) => prev + 1)
      setSubStepIndex(0)
    }
  }

  const goMajorNextStep = () => {
    setStepIndex((prev) => prev + 1)
    setSubStepIndex(0)
  }

  const goPrevStep = () => {
    if (step.component instanceof Array) {
      if (subStepIndex === 0) {
        setStepIndex((prev) => {
          if (prev === 0) return 0
          return prev - 1
        })
      } else {
        setSubStepIndex((prev) => prev - 1)
      }
    } else {
      setStepIndex((prev) => {
        if (prev === 0) return 0
        return prev - 1
      })
    }
  }

  const goToStep = (stepIndex: number) => {
    setStepIndex(stepIndex)
    setSubStepIndex(0)
  }

  const addData = (addData: Data) => {
    setData((prev) => ({ ...prev, ...addData }))
  }

  const stepComponent =
    step.component instanceof Array ? step.component[subStepIndex] : step.component

  return (
    <WizardModalContext.Provider
      value={{
        data,
        addData,
        stepIndex,
        goNextStep,
        goMajorNextStep,
        goPrevStep,
        goToStep,
        step: stepComponent,
        closeModal,
      }}
    >
      {children}
    </WizardModalContext.Provider>
  )
}

export const useWizardModal = () => {
  const context = useContext(WizardModalContext)

  if (context === undefined) {
    throw new Error('useWizardContext must be used within a WizardProvider')
  }
  return context
}
