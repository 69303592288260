import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/hooks'
import { useLazyApi } from 'hooks/useApi'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import companyListingData from './companyListingData'

const isLocalDev = process.env.NODE_ENV === 'development'
const successDomain = isLocalDev ? 'https://theorem.local:3100' : 'https://app.theoremlegal.com'

export const useProductListingCheckout = () => {
  const [getStripeSession] = useLazyApi('stripe/connect/product-checkout-url')
  const { paymentStatus } = useViewer()
  const { organization } = useOrg()
  const { slug } = useParams()

  const isTheoremLvnDiscount = paymentStatus === 'ACTIVE'

  const getCheckout = async ({
    coupon,
    trialPeriodDays,
    productId,
    priceId,
    listingType,
    isClocDiscount = false,
    slug: listingSlug,
  }: {
    productId: number
    priceId: string
    listingType: string
    coupon?: string
    trialPeriodDays?: number
    isClocDiscount?: boolean
    slug?: string
  }) => {
    const currentSlug = listingSlug || slug
    console.log(companyListingData)
    const companyListing = companyListingData.find((listing) => currentSlug === listing.slug)

    console.log(companyListing)
    if (!companyListing) return

    const { accountId, associationId } = companyListing

    console.log(accountId)
    const { url } = await getStripeSession({
      accountId,
      productId,
      priceId,
      organizationId: organization?.id,
      associationId,
      trialPeriodDays,
      isTheoremLvnDiscount,
      listingType,
      isClocDiscount,
      successUrl: `${successDomain}/${organization?.type.toLowerCase()}/${
        organization?.id
      }/listing-stepper/success-product/${currentSlug}?session_id={CHECKOUT_SESSION_ID}`,
      coupon,
    })

    console.log('url', url)

    if (!url) {
      toast.error("We'er sorry, there was an issue. Please contact support.")
      return
    }

    window.location.replace(url)
  }

  return getCheckout
}
