import { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useField } from 'formik'
import Label from 'components/theme/form/Label'
import styled from '@emotion/styled'
import { Popup, Icon } from 'semantic-ui-react'
import Modal from 'components/Modal'
import Button from 'components/theme/Button'
import CreditCardBro from './credit-card-bro.svg'
import { useRedirect } from 'hooks/useRedirect'
import { useViewer } from 'context/viewerContext'

function ButtonTypeDropdown({ stripeId = '', helpContent = '', ...props }: any) {
  const [, meta, helpers] = useField(props)
  const [options, setOptions] = useState(defaultOptions)
  const [showModal, setShowModal] = useState(false)
  const { subscription, stripeReady } = useViewer()
  const redirect = useRedirect()

  useEffect(() => {
    if (!stripeReady && !subscription) helpers.setValue('none')
  }, [])

  useEffect(() => {
    if (!stripeId && meta.value === 'checkout') helpers.setValue('none')
    if (!stripeId) return
    helpers.setValue('checkout')
  }, [stripeId])

  useEffect(() => {
    const checkoutValue = stripeReady ? 'checkout' : 'setup-connect'

    if (subscription === 'FREE') {
      // if (no connect account) checkout === 'setup-connect'
      // if (has connect account) checkout === 'checkout'
      const newOptions = [
        { key: 'none', text: 'None', value: 'none' },
        { key: 'checkout-dropdown', text: 'Checkout', value: checkoutValue },
        { key: 'custom', text: 'Custom', value: 'upgrade', icon: 'star' },
        { key: 'contact', text: 'Contact Us', value: 'upgrade', icon: 'star' },
        { key: 'demo', text: 'Request Demo', value: 'upgrade', icon: 'star' },
      ]
      setOptions([...defaultOptions, ...newOptions])
    } else if (subscription) {
      const newOptions = [
        { key: 'none', text: 'None', value: 'none' },
        { key: 'checkout-dropdown', text: 'Checkout', value: checkoutValue },
        { key: 'custom', text: 'Custom', value: 'custom' },
        { key: 'demo', text: 'Request Demo', value: 'demo' },
        { key: 'contact', text: 'Contact Us', value: 'contact' },
      ]
      setOptions([...defaultOptions, ...newOptions])
    } else if (stripeId && !options.some((option) => option.value === 'checkout')) {
      const newOptions = [
        { key: 'custom', text: 'Custom', value: 'custom' },
        { key: 'checkout-dropdown', text: 'Checkout', value: 'checkout' },
      ]
      setOptions([...defaultOptions, newOptions])
      helpers.setValue('checkout')
    } else if (!stripeId) {
      const newOptions = [{ key: 'custom', text: 'Custom', value: 'custom' }]
      setOptions([...defaultOptions, ...newOptions])
      helpers.setValue('demo')
    }
  }, [stripeId])

  const onDropdownChange = (_e: any, { value }: any) => {
    if (value === 'upgrade') {
      redirect('upgrade')
    }

    if (value === 'setup-connect') {
      setShowModal(true)
      return
    }
    helpers.setValue(value)
  }

  return (
    <Root>
      <Label>
        Action Button
        {helpContent && (
          <Popup
            inverted
            styles={{
              background: '#333',
            }}
            trigger={
              <div>
                <HelpIcon name="info circle" />
              </div>
            }
          >
            <Popup.Content>{helpContent}</Popup.Content>
          </Popup>
        )}
      </Label>
      <Dropdown
        selection
        fluid
        style={{ minWidth: 190 }}
        name="buttonType"
        onChange={onDropdownChange}
        value={meta.value}
        options={options}
      />
      <Modal isShowing={showModal} toggleModal={() => setShowModal(false)}>
        <ModalBody>
          <h3>Finish Payment Setup</h3>
          <h4>Connect your bank account to accept payments</h4>
          <img src={CreditCardBro} alt="A cartoonish person leaning on a giant credit card" />
          <ModalNav>
            <FinishButton onClick={() => redirect('upgrade/setup/1')}>Finish Setup</FinishButton>{' '}
            <a href="https://share.hsforms.com/1mi8ic8YSRxGWt-8MP4E0PA3p9i3" target="_blank">
              Learn How
            </a>
          </ModalNav>
        </ModalBody>
      </Modal>
    </Root>
  )
}

const defaultOptions: any[] = [
  // { key: 'demo', text: 'Request Demo', value: 'demo' },
  // { key: 'contact', text: 'Contact Us', value: 'contact' },
]

const Root = styled.div`
  padding-right: 16px;
  position: relative;
  min-width: 140px;
  z-index: 1000;

  .star {
    color: #ffd700;
  }
`

const HelpIcon = styled(Icon)`
  color: #00a2ff;
  margin-left: 8px !important;
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FinishButton = styled(Button)`
  background-color: #498de6;
  color: white;
  margin-right: 32px;

  &:hover {
    color: #498de6;
    background-color: white;
  }
`

const ModalNav = styled.div``

export default ButtonTypeDropdown
