// dependencies
import React from 'react'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import * as yup from 'yup'
// components
import { FormField } from 'components/theme/form'
import { DispatchInput } from '.'
import Label from 'components/theme/form/Label'
// definitions
import { FormProps } from '.'

const RfpStart: React.FC<FormProps> = ({
  FormStepperLinks,
  updateStepper,
  onSubmit,
  initialValues,
}) => {
  const validationSchema = yup.object().shape({
    title: yup.string().max(50),
    companyDescription: yup.string().max(100),
  })

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ errors, touched }) => (
        <Form>
          <FormField>
            <Label children={'Title'} />
            <DispatchInput
              name="title"
              placeholder="Give your RFP a title"
              updateStepper={updateStepper}
            />
            {errors.title && touched.title && <ErrorText>{errors.title}</ErrorText>}
          </FormField>
          <FormField>
            <Label
              children={'Company Description'}
              helpContent={
                <>
                  This company description is displayed on our platform and may be shared with
                  prospective vendors.
                  <br />
                  <br />
                  We use this to keep RFPs anonymous and protect your company's identity during the
                  RFP process.
                </>
              }
            />
            <DispatchInput
              name="companyDescription"
              placeholder='e.g. "Am law 200 firm, software company, consultancy (limit: 100 characters)"'
              updateStepper={updateStepper}
              area
            />
          </FormField>
          {errors.companyDescription && touched.companyDescription && (
            <ErrorText>{errors.companyDescription}</ErrorText>
          )}
          <FormStepperLinks />
        </Form>
      )}
    </Formik>
  )
}

const ErrorText = styled.div`
  color: #f44336;
  font-weight: 400;
  margin-top: 6px;
`

export default RfpStart
