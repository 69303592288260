import { gql } from 'graphql-request'

export const PRODUCT_CORE_FRAGMENT = gql`
  fragment ProductCore on Product {
    nodeId
    id
    name
    slug
    details
    state
    show
    excerpt
    updatedAt
    createdAt
    testMode
    type
  }
`

export const VIEWER_PRODUCT_FRAGMENT = gql`
  fragment ViewerProduct on Product {
    ...ProductCore
    description
    productToIntegrations {
      integration {
        ...ProductCore
      }
    }
    featureToProducts {
      feature {
        id
        name
        slug
        nodeId
      }
    }
    termToProducts {
      term {
        ...TermCore
        taxonomy {
          nodeId
          id
          name
          slug
        }
      }
    }
    productToClients {
      client {
        id
        name
      }
    }
    productToCompetitors {
      locked
      competitor {
        ...ProductCore
      }
    }
  }
`
