import styled from '@emotion/styled'
import { useField } from 'formik'

function Radio({ label = '', ...props }: any) {
  const [field, meta] = useField({ ...props })

  return (
    <Label>
      <Input type="radio" {...field} {...props} checked={props.value === meta.value} /> {label}
    </Label>
  )
}

const Input = styled.input`
  margin-right: 8px;
`

const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export default Radio
