import EditTeam from 'components/settings/Team'
import EditVendorUser from 'components/vendors/EditVendorUser'
import EditVendor from 'components/vendors/EditVendor'
import EditProducts from 'components/vendors/EditProducts'
import EditLocations from 'components/settings/Locations'
import DiversityCert from 'components/settings/DiversityCert'
import { MenuRoute } from './settingsRoutes'

const vendorSettingRoutes: MenuRoute[] = [
  {
    index: true,
    text: 'User',
    element: <EditVendorUser />,
  },
  {
    path: 'company',
    text: 'Company',
    element: <EditVendor />,
  },
  {
    path: 'team',
    text: 'Team',
    element: <EditTeam />,
  },
  {
    path: 'product',
    text: 'Products',
    element: <EditProducts />,
  },
  {
    path: 'location',
    text: 'Locations',
    element: <EditLocations />,
  },
  {
    path: 'certifications',
    text: 'Diversity Certifications',
    element: <DiversityCert />,
  },
]

export default vendorSettingRoutes
