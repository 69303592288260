import React from 'react'
import { Formik, FieldArray, Form } from 'formik'
import { TextInput, FormField } from 'components/theme/form'
import Button from 'components/theme/Button'
import * as yup from 'yup'
import styled from '@emotion/styled'
import { UnderlineTitle } from 'components/theme'
import PromptIfDirty from 'components/forms/PromptIfDirty'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Product } from 'data/types'
import { useMutateProductDetails } from 'data/hooks'

const defaultValues = {
  features: [],
}

const validationSchema = yup.object().shape({})

type Props = {
  initialValues: any
  product?: Product
  onComplete: (productId: number | undefined) => void
}

interface Variables {
  features: any
}

function FeaturesForm({ initialValues = defaultValues, product, onComplete }: Props) {
  const queryClient = useQueryClient()
  const mutateProductDetails = useMutateProductDetails()
  const updateFeatures = useMutation(
    async ({ features }: Variables) => {
      if (!product) return

      await mutateProductDetails.mutateAsync({
        productId: product.id,
        details: {
          ...product.details,
          features,
        },
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )

  const onSubmit = async ({ features }: any) => {
    updateFeatures.mutate(
      {
        features,
      },
      {
        onSuccess: () => onComplete(product?.id),
      }
    )
  }

  return (
    <Root>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ values, isSubmitting, isValid }) => (
          <Form>
            <PromptIfDirty />
            <FormField>
              <FieldArray
                name="features"
                render={(arrayHelpers) => (
                  <Root>
                    <UnderlineTitle helpContent="Add core features to display in our side-by-side comparison engine">
                      Features
                    </UnderlineTitle>
                    {values.features && (
                      <>
                        {values.features.map((_features: string, index: number) => (
                          <Resource key={`${_features}-${index}`}>
                            <ResourceText>
                              <TextInput
                                label="Text"
                                placeholder="Feature Name"
                                name={`features[${index}]`}
                              />
                            </ResourceText>
                            <ResourceButton>
                              <RemoveButton
                                type="button"
                                onClick={() => {
                                  arrayHelpers.remove(index)
                                }}
                              >
                                -
                              </RemoveButton>
                            </ResourceButton>
                          </Resource>
                        ))}
                      </>
                    )}
                    {values.features.length < 7 && (
                      <AddButton type="button" onClick={() => arrayHelpers.push('')}>
                        + Add Feature
                      </AddButton>
                    )}
                  </Root>
                )}
              />
            </FormField>
            <FormField>
              <ButtonContainer>
                <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                  Update
                </Button>
              </ButtonContainer>
            </FormField>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const RemoveButton = styled(Button)`
  color: #f44336;
  border-color: #f44336;

  &:hover {
    border-color: #f44336;
    background-color: #f44336;
  }
`

const Root = styled.div`
  width: 100%;
  max-width: 760px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

const Resource = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 12px;
`

const ResourceText = styled.div`
  flex: 1 1 0px;
  margin-right: 15px;
`

const ResourceButton = styled.div`
  flex: 0 0 auto;
  text-align: right;
`

const AddButton = styled(Button)`
  padding: 4px 16px;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  margin-top: 24px;
`

export default FeaturesForm
