import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { useLazyApi } from 'hooks/useApi'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useEffect } from 'react'

function VendorCheckout() {
  const location = useLocation()
  const [getStripeSession] = useLazyApi('stripe/organization/vendor-checkout')

  useEffect(() => {
    ;(async () => {
      const locationState = location.state as any
      const { url } = await getStripeSession({ priceId: locationState.priceId })
      window.location.replace(url)
    })()
  }, [])

  return (
    <Root>
      <FullPageLoading />
    </Root>
  )
}

const Root = styled.div``

export default VendorCheckout
