import { useQuery } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlGetCertificationsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useDiversityCerts = () => {
  const request = useGql()
  const { organization } = useOrg()

  const { data, ...other } = useQuery(['diversityCert'], async () => {
    const response = await request<GqlGetCertificationsQuery>(GET_CERTIFICATIONS, {
      orgId: organization?.id,
    })

    return response?.diversityCertifications
  })

  return { diversityCert: data, ...other }
}
const GET_CERTIFICATIONS = gql`
  query GetCertifications($orgId: UUID!) {
    diversityCertifications(condition: { organizationId: $orgId }) {
      name
      provider
      description
    }
  }
`
