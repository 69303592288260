import styled from '@emotion/styled'
import Modal from 'components/Modal'
import { Button } from 'components/theme'
import CardImage from 'assets/marketing/credit-card-rafiki.png'
import { useRedirect } from 'hooks/useRedirect'

interface Props {
  showModal: boolean
  toggleModal: () => void
  continueLink?: string
}

const VendorUpgradeModal = ({ showModal, toggleModal, continueLink }: Props) => {
  const redirect = useRedirect()

  showModal = false

  return (
    <Modal isShowing={showModal} toggleModal={toggleModal} width="650px">
      <Root>
        <Title> Your Account is set to Expire</Title>
        <Content>Keep your listing and get more out of Thoerem</Content>
        <Image src={CardImage} />
        <div>
          <Button onClick={() => redirect('upgrade')}>Upgrade Today</Button>
        </div>
        {continueLink && (
          <ContinueLink href={continueLink} target="_blank">
            Continue
          </ContinueLink>
        )}
      </Root>
    </Modal>
  )
}

const Image = styled.img`
  width: auto;
  max-height: 330px;
`

const Root = styled.div`
  text-align: center;
`

const Title = styled.h3`
  font-size: 28px;
  margin-bottom: 4px;
`

const Content = styled.p`
  font-size: 18px;
  margin-bottom: 0;
`

const ContinueLink = styled.a`
  display: inline-block;
  margin-top: 20px;
`

export default VendorUpgradeModal
