import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import Modal from 'components/Modal'
import clocBlueSvg from 'assets/cloc/cloc-blue.svg'
import clocGoldSvg from 'assets/cloc/cloc-gold.svg'
import clocBlackSvg from 'assets/cloc/cloc-black.svg'
import { useRedirect } from 'hooks/useRedirect'
import { useState } from 'react'
import { useOrg, useNoviOrganization } from 'data/hooks'
import { Dropdown } from 'semantic-ui-react'
import { Button } from 'components/theme'
import { FormField } from 'components/theme/form'
import { isUsingProductionServer } from 'services/helper'
import { format } from 'date-fns'
import { useLazyApi } from 'hooks/useApi'
import { useViewer } from 'context/viewerContext'
import { Link } from 'react-router-dom'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

interface Props {
  isShow: boolean
  toggleModal: () => void
}

// const networkPriceIds = [
//   {
//     network: 'cloc',
//     priceIds: [
//       {
//         name: 'essential',
//         production: 'price_1KeQl2CldCjodC13wMzwDACb',
//         development: 'price_1KjrmCHIw3FF5Jrc2CWeynkm',
//       },
//       {
//         name: 'premium',
//         production: 'price_1KeQmDCldCjodC13DePzyHad',
//         development: 'price_1KjrpbHIw3FF5JrcDx0lnY5j',
//       },
//       {
//         name: 'enterprise',
//         production: 'price_1L5YqMCldCjodC13FXwF40GA',
//         development: 'price_1L5YFiHIw3FF5JrcjqGZpMPr',
//       },
//     ],
//   },
// ]

const BASIC_PRICE_ID = isUsingProductionServer
  ? 'price_1KeQl2CldCjodC13wMzwDACb'
  : 'price_1KjrmCHIw3FF5Jrc2CWeynkm'

const PREMIUM_PRICE_ID = isUsingProductionServer
  ? 'price_1KeQmDCldCjodC13DePzyHad'
  : 'price_1KjrpbHIw3FF5JrcDx0lnY5j'

const ENTERPRISE_PRICE_ID = isUsingProductionServer
  ? 'price_1L5YqMCldCjodC13FXwF40GA'
  : 'price_1L5YFiHIw3FF5JrcjqGZpMPr'

const ASSOCIATION_ID = '5a172a54-130e-4cf6-8416-625f73950d98'
const ACCOUNT_ID = isUsingProductionServer ? 'acct_1BtTBjCldCjodC13' : 'acct_1Ii7RTHIw3FF5Jrc' // TheoremLTS test account

const ClocModal = ({ isShow, toggleModal }: Props) => {
  const redirect = useRedirect()
  const { vendor, lawfirm, organization } = useOrg()
  const { noviOrganization } = useNoviOrganization()
  const [priceId, setPriceId] = useState<string | undefined>()
  const [clocListing, setClocListing] = useState<any>(organization?.appMetadata?.clocListing)
  const [freeTrial, setFreeTrial] = useState<boolean>(
    organization?.appMetadata?.hasClocTrial || false
  )
  const products = vendor ? vendor.products : lawfirm?.products
  const [getHasClocMembership] = useLazyApi('company/has-cloc-membership')
  const [hasMembership, setHasMembership] = useState(false)
  const { paymentStatus } = useViewer()
  const baseOrgUrl = useBaseOrgUrl()

  const [productId, setProductId] = useState<number | undefined>(products && products[0].id)

  const isDiscount = noviOrganization?.status === 'CURRENT' || hasMembership
  const isActiveTheorem = paymentStatus === 'ACTIVE'
  // const isDiscount = true
  // const isActiveTheorem = true

  useEffect(() => {
    ;(async () => {
      const hasClocMembership = await getHasClocMembership()
      setHasMembership(hasClocMembership)
    })()
  }, [])

  const onBoxClick = (priceId: string) => () => {
    setPriceId(priceId)
  }

  const selectProduct = () => {
    const listingType = priceId === BASIC_PRICE_ID ? 'essential' : 'premium'

    const isTheoremLvnDiscount = isActiveTheorem

    redirect('product-listing/checkout', {
      priceId,
      productId,
      listingType,
      isTheoremLvnDiscount,
      isClocDiscount: isDiscount,
      associationId: ASSOCIATION_ID,
      accountId: ACCOUNT_ID,
    })
  }

  const closeModal = () => {
    setPriceId(undefined)
    toggleModal()
  }

  const goToCheckout = (priceId: string) => () => {
    const listingType = 'enterprise'

    redirect('product-listing/checkout', {
      priceId,
      listingType,
      isTheoremLvnDiscount: isActiveTheorem,
      associationId: ASSOCIATION_ID,
      accountId: ACCOUNT_ID,
    })
  }

  return (
    <Root>
      <Modal isShowing={isShow} toggleModal={closeModal} width="900px">
        {clocListing && (
          <div>
            <TextCenter>
              <ClocLogo src={clocBlueSvg} />
            </TextCenter>
            <ClocListing>
              We’ve Identified an active listing on <strong>CLOC’s Directory</strong>. Enter your
              payment details on the next screen to complete the transfer. You will NOT be charged
              until the renewal date on File -{' '}
              <strong>{format(clocListing?.expiredDate * 1000, 'MM/dd/yyyy')}</strong> - and will be
              notified in advance of renewal
            </ClocListing>
            <TextCenter>
              <Button onClick={() => setClocListing(null)}>Continue</Button>
            </TextCenter>
          </div>
        )}
        {freeTrial && (
          <div>
            <TextCenter>
              <ClocLogo src={clocBlueSvg} />
            </TextCenter>
            <ClocListing>
              We’ve Identified you have a free trial with <strong>CLOC’s Directory</strong>. Enter
              your payment details on the next screen to complete the transfer. You will NOT be
              charged until after the 90 day free trial is over.
            </ClocListing>
            <TextCenter>
              <Button onClick={() => setFreeTrial(false)}>Continue</Button>
            </TextCenter>
          </div>
        )}
        {priceId && products && (
          <div>
            <h3>Select a product to list</h3>
            <FormField>
              <Dropdown
                options={products.map((product) => ({
                  text: product.name,
                  value: product.id,
                }))}
                value={productId}
                onChange={(_e, { value }) => {
                  if (typeof value === 'number' || value === undefined) return setProductId(value)
                }}
                fluid
                selection
              />
            </FormField>
            <Button onClick={selectProduct}>Select</Button>
          </div>
        )}
        {!priceId && !(clocListing || freeTrial) && (
          <Container>
            <MainTitle>
              {isDiscount ? 'Select a Listing Plan' : 'Purchase Listing | CLOC MEMBERS SAVE 20%'}
            </MainTitle>
            {isDiscount ? (
              <GreenText>
                Congrats! We found an active CLOC corporate membership for this email account.
                Discount Applied.
              </GreenText>
            ) : isActiveTheorem ? (
              <>
                <NonDiscountText>
                  Discount requires active CLOC corporate membership.
                </NonDiscountText>
                <ActiveTheoremDiscount>
                  Congrats! You still Save 15% with your PRO membership.
                </ActiveTheoremDiscount>
              </>
            ) : (
              <>
                <NonDiscountText>
                  Discount requires active CLOC corporate membership.
                </NonDiscountText>
                <TheoremProDiscount>
                  Or,{' '}
                  <Link to={`${baseOrgUrl}/theorem-pro-upgrade`}>Save 15% with Theorem PRO</Link>
                </TheoremProDiscount>
              </>
            )}
            <Boxes>
              <Column>
                <Box onClick={onBoxClick(BASIC_PRICE_ID)}>
                  <Logo src={clocBlueSvg} />
                  <Title>Essentials</Title>
                  <List>
                    <Item>Listing Management Portal</Item>
                    <Item>CLOC Badge on Listing</Item>
                    <Item>Enhanced Search Placement</Item>
                    <Item>RFP Participation</Item>
                    <Item>Marketplace Insights Newsletter</Item>
                  </List>
                  <p>i.e. “Product X Is now available through the CLOC Network”</p>
                  {isDiscount ? (
                    <>
                      <PriceWrapper>
                        <DiscountPrice>$500</DiscountPrice>
                        <OldPrice>$625</OldPrice>
                      </PriceWrapper>
                      <Discount>20% Off Applied!</Discount>
                    </>
                  ) : isActiveTheorem ? (
                    <>
                      <PriceWrapper>
                        <DiscountPrice>$531.25</DiscountPrice>
                        <OldPrice>$625</OldPrice>
                      </PriceWrapper>
                      <Discount>15% Off Applied!</Discount>
                    </>
                  ) : (
                    <PriceWrapper>
                      <Price>$625</Price>
                    </PriceWrapper>
                  )}
                </Box>
              </Column>
              <Column>
                <Box onClick={onBoxClick(PREMIUM_PRICE_ID)}>
                  <Logo src={clocGoldSvg} />
                  <Title>Premium</Title>
                  <List>
                    <Item>Everything in Essential, Plus;</Item>
                    <Item>Premium Gold CLOC Badge</Item>
                    <Item>Top-of-Search Placement</Item>
                    <Item>Featured Home Page Placement</Item>
                    <Item>Highlighted Offers &amp; Test Drives in TechHub</Item>
                    <Item>Featured promotion in Theorem Outreach</Item>
                    <Item>New | Legal Ops Premium Categories</Item>
                  </List>
                  {isDiscount ? (
                    <>
                      <PriceWrapper>
                        <DiscountPrice>$2,500</DiscountPrice>
                        <OldPrice>$3,125</OldPrice>
                      </PriceWrapper>
                      <Discount>20% Off Applied!</Discount>
                    </>
                  ) : isActiveTheorem ? (
                    <>
                      <PriceWrapper>
                        <DiscountPrice>$2,656.25</DiscountPrice>
                        <OldPrice>$3,125</OldPrice>
                      </PriceWrapper>
                      <Discount>15% Off Applied!</Discount>
                    </>
                  ) : (
                    <PriceWrapper>
                      <Price>$3,125</Price>
                    </PriceWrapper>
                  )}
                </Box>
              </Column>
              <Column>
                <Box onClick={goToCheckout(ENTERPRISE_PRICE_ID)}>
                  <Logo src={clocBlackSvg} />
                  <Title>Enterprise</Title>
                  <List>
                    <Item>Everything in Premium, Plus;</Item>
                    <Item>Unlimited products</Item>
                    <Item>Priority support</Item>
                  </List>
                  {isDiscount ? (
                    <>
                      <PriceWrapper>
                        <DiscountPrice>$5,000</DiscountPrice>
                        <OldPrice>$6,250</OldPrice>
                      </PriceWrapper>
                      <Discount>20% Off Applied!</Discount>
                    </>
                  ) : isActiveTheorem ? (
                    <>
                      <PriceWrapper>
                        <DiscountPrice>$5,312.50</DiscountPrice>
                        <OldPrice>$6,250</OldPrice>
                      </PriceWrapper>
                      <Discount>15% Off Applied!</Discount>
                    </>
                  ) : (
                    <PriceWrapper>
                      <Price>$6,250</Price>
                    </PriceWrapper>
                  )}
                </Box>
              </Column>
            </Boxes>
          </Container>
        )}
      </Modal>
    </Root>
  )
}

const Root = styled.div``

const Container = styled.div``

const TheoremProDiscount = styled.p`
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 16px;
  color: #43ba1a;
  margin-top: 8px;

  a {
    color: #43ba1a;
    text-decoration: underline;

    &:hover {
      color: #333;
    }
  }
`

const ActiveTheoremDiscount = styled.div`
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 8px;
  font-size: 16px;
  color: #43ba1a;
`

const MainTitle = styled.h2`
  text-align: center;
  margin-bottom: 0px;
`

const ClocListing = styled.div`
  font-size: 18px;
  line-height: 28px;
  margin: 20px auto;
  max-width: 690px;
`

const ClocLogo = styled.img`
  max-width: 170px;
`

const TextCenter = styled.div`
  text-align: center;
`

const Boxes = styled.div`
  display: flex;
  margin-left: -16px;
  margin-right: -16px;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: block;
  }
`

const Column = styled.div`
  display: flex;
  padding: 0 16px;
  width: 50%;

  ${({ theme }) => theme.breakpoint.mobile} {
    width: 100%;
    margin: 0 auto 24px;
    max-width: 300px;
  }
`

const Logo = styled.img`
  max-width: 120px;
`

const GreenText = styled.p`
  color: #43ba1a;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.02rem;
`

const NonDiscountText = styled.p`
  text-align: center;
  color: #e16703;
  font-size: 16px;
  margin-bottom: 0;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 20px;
  cursor: pointer;
  border: transparent 2px solid;
  transition: border 250ms;

  &:hover {
    border: #4cd0d1 2px solid;
  }
`

const Title = styled.h4`
  font-size: 20px;
  margin-top: 16px;
`

const List = styled.ul`
  padding-left: 16px;
  margin-bottom: 20px;
`

const Item = styled.li`
  font-size: 14px;
  margin-bottom: 4px;
  letter-spacing: 0.02rem;
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-bottom: 4px;
`

const Price = styled.div`
  font-size: 20px;
  font-weight: 600;
`

const DiscountPrice = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-right: 12px;
`

const OldPrice = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-decoration: line-through;
  color: grey;
`

const Discount = styled.div`
  color: #43ba1a;
  font-weight: 500;
`

export default ClocModal
