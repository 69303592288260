import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { useInstantSearch, useSearchBox } from 'react-instantsearch-hooks-web'
import { useSelectTechStackContext } from './SelectTechStackContext'
import SelectedAppPill from './SelectedAppPill'
import SearchResults from './SearchResults'

function SearchBar({ withoutSelections, onSelect, withoutTechstack }: any) {
  const { state, dispatch } = withoutTechstack
    ? { state: { selectedApps: [] }, dispatch: undefined }
    : useSelectTechStackContext()
  const { refine, isSearchStalled } = useSearchBox()
  const [isOverflowing, setIsOverflowing] = useState(false)
  const { results } = useInstantSearch()
  const overflowRef = useRef<any>()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    refine(value)
    dispatch && dispatch({ type: 'ON_KEY_DOWN', payload: { searchValue: value } })
  }

  useEffect(() => {
    setIsOverflowing(overflowRef.current?.scrollWidth > overflowRef.current?.clientWidth)
  }, [JSON.stringify(state)])

  const showPills = () => {
    const { selectedApps } = state

    return [...selectedApps].map((app) => <SelectedAppPill {...app} key={`${app.id}-pill`} />)
  }

  const Arrows = () => (
    <>
      <Arrow
        onClick={(event) => {
          event.preventDefault()
          for (let i = 0; i <= 50 && i >= 0; i++) {
            overflowRef.current.scrollLeft -= 1
          }
        }}
      >
        {'<'}
      </Arrow>
      <Arrow
        onClick={(event) => {
          event.preventDefault()
          for (let i = 0; i <= 50 && i <= overflowRef.current.scrollWidth; i++) {
            overflowRef.current.scrollLeft += 1
          }
        }}
      >
        {'>'}
      </Arrow>
    </>
  )

  return (
    <Root>
      <ClickOut isBig={!!results.query} onClick={() => refine('')} />
      <Form noValidate action="" role="search">
        <BarWrapper>
          {!withoutSelections && <Pills ref={overflowRef}>{showPills()}</Pills>}
          {isOverflowing && !withoutSelections && <Arrows />}
          <Bar type="search" onChange={onChange} placeholder="Search & select apps" />
        </BarWrapper>
        {isSearchStalled ? 'My search is stalled' : ''}
        <SearchResults onSelect={onSelect} withoutTechstack={withoutTechstack} />
      </Form>
    </Root>
  )
}

const Form = styled.form`
  z-index: 2;
  position: relative;
`

const Root = styled.div`
  position: relative;
  z-index: 500;
`

const ClickOut = styled.div<{ isBig: boolean }>`
  position: fixed;
  ${({ isBig }) => (isBig ? 'top: 0; right: 0; left: 0; bottom: 0;' : '')}
  z-index: 1;
`

const Pills = styled.div`
  display: flex;
  overflow: hidden;
`

export const BarWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 4px;
  height: 48px;
  font-size: 20px;
  border-radius: 10px;
  border: 1px solid #999aa2;
  overflow: none;
`

const Arrow = styled.button`
  cursor: pointer;
  display: inline-block;
  height: 100%;
  width: 25px;
  position: relative;
  transition: background-color 400ms ease;
  background-color: white;
  border: none;
  color: #000;
  &:disabled {
    color: #aaa;
    &:hover {
      background-color: white;
    }
  }
  &:hover {
    background-color: #eee;
  }
`

const Bar = styled.input`
  border: none;
  margin-left: 8px;
  min-width: 186px;
  flex-grow: 1;
`

export default SearchBar
