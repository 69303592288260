import React from 'react'
import { Formik, Form } from 'formik'
import { FormField, TrueFalse, Label, MoneyInput, TextInput } from 'components/theme/form'
import Button from 'components/theme/Button'
import styled from '@emotion/styled'
import { FlexLoading } from 'components/PulsatingLoader'
import * as yup from 'yup'
import _ from 'lodash'
import DropdownAllowAdditions from 'components/fields/DropdownAllowAdditions'
import { Dropdown } from 'semantic-ui-react'
import { EditorState, convertToRaw } from 'draft-js'
import RichEditor from 'components/fields/RichEditor'
import { draftToMarkdown } from 'markdown-draft-js'
import { PRICE_INTERVAL } from 'model/static'
import PromptIfDirty from 'components/forms/PromptIfDirty'
import { useMutateProductDetails } from 'data/hooks'
import { usePriceTermDropdown } from './usePriceTermDropdown'
import { useClientDropdown } from './useClientDropdown'
import { useMutatePriceTerms } from './useMutatePriceTerms'
import { useMutateClients } from './useMutateClients'
import { useOrg } from 'data/organization/useOrg'

const defaultValues = {
  pricingDescription: EditorState.createEmpty(),
  planDescription: EditorState.createEmpty(),
  startingMonthlyPrice: '',
  startingPriceInterval: 'monthly',
  startingPriceIsPerUser: false,
  pricingPlans: [],
  hasFreeTrial: false,
  hasFreeVersion: false,
  hasPerUser: false,
  priceTerms: [],
  clients: [],
  ctaButtonLink: '',
  ctaButtonText: '',
}

const validationSchema = yup.object().shape({})

function SalesForm({
  initialValues = defaultValues,
  product,
  submitButtonText = 'UPDATE',
  onComplete,
  isService = false,
}: any) {
  const { organizationLoading } = useOrg()
  // const { priceTermOptions, onSubmitPriceTerms, priceTermLoading } = usePriceTermDropdown(productId)
  // const { productToClientOptions, onSubmitProductToClients, productToClientLoading } = useClientDropdown(productId)

  const { data: priceTermOptions, isLoading: priceTermLoading } = usePriceTermDropdown()
  const { data: productToClientOptions, isLoading: productToClientLoading } = useClientDropdown()

  const productId = product.id

  const mutateClients = useMutateClients(productId)
  const mutatePriceTerms = useMutatePriceTerms(productId)
  const mutateProductDetails = useMutateProductDetails()

  initialValues = {
    ...initialValues,
    priceTerms: setInitialPriceTerms(product),
    clients: setInitialClients(product),
  }

  const onSubmit = async ({
    startingPrice,
    startingPriceInterval,
    startingPriceIsPerUser,
    pricingDescription,
    priceTerms,
    planDescription,
    hasFreeTrial,
    hasFreeVersion,
    hasPerUser,
    clients,
    ctaButtonLink,
    ctaButtonText,
  }: any) => {
    if (productId) {
      try {
        const details = {
          ...product?.details,
          startingPrice: parseInt(startingPrice, 10),
          startingPriceInterval,
          startingPriceIsPerUser,
          pricingDescription: draftToMarkdown(convertToRaw(pricingDescription.getCurrentContent())),
          planDescription: draftToMarkdown(convertToRaw(planDescription.getCurrentContent())),
          hasFreeTrial,
          hasFreeVersion,
          hasPerUser,
          ctaButtonLink,
          ctaButtonText,
        }
        await mutateProductDetails.mutateAsync({
          productId,
          details,
        })
        await mutatePriceTerms.mutateAsync({
          terms: priceTerms,
          initialValue: initialValues.priceTerms,
        })
        await mutateClients.mutateAsync({ clients, initialValue: initialValues.clients })
      } catch (e) {
        console.error(e)
      }
    }

    onComplete()
  }

  if (organizationLoading || priceTermLoading || productToClientLoading) {
    return <FlexLoading />
  }

  return (
    <Root>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ values, setFieldValue, isSubmitting, isValid }) => (
          <Form>
            <PromptIfDirty />
            <FormField>
              <Label>Pricing Headline (Displays Above Pricing Plans)</Label>
              <RichEditor
                editorState={values.pricingDescription}
                onChange={setFieldValue}
                name="pricingDescription"
              />
            </FormField>
            {!isService && (
              <>
                <FormField>
                  <Label>Plan Details (Displays Below Pricing Plans)</Label>
                  <RichEditor
                    editorState={values.planDescription}
                    onChange={setFieldValue}
                    name="planDescription"
                  />
                </FormField>
                <FlexWrapper>
                  <div>
                    <MoneyInput name="startingPrice" placeholder="4000" label="Starting Price" />
                  </div>
                  <IntervalWrapper>
                    <Label>Starting Price Interval</Label>
                    <Dropdown
                      selection
                      name="startingPriceInterval"
                      placeholder="Starting Price Interval"
                      label="Starting Price Interval"
                      onChange={(_e, { value }) => setFieldValue('startingPriceInterval', value)}
                      options={PRICE_INTERVAL}
                      value={values.startingPriceInterval}
                    />
                  </IntervalWrapper>
                </FlexWrapper>
                <FormField>
                  <Label helpContent="Never shared. For anonymized reporting and matching.">
                    Clients using your app
                  </Label>
                  <Dropdown
                    fluid
                    selection
                    search
                    multiple
                    onChange={(_e, { value }) => setFieldValue('clients', value)}
                    options={productToClientOptions}
                    value={values.clients}
                    name="clients"
                    placeholder="Clients"
                  />
                </FormField>
                <FormField>
                  <Label helpContent="Leave blank for all">Price Terms</Label>
                  <DropdownAllowAdditions
                    name="priceTerms"
                    defaultValue={values.priceTerms}
                    options={priceTermOptions}
                    placeholder="Price Terms"
                    onChange={setFieldValue}
                  />
                </FormField>
                <TrueFalseWrapper>
                  <FormField>
                    <TrueFalse name="hasFreeTrial" label="Has free trial" text="" />
                  </FormField>
                  <FormField>
                    <TrueFalse name="hasFreeVersion" label="Has free version" text="" />
                  </FormField>
                  <FormField>
                    <TrueFalse name="hasPerUser" label="Has per user" text="" />
                  </FormField>
                </TrueFalseWrapper>
              </>
            )}
            {isService && (
              <>
                <FormField>
                  <TextInput
                    name="ctaButtonLink"
                    label="CTA Button Link"
                    placeholder="https://www.example.com"
                    icon="linkify"
                    isSubmitting={isSubmitting}
                  />
                </FormField>
                <FormField>
                  <TextInput
                    label="CTA Button Text"
                    name="ctaButtonText"
                    placeholder="Contact Us"
                  />
                </FormField>
              </>
            )}
            <FormField>
              {/* <PricingPlanField value={values.pricingPlans} setFieldValue={setFieldValue}/> */}
              <ButtonContainer>
                <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                  {submitButtonText}
                </Button>
              </ButtonContainer>
            </FormField>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const setInitialPriceTerms = (product: any) => {
  const priceTerms = _.has(product, 'priceTerms') ? product.priceTerms.map(({ id }: any) => id) : []

  return priceTerms
}

const setInitialClients = (product: any) => {
  const clients = _.has(product, 'clients') ? product.clients.map(({ id }: any) => id) : []

  return clients
}

/* const setupStripePlans = async (_productId, pricingPlans) => {
  if (pricingPlans.length > 0) {
    try {
      const updatePlanRequest = pricingPlans.map(async (plan) => {
        const { stripeId } = plan

        [>if (!stripeId && canCreateStripePlan(priceType)) {
          const { plan: createdStripePlan } = await createPlan({
            productId,
            price: getPrice(price),
            name: name,
            interval: getInterval(priceType, priceRange),
            intervalCount: getIntervalCount(priceType, pricePeriod),
          })

          // TODO: We may want to store this stripe plan locally within stripeContext
          return { ...plan, stripeId: createdStripePlan.id }
        }<]

        if (stripeId) {
          return plan
        }

        return { ...plan, stripeId: null }
      })

      return await Promise.all(updatePlanRequest)
    } catch (e) {
      console.error(e)
    }
  }

  return []
} */

/* const canCreateStripePlan = (priceType) => {
  // TODO: I'll need to check and see if the plan exist
  return (
    priceType === 'custom' ||
    priceType === '3months' ||
    priceType === '6months' ||
    priceType === 'monthly' ||
    priceType === 'annually'
  )
}

const getPrice = (price, priceType) => {
  if (priceType === 'annually') {
    return price * 12
  }

  return price
}

const getInterval = (priceType, priceRange) => {
  if (priceType === 'annually') {
    return 'year'
  }

  if (priceType === 'custom' && priceRange === 'years') {
    return 'year'
  }

  return 'month'
}

const getIntervalCount = (priceType, pricePeriod) => {
  switch (priceType) {
    case 'custom':
      return pricePeriod

    case '3months':
      return 3

    case '6months':
      return 6

    case 'monthly':
    case 'annually':
    default:
      return 1
  }
}
 */
// { key: 'monthly', text: 'Monthly', value: 'monthly' },
// { key: '3months', text: 'Every 3 months', value: '3months' },
// { key: '6months', text: 'Every 6 months', value: '6months' },
// { key: 'annually', text: 'Annually', value: 'annually' },
// { key: 'oneTime', text: 'One Time', value: 'oneTime' },
// { key: 'custom', text: 'Custom', value: 'custom' },
// { key: 'free', text: 'Free', value: 'free' },
// { key: 'callForPricing', text: 'Contact Us', value: 'callForPricing' },

const FlexWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const IntervalWrapper = styled.div`
  padding-left: 20px;
`

const Root = styled.div`
  width: 100%;
  max-width: 760px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 760px;
`

const TrueFalseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export default SalesForm
