import React from 'react'
import { Dropdown } from 'components/theme/form'
import { useTaxonomyDropdown } from 'hooks/useTaxonomyDropdown'

type Props = {
  [key: string]: any
  taxonomyId: number
  search?: boolean
  name: string
  label?: string
  value?: number | number[]
  setFieldValue: any
  onChange?: (event: any, value: any) => void
}

const TaxDropdown: React.FC<Props> = ({
  taxonomyId,
  hasBlankOption,
  search,
  label,
  name,
  value,
  setFieldValue,
  onChange,
  ...props
}) => {
  const { options, isLoading } = useTaxonomyDropdown({ taxonomyId })

  const sortedOptions = options.sort((a: any, b: any) => {
    const aText = a.text.toUpperCase()
    const bText = b.text.toUpperCase()
    return aText < bText ? -1 : aText > bText ? 1 : 0
  })

  const blankOption = { text: ' ', value: undefined }

  if (isLoading) return <></>

  return (
    <Dropdown
      options={hasBlankOption ? [blankOption, ...sortedOptions] : sortedOptions}
      search={search}
      onChange={(event: any, { value }: any) => {
        onChange && onChange(event, value)
        setFieldValue(name, value)
      }}
      label={label}
      setFieldValue={setFieldValue}
      name={name}
      value={value}
      {...props}
    />
  )
}

export default TaxDropdown
