import React, { ReactElement, useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import styled from '@emotion/styled'
import StepperButtons from './StepperButtons'

type Props = {
  onSubmit: (values: any) => void
  onFinalSubmit: (values: any) => void
  FormChildren: Array<ReactElement> | React.FC<{step: number}>
  RightSide?: Array<ReactElement> | React.FC<{step: number}>
  initialValues: Array<{ [key: string]: any }>
  formikProps?: { [key: string]: any }
  validators: any[]
}

const FormStepper: React.FC<Props> = ({
  onSubmit,
  onFinalSubmit,
  FormChildren,
  initialValues,
  formikProps,
  validators,
  RightSide
}) => {
  const [step, setStep] = useState<number>(0)
  const [selectedValues, setSelectedValues] = useState<any>(initialValues[0])
  const [selectedValidator, setSelectedValidator] = useState<any>(validators ? validators[0] : undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const maxStep = FormChildren.length - 1

  useEffect(() => {
    setLoading(true)
    setSelectedValues(initialValues[step])
    validators && setSelectedValidator(validators[step])
  }, [step])

  useEffect(() => setLoading(false), [JSON.stringify(selectedValues)])

  return (
    <>
      {!loading && (
        <Formik
          onSubmit={(values) => {
            step < maxStep ? onSubmit(values) : onFinalSubmit(values)
            step < maxStep && setStep((prev) => prev + 1)
          }}
          initialValues={selectedValues}
          validationSchema={selectedValidator ? selectedValidator : undefined}
          {...formikProps}
        >
          {({ isSubmitting }) => (
            <StyledForm>
              <LeftContainer split={!!RightSide}>
                {Array.isArray(FormChildren) ? FormChildren[step] : <FormChildren step={step} />}
                <StepperButtons
                  isSubmitting={isSubmitting}
                  step={step}
                  maxStep={maxStep}
                  setStep={setStep}
                />
              </LeftContainer>
              {RightSide && (
                <RightContainer>
                  {Array.isArray(RightSide) ? RightSide[step] : <RightSide step={step} />}
                </RightContainer>
              )}
            </StyledForm>
          )}
        </Formik>
      )}
    </>
  )
}

const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
`

const LeftContainer = styled.div<{split?: boolean}>`
  width: ${({ split }) => split ? '55%' : '100%'}
`

const RightContainer = styled.div`
  margin: 0 32px;
  width: 45%;
`

export default FormStepper
