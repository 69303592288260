import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { OrganizationType, UserRole } from 'generated/graphql'
import { useOrg } from 'data/organization/useOrg'

type Props = {
  role: UserRole
  onChange: (role: UserRole) => void
  allowVendorRole?: boolean
}

const UserRoleDropdown: React.FC<Props> = ({ role, onChange, allowVendorRole = false }) => {
  const { organization } = useOrg()

  const isSuperAdmin =
    role === 'SUPER_ADMIN' && organization?.id !== 'dc6d31c7-40b1-4616-9d00-36695e03d515'

  if (isSuperAdmin) return <>SUPER ADMIN</>
  const options =
    organization?.type === OrganizationType.Association && allowVendorRole
      ? ASSOC_ROLE_OPTIONS
      : USER_ROLE_OPTIONS

  return (
    <Dropdown
      options={
        isSuperAdmin
          ? [...options, { key: 'SUPER ADMIN', text: 'SUPER ADMIN', value: 'SUPER_ADMIN' }]
          : options
      }
      onChange={(_, { value }) => onChange(value as UserRole)}
      value={role}
    />
  )
}

const USER_ROLE_OPTIONS = [
  {
    key: 'USER',
    text: 'USER',
    value: 'USER',
  },
  {
    key: 'ADMIN',
    text: 'ADMIN',
    value: 'ADMIN',
  },
]

const ASSOC_ROLE_OPTIONS = [
  ...USER_ROLE_OPTIONS,
  {
    key: 'VENDOR',
    text: 'VENDOR',
    value: 'VENDOR',
  },
]

export default UserRoleDropdown
