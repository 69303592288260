import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useCreateProductToUser = () => {
  const request = useGql()
  return useMutation(async (productId: number) => {
    await request(CREATE_PRODUCT_TO_USER, { productId })
  })
}

export const CREATE_PRODUCT_TO_USER = gql`
  mutation CreateProductToUser($productId: Int!, $userId: Int!) {
    createProductToUser(input: { productToUser: { productId: $productId, userId: $userId } }) {
      clientMutationId
    }
  }
`
