import styled from '@emotion/styled'
import { useAssociationStackshare } from './associationStackshareContext'
import AssociationTechstackTableRow from './AssociationTechstackTableRow'

const AssociationTechstackTable: React.FC = () => {
  const { techstack } = useAssociationStackshare()

  return (
    <Root>
      <Table>
        <thead>
          <TableHead>
            <TableHeadline>PRODUCT</TableHeadline>
            <TableHeadline>USED BY</TableHeadline>
            <TableHeadline></TableHeadline>
            <TableHeadline></TableHeadline>
          </TableHead>
        </thead>
        <tbody>
          {techstack.map((stack) => (
            <AssociationTechstackTableRow key={stack.product.id} techstack={stack} />
          ))}
        </tbody>
      </Table>
    </Root>
  )
}

const Root = styled.div``

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const TableHead = styled.tr``

const TableHeadline = styled.th`
  text-align: left;
  letter-spacing: 0.02rem;
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
`

export default AssociationTechstackTable
