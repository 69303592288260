import React from 'react'
import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'

interface Props {
  title: string
  subtitle?: string
  text?: string | React.ReactNode
  buttonText: string
  image: string
  executeScroll: () => void
}

const Hero: React.FC<Props> = ({ title, subtitle, text, buttonText, image, executeScroll }) => {
  return (
    <Root>
      <Wrapper>
        <Left>
          <Title>{title}</Title>
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : <SubtitleText>{text}</SubtitleText>}
          <Button onClick={executeScroll}>{buttonText}</Button>
          {subtitle && title && <Text>{text}</Text>}
        </Left>
        <Right>
          <Image src={image} />
        </Right>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  padding: 0 20px;

  a {
    font-weight: 500;
  }
`

const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0;
`

const Subtitle = styled.p`
  font-size: 28px;
  margin-bottom: 16px;
`

const SubtitleText = styled.p`
  font-size: 16px;
  padding-top: 20px;
  max-width: 400px;
  line-height: 1.65;
  letter-spacing: 0.01rem;
  margin-bottom: 24px;
`

const Text = styled.p`
  font-size: 16px;
  padding-top: 20px;
  max-width: 410px;
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 600px;
  margin: 0 auto;
  max-width: 1140px;
  align-items: center;

  ${breakpoint.mobile} {
    height: auto;
  }
`

const Button = styled.a`
  display: inline-block;
  padding: 12px 28px;
  color: white;
  background: #ff403f;
  border-radius: 6px;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  transition: background 250ms;
  cursor: pointer;

  &:hover {
    color: white;
    background: #498de6;
  }
`

const Left = styled.div`
  width: 40%;
  padding-left: 20px;
  ${breakpoint.mobile} {
    width: 100%;
    order: 2;
    padding: 20px 0 40px;
  }
`

const Right = styled.div`
  width: 60%;

  ${breakpoint.mobile} {
    width: 100%;
    order: 1;
  }
`

const Image = styled.img`
  max-width: 700px;
  max-height: 500px;
  width: 100%;
`

export default Hero
