import {
  ORG_CORE_FRAGMENT,
  VIEWER_ORG_FRAGMENT,
  CURRENT_ORG_FRAGMENT,
} from 'data/fragments/organization'
import { PRODUCT_CORE_FRAGMENT, VIEWER_PRODUCT_FRAGMENT } from 'data/fragments/product'
import { TERM_CORE_FRAGMENT } from 'data/fragments/term'
import { gql } from 'graphql-request'

export const CURRENT_ORG_GQL = gql`
  query ViewerCurrentOrg {
    viewersCurrentOrganization {
      ...CurrentOrg
    }
  }

  ${CURRENT_ORG_FRAGMENT}
  ${ORG_CORE_FRAGMENT}
  ${PRODUCT_CORE_FRAGMENT}
  ${VIEWER_ORG_FRAGMENT}
  ${VIEWER_PRODUCT_FRAGMENT}
  ${TERM_CORE_FRAGMENT}
`
