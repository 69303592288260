import React from 'react'
import BasicInfoForm from 'components/forms/product/BasicInfoForm'
import { FlexLoading } from 'components/PulsatingLoader'
import _ from 'lodash'
import { FormDescription } from 'components/theme/form'
import { useProductSettings } from '../useProductSettings'
import VendorUpgradeModal from 'components/modals/VendorUpgradeModal'

function BasicProductInfo() {
  const { product, isService, loading, onComplete, showModal, toggleModal } = useProductSettings()

  const initialValues = {
    name: _.get(product, 'name', ''),
    url: _.get(product, 'details.url', '') || '',
    excerpt: _.get(product, 'excerpt', '') || '',
    logoFile: _.get(product, 'details.logoUrl', []),
    squareLogoFile: _.get(product, 'details.squareLogoUrl', []),
  }

  if (loading || !product) return <FlexLoading />

  return (
    <>
      <FormDescription>Required information for profile preview in marketplace</FormDescription>
      <BasicInfoForm
        initialValues={initialValues}
        onComplete={onComplete}
        product={product}
        submitButtonText="Update"
        isService={isService}
      />
      <VendorUpgradeModal showModal={showModal} toggleModal={toggleModal} />
    </>
  )
}

export default BasicProductInfo
