import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useUpdateShowProductListing = () => {
  const request = useGql()
  const queryClient = useQueryClient()
  const { organization } = useOrg()

  return useMutation(
    async ({ id, show }: { id: number; show: boolean }) => {
      await request(UPDATE_SHOW_PRODUCT_LISTING, {
        id,
        show,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['listings', organization?.id]),
    }
  )
}

const UPDATE_SHOW_PRODUCT_LISTING = gql`
  mutation UpdateProductListingShow($id: Int!, $show: Boolean) {
    updateProductListing(input: { patch: { show: $show }, id: $id }) {
      clientMutationId
    }
  }
`
