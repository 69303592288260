import { toast } from 'react-toastify'
import { FullPageLoading } from 'components/PulsatingLoader'
import AssociationForm from 'components/forms/AssociationForm'
import { useOrg } from 'data/organization/useOrg'

function EditAssociation() {
  const { association } = useOrg()

  if (!association) return <FullPageLoading />

  const initialValues = {
    name: association.name || '',
    website: association.details.website || '',
    logo: association.details.logo || [],
    bannerOverlay: association.details.bannerOverlay || [],
    bannerImage: association.details.bannerImage || [],
    bannerText: association.details.bannerImage || '',
    overlayWidth: association.details.overlayWidth || '',
    practiceGroup:
      association.terms.filter((term) => term.taxonomyId === 13).map(({ id }) => id) || [],
    roles: association.terms.filter((term) => term.taxonomyId === 8).map(({ id }) => id) || [],
  }

  const onComplete = () => {
    toast.success('Vendor has been updated')
  }

  return <AssociationForm initialValues={initialValues} onComplete={onComplete} />
}

export default EditAssociation
