import { useState } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { OrganizationType } from 'generated/graphql'
import { useOrg } from 'data/organization/useOrg'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Link } from 'react-router-dom'
import InviteModal from 'components/modals/InviteModal'
import { useViewer } from 'context/viewerContext'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'

const UserMenuLinks = () => {
  const [isInviteModal, setIsInviteModal] = useState(false)
  const baseOrgUrl = useBaseOrgUrl()
  const { organization } = useOrg()
  const { isSuperAdmin } = useViewer()
  const marketplaceUrl = useMarketplaceUrl(true)

  const isAllowInviteTeam =
    (organization?.type !== OrganizationType.Personal &&
      organization?.type !== OrganizationType.Association) ||
    organization?.type === OrganizationType.Association

  return (
    <Root>
      {organization?.type === OrganizationType.Vendor ? (
        <MenuAnchor href={`${marketplaceUrl}returnTo=getting-started`}>Getting Started</MenuAnchor>
      ) : (
        <MenuLink to={`${baseOrgUrl}/getting-started`}>Getting Started</MenuLink>
      )}
      {isAllowInviteTeam && (
        <MenuClickable onClick={() => setIsInviteModal(true)}>Invite Teammates +</MenuClickable>
      )}
      {isSuperAdmin && (
        <MenuLink to={`${baseOrgUrl}/discover-organizations`}>Join Another Organization</MenuLink>
      )}
      <MenuAnchor href="https://share.hsforms.com/1mi8ic8YSRxGWt-8MP4E0PA3p9i3" target="_blank">
        Get Help | Give Feedback
      </MenuAnchor>
      <MenuLink to={`${baseOrgUrl}/settings`}>Profile and Settings</MenuLink>
      <InviteModal
        open={isInviteModal}
        onModalClose={() => {
          setIsInviteModal(false)
        }}
      />
    </Root>
  )
}

const Root = styled.div`
  padding: 8px 0;
`

const linkStyles = css`
  font-size: 16px;
  font-weight: 500;
  display: block;
  color: #585a5f;
  padding: 6px 20px;
  transition: 250ms background, 250ms color;
  cursor: pointer;

  &:hover {
    background: #eee;
    color: #222;
  }
`

const MenuLink = styled(Link)`
  ${linkStyles}
`

const MenuAnchor = styled.a`
  ${linkStyles}
`

const MenuClickable = styled.div`
  ${linkStyles}
`

export default UserMenuLinks
