import React from 'react'
import styled from '@emotion/styled'
import { useStories } from 'context/userStoriesContext'
import FoldoutStory from 'components/UserStories/FoldoutStory'
import { Button } from 'components/theme'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const UserStories: React.FC<{ productId: number }> = ({ productId }) => {
  const { productSpecificStories } = useStories()
  const baseOrgUrl = useBaseOrgUrl()

  if (productSpecificStories(productId).length === 0)
    return (
      <NoStories>
        <Text>This product has no user stories</Text>
        <Button
          component="link"
          to={{ pathname: `${baseOrgUrl}/user-stories/create`, state: { productId } }}
        >
          Add the First User Story
        </Button>
      </NoStories>
    )

  return (
    <>
      {productSpecificStories(productId).map((Story, index) => (
        <FoldoutStory key={`UserStories--${index}`} Story={Story} />
      ))}
    </>
  )
}

const Text = styled.div`
  text-align: center;
  font-size: 16px;
  opacity: 0.6;
  font-weight: 600;
  margin-bottom: 16px;
`

const NoStories = styled.div`
  text-align: center;
`

export default UserStories
