import React from 'react'
import { toast } from 'react-toastify'
import { Formik, Form } from 'formik'
import { UnderlineTitle } from 'components/theme'
import styled from '@emotion/styled'
import { TextInput, FormField, TrueFalse } from 'components/theme/form'
import ColorPicker from 'components/fields/ColorPicker'
import PriceFields from './PriceFields'
import ButtonTypeDropdown from './ButtonTypeDropdown'
import PlanHeader from './PlanHeader'
import FieldItemField from './FieldItemField'
import { PricingPlan as PlanPreview } from '@theoremlts/theorem-design'
import { useViewer } from 'context/viewerContext'

const PricingPlan: React.FC<{
  index: number | null
  resource: any
  updatePlan: (action: 'UPDATE' | 'DELETE' | 'ADD', index: number | null, plan?: any) => void
  closePlan: () => void
}> = ({ index, resource, updatePlan, closePlan }) => {
  const { paymentStatus } = useViewer()

  const CustomButton = () => (
    <>
      <ButtonTextWrapper>
        <TextInput label="Button Text" placeholder="Button Text" name={`buttonText`} />
      </ButtonTextWrapper>
      <ButtonURLWrapper>
        <TextInput label="Button URL" placeholder="example.com" name={`buttonUrl`} />
      </ButtonURLWrapper>
    </>
  )

  const actionButtonHelperContent = () => {
    if (paymentStatus === 'ACTIVE') {
      return 'This will be how customers interact with your pricing plans'
    } else {
      return 'This will be how customers interact with your pricing plans - starred actions require upgraded access'
    }
  }

  return (
    <Formik initialValues={resource} onSubmit={(values) => updatePlan('UPDATE', index, values)}>
      {({ values, setFieldValue }) => (
        <Form>
          <PricingPlanWrapper>
            <PlanHeader resource={values} index={index} closePlan={closePlan} />
            <FormFieldsWrapper>
              {/* <SimpleBarReact> */}
              <FormTop>
                <Left>
                  <TopField>
                    <FormField>
                      <FieldSameLine>
                        <NameFieldWrapper>
                          <TextInput label="Plan Title" placeholder="name" name={`name`} />
                        </NameFieldWrapper>
                        <ColorPicker label="Color Picker" name={`color`} />
                      </FieldSameLine>
                    </FormField>
                  </TopField>
                  <>
                    <UnderlineTitle>Payment</UnderlineTitle>

                    <PriceWrapper>
                      <PriceFields resource={values} setFieldValue={setFieldValue} index={index} />
                    </PriceWrapper>
                  </>
                  {values.priceType !== 'tiered' && (
                    <>
                      <UnderlineTitle>Metadata</UnderlineTitle>
                      <FieldSameLine>
                        <FirstHalf>
                          <TrueFalse
                            label="Feature this Plan?"
                            text="Mark as recommended"
                            name={`isRecommended`}
                          />
                        </FirstHalf>
                        <Half>
                          <TrueFalse
                            label="Is plan per active user?"
                            text="Yes"
                            name={`isPerUser`}
                          />
                        </Half>
                      </FieldSameLine>
                    </>
                  )}
                  {values.priceType === 'tiered' && (
                    <>
                      <UnderlineTitle>Metadata</UnderlineTitle>
                      <FieldSameLine>
                        <FirstHalf>
                          <TrueFalse
                            label="Feature this Plan?"
                            text="Mark as recommended"
                            name={`isRecommended`}
                          />
                        </FirstHalf>
                        <Half>
                          <TrueFalse
                            label="Is plan per active user?"
                            text="Yes"
                            name={`isPerUser`}
                          />
                        </Half>
                      </FieldSameLine>
                    </>
                  )}
                  <Section>
                    <FieldSameLine>
                      <HasDiscountWrapper>
                        <TrueFalse label="Has discount code?" text="Yes" name={`hasDiscountCode`} />
                      </HasDiscountWrapper>

                      {values.hasDiscountCode && (
                        <Grow>
                          <TextInput
                            label="Discount Code"
                            placeholder="discount code"
                            name={`discountCode`}
                          />
                        </Grow>
                      )}
                    </FieldSameLine>
                  </Section>
                  <ButtonFieldsWrapper>
                    <ButtonTypeDropdown
                      name={`buttonType`}
                      stripeId={values.stripeId}
                      helpContent={actionButtonHelperContent()}
                    />
                    {values.buttonType === 'custom' && <CustomButton />}
                  </ButtonFieldsWrapper>
                  <FieldItemField index={index} value={values.fields} />
                  {/* </SimpleBarReact> */}
                </Left>
                <PreviewWrapper isRecommended={values.isRecommended}>
                  <PlanPreview
                    plan={values}
                    isPreview
                    onActionButton={(event) => {
                      event.preventDefault()
                      toast.success(
                        'This card is a preview - in the ecosystem a user will either be redirected to another page, or checkout',
                        { toastId: 'unique_preview_card ' }
                      )
                    }}
                  />
                </PreviewWrapper>
              </FormTop>
            </FormFieldsWrapper>
          </PricingPlanWrapper>
        </Form>
      )}
    </Formik>
  )
}

const TopField = styled.div`
  position: relative;
  z-index: 10000;
`

const Left = styled.div`
  width: calc(100% - 375px);
`

const FormTop = styled.div`
  postion: relative;
`

const PreviewWrapper = styled.div<{ isRecommended: boolean }>`
  width: 100%;
  display: flex;
  max-width: 375px;
  position: absolute;
  top: ${({ isRecommended }) => (isRecommended ? '107px' : '78px')};
  right: 0;
`

const FirstHalf = styled.div`
  width: 50%;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 8px 16px 16px 0;
`

const Half = styled.div`
  width: 50%;
  padding: 8px 16px 16px;
  border-bottom: 1px solid #ddd;
`

const NameFieldWrapper = styled.div`
  flex: 1 1 0;
  padding-right: 16px;
`

const Section = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 16px 0;
`

const PriceWrapper = styled.div`
  padding-bottom: 16px;
`

const PricingPlanWrapper = styled.div`
  flex-wrap: wrap;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
`

const FormFieldsWrapper = styled.div`
  padding: 16px;
  padding-bottom: 32px;
  max-height: 674px;
  height: min-content;
  overflow: scroll;
  overflow-x: hidden;
`

const ButtonFieldsWrapper = styled.div`
  display: flex;
  padding: 16px 0;
`

const ButtonTextWrapper = styled.div`
  flex: 1 1 0px;
  padding-right: 8px;
`

const ButtonURLWrapper = styled.div`
  flex: 1 1 0px;
  padding-left: 8px;
`

const FieldSameLine = styled.div`
  display: flex;
  position: relative;
  z-index: 999;
`

const HasDiscountWrapper = styled.div`
  min-width: 124px;
`
const Grow = styled.div`
  margin-left: 16px;
  flex: 1 1 0;
`

export default PricingPlan
