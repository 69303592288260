import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { extractPictureUrl } from 'services/image'
import { useUpdateJobTitle } from 'data/hooks'

export interface MutateViewerVariables {
  first?: string
  last?: string
  jobTitle?: string
  orgJobTitle?: number
  fileUrl?: string
  linkedin?: string
  twitter?: string
  currentOrganizationId?: string
  locationId?: number
  details?: { [key: string]: any }
  appMetadata?: { [key: string]: any }
  deleteAppMetadataKeys?: string[]
}
export const useMutateViewer = () => {
  const request = useGql()
  const queryClient = useQueryClient()
  const { viewer } = useViewer()
  const { organization } = useOrg()
  const updateJobTitle = useUpdateJobTitle()

  const parseDetails = async (variables: MutateViewerVariables): Promise<any> => {
    const { jobTitle, linkedin, twitter, fileUrl, details: moreDetails } = variables

    const imageUrl = await extractPictureUrl(fileUrl)

    const details = {
      ...viewer.details,
      ...moreDetails,
    }
    if (jobTitle !== undefined) details.jobTitle = jobTitle
    if (imageUrl !== undefined && imageUrl !== '') details.imageUrl = imageUrl
    if (linkedin !== undefined) details.linkedin = linkedin
    if (twitter !== undefined) details.twitter = twitter

    return details
  }

  return useMutation(
    async (variables: MutateViewerVariables) => {
      const {
        first,
        last,
        jobTitle,
        orgJobTitle,
        currentOrganizationId,
        locationId,
        appMetadata: moreAppMetadata,
        deleteAppMetadataKeys,
      } = variables

      const appMetadata = {
        ...viewer.appMetadata,
        ...moreAppMetadata,
      }

      if (deleteAppMetadataKeys) {
        deleteAppMetadataKeys.forEach((key) => {
          delete appMetadata[key]
        })
      }

      const details = await parseDetails(variables)

      await request(UPDATE_USER, {
        id: viewer.id,
        first,
        last,
        jobTitle,
        currentOrganizationId,
        locationId,
        details,
        appMetadata,
      })

      if (organization && orgJobTitle) {
        console.log(orgJobTitle)
        await updateJobTitle.mutateAsync(orgJobTitle)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['viewer']),
    }
  )
}

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $first: String
    $last: String
    $jobTitle: String
    $currentOrganizationId: UUID
    $details: JSON
    $appMetadata: JSON
    $locationId: Int
    $id: Int!
  ) {
    updateUser(
      input: {
        patch: {
          first: $first
          last: $last
          jobTitle: $jobTitle
          details: $details
          appMetadata: $appMetadata
          currentOrganization: $currentOrganizationId
          locationId: $locationId
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`
