import React, { useState, useEffect } from 'react'
import FileUpload from 'components/fields/FileUpload'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Icon } from 'semantic-ui-react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useField } from 'formik'
import Label from 'components/theme/form/Label'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const setImageSrc = (file: any) => (typeof file === 'string' ? file : URL.createObjectURL(file))

const DraggableImages = React.memo(function DraggableImages({ deleteImage, files }: any) {
  return files.map((image: any, index: number) => (
    <Draggable key={image.id} draggableId={image.id} index={index}>
      {(provided) => (
        <ImageContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <CloseIcon onClick={deleteImage(image.id)} name="close" />
          <Logo src={setImageSrc(image.file)} alt="Product Image" />
        </ImageContainer>
      )}
    </Draggable>
  ))
})

const formatInitialValue = (initialValue: any) =>
  initialValue.map((val: any) => ({ id: uuid(), file: val }))

function UploadMultipleAssets({ name, isService, onChange }: any) {
  const [, meta] = useField({ name })
  const [files, setFiles] = useState(formatInitialValue(meta.initialValue))

  useEffect(() => {
    onChange(name, files, true)
  }, [name, files, onChange])

  const deleteImage = (id: any) => () => {
    setFiles((prevFiles: any) => _.filter(prevFiles, (preFile) => preFile.id !== id))
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const fileOrder = reorder(files, result.source.index, result.destination.index)

    setFiles([...fileOrder])
  }

  const addFile = (file: any) => {
    setFiles((prevFiles: any) => [...prevFiles, { file, id: uuid() }])
  }

  return (
    <>
      <Top>
        <Label>Upload {isService ? 'Service' : 'Product'} Images (Screenshots)</Label>
        <Right>Approve file types: PNG, JPG, SVG, WebP</Right>
      </Top>
      <DragWrapper>
        {files && files.length > 0 && (
          <DraggableWrapper>
            <HelperDragText>Drag and Drop to order slides</HelperDragText>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                  <DropArea ref={provided.innerRef} {...provided.droppableProps}>
                    {files && files.length > 0 && (
                      <DraggableImages deleteImage={deleteImage} files={files} />
                    )}
                  </DropArea>
                )}
              </Droppable>
            </DragDropContext>
          </DraggableWrapper>
        )}
        <FileUploadWrapper>
          <FileUpload
            addFile={addFile}
            styles={css`
              max-width: none;
              background: #eee;
            `}
          >
            Upload Slides Here
          </FileUpload>
        </FileUploadWrapper>
      </DragWrapper>
    </>
  )
}

const DropArea = styled.div`
  display: flex;
  overflow: auto;
`

const Top = styled.div`
  justify-content: space-between;
  display: flex;
  font-size: 13px;
  font-style: italic;
  color: #616161;
`

const Right = styled.div``

const Logo = styled.img`
  max-width: 150px;
  max-height: 130px;
`

const CloseIcon = styled(Icon)`
  opacity: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  transition: opacity 350ms;
`

const ImageContainer = styled.div`
  position: relative;
  max-width: 150px;
  max-height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  &:hover {
    ${CloseIcon} {
      opacity: 1 !important;
    }
  }
`

const DragWrapper = styled.div`
  background: #f6f6f6;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  overflow: hidden;
`

const HelperDragText = styled.div`
  text-align: center;
  color: #555;
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.75px;
`

const DraggableWrapper = styled.div`
  background: #e8e8e8;
  padding: 5px 15px 15px;
`

const FileUploadWrapper = styled.div`
  padding: 15px;
`

export default React.memo(UploadMultipleAssets)
