import styled from '@emotion/styled'
import EditCompanyListing from './EditCompanyListing'

const LawfirmFlow = () => {
  return (
    <Root>
      <EditCompanyListing />
    </Root>
  )
}

const Root = styled.div``

export default LawfirmFlow
