import styled from '@emotion/styled'
import { useWizardModal } from 'components/modals/WizardModal'
import AccPlans from './AccPlans'
import ClocPlans from './ClocPlans'
import EltaPlans from './EltaPlans'

const ProductPlans = () => {
  const { data } = useWizardModal()

  const { networkSlug } = data

  return (
    <Root>
      {networkSlug === 'acc' && <AccPlans />}
      {networkSlug === 'cloc' && <ClocPlans />}
      {networkSlug === 'elta' && <EltaPlans />}
      {networkSlug === 'in-house-connect' && <EltaPlans />}
    </Root>
  )
}

const Root = styled.div``

export default ProductPlans
