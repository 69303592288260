import { useField } from 'formik'
import { useEffect } from 'react'
import Label from './Label'
import MoneyCurrencyField from './MoneyCurrencyField'

interface Props {
  label?: string
  inputName: string
  currencyName: string
  disabled?: boolean
  hasConnectAccount?: boolean
  placeholder?: string
}
const MoneyCurrencyFieldFormik = ({
  label,
  inputName,
  currencyName,
  disabled,
  hasConnectAccount,
  placeholder,
}: Props) => {
  const [, meta, helpers] = useField({ name: inputName, type: 'text' })
  const [, meta2, helpers2] = useField({ name: currencyName })

  useEffect(() => {
    if (disabled) helpers.setValue(0)
  }, [disabled])

  const onMoneyChange = (value?: number) => {
    helpers.setValue(value)
  }

  const onCurrencyChange = (value: string) => {
    helpers2.setValue(value)
  }

  return (
    <div>
      {label && (
        <Label
          helpContent={
            hasConnectAccount &&
            label !== 'Starting Price' &&
            'When a price is associated with a Stripe price plan users can buy this product directly on Theorem.'
          }
        >
          {label}
        </Label>
      )}
      <MoneyCurrencyField
        moneyValue={meta.value}
        onMoneyChange={onMoneyChange}
        currencyValue={meta2.value}
        onCurrencyChange={onCurrencyChange}
        placeholder={placeholder}
      />
    </div>
  )
}

export default MoneyCurrencyFieldFormik
