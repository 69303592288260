import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'
import { useProductListings } from 'data/hooks'
import { useListingStepper } from '../ListingStepperContext'
import FaqAccordian from './FaqAccordian'
import ListingGetStarted from './ListingGetStarted'
import questionAnswers from './questionAnswers'

const Faqs = () => {
  const { productListings } = useProductListings()
  const { orgListings } = useListingStepper()

  const hasProductListing = productListings && productListings.length > 0
  const hasOrgListing = orgListings && orgListings.length > 0

  return (
    <Root>
      <BoxWrapper>
        {(hasProductListing || hasOrgListing) && (
          <ListingWrapper>
            <ListingGetStarted />
          </ListingWrapper>
        )}
        <Wrapper>
          <Title>FAQ</Title>
          <List>
            {questionAnswers.map(({ question, answer }, index) => (
              <FaqAccordian key={index} question={question} answer={answer} />
            ))}
          </List>
        </Wrapper>
      </BoxWrapper>
    </Root>
  )
}

const ListingWrapper = styled.div`
  padding-bottom: 40px;
`

const Root = styled.div`
  padding-top: 20px;
`

const Title = styled.h2`
  margin-bottom: 16px;
  text-align: center;
`

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

const List = styled.div``

export default Faqs
