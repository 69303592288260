import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'
import Modal from 'components/Modal'
import { SearchResult } from '.'
import { toast } from 'react-toastify'
import { useSetUserStatus } from 'hooks/graphql'
import { logEvent } from '@theoremlts/theorem-design'

import { useAuth0 } from '@auth0/auth0-react'
import { useLazyApi } from 'hooks/useApi'
import { useNavigate } from 'react-router'
import { UserStatus, UserRole } from 'generated/graphql'
import { useViewer } from 'context/viewerContext'
import { useSwitchOrg } from 'data/viewer/useSwitchOrg'
import { useCreateUserToOrg } from 'data/hooks'

type Props = {
  isShowing: boolean
  toggle: () => void
  organization: SearchResult
  adminJoin: () => void
}

const ConnectOrganizationModal = ({ isShowing, toggle, organization, adminJoin }: Props) => {
  const { logout } = useAuth0()
  const [requestInviteEmail] = useLazyApi('company/request')
  const createUserToOrg = useCreateUserToOrg()
  const switchOrg = useSwitchOrg()

  const { setUserStatus } = useSetUserStatus()
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const { isClaimed, domain, id: organizationId } = organization
  const { viewer } = useViewer()
  const { email } = viewer

  const userDomain = email.split('@')[1]
  let ModalBody = <></>

  const logoutUser = () => {
    logEvent('log_out')
    logout()
  }

  const claimOrganization = async () => {
    if (loading) return
    try {
      setLoading(true)
      await createUserToOrg.mutateAsync({
        organizationId,
        role: UserRole.Owner,
      })
      await setUserStatus(UserStatus.LawfirmPlans)
      await switchOrg.mutateAsync(organizationId)
      navigate('/setup/lawfirm-plans')
    } catch (error) {
      setLoading(false)
      console.error(error)
      toast.warn('Something went wrong, please try again.')
    }
  }

  const requestOrganizationAccess = async () => {
    if (loading) return
    try {
      setLoading(true)
      await requestInviteEmail({ organizationId: organization.id })
      toast.success(`You requested to join ${organization.name}!`)
      toggle()
    } catch (error) {
      setLoading(false)
      console.error(error)
      toast.warn('Something went wrong, please try again.')
    }
  }

  const Cancel = () => <CancelButton onClick={toggle}>Cancel</CancelButton>

  if (!isClaimed && userDomain !== domain) {
    ModalBody = (
      <>
        <Text>Please login with the correct email domain to connect with this organization</Text>
        <Buttons>
          <Cancel />
          <Button onClick={logoutUser}>Logout</Button>
          {email.includes('@theoremlegal.com') && (
            <Button color="#9013FE" style={{ marginLeft: '8px' }} onClick={() => adminJoin()}>
              Join As Admin
            </Button>
          )}
        </Buttons>
      </>
    )
  } else if (!isClaimed) {
    ModalBody = (
      <>
        <Text>Are you sure you want to claim this organization?</Text>
        <Buttons>
          <Cancel />
          <Button onClick={claimOrganization} loading={loading} disabled={loading}>
            Get Started
          </Button>
        </Buttons>
      </>
    )
  } else {
    ModalBody = (
      <>
        <Text>Request access to this organization</Text>
        <Buttons>
          <Cancel />
          <Button onClick={requestOrganizationAccess} loading={loading} disabled={loading}>
            Request
          </Button>
          {email.includes('@theoremlegal.com') && (
            <Button color="#9013FE" style={{ marginLeft: '8px' }} onClick={() => adminJoin()}>
              Join As Admin
            </Button>
          )}
        </Buttons>
      </>
    )
  }

  return (
    <Modal isShowing={isShowing} toggleModal={toggle}>
      <Body>{ModalBody}</Body>
    </Modal>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.div`
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.2;
`

const CancelButton = styled(Button)`
  color: #aaa;
  border-color: #aaa;
  margin-right: 8px;

  &:hover {
    color: white;
    border-color: #aaa;
    background-color: #aaa;
  }
`

const Buttons = styled.div`
  display: flex;
`

export default ConnectOrganizationModal
