import { useQuery } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlGetTechstackQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useTechstack = () => {
  const { organization } = useOrg()
  const request = useGql()
  const { data, ...other } = useQuery(
    ['org', organization?.id, 'techstack'],
    async () => {
      if (!organization) return

      const response = await request<GqlGetTechstackQuery>(GET_TECHSTACK, {
        selectedOrganizationId: organization.id,
      })

      return response.techstacks
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  )

  return { techstack: data, ...other }
}

const GET_TECHSTACK = gql`
  query GetTechstack($selectedOrganizationId: UUID) {
    techstacks(condition: { organizationId: $selectedOrganizationId }) {
      id
      product {
        id
        details
        slug
        name
        termToProducts {
          term {
            id
            name
            slug
            createdAt
            taxonomyId
            taxonomy {
              id
              name
              slug
            }
          }
        }
      }
      metadata
      access
      stage
      userByAddedBy {
        first
        email
        last
        details
        id
      }
      ownedBy
      userByOwnedBy {
        id
        details
        email
        first
        last
      }
    }
  }
`
