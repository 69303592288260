import styled from '@emotion/styled'
import { useFirmApps } from './firmAppsContext'
import PulsatingLoader from 'components/PulsatingLoader'
import FirmAppTableRow from './FirmAppTableRow'
import NoTechstack from './NoTechstack'

const FirmAppsTable = () => {
  const { loading, techstack } = useFirmApps()

  if (loading) return <PulsatingLoader />

  return (
    <Root>
      {techstack.length > 0 ? (
        <Table>
          <thead>
            <TableHead>
              <TableHeadline>PRODUCT</TableHeadline>
              <TableHeadline>MAIN CATEGORY</TableHeadline>
              <TableHeadline>USED BY</TableHeadline>
              <TableHeadline></TableHeadline>
              <TableHeadline></TableHeadline>
            </TableHead>
          </thead>
          <tbody>
            {techstack.map((product) => (
              <FirmAppTableRow key={product.id} product={product} />
            ))}
          </tbody>
        </Table>
      ) : (
        <NoTechstack />
      )}
    </Root>
  )
}

const Root = styled.div``

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const TableHead = styled.tr``

const TableHeadline = styled.th`
  text-align: left;
  letter-spacing: 0.02rem;
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
`

export default FirmAppsTable
