import { useMutation } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { GqlCreateOrganizationTechstackMutation } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useCreateTechstack = () => {
  const request = useGql()
  const { viewer } = useViewer()
  const { organization } = useOrg()
  return useMutation(async (productId: number) => {
    const response = await request<GqlCreateOrganizationTechstackMutation>(
      CREATE_ORGANIZATION_TECHSTACK,
      {
        organizationId: organization?.id,
        addedBy: viewer.id,
        productId,
      }
    )

    return response.createTechstack?.techstack
  })
}

export const CREATE_ORGANIZATION_TECHSTACK = gql`
  mutation CreateOrganizationTechstack($organizationId: UUID!, $productId: Int!, $addedBy: Int) {
    createTechstack(
      input: {
        techstack: { organizationId: $organizationId, productId: $productId, addedBy: $addedBy }
      }
    ) {
      techstack {
        id
        productId
      }
    }
  }
`
