import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Icon } from 'components/theme'
import { faArrowsUpDown } from '@fortawesome/free-solid-svg-icons'
import styled from '@emotion/styled'

interface Props {
  id: string
  children: React.ReactNode
  removeClick: () => void
}
const SortableItem = ({ id, removeClick, children }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Root ref={setNodeRef} style={style} {...attributes}>
      <Content>{children}</Content>
      <Buttons>
        <button type="button" onClick={removeClick}>
          Remove
        </button>
        <button ref={setActivatorNodeRef} {...listeners}>
          <span>
            Move <Icon name={faArrowsUpDown} />
          </span>
        </button>
      </Buttons>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`

const Content = styled.div`
  margin-right: 20px;
`
const Buttons = styled.div``

export default SortableItem
