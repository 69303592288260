import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { GqlGetUserStoriesQuery } from 'generated/graphql'

export const useUserStories = () => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['userStory'],
    async () => {
      const response = await request<GqlGetUserStoriesQuery>(GET_USER_STORIES)

      return response.userStories
    },
    {
      staleTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  )

  return { userStories: data, ...other }
}

export const GET_USER_STORIES = gql`
  query GetUserStories {
    userStories {
      userByAuthor {
        id
        first
        last
        testMode
        details
      }
      to
      updatedAt
      createdAt
      details
      id
      organizationId
      organization {
        associationToOrganizations {
          associationId
          status
        }
      }
      ownerCreated
      product {
        id
        name
        details
        slug
        testMode
      }
      when
      termToUserStories {
        term {
          id
          name
          taxonomyId
        }
      }
    }
  }
`
