import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'
import { useOrg } from 'data/hooks'
import { ProductType } from 'generated/graphql'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Link, useParams } from 'react-router-dom'
import { useCompanyListing } from './useCompanyListing'

const DOMAIN =
  process.env.NODE_ENV === 'development'
    ? `https://ecosystem.theorem.local:3300`
    : 'https://ecosystem.theoremlegal.com'

const CompanyListing = () => {
  const { companyListing, companyProducts } = useCompanyListing()
  const baseOrgUrl = useBaseOrgUrl()
  const { slug } = useParams()
  const { organization } = useOrg()

  return (
    <Root>
      <BoxWrapper>
        <BackButton to="..">&#60; Back</BackButton>
        <Title>Your company details</Title>
        <CompanyListingBox>
          <Left>
            <CompanyLogo src={companyListing?.organization.details.logo} />
            <CompanyName>{companyListing?.organization.name}</CompanyName>
          </Left>
          <Right>
            <CompanyLink
              href={`${DOMAIN}/api/auth/login?returnTo=/admin/edit-company-listing/basic`}
            >
              Edit Company Listing Wizard
            </CompanyLink>
            <Divider />
            <CompanyLink
              href={`${DOMAIN}/api/auth/login?returnTo=/${slug}/company/${organization?.slug}`}
            >
              View Company Listing
            </CompanyLink>
          </Right>
        </CompanyListingBox>
        <Title>Product and Service Listings for this company</Title>
        <ProductBoxes>
          {companyProducts?.map(({ id, name, slug, type, details }) => {
            const routeType = type === ProductType.Service ? 'service' : 'product'
            const previewUrl = `${DOMAIN}/api/auth/login?returnTo=/${slug}/${routeType}/${organization?.slug}`
            return (
              <ProductBox key={`prod-box-${id}`}>
                <Logo src={details?.squareLogoUrl} />
                <Name>{name}</Name>
                <Type>{type}</Type>
                <BottomLinks>
                  <a href={previewUrl} target="_blank">
                    <BottomLink>View</BottomLink>
                  </a>
                  <BottomLink>
                    <Link to={`${baseOrgUrl}/product/settings/${id}`}>Edit</Link>
                  </BottomLink>
                </BottomLinks>
              </ProductBox>
            )
          })}
        </ProductBoxes>
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

const BackButton = styled(Link)`
  font-weight: 500;
  font-size: 18px;
`

const CompanyLink = styled.a`
  display: block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 4px;
`

const Divider = styled.div`
  border-bottom: 1px dashed #ccc;
`

const CompanyListingBox = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 6px;
`

const CompanyLogo = styled.img`
  width: 100%;
  max-width: 100px;
`

const CompanyName = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding-left: 16px;
`

const Left = styled.div`
  display: flex;
`

const Right = styled.div`
  text-align: right;
`

const Title = styled.h3``

const Logo = styled.img`
  width: 100%;
  max-width: 100px;
  max-height: 100px;
`

const ProductBox = styled.div`
  max-width: 200px;
  text-align: center;
  margin: 0 16px;
  width: 100%;
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 6px;
`

const ProductBoxes = styled.div`
  display: flex;
  margin: 0 -16px;
`

const Name = styled.div`
  padding-top: 12px;
  padding-bottom: 4px;
  font-weight: 500;
`

const Type = styled.div`
  background: #46a1eb;
  padding: 0 16px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 1000px;
  color: white;
  display: inline-block;
  margin-bottom: 28px;
`

const BottomLinks = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
`

const BottomLink = styled.span`
  color: #1094d1;
`

export default CompanyListing
