import styled from '@emotion/styled'
import BasicInfoForm from 'components/forms/product/BasicInfoForm'
import { BoxWrapper } from 'components/theme'
import BackButton from 'components/theme/BackButton'
import { useLocation, useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useListingStepper } from './ListingStepperContext'
import { useCreateFreeProductListing } from './useCreateFreeProductListing'

const CreateProduct = () => {
  const { orgListings } = useListingStepper()
  const navigate = useNavigate()
  const { slug } = useParams()
  const location = useLocation()
  const createFreeProductListing = useCreateFreeProductListing()

  const hasOrgListing = orgListings?.some(({ association }) => slug === association.slug)

  const isService = location.pathname.includes('select-service')

  const onComplete = async (productId: number) => {
    if (hasOrgListing) {
      await createFreeProductListing(productId)
      toast.success(`Added ${isService ? 'service' : 'product'} to listing`)
      navigate(`..`)
    } else {
      navigate(`../product-plans/${slug}`, {
        state: { productId },
      })
    }
  }

  return (
    <Root>
      <BoxWrapper>
        <BackButton />
        <Title>Create Product</Title>
        <Subtitle>Add Basic info &amp; Complete after purchase.</Subtitle>
        <FormWrapper>
          <BasicInfoForm submitButtonText="Save & Continue" onComplete={onComplete} removePreview />
        </FormWrapper>
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

const Title = styled.h2`
  text-align: center;
  margin-bottom: 8px;
`

const Subtitle = styled.div`
  font-size: 16px;
  text-align: center;
  padding-bottom: 32px;
`

const FormWrapper = styled.div`
  max-width: 760px;
  margin: 0 auto;
`

export default CreateProduct
