import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'
import EcosystemPng from 'assets/marketing/lawfirm/ecosystem-marketplace-access.png'
import ComparePng from 'assets/marketing/lawfirm/compare-tool.png'
import RfpPng from 'assets/marketing/lawfirm/rfp.png'
import PremiumDataPng from 'assets/marketing/lawfirm/premium-data.png'
import StackManagementPng from 'assets/marketing/lawfirm/stack-management.png'
import SignInPng from 'assets/marketing/lawfirm/signIn.png'

const Features = () => {
  return (
    <Root>
      <Title>Subscribe today and receive</Title>
      <FeaturesBlock>
        <Feature>
          <FeatureContainer>
            <Image src={EcosystemPng} />
            <FeatureContent>
              <FeatureTitle>Ecosystem and marketplace access</FeatureTitle>
              <FeatureText>
                Discover new tools and services. Compare tools side-by-side. Learn about tech
                stacks.
              </FeatureText>
            </FeatureContent>
          </FeatureContainer>
        </Feature>
        <Feature>
          <FeatureContainer>
            <Image src={ComparePng} />
            <FeatureContent>
              <FeatureTitle>Compare tools side-by-side</FeatureTitle>
              <FeatureText>
                Easily highlight tool attributes side-by-side and share with your team.{' '}
              </FeatureText>
            </FeatureContent>
          </FeatureContainer>
        </Feature>
        <Feature>
          <FeatureContainer>
            <Image src={RfpPng} />
            <FeatureContent>
              <FeatureTitle>Simplify legal tech RFP</FeatureTitle>
              <FeatureText>
                Request demos and streamline RFP from providers in the marketplace with tools for
                use case capture.
              </FeatureText>
            </FeatureContent>
          </FeatureContainer>
        </Feature>
      </FeaturesBlock>
      <Title>Upgrade your Firm Today</Title>
      <FeaturesBlock>
        <Feature>
          <FeatureContainer>
            <Image src={PremiumDataPng} />
            <FeatureContent>
              <FeatureTitle>Premium Market Data</FeatureTitle>
              <FeatureText>
                Access real-time premium market data to better understand tool adoption.
              </FeatureText>
            </FeatureContent>
          </FeatureContainer>
        </Feature>
        <Feature>
          <FeatureContainer>
            <Image src={StackManagementPng} />
            <FeatureContent>
              <FeatureTitle>Internal Stack Share and stack management.</FeatureTitle>
              <FeatureText>
                Manage IT requests and help your team collaborate to identify the tools used by
                professionals at your firm.
              </FeatureText>
            </FeatureContent>
          </FeatureContainer>
        </Feature>
        <Feature>
          <FeatureContainer>
            <Image src={SignInPng} />
            <FeatureContent>
              <FeatureTitle>Control Access &amp; Track Utilization</FeatureTitle>
              <FeatureText>Enable secure single-sign and track app utilization data.</FeatureText>
            </FeatureContent>
          </FeatureContainer>
        </Feature>
      </FeaturesBlock>
    </Root>
  )
}

const Root = styled.div`
  padding: 60px 20px;
  background: #f2f2f2;

  ${breakpoint.mobile} {
    padding: 40px 20px;
  }
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 32px;
  font-size: 28px;
  letter-spacing: 0.02rem;

  ${breakpoint.mobile} {
    font-size: 20px;
  }
`

const FeaturesBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1024px;
  margin: 0 auto;
  align-items: stretch;
`

const Feature = styled.div`
  width: 33.3333%;
  padding: 0 20px;
  margin-bottom: 28px;

  ${breakpoint.mobile} {
    width: 100%;
    max-width: 420px;
    margin: 0 auto 28px;

    &::last-of-type {
      margin-bottom: 0;
    }
  }
`

const FeatureTitle = styled.h4`
  font-size: 18px;
  letter-spacing: 0.02rem;
`

const FeatureText = styled.div`
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.02rem;
`

const FeatureContent = styled.div`
  flex: 1 1 0;
  background: white;
  padding: 16px;
  border-top: 1px solid #ddd;
`

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const Image = styled.img`
  max-width: 100%;
`

export default Features
