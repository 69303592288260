import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLazyApi } from 'hooks/useApi'
import { useSetUserStatus } from 'hooks/graphql'
import { FullPageLoading } from 'components/PulsatingLoader'
import { UserStatus } from 'generated/graphql'

function PersonalCheckout() {
  const location = useLocation()
  const [createSubscription] = useLazyApi('stripe/personal/create-subscription')
  const [getStripeSession] = useLazyApi('stripe/personal/checkout')
  const { setUserStatus } = useSetUserStatus()
  const navigate = useNavigate()

  const locationState = location.state as any

  useEffect(() => {
    ;(async () => {
      const sessionId =
        getQueryParameter('session_id') || localStorage.getItem('stripeCheckoutSessionId')

      if (sessionId) {
        await createSubscription({ sessionId })

        await setUserStatus(UserStatus.CreatePersonalUser)
        navigate('/setup/personal-user')
      } else {
        const { url } = await getStripeSession({ priceId: locationState.priceId })
        window.location.replace(url)
      }
    })()
  }, [])

  return <FullPageLoading />
}

const getQueryParameter = (param: string) =>
  new URLSearchParams(document.location.search.substring(1)).get(param)

export default PersonalCheckout
