import React from 'react'
import styled from '@emotion/styled'
import { useLazyApi } from 'hooks/useApi'
import TestDriveForm from './TestDriveForm'
import { BoxWrapper } from 'components/theme'
import { Values } from './types'
import { useTestDriveTaxonomy } from './useTestDriveTaxonomy'
import { useViewer } from 'context/viewerContext'
import { useNavigate } from 'react-router'
import { useCreateTestDrive } from 'data/hooks'

const defaultValues: Values = {
  priceId: '',
  freeTrialDays: '',
  description: '',
  features: [],
  jobTitle: [],
  lawfirmType: [],
  practiceGroup: [],
  isVendorInVendor: false,
}

const TestDriveCreate = () => {
  const createTestDrive = useCreateTestDrive()
  const { accountId } = useViewer()
  const [getProduct] = useLazyApi('stripe/product/retrieve')
  const { updateTermToTaxonomy } = useTestDriveTaxonomy()
  const navigate = useNavigate()

  const onSubmit = async (values: Values) => {
    const {
      productId,
      priceId,
      freeTrialDays,
      features,
      jobTitle,
      lawfirmType,
      practiceGroup,
      description,
      isVendorInVendor,
    } = values

    if (!productId) return

    try {
      const result = await getProduct({ priceId, accountId })

      const stripeProductId = result?.product?.id
      const stripeDetail = { priceId, productId: stripeProductId }
      const details = {
        stripe: {
          test: stripeDetail,
          production: stripeDetail,
          freeTrialDays,
          productName: result?.product?.name,
          accountId,
        },
        description,
        features,
        isVendorInVendor,
      }

      const testDriveId = await createTestDrive.mutateAsync({
        productId,
        details,
      })

      if (!testDriveId) return

      await updateTermToTaxonomy(jobTitle, defaultValues?.jobTitle || [], testDriveId)
      await updateTermToTaxonomy(lawfirmType, defaultValues?.lawfirmType || [], testDriveId)
      await updateTermToTaxonomy(practiceGroup, defaultValues?.practiceGroup || [], testDriveId)

      navigate('..')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Root>
      <Title>Create Test Drive or Offer</Title>
      <BoxWrapper>
        <TestDriveForm defaultValues={defaultValues} onSubmit={onSubmit} />
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

const Title = styled.h1``

export default TestDriveCreate
