import GIF from 'assets/purple-logo.png'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

export default function PulsatingLoader({ className = null }: any) {
  return <Image className={className} src={GIF} />
}

export const PulsatingLoaderContainer = ({ className = null }: any) => {
  return (
    <Root className={className}>
      <Image src={GIF} />
    </Root>
  )
}

export const FullPageLoading = ({ withTopBar = false }) => (
  <Full withTopBar={withTopBar}>
    <Image src={GIF} />
  </Full>
)

export const FlexLoading = () => (
  <FlexLoadingContainer>
    <Image src={GIF} />
  </FlexLoadingContainer>
)

const Root = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
`

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: 0;
  }
`

const Image = styled.img`
  width: 94px;
  animation: ${fadeInOut} 2s infinite;
`

const Full = styled.div<{ withTopBar: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => (props.withTopBar ? 'calc(100vh - 155px)' : '100vh')};
`

const FlexLoadingContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
