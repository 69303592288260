import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'

function NumberCard({ title = '', number = 0 }: { title: string; number: number }) {
  return (
    <Root>
      <Card>
        <Title>{title}</Title>
        <Number>{number}</Number>
      </Card>
    </Root>
  )
}

const Root = styled.div`
  width: 33.3333%;
  padding: 10px;

  ${breakpoint.tablet} {
    width: 100%;
  }
`

const Card = styled.div`
  padding: 20px;
  border: 1px solid #aaa;
  border-radius: 6px;
  background: #fdfdfd;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Number = styled.div`
  font-size: 4.25vw;
  line-height: 1;
  font-weight: 500;
  color: #444;

  ${breakpoint.tablet} {
    font-size: 40px;
  }
`

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`

export default NumberCard
