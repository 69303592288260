import styled from '@emotion/styled'
import extractDomain from 'extract-domain'
import SignupHeader from 'components/SignupHeader'
import SelectOwnedProducts from './SelectOwnedProducts'
import InviteAdmin from './InviteAdmin'
import { useUserSetup } from 'context/userSetupContext'
import { useViewer } from 'context/viewerContext'

function CheckMatchedProducts() {
  const { viewer } = useViewer()
  const { amplitude } = useUserSetup()
  // const { updateFlowProps } = useAmplitudeSignUp()
  amplitude?.saveTrackedProps({ flow: 'product_matched' })

  return (
    <Root>
      <SignupHeader />
      <Top>
        <Title>We&apos;ve found a profile matching your domain</Title>
        <Domain>{extractDomain(viewer.email)}</Domain>
      </Top>

      <Bottom>
        <FirstHalf>
          <SelectOwnedProducts />
          <OrText>Or</OrText>
        </FirstHalf>
        <SecondHalf>
          <InviteAdmin />
        </SecondHalf>
      </Bottom>
    </Root>
  )
}

const Title = styled.h1`
  font-weight: 500;
`

const Domain = styled.div`
  font-weight: bold;
  color: #670bb7;
  font-size: 48px;
  opacity: 0.6;
`

const Root = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Top = styled.div`
  padding: 30px 0 100px;
  text-align: center;
`

const Bottom = styled.div`
  display: flex;
  width: 100%;
`

const FirstHalf = styled.div`
  flex: 1 1 0;
  padding: 20px 40px 20px 20px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background: #9013fe;
    width: 1px;
    right: 0;
    top: 0;
    bottom: 0;
  }
`

const OrText = styled.div`
  font-size: 18px;
  position: absolute;
  top: 50%;
  right: 0;
  background: white;
  transform: translate(50%, -50%);
  z-index: 1;
  text-transform: italic;
  padding: 10px 0;
  color: #9013fe;
  font-weight: bold;
`

const SecondHalf = styled.div`
  flex: 1 1 0;
  padding: 20px 20px 20px 40px;
`

export default CheckMatchedProducts
