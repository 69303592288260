import VendorUserForm from 'components/forms/VendorUserForm'
import { toast } from 'react-toastify'
import styled from '@emotion/styled'
import { useViewer } from 'context/viewerContext'

function EditVendorUser() {
  const { viewer } = useViewer()

  const onComplete = () => {
    toast.success('Your information has been updated')
  }

  const initialValues = {
    first: viewer.first,
    last: viewer.last,
    jobTitle: viewer.jobTitle || '',
    orgJobTitle: viewer.orgJobTitle || '',
    fileUrl: viewer.details.imageUrl,
    twitter: viewer.details.twitter || '',
    linkedin: viewer.details.linkedin || '',
  }

  return (
    <Root>
      <VendorUserForm
        onComplete={onComplete}
        initialValues={initialValues}
        submitButtonText="Update"
      />
    </Root>
  )
}

const Root = styled.div`
  max-width: 760px;
`

export default EditVendorUser
