import styled from '@emotion/styled'
import CreateOrganizationForm from './CreateOrganizationForm'

const CreateCompany = () => {
  const initialValues = {
    name: '',
    website: '',
    domain: '',
    logo: [],
  }

  return (
    <Root>
      <CreateOrganizationForm initialValues={initialValues} />
    </Root>
  )
}

const Root = styled.div``

export default CreateCompany
