import React from 'react'
import styled from '@emotion/styled'
import { Link, useParams } from 'react-router-dom'
import EditVendorStackshareForm, { FormValues } from './EditVendorStackshareForm'
import { useMutateOrg, useOrgById } from 'data/hooks'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { useMutateVendorStackshareLogos } from './useMutateVendorStackshareLogos'
import { useMutateTermToVendorStackshare } from './useMutateTermToVendorStackshare'

function EditVendorStackshare() {
  const { orgId } = useParams()
  const { company: vendor } = useOrgById(orgId)
  const mutateVendorStackshareLogos = useMutateVendorStackshareLogos(orgId)
  const mutateTermToVendorStackshare = useMutateTermToVendorStackshare(orgId)
  const mutateOrg = useMutateOrg()

  const queryClient = useQueryClient()

  if (!vendor) return null

  const initialValues: FormValues = {
    stackshareDemoRequest: vendor.details?.stackshareDemoRequest || '',
    terms: vendor.termToVendorStackshares
      .sort((a, b) => a.order - b.order)
      .map(({ term, id }) => ({
        termToVendorStackshareId: id,
        termId: term.id,
        taxonomyId: term.taxonomyId,
      })),
    companies: vendor.vendorStackshareLogos
      .sort((a, b) => a.order - b.order)
      .map(({ id, logo, name }) => ({
        logoId: id,
        logo,
        name,
      })),
  }

  const onSubmit = async (data: FormValues) => {
    const { stackshareDemoRequest, companies, terms } = data
    if (!orgId) return

    await mutateOrg.mutateAsync({
      orgId,
      details: {
        ...vendor.details,
        stackshareDemoRequest,
      },
    })

    await mutateTermToVendorStackshare(initialValues.terms, terms)
    await mutateVendorStackshareLogos(initialValues.companies, companies)

    queryClient.invalidateQueries(['company', orgId])
    queryClient.invalidateQueries(['vendorStackshare'])

    toast.success('Vendor Stackshare updated')
  }

  return (
    <Root>
      <Top>
        <Link to="../list">
          <strong>Back</strong>
        </Link>
        <a
          href={`https://ecosystem.theoremlegal.com/stackshare/vendor/${vendor.slug}`}
          target="_blank"
          rel="noreferrer"
        >
          <strong>View Vendor Stackshare Page</strong>
        </a>
      </Top>
      <h2>Edit Vendor Stackshare - {vendor.name}</h2>
      <EditVendorStackshareForm initialValues={initialValues} onSubmit={onSubmit} />
    </Root>
  )
}

const Root = styled.div``

const Top = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`
export default EditVendorStackshare
