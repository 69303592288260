import axios from 'axios'
import { API_BASE } from '../static'

const LOCAL_DOMAIN = 'http://localhost:3001'

export const securePostRequest = async (url: string, body?: any, isLocal = false) => {
  try {
    const response = await axios.post(`${isLocal ? LOCAL_DOMAIN : API_BASE}/${url}`, body)

    return response.data ? response.data.result : null
  } catch (e) {
    console.error({ e })
    if (e.response) {
      console.error(e)
      const { error } = e.response.data
      throw error.error_description ? new Error(error.error_description) : new Error(error)
    }
    throw new Error('There was an error with your request. Please try again')
  }
}
