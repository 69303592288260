import React from 'react'
import styled from '@emotion/styled'
import ImagePng from './theorem-cloud.png'
import { useAuth0 } from '@auth0/auth0-react'
import { breakpoint } from 'services/theming'
import { logEvent } from '@theoremlts/theorem-design'

type Props = {
  executeScroll: () => void
}

const PlanHeader: React.FC<Props> = ({ executeScroll }) => {
  const { logout } = useAuth0()

  return (
    <Root>
      <Wrapper>
        <Left>
          <Title>Join the World of Legal Tech</Title>
          <SubTitle>Get Started Free</SubTitle>
          <Button onClick={() => executeScroll()}>Join Now</Button>
          <Text>
            Get started with a single user account &amp; preview premium team features.{' '}
            <a onClick={() => {
              logEvent('log_out')
              logout()
            }}>Log out</a>
          </Text>
        </Left>
        <Right>
          <Image src={ImagePng} />
        </Right>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  padding: 0 20px;

  a {
    font-weight: 500;
  }
`

const Text = styled.p`
  font-size: 16px;
  padding-top: 20px;
  max-width: 400px;
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 600px;
  margin: 0 auto;
  max-width: 1140px;
  align-items: center;

  ${breakpoint.mobile} {
    height: auto;
  }
`

const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0;
`

const SubTitle = styled.p`
  font-size: 28px;
  margin-bottom: 16px;
`

const Button = styled.a`
  display: inline-block;
  padding: 12px 28px;
  color: white;
  background: #ff403f;
  border-radius: 6px;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  transition: background 250ms;
  cursor: pointer;

  &:hover {
    color: white;
    background: #498de6;
  }
`

const Left = styled.div`
  width: 40%;
  padding-left: 20px;
  ${breakpoint.mobile} {
    width: 100%;
    order: 2;
    padding: 20px 0 40px;
  }
`

const Right = styled.div`
  width: 60%;

  ${breakpoint.mobile} {
    width: 100%;
    order: 1;
  }
`

const Image = styled.img`
  max-width: 700px;
  width: 100%;
`

export default PlanHeader
