import { OrganizationSubtype, OrganizationType } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from '../useOrg'

interface Variables {
  type: OrganizationType
  subtype: OrganizationSubtype
}
export const useChangeOrgType = () => {
  const request = useGql()
  const queryClient = useQueryClient()
  const { organization } = useOrg()

  return useMutation(
    async (variables: Variables) => {
      if (!organization) return

      const { type, subtype } = variables

      await request(UPDATE_ORG_TYPE, { id: organization.id, type, subtype })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

const UPDATE_ORG_TYPE = gql`
  mutation UpdateOrgType($id: UUID!, $type: OrganizationType!, $subtype: OrganizationSubtype) {
    updateOrganization(input: { patch: { subtype: $subtype, type: $type }, id: $id }) {
      organization {
        id
      }
    }
  }
`
