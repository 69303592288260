import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { GqlOrgInTermToVendorStacksharesQuery } from 'generated/graphql'

export const useGetAllVendorStackshares = () => {
  const request = useGql()
  const { data, ...other } = useQuery(['vendorStackshare'], async () => {
    const response = await request<GqlOrgInTermToVendorStacksharesQuery>(ALL_VENDOR_STACKSHARES)

    const orgs = response.termToVendorStackshares.map(({ organization }) => organization)

    const uniqueOrgIds = new Set()
    const uniqueOrgs = orgs.filter((obj) => {
      if (!uniqueOrgIds.has(obj.id)) {
        uniqueOrgIds.add(obj.id)
        return true
      }

      return false
    })

    console.log(uniqueOrgs)

    return uniqueOrgs
  })

  return { vendors: data, ...other }
}

const ALL_VENDOR_STACKSHARES = gql`
  query OrgInTermToVendorStackshares {
    termToVendorStackshares {
      organizationId
      organization {
        id
        name
        slug
        domain
        details
      }
    }
  }
`
