import styled from '@emotion/styled'
import { useOrgById } from 'data/hooks'
import UpdateSearchTermsForm from './UpdateSearchTermsForm'
import { Term } from 'data/types'
import { toast } from 'react-toastify'
import { useParams } from 'react-router'

const parseTermIds = (terms: Term[], taxonomyId: number) => {
  return terms.filter((term) => taxonomyId === term.taxonomyId).map((term) => term.id)
}

const EditSearchTerms = () => {
  const { orgId } = useParams()
  const { company, isLoading } = useOrgById(orgId)

  const terms = company?.termToOrganizations?.map(({ term }) => term)

  const setInitialValues = () => {
    if (!terms) return
    const practiceGroups = parseTermIds(terms, 13)

    const companyType = parseTermIds(terms, 17)
    const employeeSize = parseTermIds(terms, 19)

    const countries = parseTermIds(terms, 3)
    const languages = parseTermIds(terms, 4)

    const initialValues = {
      practiceGroups,
      countries,
      languages,
      companyType,
      employeeSize: employeeSize[0],
    }

    return initialValues
  }

  const onComplete = () => {
    toast.success('Company updated')
  }

  if (!terms || isLoading) return null

  return (
    <Root>
      <UpdateSearchTermsForm
        orgId={orgId}
        initialValues={setInitialValues()}
        onComplete={onComplete}
      />
    </Root>
  )
}

const Root = styled.div``

export default EditSearchTerms
