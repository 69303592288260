import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Popup } from 'semantic-ui-react'
import { MainTitle, BoxWrapper } from 'components/theme'
import { breakpoint } from 'services/theming'
import Tooltip from 'components/theme/Tooltip'
import _ from 'lodash'
import AppBoxTooltip from '../AppBoxTooltip'
import YourAppsPopup from 'views/associations/AssociationApps/YourAppsPopup'
import { useViewer } from 'context/viewerContext'

function YourApps() {
  const { viewer } = useViewer()

  return (
    <Root>
      <UserApps>
        <Title>
          Your Apps
          <YourAppsPopup
            text={
              'Add the tools you use to your profile for quick access. This is your personal stack. It will be shared with organizations you are a part of and used to make better recommendations for you throughout our system.'
            }
          />
        </Title>
        <BoxWrapper>
          <AppsContainer>
            {viewer.techstack &&
              viewer.techstack.map((app) => (
                <Tooltip
                  key={`logo-box-${app.id}`}
                  placement="right"
                  trigger="hover"
                  tooltip={<AppBoxTooltip name={app.name} />}
                >
                  <OutlinkBox
                    href={`https://ecosystem.theoremlegal.com/product/${app.slug}`}
                    target="_blank"
                  >
                    <LogoImage
                      src={
                        _.get(app, 'details.squareLogoUrl', false) ||
                        _.get(app, 'details.logoUrl', '')
                      }
                      alt={app.name}
                    />
                  </OutlinkBox>
                </Tooltip>
              ))}
            <Popup
              trigger={
                <ProductsLink to="personal/edit-techstack">
                  <PlusWrapper>+</PlusWrapper>
                </ProductsLink>
              }
            >
              <Popup.Content>Edit my Stack</Popup.Content>
            </Popup>
          </AppsContainer>
        </BoxWrapper>
      </UserApps>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  padding-bottom: 60px;
`

const Title = styled(MainTitle)`
  margin-bottom: 20px !important;
  font-size: 24px;
  letter-spacing: 0;
  display: flex;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto 20px !important;

  ${breakpoint.mobile} {
    text-align: center;
  }
`

const AppsContainer = styled.div`
  display: grid;
  grid-gap: 40px 40px;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  max-width: 860px;

  ${breakpoint.mobile} {
    grid-gap: 30px 30px;
  }
`

const OutlinkBox = styled.a`
  display: block;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  transition: box-shadow 250ms, background 250ms;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  ${breakpoint.mobile} {
    margin: 0;
  }
`

const ProductsLink = styled(Link)`
  position: relative;
  border: 2px dashed #d3ced6;
  border-radius: 4px;
  display: block;
  margin: 0;

  &:before {
    content: '';
    padding-top: 100%;
    width: 100%;
    display: block;
  }

  &:hover {
    color: #8f5dd2;
  }
`

const PlusWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 30px;
  font-weight: 300;
  color: #8f5dd2;
  line-height: 1;
  transform: translate(-50%, -50%);
`

const LogoImage = styled.img`
  max-width: 100%;
`

const UserApps = styled.div`
  margin-bottom: 60px;
`

export default YourApps
