import { useQuery } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlTestDriveListQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useTestDrives = () => {
  const request = useGql()
  const { organization } = useOrg()
  const { data, ...other } = useQuery(['testDrive'], async () => {
    const response = await request<GqlTestDriveListQuery>(TEST_DRIVE_LIST, {
      organizationId: organization?.id,
    })

    return response.testDrives
  })

  return {
    testDrives: data,
    ...other,
  }
}

export const TEST_DRIVE_LIST = gql`
  query TestDriveList($organizationId: UUID!) {
    testDrives(condition: { organizationId: $organizationId }) {
      details
      id
      createdAt
      updatedAt
      product {
        details
        id
        name
        slug
        excerpt
        termToProducts {
          term {
            id
            name
            slug
            taxonomyId
          }
        }
      }
      termToTestDrives {
        term {
          name
          id
          slug
        }
      }
    }
  }
`
