import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useSwitchOrg = () => {
  const { viewer } = useViewer()
  const queryClient = useQueryClient()
  const request = useGql()

  return useMutation(
    (organizationId: string) => {
      return request(SET_CURRENT_ORGANIZATION, { userId: viewer.id, organizationId })
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries()
      },
    }
  )
}

export const SET_CURRENT_ORGANIZATION = gql`
  mutation SetCurrentOrganization($userId: Int!, $organizationId: UUID!) {
    updateUser(input: { patch: { currentOrganization: $organizationId }, id: $userId }) {
      clientMutationId
    }
  }
`
