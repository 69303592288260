import React, { useState } from 'react'
import styled from '@emotion/styled'
import Modal from 'components/Modal'
import { Button } from 'components/theme'
import { useLazyApi } from 'hooks/useApi'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  selectedUser?: number | string
  showModal: boolean
  toggleModal: () => void
  onSubmit?: () => void
}
const LawfirmUpgradeRequiredModal: React.FC<Props> = ({
  selectedUser,
  showModal,
  toggleModal,
  onSubmit,
}) => {
  const [addRequestTeammate] = useLazyApi('stripe/organization/add-request-teammate')
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)

  const addUser = async () => {
    if (loading) return

    try {
      setLoading(true)
      await addRequestTeammate({ memberUserId: selectedUser })
      queryClient.invalidateQueries(['org'])
      onSubmit && onSubmit()
      toggleModal()
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Modal toggleModal={toggleModal} isShowing={showModal}>
      <Root>
        <Description>Please confirm that you want to allow this user.</Description>
        <UpgradeButton onClick={addUser} loading={loading} disabled={loading}>
          Confirm
        </UpgradeButton>
      </Root>
    </Modal>
  )
}

const Root = styled.div`
  text-align: center;
`

const Description = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 24px;
`

const UpgradeButton = styled(Button)`
  background: #ff8d00;
  border-color: #ff8d00;
  color: white;

  &:disabled,
  &[disabled] {
    background: transparent;

    &:hover {
      background: transparent;
      cursor: not-allowed;
    }
  }
`

export default LawfirmUpgradeRequiredModal
