import { MemberStatus } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation } from '@tanstack/react-query'

interface Variables {
  associationId: string
  organizationId: string
  status: MemberStatus
}
export const useCreateAssocToOrg = () => {
  const request = useGql()

  return useMutation(async (variables: Variables) => {
    await request(CREATE_ASSOC_TO_ORG, variables)
  })
}

const CREATE_ASSOC_TO_ORG = gql`
  mutation CreateAssociationToOrganization(
    $associationId: UUID!
    $organizationId: UUID!
    $status: MemberStatus
  ) {
    upsertAssociationToOrganization(
      input: {
        associationToOrganization: {
          associationId: $associationId
          organizationId: $organizationId
          status: $status
        }
      }
    ) {
      clientMutationId
    }
  }
`
