import React, { useState } from 'react'
import { PulsatingLoaderContainer } from 'components/PulsatingLoader'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { Dropdown } from 'semantic-ui-react'
import { Formik, Form } from 'formik'
import TextInput from 'components/theme/form/TextInput'
import { extractPictureUrl } from 'services/image'
import { VENDOR_TYPE } from 'model/static'
import Button from 'components/theme/Button'
import Label from 'components/theme/form/Label'
import styled from '@emotion/styled'
import * as yup from 'yup'
// import { CREATE_LOCATION, UPDATE_ORGANIZATION } from 'model/graphql/mutations/organization'
// import { UPDATE_USER_LOCATION } from 'model/graphql/mutations/user'
import { useLocationForm } from 'components/forms/LocationForm2'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import _ from 'lodash'
import { MemberStatus, OrganizationSubtype, OrganizationType } from 'generated/graphql'
import { useAppMetadata, useCreateAssocToOrg, useCreateOrg, useUpdateOrg } from 'data/hooks'
import { useAuth0 } from '@auth0/auth0-react'

const validationSchema = yup.object().shape({
  companyName: yup.string().trim().required('Required'),
  vendorType: yup.string().trim().required('Required'),
  fileUrl: yup.mixed(),
})

function CreateVendorForm({ onComplete }: { onComplete: (vendorId: string) => void }) {
  const { organization } = useOrg()
  const updateOrg = useUpdateOrg()
  const { viewer } = useViewer()
  const [loading, setLoading] = useState(false)
  const { LocationForm } = useLocationForm({})
  const { data: appMetadata } = useAppMetadata()
  const createVendor = useCreateOrg()
  const createAssocToOrg = useCreateAssocToOrg()
  const { user } = useAuth0()
  const initialValues = {
    companyName: organization ? organization.name || appMetadata?.noviOrg?.name || '' : '',
    vendorType: 'Software Provider',
    fileUrl: _.get(organization, 'products[0].details.squareLogoUrl', []),
  }

  console.log({ user })

  const onSubmit = async (values: any) => {
    setLoading(true)
    const { fileUrl, companyName, vendorType } = values

    const logo = await extractPictureUrl(fileUrl)

    const details = { vendorType, logo }

    let vendorId
    if (viewer.currentOrganization) {
      vendorId = viewer.currentOrganization

      await updateOrg.mutateAsync({
        name: companyName,
        details,
      })
    } else {
      vendorId = await createVendor.mutateAsync({
        name: companyName,
        type: OrganizationType.Vendor,
        subtype: OrganizationSubtype.Software,
        details: details,
      })
    }

    if (appMetadata.assocOrg) {
      await createAssocToOrg.mutateAsync({
        associationId: appMetadata.assocOrg.id,
        organizationId: vendorId,
        status: MemberStatus.Active,
      })
    }

    // if (!organization || organization?.locations.length === 0) {
    //   const locationId = await createLocationData({ state, city, country }, vendorId)
    //   await updateUserLocation(locationId) // maybe add the location ID in the userToOrganization table
    // }

    onComplete(vendorId)
  }

  // const updateUserLocation = async (locationId: number) => {
  //   await updateUser({
  //     variables: {
  //       locationId,
  //       id: viewer.id,
  //     },
  //   })
  // }

  // const createLocationData = async ({ city, state, country }: any, organizationId: string) => {
  //   const { data } = await createLocation({
  //     variables: {
  //       city,
  //       state,
  //       country,
  //       organizationId,
  //       isHeadquarter: true,
  //     },
  //   })

  //   const locationId = data.createLocation.location.id

  //   return locationId
  // }

  if (loading)
    return (
      <LoadingContainer>
        <PulsatingLoaderContainer />
      </LoadingContainer>
    )

  // const showLocation = !organization || organization?.locations.length === 0

  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isValid, isSubmitting }) => (
          <Form>
            <FormField>
              <UploadSingleAsset label="Logo" name="fileUrl" selectedImage={values.fileUrl}>
                Upload Logo
              </UploadSingleAsset>
            </FormField>
            <FormField>
              <TextInput
                label="Company Name"
                placeholder="Your Name"
                name="companyName"
                icon="building"
                required
              />
            </FormField>
            <FormField>
              <Label required>Vendor Type</Label>
              <Dropdown
                fluid
                selection
                options={VENDOR_TYPE}
                name="vendorType"
                value={values.vendorType}
                onChange={(_e, { value }) => setFieldValue('vendorType', value)}
              />
            </FormField>
            {false && (
              <>
                <hr />
                <LocationWrapper>
                  <LocationTitle>Your Vendor Location:</LocationTitle>
                  <LocationForm />
                </LocationWrapper>
              </>
            )}
            <FormField>
              <ButtonContainer>
                <Button disabled={!isValid || isSubmitting} loading={isSubmitting} type="submit">
                  Create Vendor
                </Button>
              </ButtonContainer>
            </FormField>
          </Form>
        )}
      </Formik>
    </FormContainer>
  )
}

const FormContainer = styled.div`
  max-width: 760px;
  width: 100%;
  padding: 40px;
  margin: 0 auto;
`

const LoadingContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FormField = styled.div`
  margin-bottom: 20px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

const LocationWrapper = styled.div``

const LocationTitle = styled.div`
  color: #555;
  font-weight: 500;
  font-size: 16px;
  margin: 12px 0;
`

export default CreateVendorForm
