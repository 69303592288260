import styled from '@emotion/styled'
import { Label, TextInput, FormField, Dropdown } from 'components/theme/form'
import { Button } from 'components/theme'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { Formik } from 'formik'
import { extractPictureUrl } from 'services/image'
import { VENDOR_TYPE } from 'model/static'
import * as yup from 'yup'
import { useOrg } from 'data/organization/useOrg'
import { useMutateOrg } from 'data/organization/useMutateOrg'

const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Required'),
  logo: yup.mixed(),
  vendorType: yup.string().trim().required('Required'),
})

const defaultValues = {
  name: '',
  website: '',
  logo: [],
  businessStatus: '',
  companySize: '',
  vendorType: 'Software Provider',
  yearFounded: null,
}

function VendorForm({ initialValues = defaultValues, onComplete, className = null }: any) {
  const { vendor } = useOrg()
  const mutateOrg = useMutateOrg()

  const onSubmit = async ({
    name,
    website,
    logo,
    businessStatus,
    companySize,
    yearFounded,
    vendorType,
  }: any) => {
    const logoUrl = await extractPictureUrl(logo)

    await mutateOrg.mutateAsync({
      name,
      details: {
        ...vendor?.details,
        website,
        logo: logoUrl,
        businessStatus,
        companySize,
        yearFounded,
        vendorType,
      },
    })

    onComplete()
  }

  return (
    <Root className={className}>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, isValid, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormField>
              <UploadSingleAsset label="Company Logo" name="logo" selectedImage={values.logo}>
                Add Company Logo
              </UploadSingleAsset>
            </FormField>
            <FormField>
              <TextInput label="Company Name" name="name" placeholder="My Company Inc." required />
            </FormField>
            <FormField>
              <Label required>Company Type</Label>
              <Dropdown
                fluid
                selection
                options={VENDOR_TYPE}
                setFieldValue={setFieldValue}
                name="vendorType"
                value={values.vendorType}
                onChange={(_e: any, { value }: any) => setFieldValue('vendorType', value)}
              />
            </FormField>
            <FormField>
              <Dropdown
                fluid
                selection
                label="Business Status"
                setFieldValue={setFieldValue}
                options={businessStatusOptions}
                value={values.businessStatus}
                name="businessStatus"
                placeholder="Select Business Status"
              />
            </FormField>
            <FormField>
              <Dropdown
                fluid
                selection
                label="Company Size"
                setFieldValue={setFieldValue}
                options={companySizeOptions}
                value={values.companySize}
                name="companySize"
                placeholder="Select Company Size"
              />
            </FormField>
            <FormField>
              <Dropdown
                fluid
                selection
                label="Year Founded"
                setFieldValue={setFieldValue}
                options={getYearRangeOptions()}
                value={values.yearFounded}
                name="yearFounded"
                placeholder="Select year"
              />
            </FormField>
            <ButtonContainer>
              <Button disabled={isSubmitting || !isValid} loading={isSubmitting}>
                Update
              </Button>
            </ButtonContainer>
          </form>
        )}
      </Formik>
    </Root>
  )
}

const businessStatusOptions = [
  {
    text: 'Product in Beta Test',
    value: 'Product in Beta Test',
  },
  {
    text: 'Product Development',
    value: 'Product Development',
  },
  {
    text: 'Profitable',
    value: 'Profitable',
  },
  {
    text: 'Generating Revenue',
    value: 'Generating Revenue',
  },
  {
    text: 'Generating Revenue/Not Profitable',
    value: 'Generating Revenue/Not Profitable',
  },
  {
    text: 'Startup',
    value: 'Startup',
  },
  {
    text: 'Stealth',
    value: 'Stealth',
  },
  {
    text: 'Out of Business',
    value: 'Out of Business',
  },
]

export const companySizeOptions = [
  {
    text: '1 - 5',
    value: '1 - 5',
  },
  {
    text: '6 - 10',
    value: '6 - 10',
  },
  {
    text: '11 - 25',
    value: '11 - 25',
  },
  {
    text: '26 - 50',
    value: '26 - 50',
  },
  {
    text: '51 - 100',
    value: '51 - 100',
  },
  {
    text: '100+',
    value: '100+',
  },
  {
    text: '500+',
    value: '500+',
  },
  {
    text: '1000+',
    value: '1000+',
  },
]

const getYearRangeOptions = () => {
  const date = new Date()
  const oldestYear = 1900
  const currentYear = date.getFullYear()

  return Array(currentYear - oldestYear + 1)
    .fill(undefined)
    .map((_, i) => ({
      text: oldestYear + i,
      value: oldestYear + i,
    }))
    .reverse()
}

const Root = styled.div`
  max-width: 760px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

export default VendorForm
