import React from 'react'
import styled from '@emotion/styled'

function ExcerptDescription({ description = '', maxLength }: { description: string, maxLength: number}) {
  const descriptionCharCount = description.length
  const lengthDifference = maxLength - descriptionCharCount

  return (
    <Root>
      You have {lengthDifference >= 0 ? <Good>{lengthDifference}</Good> : <Bad>0</Bad>} characters
      remaining
    </Root>
  )
}

const Root = styled.div`
  white-space: nowrap;
`

const Good = styled.div`
  display: inline;
  font-weight: 500;
  color: ${(props) => props.theme.color.primaryBlue};
`

const Bad = styled.div<{ theme?: any }>`
  display: inline;
  font-weight: 500;
  color: ${(props) => props.theme.color.error};
`

export default React.memo(ExcerptDescription)
