import React from 'react'
import styled from '@emotion/styled'

interface SwitchProps {
  isCheck: boolean
  onSwitch?: (isCheck: boolean) => void
  disabled?: boolean
}

const Switch = ({ isCheck, onSwitch, disabled }: SwitchProps) => {
  const toggleCheck = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return
    if (onSwitch) onSwitch(target.checked)
  }

  return (
    <>
      <Wrapper isChecked={isCheck} disabled={Boolean(disabled)}>
        <CheckboxInput type="checkbox" checked={isCheck} onChange={toggleCheck} />
        <SwitchToggle isChecked={isCheck} />
      </Wrapper>
    </>
  )
}

const SwitchToggle = styled.div<{ isChecked: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 400px;
  background: ${(props) => (props.isChecked ? '#4cd0d1' : '#b69bc7')};
  transform: translateX(${(props) => (props.isChecked ? '20px' : '0')});
  transition: background 350ms, transform 250ms;
`

const Wrapper = styled.label<{ isChecked: boolean; disabled: boolean }>`
  width: 48px;
  display: block;
  background: ${(props) => (props.isChecked ? '#ececec' : '#dadada')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 400px;
  border: 2px solid #888;
  padding: 2px;
  transition: background 250ms;

  &:hover {
    background: #ccc;

    ${SwitchToggle} {
      background: ${(props) => (props.isChecked ? '#1e9d9e' : '#a273bf')};
    }
  }
`

const CheckboxInput = styled.input`
  display: none;
`

export default Switch
