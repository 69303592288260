import React from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'
import { css } from '@emotion/react'

interface Props {
  onCancel: () => void
  onConfirm: () => void
  children: React.ReactNode
}
const ConfirmModal = ({ children, onCancel, onConfirm }: Props) => {
  return ReactDOM.createPortal(
    <Root>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <Content>{children}</Content>
        <ButtonWrapper>
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
        </ButtonWrapper>
      </ContentWrapper>
    </Root>,
    document.body
  )
}

const Root = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 9000;
`

const Content = styled.div`
  margin-bottom: 12px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const buttonStyles = css`
  background: transparent;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 8px 16px;
  transition: color 200ms, background 200ms;
`

const ConfirmButton = styled.button`
  ${buttonStyles}
  border: 2px solid #9013FE;
  color: #9013fe;
  margin-left: 12px;

  &:hover {
    color: white;
    background: #9013fe;
  }
`

const CancelButton = styled.button`
  ${buttonStyles}
  border: 2px solid transparent;
  color: #484848;

  &:hover {
    background: #efefef;
  }
`

const ContentWrapper = styled.div`
  position: relative;
  background: white;
  border-radius: 5px;
  padding: 32px;
  width: 400px;
  max-width: 100vw;
  max-height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${breakpoint.mobile} {
    padding: 0 0 60px;
  }
`

export default ConfirmModal
