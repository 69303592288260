import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { getLocalTime } from 'services/helper'
import { Member } from './types'
import Avatar from 'components/user/Avatar'
import InviteModal from 'components/modals/InviteModal'
import { LineItemTable } from '@theoremlts/theorem-design'
import ActionMenu from './ActionMenu'
import { useViewer } from 'context/viewerContext'
import { useAllTeam } from 'data/hooks'
import { UserRole } from 'generated/graphql'

export interface ParsedMember extends Member {
  searchableInfo: string[]
  organizations: string[]
}

const Team = () => {
  const { isAdmin } = useViewer()
  const { team, isLoading } = useAllTeam({ role: UserRole.SuperAdmin })

  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [query, setQuery] = useState<string>('')
  const [visibleTeam, setVisibleTeam] = useState<ParsedMember[]>([])
  const [allTeam, setAllTeam] = useState<ParsedMember[]>([])

  const setTeamMembers = (setTeam = team) =>
    setTeam?.map((member: any) => {
      return {
        id: member.user?.id,
        organizations: member.user?.usersToOrganizations.map(
          ({ organizationId }: { organizationId: string }) => organizationId
        ),
        role: member.role,
        status: member.status,
        email: member.user?.email || '',
        jobTitle: member.user?.jobTitle || '',
        name:
          member.user?.first || member.user?.last
            ? `${member.user?.first || ''} ${member.user?.last || ''}`
            : null,
        headshot: member.user?.details?.imageUrl || '',
        activity: member.user?.updatedAt ? getLocalTime(member.user?.updatedAt) : '',
        user: member.user,
        searchableInfo: `${member.user?.id || ''}, ${member.status}, ${member.user?.email || ''}, ${
          member.user?.jobTitle || ''
        }, ${member.user?.first || ''}, ${member.user?.last || ''}}`
          .toLowerCase()
          .split(', '),
      }
    }) || []

  useEffect(() => {
    if (!team || isLoading) return
    setQuery('')
    const parsedTeam = setTeamMembers().reduce((admins: ParsedMember[], member: ParsedMember) => {
      if (admins.every((admin) => admin.id !== member.id)) admins.push(member)
      return admins
    }, [] as ParsedMember[])
    setAllTeam(parsedTeam)
  }, [JSON.stringify(team), isLoading])

  const splitQuery = (query: string) => {
    const checkForHangingComma = (queries: string[]) => {
      const i = queries.length - 1
      if (queries[i][queries[i].length - 1] === ',') {
        queries[i] = queries[i].slice(0, queries[i].length - 1)
      }
    }

    let queries = [] as string[]
    if (query.includes(', ')) {
      queries = query.split(', ')
    } else if (query.includes(' ')) {
      queries = query.split(' ')
    } else {
      queries = [query]
    }
    checkForHangingComma(queries)
    queries = queries.map((query) => query.toLowerCase())
    return queries
  }

  useEffect(() => {
    if (query) {
      const queries = splitQuery(query)
      const results = allTeam.filter(({ searchableInfo }) =>
        queries.every((query) => searchableInfo.some((value) => value.includes(query)))
      )
      setPage(1)
      setVisibleTeam(results)
    } else {
      setVisibleTeam(allTeam)
    }
  }, [query, allTeam])

  const printTeam = () =>
    visibleTeam
      .map((member: any, index: number) => (
        <TableRow key={index}>
          <PersonItem>
            <Avatar user={member.user} />
            <PersonDetails>
              {member.name ? (
                <>
                  <PersonName>{member.name}</PersonName>
                  <PersonEmail>{member.email}</PersonEmail>
                </>
              ) : (
                <PersonName>{member.email}</PersonName>
              )}
            </PersonDetails>
          </PersonItem>
          <TypeItem>SUPER ADMIN</TypeItem>
          <ActiveItem>{member.activity}</ActiveItem>
          <StatusItem emphasis={member.status !== 'ACTIVE'}>{member.status}</StatusItem>
          {isAdmin && (
            <td>
              <ActionMenu
                isAdmin
                member={member}
                onConfirmReject={rejectRequestUser}
                onConfirmAllow={addRequestUser}
              />
            </td>
          )}
        </TableRow>
      ))
      .slice((page - 1) * 25, (page - 1) * 2 + 25)

  const addRequestUser = () => {
    console.log('add')
    // if (refetch) refetch({ organizationId: organization?.id })
  }

  const rejectRequestUser = () => {
    console.log('add')
    // if (refetch) refetch({ organizationId: organization?.id })
  }

  const onInviteUsers = () => {
    console.log('add')
    // if (refetch) refetch({ organizationId: organization?.id })
  }

  const toggleInviteModal = () => {
    setInviteModalOpen((prevVal) => !prevVal)
  }

  return (
    <Root>
      <LineItemTable headerItems={['SUPER ADMINS', ' ', ' ', ' ', ' ']} lineItems={printTeam()} />
      <InviteModal
        open={inviteModalOpen}
        onModalClose={toggleInviteModal}
        onInvite={onInviteUsers}
      />
    </Root>
  )
}

const Root = styled.div`
  margin-top: 10px;
`

const TableRow = styled.tr``

const PersonItem = styled.td`
  display: flex;
  align-items: center;
`

const TypeItem = styled.td``

const ActiveItem = styled.td``

const StatusItem = styled.td<{ emphasis?: boolean }>`
  ${({ emphasis }) => emphasis && 'font-weight: bold; color: #9013FE;'}
`

const PersonDetails = styled.div`
  padding-left: 8px;
`

const PersonName = styled.div`
  font-weight: 500;
`

const PersonEmail = styled.div`
  font-size: 12px;
  color: #6f7782;
`

export default Team
