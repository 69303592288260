import { GqlViewerQuery, UserRole } from 'generated/graphql'
import { parsePersonalTechstack } from './parsePersonalTechstack'
import { parseTermsToViewer } from './parseTermToViewer'
import { parseViewerTechstack } from './parseViewerTechstack'
import { parseViewerToOrg } from './parseViewerToOrg'
import { Viewer, ViewerToOrganization } from './viewerTypes'
import { Term } from 'data/types'

export const parseViewer = (data: GqlViewerQuery): Viewer | undefined => {
  if (!data.viewer) return

  const {
    id,
    first,
    last,
    email,
    jobTitle,
    status,
    details,
    appMetadata,
    location,
    currentOrganization,
    termToUsers,
    usersToOrganizations,
    usersToTechstacks,
    personalTechstacks,
    testMode,
  } = data.viewer

  const practiceGroups = termToUsers
    .filter(({ term }) => term?.taxonomyId === 13)
    .map(({ term }) => ({ id: term?.id, name: term?.name })) as Term[]
  const industry = termToUsers
    .filter(({ term }) => term?.taxonomyId === 20)
    .map(({ term }) => ({ id: term?.id, name: term?.name })) as Term[]

  const organizations = parseViewerToOrg(usersToOrganizations)
  const currentOrganizationId =
    !currentOrganization && organizations.length > 0 ? organizations[0].id : currentOrganization

  const userRole = parseUserRole(currentOrganizationId, organizations)
  const techstack = parseViewerTechstack(usersToTechstacks, currentOrganizationId)
  const orgJobTitle = parseOrgJobTitle(organizations, currentOrganization)
  const personalTechstack = parsePersonalTechstack(personalTechstacks)
  const terms = parseTermsToViewer(termToUsers)

  return {
    id,
    first,
    last,
    name: `${first} ${last}`,
    email,
    jobTitle,
    orgJobTitle,
    role: userRole,
    status,
    location,
    organizations,
    practiceGroups,
    industry,
    currentOrganization: currentOrganizationId,
    currentOrganizationId,
    techstack,
    terms,
    details,
    appMetadata,
    personalTechstack,
    testMode,
  }
}

const parseUserRole = (
  currentOrganizationId: string | undefined,
  organizations: ViewerToOrganization[]
) => {
  if (organizations.length === 0) return

  return currentOrganizationId
    ? organizations.filter((organization) => organization.id === currentOrganizationId)[0]?.role
    : organizations[0]?.role || UserRole.User
}

const parseOrgJobTitle = (
  organizations: ViewerToOrganization[],
  currentOrganization?: string
): number => {
  if (!currentOrganization) return 0

  const foundOrg = organizations.find((organization) => organization.id === currentOrganization)

  return foundOrg?.jobTitle || 0
}
