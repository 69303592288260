import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import MenuItem from './MenuItem'
import SecondaryMenu from './SecondaryMenu'

interface Props {
  closeMobileMenu: () => void
}
const SuperAdminMenu = ({ closeMobileMenu }: Props) => {
  return (
    <Root>
      <MenuItem to="/super-admin/companies">
        Super Admin <DownCaret name="caret-down" />
      </MenuItem>
      <SecondaryMenu
        closeMobileMenu={closeMobileMenu}
        pages={[
          {
            title: 'Company',
            link: `/super-admin/companies`,
          },
          {
            title: 'Vendor Stackshare',
            link: '/super-admin/vendor-stackshare/view',
          },
        ]}
      />
    </Root>
  )
}

const Root = styled.div`
  position: relative;

  &:hover {
    & > a:after {
      transform: scaleY(1);
    }

    & > div:first-of-type {
      display: block;
    }
  }

  ${({ theme }) => theme.breakpoint.mobile} {
    &:first-of-type a {
      border-top: 0;
    }
  }
`

const DownCaret = styled(Icon)`
  font-size: 16px;
  margin-left: 4px;
`

export default SuperAdminMenu
