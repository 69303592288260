import { gql } from 'graphql-request'

export const TERM_CORE_FRAGMENT = gql`
  fragment TermCore on Term {
    nodeId
    id
    name
    slug
    order
    taxonomyId
  }
`
