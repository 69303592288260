import React, { useEffect, useState } from 'react'
import CategoriesForm from 'components/forms/product/CategoriesForm'
import { FormDescription } from 'components/theme/form'
import { useProductSettings } from '../useProductSettings'
import VendorUpgradeModal from 'components/modals/VendorUpgradeModal'
import { Product } from 'data/types'
import _ from 'lodash'

function ProductCategories() {
  const { product, loading, onComplete, showModal, toggleModal, isService } = useProductSettings()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (loading === false && !ready) setReady(true)
  }, [loading])

  if (!ready) return <div />

  const initialValues = setInitialValues(product)

  return (
    <>
      <FormDescription>
        How others will find your {isService ? 'service' : 'product'} on Theorem
      </FormDescription>
      <CategoriesForm
        currentProductId={product?.id}
        onComplete={onComplete}
        initialValues={initialValues}
        submitButtonText="Update"
        isService={isService}
      />
      <VendorUpgradeModal showModal={showModal} toggleModal={toggleModal} />
    </>
  )
}

const setInitialValues = (product?: Product) => {
  if (!product) return
  const productCategories = _.has(product, 'categories')
    ? product.categories.map(({ id }: any) => id)
    : []
  const productSubcategories = _.has(product, 'subcategories')
    ? product.subcategories?.map(({ id }: any) => id)
    : []
  const practiceGroups = _.has(product, 'practiceGroups')
    ? product.practiceGroups?.map(({ id }: any) => id)
    : []
  const features = _.has(product, 'features') ? product.features.map(({ id }: any) => id) : []
  const bestFor = _.has(product, 'bestFor') ? product.bestFor.map(({ id }: any) => id) : []

  const countries = _.has(product, 'countries') ? product.countries.map(({ id }: any) => id) : []
  const languages = _.has(product, 'languages') ? product.languages.map(({ id }: any) => id) : [527]
  const deployments = _.has(product, 'deployments')
    ? product.deployments?.map(({ id }: any) => id)
    : []
  const roles = _.has(product, 'roles') ? product.roles.map(({ id }: any) => id) : []

  const initialValues = {
    productCategories,
    productSubcategories,
    practiceGroups,
    countries,
    languages,
    features,
    bestFor,
    deployments,
    roles,
  }

  return initialValues
}

export default ProductCategories
