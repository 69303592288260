import { useQuery } from '@tanstack/react-query'
import { GqlGetTestDriveQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useTestDrive = (testDriveId: number) => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['testDrive', testDriveId],
    async () => {
      const response = await request<GqlGetTestDriveQuery>(GET_TEST_DRIVE, { id: testDriveId })

      const testDrive = response.testDrive

      if (!testDrive) return

      const terms = testDrive.termToTestDrives
      const bestFor = terms
        .filter(({ term }) => term.taxonomyId === 16)
        .map(({ term }) => term.name)
      const practiceGroups = terms
        .filter(({ term }) => term.taxonomyId === 13)
        .map(({ term }) => term.name)

      return {
        ...testDrive,
        product: {
          ...testDrive.product,
          bestFor,
          practiceGroups,
        },
      }
    },
    {
      enabled: !!testDriveId,
    }
  )

  return {
    testDrive: data,
    ...other,
  }
}

export const GET_TEST_DRIVE = gql`
  query GetTestDrive($id: Int!) {
    testDrive(id: $id) {
      createdAt
      id
      order
      productId
      termId
      updatedAt
      details
      termToTestDrives {
        term {
          id
          name
          slug
          taxonomyId
        }
      }
      product {
        id
        name
        slug
        details
        excerpt
        termToProducts {
          term {
            taxonomyId
            id
            slug
            nodeId
          }
        }
      }
      term {
        id
        name
        slug
      }
    }
  }
`
