import Label from 'components/theme/form/Label'
import { Checkbox } from 'semantic-ui-react'
import { useField } from 'formik'

function TrueFalse({ label = '', helpContent = '', text = '', ...props }: any) {
  const [, meta, helpers] = useField(props)

  const toggleCheck = () => {
    helpers.setValue(!meta.value)
  }

  return (
    <div>
      <Label helpContent={helpContent}>{label}</Label>
      <Checkbox label={text} checked={!!meta.value} onChange={toggleCheck} />
    </div>
  )
}

export default TrueFalse
