import { createContext, useState, useContext, useCallback, ReactNode, useEffect } from 'react'
import produce from 'immer'
import { SectionLayoutType } from 'generated/graphql'
import { useAddAssocHome, useAssocHomes, useDeleteAssocHome, useUpdateAssocHome } from 'data/hooks'
import useUpdateEffect from 'hooks/utility/useUpdateEffect'

export interface SectionLayout {
  id: number | string
  type: SectionLayoutType
  order: number
}

interface ContextProps {
  sections: SectionLayout[]
  addSection: (type: SectionLayoutType) => void
  deleteSection: (id: number | string) => void
  moveSection: (dragIndex: number, hoverIndex: number) => void
}

export const AssocHomeEditorContext = createContext({} as ContextProps)

// NOTE: if null it means create, otherwise, with a number it means update

export const AssocHomeEditorProvider = ({ children }: { children: ReactNode }) => {
  const { assocHome, isLoading } = useAssocHomes()
  const [sections, setSections] = useState<SectionLayout[]>(assocHome || [])
  const [cardMoveIndex, setCardMoveIndex] = useState(0)
  const addAssocHome = useAddAssocHome()
  const deleteAssocHome = useDeleteAssocHome()
  const updateAssocHome = useUpdateAssocHome()

  useEffect(() => {
    if (isLoading || !assocHome) return
    setSections(assocHome)
  }, [isLoading])

  useUpdateEffect(() => {
    sections.forEach((section, index) => {
      if (typeof section.id === 'number') updateAssocHome.mutate({ id: section.id, order: index })
    })
  }, [cardMoveIndex])

  const moveSection = useCallback((dragIndex: number, hoverIndex: number) => {
    setSections((prevCards: SectionLayout[]) =>
      produce(prevCards, (draft) => {
        draft.splice(dragIndex, 1)
        draft.splice(hoverIndex, 0, prevCards[dragIndex])
      })
    )
    setCardMoveIndex((prevVal) => prevVal + 1)
  }, [])

  const addSection = async (type: SectionLayoutType) => {
    const assocHomeId = await addAssocHome.mutateAsync({ type, order: sections.length })

    setSections((prevCards: SectionLayout[]) =>
      produce(prevCards, (draft) => {
        draft.push({
          id: assocHomeId,
          type,
          order: sections.length,
        })
      })
    )
  }

  const deleteSection = async (id: number | string) => {
    if (typeof id === 'number') {
      await deleteAssocHome.mutateAsync(id)
    }

    setSections((prevSections: SectionLayout[]) =>
      prevSections.filter((section) => section.id !== id)
    )
  }

  return (
    <AssocHomeEditorContext.Provider value={{ sections, addSection, moveSection, deleteSection }}>
      {children}
    </AssocHomeEditorContext.Provider>
  )
}

export const useAssocHomeEditor = () => useContext(AssocHomeEditorContext)
