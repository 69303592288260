import { Step } from 'interfaces'

import CreateUser from './lawfirm/CreateUser'
import CreateLawfirm from './lawfirm/CreateLawfirm'
import UserFirmTechstack from 'views/lawfirms/techstack/UserFirmTechstack'
import { UserStatus } from 'generated/graphql'

export const createUserStep: Step = {
  component: CreateUser,
  userStatus: UserStatus.CreateUser,
  title: 'Your Basic Information',
  subtitle: 'You can update this information later',
  icon: 'user',
  iconTitle: 'Create User',
}

export const createFirmStep: Step = {
  component: CreateLawfirm,
  userStatus: UserStatus.CreateOrg,
  title: 'Your Company Information',
  subtitle: 'You can update this information later',
  icon: 'building',
  iconTitle: 'Company',
}

export const selectUserFirmStackStep: Step = {
  component: UserFirmTechstack,
  userStatus: UserStatus.SetLawfirmUserStack,
  title: 'Select Lawfirm User Stack',
  subtitle: 'You can update this information later',
  icon: 'tools',
  iconTitle: 'Your Firm Apps',
}

export const selectPersonalTechstackStep: Step = {
  component: UserFirmTechstack,
  userStatus: UserStatus.SetLawfirmUserStack,
  title: 'Add other tools you use',
  subtitle:
    'Help your firm better understand outside technology. Your admin is committed to providing better technology access.',
  icon: 'tools',
  iconTitle: 'Your Apps',
}
