import React from 'react'
import styled from '@emotion/styled'
import { TrackingChartProvider, useTrackingChartContext } from 'context/trackingChartContext'
import OptionsBar from './OptionsBar'
import AnalyticCharts from './AnalyticCharts'
import { Dropdown } from 'components/theme/form'
import { Button, Icon } from 'components/theme'
import { useNavigate } from 'react-router-dom'

import statsPreview from 'assets/VendorDashboardUpsell.png'
import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useOrg } from 'data/organization/useOrg'

function Analytics() {
  const { paymentStatus } = useViewer()
  const { vendor } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()
  const navigate = useNavigate()

  const makeProductOptions = () =>
    vendor?.products.map((product) => ({
      text: product.name,
      key: `${product.id}-dropdown-option`,
      value: product.id,
    }))

  const Controls = () => {
    const { setProductToAnalyze } = useTrackingChartContext()
    return (
      <Root>
        <TopOptions>
          <StyledDropdown
            placeholder="Choose a product"
            search
            options={makeProductOptions()}
            onChange={(_e: any, { value }: any) => {
              setProductToAnalyze(value)
            }}
          />
          <OptionsBar />
        </TopOptions>
        <AnalyticCharts />
      </Root>
    )
  }

  if (paymentStatus === 'ACTIVE')
    return (
      <>
        <TrackingChartProvider>
          <Controls />
        </TrackingChartProvider>
      </>
    )

  return (
    <>
      <Container>
        <CtaBox>
          <Text>
            With an upgraded account you'll be able to see real time stats for your products.
          </Text>
          <PayButton onClick={() => navigate(`${baseOrgUrl}/upgrade`)}>
            <StarIcon name="star" /> <ButtonText>Upgrade</ButtonText>
          </PayButton>
        </CtaBox>
        <PreviewImage
          src={statsPreview}
          alt="a blurry preview of the statistics page and it's bars and graphs"
        />
      </Container>
    </>
  )
}

const Container = styled.div`
  margin-top: -20px;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TopOptions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 20px;
  margin-top: -30px;
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
`

const StyledDropdown = styled(Dropdown)`
  min-width: 150px !important;
`

const CtaBox = styled.div`
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  height: 150px;
  width: 400px;
  margin-top: -100px;
  padding: 16px;
  text-align: center;
  font-size: 20px;
  border: solid 2px #ffd700;
  border-radius: 5px;
`

const PreviewImage = styled.img`
  width: 90%;
  margin: 0 auto;
  z-index: 0;
`

const ButtonText = styled.div`
  transform: translateY(1px);
  margin-left: 8px;
`

const PayButton = styled(Button)`
  color: #c3621c;
  background-color: #ffd700;
  border-color: #ffd700;
  display: flex;
  align-items: center;
  padding-left: 10px;
  line-height: 1;
  margin-right: 10px;

  &:hover {
    color: #c3621c;
    border-color: #c3621c;
    background-color: transparent;
  }
`

const StarIcon = styled(Icon)`
  font-size: 12px;
`

const Root = styled.div`
  width: 100%;
  padding: 4px;
`

const Text = styled.p`
  line-height: 1.4;
`

export default Analytics
