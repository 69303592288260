import styled from '@emotion/styled'
import { useState } from 'react'

interface Props {
  isInitialCheck?: boolean
  onChange: (value: any) => void
  value: any
}
const Checkbox = ({ isInitialCheck, value, onChange }: Props) => {
  const [isChecked, setIsChecked] = useState(!!isInitialCheck)

  const toggleCheckbox = () => {
    setIsChecked((prevVal) => !prevVal)
    onChange(value)
  }

  return (
    <Root onClick={toggleCheckbox}>
      <InputCheckbox type="checkbox" checked={isChecked} />
      <CheckboxOutline isChecked={isChecked}>
        <Check isChecked={isChecked} />
      </CheckboxOutline>
    </Root>
  )
}

const Root = styled.div`
  display: inline-block;
  cursor: pointer;
`

const checkedColor = '#9013FE'
const uncheckedColor = '#4d4d51'
const CheckboxOutline = styled.div<{ isChecked: boolean }>`
  border: 2px solid ${({ isChecked }) => (isChecked ? checkedColor : uncheckedColor)};
  border-radius: 3px;
  height: 20px;
  width: 20px;
  transition: background 200ms;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

const Check = styled.div<{ isChecked: boolean }>`
  display: ${({ isChecked }) => (isChecked ? 'block' : 'none')};
  width: 7px;
  height: 13px;
  border-right: 3px solid;
  border-bottom: 3px solid;
  border-color: ${({ isChecked }) => (isChecked ? checkedColor : uncheckedColor)};
  transform-origin: center center;
  transform: translateX(4px) rotate(45deg);
`

const InputCheckbox = styled.input`
  display: none;
`

export default Checkbox
