import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  id: number
  termId: number
  order: number
}

export const useUpdateTermToVendorStackshare = () => {
  const request = useGql()
  return useMutation(async ({ id, termId, order }: Variables) => {
    await request(UPDATE_TERM_TO_VENDOR_STACKSHARE, { id, termId, order })
  })
}

const UPDATE_TERM_TO_VENDOR_STACKSHARE = gql`
  mutation UpdateTermToVendorStackshare($id: Int!, $termId: Int, $order: Int) {
    updateTermToVendorStackshare(input: { patch: { order: $order, termId: $termId }, id: $id }) {
      clientMutationId
    }
  }
`
