import React from 'react'
import styled from '@emotion/styled'
import YourAppsPopup from 'views/associations/AssociationApps/YourAppsPopup'

import FirmApps from './FirmApps'
import Modal, { useModal } from 'components/Modal'
import BoxWrapperTabs from 'components/theme/BoxWrapperTabs'
import YourFirmApps from './YourFirmApps'

function YourApps() {
  const { showModal, toggle } = useModal()

  return (
    <>
      <BoxWrapperTabs
        views={[
          {
            tabName: (
              <>
                Your Apps{' '}
                <YourAppsPopup
                  text={
                    'Add the tools you use to your profile for quick access. This is your personal stack. It will be shared with organizations you are a part of and used to make better recommendations for you throughout our system.'
                  }
                />
              </>
            ),
            component: <YourFirmApps />,
          },
          { tabName: 'Stack Share', component: <FirmApps /> },
        ]}
      />
      <Modal isShowing={showModal} toggleModal={toggle} width="550px">
        <Iframe src="https://airtable.com/embed/shrdVdxkew8dWXCcj?backgroundColor=cyan" />
      </Modal>
    </>
  )
}

const Iframe = styled.iframe`
  border: none;
  height: 2900px;
  width: 100%;
`

export default YourApps
