import styled from '@emotion/styled'
import { Button } from 'components/theme'
import { useOrg } from 'data/organization/useOrg'
import { useAppMetadata } from 'data/viewer/useAppMetadata'
import { useMutateViewer } from 'data/viewer/useMutateViewer'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
import { useRedirect } from 'hooks/useRedirect'

const VendorToAssocSuccess = () => {
  const { vendor } = useOrg()
  const { data: appMetadata } = useAppMetadata()
  const mutateViewer = useMutateViewer()
  const marketplaceUrl = useMarketplaceUrl(true)

  const continueClick = () => {
    mutateViewer.mutate(
      {
        appMetadata: {},
      },
      {
        onSuccess: () => {
          window.location.href = `${marketplaceUrl}returnTo=/getting-started`
        },
      }
    )
  }

  const association = appMetadata.assocOrg

  if (!vendor || !association) return <div></div>

  return (
    <Root>
      <Container>
        <Logo src={association.details.logo} />
        <Headline>
          <strong>{vendor.name}</strong> has been added to
          <br />
          <strong>{association.name}'s</strong> network.
        </Headline>
        <Content>
          You have access to their private network of users, vendor directory, and StackShare™
          {/*You have access to their private network of users, have vendor access to their ecosystem, and can create exclusive deals and offerings */}
        </Content>
        <Button onClick={continueClick}>Continue</Button>
      </Container>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

const Headline = styled.p`
  font-size: 28px;
  margin-bottom: 16px;
  line-height: 1.4;
`

const Content = styled.p`
  font-size: 16px;
  margin-bottom: 28px;
  max-width: 530px;
`

const Container = styled.div`
  text-align: center;
`

const Logo = styled.img`
  max-width: 180px;
`

export default VendorToAssocSuccess
