import { useQuery } from '@tanstack/react-query'
import { GqlGetAllRfpsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useAllRfps = () => {
  const request = useGql()
  const { data, ...other } = useQuery(['rfps'], async () => {
    const response = await request<GqlGetAllRfpsQuery>(GET_ALL_RFPS)

    return response.rfps
  })

  return { rfps: data, ...other }
}

const GET_ALL_RFPS = gql`
  query getAllRfps {
    rfps(orderBy: CREATED_AT_DESC) {
      createdAt
      details
      id
      rfpToProducts {
        product {
          name
          id
          productToOrganizations {
            organizationId
          }
        }
      }
      termToRfps {
        term {
          name
          id
          slug
          taxonomyId
        }
      }
      user {
        email
        first
        last
        id
        currentOrganization
      }
    }
  }
`
