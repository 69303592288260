import { useState } from 'react'
import Modal from 'components/Modal'
import { AssociationTechstack } from 'interfaces'
import ModalHeader from './ModalHeader'
import UsersTable from './UsersTable'
import UserStories from './UserStories'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

interface Props {
  showModal: boolean
  toggleModal: () => void
  productProfile: AssociationTechstack
  initialView?: 'USERS' | 'USER_STORIES'
}

const ProductProfile = ({
  showModal,
  toggleModal,
  productProfile,
  initialView = 'USERS',
}: Props) => {
  const { product, users } = productProfile
  const [view, setView] = useState(initialView)
  const baseOrgUrl = useBaseOrgUrl()

  const switchView = (view: 'USERS' | 'USER_STORIES') => () => {
    setView(view)
  }

  return (
    <Modal isShowing={showModal} toggleModal={toggleModal} width="800px" removePadding>
      <ModalHeader product={product} />
      <Tabs>
        <Tab active={view === 'USERS'} onClick={switchView('USERS')}>
          Users
        </Tab>
        <Tab active={view === 'USER_STORIES'} onClick={switchView('USER_STORIES')}>
          User Stories
        </Tab>
        <ActionButtons>
          <TabAnchor
            href="https://airtable.com/embed/shrdVdxkew8dWXCcj?backgroundColor=cyan"
            target="_blank"
          >
            Leave a Review
          </TabAnchor>
          <TabLink to={`${baseOrgUrl}/user-stories/create`} state={{ productId: product.id }}>
            Add User Story
          </TabLink>
        </ActionButtons>
      </Tabs>
      <Main>
        {view === 'USERS' && <UsersTable users={users} toggleModal={toggleModal} />}
        {view === 'USER_STORIES' && <UserStories productId={product.id} />}
      </Main>
    </Modal>
  )
}

const Main = styled.div`
  padding: 30px;
  min-height: 170px;
`

const ActionButtons = styled.div`
  margin-left: auto;
`

const Tabs = styled.div`
  display: flex;
  padding-top: 12px;
  padding: 4px 30px 0px;
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
`

const tabStyles = css`
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #9013fe;
    opacity: 1;
    transition: 250ms opacity;
  }

  &.active:after,
  &:hover:after {
    opacity: 1;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.87);
  }
`

const TabAnchor = styled.a`
  ${tabStyles}

  &:after {
    background: #139cfe;
  }
`

const TabLink = styled(Link)`
  ${tabStyles}

  &:after {
    background: #5f799a;
  }
`

const Tab = styled.div<{ active: boolean }>`
  ${tabStyles}
  font-weight: ${({ active }) => (active ? 500 : 400)};

  &:after {
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
`

export default ProductProfile
