import image from 'assets/marketing/cropped-box.svg'
import TopCtaSection from './TopCtaSection'

const CompanyStack = () => {
  return (
    <TopCtaSection
      image={image}
      title="Update Your Company Stack"
      text="Use your company stack to help your colleagues discover the tools and services your company uses and add to their personal profiles."
      outLink={{
        href: 'https://help.theoremlegal.com/kb/en/article/tech-stacks',
        text: 'Watch Tutorial',
      }}
      routerLink={{
        to: '../techstack-management',
        text: 'Edit',
      }}
    />
  )
}

export default CompanyStack
