import React from 'react'
import { SUBSCRIPTION_AGREEMENT, TERMS_AND_CONDITIONS, PRIVACY_POLICY } from 'services/globalAssets'
import { Product } from 'data/types'

interface Props {
  product: Product
}

const TestDriveLegal: React.FC<Props> = ({ product }) => {
  const { publicTermsUrl, eulaUrl, eulaPdf } = product?.details

  return (
    <>
      By confirming your new plan, you agree to comply with the {product.name}{' '}
      <a
        href={publicTermsUrl || eulaUrl || eulaPdf?.url || ''}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>{' '}
      /{' '}
      <a
        href={eulaPdf?.url || eulaUrl || publicTermsUrl || ''}
        target="_blank"
        rel="noopener noreferrer"
      >
        End-user License Agreement
      </a>
      , and the Theorem LTS 
      <a href={SUBSCRIPTION_AGREEMENT} target="_blank" rel="noopener noreferrer">
        Subscription Agreement
      </a>
      ,{' '}
      <a href={TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>
      , and 
      <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
        Privacy Policies
      </a>
      .
    </>
  )
}

export default TestDriveLegal
