import { useEffect } from 'react'
import styled from '@emotion/styled'
import theoremProData from './theoremProData'
import TheoremLogoPng from 'assets/purple-logo.png'
import ProIconSvg from 'assets/upgrade/pro-icon.svg'
import { BoxWrapper } from 'components/theme'
import { useLazyApi } from 'hooks/useApi'
import BackButton from 'components/theme/BackButton'
import { toast } from 'react-toastify'

import { isUsingProductionServer } from 'services/helper'

const priceId =
  process.env.NODE_ENV !== 'development' && isUsingProductionServer
    ? 'price_1MCE1kJl1YuJqgKxXKcOAU7C' // production
    : 'price_1I7UbPJl1YuJqgKxdTtNs8L4' // test

const TheoremProUpgrade = () => {
  const [getStripeSession] = useLazyApi('stripe/organization/vendor-checkout')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getCheckout = async () => {
    const { url } = await getStripeSession({ priceId })

    if (url) window.location.replace(url)
    else toast.error('Something went wrong')
  }

  return (
    <BoxWrapper>
      <BackButton />
      <Root>
        <TopSection>
          <ImageWrapper>
            <TheoremLogo src={TheoremLogoPng} />
            <ProIcon src={ProIconSvg} />
          </ImageWrapper>
          <TopText>
            All-in-One Subscription (Annual)
            <br />
            Universal Listing*, advanced seller tools, exclusive deals, and more
          </TopText>
          <UpgradeButton onClick={getCheckout}>UPGRADE TO PRO</UpgradeButton>
        </TopSection>
        <WhatIncluded>What’s Included:</WhatIncluded>
        {theoremProData.map(({ icon, title, subtitle, content }) => (
          <FeatureBlock key={title}>
            <FeatureIcon src={icon} />
            <FeatureContent>
              <FeatureTitle>{title}</FeatureTitle>
              <FeatureText>{subtitle}</FeatureText>
              <FeatureBottomText>{content}</FeatureBottomText>
            </FeatureContent>
          </FeatureBlock>
        ))}
      </Root>
    </BoxWrapper>
  )
}

const Root = styled.div``

const TopText = styled.div`
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
`

const WhatIncluded = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`

const TopSection = styled.div`
  padding-bottom: 40px;
  text-align: center;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`

const TheoremLogo = styled.img`
  margin-right: 20px;
  max-height: 50px;
`

const UpgradeButton = styled.div`
  background: #ffc000;
  padding: 8px 24px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  max-width: 200px;
  margin: 12px auto 0;
  cursor: pointer;
`

const FeatureBlock = styled.div`
  display: flex;
  max-width: 710px;
  margin: 0 auto 20px;
`

const FeatureIcon = styled.img`
  max-width: 32px;
  width: 100%;
`

const FeatureContent = styled.div`
  padding-left: 32px;
`

const FeatureTitle = styled.div`
  color: #676767;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02rem;
`

const FeatureText = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 600;
`

const FeatureBottomText = styled.div`
  font-size: 16px;
  color: #676767;
`

const ProIcon = styled.img`
  max-height: 30px;
`

export default TheoremProUpgrade
