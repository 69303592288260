import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useCreateOrgListing = () => {
  const request = useGql()
  const { organization } = useOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async ({
      associationId,
      status,
      type,
    }: {
      associationId: string
      status: string
      type: string
    }) => {
      await request(CREATE_ORG_LISTING, {
        organizationId: organization?.id,
        associationId,
        status,
        type,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listings', organization?.id])
      },
    }
  )
}

const CREATE_ORG_LISTING = gql`
  mutation CreateOrgListing(
    $organizationId: UUID!
    $associationId: UUID!
    $status: CompanyListingStatus
    $type: String
  ) {
    upsertOrganizationListing(
      input: {
        organizationListing: {
          organizationId: $organizationId
          associationId: $associationId
          status: $status
          type: $type
        }
      }
    ) {
      clientMutationId
    }
  }
`
