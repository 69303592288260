import { isUsingProductionServer } from './helper'

const PROD_CLIENT_ID = 'Juj5gpkQXsQB4rOgaVCqwHOrtcPZbGP2'
const LOCAL_CLIENT_ID = 'Ejnhk44CQ0t2AVkGy4ZIuFpLm2p8USps'
const STAGING_CLIENT_ID = 'xkQkBvD064amiKwxvOtZ9N4Pyhy8UOL5'

const isLocalDev = process.env.NODE_ENV === 'development'

const clientId = isLocalDev
  ? LOCAL_CLIENT_ID
  : isUsingProductionServer
  ? PROD_CLIENT_ID
  : STAGING_CLIENT_ID

export default {
  clientId,
  domain: 'auth.theoremlegal.com',
  graphQlAudience: 'https://api.theoremlegal.com',
  localGraphQlAudience: 'http://localhost:8081',
}
