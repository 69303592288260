import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Navigate } from 'react-router-dom'

const RedirectOut = () => {
  const { isAdmin } = useViewer()
  const baseOrgUrl = useBaseOrgUrl()

  const url = isAdmin ? `${baseOrgUrl}/admin` : `${baseOrgUrl}/your-apps`

  return <Navigate to={url} />
}

export default RedirectOut
