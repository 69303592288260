import { useQuery } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { GqlGetUserToOrgQuery, MemberStatus } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useGetUserOrgStatus = () => {
  const request = useGql()
  const { organization } = useOrg()
  const { viewer } = useViewer()
  const { data, ...other } = useQuery(
    ['viewer', 'userStatus', organization?.id],
    async () => {
      const response = await request<GqlGetUserToOrgQuery>(GET_USER_TO_ORG, {
        organizationId: organization?.id,
        userId: viewer.id,
      })

      return response.usersToOrganization?.status
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  )

  return { userStatus: data || MemberStatus.Pending, ...other }
}

export const GET_USER_TO_ORG = gql`
  query GetUserToOrg($organizationId: UUID!, $userId: Int!) {
    usersToOrganization(organizationId: $organizationId, userId: $userId) {
      role
      status
    }
  }
`
