import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { useLazyApi } from 'hooks/useApi'
import { FullPageLoading } from 'components/PulsatingLoader'
import { fixCurrencyDisplay } from 'services/helper'
import { Link } from 'react-router-dom'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useProduct } from 'data/hooks'

type StripeProduct = {
  name: string
  amount: number
}

function TestDriveSuccess() {
  const location = useLocation()
  const locationState = location.state as any
  const { product, isLoading: productLoading } = useProduct(locationState.productId)
  const { product: selectedProduct, isLoading: selectedProductLoading } = useProduct(
    locationState.selectedProduct
  )
  const [stripeProduct, setStripeProduct] = useState<StripeProduct>({ name: '', amount: 0 })
  const [stripeLoading, setStripeLoading] = useState(true)
  const [retrieveProduct] = useLazyApi('stripe/product/retrieve')
  const baseOrgUrl = useBaseOrgUrl()

  useEffect(() => {
    ;(async () => {
      const { product, price } = await retrieveProduct({
        priceId: locationState.priceId,
        accountId: locationState.accountId,
      })

      setStripeProduct({
        name: product.name,
        amount: price.unit_amount,
      })

      setStripeLoading(false)
    })()
  }, [])
  // get test drive product details
  // get forWhich product details
  // get price amount

  if (productLoading || selectedProductLoading || stripeLoading) return <FullPageLoading />

  return (
    <Root>
      <Wrapper>
        <TopImage src={product?.details.logoUrl || product?.details.squareLogoUrl} />
        <Title>Thank You for Your Purchase!</Title>
        <p>
          A member of the {product?.name} Client Success team has received the details of your
          purchase and will be in touch to complete your order.
        </p>
        <p>
          Any questions? See{' '}
          <a
            href={`https://ecosystem.theoremlegal.com/product/${product?.slug}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {product?.name}
          </a>
        </p>

        <h3>Purchase Summary</h3>
        <Grid>
          <GridHeader>Product</GridHeader>
          <GridItem>
            <PlanLogos>
              {selectedProduct && <ProductLogo src={selectedProduct.details.squareLogoUrl} />}{' '}
              <ProductName>{stripeProduct.name}</ProductName>
            </PlanLogos>
          </GridItem>

          <GridHeader>Offer from</GridHeader>
          <GridItem>
            <PrevalentLogo src={product?.details.logoUrl} />
          </GridItem>
          <GridHeader>Cost</GridHeader>
          <GridItem>
            <PlanCost>{fixCurrencyDisplay(stripeProduct.amount)}</PlanCost>
          </GridItem>
        </Grid>
        <ButtonWrapper>
          <GoBackButton to={`${baseOrgUrl}`}>&lt; Back to Theorem</GoBackButton>
        </ButtonWrapper>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  p {
    font-size: 16px;
    line-height: 1.7;
  }
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 550px;
`

const ProductLogo = styled.img`
  max-width: 70px;
  width: 100%;
  padding: 0 10px;
`

const ProductName = styled.div``

const PrevalentLogo = styled.img`
  width: 100%;
  max-width: 140px;
`

const PlanLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PlanCost = styled.div`
  font-weight: 500;
  color: #555;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  margin-bottom: 24px;
`

const GridHeader = styled.div`
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
`

const GridItem = styled.div`
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TopImage = styled.img`
  max-width: 150px;
  display: block;
  margin: 0 auto 32px;
`

const ButtonWrapper = styled.div`
  text-align: center;
`

const GoBackButton = styled(Link)`
  text-align: center;
  font-size: 16px;
`

export default TestDriveSuccess
