import { useMutation, useQueryClient } from '@tanstack/react-query'
import { OrganizationSubtype, OrganizationType } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useOrg } from '../useOrg'

interface Variables {
  id: string
  name?: string
  excerpt?: string
  description?: string
  domain?: string
  details?: any
  type: OrganizationType
  subtype?: OrganizationSubtype
}
export const useSuperAdminUpdateOrg = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { id, name, excerpt, description, domain, details, type, subtype } = variables

      await request(UPDATE_ORG, {
        id,
        name,
        excerpt,
        description,
        domain,
        details,
        type,
        subtype,
      })

      return id
    },
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['companies'])
        queryClient.invalidateQueries(['company', id])
      },
    }
  )
}

const UPDATE_ORG = gql`
  mutation SuperAdminUpdateOrg(
    $id: UUID!
    $name: String
    $excerpt: String
    $description: String
    $domain: String
    $details: JSON
    $type: OrganizationType
    $subtype: OrganizationSubtype
  ) {
    updateOrganization(
      input: {
        patch: {
          name: $name
          details: $details
          excerpt: $excerpt
          domain: $domain
          description: $description
          type: $type
          subtype: $subtype
        }
        id: $id
      }
    ) {
      organization {
        id
      }
    }
  }
`
