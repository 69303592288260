import React from 'react'
// import styled from '@emotion/styled'
import { Outlet } from 'react-router'

const StepperControls = () => {
  // const navigate = useNavigate()

  return (
    <>
      <Outlet />
    </>
  )
  // return (
  //   <>
  //     <Top>
  //       <BackButton onClick={() => navigate(-1)}>Back</BackButton>
  //     </Top>
  //     <Outlet />
  //     <Root>
  //       <Exit onClick={() => navigate('../listing-stepper')}>Exit</Exit>
  //       <Button>Next</Button>
  //     </Root>
  //   </>
  // )
}

// const BackButton = styled.div``
// const Top = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   background: white;
//   border-bottom: 1px solid #ccc;
//   display: flex;
// `

// const Root = styled.div`
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background: white;
//   border-top: 1px solid #ccc;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 8px 20px;
// `

// const Exit = styled.div`
//   font-size: 16px;
//   font-weight: bold;
//   cursor: pointer;
// `

// const Button = styled.div`
//   background: #ff7a00;
//   font-weight: 500;
//   font-size: 20px;
//   color: white;
//   padding: 8px 16px;
//   border-radius: 100px;
//   cursor: pointer;
// `

export default StepperControls
