import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/hooks'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation } from '@tanstack/react-query'

export const useUpdateJobTitle = () => {
  const request = useGql()
  const { viewer } = useViewer()
  const { organization } = useOrg()
  return useMutation(async (jobTitle: number) => {
    if (!organization) return

    await request(UPDATE_ORG_JOB_TITLE, {
      jobTitle,
      userId: viewer.id,
      organizationId: organization.id,
    })
  })
}

export const UPDATE_ORG_JOB_TITLE = gql`
  mutation UpdateOrgJobTitle($jobTitle: Int, $userId: Int!, $organizationId: UUID!) {
    updateUsersToOrganization(
      input: { patch: { jobTitle: $jobTitle }, userId: $userId, organizationId: $organizationId }
    ) {
      clientMutationId
    }
  }
`
