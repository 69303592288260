import React from 'react'
import Checkout from 'components/payment/Checkout'
import { useNavigate, useLocation } from 'react-router-dom'
import { CheckoutSubmit } from 'interfaces'
import { useLazyApi } from 'hooks/useApi'
import { FullPageLoading } from 'components/PulsatingLoader'
import { SUBSCRIPTION_AGREEMENT } from 'services/globalAssets'
import { useOrg } from 'data/organization/useOrg'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useProduct } from 'data/hooks'

interface Location {
  formValue: {
    companyName: string
    email: string
    firstName: string
    lastName: string
    jobTitle: string
    phone: string
    selectedProduct?: number
  }
  stripeInfo: {
    priceId: string
    stripeProductId: string
    productId: number
  }
}

function PrevalentCheckout() {
  const location = useLocation()
  const navigate = useNavigate()
  const { organization } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()
  const [submitTestDriveCheckout] = useLazyApi('stripe/test-drive/checkout')

  const locationState: Location = location.state as any

  const { selectedProduct, email, firstName, lastName, jobTitle, phone, companyName } =
    locationState.formValue
  const { priceId, productId } = locationState.stripeInfo

  const { product, isLoading } = useProduct(productId)

  const onSubmit = async ({ paymentMethodId, quantity }: CheckoutSubmit) => {
    await submitTestDriveCheckout({
      productId: locationState.stripeInfo.productId,
      forWhichProductId: selectedProduct || productId,
      organizationId: organization?.id,
      priceId,
      paymentMethodId,
      quantity,
      email,
      firstName,
      lastName,
      jobTitle,
      companyName,
      phone,
    })

    navigate(`${baseOrgUrl}/prevalent/checkout-success`, {
      state: {
        productId,
        selectedProduct,
        priceId,
      },
    })
  }

  if (isLoading) return <FullPageLoading />

  return (
    <Checkout
      priceId={priceId}
      onSubmit={onSubmit}
      email={email}
      allowQuantityField={false}
      legalComponent={
        <>
          By confirming your new plan, you agree to comply with the {product?.name}{' '}
          <a href={product?.details?.publicTermsUrl} target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>{' '}
          /{' '}
          <a href={product?.details?.eulaUrl} target="_blank" rel="noopener noreferrer">
            End-User License Agreement
          </a>
          , the Theorem LTS 
          <a href={SUBSCRIPTION_AGREEMENT} target="_blank" rel="noopener noreferrer">
            Subscription Agreement
          </a>
          ,{' '}
          <a href="https://www.theoremlegal.com/legal" target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>
          , and 
          <a href="https://www.theoremlegal.com/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policies
          </a>
          .
        </>
      }
    />
  )
}

export default PrevalentCheckout
