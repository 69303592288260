import styled from '@emotion/styled'
import { useSelectTechStackContext } from './SelectTechStackContext'

interface Props {
  id: number
  title: string
  logo: string
}
function AppBox({ id, title, logo }: Props) {
  const { state, dispatch } = useSelectTechStackContext()

  const toggleSelectedApp = () => {
    dispatch({ type: 'TOGGLE_APP_SELECTION', payload: { id, title, logo } })
  }

  const isChecked = state.selectedApps.some((apps: any) => apps.id === id)
  const orgSelected = state.orgTechstack.some(
    (orgTechstackItem: any) => orgTechstackItem.productId === id
  )

  return (
    <Root onClick={toggleSelectedApp} isChecked={isChecked} orgSelected={orgSelected}>
      <LogoWrapper>
        <Logo src={logo} />
      </LogoWrapper>
      <Name isChecked={isChecked}>{title}</Name>
    </Root>
  )
}

const LogoWrapper = styled.div`
  width: 90px;
  height: 90px;
  border: 1px solid #aaa;
  margin: 0 auto;
  padding: 5px;
  border-radius: 12px;
  background: white;
  margin-bottom: 8px;
  transition: border-radius 350ms;
`

const Root = styled.div<{ orgSelected: boolean; isChecked: boolean }>`
  background: ${({ orgSelected }) => (orgSelected ? '#dccce6' : '#e7e8ef')};
  padding: 16px 8px 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 250ms, box-shadow 250ms;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0);
  border: 2px solid ${({ isChecked }) => (isChecked ? '#9013FE' : 'transparent')};
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    background: #fff;
    ${LogoWrapper} {
      border: 1px solid transparent;
    }
  }
`

const Logo = styled.img`
  max-width: 100%;
  border-radius: 8px;
`

const Name = styled.div<{ isChecked: boolean }>`
  font-weight: 600;
  text-align: center;
  color: ${({ isChecked }) => (isChecked ? '#9013FE' : '#555')};
`

export default AppBox
