import React from 'react'
import styled from '@emotion/styled'
import FielUpload from './FileUpload'
import Icon from 'components/theme/Icon'
import { css } from '@emotion/react'
import { Label } from 'components/theme/form'
import { useField } from 'formik'

interface Props {
  name: string
}
function PdfUploader(props: Props) {
  const [field, , helpers] = useField(props)

  const addFile = (file: any) => {
    helpers.setValue(file)
  }

  const removeFile = () => {
    helpers.setValue(null)
  }

  const file = field.value

  return (
    <Root>
      <Label>Upload EULA</Label>
      {file ? (
        <SelectedPdf>
          <PdfFileName>
            <PdfIcon name="file-pdf" />
            {file.name}
          </PdfFileName>
          <CloseIcon name="times" onClick={removeFile} />
        </SelectedPdf>
      ) : (
        <FielUpload
          addFile={addFile}
          styles={css`
            height: 50px;
          `}
        >
          <UploadText>Upload PDF</UploadText> <PdfIcon name="file-pdf" />
        </FielUpload>
      )}
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
`

const SelectedPdf = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px dashed #333;
  background: #efefef;
  padding: 12px;
  max-width: 350px;
  border-radius: 4px;
`

const PdfFileName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`

const PdfIcon = styled(Icon)`
  color: red;
  margin-right: 8px;
`

const CloseIcon = styled(Icon)`
  color: red;
`

const UploadText = styled.span`
  margin-right: 8px;
  font-weight: bold;
`

export default PdfUploader
