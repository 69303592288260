import styled from '@emotion/styled'
import Products from 'views/vendors/products/Products'
// import MarketplaceSellerAgreement from 'views/vendors/VendorProfile/MarketplaceSellerAgreement'

const LawfirmProducts = () => {
  return (
    <Root>
      <Products />
    </Root>
  )
}

const Root = styled.div``

export default LawfirmProducts
