import { GqlSearchOrgsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useDebounce } from 'hooks/utility/useDebounce'
import { useQuery } from '@tanstack/react-query'

interface UseSearchOrgs {
  searchString?: string
  domain?: string
  page?: number
  type?: string
}
export const useSearchOrgs = ({
  searchString = '',
  domain = '',
  type,
  page = 0,
}: UseSearchOrgs) => {
  const request = useGql()
  const debouncedSearch = useDebounce(searchString)

  console.log(type)
  const { data, ...other } = useQuery(
    ['searchOrg', debouncedSearch, type, domain, page],
    async () => {
      const data = await request<GqlSearchOrgsQuery>(SEARCH_ORGS, {
        searchString: searchString,
        offset: page,
        domain,
        type,
      })

      const searchedOrgs = data.organizationsConnection?.nodes.map(
        ({
          id,
          name,
          slug,
          type,
          domain,
          details,
          associationToOrganizations,
          usersToOrganizations,
        }) => ({
          id,
          name,
          slug,
          type,
          domain: domain || '',
          logo: details.logoUrl || details.logo || '',
          associationIds: associationToOrganizations.map(({ associationId }) => associationId),
          isClaimed: !!usersToOrganizations.length,
        })
      )

      return searchedOrgs
    },
    {
      staleTime: 1000 * 60,
    }
  )

  return { orgs: data, ...other }
}

const SEARCH_ORGS = gql`
  query SearchOrgs($searchString: String, $offset: Int!, $type: OrganizationType, $domain: String) {
    organizationsConnection(
      first: 12
      offset: $offset
      filter: {
        name: { includesInsensitive: $searchString, notLike: "" }
        domain: { notLike: $domain }
        testMode: { equalTo: false }
      }
      orderBy: NAME_ASC
      condition: { type: $type }
    ) {
      totalCount
      nodes {
        details
        id
        domain
        name
        slug
        type
        usersToOrganizations {
          userId
        }
        associationToOrganizations {
          associationId
        }
      }
    }
  }
`
