import { useCreateProductWizard } from 'context/CreateProductWizardContext'
import CategoriesForm from 'components/forms/product/CategoriesForm'
import styled from '@emotion/styled'

function ProductCategories() {
  const { goNextStep, currentProductId } = useCreateProductWizard()

  const onSubmit = async () => {
    goNextStep()
  }

  return (
    <Root>
      <CategoriesForm currentProductId={currentProductId} onComplete={onSubmit} />
    </Root>
  )
}

const Root = styled.div`
  padding: 40px;
  display: flex;
  justify-content: center;
`

export default ProductCategories
