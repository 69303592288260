import { useState } from 'react'
import styled from '@emotion/styled'
import { FieldGroup, FormLabel, InputStyle } from './styles'
import { CardElement } from '@stripe/react-stripe-js'
import { useField } from 'formik'

function CardInputs() {
  const [, meta, helpers] = useField({ name: 'isCardApproved' })
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Root>
      <FormLabel>Card information</FormLabel>
      <Wrapper isFocused={isFocused}>
        <CardElement
          className="MyCardElement"
          options={options}
          onChange={(e) => helpers.setValue(e.complete)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </Wrapper>
      {meta.value && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Root>
  )
}

const options = {
  style: {
    base: {
      width: '100%',
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const Root = styled.div`
  ${FieldGroup}
`

const Wrapper = styled.div<{ isFocused: boolean }>`
  ${InputStyle}

  ${({ isFocused }) =>
    isFocused &&
    'box-shadow: 0 0 0 1px rgb(50 151 211 / 30%), 0 1px 1px 0 rgb(0 0 0 / 7%), 0 0 0 4px rgb(50 151 211 / 30%);'}
`

const ErrorMessage = styled.div`
  font-weight: 500;
  color: #ff1744;
  letter-spacing: 0.02rem;
  padding-top: 4px;
`

export default CardInputs
