import styled from '@emotion/styled'
import LocationForm from 'components/forms/LocationForm/LocationForm'
import { useLocationData } from 'hooks/graphql/organization/useLocationData'
import { toast } from 'react-toastify'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'

function Locations() {
  const { viewer } = useViewer()
  const { organization } = useOrg()
  const {
    upsertLocation,
    deleteLocation,
    getLocationFormOptions,
    updateUserLocation,
    getHeadquarterId,
    locationsOptions,
    updateHeadquarters,
  } = useLocationData()

  const locationId = viewer?.location?.id

  const onSubmit = async (values: any) => {
    const headquarterId =
      typeof values.headquarterId === 'string'
        ? parseInt(values.headquarterId, 10)
        : values.headquarterId

    await updateHeadquarters(headquarterId)

    for (const location of values.locations) {
      await upsertLocation(location)
    }

    const deleted = defaultValues.locations.filter(
      (location: any) =>
        !values.locations.find((updatedLocation: any) => updatedLocation.id === location.id)
    )

    for (const location of deleted) {
      if (location.id === locationId) {
        const headQrtId = getHeadquarterId(organization)
        await updateUserLocation(headQrtId)
      }

      deleteLocation(location.id)
    }

    toast('Your locations are updated.', { type: 'success' })
  }

  const defaultValues = {
    headquarterId: getHeadquarterId(organization),
    locations: getLocationFormOptions(organization?.locations),
  }

  const handleDelete = (index: number) => {
    const locationToDelete = defaultValues.locations[index]
    if (!locationToDelete) return
    deleteLocation(locationToDelete.id)
    toast('Location is deleted.', { type: 'success' })
  }

  return (
    <Root>
      <LocationForm
        updateHeadquarters={updateHeadquarters}
        onDelete={handleDelete}
        defaultValues={defaultValues}
        locationsOptions={locationsOptions}
        onSubmit={onSubmit}
      />
    </Root>
  )
}

const Root = styled.div``

export default Locations
