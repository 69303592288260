import React from 'react'
import { Formik, Form } from 'formik'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { FormField, TextInput, Dropdown, Label } from 'components/theme/form'
import Button from 'components/theme/Button'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { extractPictureUrl } from 'services/image'
import HelpPopupContent from 'components/HelpPopContent'
import * as yup from 'yup'
import { useTaxonomyDropdown } from 'hooks/useTaxonomyDropdown'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useUpsertBasicCompany } from './useUpsertBasicCompany'
import vendorOptions from './vendorCompanyType'
import { useOrg } from 'data/hooks'
import { OrganizationType } from 'generated/graphql'

const defaultValues = {
  isLawfirm: 'false',
  name: '',
  url: '',
  excerpt: '',
  logoFile: [],
  squareLogoFile: [],
  specialty: [],
}

const validationSchema = yup.object().shape({
  name: yup.string().min(1, 'Too Short').required('Required'),
  website: yup.string().min(1, 'Required').required('Required').nullable(),
  specialty: yup.array().max(3),
})

export interface Values {
  name: string
  website: string
  wideLogo: any
  logo: any
  companyType?: number
  employeeSize?: number
  specialty?: number[]
}

// TODO: switch name to BasicCompanyListingForm
const BasicCompanyForm = ({
  initialValues = defaultValues,
  submitButtonText = 'NEXT (SELECT CATEGORY)',
  associationId,
  onComplete,
}: any) => {
  const { options: practiceGroupOptions, isLoading: practiceGroupsLoading } = useTaxonomyDropdown({
    taxonomyId: 13,
  })
  const { options: employeeSizeOptions, isLoading: employeeSizeLoading } = useTaxonomyDropdown({
    taxonomyId: 19,
    sortByOrder: true,
  })
  const { organization } = useOrg()

  const upsertBasicCompany = useUpsertBasicCompany(
    [initialValues.companyType, initialValues.employeeSize, ...initialValues.specialty].filter(
      Boolean
    )
  )

  const onSubmit = async ({
    name,
    website,
    logo,
    wideLogo,
    companyType,
    employeeSize,
    specialty,
  }: Values) => {
    const wideLogoUrl = await extractPictureUrl(wideLogo)
    const logoUrl = await extractPictureUrl(logo)

    await upsertBasicCompany({
      associationId,
      name,
      website,
      wideLogo: wideLogoUrl,
      logo: logoUrl,
      companyType,
      employeeSize,
      specialty,
    })

    onComplete()
  }

  if (practiceGroupsLoading || employeeSizeLoading) return <FullPageLoading />

  return (
    <Root>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <>
            <FormRoot>
              <FormField>
                <TextInput
                  name="name"
                  label="Company Name"
                  placeholder="Company Name"
                  icon="user"
                  isSubmitting={isSubmitting}
                  required
                />
              </FormField>
              <FormField>
                <TextInput
                  name="website"
                  label="Website URL"
                  placeholder="https://www.example.com"
                  icon="linkify"
                  isSubmitting={isSubmitting}
                  required
                />
              </FormField>
              {organization?.type !== OrganizationType.Lawfirm && (
                <>
                  <FormField>
                    <Label required>Company Type</Label>
                    <Dropdown
                      fluid
                      selection
                      options={vendorOptions}
                      setFieldValue={setFieldValue}
                      name="companyType"
                      value={values.companyType}
                      onChange={(_e: any, { value }: any) => setFieldValue('companyType', value)}
                    />
                  </FormField>
                  <FormField>
                    <Dropdown
                      fluid
                      selection
                      label="Company Size"
                      setFieldValue={setFieldValue}
                      options={employeeSizeOptions.map(({ text, ...other }) => ({
                        ...other,
                        text: `${text} Employees`,
                      }))}
                      value={values.employeeSize}
                      name="employeeSize"
                      placeholder="Select Company Size"
                    />
                  </FormField>
                </>
              )}
              {organization?.type === OrganizationType.Lawfirm && (
                <>
                  <FormField>
                    <Label>Specialty</Label>
                    <Dropdown
                      fluid
                      selection
                      search
                      clearable
                      multiple
                      value={values.specialty}
                      onChange={(_e: any, { value }: any) => setFieldValue('specialty', value)}
                      options={practiceGroupOptions}
                      name="specialty"
                    />
                  </FormField>
                  <FormField>
                    <Label required>Approx. Size (# of Attorneys)</Label>
                    <Dropdown
                      fluid
                      selection
                      clearable
                      onChange={(_e: any, { value }: any) => setFieldValue('employeeSize', value)}
                      options={employeeSizeOptions.map(({ text, ...other }) => ({
                        ...other,
                        text: `${text} Attorneys`,
                      }))}
                      value={values.employeeSize}
                      name="employeeSize"
                    />
                  </FormField>
                </>
              )}
              <FormField>
                <SplitColumn>
                  <WideLogo>
                    <UploadSingleAsset
                      label="Wide Logo"
                      name="wideLogo"
                      selectedImage={values.wideLogo}
                      helpContent={<HelpPopupContent />}
                      styles={css`
                        max-width: none;
                      `}
                      fluidWidth
                    >
                      Upload Wide Logo
                    </UploadSingleAsset>
                  </WideLogo>
                  <SmallLogo>
                    <UploadSingleAsset
                      label="Square Logo"
                      name="logo"
                      helpContent={<HelpPopupContent />}
                      selectedImage={values.logo}
                      required
                    >
                      Square Logo
                    </UploadSingleAsset>
                  </SmallLogo>
                </SplitColumn>
              </FormField>
              <FormField>
                <ButtonContainer>
                  <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                    {submitButtonText}
                  </Button>
                </ButtonContainer>
              </FormField>
            </FormRoot>
          </>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
`

const FormRoot = styled(Form)`
  max-width: 760px;
  width: 100%;
  margin-bottom: 25px;
`

const SplitColumn = styled.div`
  display: flex;
`

const WideLogo = styled.div`
  flex: 1;
  margin-right: 20px;
`

const SmallLogo = styled.div`
  width: 150px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
  text-align: right;
`

export default BasicCompanyForm
