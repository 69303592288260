import React, { useEffect } from 'react'
import { useField, FieldHookConfig } from 'formik'
import { Dropdown } from 'semantic-ui-react'
import { useLazyApi } from 'hooks/useApi'
import { fixCurrencyDisplay } from 'services/helper'
import { useViewer } from 'context/viewerContext'

type Props = FieldHookConfig<any> & {
  disabled?: boolean
}

const PriceIdInput = ({ disabled = false, ...props }: Props) => {
  const [field, , helpers] = useField(props)
  const { accountId } = useViewer()
  const [getAllPrices, { data }] = useLazyApi(`/stripe/connect/get-all-prices`)

  const getOptions = () => {
    const options = data?.prices?.data?.map(
      ({ id, unit_amount, product, billing_scheme, currency, recurring }: any) => ({
        value: id,
        text: getOptionTitle({
          currency,
          unitAmount: unit_amount,
          billingScheme: billing_scheme,
          product,
          recurring,
        }),
      })
    )
    return options ? options : []
  }

  const getOptionTitle = ({ currency, unitAmount, billingScheme, product, recurring }: any) => {
    const sign = currency === 'usd' ? '$' : currency
    const money = unitAmount ? fixCurrencyDisplay(parseInt(unitAmount, 10)) : billingScheme
    return `${product.name} - ${money}${sign !== '$' && unitAmount ? ` ${sign} ` : ''}${
      recurring ? ` per ${recurring.interval}` : ' one time'
    }`
  }

  useEffect(() => {
    if (accountId) getAllPrices({ accountId })
  }, [accountId])

  useEffect(() => {
    if (disabled) helpers.setValue('')
  }, [disabled])

  const onChange = (e: any, { value }: any) => {
    helpers.setValue(value)
  }

  return (
    <Dropdown
      value={field.value}
      placeholder="Select an option"
      onChange={onChange}
      disabled={disabled}
      options={getOptions()}
      selection
      fluid
    />
  )
}

export default PriceIdInput
