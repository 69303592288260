import React from 'react'
import { breakpoint } from 'services/theming'
import styled from '@emotion/styled'

const PersonalFaq: React.FC = () => {
  return (
    <Root>
      <Title>Frequently asked questions</Title>
      <Wrapper>
        <Block>
          <Question>What level of access will I be granted?</Question>
          <Answer>
            You will receive basic marketplace access and enjoy basic algorithmic search
            functionality. In order to gain broader access to firm features and marketplace content,
            you’ll need to sign up using a work email.
          </Answer>
        </Block>
        <Block>
          <Question>What is your cancellation policy?</Question>
          <Answer>
            If you cancel your plan before the next renewal cycle, you will retain access to paid
            features until the end of your subscription period. When your subscription expires, you
            will lose access to paid features and all data associated with those features.
          </Answer>
        </Block>
        <Block>
          <Question>Refund Policy</Question>
          <Answer>
            THEOREM DOES NOT OFFER REFUNDS. Theorem may, in its sole discretion, allow Registered
            Users to downgrade their account or issue a refund under special circumstances.
          </Answer>
        </Block>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  padding: 60px 20px 120px;
  background: #f2f2f2;

  ${breakpoint.mobile} {
    padding: 40px 20px;
  }
`

const Wrapper = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 36px;
`

const Question = styled.div`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.02rem;
  margin-bottom: 12px;
`

const Answer = styled.p`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.02rem;
`

const Block = styled.div`
  padding-bottom: 32px;
`

export default PersonalFaq
