import styled from '@emotion/styled'

function NoMatch() {
  return (
    <Root>
      <Header>404 this page does not exist</Header>
    </Root>
  )
}

const Root = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Header = styled.h1``

export default NoMatch
