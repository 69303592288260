import styled from '@emotion/styled'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { OrganizationType } from 'generated/graphql'
import AssociationMenu from './AssociationMenu'
import CompanyMenu from './CompanyMenu'
import LawfirmMenu from './LawfirmMenu'
import VendorMenu from './VendorMenu'
import MenuItem from './MenuItem'
import SuperAdminMenu from './SuperAdminMenu'

interface Props {
  title?: string
  isShowMobileMenu: boolean
  toggleMobileMenu: () => void
  closeMobileMenu: () => void
}

const GlobalMenu = ({ title, isShowMobileMenu, toggleMobileMenu, closeMobileMenu }: Props) => {
  const { organization } = useOrg()
  const { isSuperAdmin } = useViewer()

  return (
    <Root>
      <MobileMenuButton onClick={toggleMobileMenu}>
        <MenuIcon isShowMobileMenu={isShowMobileMenu}>
          <MenuBar />
          <MenuBar />
          <MenuBar />
          <MenuBar />
        </MenuIcon>
        {isShowMobileMenu ? 'Close' : title}
      </MobileMenuButton>
      <DesktopMenu isShowMobileMenu={isShowMobileMenu}>
        {organization?.type === OrganizationType.Vendor && (
          <VendorMenu closeMobileMenu={closeMobileMenu} />
        )}
        {organization?.type === OrganizationType.Lawfirm && (
          <LawfirmMenu closeMobileMenu={closeMobileMenu} />
        )}
        {organization?.type === OrganizationType.Company && (
          <CompanyMenu closeMobileMenu={closeMobileMenu} />
        )}
        {organization?.type === OrganizationType.Association && (
          <AssociationMenu closeMobileMenu={closeMobileMenu} />
        )}
        {isSuperAdmin && <SuperAdminMenu closeMobileMenu={closeMobileMenu} />}
      </DesktopMenu>
    </Root>
  )
}

const MenuBar = styled.div`
  position: absolute;
  width: 24px;
  height: 2px;
  background: #5b6979;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  transition: 250ms transform, 250ms opacity;

  &:first-of-type {
    top: calc(50% - 4px);
    opacity: 1;
  }

  &:last-of-type {
    top: calc(50% + 4px);
    opacity: 1;
  }
`

const MenuIcon = styled.div<{ isShowMobileMenu: boolean }>`
  margin-right: 8px;
  width: ${({ isShowMobileMenu }) => (isShowMobileMenu ? '20px' : '24px')};
  transition: 250ms width;
  line-height: 36px;
  height: 36px;
  position: relative;

  ${MenuBar} {
    ${({ isShowMobileMenu }) =>
      isShowMobileMenu
        ? `
  &:first-of-type,
  &:last-of-type {
    opacity: 0
  }

    &:nth-of-type(2) {
    transform: translate(0%, -50%) rotate(-45deg) scalex(.75);
  }
    &:nth-of-type(3) {
    transform: translate(0%, -50%) rotate(45deg) scalex(.75);
  }
  `
        : ''}
  }
`

const Root = styled.div`
  padding-left: 24px;

  ${({ theme: { breakpoint } }) => breakpoint.tablet} {
    padding-left: 16px;
  }
`

const MobileMenuButton = styled.button`
  display: none;
  background: white;
  border: 2px solid #8d97a2;
  height: 36px;
  line-height: 36px;
  border-radius: 6px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02rem;
  white-space: nowrap;
  background: transparent;
  ${({ theme: { breakpoint } }) => breakpoint.mobile} {
    display: flex;
    align-items: center;
  }
`

const DesktopMenu = styled.div<{ isShowMobileMenu?: boolean }>`
  display: flex;
  ${({ theme: { breakpoint } }) => breakpoint.mobile} {
    position: fixed;
    top: ${({ theme }) => theme.topBarHeight};
    left: 0;
    padding-top: 20px;
    width: 100%;
    height: calc(100vh - ${({ theme }) => theme.topBarHeight});
    background: white;
    display: ${({ isShowMobileMenu }) => (isShowMobileMenu ? 'flex' : 'none')};
  }
`

export default GlobalMenu
