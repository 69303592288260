import { Routes, Route } from 'react-router-dom'
import {
  CreateProductWizardProvider,
  useCreateProductWizard,
} from 'context/CreateProductWizardContext'
import { Icon } from 'semantic-ui-react'
import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'
import BasicProductInfo from './BasicProductInfo'
import CategoriesForm from './CategoriesForm'
import DescriptionForm from './DescriptionForm'
import ExtraForm from './ExtraForm'

const pages: [string, any][] = [
  ['basic-info', BasicProductInfo],
  ['categories', CategoriesForm],
  ['description', DescriptionForm],
  ['extra', ExtraForm],
]

function CreateNewProductWizard() {
  const { atFirstStep, goPrevStep } = useCreateProductWizard()

  return (
    <BoxWrapper>
      <BackButtonContainer>
        {!atFirstStep && (
          <BackButton onClick={goPrevStep}>
            <Icon name="angle left" />
            Back
          </BackButton>
        )}
      </BackButtonContainer>

      <Routes>
        {pages.map(([slug, Component]) => (
          <Route key={slug} path={slug} element={<Component />} />
        ))}
      </Routes>
    </BoxWrapper>
  )
}

const WithCreateProductWizardProvider = () => {
  return (
    <CreateProductWizardProvider pages={pages}>
      <CreateNewProductWizard />
    </CreateProductWizardProvider>
  )
}

const BackButtonContainer = styled.div`
  width: 100%;
  max-width: 760px;
  margin: 0 auto 0;
  padding: 0;
`

const BackButton = styled.button`
  background: transparent;
  border: none;
  color: #777;
  font-weight: 500;

  &:hover {
    color: #222;
  }
`

export default WithCreateProductWizardProvider
