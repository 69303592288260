import { newDeletedList } from 'services/helper'
import { useMutateTermsToProduct, useTaxonomyTerm } from 'data/hooks'

type UseTaxonomyDropdownProps = {
  currentProductId?: number
  taxonomyId: number
  sortByOrder?: boolean
}
export const useTaxonomyDropdown = ({
  currentProductId,
  taxonomyId,
  sortByOrder = false,
}: UseTaxonomyDropdownProps) => {
  const mutateTermsToProduct = useMutateTermsToProduct()
  const { terms, isLoading } = useTaxonomyTerm(taxonomyId)

  const options = getTermOptions(terms, sortByOrder)

  const onSubmit = async (terms: number[], initialValue: number[]) => {
    if (!currentProductId) return

    const [createdTermIds, deletedTermIds] = newDeletedList(initialValue, terms)

    await mutateTermsToProduct.mutateAsync({
      productId: currentProductId,
      createdTermIds,
      deletedTermIds,
    })
  }

  return { options, onSubmit, isLoading }
}

type Term = {
  id: number
  name: string
}

type Option = {
  key: number
  text: string
  value: number
}
const getTermOptions = (termsData: any, sortByOrder: boolean): Option[] => {
  const terms = termsData || []

  return terms
    .sort((a: any, b: any) => (sortByOrder ? a.order - b.order : a.name.localeCompare(b.name)))
    .map((term: Term) => ({
      key: term.id,
      text: term.name,
      value: term.id,
    }))
}
