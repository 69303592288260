import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'semantic-ui-react'
import { breakpoint } from 'services/theming'

// tagline
// features
// button
// name
// priceId
// trialPeriodDays
// status
// interval
// amount

type Props = {
  plan: {
    priceId: string
    name: string
    tagline: string
    features: string[]
    button?: string
    color: string
    amount: number
    perUserAmount?: number
    tiersMode: string
    interval: string
    trialPeriodDays?: number
    hasDiscountCode?: boolean
    discountCode?: string
    userPrice: number | null
  }
  onClick: (priceId: string) => void
}

function PricePanel({ plan, onClick }: Props) {
  const {
    priceId,
    name,
    tagline,
    features,
    button,
    color,
    amount,
    perUserAmount,
    tiersMode,
    interval,
    trialPeriodDays,
    hasDiscountCode,
    discountCode,
    userPrice,
  } = plan

  const price = amount === 0 ? '$0' : `$${amount / 100}`
  const perUserPrice = perUserAmount === 0 ? '$0' : `$${userPrice ? userPrice / 100 : '0'}`

  return (
    <Plan>
      <PlanName color={color}>{name}</PlanName>
      <Tagline>{tagline}</Tagline>
      <BottomLine />
      <Price color={color}>
        {price} <PriceUnit>/ {interval}</PriceUnit>
      </Price>
      {tiersMode === 'graduated' && (
        <PerUserPrice>
          {perUserPrice} per user <PriceUnit>/ {interval}</PriceUnit>
        </PerUserPrice>
      )}
      <Features>
        {features.map((feature, index) => (
          <Feature key={index}>
            <FeatureText>{feature}</FeatureText>
          </Feature>
        ))}
        {trialPeriodDays && (
          <Feature>
            <CheckIcon themecolor={color} name="check" />
            <FeatureText>Free trial {trialPeriodDays} days</FeatureText>
          </Feature>
        )}
      </Features>
      <ButtonContainer>
        <Button onClick={() => onClick(priceId)} color={color}>
          {button || 'Purchase'}
        </Button>
      </ButtonContainer>
      {hasDiscountCode && (
        <DiscountCodeWrapper color={color}>
          Promo Code: <PromoCode>{discountCode}</PromoCode>
        </DiscountCodeWrapper>
      )}
    </Plan>
  )
}

const BottomLine = styled.hr`
  border: none;
  border-bottom: 1px solid rgba(88, 28, 125, 0.2);
  margin: 8px -28px 20px;
`

const Plan = styled.div`
  background: white;
  margin: 30px 10px;
  padding: 28px;
  border-radius: 20px;
  width: 33.3333%;
  line-height: 1;
  box-shadow: 0 5px 30px rgba(88, 28, 125, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;

  ${breakpoint.desktop} {
    margin: 30px auto;
    width: 100%;
    max-width: 500px;
  }
`

const Tagline = styled.div<{ theme?: any }>`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 32px;
  text-align: center;
  color: ${({ theme }) => theme.color.text};
`

const PlanName = styled.div<{ color: string }>`
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin: 0 -28px;
  padding-bottom: 0px;
  color: ${({ color }) => (color ? `${color}` : 'black')};
`

const Price = styled.div<{ color: string }>`
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: ${({ color }) => (color ? `${color}` : 'black')};
`

const PriceUnit = styled.div`
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 4px;
`

const PerUserPrice = styled.div`
  font-size: 14px;
  color: #545458;
  margin-top: 8px;
  margin-bottom: 4px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  text-align: center;
  font-weight: bold;
`

const CheckIcon = styled(Icon)<{ themecolor: string }>`
  margin-right: 10px !important;
  font-size: 10px !important;
  color: ${({ themecolor }) => themecolor || 'inherit'} !important;
`

const Features = styled.div`
  padding: 20px 0 30px;
  text-align: center;
`

const Feature = styled.div`
  border-bottom: 1px solid rgba(88, 28, 125, 0.2);
  padding: 12px 0;
  display: flex;
  justify-content: center;
  text-align: center;

  &:last-child {
    border-bottom: none;
  }
`

const FeatureText = styled.div`
  color: #555;
`

const DiscountCodeWrapper = styled.div<{ color: string }>`
  background: ${({ color }) => color || 'rgba(0,0,0,.87)'};
  color: white;
  padding: 4px 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: auto;
`

const Button = styled.button`
  padding: 10px 40px;
  border-radius: 100px;
  font-size: 20px;
  font-weight: bold;
  border: none;
  color: white;
  background: linear-gradient(140deg, rgba(144, 19, 254, 1) 0%, rgba(73, 141, 230, 1) 100%);
  letter-spacing: 0.3px;
  box-shadow: 0 5px 20px rgba(88, 28, 125, 0.35);
`

const PromoCode = styled.strong`
  font-size: 16px;
  font-weight: bold;
  margin-left: 4px;
`

export default PricePanel
