import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlAddAssocHomeMutation, SectionLayoutType } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  type: SectionLayoutType
  order: number
}
export const useAddAssocHome = () => {
  const request = useGql()
  const { organization } = useOrg()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { type, order } = variables

      const response = await request<GqlAddAssocHomeMutation>(ADD_ASSOC_HOME, {
        organizationId: organization?.id,
        order,
        type,
        details: getDefaultDetails(type),
      })

      return response.createAssocHome.assocHome.id
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['assocHome']),
    }
  )
}

const getDefaultDetails = (type: SectionLayoutType) => {
  switch (type) {
    case SectionLayoutType.LargeProduct:
      return {
        sliderType: 'ALL_PRODUCTS',
        slides: [],
      }

    case SectionLayoutType.ProductsSlider:
      return {
        sliderType: 'CUSTOM',
        productIds: [],
        categoryIds: [],
      }
    default:
      return {}
  }
}

const ADD_ASSOC_HOME = gql`
  mutation AddAssocHome(
    $organizationId: UUID!
    $type: SectionLayoutType!
    $order: Int!
    $details: JSON
  ) {
    createAssocHome(
      input: {
        assocHome: {
          organizationId: $organizationId
          order: $order
          type: $type
          details: $details
        }
      }
    ) {
      assocHome {
        id
      }
    }
  }
`
