import { Term } from 'data/types'
interface TermToViewerGql {
  termId: number
  term?: {
    nodeId: string
    id: number
    name: string
    slug: string
    order?: number
    taxonomyId: number
  }
}

export const parseTermsToViewer = (termToUsers: TermToViewerGql[]): Term[] => {
  return termToUsers.map(({ term }) => ({ ...term })) as Term[]
}
