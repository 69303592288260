import React from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'
import { Icon } from 'components/theme'
import { CSSObject } from '@emotion/react'

interface Props {
  toggleModal: () => void
  showClose?: boolean
  closeModalStyles?: CSSObject
  children: React.ReactNode
  width?: string
}
const BaseModal = ({ children, toggleModal, width, showClose = true, closeModalStyles }: Props) => {
  return ReactDOM.createPortal(
    <Root onClick={toggleModal}>
      <ContentWrapper onClick={(e) => e.stopPropagation()} width={width}>
        {showClose && <CloseButton onClick={toggleModal} name="times" css={closeModalStyles} />}
        {children}
      </ContentWrapper>
    </Root>,
    document.body
  )
}

const Root = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0 20px;
`

const ContentWrapper = styled.div<{ width?: string }>`
  position: relative;
  background: white;
  border-radius: 5px;
  max-width: 100vw;
  max-height: calc(100vh - 40px);
  ${({ width }) => (width ? `width: ${width};` : '')}

  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${breakpoint.mobile} {
    padding: 0 0 60px;
  }
`

const CloseButton = styled(Icon)`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  font-size: 18px;
  z-index: 1;
`

export default BaseModal
