import React, { ReactNode, useEffect, useState } from 'react'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useLocation, useNavigate } from 'react-router-dom'
import { SignUpTracker } from '@theoremlts/theorem-design'
import { useViewer } from 'context/viewerContext'
import { UserRole, OrganizationType, UserStatus } from 'generated/graphql'
import { ViewerToOrganization } from 'data/types'
import { useAppMetadata } from 'data/viewer/useAppMetadata'
import { useOrg } from 'data/organization/useOrg'
import LogoutButton from 'components/theme/LogoutButton'
import { useAuth0 } from '@auth0/auth0-react'

// The most important thing about this is to make sure we are aware of all the signup paths
// How can I best explain that in code
type InitialState = {
  organizationId?: string
  organizationType?: OrganizationType
  organization: ViewerToOrganization
  userRole?: UserRole
  setupType: string
  amplitude: null | SignUpTracker
}

const UserSetupContext = React.createContext({} as InitialState)

/* const setupPaths = {
  VENDOR: {
    OWNER_NO_ORG: ['role-check', 'vendor-user-account-organization', 'create-product', 'integrations'], // Start from role-check page
    OWNER_WITH_ORG: ['select-organization', 'vendor-user-account-organization', 'create-product', 'integrations'], // Start from select-organization page
    OWNER_WITH_PRODUCTS: ['select-products', 'vendor-user-organization'],
    USER_WITH_ORG: ['select-organization', 'user-account'],
  },
  LAWFIRM: {
    OWNER_NO_ORG: ['role-check', 'lawfirm-user-account', 'lawfirm-organization', 'select-products'], // Start from role-check page
    OWNER_WITH_ORG: ['select-organization', 'lawfirm-user-account', 'lawfirm-organization', 'select-products'], // Start Selected from select-organization page
    USER_WITH_ORG: ['select-organization', 'user-account'], // Start from select-organization
  },
  PERSONAL: {
    NEW_USER: ['role-check', 'user-account', 'select-products', 'personal-payment'], // start from role-check page
  },
} */

// User signs in
// We check the User Status
// If SET_PRODUCTS user role is only a vendor
// After users selects Role, company, or product we determine the path
// We first check if there are match orgs

export const UserSetupProvider = ({ children }: { children?: ReactNode }) => {
  const { viewer, viewerLoading } = useViewer()
  const navigate = useNavigate()
  const location = useLocation()
  const [called, setCalled] = useState(false)
  const [amplitude, setAmplitude] = useState<null | SignUpTracker>(null)
  const [setupType, setSetupType] = useState('NORMAL')
  const { data: appMetadata } = useAppMetadata()
  const { organization } = useOrg()
  const { isAuthenticated } = useAuth0()

  const currentOrganizationId = viewer?.currentOrganization
  const selectedOrganization = viewer?.organizations.filter(
    (organization) => organization.id === currentOrganizationId
  )[0]

  const organizationType = organization?.type
  const userRole = selectedOrganization?.role

  const onCompleteTracking = () => {
    setAmplitude(null)
  }

  useEffect(() => {
    if (!amplitude) return

    if (userRole === UserRole.User) {
      setSetupType('USER')
    } else if (appMetadata?.matchedProducts) {
      setSetupType('MATCHED_PRODUCTS')
      amplitude.saveTrackedProps({ flow: 'product_matched' })
    } else if (appMetadata?.matchedOrg) {
      setSetupType('MATCHED_ORGANIZATIONS')
      amplitude.saveTrackedProps({ flow: 'organization_matched' })
    } else {
      setSetupType('OWNER')
      amplitude.saveTrackedProps({ is_owner: true })
    }
  }, [amplitude])

  useEffect(() => {
    if (
      !viewer ||
      viewerLoading ||
      (called === true && (viewer.status !== UserStatus.SetApps || UserStatus.Active))
    )
      return
    setCalled(true)

    if (viewer.status !== UserStatus.Active) setAmplitude(new SignUpTracker(onCompleteTracking))

    switch (viewer.status) {
      case UserStatus.Unconfirmed:
      case UserStatus.SelectRole:
        navigate('/setup/role-check')
        return

      case UserStatus.SetOrg:
        navigate('/setup/match-organization')
        return

      case UserStatus.SetProducts:
        navigate('/setup/match-products')
        return

      case UserStatus.CreatePersonalUser:
        return navigate('/setup/personal-user')

      case UserStatus.CreateUser:
        if (organizationType === OrganizationType.Lawfirm) {
          navigate('/setup/lawfirm-user')
        } else if (organizationType === OrganizationType.Vendor) {
          navigate('/setup/vendor-user')
        } else {
          navigate('/setup/create-user')
        }
        return

      // TODO: possibly refactor this, there is this weird state in useOrganization where if there
      // is no organization setup and a user lands on this page, it breaks on reload. This logic
      // of setupOrganizationType fixes this. There may be a better way
      case UserStatus.CreateOrg:
        if (organizationType === OrganizationType.Lawfirm) {
          navigate('/setup/lawfirm-organization')
        } else if (organizationType === OrganizationType.Vendor) {
          navigate('/setup/vendor-organization')
        } else if (organizationType === OrganizationType.Company) {
          navigate('/setup/company-organization')
        } else {
          navigate('/setup/organization')
        }
        return

      case UserStatus.CreateProduct:
        navigate('/setup/product')
        return

      case UserStatus.SetApps:
        navigate('/setup/personal-techstack')
        return

      case UserStatus.SetLawfirmStack:
        navigate('/setup/lawfirm-techstack')
        return

      case UserStatus.SetLawfirmUserStack:
        navigate('/setup/lawfirm-user-techstack')
        return

      case UserStatus.PersonalSuccess:
        navigate('/setup/personal-payment')
        break

      case UserStatus.TermsConditions:
        navigate('/setup/terms-and-conditions')
        break

      case UserStatus.Invite:
        navigate('/setup/invite')
        break

      case UserStatus.RequestScreen:
        navigate('/setup/request')
        break

      case UserStatus.LawfirmPlans:
        navigate('/setup/lawfirm-plans')
        break

      case UserStatus.AssocUser:
        navigate('/setup/association-user')
        break

      case UserStatus.AssocStack:
        navigate('/setup/association-stack')
        break

      case UserStatus.PersonalPayment:
      case UserStatus.LawfirmPayment:
        if (
          !(
            location.pathname === '/setup/lawfirm-checkout' ||
            location.pathname === '/setup/lawfirm-checkout/'
          )
        ) {
          console.log(location.pathname)
          // navigate('/setup/lawfirm-checkout')
        }
        break

      case UserStatus.JoinOrg:
        navigate('/setup/join')
        break

      case UserStatus.Active:
      default:
        return
    }
  }, [
    viewerLoading,
    viewer?.status,
    called,
    history,
    organizationType,
    viewer?.details.setupOrganizationType,
  ])

  if ((!viewer || viewerLoading || !called) && isAuthenticated)
    return (
      <div>
        <LogoutButton />
        <FullPageLoading />
      </div>
    )

  return (
    <UserSetupContext.Provider
      value={{
        amplitude,
        organizationId: currentOrganizationId,
        organizationType,
        organization: selectedOrganization,
        setupType,
        userRole,
      }}
    >
      {children}
    </UserSetupContext.Provider>
  )
}

export const useUserSetup = () => React.useContext(UserSetupContext)
