import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { isBefore, isAfter } from 'date-fns'
import { toast } from 'react-toastify'
import UserStory from 'interfaces/userStory/UserStory'
import { createBlankStory } from 'interfaces/userStory/Helpers'
import YesOrNoModal from 'components/modals/YesOrNoModal'

import { RawUserStory } from 'model/graphql/queries/userStory'
import { useViewer } from 'context/viewerContext'
import { OrganizationType } from 'generated/graphql'
import { useOrg } from 'data/organization/useOrg'
import {
  useAddUserStoryTerms,
  useCreateUserStory,
  useDeleteUserStory,
  useUpdateUserStory,
  useUserStories,
} from 'data/userStory/hooks'

type StoriesContext = {
  userStories: UserStory[]
  loading: boolean
  productSpecificStories: (id: number) => UserStory[]
  authorSpecificStories: (id: number) => UserStory[]
  searchUserStories: (query: string) => void
  deleteStory: (id: number) => void
  getStory: (id?: number | string) => UserStory
  blankStory: UserStory
  updateStory: (story: UserStory) => void
  createStory: (story: UserStory) => void
}

const UserStoriesContext = createContext({} as StoriesContext)

export const UserStoriesProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [userStories, setUserStories] = useState<UserStory[]>([])
  const [blankStory, setBlankStory] = useState<UserStory>(
    createBlankStory({ organizationType: OrganizationType.Lawfirm, orgId: 'broken', userId: 0 })
  )
  const [filteredStories, setFilteredStories] = useState<null | UserStory[]>([])
  const [deleteStoryModal, setDeleteStoryModal] = useState<undefined | number>(undefined)
  const deleteUserStory = useDeleteUserStory()
  const updateUserStory = useUpdateUserStory()
  const createUserStory = useCreateUserStory()
  const addNewTerms = useAddUserStoryTerms()

  const { userStories: data, isLoading: dataLoading } = useUserStories()
  const { organization, vendor } = useOrg()
  const { viewer } = useViewer()
  const userId = viewer?.id
  const role = viewer?.role
  const orgId = organization?.id

  useEffect(() => {
    if (dataLoading || !data) return
    if (!orgId || !userId || !role || !organization) return
    const permissions = { organizationType: organization.type, orgId, userId, userRole: role }
    const parsedStories: UserStory[] = data.map(
      (userStory: RawUserStory) => new UserStory(userStory, permissions)
    )
    const viewableStories = parsedStories.filter(({ meta }) => meta.permissions.view)
    setUserStories(viewableStories)
    setLoading(false)
  }, [dataLoading, JSON.stringify(data), orgId, userId])

  useEffect(() => {
    if (!orgId || !userId || !organization) return
    // sets state with correct permissions
    const blank = createBlankStory({ organizationType: organization.type, orgId, userId })
    setBlankStory(blank)
  }, [loading, orgId, userId])

  const productSpecificStories = (id: number) =>
    userStories.filter(({ product }) => product?.id === id)

  const authorSpecificStories = (id: number) =>
    userStories
      .filter(({ author }) => author?.id === id)
      .sort((a, b) => {
        if (!a.updatedAt) return 1
        if (!b.updatedAt) return -1
        if (isBefore(a.updatedAt, b.updatedAt)) return 1
        if (isAfter(a.updatedAt, b.updatedAt)) return -1
        return 0
      })

  const searchUserStories = (query: string) => {
    if (query === '') return setFilteredStories([])
    const results = userStories.filter((story) =>
      story.searchableAttributes().includes(query.toLowerCase())
    )
    results.length > 0 ? setFilteredStories(results) : setFilteredStories(null)
  }

  const getStory = (searchId?: number | string) => {
    const storyId = typeof searchId !== 'number' ? Number(searchId) : searchId
    if (searchId && isNaN(storyId)) toast.error('Something went wrong, please try again')
    if (isNaN(storyId)) return blankStory
    const story = userStories.find(({ id }) => id === storyId)
    if (!story) toast.error('Something went wrong, please try again')
    return story ? story : blankStory
  }

  const deleteStoryFromDB = async (id: number) => {
    const deletingStory = userStories.find((story) => story.id === id)
    if (!deletingStory?.meta.permissions.edit) {
      return toast.error("You don't have permission to delete this user story")
    }
    try {
      await deleteUserStory.mutateAsync(id)
      setUserStories((prev) => prev.filter((story) => id !== story.id))
      if (filteredStories && filteredStories.length > 0) {
        setFilteredStories((prev) => (prev ? prev.filter((story) => id !== story.id) : null))
      }
      toast.success('The user story was successfully deleted')
    } catch (error) {
      toast.warn('Something went wrong, please try again!')
    }
  }

  const isOwnerCreated = (userStory: UserStory) => {
    if (organization?.type !== 'VENDOR') return false
    return vendor?.products.some(({ id }) => userStory.product?.id === id) || false
  }

  const deleteStory = (id: number) => {
    setDeleteStoryModal(id)
  }

  const updateStory = async (userStory: UserStory) => {
    const variables = userStory.makeUpdateVariables()
    await updateUserStory.mutateAsync(variables)
  }

  const createStory = async (userStory: UserStory) => {
    const variables = userStory.makeNewStoryVariables()
    const userStoryId = await createUserStory.mutateAsync({
      userId,
      ownerCreated: isOwnerCreated(userStory),
      ...variables,
    })
    const terms = userStory.surfaceTerms()
    if (terms.length > 0 && userStoryId)
      await addNewTerms.mutateAsync({ terms, story: userStoryId })
  }

  return (
    <UserStoriesContext.Provider
      value={{
        userStories: filteredStories && filteredStories.length > 0 ? filteredStories : userStories,
        loading,
        productSpecificStories,
        authorSpecificStories,
        searchUserStories,
        deleteStory,
        blankStory,
        getStory,
        updateStory,
        createStory,
      }}
    >
      <YesOrNoModal
        question="Are you sure you want to delete this user story?"
        onYes={() => deleteStoryModal && deleteStoryFromDB(deleteStoryModal)}
        showModal={!!deleteStoryModal}
        toggle={() => setDeleteStoryModal(undefined)}
      />
      {children}
    </UserStoriesContext.Provider>
  )
}

export const useStories = () => useContext(UserStoriesContext)
