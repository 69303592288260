import styled from '@emotion/styled'
import FaqAccordian from '../CurrentListings/FaqAccordian'
import questionAnswers from './questionAnswers'

const ListingFaq = () => {
  return (
    <Root>
      <Title>FAQ</Title>
      <Wrapper>
        <List>
          {questionAnswers.map(({ question, answer }, index) => (
            <FaqAccordian key={index} question={question} answer={answer} />
          ))}
        </List>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  padding-top: 20px;
`

const Title = styled.h2`
  margin-bottom: 16px;
  text-align: center;
`

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

const List = styled.div``

export default ListingFaq
