import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { Editor, RichUtils, getDefaultKeyBinding, DraftHandleValue } from 'draft-js'
import BlockStyleControls from './BlockStyleControls'
import InlineStyleControls from './InlineStyleControls'

function RichEditor({ name, onChange, editorState }: any) {
  const editorRef = useRef<any>()

  const onEditorChange = (editorStateChange: any) => {
    onChange(name, editorStateChange)
  }

  const focus = () => editorRef.current.focus()

  const handleKeyCommand = (command: any): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      onEditorChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  function myKeyBindingFn(e: any) {
    /* if (e.keyCode === 83 && hasCommandModifier(e)) {
      return 'myeditor-save'
    } */
    return getDefaultKeyBinding(e)
  }

  const toggleBlockType = (blockType: any) => {
    onEditorChange(RichUtils.toggleBlockType(editorState, blockType))
  }

  const toggleInlineStyle = (inlineStyle: any) => {
    onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let className = 'RichEditor-editor'
  const contentState = editorState.getCurrentContent()

  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder'
    }
  }

  return (
    <Root>
      <StyleControlWrapper>
        <BlockStyleControls editorState={editorState} onToggle={toggleBlockType} />
        <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
      </StyleControlWrapper>
      <EditorWrapper className={className} onClick={focus}>
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={myKeyBindingFn}
          onChange={onEditorChange}
          ref={editorRef}
          spellCheck
        />
      </EditorWrapper>
    </Root>
  )
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
}

const EditorWrapper = styled.div`
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  margin-top: 10px;
  min-height: 150px;
  color: rgba(0, 0, 0, 0.87);
  padding: 10px 0;
`

const Root = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  padding: 0.67857143em 1em;
`

const StyleControlWrapper = styled.div`
  display: flex;
`

function getBlockStyle(block: any) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote'
    default:
      return ''
  }
}

export default React.memo(RichEditor)
