import styled from '@emotion/styled'
import { useGetAllVendorStackshares } from 'data/hooks'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
import { Link } from 'react-router-dom'

const CurrentVendorStackshare = () => {
  const { vendors } = useGetAllVendorStackshares()
  const marketplaceUrl = useMarketplaceUrl(true)

  return (
    <Root>
      <h2>Current Vendor Stackshare</h2>
      <Table>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {vendors?.map(({ id, slug, name, details }) => (
            <Row key={`company-${id}`}>
              <td>
                <a
                  href={`${marketplaceUrl}returnTo=/stackshare/vendor/${slug}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Name>
                    <Logo src={details.logo} />
                    {name}
                  </Name>
                </a>
              </td>
              <td>
                <Link to={`../edit/${id}`}>Edit</Link>
              </td>
            </Row>
          ))}
        </tbody>
      </Table>
      <hr />
    </Root>
  )
}

const Root = styled.div``

const Table = styled.table`
  width: 100%;
  td {
    vertical-align: middle;
    padding: 4px;
  }
`

const Name = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0.02rem;
`

const Row = styled.tr`
  &:nth-of-type(even) {
    background-color: white;
  }
`

const Logo = styled.img`
  max-height: 30px;
  display: block;
  margin-right: 16px;
`

export default CurrentVendorStackshare
