import { Dropdown as SemanticDropdown } from 'semantic-ui-react'
import Label from './Label'

// TODO: figure out how to include error state
function Dropdown({
  name,
  options = [],
  label = '',
  required = false,
  setFieldValue,
  ...props
}: any) {
  return (
    <label>
      {label && <Label required={required}>{label}</Label>}
      <SemanticDropdown
        fluid
        selection
        onChange={(_e, { value }) => setFieldValue(name, value)}
        options={options}
        name={name}
        {...props}
      />
    </label>
  )
}

export default Dropdown
