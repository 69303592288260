import { BoxWrapper, Icon } from 'components/theme'
import styled from '@emotion/styled'
import ImgSvg from './Analysis-amico.svg'

function RfpSuccess() {
  return (
    <Root>
      <BoxWrapper>
        <Title>Thanks for submitting!!</Title>
        <Headline>
          We've already started assembling a group of vendors
          <br />
          to bring technology to your cause.
        </Headline>
        <Description>
          You'll receive a copy of your proposal along with coorespondance via email
        </Description>
        <ImageWrapper>
          <Image src={ImgSvg} />
        </ImageWrapper>
        <CtaBox>
          <CtaTitle>What's Next?</CtaTitle>
          <InstructionsWrapper>
            <Instructions>
              <CircleIcon name="arrow-circle-right" />{' '}
              <Text>A member of our research team will reach out to you within 24 hours</Text>
            </Instructions>
            <Instructions>
              <CircleIcon name="arrow-circle-right" />{' '}
              <Text>
                We will work with you to ensure that your RFP accurately reflects your goals and we
                obtain responses appropriately.
              </Text>
            </Instructions>
            <Instructions>
              <CircleIcon name="arrow-circle-right" />{' '}
              <Text>
                Custom RFP Forms available as upgrade - request in product feedback on menu
              </Text>
            </Instructions>
          </InstructionsWrapper>
        </CtaBox>
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div`
  text-align: center;
`

const Headline = styled.h1`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
`

const Title = styled.h1`
  font-size: 30px;
  font-weight: 300;
`

const Description = styled.p`
  margin-bottom: 30px;
`

const ImageWrapper = styled.div`
  text-align: center;
`

const Image = styled.img`
  max-width: 300px;
  width: 100%;
`

const CtaBox = styled.div`
  border: 1px solid green;
  border-radius: 6px;
  padding: 20px;
  background: #24ab1417;
  margin-top: 32px;
`

const CtaTitle = styled.h1`
  color: #61a75d;
  font-size: 20px;
  margin-bottom: 10px;
`

const InstructionsWrapper = styled.div`
  max-width: 670px;
  margin: 0 auto;
`

const Instructions = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
  text-align: left;
  display: flex;
`

const CircleIcon = styled(Icon)`
  color: #61a75d;
  transform: translateY(3px);
`

const Text = styled.div`
  margin-left: 8px;
  line-height: 1.4;
`

export default RfpSuccess
