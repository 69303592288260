import { GqlViewerCurrentOrgQuery, OrganizationType } from 'generated/graphql'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'
import { CURRENT_ORG_GQL } from './currentOrgGql'
import { parseOrg } from './parseOrg'

const queryOptions = {
  staleTime: 1000 * 600,
}

export const useOrg = () => {
  const request = useGql()
  const { data, isLoading } = useQuery(
    ['org'],
    async () => {
      // this may return undefined until user is attached to an org
      const data = await request<GqlViewerCurrentOrgQuery>(CURRENT_ORG_GQL)

      const currentOrganization = parseOrg(data.viewersCurrentOrganization)

      return currentOrganization
    },
    queryOptions
  )

  const vendor = data?.type === OrganizationType.Vendor ? data : null
  const lawfirm = data?.type === OrganizationType.Lawfirm ? data : null
  const company = data?.type === OrganizationType.Company ? data : null
  const association = data?.type === OrganizationType.Association ? data : null

  const isSeller = data?.type === OrganizationType.Vendor || data?.type === OrganizationType.Lawfirm

  return {
    organization: data,
    organizationLoading: isLoading,
    isSeller,
    vendor,
    lawfirm,
    company,
    association,
  }
}
