import React, { ReactElement, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { Formik, Form } from 'formik'
import { Select, TextInput } from 'components/theme/form'
import COUNTRIES from 'model/static/countries.json'
import STATES from 'model/static/states.json'

const getStatesOptions = (countryId: any): any[] => {
  return STATES.filter((item) => item.country_id === countryId).map((state) => ({
    value: state.id,
    label: state.name,
  }))
}

const getCountryOptions = () => {
  return COUNTRIES.map((country) => ({ value: country.id, label: country.name }))
}

const countryItemRenderer = ({ item, methods }: { item: any; methods: any }) => {
  const country = COUNTRIES.find((c) => c.id === item.value)

  return (
    <CountryItemContainer onClick={() => methods.addItem(item)}>
      <CountryFlagLabel>{country?.emoji}</CountryFlagLabel>
      <span>{item.label}</span>
    </CountryItemContainer>
  )
}

const countryOptions = getCountryOptions()

export function useLocationForm({ portal = false }): {
  LocationForm: () => ReactElement
  state: string | undefined
  country: string | undefined
  city: string
} {
  const [stateOptions, setstateOptions] = useState<any[]>(getStatesOptions(233))
  const [selectedCountry, setSelectedCountry]: any = useState([
    { value: 233, label: 'United States' },
  ])
  const [selectedState, setSelectedState]: any = useState([])
  const [selectedCity, setSelectedCity] = useState('')

  const validate = (values: any) => {
    const [selectedCountry] = values.country

    const states = getStatesOptions(selectedCountry?.value)
    setstateOptions(states)
    setSelectedCountry(values.country)
    setSelectedState(values.state)
    setSelectedCity(values.city)
  }

  const LocationForm = useCallback(() => {
    return (
      <Formik
        initialValues={{
          city: selectedCity,
          country: selectedCountry,
          state: selectedState,
        }}
        validate={validate}
        onSubmit={(values) => {
          console.log({ values })
        }}
      >
        <Form>
          <FormField>
            <Select
              itemRenderer={countryItemRenderer}
              portal={portal}
              options={countryOptions}
              name="country"
              label="Country"
              required
            />
          </FormField>
          <FormField>
            <Select portal={portal} options={stateOptions} name="state" label="State" />
          </FormField>
          <FormField>
            <TextInput name="city" label="City" placeholder="city" required />
          </FormField>
        </Form>
      </Formik>
    )
  }, [stateOptions, selectedCountry, selectedState, selectedCity])

  return {
    LocationForm,
    state: selectedState[0]?.label,
    country: selectedCountry[0]?.label,
    city: selectedCity,
  }
}

export const LocationFormComponent = ({
  countryName = 'country',
  stateName = 'state',
  cityName = 'city',
}): ReactElement => {
  const [stateOptions, setstateOptions] = useState<any[]>(getStatesOptions(233))

  const handleChange = (values: any) => {
    const [selectedCountry] = values

    const states = getStatesOptions(selectedCountry?.value)
    setstateOptions(states)
  }

  return (
    <>
      <FormFieldLarge>
        <Select
          portal
          itemRenderer={countryItemRenderer}
          onChange={handleChange}
          options={countryOptions}
          name={countryName}
          label="Country"
          required
        />
      </FormFieldLarge>
      <FormField>
        <Select portal options={stateOptions} name={stateName} label="State" />
      </FormField>
      <FormField>
        <CityInput name={cityName} label="City" placeholder="City" required />
      </FormField>
    </>
  )
}

const CountryFlagLabel = styled.span`
  margin-right: 8px;
`

const CountryItemContainer = styled.div`
  cursor: pointer;
  padding: 4px 8px;
  display: flex;
  flex-flow: row nowrap;
  &:hover {
    background: rgba(0, 116, 217, 0.1);
    outline: none;
  }
`

const FormFieldLarge = styled.div`
  margin-bottom: 20px;
  width: 100%;
  padding: 0 20px;
`

const FormField = styled.div`
  margin-bottom: 20px;
  width: 50%;
  padding: 0 20px;
`

const CityInput = styled(TextInput)`
  height: 42px;
`
