import { gql } from 'graphql-request'

export const USER_TO_ORG_CORE_FRAGMENT = gql`
  fragment UserToOrgCore on UsersToOrganization {
    userId
    organizationId
    role
    jobTitle
    status
    createdAt
    updatedAt
  }
`
