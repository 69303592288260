import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Popup } from 'semantic-ui-react'
import { MainTitle, BoxWrapper } from 'components/theme'
import { breakpoint } from 'services/theming'
import Tooltip from 'components/theme/Tooltip'
import _ from 'lodash'
import YourAppsPopup from './YourAppsPopup'

import AppBoxTooltip from './AppBoxTooltip'
// import FirmApps from './FirmApps'
import Modal, { useModal } from 'components/Modal'
import AssociationMemberApps from './AssociationMemberApps'
import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const AssociationApps = () => {
  const { viewer } = useViewer()
  const baseOrgUrl = useBaseOrgUrl()
  const { showModal, toggle } = useModal()

  const isGridView = viewer.personalTechstack.length > 0

  return (
    <>
      <Root>
        <UserApps>
          <Title>
            Your Apps{' '}
            <YourAppsPopup
              text={
                'Add the tools you use to your profile for quick access. This is your personal stack. It will be shared with organizations you are a part of and used to make better recommendations for you throughout our system.'
              }
            />
          </Title>
          <BoxWrapper>
            <AppsContainer isGridView={isGridView}>
              {viewer.personalTechstack &&
                viewer.personalTechstack.map((app) => (
                  <Tooltip
                    key={`logo-box-${app.id}`}
                    placement="right"
                    trigger="hover"
                    interactive={true}
                    delayHide={1000}
                    tooltip={
                      <AppBoxTooltip
                        appId={app.id}
                        name={app.name}
                        signInUrl={app.details.signInUrl}
                        toggleModal={toggle}
                      />
                    }
                  >
                    <OutlinkBox
                      href={`https://ecosystem.theoremlegal.com/product/${app.slug}`}
                      target="_blank"
                    >
                      <LogoImage
                        src={
                          _.get(app, 'details.squareLogoUrl', false) ||
                          _.get(app, 'details.logoUrl', '')
                        }
                        alt={app.name}
                      />
                    </OutlinkBox>
                  </Tooltip>
                ))}
              <Popup
                trigger={
                  <ProductsLink
                    to={`${baseOrgUrl}/edit-techstack`}
                    isgridview={isGridView.toString()}
                  >
                    <PlusWrapper>+</PlusWrapper>
                  </ProductsLink>
                }
              >
                <Popup.Content>Edit my Stack</Popup.Content>
              </Popup>
              {!(viewer.personalTechstack.length > 0) && (
                <NoAppsMessage>
                  <h3>Create your personal stack</h3>
                  <ToolsText>
                    Tools you add here will be displayed on your profile for quick access. Use your
                    stack profile as your tech resume and receive better recommendations throughout
                    the platform and marketplace.{' '}
                    <a
                      href="https://help.theoremlegal.com/kb/en/article/tech-stacks"
                      target="_blank"
                    >
                      Learn more
                    </a>
                  </ToolsText>
                </NoAppsMessage>
              )}
            </AppsContainer>
          </BoxWrapper>
        </UserApps>
        <AssociationMemberApps />
      </Root>

      <Modal isShowing={showModal} toggleModal={toggle} width="550px">
        <Iframe src="https://airtable.com/embed/shrdVdxkew8dWXCcj?backgroundColor=cyan" />
      </Modal>
    </>
  )
}

const ToolsText = styled.p`
  font-size: 15px;
`

const Root = styled.div`
  width: 100%;
  padding-bottom: 60px;
`

const Title = styled(MainTitle)`
  font-size: 24px;
  letter-spacing: 0;
  display: flex;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto 20px !important;

  ${breakpoint.mobile} {
    text-align: center;
  }
`

const NoAppsMessage = styled.div`
  flex: 1 1 auto;
`
const AppsContainer = styled.div<{ isGridView: boolean }>`
  display: ${({ isGridView }) => (isGridView ? 'grid' : 'flex')};
  grid-gap: 40px 40px;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  max-width: 860px;

  ${breakpoint.mobile} {
    grid-gap: 30px 30px;
  }
`

const OutlinkBox = styled.a`
  display: block;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  transition: box-shadow 250ms, background 250ms;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  ${breakpoint.mobile} {
    margin: 0;
  }
`

const ProductsLink = styled(Link)<{ isgridview: string }>`
  position: relative;
  border: 2px dashed #d3ced6;
  border-radius: 4px;
  display: block;
  margin: 0;

  ${({ isgridview }) => (isgridview === 'true' ? '' : 'width: 100px;min-width: 100px;')}

  &:before {
    content: '';
    padding-top: 100%;
    width: 100%;
    display: block;
  }

  &:hover {
    color: #8f5dd2;
  }
`

const PlusWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 30px;
  font-weight: 300;
  color: #8f5dd2;
  line-height: 1;
  transform: translate(-50%, -50%);
`

const LogoImage = styled.img`
  max-width: 100%;
  max-height: 77px;
`

const UserApps = styled.div`
  margin-bottom: 60px;
`

const Iframe = styled.iframe`
  border: none;
  height: 2900px;
  width: 100%;
`

export default AssociationApps
