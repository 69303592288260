import { useViewer } from 'context/viewerContext'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface Variables {
  deletedTermIds: number[]
  createdTermIds: number[]
}
export const useMutateTermsToViewer = () => {
  const request = useGql()
  const queryClient = useQueryClient()
  const { viewer } = useViewer()

  return useMutation(
    async (variables: Variables) => {
      const { deletedTermIds, createdTermIds } = variables

      await request(CREATE_TERMS_TO_USER, { termIds: createdTermIds, userId: viewer.id })
      await request(DELETE_TERMS_TO_USER, { termIds: deletedTermIds, userId: viewer.id })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['viewer']),
    }
  )
}

const CREATE_TERMS_TO_USER = gql`
  mutation CreateTermsToUser($termIds: [Int!]!, $userId: Int!) {
    createTermsToUser(input: { termIds: $termIds, userId: $userId }) {
      clientMutationId
    }
  }
`

const DELETE_TERMS_TO_USER = gql`
  mutation DeleteTermsToUser($termIds: [Int!]!, $userId: Int!) {
    deleteTermsToUser(input: { termIds: $termIds, userId: $userId }) {
      clientMutationId
    }
  }
`
