import { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { useOrg } from 'data/organization/useOrg'
import {
  useAddRemoveHeadquarter,
  useCreateLocation,
  useDeleteLocation,
  useMutateViewer,
  useSetHeadquarter,
  useUpdateLocation,
  useUpdateUserToOrgLocation,
} from 'data/hooks'

const getLocationFormOptions = (locations: any) => {
  return locations.map((location: any) => ({
    city: location.city,
    state: [{ value: location.state, label: location.state }],
    country: [{ value: location.country, label: location.country }],
    isHeadquarter: location.isHeadquarter,
    id: location.id,
  }))
}

const getHeadquarterId = (organization: any) => {
  if (!organization) return undefined

  const location = organization?.locations.filter((location: any) => location.isHeadquarter)[0]
  return location ? location.id : undefined
}

const formatLocations = (locations: any) => {
  if (!locations) return []

  return locations.map((location: any) => ({
    text: `${location.city}, ${location.state} ${location.country}`,
    value: location.id,
  }))
}

export const useLocationData = () => {
  const { organization } = useOrg()
  const createLocation = useCreateLocation()
  const updateLocation = useUpdateLocation()
  const removeLocaation = useDeleteLocation()
  const updateUserToOrgLocation = useUpdateUserToOrgLocation()
  const mutateViewer = useMutateViewer()
  const addRemoveHeadquarter = useAddRemoveHeadquarter()
  const setHeadquarter = useSetHeadquarter()
  const locationsOptions = formatLocations(organization?.locations)
  const currentHeadquarterId = useRef<number>()

  useEffect(() => {
    currentHeadquarterId.current = getHeadquarterId(organization)
  }, [organization?.locations])

  const updateHeadquarters = async (headquarterId: number) => {
    if (currentHeadquarterId.current !== headquarterId) {
      if (!currentHeadquarterId.current) {
        await setHeadquarter.mutateAsync(headquarterId)
      } else {
        await addRemoveHeadquarter.mutateAsync({
          oldLocationId: currentHeadquarterId.current,
          newLocationId: headquarterId,
        })
      }
    }
  }

  const upsertLocation = async (location: any) => {
    if (!location.id) {
      await createLocation.mutateAsync({
        city: location.city,
        country: location.country[0]?.label,
        state: location.state[0]?.label,
      })
    } else {
      await updateLocation.mutateAsync({
        locationId: location.id,
        city: location.city,
        country: location.country[0]?.label,
        state: location.state[0]?.label,
      })
    }
  }

  const updateUserLocation = async (locationId: number) => {
    await mutateViewer.mutateAsync({ locationId })
    await updateUserToOrgLocation.mutateAsync(locationId)
  }

  const deleteLocation = async (id: number): Promise<any> => {
    try {
      await removeLocaation.mutateAsync(id)
    } catch (e) {
      console.error({ e })
      toast.warning(
        `Unable to delete location. The location is in use by other organization members.`
      )
    }
  }

  return {
    upsertLocation,
    deleteLocation,
    getLocationFormOptions,
    updateUserLocation,
    getHeadquarterId,
    updateHeadquarters,
    addRemoveHeadquarter,
    setHeadquarter,
    locationsOptions,
    currentHeadquarterId,
  }
}
