import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import axios, { Method, AxiosError, CancelTokenSource, AxiosRequestConfig } from 'axios'
import { isUsingProductionServer } from 'services/helper'

const LOCAL_DOMAIN = 'http://localhost:3001'

type DefaultOptions = {
  method?: Method
  isLocal?: boolean
  stagingAudience?: string
  localAudience?: string
  scope?: string
  headers?: any
}

type State<T = any> = {
  error: AxiosError | null
  loading: boolean
  data: T
}

const defaultOptions: DefaultOptions = {
  method: 'post',
  isLocal: false,
  stagingAudience: 'https://qrr3vzquxi.execute-api.us-west-2.amazonaws.com/staging',
  localAudience: LOCAL_DOMAIN,
  scope: '',
}

const getAudience = (isLocal = false) => {
  if (process.env.NODE_ENV !== 'development' && isUsingProductionServer) {
    return 'https://m0qbfdtry8.execute-api.us-west-2.amazonaws.com/dev'
  }

  if (isLocal) {
    return 'http://localhost:3001'
  }

  return 'https://qrr3vzquxi.execute-api.us-west-2.amazonaws.com/staging'
}

export const useApi = (urlPath: string, options = defaultOptions) => {
  const { getAccessTokenSilently } = useAuth0()
  const [state, setState] = useState<State>({
    error: null,
    loading: true,
    data: null,
  })
  const [refreshIndex, setRefreshIndex] = useState(0)

  options = { ...defaultOptions, ...options }

  useEffect(() => {
    ;(async () => {
      try {
        const { scope, isLocal, ...fetchOptions } = options
        const accessToken = await getAccessTokenSilently({
          audience: getAudience(isLocal),
          scope,
        })
        const response = await axios({
          url: `${getAudience(isLocal)}/${urlPath}`,
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        })

        const data = response.data ? response.data.result : null

        setState((prevState) => ({
          ...prevState,
          data,
          loading: false,
        }))
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          error,
          loading: false,
        }))
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshIndex, urlPath])

  return { ...state, refresh: () => setRefreshIndex((prevIndex) => prevIndex + 1) }
}

type UseLaziApi = <T = any>(
  urlPath: string,
  options?: DefaultOptions
) => [(data?: any, source?: CancelTokenSource) => Promise<T>, State<T>]

export const useLazyApi: UseLaziApi = (urlPath: string, options = defaultOptions) => {
  const { getAccessTokenSilently } = useAuth0()
  const [state, setState] = useState<State>({
    error: null,
    loading: false,
    data: null,
  })

  options = { ...defaultOptions, ...options }
  const fetch = async (data = {}, source?: CancelTokenSource) => {
    setState((prevState) => ({ ...prevState, loading: true }))

    try {
      const { scope, isLocal, ...fetchOptions } = options
      const accessToken = await getAccessTokenSilently({
        audience: getAudience(isLocal),
        scope,
      })

      const axiosOptions: AxiosRequestConfig = {
        url: `${getAudience(isLocal)}/${urlPath}`,
        ...fetchOptions,
        data,
        headers: {
          ...fetchOptions.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      }

      if (source) axiosOptions.cancelToken = source.token

      const response = await axios(axiosOptions)

      let responseData = response.data ? response.data.result : null

      if (!responseData) responseData = response.data ? response.data : null

      setState((prevState) => ({
        ...prevState,
        data: responseData,
        loading: false,
      }))

      return responseData
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.error({ error })

      setState((prevState) => ({
        ...prevState,
        error,
        loading: false,
      }))

      return { error: error.response?.data.error || {} }
    }
  }

  return [fetch, state]
}
