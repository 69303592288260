import { useCreateProductListing } from 'data/hooks'
import { ProductState } from 'generated/graphql'
import { useParams } from 'react-router'
import companyListingData from './companyListingData'

export const useCreateFreeProductListing = () => {
  const { slug } = useParams()
  const createProductListing = useCreateProductListing()

  const company = companyListingData.find((company) => slug === company.slug)

  const createFreeProductListing = async (productId: number) => {
    if (!company) return

    createProductListing.mutateAsync({
      associationId: company.associationId,
      productId,
      state: ProductState.Active,
      type: 'premium',
    })
  }

  return createFreeProductListing
}
