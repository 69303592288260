import styled from '@emotion/styled'
import BasicInfoForm from 'components/forms/product/BasicInfoForm'
import { useWizardModal } from 'components/modals/WizardModal'
import { BoxWrapper } from 'components/theme'
// import { useLocation, useNavigate, useParams } from 'react-router'
// import { toast } from 'react-toastify'
// import { useListingStepper } from './ListingStepperContext'
// import { useCreateFreeProductListing } from './useCreateFreeProductListing'

const CreateService = () => {
  // const { orgListings } = useListingStepper()
  const { goNextStep, addData } = useWizardModal()
  // const navigate = useNavigate()
  // const { slug } = useParams()
  // const location = useLocation()
  // const createFreeProductListing = useCreateFreeProductListing()

  // const hasOrgListing = orgListings?.some(({ association }) => slug === association.slug)

  // const isService = location.pathname.includes('select-service')

  const onComplete = async (productId: number) => {
    // if (hasOrgListing) {
    //   await createFreeProductListing(productId)
    //   toast.success(`Added ${isService ? 'service' : 'product'} to listing`)
    //   navigate(`..`)
    // } else {
    //   navigate(`../product-plans/${slug}`, {
    //     state: { productId },
    //   })
    // }
    addData({ productId })
    goNextStep()
  }

  return (
    <Root>
      <BoxWrapper>
        <BasicInfoForm submitButtonText="View Plans" onComplete={onComplete} isService />
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

export default CreateService
