import React from 'react'
import styled from '@emotion/styled'
import UserStory from 'interfaces/userStory/UserStory'

type Props = {
  userStory: UserStory
  step: number
  inProgress?: boolean
  boldedKeywords?: boolean
  withPills?: boolean
}

const StoryContent: React.FC<Props> = ({
  userStory: {
    id,
    role,
    firmType,
    practiceGroup,
    stage,
    when,
    product,
    feature,
    to
  },
  boldedKeywords = false,
  withPills = false,
  step,
  inProgress = false
}) => {
  const doesThisEndInAPeriod = (words: string) =>
    words && words[words.length - 1] === '.' ? true : false

  return (
    <Root>
      <div>
        <Text>
          <B bolded={boldedKeywords}>When </B>
          {when ? when : !inProgress ? '' : `[ something happens ]`}
          <B bolded={boldedKeywords}> use </B>
          {product?.name ? `${product.name}` : !inProgress ? '' : `[ this product ]`}
          {feature?.type > 0 && (
            <i> {feature.name || !inProgress ? '' : `[ this feature ]`} </i>
          )}{' '}
          <B bolded={boldedKeywords}>to </B>
          {to ? to : !inProgress ? '' : `[ do something ]`}
          {!doesThisEndInAPeriod(to) && '.'}
        </Text>
        {withPills && (
          <div>
            {(step === 1 || stage.length > 0) && (
              <>
                <H4>Stages(s)</H4>
                <Pills>
                  {stage.length > 0 ? (
                    stage.map((stage, i) => <Pill key={`${id}-stage-${i}`}>{stage}</Pill>)
                  ) : inProgress && step === 1 ? (
                    <Pill>a stage of your workflow</Pill>
                  ) : (
                    <></>
                  )}
                </Pills>
              </>
            )}
            {(step === 2 || firmType?.name || role?.name || practiceGroup?.name) && (
              <>
                <H4>For</H4>
                <Pills>
                  {inProgress && !role && !firmType && !practiceGroup && (
                    <Pill>specific people or groups</Pill>
                  )}
                  {firmType?.name && <Pill>{firmType.name}</Pill>}
                  {role?.name && <Pill>{role.name}</Pill>}
                  {practiceGroup?.name && <Pill>{practiceGroup.name}</Pill>}
                </Pills>
              </>
            )}
          </div>
        )}
      </div>
    </Root>
  )
}

const Text = styled.div``

const Root = styled.div`
  display: flex;
  margin: 5px;
`

const B = styled.span<{ bolded: boolean }>`
  ${({ bolded }) => (bolded ? 'font-weight: 500;' : '')}
  color: black;
`

const Pills = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 90%;
  margin: 0 -2px;
`

const Pill = styled.div`
  border: 1px solid #aaa;
  padding: 2px 6px;
  margin: 2px 2px;
  border-radius: 20px;
  white-space: nowrap;
  font-size: 12px;
`

const H4 = styled.h4`
  margin: 4px 0 !important;
  font-size: 14px;
`

export default StoryContent
