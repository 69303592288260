import React from 'react'
import styled from '@emotion/styled'
import { Popup, Icon } from 'semantic-ui-react'

const YourAppsPopup: React.FC<{ text: string }> = ({ text }) => (
  <Popup
    inverted
    styles={{
      background: '#333',
    }}
    trigger={
      <HelpWrapper>
        <HelpIcon name="info circle" />
      </HelpWrapper>
    }
  >
    <Popup.Content>{text}</Popup.Content>
  </Popup>
)

const HelpWrapper = styled.div`
  margin-top: -8px !important;
`

const HelpIcon = styled(Icon)`
  color: #00a2ff;
  margin-left: 3px !important;
  font-size: 14px !important;
`

export default YourAppsPopup
