import slugify from 'slugify'
import awsConfig from 'aws-exports'
import moment from 'moment'
import { isPublicEmail } from './isPublicEmail'
import { format } from 'date-fns'

const TEST_DOMAINS = [
  'theoremfirm.com',
  'theoremlegal.com',
  'theoremvendor.com',
  'theoremlts.com',
  'albertdeguzman.com',
  'designbymilano.com',
]

export const isTestEmail = (email: string): boolean => {
  const testMode = TEST_DOMAINS.includes(email.split('@')[1])

  return testMode
}

export const newDeletedList = <T>(initialList: T[], currentList: T[]) => {
  const noUndefinedInitialList = initialList.filter((item: T): item is T => item !== undefined)
  const noUndefinedCurrentList = currentList.filter((item: T): item is T => item !== undefined)

  const newList = noUndefinedCurrentList.filter((id) => !noUndefinedInitialList.includes(id))

  const deletedList = noUndefinedInitialList.filter((id) => !noUndefinedCurrentList.includes(id))

  return [newList, deletedList]
}

export const fixCurrencyDisplay = (amount: number): string => {
  const usdCurrency = (amount / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  if (usdCurrency.match(/^\$[1-9]\d?(?:,\d{3})*(.00)?$/g)) {
    return usdCurrency.substring(0, usdCurrency.length - 3)
  }

  return usdCurrency
}

type FormatStripeData = (stripeTimestamp: number, format?: string) => string
export const formatStripeDate: FormatStripeData = (
  stripeTimestamp,
  dateFormat = 'MMMM d, yyyy'
) => {
  return format(stripeTimestamp * 1000, dateFormat)
}

export const getSlug = (text: string): string => {
  return slugify(text, { lower: true })
}

export const parseJson = <T>(obj: T) => {
  if (!obj) return {}
  return typeof obj === 'string' ? JSON.parse(obj) : obj
}

interface ListWithId<T> {
  id: T
  [key: string]: any
}

export const getIndexOfId = <T>(list: readonly ListWithId<T>[], id: T): number => {
  return list.map(({ id }) => id).indexOf(id)
}

/**
 * Use this to convert postgres TIMESTAMP into your local time. Postgres uses UTC as it default time zone
 * @constructor
 * @param {string} utcTimezone - Timezone from SQL entry
 * @param {string} format - MomentJS format
 */
export const getLocalTime = (utcTimezone: string, format = 'MMM DD YYYY h:mm A'): string => {
  return moment.utc(utcTimezone).local().format(format)
}

export const extractDomainFromEmail = (email: string): string => {
  let domain = email
  if (!isPublicEmail(email)) {
    const regex = new RegExp(/@.*/g)
    domain = email.match(regex)?.[0].replace('@', '') || email
  }

  return domain
}

export const getCurrencySymbol = (currency: string, locale?: string) =>
  (0)
    .toLocaleString(locale || 'en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()

export const isUsingStagingServer: boolean =
  awsConfig.aws_content_delivery_bucket === 'theorem-prod-staging'

export const isUsingProductionServer: boolean =
  awsConfig.aws_content_delivery_bucket === 'theorem-prod-dev'
