import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { GqlGetAssocHomeQuery } from 'generated/graphql'

export const useAssocHome = (assocHomeId: number) => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['assocHome', assocHomeId],
    async () => {
      const response = await request<GqlGetAssocHomeQuery>(ASSOC_HOME_BY_ID, {
        assocHomeId: assocHomeId,
      })

      return response.assocHomeById
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  )

  return { assocHome: data, ...other }
}

const ASSOC_HOME_BY_ID = gql`
  query GetAssocHome($assocHomeId: Int!) {
    assocHomeById(id: $assocHomeId) {
      id
      details
      type
      updatedAt
      createdAt
      order
    }
  }
`
