import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { GqlGetAdminProductsQuery } from 'generated/graphql'

export const useGetAllAdminProducts = () => {
  const request = useGql()

  const { data, ...other } = useQuery(
    ['adminProducts'],
    async () => {
      const response = await request<GqlGetAdminProductsQuery>(GET_PRODUCTS)

      return response.products
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  )

  return { products: data, ...other }
}

const GET_PRODUCTS = gql`
  query GetAdminProducts {
    products {
      createdAt
      description
      details
      excerpt
      id
      slug
      name
      state
      updatedAt
      testMode
      productToOrganizations {
        organization {
          name
          id
        }
      }
    }
  }
`
