import { Formik } from 'formik'
import TextInput from 'components/theme/form/TextInput'
import Button from 'components/theme/Button'
import UploadMultipleAssets from 'components/fields/UploadMultipleAssets'
import { useMutateProductDetails } from 'data/hooks'
import { extractPictureUrl, handleImageUpload } from 'services/image'
import styled from '@emotion/styled'
import * as yup from 'yup'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import HelpPopupContent from 'components/HelpPopContent'
import { css } from '@emotion/react'

const defaultValues = {
  demoVideo: '',
  slides: [],
  bannerImage: '',
}

const validationSchema = yup.object().shape({
  demoVideo: yup.string().trim().nullable(),
})

function ExtraForm({
  initialValues = defaultValues,
  product,
  submitButtonText = 'NEXT (ADD INTEGRATIONS)',
  onComplete,
  isService = false,
}: any) {
  const mutateProductDetails = useMutateProductDetails()

  const onSubmit = async ({ demoVideo, slides = [], bannerImage }: any) => {
    if (!product) return

    const extractedBannerImage = await extractPictureUrl(bannerImage)
    const sliderPromise = slides.map(async (slide: any) => {
      if (typeof slide.file === 'string') return slide.file

      const slideUrl = await handleImageUpload(slide.file)
      return slideUrl
    })

    const sliderUrls = await Promise.all(sliderPromise)

    const details = {
      ...product.details,
      demoVideo,
      slides: sliderUrls,
      bannerImage: extractedBannerImage,
    }

    mutateProductDetails.mutate(
      {
        productId: product.id,
        details,
      },
      {
        onSuccess: () => onComplete(),
      }
    )
  }

  return (
    <Root>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        ignoreLoading
        validateOnMount
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting, isValid }) => (
          <form onSubmit={handleSubmit}>
            {/* <PromptIfDirty /> */}
            <FormField>
              <TextInput
                name="demoVideo"
                placeholder="Youtube, Vimeo, or Wistia link"
                helpContent={
                  <div>
                    <HelpLine>
                      <strong>Youtube:</strong> https://www.youtube.com/watch?v=[VIDEO_ID]
                    </HelpLine>
                    <HelpLine>
                      <strong>Vimeo:</strong> https://vimeo.com/[VIDEO_ID]
                    </HelpLine>
                    <HelpLine>
                      <strong>Wistia:</strong> https://[SLUG].wistia.com/medias/[VIDEO_ID]
                    </HelpLine>
                  </div>
                }
                icon="linkify"
                label="Video URL"
              />
            </FormField>

            {!isService ? (
              <FormField>
                <UploadMultipleAssets
                  onChange={setFieldValue}
                  name="slides"
                  isService={isService}
                />
              </FormField>
            ) : (
              <FormField>
                <UploadSingleAsset
                  label="Banner Image"
                  name="bannerImage"
                  selectedImage={values.bannerImage}
                  helpContent={<HelpPopupContent />}
                  imageStyles={css`
                    width: auto;
                    display: flex;
                    justify-content: center;
                  `}
                  styles={css`
                    max-width: none;
                  `}
                >
                  Upload Wide Banner Image
                </UploadSingleAsset>
              </FormField>
            )}

            <FormField>
              <ButtonContainer>
                <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                  {submitButtonText}
                </Button>
              </ButtonContainer>
            </FormField>
          </form>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  max-width: 760px;
`

const FormField = styled.div`
  margin-bottom: 20px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

const HelpLine = styled.div`
  white-space: nowrap;
`

export default ExtraForm
