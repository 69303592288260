import styled from '@emotion/styled'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useListingStepper } from '../ListingStepperContext'
import { ProductType } from 'generated/graphql'
import { useOrg } from 'data/hooks'

const DOMAIN =
  process.env.NODE_ENV === 'development'
    ? `https://ecosystem.theorem.local:3300`
    : 'https://ecosystem.theoremlegal.com'

const ActiveProducts = () => {
  const { productListings } = useListingStepper()
  const baseOrgUrl = useBaseOrgUrl()
  const { organization } = useOrg()
  const { slug } = useParams()
  const location = useLocation()

  const isService = location.pathname.includes('select-service')

  if (!productListings || productListings.length === 0) return null

  const selectedListings = productListings.filter(
    ({ product, association }) =>
      association.slug === slug &&
      ((product.type === ProductType.Service && isService) ||
        (product.type === ProductType.Software && !isService))
  )

  if (selectedListings.length === 0) return null

  return (
    <Root>
      <SmallTitle>
        Current {isService ? 'service' : 'product'} listings with {slug?.toUpperCase()}:
      </SmallTitle>
      <List>
        {selectedListings.map(({ product }) => {
          const { type } = product
          const routeType = type === ProductType.Service ? 'service' : 'product'
          const previewUrl = `${DOMAIN}/api/auth/login?returnTo=/${slug}/${routeType}/${organization?.slug}`
          return (
            <ProductRow>
              <Left>
                <ProductLogo src={product.details.squareLogoUrl} />
                <ProductName>{product.name}</ProductName>
              </Left>
              <ActionButtons>
                <ViewLink href={previewUrl} target="_blank">
                  View
                </ViewLink>{' '}
                <Link to={`${baseOrgUrl}/product/settings/${product.id}`}>Edit</Link>
              </ActionButtons>
            </ProductRow>
          )
        })}
      </List>
    </Root>
  )
}

const Root = styled.div`
  margin-top: 32px;
  background: #fafafa;
  padding: 20px;
  border: 1px dashed #b4b4b4;
  border-radius: 6px;
`

const ViewLink = styled.a`
  margin-right: 16px;
`

const ProductLogo = styled.img`
  max-width: 16px;
`

const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
`

const SmallTitle = styled.h4`
  margin-bottom: 20px;
`

const List = styled.div``

const Left = styled.div`
  display: flex;
  align-items: center;
`

const ProductName = styled.div`
  white-space: nowrap;
  padding-left: 8px;
`

const ActionButtons = styled.div`
  margin-left: auto;
  margin-right: 0;

  & > a {
    font-weight: 500;
  }
`

export default ActiveProducts
