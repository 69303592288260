import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import PrevalentWidget from './PrevalentWidget'
import VendorUpgradeModal from 'components/modals/VendorUpgradeModal'
import { useViewer } from 'context/viewerContext'
import _ from 'lodash'
import Products from '../products/Products'

function VendorProfile() {
  const location = useLocation()
  const { paymentStatus } = useViewer()
  const locationState = location.state as any
  const [showModal, setShowModal] = useState(locationState?.firstEnter)

  useEffect(() => window.scrollTo(0, 0), [])

  useEffect(() => {
    const showSuccess = _.get(location, 'showSuccess', false)
    if (showSuccess) toast.success('Congrats! Your account has been created. 🎉')

    const message = _.get(location, 'message', '')
    if (message) toast.success(message)

    const inviteCode = localStorage.getItem('invite_code')
    if (!inviteCode) return

    toast.success('Congrats! Profile updated. 🎉')
    localStorage.removeItem('invite_code')
    localStorage.removeItem('invite_email')
  }, [location])

  const hideModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <Wrapper>
        <Products />
      </Wrapper>
      <Root>
        <PrevalentWidget />
      </Root>
      <VendorUpgradeModal
        showModal={showModal && paymentStatus === 'NOT_ACTIVE'}
        toggleModal={hideModal}
      />
    </>
  )
}

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const Root = styled.div`
  padding-top: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

export const Description = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  color: #636363;
`

export const Box = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 30px;
`

export const ProductsBox = styled(Box)`
  min-height: 206px;
  background: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
`

export const BoxTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #545454;
`

export default VendorProfile
