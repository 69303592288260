import React from 'react'
import styled from '@emotion/styled'

type Props = {
  children?: React.ReactNode
  title: string
  image: any
}
const ComingSoon = ({ title, image, children }: Props) => {
  return (
    <Root>
      <Title>{title}</Title>
      {children && <Content>{children}</Content>}
      <Image src={image} />
    </Root>
  )
}

const Root = styled.div`
  text-align: center;
`

const Title = styled.div`
  color: #512da8;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
  max-width: 760px;
  margin: 0 auto 28px;
`

const Image = styled.img`
  width: 100%;
  max-width: 760px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.07);
`

const Content = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  max-width: 600px;
  margin: 0 auto 40px;
`

export default ComingSoon
