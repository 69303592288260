import React from 'react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { Popup, Icon } from 'semantic-ui-react'

type TabMenuItem = {
  url: string
  text: string | React.ReactNode
  helpContent?: string | React.ReactNode
  exact?: boolean
}

type Props = {
  menuItems: TabMenuItem[]
}

function TabsMenu({ menuItems }: Props) {
  return (
    <Root>
      {menuItems.map(({ url, text, exact, helpContent }) => (
        <Link key={url} to={url} end={exact}>
          {text}
          {helpContent && (
            <StyledPopup
              inverted
              styles={{
                background: '#333',
              }}
              trigger={
                <div>
                  <HelpIcon name="info circle" />
                </div>
              }
            >
              <Popup.Content>{helpContent}</Popup.Content>
            </StyledPopup>
          )}
        </Link>
      ))}
    </Root>
  )
}

const StyledPopup = styled(Popup)`
  z-index: 20000 !important;
`

const HelpIcon = styled(Icon)`
  color: #00a2ff;
  margin-left: 8px !important;
`

const Root = styled.div`
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  margin-bottom: 30px;
`

const Link = styled(NavLink)`
  position: relative;
  display: inline-flex;
  margin: 0;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.87);

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #9013fe;
    opacity: 0;
    transition: 250ms opacity;
  }

  &.active:after,
  &:hover:after {
    opacity: 1;
  }

  &.active {
    font-weight: 500;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.87);
  }
`

export default React.memo(TabsMenu)
