import { useOrg } from 'data/organization/useOrg'
import { GqlProductListingsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'

// NOTE: the issue with this is that it only grabs a single product listing. Will need to be refactored to support more, but this will suffice for CLOC
export const useProductListings = () => {
  const request = useGql()
  const { organization } = useOrg()

  const { data, ...other } = useQuery(
    ['product-listing', organization?.id],
    async () => {
      const result = await request<GqlProductListingsQuery>(GET_PRODUCT_LISTINGS, {
        organizationId: organization?.id,
      })

      return result?.productListings
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  )

  return { productListings: data, ...other }
}

const GET_PRODUCT_LISTINGS = gql`
  query ProductListings($organizationId: UUID) {
    productListings(condition: { organizationId: $organizationId }) {
      id
      association {
        id
        name
        slug
      }
      product {
        name
        slug
        type
        id
        details
        type
      }
      type
      state
    }
  }
`
