import styled from '@emotion/styled'
import { Routes, Route } from 'react-router'
import TestDriveList from 'components/vendors/testDrive/TestDriveList'
import TestDriveCreate from 'components/vendors/testDrive/TestDriveCreate'
import TestDriveEdit from 'components/vendors/testDrive/TestDriveEdit'

const TestDrive = () => {
  return (
    <Root>
      <Routes>
        <Route path="/" element={<TestDriveList />} />
        <Route path="create" element={<TestDriveCreate />} />
        <Route path="edit/:testDriveId" element={<TestDriveEdit />} />
      </Routes>
    </Root>
  )
}

const Root = styled.div``

export default TestDrive
