import { newDeletedList } from 'services/helper'
import { useViewer } from 'context/viewerContext'
import { Viewer } from 'data/types'
import { useMutateUsersToTechstacks } from 'data/hooks'

export const useUserOrgTechstack = () => {
  const { viewer } = useViewer()
  const mutateUserToTechstack = useMutateUsersToTechstacks()
  const userTechstackIds = parseUserTechstackIds(viewer)

  const onSubmit = async (selectedTechstackIds: number[]) => {
    const [createdTechstackIds, deletedTechstackIds] = newDeletedList(
      userTechstackIds,
      selectedTechstackIds
    )

    await mutateUserToTechstack.mutateAsync({ createdTechstackIds, deletedTechstackIds })
  }

  return { onSubmit, userTechstackIds }
}

const parseUserTechstackIds = (user: Viewer): number[] => {
  if (user.techstack.length === 0) return []

  return user.techstack.map((product) => product.techstackId)
}
