import React from 'react'
import { Button, Icon } from 'components/theme'
import styled from '@emotion/styled'
import StripeHeaderMetaData from './StripeHeaderMetaData'

const PlanHeader = ({ resource, index, closePlan }: any) => (
  <>
    <Root isStripe={resource.stripeId}>
      <PlanCount isStripe={resource.stripeId}>
        {resource.stripeId ? <Icon name="dollar-sign" /> : <>{index + 1}</>}
      </PlanCount>

      {resource.stripeId && <StripeHeaderMetaData resource={resource} />}
      <div>
        <CancelButton type="button" onClick={closePlan}>
          Cancel
        </CancelButton>
        <SaveButton type="submit">Save</SaveButton>
      </div>
    </Root>
  </>
)

const Root = styled.div<{ isStripe: boolean }>`
  background: ${({ isStripe }) =>
    isStripe
      ? 'linear-gradient(90deg, rgba(139,195,74,1) 0%, rgba(87,223,164,1) 100%)'
      : '#f5f5f5'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #bdbdbd;
`

const CancelButton = styled(Button)`
  color: #aaa;
  border-color: #aaa;
  background-color: white;
  margin-right: 20px;

  &:hover {
    border-color: #aaa;
    color: white;
    background-color: #aaa;
  }
`
const SaveButton = styled(Button)``

const PlanCount = styled.div<{ isStripe: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${({ isStripe }) => (isStripe ? 'rgba(0,0,0,.3)' : '#b9b9b9')};

  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 900;
  line-height: 0;
`

export default PlanHeader
