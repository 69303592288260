import styled from '@emotion/styled'
import { User } from 'interfaces'
import { FC } from 'react'
import UserRow from './UserRow'

interface Props {
  users: User[]
  toggleModal: () => void
}

const UsersTable: FC<Props> = ({ users, toggleModal }) => {
  return (
    <Root>
      <Table>
        <thead>
          <tr>
            <Th>USERS</Th>
            <Th>ACTIONS</Th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <UserRow key={`UserRow--${user.id}`} user={user} toggleModal={toggleModal} />
          ))}
        </tbody>
      </Table>
    </Root>
  )
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const Th = styled.th`
  text-align: left;
  letter-spacing: 0.02rem;
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
`

const Root = styled.div``

export default UsersTable
