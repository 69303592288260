import { Route, Routes } from 'react-router-dom'
import AuthSelect from './AuthSelect'
import Signup from './Signup'
import EmailSent from './EmailSent'
import LogoSvg from 'assets/networks/cloc/cloc-blue-icon.svg'
import LongLogoSvg from 'assets/networks/cloc/cloc-blue.svg'

const AUTH0_ORG_ID = 'org_7IefMs3ethVVq9Ue'

const Login = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthSelect auth0OrgId={AUTH0_ORG_ID} logo={LongLogoSvg} />} />
      <Route path="signup" element={<Signup auth0OrgId={AUTH0_ORG_ID} logo={LogoSvg} />} />
      <Route path="email-sent" element={<EmailSent logo={LogoSvg} />} />
    </Routes>
  )
}

export default Login
