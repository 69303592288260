import { Route, Routes } from 'react-router-dom'
import AuthSelect from './AuthSelect'
import Signup from './Signup'
import EmailSent from './EmailSent'
import LogoSvg from 'assets/networks/in-house-connect/blue-logo.jpg'

const AUTH0_ORG_ID = 'org_SwBJRkmT1wowTFaK'

const Login = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthSelect auth0OrgId={AUTH0_ORG_ID} logo={LogoSvg} />} />
      <Route
        path="signup"
        element={<Signup auth0OrgId={AUTH0_ORG_ID} logo={LogoSvg} networkName="In-House Connect" />}
      />
      <Route path="email-sent" element={<EmailSent logo={LogoSvg} />} />
    </Routes>
  )
}

export default Login
