import React, { useState } from 'react'
import styled from '@emotion/styled'
import Modal from 'components/Modal'
import { Button } from 'components/theme'
import { useDeleteUserToOrg } from 'data/hooks'

type Props = {
  rejectedUser?: number | string
  showModal: boolean
  toggleModal: () => void
  onConfirmClick?: () => void
}

const RejectModal: React.FC<Props> = ({ rejectedUser, showModal, toggleModal, onConfirmClick }) => {
  const [loading, setLoading] = useState(false)
  const deleteUserToOrg = useDeleteUserToOrg()

  if (!rejectedUser) return null

  const rejectUser = async () => {
    if (loading) return

    try {
      setLoading(true)
      const userId = typeof rejectedUser === 'string' ? parseInt(rejectedUser, 10) : rejectedUser
      await deleteUserToOrg.mutateAsync({ userId })
      onConfirmClick && onConfirmClick()
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <Modal isShowing={showModal} toggleModal={toggleModal} width="510px">
      <p>Are you sure you want to reject this user.</p>
      <ButtonWrapper>
        <CancelButton onClick={toggleModal}>Cancel</CancelButton>
        <ConfirmButton onClick={rejectUser}>Confirm</ConfirmButton>
      </ButtonWrapper>
    </Modal>
  )
}

const ButtonWrapper = styled.div``

const CancelButton = styled(Button)`
  margin-right: 16px;
`

const ConfirmButton = styled(Button)``

export default RejectModal
