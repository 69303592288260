import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { useGql } from 'hooks/useGql'
import { gql } from 'graphql-request'
import { useViewer } from 'context/viewerContext'

interface Variables {
  userId?: number
  organizationId?: string
}
export const useDeleteUserToOrg = () => {
  const request = useGql()
  const { organization } = useOrg()
  const { viewer } = useViewer()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { userId, organizationId } = variables
      if (!organization) return

      await request(DELETE_USER_TO_ORG, {
        userId: userId || viewer.id,
        organizationId: organizationId || organization.id,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['viewer']),
    }
  )
}

const DELETE_USER_TO_ORG = gql`
  mutation DeleteUsersToOrganization($userId: Int!, $organizationId: UUID!) {
    deleteUsersToOrganization(input: { userId: $userId, organizationId: $organizationId }) {
      clientMutationId
    }
  }
`
