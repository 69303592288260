import { useQuery } from '@tanstack/react-query'
import { GqlGetTermsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useAllTerms = () => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['terms'],
    async () => {
      const response = await request<GqlGetTermsQuery>(GET_TERMS)

      return response.terms
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  )

  return { terms: data, ...other }
}

export const GET_TERMS = gql`
  query GetTerms {
    terms {
      id
      name
      slug
    }
  }
`
