import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export const useWizard = ({ pages = [], initialStep = 0 }: any) => {
  const [currentStep, setCurrentStep] = useState(initialStep)
  const navigate = useNavigate()
  const location = useLocation()

  const state = typeof location.state === 'object' ? location.state : {}

  useEffect(() => {
    const browserBackEvent = () => {
      goPrevStep()
    }

    window.addEventListener('popstate', browserBackEvent)

    return () => {
      window.removeEventListener('popstate', browserBackEvent)
    }
  }, [])
  useEffect(() => {
    const [slug] = pages[currentStep]
    navigate(`${slug}`, { replace: true, state })
    // history.replace(`${url}/${slug}`, { ...location.state })
  }, [history, currentStep, pages])

  const goNextStep = () => {
    setCurrentStep((prevStep: any) => (prevStep < pages.length - 1 ? prevStep + 1 : prevStep))
  }

  const goPrevStep = () => {
    setCurrentStep((prevStep: any) => (prevStep > 0 ? prevStep - 1 : prevStep))
  }

  const atFirstStep = currentStep === 0
  const atLastStep = currentStep === pages.length - 1

  return { goNextStep, goPrevStep, currentStep, atFirstStep, atLastStep }
}
