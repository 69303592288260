import { useQuery } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { GqlGetUserToTechstacksQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useUserToTechstack = () => {
  const request = useGql()
  const { viewer } = useViewer()

  const { data, ...other } = useQuery(
    ['userToTechstack'],
    async () => {
      const response = await request<GqlGetUserToTechstacksQuery>(GET_STACKS_AND_LOGINS, {
        userId: viewer.id,
      })

      return response.usersToTechstacks
    },
    { staleTime: 1000 * 60 * 5 }
  )

  return {
    userToTechstacks: data,
    ...other,
  }
}

const GET_STACKS_AND_LOGINS = gql`
  query GetUserToTechstacks($userId: Int!) {
    usersToTechstacks(condition: { userId: $userId }) {
      techstack {
        id
        product {
          id
        }
      }
      logins
    }
  }
`
