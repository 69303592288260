import styled from '@emotion/styled'
import AppMarketplaceImage from 'assets/menu/app-marketplace.png'
import LawfirmImage from 'assets/menu/lawfirm-marketplace.svg'
import SolutionsImage from 'assets/menu/solutions.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
// import AssetImage from 'assets/menu/assets.svg'

const MarketplaceDropdown = ({ isShow }: { isShow: boolean }) => {
  const marketplaceUrl = useMarketplaceUrl()
  const { user: auth0User } = useAuth0()

  const domain =
    process.env.NODE_ENV === 'development'
      ? `https://ecosystem.theorem.local:3300`
      : 'https://ecosystem.theoremlegal.com'
  let loginUrl = `${domain}/api/auth/login?`

  if (auth0User?.org_id) {
    loginUrl = `${loginUrl}organization=${auth0User?.org_id}&`
  }

  return (
    <Root isShow={isShow}>
      <Wrapper>
        <Container>
          <AnchorItem href={marketplaceUrl} target="_blank">
            <Icon src={AppMarketplaceImage} />
            <Right>
              <Name>App Marketplace</Name>
              <Text>Search our LegalTech App Store</Text>
            </Right>
          </AnchorItem>
          <AnchorItem href={`${loginUrl}returnTo=/lawfirms`} target="_blank">
            <Icon src={LawfirmImage} />
            <Right>
              <Name>Law Firm Marketplace</Name>
              <Text>Discover Firm Innovation</Text>
            </Right>
          </AnchorItem>
          <AnchorItem href={`${loginUrl}returnTo=/services`} target="_blank">
            <Icon src={SolutionsImage} />
            <Right>
              <Name>Solutions Directory</Name>
              <Text>Find your perfect service provider match</Text>
            </Right>
          </AnchorItem>

          {/*
          <AnchorItem href={`${loginUrl}?returnTo=/lawfirm`} target="_blank">
            <Icon src={AssetImage} />
            <Right>
              <Name>Asset Marketplace</Name>
              <Text>Discover/Sell templates data and documents</Text>
            </Right>
          </AnchorItem>
        */}
        </Container>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div<{ isShow?: boolean }>`
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  position: absolute;
  top: ${({ theme }) => theme.topBarHeight};
  right: 0;
  padding-top: 8px;
  min-width: 340px;
  text-align: left;
`

const Icon = styled.img`
  max-width: 52px;
  width: 100%;
`

const Right = styled.div`
  margin-left: 12px;
  white-space: nowrap;
`

const Name = styled.div`
  font-weight: 600;
  letter-spacing: 0.02rem;
`

const Text = styled.div`
  font-weight: 400;
  font-size: 13px;
`

const AnchorItem = styled.a`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  color: #585a5f;
  padding: 8px 12px;
  transition: 250ms background, 250ms color;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;

  &:hover {
    background: #eee;
    color: #222;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  ${({ theme }) => theme.breakpoint.mobile} {
    padding-left: 8px;
  }
`

const Wrapper = styled.div`
  min-width: 340px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid #d5d5d5;

  ${({ theme }) => theme.breakpoint.mobile} {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    min-width: none;
  }
`

const Container = styled.div`
  border-radius: 6px;
  overflow: hidden;

  ${({ theme }) => theme.breakpoint.mobile} {
    border-radius: 0;
  }
`

export default MarketplaceDropdown
