import styled from '@emotion/styled'
import { useLogout } from 'hooks/useLogout'
import UserInfoHeader from './UserInfoHeader'
import UserMenuLinks from './UserMenuLinks'

const MainUserMenuDropdown = () => {
  const logout = useLogout()
  return (
    <Root>
      <UserInfoHeader />
      <UserMenuLinks />
      <Footer>
        <BottomLink onClick={logout}>Logout</BottomLink>
        <a href="https://www.theoremlegal.com/legal" target="_blank">
          <BottomLink>Legal</BottomLink>
        </a>
      </Footer>
    </Root>
  )
}

const Root = styled.div``

const Footer = styled.footer`
  border-top: 1px solid #d3d3d3;
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
`

const BottomLink = styled.div`
  color: #498de6;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #1e70bf;
  }
`

export default MainUserMenuDropdown
