import React from 'react'
import styled from '@emotion/styled'
import { useSelectTechStackContext } from './SelectTechStackContext'

type Props = { id: string, title: string, logo: string }

const SelectedAppPill: React.FC<Props> = ({ id, title, logo }) => {
  const { dispatch } = useSelectTechStackContext()
  
  const toggleSelectedApp = () => {
    dispatch({ type: 'TOGGLE_APP_SELECTION', payload: { id, title, logo } })
  }

  return (
    <Pill onClick={toggleSelectedApp}>
      {logo && <Logo src={logo} />}
      <Title>{title}</Title>
      X
    </Pill>
  )
}

const Title = styled.div`
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  margin: 0 9px;
  color: black;
`

const Pill = styled.div`
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 3px;
  color: #aaa;
  font-weight: 600;
  font-size: 12px;
  margin: 1px 5px 5px 0;
  padding: 5px;
  position: relative;
  box-shadow: inset 0 0 2px #e0e0e0;
  cursor: pointer;
`

const Logo = styled.img`
  width: 20px;
  height: 20px;
  margin: 2px 0;
`

export default SelectedAppPill