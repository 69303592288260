import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Popup, Icon } from 'semantic-ui-react'

type Props = {
  children: ReactNode
  helpContent?: string | React.ReactNode
  required?: boolean
}

const Label = ({ children, helpContent = '', required = false }: Props) => {
  return (
    <Root>
      {children} {required && <RequiredAsterisk>*</RequiredAsterisk>}
      {helpContent && (
        <StyledPopup
          inverted
          styles={{
            background: '#333',
          }}
          trigger={
            <div>
              <HelpIcon name="info circle" />
            </div>
          }
        >
          <Popup.Content>{helpContent}</Popup.Content>
        </StyledPopup>
      )}
    </Root>
  )
}

const StyledPopup = styled(Popup)`
  z-index: 20000 !important;
`

const Root = styled.div`
  display: flex;
  color: #616161;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
`

const RequiredAsterisk = styled.span`
  color: red;
  margin-left: 4px;
`

const HelpIcon = styled(Icon)`
  color: #00a2ff;
  margin-left: 8px !important;
`

export default Label
