import styled from '@emotion/styled'
import { useWizardModal } from 'components/modals/WizardModal'
import LawfirmFlow from './LawfirmFlow'
import ProductFlow from './ProductFlow'
import ServiceFlow from './ServiceFlow'

const AddListingItem = () => {
  const { data } = useWizardModal()

  // this components needs to have the ability to switch depending on the data

  return (
    <Root>
      {data.listingType === 'product' && <ProductFlow />}
      {data.listingType === 'service' && <ServiceFlow />}
      {data.listingType === 'lawfirm' && <LawfirmFlow />}
    </Root>
  )
}

const Root = styled.div``

export default AddListingItem
