import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'
import { useViewer } from 'context/viewerContext'
import { MemberStatus, OrganizationType, UserRole } from 'generated/graphql'
import { useRedirect } from 'hooks/useRedirect'
import { MutateViewerVariables } from 'data/types'
import { useMutateViewer, useAppMetadata, useUpdateMemberStatus, useOrg } from 'data/hooks'
import { useAuth0 } from '@auth0/auth0-react'

const OrgToNetworkSuccess = () => {
  const redirect = useRedirect()
  const { organization } = useOrg()
  const { refetch, viewer } = useViewer()
  const { data: appMetadata } = useAppMetadata()
  const mutateViewer = useMutateViewer()
  const updateMemberStatus = useUpdateMemberStatus()
  const { user: auth0User } = useAuth0()

  useEffect(() => {
    refetch()
  }, [])

  const continueClick = async () => {
    const assocOrgId = appMetadata?.assocOrg?.id

    let viewerMutateVariables: MutateViewerVariables = {
      deleteAppMetadataKeys: Object.keys(appMetadata),
    }

    if (appMetadata.assocOrg && organization?.type !== OrganizationType.Vendor) {
      if (organization?.type !== OrganizationType.Lawfirm) {
        viewerMutateVariables = {
          ...viewerMutateVariables,
          currentOrganizationId: assocOrgId,
        }
      }

      await updateMemberStatus.mutateAsync({
        status: MemberStatus.Active,
        organizationId: assocOrgId,
      })
    }

    await mutateViewer.mutateAsync(viewerMutateVariables)

    if (
      organization?.type === OrganizationType.Company ||
      (assocOrgId === '5a172a54-130e-4cf6-8416-625f73950d98' &&
        organization?.type === OrganizationType.Association)
    ) {
      const assocSlug = assocOrgId === '5a172a54-130e-4cf6-8416-625f73950d98' ? 'cloc' : 'acc'
      const domain =
        process.env.NODE_ENV === 'development'
          ? `https://ecosystem.theorem.local:3300`
          : 'https://ecosystem.theoremlegal.com'
      let marketplaceUrl = `${domain}/api/auth/login?returnTo=/a/${assocSlug}&`

      if (auth0User?.org_id) {
        marketplaceUrl = `${marketplaceUrl}organization=${auth0User?.org_id}`
      }

      window.location.replace(marketplaceUrl)
    } else {
      redirect('getting-started')
    }
  }

  const association = appMetadata.assocOrg

  if (!organization || !association) return <div></div>

  return (
    <Root>
      <Container>
        <Logo src={association.details.logo} />
        <Headline>
          {organization?.id === association.id || viewer.role !== UserRole.Owner ? (
            <>You have been added to</>
          ) : (
            <>
              <strong>{organization.name}</strong> has been added to
            </>
          )}
          <br />
          <strong>{association.name}'s</strong> network.
        </Headline>
        <Content>
          You have access to their private network of users, vendor directory, and StackShare™
        </Content>
        <Button onClick={continueClick}>Continue</Button>
      </Container>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`

const Headline = styled.p`
  font-size: 28px;
  margin-bottom: 16px;
  line-height: 1.4;
`

const Content = styled.p`
  font-size: 16px;
  max-width: 530px;
  margin: 0 auto 28px;
`

const Container = styled.div`
  text-align: center;
`

const Logo = styled.img`
  max-width: 180px;
`

export default OrgToNetworkSuccess
