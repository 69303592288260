import { useOrg } from 'data/organization/useOrg'
import { useNavigate } from 'react-router'

export const useRedirect = () => {
  const navigate = useNavigate()
  const { organization } = useOrg()

  const redirect = (path?: string, state?: { [key: string]: any }, replace = false) => {
    if (!organization) return

    const baseUrl = `/${organization.type.toLowerCase()}/${organization.id}`

    if (!path) return navigate(baseUrl, { state, replace })

    navigate(`${baseUrl}/${path}`, { state, replace })
  }

  return redirect
}
