import { toast } from 'react-toastify'
import { FullPageLoading } from 'components/PulsatingLoader'
import BrandingForm from 'components/forms/BrandingForm'
import styled from '@emotion/styled'
import { useOrg } from 'data/organization/useOrg'

function EditBranding() {
  const { association } = useOrg()

  if (!association) return <FullPageLoading />

  const initialValues = {
    termsPdf: association.details.termsPdf || null,
    bannerOverlay: association.details.bannerOverlay || [],
    bannerImage: association.details.bannerImage || [],
    bannerText: association.details.bannerText || '',
    overlayWidth: association.details.overlayWidth || '',
  }

  const onComplete = () => {
    toast.success('Vendor has been updated')
  }

  return (
    <Root>
      <BrandingForm initialValues={initialValues} onComplete={onComplete} />
    </Root>
  )
}

const Root = styled.div`
  max-width: 760px;
`

export default EditBranding
