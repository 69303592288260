import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import SearchBar from 'components/forms/SelectTechStack/SearchBar'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure } from 'react-instantsearch-hooks-web'
import { LineItemTable } from '@theoremlts/theorem-design'
import Pagination from 'components/DiscoverOrganization/Pagination'
import { format, parseISO, isBefore } from 'date-fns'
import { Icon, Button } from 'components/theme'
import { Navigate, useNavigate } from 'react-router-dom'
import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useGetAllAdminProducts } from 'data/hooks'
import { useRedirect } from 'hooks/useRedirect'

const algoliaClient = algoliasearch('0K3B9U0LC8', 'c597a04244e1170d74f9555a71db711e')

// const searchClient = {
//   search(requests: any) {
//     if (requests.every(({ params }: any) => !params.query)) {
//       return Promise.resolve({
//         results: requests.map(() => ({
//           hits: [],
//           nbHits: 0,
//           nbPages: 0,
//           processingTimeMS: 0,
//         })),
//       })
//     }

//     return algoliaClient.search(requests)
//   },
// }

type Product = {
  [key: string]: any
  name: string
  state: string
  updatedAt: string
  createdAt: string
  details: any
  id: number
  productToOrganizations: Array<{
    organization: {
      id: string
      name: string
    }
  }>
}

const getOrgName = ({ productToOrganizations }: Product) => {
  if (productToOrganizations.length > 0) return productToOrganizations[0].organization.name
  return 'NO ORG'
}

const AppRow = ({ product }: { product: Product }) => {
  const { name, state, updatedAt, createdAt, details, id, testMode } = product
  const { viewer } = useViewer()
  const redirect = useRedirect()

  return (
    <Row onClick={() => redirect(`${viewer.id}/product/settings/${id}`)}>
      <Product>
        {(details.squareLogoUrl || details.logoUrl) && (
          <Logo src={details.squareLogoUrl || details.logoUrl} alt={`${name}'s logo`} />
        )}
        {name}
      </Product>
      <td>{getOrgName(product)}</td>
      <td>{state}</td>
      <td>{format(parseISO(createdAt), 'MMM dd, yyyy')}</td>
      <td>{format(parseISO(updatedAt), 'MMM dd, yyyy')}</td>
      <td style={{ textAlign: 'center' }}>
        <input readOnly type="checkbox" checked={testMode} />
      </td>
    </Row>
  )
}

const AdminApps = () => {
  const { products, isLoading: productsLoading } = useGetAllAdminProducts()
  const [sortedProducts, setSortedProducts] = useState<any[]>([])
  const [sortedBy, setSortedBy] = useState<
    'Product' | 'Vendor' | 'State' | 'Created At' | 'Updated At' | 'Test Mode'
  >('Product')
  const [page, setPage] = useState<number>(1)
  const [visibleProducts, setVisibleProducts] = useState<any[]>([])
  const { viewer, viewerLoading, isSuperAdmin } = useViewer()
  const baseOrgUrl = useBaseOrgUrl()
  const navigate = useNavigate()
  const redirect = useRedirect()

  const HeaderItem: React.FC<{
    sort: 'Product' | 'Vendor' | 'State' | 'Created At' | 'Updated At' | 'Test Mode'
  }> = ({ sort }) => (
    <H onClick={() => setSortedBy(sort)}>
      {sort}
      <Icon name="sort" color={sortedBy === sort ? '#9013FE' : '#546e7a'} />
    </H>
  )

  const headerItems = [
    <HeaderItem sort="Product" />,
    <HeaderItem sort="Vendor" />,
    <HeaderItem sort="State" />,
    <HeaderItem sort="Created At" />,
    <HeaderItem sort="Updated At" />,
    <HeaderItem sort="Test Mode" />,
  ]

  const sortItems = () => {
    if (!products) return
    let sorted = []
    switch (sortedBy) {
      case 'Updated At':
      case 'Created At':
        const by = sortedBy === 'Created At' ? 'createdAt' : 'updatedAt'
        sorted = [...products].sort((a: Product, b: Product) =>
          isBefore(parseISO(b[by]), parseISO(a[by])) ? -1 : 1
        )
        break
      case 'State':
        sorted = [...products].sort((a: Product, b: Product) => {
          const A = a.state.toUpperCase()
          const B = b.state.toUpperCase()
          return A < B ? -1 : A > B ? 1 : 0
        })
        break
      case 'Product':
        sorted = [...products].sort((a: Product, b: Product) => {
          const A = a.name.toUpperCase()
          const B = b.name.toUpperCase()
          return A < B ? -1 : A > B ? 1 : 0
        })
        break
      case 'Vendor':
        sorted = [...products].sort((a: Product, b: Product) => {
          const A = getOrgName(a).toUpperCase()
          const B = getOrgName(b).toUpperCase()
          return A < B ? -1 : A > B ? 1 : 0
        })
        break
      case 'Test Mode':
        sorted = [...products].sort((a: Product, b: Product) => {
          const A = a.testMode
          const B = b.testMode
          return A < B ? 1 : A > B ? -1 : 0
        })
    }
    setSortedProducts(sorted)
  }

  const selectProductRange = (offset: number) => {
    const range = sortedProducts.slice((offset - 1) * 10, (offset - 1) * 10 + 10)
    setVisibleProducts(printRows(range))
  }

  useEffect(() => {
    if (productsLoading || !products) return
    sortItems()
  }, [JSON.stringify(products), productsLoading, sortedBy])

  useEffect(() => {
    selectProductRange(page)
  }, [JSON.stringify(sortedProducts)])

  const printRows = (rows: Product[]) => {
    return rows.map((product) => <AppRow product={product} key={`${product.id}-admin-list`} />)
  }

  if (!isSuperAdmin) return <Navigate to={baseOrgUrl} />

  if (viewerLoading) return <></>

  return (
    <>
      <InstantSearch
        searchClient={algoliaClient}
        stalledSearchDelay={2000}
        indexName="theorem_ecosystem"
      >
        <Configure hitsPerPage={12} distinct />
        <TopBar>
          <SearchBar
            onSelect={(id: number) => redirect(`${viewer.id}/product/settings/${id}`)}
            withoutSelections
            withoutTechstack
          />
          <StyledButton
            onClick={() => navigate(`${baseOrgUrl}/${viewer.id}/product/new/basic-info`)}
          >
            Create Product
          </StyledButton>
        </TopBar>
      </InstantSearch>
      <LineItemTable headerItems={headerItems} lineItems={visibleProducts} />
      {sortedProducts.length > 10 && products && (
        <Pagination
          pageCount={Math.ceil((products.length - 1) / 10)}
          onChange={(page) => {
            setPage(page)
            selectProductRange(page)
          }}
        />
      )}
    </>
  )
}

const StyledButton = styled(Button)`
  white-space: nowrap;
`

const TopBar = styled.div`
  display: flex;
  margin: 20px 0;
  > div {
    margin-right: 40px;
    min-width: 80%;
    width: 100%;
  }
`

const Row = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: #ebe8e8;
  }
`

const H = styled.div`
  cursor: pointer;
  margin-top: 20px;
  > * {
    margin-left: 8px;
  }
`

const Product = styled.td`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
`

const Logo = styled.img`
  height: 24px;
  margin-right: 16px;
`

export default AdminApps
