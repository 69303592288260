import React from 'react'
import ExtraForm from 'components/forms/product/ExtraForm'
import _ from 'lodash'
import { useProductSettings } from '../useProductSettings'
import VendorUpgradeModal from 'components/modals/VendorUpgradeModal'

function ProductExtra() {
  const { product, onComplete, showModal, toggleModal, isService } = useProductSettings()

  const initialValues = {
    demoVideo: _.get(product, 'details.demoVideo', ''),
    slides: _.get(product, 'details.slides', []),
    bannerImage: _.get(product, 'details.bannerImage', []),
  }

  return (
    <>
      <ExtraForm
        initialValues={initialValues}
        onComplete={onComplete}
        product={product}
        submitButtonText="Update"
        isService={isService}
      />
      <VendorUpgradeModal showModal={showModal} toggleModal={toggleModal} />
    </>
  )
}

export default ProductExtra
