import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js/pure'
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { Formik, Form } from 'formik'
import { TextInput, Select, Label, FieldRow } from 'components/theme/form'
import { COUNTRY_CODES } from 'model/static/country'
import styled from '@emotion/styled'
import Button from 'components/theme/Button'
import { isUsingProductionServer } from 'services/helper'
import { toast } from 'react-toastify'
import { useLazyApi } from 'hooks/useApi'
import * as yup from 'yup'
import _ from 'lodash'
import { useWizardContext } from '../prevalentContext'

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
  country: yup.array().required('Required'),
  postalCode: yup.string().required('Required'),
})

const InfoStep = () => {
  const { wizardData, updateWizardData, getStepData, nextStep } = useWizardContext()
  const [stripeError, setStripeError] = useState<any>()
  const [isGoodCouponCode, setIsGoodCouponCode] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const step = getStepData()
  const [checkCoupon] = useLazyApi('products/plan/coupon')

  const handleCoupon = (couponCode: any, setFieldValue: any) => async () => {
    try {
      const result = await checkCoupon({ coupon: couponCode })
      const percentOff = _.get(result, 'coupon.percent_off', '')
      setFieldValue('discount', percentOff)
      toast.success(
        <>
          You get <strong>{percentOff}%</strong> off!
        </>
      )

      setIsGoodCouponCode(true)
    } catch (e) {
      toast.error(
        <>
          <strong>{couponCode}</strong> is an invalid coupon code.
        </>
      )
      setIsGoodCouponCode(false)
    }
  }

  const onSuccess = async (values: any) => {
    if (!stripe) return
    if (!elements) return
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement) as any,
      billing_details: {
        name: values.name,
        email: _.get(wizardData, '[0].formData.email', ''),
        address: {
          postal_code: values.postalCode,
          country: values.country.value,
        },
      },
    })

    if (!error) {
      updateWizardData({ ...values, paymentId: paymentMethod?.id })
      nextStep()
    } else {
      setStripeError(error)
    }
  }

  return (
    <Wrapper>
      <Formik
        initialValues={step.formData}
        validationSchema={validationSchema}
        onSubmit={onSuccess}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <PaymentForm>
            <FieldRow>
              <Label required>Card details</Label>
              <PaymentWrapper>
                <CardNumberWrapper>
                  <CardNumberElement
                    options={{
                      style: paymentFormStyles,
                      showIcon: true,
                      iconStyle: 'solid',
                    }}
                  />
                </CardNumberWrapper>
                <BottomPaymentWrapper>
                  <CardExpiryWrapper>
                    <CardExpiryElement
                      options={{
                        style: paymentFormStyles,
                      }}
                    />
                  </CardExpiryWrapper>
                  <CardCvcWrapper>
                    <CardCvcElement
                      options={{
                        style: paymentFormStyles,
                      }}
                    />
                  </CardCvcWrapper>
                </BottomPaymentWrapper>
              </PaymentWrapper>
              {stripeError && <ErrorText>{stripeError.message}</ErrorText>}
            </FieldRow>
            <FieldRow>
              <TextInput label="Name on card" name="name" required />
            </FieldRow>
            <FieldRow>
              <Select label="Country" name="country" options={COUNTRY_CODES} required />
            </FieldRow>
            <FieldRow>
              <TextInput label="Postal Code" name="postalCode" required />
            </FieldRow>
            <FieldRow>
              <Label>Coupon code (Optional)</Label>
              <SameLine>
                <CouponCodeWrapper>
                  <TextInput name="couponCode" />
                </CouponCodeWrapper>{' '}
                <CouponCodeButton
                  onClick={handleCoupon(values.couponCode, setFieldValue)}
                  type="button"
                  isGoodCouponCode={isGoodCouponCode}
                >
                  {isGoodCouponCode ? 'Good' : 'Check'}
                </CouponCodeButton>
              </SameLine>
            </FieldRow>
            <Button type="submit" loading={isSubmitting} disable={isSubmitting}>
              Continue
            </Button>
          </PaymentForm>
        )}
      </Formik>
    </Wrapper>
  )
}

const paymentFormStyles = {
  base: {
    fontSize: '16px',
    color: '#424770',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#9e2146',
  },
}

const StripeWrapper = () => {
  const { loading } = useWizardContext()
  const [stripePromise, setStripePromise] = useState<any>()

  useEffect(() => {
    console.log('wordsdjas')
    if (!loading && !stripePromise) {
      const stripePromise = loadStripe(
        isUsingProductionServer
          ? 'pk_live_3n2Xu5pHIduiJ5BoV1RemJPI00r8agIfHN'
          : 'pk_test_om9MT2vyMuhyNkYo0ErhjSY600pMvdDMT7'
      )

      setStripePromise(stripePromise)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <Elements stripe={stripePromise}>
      <InfoStep />
    </Elements>
  )
}

const Wrapper = styled.div`
  margin-top: 40px;
`

const PaymentWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 16px;
`

const CouponCodeWrapper = styled.div`
  width: 100%;
  padding-right: 20px;
`
const CardNumberWrapper = styled.div`
  padding: 8px 12px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
`

const CardExpiryWrapper = styled.div`
  padding: 8px 12px;
  flex: 1 1 0;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
`

const CardCvcWrapper = styled.div`
  padding: 8px 12px;
  flex: 1 1 0;
`

const BottomPaymentWrapper = styled.div`
  display: flex;
`

const PaymentForm = styled(Form)`
  max-width: 450px;
  margin: 0 auto;
`

const SameLine = styled.div`
  display: flex;
  width: 100%;
`

const ErrorText = styled.div`
  color: #f44336;
  font-weight: 400;
  margin-top: 6px;
`

const CouponCodeButton = styled(Button)`
  background: ${({ isGoodCouponCode }) => (isGoodCouponCode ? '#4caf50' : 'transparent')};
  border-color: ${({ isGoodCouponCode }) => (isGoodCouponCode ? '#4caf50' : '#498DE6')};
  color: ${({ isGoodCouponCode }) => (isGoodCouponCode ? 'white' : '#498DE6')};
`

export default StripeWrapper
