import styled from '@emotion/styled'
import Modal from 'components/Modal'
import YouTube from 'react-youtube'
import urlParser from 'js-video-url-parser'
import { Icon } from 'components/theme'

interface Props {
  isShowing: boolean
  toggleModal: () => void
  title: string
  subtitle: string
  iconImage?: string
  videoUrl?: string
  imageUrl?: string
  features: {
    title: string
    items: string[]
  }
  noteText?: string
  highlights: Highlight[]
  buttonText: string
  onClick: () => void
}

type Highlight = {
  name: string
  description: string
}

function SalesModal({
  isShowing,
  toggleModal,
  title,
  subtitle,
  iconImage,
  videoUrl,
  imageUrl,
  features,
  noteText,
  highlights,
  buttonText,
  onClick,
}: Props) {
  const videoObject = videoUrl ? urlParser.parse(videoUrl) : false

  return (
    <Modal isShowing={isShowing} toggleModal={toggleModal} width={'1140px'}>
      <Root>
        <Left>
          <LeftHeader>
            {iconImage && (
              <IconWrapper>
                <IconImage src={iconImage} />
              </IconWrapper>
            )}
            <TitleWrapper>
              {title && <Title>{title}</Title>}
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </TitleWrapper>
          </LeftHeader>

          {videoObject && (
            <VideoWrapper>
              <YouTube
                videoId={videoObject.id}
                className="iframe-video"
                containerClassName="video-wrapper"
              />
            </VideoWrapper>
          )}

          {!videoObject && imageUrl && (
            <ImageWrapper>
              <Image src={imageUrl} />
            </ImageWrapper>
          )}
          <FeaturesTitle>{features.title}</FeaturesTitle>

          <Features>
            {features.items.length > 0 &&
              features.items.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIcon name="check" />
                  {feature}
                </Feature>
              ))}
          </Features>

          {noteText && <Note>{noteText}</Note>}
        </Left>
        <Right>
          <Highlights>
            {highlights.length > 0 &&
              highlights.map(({ name, description }, index) => (
                <Highlight key={index}>
                  <HighlightName>{name}: </HighlightName>
                  <HighlightDescription>{description}</HighlightDescription>
                </Highlight>
              ))}
          </Highlights>
          <Button onClick={onClick}>{buttonText}</Button>
        </Right>
      </Root>
    </Modal>
  )
}

const Root = styled.div`
  display: flex;
  min-height: 500px;
`

const Left = styled.div`
  width: 60%;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
`

const Right = styled.div`
  width: 40%;
  padding-left: 24px;
  padding-top: 80px;
  border-left: 1px solid #ccc;
  display: flex;
  flex-direction: column;
`

const LeftHeader = styled.div`
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 24px;
  display: flex;
`

const IconWrapper = styled.div`
  border: 1px solid #333;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 4px;
`

const IconImage = styled.img`
  width: 100%;
  border-radius: 4px;
`

const TitleWrapper = styled.div`
  padding-left: 16px;
`

const Title = styled.h2`
  margin-bottom: 4px;
`

const Subtitle = styled.h4`
  color: #777;
  font-size: 18px;
  margin: 0;
`

const VideoWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;

  .iframe-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
`

const FeaturesTitle = styled.h3`
  margin-bottom: 28px;
  margin-top: 0;
`

const Features = styled.div`
  column-count: 2;
  margin-bottom: 28px;
`

const Feature = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
`

const FeatureIcon = styled(Icon)`
  font-size: 12px;
  margin-right: 8px;
  color: #00a152;
`

const Note = styled.div`
  margin-top: auto;
  font-weight: 500;
  font-size: 16px;
  font-style: italic;
`

const Highlights = styled.div``

const Highlight = styled.div`
  margin-bottom: 20px;
`

const HighlightName = styled.strong`
  font-size: 20px;
`

const HighlightDescription = styled.span`
  font-size: 16px;
  line-height: 1.5;
`

const Button = styled.button`
  display: inline-block;
  margin: auto auto 0;
  color: white;
  background: #ff9100;
  max-width: 200px;
  border-radius: 6px;
  font-size: 22px;
  font-weight: 500;
  width: 100%;
  letter-spacing: 0.02rem;
  border: none;
  padding: 8px 0;
`

const ImageWrapper = styled.div`
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
  display: block;
`

export default SalesModal
