import React, { useState } from 'react'
import Modal from 'components/Modal'
import styled from '@emotion/styled'
import PrevalentProductBox from './PrevalentProductBox'
import PrevalentSalesModal from 'components/modals/PrevalentSalesModal'
import {
  PREVALENT_SIG_CORE_PRODUCT_ID,
  PREVALENT_SIG_CORE_PRICE_ID,
  PREVALENT_SIG_CORE_FEATURES,
  PREVALENT_SIG_LITE_PRODUCT_ID,
  PREVALENT_SIG_LITE_PRICE_ID,
  PREVALENT_SIG_LITE_FEATURES,
} from './prevalentProductData'
import SigLiteLogo from 'assets/prevalent/sig-lite.svg'
import SigCoreLogo from 'assets/prevalent/sig-core.svg'

interface Props {
  isShowModal: boolean
  toggleModal: () => void
}

type SalesModalData = {
  stripeProductId: string
  priceId: string
  price: string
  image: string
  features: string[]
  productName: string
}

interface Modal extends SalesModalData {
  show: boolean
}

const PrevalentProducts: React.FC<Props> = ({ isShowModal, toggleModal }) => {
  const [modal, setModal] = useState<Modal>({
    show: false,
    priceId: '',
    stripeProductId: '',
    price: '',
    image: '',
    productName: '',
    features: [],
  })

  const openSalesModal = ({
    stripeProductId,
    priceId,
    price,
    image,
    productName,
    features,
  }: SalesModalData) => () => {
    setModal({ show: true, stripeProductId, priceId, price, image, productName, features })
  }

  const toggleSalesModal = () => {
    setModal((prevModal) => ({
      show: !prevModal.show,
      stripeProductId: prevModal.stripeProductId,
      priceId: prevModal.priceId,
      price: prevModal.price,
      image: prevModal.image,
      productName: prevModal.productName,
      features: [],
    }))
  }

  return (
    <>
      <Modal isShowing={isShowModal} toggleModal={toggleModal} width={'1140px'}>
        <Root>
          <Title>Which product offer?</Title>
          <Boxes>
            <PrevalentProductBox
              title="SIG Lite"
              content="The SIG Lite is a 300-question assessment designed to provide a broad but high-level understanding about an Assessees internal information security controls. This level is for Assessees that need a basic level of due diligence. It can also be used as a preliminary assessment before a more detailed review."
              price="250"
              image={SigLiteLogo}
              onClick={openSalesModal({
                stripeProductId: PREVALENT_SIG_LITE_PRODUCT_ID,
                priceId: PREVALENT_SIG_LITE_PRICE_ID,
                price: '$250',
                image: SigLiteLogo,
                features: PREVALENT_SIG_LITE_FEATURES,
                productName: 'SIG Lite',
              })}
            />
            <PrevalentProductBox
              title="SIG Core"
              content="The SIG Core is an 800-question assessment designed for assessing service providers that store or manage highly sensitive or regulated information, such as consumer information or trade secrets. This level is meant to provide a deeper level of understanding about how a service provider secures information and services. It is meant to meet the needs of almost all assessments, based on industry standards."
              price="500"
              image={SigCoreLogo}
              onClick={openSalesModal({
                stripeProductId: PREVALENT_SIG_CORE_PRODUCT_ID,
                priceId: PREVALENT_SIG_CORE_PRICE_ID,
                price: '$500',
                image: SigCoreLogo,
                features: PREVALENT_SIG_CORE_FEATURES,
                productName: 'SIG Core',
              })}
            />
          </Boxes>
        </Root>
      </Modal>
      <PrevalentSalesModal
        isShowing={modal.show}
        image={modal.image}
        toggleModal={toggleSalesModal}
        priceId={modal.priceId}
        productName={modal.productName}
        stripeProductId={modal.stripeProductId}
        features={modal.features}
        price={modal.price}
      />
    </>
  )
}

const Root = styled.div`
  padding: 20px 0 40px;
`

const Title = styled.h1`
  font-size: 32px;
  text-align: center;
`

const Boxes = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -16px;
  margin-right: -16px;
`

export default PrevalentProducts
