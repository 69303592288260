import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useSetUserStatus } from 'hooks/graphql'
import CreateVendorForm from './CreateVendorForm'
import { UserStatus } from 'generated/graphql'
import { useAppMetadata } from 'data/viewer/useAppMetadata'
import SetupHeader from 'components/global/SetupHeader'
import { useLazyApi } from 'hooks/useApi'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'

const CreateVendor = () => {
  const [redirect, setRedirect] = useState<any>(false)
  const { setUserStatus } = useSetUserStatus()
  const { data: appMetadata } = useAppMetadata()
  const [welcomeEmail] = useLazyApi('company/welcome')
  const marketplaceUrl = useMarketplaceUrl(true)

  if (redirect) {
    return <Navigate to={redirect} />
  }

  const onComplete = async () => {
    setUserStatus(UserStatus.Active)

    await welcomeEmail({ networkName: appMetadata?.assocOrg && appMetadata?.assocOrg.name })

    if (appMetadata?.assocOrg) {
      window.location.href = `${marketplaceUrl}returnTo=/getting-started`
    } else {
      window.location.href = `${marketplaceUrl}returnTo=/getting-started`
    }
  }

  return (
    <Root>
      <SetupHeader />
      <CreateVendorForm onComplete={onComplete} />
    </Root>
  )
}

const Root = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export default CreateVendor
