import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { draftToMarkdown } from 'markdown-draft-js'
import { convertToRaw } from 'draft-js'

export const useUpdateProductListing = (
  productListingId?: number,
  isSkipDraftDescription?: boolean
) => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (details: any) => {
      if (!productListingId) return

      await request(UPDATE_PRODUCT_LISTING, {
        productListingId,
        details: {
          ...details,
          description: isSkipDraftDescription
            ? details.description
            : draftToMarkdown(convertToRaw(details.description.getCurrentContent())),
          pricingDescription: isSkipDraftDescription
            ? details.pricingDescription
            : draftToMarkdown(convertToRaw(details.pricingDescription.getCurrentContent())),
        },
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['product-listing', productListingId]),
    }
  )
}

const UPDATE_PRODUCT_LISTING = gql`
  mutation UpdateProductListing($productListingId: Int!, $details: JSON) {
    updateProductListing(input: { patch: { details: $details }, id: $productListingId }) {
      clientMutationId
    }
  }
`
