import { useState } from 'react'
import styled from '@emotion/styled'
import { useField } from 'formik'
import { TwitterPicker } from 'react-color'
import Label from 'components/theme/form/Label'

const colorOptions = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
  '#000000',
]

function ColorPicker({ label, ...props }: any) {
  const [, meta, helpers] = useField(props)
  const [showPicker, setShowPicker] = useState(false)

  const changeColor = (color: any) => {
    helpers.setValue(color.hex)
    setShowPicker(false)
  }

  return (
    <Root>
      <Label>{label}</Label>
      <ButtonWrapper onClick={() => setShowPicker(true)}>
        <ButtonField color={meta.value} />
      </ButtonWrapper>
      <CirclePickerWrapper show={showPicker}>
        <TwitterPicker colors={colorOptions} color={meta.value} onChangeComplete={changeColor} />
      </CirclePickerWrapper>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  z-index: 99999;
`

const ButtonWrapper = styled.div`
  width: 80px;
  height: 38px;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
`

const ButtonField = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 2px;
  width: 100%;
  height: 100%;
`

const CirclePickerWrapper = styled.div<{ show: boolean }>`
  position: absolute;
  z-index: 1000;
  top: 65px;
  right: 0;
  background: white;
  display: ${({ show }) => (show ? 'block' : 'none')};
`
export default ColorPicker
