import UserForm from 'components/forms/personal/UserForm'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { useViewer } from 'context/viewerContext'
import { Viewer } from 'data/types'

const initialValues = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  fileUrl: [],
  twitter: '',
  linkedin: '',
  practiceGroup: [],
  roles: [],
  firmType: [],
}

const loadInitialValues = (user: Viewer) => {
  const { first, last, details } = user

  return {
    ...initialValues,
    firstName: first || '',
    lastName: last || '',
    fileUrl: details.imageUrl,
    twitter: details.twitter || '',
    linkedin: details.linkedin || '',
    practiceGroup: user.practiceGroups.map(({ id }) => id),
    roles: user.terms.filter(({ taxonomyId }) => taxonomyId === 8).map(({ id }) => id),
    firmType: user.terms.filter(({ taxonomyId }) => taxonomyId === 17).map(({ id }) => id),
  }
}

function EditUser() {
  const { viewer } = useViewer()

  const defaultValues = loadInitialValues(viewer)

  const onSubmit = async () => {
    toast.success('User is updated')
  }

  return (
    <Root>
      <FormContainer>
        <UserForm
          defaultValues={{ ...defaultValues }}
          onSubmit={onSubmit}
          submitButtonText="Update Account"
        />
      </FormContainer>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
`

const FormContainer = styled.div`
  max-width: 760px;
`

export default EditUser
