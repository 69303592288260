import React, { useEffect, useReducer } from 'react'

const SelectTechStackContext = React.createContext({} as any)

const defaultValue = {
  displayDefault: true,
  selectedApps: [],
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'ON_KEY_DOWN':
      const { searchValue } = action.payload

      return { ...state, displayDefault: !searchValue, searchValue }

    case 'TOGGLE_APP_SELECTION':
      const { id } = action.payload

      return {
        ...state,
        selectedApps: state.selectedApps.some((selectedApp: any) => selectedApp.id === id)
          ? state.selectedApps.filter((selectedApp: any) => selectedApp.id !== id)
          : [...state.selectedApps, action.payload],
      }

    case 'UPDATE_ORG_TECHSTACK':
      const { orgTechstack } = action.payload

      return {
        ...state,
        orgTechstack,
      }

    default:
      return state
  }
}

export const SelectTechStackProvider: React.FC<any> = ({
  children,
  orgTechstack = [],
  initialSelectedApps = [],
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultValue,
    selectedApps: initialSelectedApps,
    orgTechstack,
  })

  useEffect(() => {
    if (orgTechstack.length === 0) return

    dispatch({ type: 'UPDATE_ORG_TECHSTACK', payload: { orgTechstack } })
  }, [JSON.stringify(orgTechstack)])

  return (
    <SelectTechStackContext.Provider value={{ state, dispatch }}>
      {children}
    </SelectTechStackContext.Provider>
  )
}

export const useSelectTechStackContext = () => React.useContext(SelectTechStackContext)
