import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  oldLocationId: number
  newLocationId: number
}
export const useAddRemoveHeadquarter = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { oldLocationId, newLocationId } = variables

      await request(ADD_REMOVE_HEADQUARTER, {
        oldId: oldLocationId,
        newId: newLocationId,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

export const ADD_REMOVE_HEADQUARTER = gql`
  mutation AddRemoveHeadquarter($oldId: Int!, $newId: Int!) {
    oldLocation: updateLocation(input: { patch: { isHeadquarter: false }, id: $oldId }) {
      location {
        id
      }
    }
    newLocation: updateLocation(input: { patch: { isHeadquarter: true }, id: $newId }) {
      location {
        id
      }
    }
  }
`
