import IntegrationsForm from 'components/forms/product/IntegrationsForm'
import styled from '@emotion/styled'
import { useProductSettings } from '../useProductSettings'
import VendorUpgradeModal from 'components/modals/VendorUpgradeModal'

function Integrations() {
  const { product, onComplete, showModal, toggleModal } = useProductSettings()

  const initialValues = product?.integrations ? product.integrations.map(({ id }) => id) : []

  return (
    <Root>
      <IntegrationsForm
        productId={product?.id}
        initialValues={initialValues}
        onComplete={onComplete}
      />
      <VendorUpgradeModal showModal={showModal} toggleModal={toggleModal} />
    </Root>
  )
}

const Root = styled.div``

export default Integrations
