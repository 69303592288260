import { FC, useState } from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'
import { Avatar } from 'components/user'
import ProfileModal from 'components/user/Profile'
import { User } from 'interfaces'
import Modal, { useModal } from 'components/Modal'

interface Props {
  user: User
  toggleModal: () => void
}

const UserRow: FC<Props> = ({ user, toggleModal }) => {
  const { showModal, toggle } = useModal()
  const [selectedModalTab, setSelectedModalTab] = useState<'TECHSTACK' | 'USER_STORY'>('TECHSTACK')

  console.log(toggleModal)
  const onActionButtonClick = (tabType: 'TECHSTACK' | 'USER_STORY') => () => {
    setSelectedModalTab(tabType)
    toggle()
  }

  return (
    <Tr>
      <Td>
        <Flex>
          <Avatar user={user} />
          <UserDetails>
            <Name>
              {user.first} {user.last}
            </Name>
            <Email>{user.email}</Email>
          </UserDetails>
        </Flex>
      </Td>
      <Td>
        <SmallButton onClick={onActionButtonClick('TECHSTACK')}>Techstack</SmallButton>
        <SmallButton onClick={onActionButtonClick('USER_STORY')}>User Stories</SmallButton>
      </Td>
      {showModal && user.id && (
        <Modal width="800px" isShowing={showModal} toggleModal={toggle} removePadding>
          <ProfileModal selectedId={user.id} defaultTab={selectedModalTab} />
        </Modal>
      )}
    </Tr>
  )
}

const Tr = styled.tr``

const Td = styled.td`
  padding: 16px 0;
  border-bottom: 1px solid #eee;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const UserDetails = styled.div`
  margin-left: 12px;
`

const Name = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.02rem;
`

const Email = styled.div`
  font-size: 12px;
  color: #6f7782;
`

const SmallButton = styled(Button)`
  margin-left: 8px;
  padding: 4px 16px;
`

export default UserRow
