import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useDeleteAssocHome = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (assocHomeId: number) => {
      await request(DELETE_ASSOC_HOME, { assocHomeId })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['assocHome']),
    }
  )
}

const DELETE_ASSOC_HOME = gql`
  mutation DeleteAssocHome($assocHomeId: Int!) {
    deleteAssocHomeById(input: { id: $assocHomeId }) {
      clientMutationId
    }
  }
`
