import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { useAuth0 } from '@auth0/auth0-react'
import { StepHeader } from 'interfaces'
import StepIcon from './StepIcon'
import { Button } from 'components/theme'
import { logEvent } from '@theoremlts/theorem-design'

interface Props {
  steps: StepHeader[]
  currentStep: number
  completedStep?: number
}

interface StepWithIndex extends StepHeader {
  index: number
}

// if the current step is 0 then the viewable steps are 0, 1, 2
// if the current step is 1 or more then the viewable steps are [n - 1, n, n + 1]
// (optional) if the current step is at the end then the steps are [n - 2, n - 1, n]
const HeaderStepper: React.FC<Props> = ({ steps, currentStep }) => {
  const { logout } = useAuth0()
  const stepCount = steps.length
  let viewableSteps: StepWithIndex[] = []
  let largeIconIndex = 1

  const stepsWithIndex: StepWithIndex[] = steps.map((step, index) => ({
    ...step,
    index: index + 1,
  }))

  if (currentStep === 0) {
    viewableSteps = [...stepsWithIndex].slice(currentStep, currentStep + 3)
    largeIconIndex = 0
  } else if (currentStep + 1 >= stepCount) {
    if (stepCount === 2) {
      viewableSteps = [...stepsWithIndex]
    } else {
      viewableSteps = [...stepsWithIndex].slice(currentStep - 2, stepCount + 1)
      largeIconIndex = 2
    }
  } else {
    viewableSteps = [...stepsWithIndex].slice(currentStep - 1, currentStep + 2)
  }

  const step = steps[currentStep]

  return (
    <Root>
      <LogoutButton
        onClick={() => {
          logEvent('log_out')
          logout()
        }}
      >
        Logout
      </LogoutButton>
      <Wrapper>
        <Title>{step?.title}</Title>
        <Subtitle>{step?.subtitle}</Subtitle>
        <Steps>
          {viewableSteps.map((step, index) => (
            <Fragment key={`${step.index}--step-index`}>
              <StepIcon
                {...step}
                isLarge={index === largeIconIndex}
                isInactive={index > largeIconIndex}
              />
              {index < viewableSteps.length - 1 && (
                <StepDivider isComplete={index < largeIconIndex} />
              )}
            </Fragment>
          ))}
        </Steps>
      </Wrapper>
    </Root>
  )
}

const LogoutButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const Root = styled.div`
  background: #f5f8fa;
  text-align: center;
  padding: 32px 40px 56px;
  position: relative;
  border-bottom: 1px solid #ddd;
`

const Wrapper = styled.div`
  font-size: 18px;
`

const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 8px;
`

const Subtitle = styled.div`
  margin-bottom: 20px;
`

const Steps = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
`

const StepDivider = styled.div<{ isComplete: boolean }>`
  border-bottom: 2px dashed;

  border-color: ${({ isComplete }) => (isComplete ? '#be7bbe' : '#BCBCBC')};
  flex: 1 1 auto;
  margin: 0 4px;
  max-width: 120px;
`

export default HeaderStepper
