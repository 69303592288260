import { useCreateProductWizard } from 'context/CreateProductWizardContext'
import { markdownToDraft } from 'markdown-draft-js'
import { EditorState, convertFromRaw } from 'draft-js'
import DescriptionForm from 'components/forms/product/DescriptionForm'
import styled from '@emotion/styled'
import { useOrg } from 'data/organization/useOrg'
import _ from 'lodash'

function ProductDescription() {
  const { goNextStep, currentProductId } = useCreateProductWizard()
  const { vendor, lawfirm } = useOrg()

  let initialValues = {
    description: EditorState.createEmpty(),
  }

  const product = _.find(vendor ? vendor?.products : lawfirm?.products, { id: currentProductId })

  if (product) {
    const markdownBlocks = markdownToDraft(_.get(product, 'description', ''))
    const markdownState = convertFromRaw(markdownBlocks)

    initialValues = {
      ...initialValues,
      description: EditorState.createWithContent(markdownState),
    }
  }

  const onComplete = () => {
    goNextStep()
  }

  return (
    <Root>
      <DescriptionForm
        initialValues={initialValues}
        onComplete={onComplete}
        productId={currentProductId}
      />
    </Root>
  )
}

const Root = styled.div`
  padding: 40px;
  display: flex;
  justify-content: center;
`

export default ProductDescription
