import { useState } from 'react'
import { extractPictureUrl } from 'services/image'
import { newDeletedList } from 'services/helper'
import { useSetUserStatus } from 'hooks/graphql'
import { MemberStatus, OrganizationSubtype, OrganizationType, UserStatus } from 'generated/graphql'
import { useOrg } from 'data/organization/useOrg'
import { useSwitchOrg } from 'data/viewer/useSwitchOrg'
import {
  useAppMetadata,
  useCreateAssocToOrg,
  useCreateLocation,
  useCreateOrg,
  useMutateTermsToOrg,
  useMutateViewer,
  useUpdateOrg,
} from 'data/hooks'

export const useCreateLawfirm = () => {
  const { lawfirm } = useOrg()
  const createLocation = useCreateLocation()
  const updateLawfirm = useUpdateOrg()
  const mutateViewer = useMutateViewer()
  const mutateTermsToOrg = useMutateTermsToOrg()
  const createOrg = useCreateOrg()
  const switchOrg = useSwitchOrg()
  const { setUserStatus } = useSetUserStatus()
  const [loading, setLoading] = useState(false)
  const { data: appMetadata } = useAppMetadata()
  const createAssocToOrg = useCreateAssocToOrg()

  type LawfirmData = {
    logoUrl: any
    name: string
    lawfirmType: number
    lawfirmSize: string
    jurisdictionRegion: string
    isHeadquarter?: boolean
    specialty?: number
    city: string
    state: string
    country: string
  }

  type InitialTerms = {
    initialLawfirmType?: number
    initialSpecialty?: number
  }

  const setupLawfirmData = async (values: LawfirmData, initialTerms: InitialTerms) => {
    setLoading(true)
    await createLawfirmData(values, initialTerms)

    if (!lawfirm || lawfirm?.locations.length === 0) {
      const locationId = await createLocationData(values)
      if (locationId) await updateUserLocation(locationId) // maybe add the location ID in the userToOrganization table
    }

    setUserStatus(UserStatus.Active)
  }

  const updateUserLocation = async (locationId: number) => {
    await mutateViewer.mutateAsync({ locationId })
  }

  const createLocationData = async ({ city, state, country, isHeadquarter }: LawfirmData) => {
    const locationId = await createLocation.mutateAsync({
      city,
      state,
      country,
      isHeadquarter,
    })

    return locationId
  }

  const createLawfirmData = async (
    { logoUrl, name, lawfirmType, lawfirmSize, specialty, jurisdictionRegion }: LawfirmData,
    initialTerms: InitialTerms
  ) => {
    const logo = await extractPictureUrl(logoUrl)

    let lawfirmId = lawfirm?.id || ''

    const details = {
      lawfirmSize,
      jurisdictionRegion,
      logo,
    }

    const { initialLawfirmType } = initialTerms

    const initialList = [initialLawfirmType].filter(
      (item: number | undefined): item is number => item !== undefined
    )
    const currentList = [lawfirmType].filter(
      (item: number | undefined): item is number => item !== undefined
    )
    const [createdTermIds, deletedTermIds] = newDeletedList(initialList, currentList)

    if (lawfirmId) {
      await updateLawfirm.mutateAsync({ name, details, mainTermId: specialty })

      await mutateTermsToOrg.mutateAsync({ createdTermIds, deletedTermIds })
    } else {
      lawfirmId = await createOrg.mutateAsync({
        name,
        details,
        type: OrganizationType.Lawfirm,
        subtype: OrganizationSubtype.Lawfirm,
      })

      await mutateTermsToOrg.mutateAsync({
        createdTermIds,
        deletedTermIds,
        organizationId: lawfirmId,
      })

      await switchOrg.mutateAsync(lawfirmId)
    }

    if (appMetadata.assocOrg && lawfirmId) {
      await createAssocToOrg.mutateAsync({
        associationId: appMetadata.assocOrg.id,
        organizationId: lawfirmId,
        status: MemberStatus.Active,
      })
    }

    return lawfirmId
  }

  return { loading, setupLawfirmData }
}
