import csv from 'csvtojson'
import { ProductState } from 'generated/graphql'
import slugify from 'slugify'

// 	CONTRACT VALUE	RENEWAL DATE	NUMBER OF SEATS	STAGE	Access	OWNED BY	ADDED BY

type Products = {
  __typename?: 'Product'
  id: number
  excerpt: string
  details: any
  description: string
  createdAt: any
  name: string
  slug: string
  state: ProductState
  updatedAt: any
}

type CsvTechstackItem = {
  [key: string]: any
  'Product Name': string
  'Contract Value'?: string
  'Number of Seats'?: string
  'Renewal Date'?: string
  Stage?: string
  Access?: 'User' | 'Organization' | 'Hide'
}

type Stages =
  | 'ACTIVE'
  | 'INVESTIGATION'
  | 'UNDER_REVIEW'
  | 'PILOT'
  | 'CONTRACT_REVIEW'
  | 'PRACTICE_ROLLOUT'
  | 'REGION_ROLLOUT'
  | 'ACTIVE'

type Access = 'USER' | 'ORGANIZATION' | 'REVIEW' | 'AUTHORIZED' | 'HIDE'

type ParsedCsvTechstackItem = {
  product: {
    id: number
    name: string
    details: {
      [key: string]: any
      squareLogo: string
      logoUrl: string
    }
  }
  techstack: {
    access?: Access
    seats?: number
    stage?: Stages
    metadata: {
      [key: string]: any
      contractValue?: number
      renewalDate?: string
    }
  }
}

export type ParseError = {
  errorType: 'error' | 'warn'
  status:
    | 'MISSING PRODUCT'
    | 'BAD MONEY'
    | 'BAD ACCESS'
    | 'BAD STAGE'
    | 'EXTRA COLUMNS'
    | 'NO PRODUCTS'
    | 'BAD DATE'
  value?: CsvTechstackItem | string[]
}

const parseDate = (val: string) => {
  const splitDate = val.replace('/', '-').replace('/', '-').split('-')
  if (
    splitDate.length !== 3 ||
    splitDate[0].length !== 4 ||
    splitDate[1].length > 2 ||
    splitDate[2].length > 2
  )
    return undefined
  for (let i = 0; i < 3; i++) {
    if (isNaN(Number(splitDate[i]))) return undefined
  }
  if (splitDate.some((date) => Number(date) < 1)) return undefined
  if (Number(splitDate[1]) > 12 || Number(splitDate[2]) > 31) return undefined
  return splitDate.join('-')
}

const parseMoney = (value: string) => {
  return Number(value.replace('$', '').replace(',', '').split('.').join(''))
}

const CsvToTeckstack = async (
  file: File | null,
  products: Products[],
  techstackAction: (stack: ParsedCsvTechstackItem | ParseError) => void
) => {
  if (!file) return console.warn('CsvToTechstack ran without a file')
  const productMap = products.reduce((map, { name, ...props }) => {
    map[name] = { name, ...props }
    return map
  }, {} as { [key: string]: any })

  const parseStage = (stage: string) => {
    const stages = [
      'ACTIVE',
      'INVESTIGATION',
      'UNDER_REVIEW',
      'PILOT',
      'CONTRACT_REVIEW',
      'PRACTICE_ROLLOUT',
      'REGION_ROLLOUT',
      'ACTIVE',
    ]
    const parsed = slugify(stage?.toUpperCase(), { replacement: '_' })
    return stages.includes(parsed) ? (parsed as Stages) : undefined
  }
  const allowedAccess = ['USER', 'ORGANIZATION', 'REVIEW', 'AUTHORIZED', 'HIDE']

  const parseAccess = (permission: string) => {
    const perm = permission.toUpperCase()
    return allowedAccess.includes(perm) ? (perm as Access) : undefined
  }

  const checkForExtraColumns = (stack: CsvTechstackItem[]) => {
    return stack.reduce((props, item) => {
      const itemProps = Object.keys(item)
      itemProps.forEach((p) => {
        if (
          !props.includes(p) &&
          ![
            'Product Name',
            'Contract Value',
            'Number of Seats',
            'Renewal Date',
            'Stage',
            'Access',
          ].includes(p)
        )
          props.push(p)
      })
      return props
    }, [] as string[])
  }

  const hasProductNames = (stack: CsvTechstackItem[]) => {
    const props = stack.reduce((props, item) => {
      const itemProps = Object.keys(item)
      return [...props, ...itemProps]
    }, [] as string[])
    return props.includes('Product Name')
  }

  const parseTechstack = (
    stack: CsvTechstackItem[]
  ): Array<ParsedCsvTechstackItem | ParseError> => {
    if (!hasProductNames(stack))
      return [
        {
          status: 'NO PRODUCTS',
          errorType: 'error',
        },
      ]
    const { errors, parsedStack } = stack.reduce(
      ({ errors, parsedStack }, item) => {
        const product = productMap[item['Product Name']]
        // if (item['Product Name'] === 'Westlaw Edge') debugger
        if (!product) {
          errors.push({
            status: 'MISSING PRODUCT',
            errorType: 'warn',
            value: item,
          })
          return { errors, parsedStack }
        }
        const contractValue = item['Contract Value']
          ? parseMoney(item['Contract Value'])
          : undefined
        if (contractValue && isNaN(contractValue))
          errors.push({
            status: 'BAD MONEY',
            errorType: 'error',
            value: item,
          })
        const stage = item['Stage'] ? parseStage(item['Stage']) : undefined
        if (!stage && item['Stage'])
          errors.push({
            status: 'BAD STAGE',
            errorType: 'error',
            value: item,
          })
        const access = item['Access'] ? parseAccess(item['Access']) : undefined
        if (!access && item['Access'])
          errors.push({
            status: 'BAD ACCESS',
            errorType: 'error',
            value: item,
          })
        const renewalDate = item['Renewal Date'] ? parseDate(item['Renewal Date']) : undefined
        if (!renewalDate && item['Renewal Date'])
          errors.push({
            status: 'BAD DATE',
            errorType: 'error',
            value: item,
          })

        parsedStack.push({
          product,
          techstack: {
            access,
            stage,
            seats: Number(item['Number of Seats']),
            metadata: {
              renewalDate,
              contractValue: typeof contractValue === 'number' ? contractValue : undefined,
            },
          },
        })
        return { errors, parsedStack }
      },
      { errors: [], parsedStack: [] } as {
        errors: ParseError[]
        parsedStack: ParsedCsvTechstackItem[]
      }
    )
    const extraItems = checkForExtraColumns(stack)
    if (extraItems.length > 0)
      errors.push({
        status: 'EXTRA COLUMNS',
        errorType: 'warn',
        value: extraItems,
      })
    return [...errors, ...parsedStack]
  }

  const reader = new FileReader()

  reader.onload = async (event) => {
    if (!event.target) return
    const csvData = event.target.result
    if (typeof csvData !== 'string') return
    const data = await csv().fromString(csvData)
    const stack = parseTechstack(data)
    stack.forEach((item) => techstackAction(item))
  }

  reader.readAsText(file)
}

export default CsvToTeckstack
