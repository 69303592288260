import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from './useOrg'

interface Variables {
  orgId?: string
  name?: string
  details: {
    [key: string]: any
  }
}
export const useMutateOrg = () => {
  const request = useGql()
  const queryClient = useQueryClient()
  const { organization } = useOrg()

  return useMutation(
    async (variables: Variables) => {
      if (!organization) return

      const { name, details, orgId } = variables

      await request(UPDATE_ORGANIZATION, { id: orgId || organization.id, name, details })

      return orgId || organization.id
    },
    {
      onSuccess: (orgId) => {
        queryClient.invalidateQueries(['org'])
        queryClient.invalidateQueries(['company', orgId])
      },
    }
  )
}

const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($id: UUID!, $name: String, $details: JSON) {
    __typename
    updateOrganization(input: { patch: { details: $details, name: $name }, id: $id }) {
      organization {
        id
      }
    }
  }
`
