import styled from '@emotion/styled'
import { BoxWrapper } from 'components/theme'
import { useParams } from 'react-router'
import AccPlans from './AccPlans'
import ClocPlans from './ClocPlans'
import EltaPlans from './EltaPlans'
import BackButton from 'components/theme/BackButton'

const ProductPlans = () => {
  const { slug } = useParams()
  return (
    <Root>
      <BoxWrapper>
        <BackButton />
        {slug === 'acc' && <AccPlans />}
        {slug === 'cloc' && <ClocPlans />}
        {slug === 'elta' && <EltaPlans />}
      </BoxWrapper>
    </Root>
  )
}

const Root = styled.div``

export default ProductPlans
