import styled from '@emotion/styled'
import { TextInput, FormField, Dropdown } from 'components/theme/form'
import { Button } from 'components/theme'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { Formik, Form } from 'formik'
import { extractPictureUrl } from 'services/image'
import * as yup from 'yup'
import { useOrg } from 'data/organization/useOrg'
import { useMutateOrg } from 'data/organization/useMutateOrg'
import { COMPANY_SIZE_OPTIONS } from 'model/static'
import { useAppMetadata, useCreateAssocToOrg } from 'data/hooks'
import { MemberStatus } from 'generated/graphql'

const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Required'),
  logo: yup.mixed(),
})

const defaultValues = {
  name: '',
  website: '',
  logo: [],
  companySize: '',
}

const CompanyForm = ({ initialValues = defaultValues, onComplete, className = null }: any) => {
  const { organization } = useOrg()
  const mutateOrg = useMutateOrg()
  const { data: appMetadata } = useAppMetadata()
  const createAssocToOrg = useCreateAssocToOrg()

  const onSubmit = async ({ name, website, logo, companySize }: any) => {
    const logoUrl = await extractPictureUrl(logo)

    await mutateOrg.mutateAsync({
      name,
      details: {
        ...organization?.details,
        website,
        logo: logoUrl,
        companySize,
      },
    })

    if (appMetadata.assocOrg && organization?.id) {
      await createAssocToOrg.mutateAsync({
        associationId: appMetadata.assocOrg.id,
        organizationId: organization.id,
        status: MemberStatus.Active,
      })
    }

    onComplete()
  }

  const companySize = COMPANY_SIZE_OPTIONS.map((option) => ({
    ...option,
    text: `${option?.text} Employees`,
  }))

  return (
    <Root className={className}>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <FormField>
              <UploadSingleAsset label="Company Logo" name="logo" selectedImage={values.logo}>
                Add Company Logo
              </UploadSingleAsset>
            </FormField>
            <FormField>
              <TextInput label="Company Name" name="name" placeholder="My Company Inc." required />
            </FormField>
            <FormField>
              <Dropdown
                fluid
                selection
                label="Company Size"
                setFieldValue={setFieldValue}
                options={companySize}
                value={values.companySize}
                name="companySize"
                placeholder="Select Company Size"
              />
            </FormField>
            <ButtonContainer>
              <Button disabled={isSubmitting || !isValid} loading={isSubmitting}>
                Update
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

export const companySizeOptions = [
  {
    text: '1 - 5',
    value: '1 - 5',
  },
  {
    text: '6 - 10',
    value: '6 - 10',
  },
  {
    text: '11 - 25',
    value: '11 - 25',
  },
  {
    text: '26 - 50',
    value: '26 - 50',
  },
  {
    text: '51 - 100',
    value: '51 - 100',
  },
  {
    text: '100+',
    value: '100+',
  },
  {
    text: '500+',
    value: '500+',
  },
  {
    text: '1000+',
    value: '1000+',
  },
]

const Root = styled.div`
  max-width: 760px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

export default CompanyForm
