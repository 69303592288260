import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useDeleteTermToVendorStackshare = () => {
  const request = useGql()
  return useMutation(async (id: number) => {
    request(DELETE_TERM_TO_VENDOR_STACKSHARE, { id })
  })
}

const DELETE_TERM_TO_VENDOR_STACKSHARE = gql`
  mutation DeleteTermToVendorStackshare($id: Int!) {
    deleteTermToVendorStackshare(input: { id: $id }) {
      clientMutationId
    }
  }
`
