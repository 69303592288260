import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import styled from '@emotion/styled'
import VendorUserForm, { defaultValues as initialValues } from 'components/forms/VendorUserForm'
import { useUserSetup } from 'context/userSetupContext'
import { useSetUserStatus } from 'hooks/graphql'
import { useLazyApi } from 'hooks/useApi'
import { UserRole, UserStatus } from 'generated/graphql'
import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useAppMetadata } from 'data/hooks'
import SetupHeader from 'components/global/SetupHeader'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'

const loadInitialValues = (user: any) => {
  const { first, last, jobTitle, orgJobTitle, details } = user

  return {
    ...initialValues,
    first: first || '',
    last: last || '',
    jobTitle: jobTitle || '',
    orgJobTitle: orgJobTitle || '',
    fileUrl: details?.imageUrl,
  }
}

function CreateUser() {
  const { viewer, refetch } = useViewer()
  const baseOrgUrl = useBaseOrgUrl()
  const { amplitude } = useUserSetup()
  const [redirect, setRedirect] = useState<any>(null)
  const { setUserStatus } = useSetUserStatus()
  const [welcomeEmail] = useLazyApi('company/welcome')
  const { data: appMetadata } = useAppMetadata()
  const marketplaceUrl = useMarketplaceUrl(true)

  const defaultValues = loadInitialValues(viewer)

  useEffect(() => {
    refetch()
  }, [])

  const onSubmit = async () => {
    if (viewer.role === UserRole.Owner) {
      setUserStatus(UserStatus.CreateOrg)
      setRedirect('/setup/vendor-organization')
    } else {
      amplitude
        ?.logEventWithSavedProps('set_user_info', {
          action: 'update_information',
          organization_type: 'VENDOR',
        })
        .completeSignUp({})
      setUserStatus(UserStatus.Active)

      if (appMetadata?.assocOrg) {
        await welcomeEmail({ networkName: appMetadata.assocOrg && appMetadata.assocOrg.name })

        window.location.href = `${marketplaceUrl}returnTo=/getting-started`
        // const pathname = '/setup/network-success'

        // setRedirect({ pathname, showSuccess: true })
      } else {
        window.location.href = `${marketplaceUrl}returnTo=/getting-started`
      }
    }
  }

  if (redirect) {
    return <Navigate to={redirect} />
  }

  return (
    <Root>
      <SetupHeader />
      <FormContainer>
        <h1>Your Basic Information</h1>
        <VendorUserForm
          initialValues={{ ...defaultValues }}
          onComplete={onSubmit}
          submitButtonText="Create User"
          showImageUpload={false}
        />
      </FormContainer>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
`

const FormContainer = styled.div`
  max-width: 760px;
  width: 100%;
  padding: 40px;
  margin: 0 auto;
`

export default CreateUser
