import styled from '@emotion/styled'
import { Button } from 'components/theme'
import ProductSvg from '../ListingType/product.svg'
import ServiceSvg from '../ListingType/service.svg'
import CompanySvg from '../ListingType/company.svg'
import CompanyModal from './CompanyModal'
import { useState } from 'react'
import ProductServiceModal from './ProductServiceModal'
import ServiceModal from './ServiceModal'
import { useOrg } from 'data/hooks'
import { OrganizationType } from 'generated/graphql'
import ListingWizard from '../ListingWizard'

const ListingGetStarted = () => {
  const [showModal, setShowModal] = useState(false)
  const [showCompanyModal, setShowCompanyModal] = useState(false)
  const [showProductModal, setShowProductModal] = useState(false)
  const [showServiceModal, setShowServiceModal] = useState(false)
  const { organization } = useOrg()

  const toggleCompanyModal = () => {
    setShowCompanyModal((prevVal) => !prevVal)
  }

  const toggleProductModal = () => {
    setShowProductModal((prevVal) => !prevVal)
  }

  const toggleServiceModal = () => {
    setShowServiceModal((prevVal) => !prevVal)
  }

  return (
    <Root>
      {showModal && <ListingWizard closeModal={() => setShowModal(false)} />}
      <Title>Listings</Title>
      <ListingBlocks>
        <ListingBlock>
          <ListingImage src={CompanySvg} />
          <ListingName>
            {organization?.type === OrganizationType.Lawfirm ? 'Lawfirm' : 'Company'}
          </ListingName>
          <ListingDetail>Feature your business</ListingDetail>
          <LearnMore onClick={toggleCompanyModal}>Learn More</LearnMore>
        </ListingBlock>
        <ListingBlock>
          <ListingImage src={ProductSvg} />
          <ListingName>Product Listing</ListingName>
          <ListingDetail>Showcase your product</ListingDetail>
          <LearnMore onClick={toggleProductModal}>Learn More</LearnMore>
        </ListingBlock>
        {organization?.type !== OrganizationType.Lawfirm && (
          <ListingBlock>
            <ListingImage src={ServiceSvg} />
            <ListingName>Service Listing</ListingName>
            <ListingDetail>Professional Services</ListingDetail>
            <LearnMore onClick={toggleServiceModal}>Learn More</LearnMore>
          </ListingBlock>
        )}
      </ListingBlocks>
      <ButtonWrapper>
        <GetStartedButton onClick={() => setShowModal(true)}>Get Started</GetStartedButton>
      </ButtonWrapper>
      {showCompanyModal && <CompanyModal toggleModal={toggleCompanyModal} />}
      {showProductModal && <ProductServiceModal toggleModal={toggleProductModal} />}
      {showServiceModal && <ServiceModal toggleModal={toggleServiceModal} />}
    </Root>
  )
}

const GetStartedButton = styled(Button)`
  background: ${({ theme }) => theme.color.button};
  color: white;
  width: 280px;
`

const Root = styled.div``

const Title = styled.h2`
  text-align: center;
`

const ListingBlocks = styled.div`
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  justify-content: center;
`

const ListingBlock = styled.div`
  width: 33.3333%;
  text-align: center;
`

const ListingImage = styled.img`
  max-width: 40px;
`

const ListingName = styled.div`
  padding-top: 8px;
  font-weight: 500;
  font-size: 16px;
`

const ListingDetail = styled.div``

const LearnMore = styled.div`
  font-size: 16px;
  padding-top: 4px;
  color: #498de6;
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: 28px;
`

export default ListingGetStarted
