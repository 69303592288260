import { useState } from 'react'
import styled from '@emotion/styled'
import { Icon } from 'components/theme'

interface Props {
  question: string
  answer: string
}
const FaqAccordian = ({ question, answer }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Root onClick={() => setIsOpen((prevVal) => !prevVal)}>
      <AngleIcon isopen={isOpen.toString()} name="angle-down" />
      <Main>
        <Question>{question}</Question>
        <Answer isOpen={isOpen}>{answer}</Answer>
      </Main>
    </Root>
  )
}

const AngleIcon = styled(Icon)`
  margin-top: 20px;
  margin-left: 20px;
  font-size: 16px;
  transform: rotate(${({ isopen }) => (isopen === 'true' ? '180deg' : '0deg')});
`

const Root = styled.div`
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
`

const Main = styled.div``

const Question = styled.div`
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
`

const Answer = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding: 16px;
  font-size: 16px;
  line-height: 1.6;
`

export default FaqAccordian
