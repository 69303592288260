import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useDeleteLocation = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (locationId: number) => {
      await request(DELETE_LOCATION, { id: locationId })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

const DELETE_LOCATION = gql`
  mutation DeleteLocation($id: Int!) {
    deleteLocation(input: { id: $id }) {
      location {
        city
        id
        isHeadquarter
        country
        state
      }
    }
  }
`
