import { useEffect } from 'react'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useNavigate, useParams } from 'react-router-dom'
import { useViewer } from 'context/viewerContext'
import { useMutateViewer } from 'data/viewer/useMutateViewer'

function PersonalSuccess() {
  const { viewer } = useViewer()
  const mutateViewer = useMutateViewer()
  const { sessionId } = useParams<{ sessionId: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    const init = async () => {
      await mutateViewer.mutateAsync({
        details: {
          paymentStatus: 'PAID',
        },
      })

      navigate('/personal/your-apps', { replace: true })
    }

    if (sessionId) {
      init()
    } else {
      console.log('no session id')
    }
  }, [history, sessionId, viewer.details, viewer.id])

  return <FullPageLoading />
}

export default PersonalSuccess
