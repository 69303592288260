import Icon from 'components/theme/Icon'
import styled from '@emotion/styled'

function StyleButton({ style, onToggle, active, label, icon }: any) {
  const onMouseDown = () => {
    onToggle(style)
  }

  return (
    <ClickableButton activeStyle={active} onMouseDown={onMouseDown} type="button">
      {icon ? <IconButton name={icon} /> : label}
    </ClickableButton>
  )
}

const IconButton = styled(Icon)`
  max-width: 12px !important;
  max-height: 12px !important;
`

const ClickableButton = styled.button<{ activeStyle: boolean }>`
  color: ${({ activeStyle }) => (activeStyle ? '#ab55f7' : '#777')};
  cursor: pointer;
  margin-right: 12px;
  display: inline-flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 3px;
  border: 1px solid ${({ activeStyle }) => (activeStyle ? '#c28df1' : '#ccc')};
  box-shadow: none;
  outline: none;

  &:hover {
    color: #ab55f7;
  }
`

export default StyleButton
