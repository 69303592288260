import Button from 'components/theme/Button'
import { Dropdown } from 'semantic-ui-react'
import { Formik, Form } from 'formik'
import { Label, FormField } from 'components/theme/form'
import { newDeletedList } from 'services/helper'
import styled from '@emotion/styled'
import * as yup from 'yup'

import { useMutateTermsToOrg } from 'data/hooks'
import { useTaxonomyDropdowns } from 'components/forms/product/CategoriesForm/hooks/useTaxonomyDropdowns'

const validationSchema = yup.object().shape({
  practiceGroups: yup.array().max(10),
  country: yup.array().max(3),
})

useTaxonomyDropdowns
const defaultValues = {
  practiceGroups: [],
  countries: [],
  languages: [],
}

const UpdateSearchTermsForm = ({
  orgId,
  onComplete,
  initialValues = defaultValues,
  submitButtonText = 'Submit',
  isService = false,
}: any) => {
  const {
    employeeSizeDropdown,
    practiceGroupDropdown,
    countryDropdown,
    languageDropdown,
    isLoading: isTaxonomyDropdownsLoading,
  } = useTaxonomyDropdowns()

  const mutateTermsToOrg = useMutateTermsToOrg()

  if (isTaxonomyDropdownsLoading) {
    return null
  }

  const onSubmit = async ({ practiceGroups, countries, languages }: any) => {
    try {
      console.log({
        practiceGroups,
        countries,
        languages,
      })
      const currentTerms = [...practiceGroups, ...countries, ...languages]

      const initialTerms = [
        ...initialValues.practiceGroups,
        ...initialValues.countries,
        ...initialValues.languages,
      ]

      const [createdTermIds, deletedTermIds] = newDeletedList(initialTerms, currentTerms)

      console.log(createdTermIds)
      const isServiceHasServicesTerm = isService && !initialTerms.some((termId) => termId === 682)

      await mutateTermsToOrg.mutateAsync({
        createdTermIds: isServiceHasServicesTerm ? [...createdTermIds, 682] : createdTermIds,
        deletedTermIds,
        organizationId: orgId,
      })
    } catch (e) {
      console.error(e)
    }

    onComplete()
  }

  return (
    <Root>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ values, setFieldValue, isSubmitting, isValid }) => (
          <Form>
            <FormField>
              <Label helpContent="Recommendation: Select/indicate Transactional or Litigation">
                {isService ? 'Type of Practice' : 'Target Practice Group (up to 10)'}
              </Label>
              <Dropdown
                fluid
                selection
                search
                multiple
                onChange={(_e, { value }) => setFieldValue('practiceGroups', value)}
                options={practiceGroupDropdown}
                value={values.practiceGroups}
                name="practiceGroups"
                placeholder={`Practice ${isService ? '' : 'Groups'}`}
              />
            </FormField>

            <FormField>
              <Label required>Approx. Size (# of Attorneys)</Label>
              <Dropdown
                fluid
                selection
                clearable
                onChange={(_e: any, { value }: any) => setFieldValue('employeeSize', value)}
                options={employeeSizeDropdown?.map(({ text, ...other }) => ({
                  ...other,
                  text: `${text} Attorneys`,
                }))}
                value={values.employeeSize}
                name="employeeSize"
              />
            </FormField>

            <FormField>
              <Label helpContent="Where product is sold, leave blank for all.">Target market</Label>
              <Dropdown
                fluid
                selection
                search
                multiple
                name="countries"
                value={values.countries}
                options={countryDropdown}
                placeholder="Country (leave blank for all)"
                onChange={(_e, { value }) => setFieldValue('countries', value)}
              />
            </FormField>

            <FormField>
              <Label helpContent="Supported">Languages</Label>
              <Dropdown
                fluid
                selection
                search
                multiple
                name="languages"
                defaultValue={values.languages}
                options={languageDropdown}
                placeholder="Defaults to English"
                onChange={(_e, { value }) => setFieldValue('languages', value)}
              />
            </FormField>

            <FormField>
              <ButtonContainer>
                <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                  {submitButtonText}
                </Button>
              </ButtonContainer>
            </FormField>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

// const parseFeatures = (product) => {
//   const features = featuresData.map(({ name }) => name)

//   return features.length > 0 ? features.join(', ') : ''
// }

const Root = styled.div`
  max-width: 760px;
  width: 100%;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

export default UpdateSearchTermsForm
