import { useQuery } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlOrganizationTechstackFilterQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useTechstackFilter = (userIds?: number[]) => {
  const request = useGql()
  const { organization } = useOrg()

  const { data, ...other } = useQuery(['techstack', 'Filter', [userIds]], async () => {
    const response = await request<GqlOrganizationTechstackFilterQuery>(GET_TECHSTACK_FILTER, {
      selectedOrganizationId: organization?.id,
      userIds,
    })

    return response.techstackFilter
  })

  return { techstack: data, ...other }
}

const GET_TECHSTACK_FILTER = gql`
  query OrganizationTechstackFilter($selectedOrganizationId: UUID!, $userIds: [Int]) {
    techstackFilter(orgId: $selectedOrganizationId, userIds: $userIds) {
      id
      addedBy
      access
      metadata
      product {
        id
        name
        details
        createdAt
        slug
        updatedAt
        excerpt
        termToProducts {
          termId
          term {
            taxonomyId
            id
            name
          }
        }
        testDrives {
          id
          details
          termId
          termToTestDrives {
            term {
              id
              name
              slug
              taxonomyId
              taxonomy {
                id
                name
                slug
              }
            }
          }
        }
      }
      usersToTechstacks {
        user {
          id
          email
          first
          last
          details
        }
      }
    }
  }
`
