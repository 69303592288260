import React from 'react'
import { Icon, Button, Dropdown } from 'semantic-ui-react'
import styled from '@emotion/styled'
import { TextInput, Label } from 'components/theme/form'
import { Formik, Form } from 'formik'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { useTaxonomyDropdown } from 'hooks/useTaxonomyDropdown'
import { COMPANY_SIZE_OPTIONS } from 'model/static'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useLocationForm } from './LocationForm2'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  name: yup.string().required('Required').nullable(),
  lawfirmSize: yup.string().required('Required'),
})

type Values = {
  name: string
  lawfirmType?: number
  lawfirmSize: string
  industry?: number
  logoUrl: any
  jurisdictionRegion: string
  specialty?: number
  city?: string
  state?: string
  country?: string
  isHeadquarter?: boolean
}

type Props = {
  defaultValues: Values
  onSubmit: (values: Values) => void
  showLocation: boolean
  onSubmitButtonText?: string
}

const LawfirmForm = ({
  defaultValues,
  onSubmit,
  showLocation = false,
  onSubmitButtonText = 'Update Law Firm',
}: Props) => {
  const { LocationForm, state, country, city } = useLocationForm({})
  const { options: practiceGroupOptions, isLoading: practiceGroupsLoading } = useTaxonomyDropdown({
    taxonomyId: 13,
  })
  const { options: industryOptions, isLoading: industryLoading } = useTaxonomyDropdown({
    taxonomyId: 20,
  })

  if (practiceGroupsLoading || industryLoading) return <FullPageLoading />

  return (
    <Formik
      initialValues={{ ...defaultValues }}
      onSubmit={(values) =>
        onSubmit({ ...values, state, country, city, isHeadquarter: showLocation })
      }
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ values, isValid, setFieldValue, isSubmitting }) => (
        <Form>
          <FormFieldsContainer>
            <FormField>
              <UploadSingleAsset
                name="logoUrl"
                label="Law Firm Logo"
                selectedImage={values.logoUrl}
              >
                <UploadContent>
                  <UploadIcon name="building" />
                  <span>Upload Square Logo</span>
                </UploadContent>
              </UploadSingleAsset>
            </FormField>
            <FormField>
              <TextInput
                name="name"
                label="Law Firm Name"
                placeholder="Law Firm Name..."
                required
              />
            </FormField>
            <FormField>
              <Label required>Approx. Size (# of Attorneys)</Label>
              <Dropdown
                fluid
                selection
                clearable
                onChange={(_e, { value }) => setFieldValue('lawfirmSize', value)}
                options={COMPANY_SIZE_OPTIONS}
                value={values.lawfirmSize}
                name="lawfirmSize"
              />
            </FormField>
            <FormField>
              <Label>Specialty</Label>
              <Dropdown
                fluid
                selection
                search
                clearable
                value={values.specialty}
                onChange={(_e, { value }) => setFieldValue('specialty', value)}
                options={practiceGroupOptions}
                name="specialty"
              />
            </FormField>
            <FormField>
              <Label>Industry</Label>
              <Dropdown
                fluid
                selection
                search
                clearable
                value={values.industry}
                onChange={(_e, { value }) => setFieldValue('industry', value)}
                options={industryOptions}
                name="industry"
              />
            </FormField>
            {showLocation && (
              <>
                <hr />
                <LocationWrapper>
                  <LocationTitle>Your Law firm Location:</LocationTitle>
                  <LocationForm />
                </LocationWrapper>
              </>
            )}
          </FormFieldsContainer>
          <FormField>
            <Button
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
              labelPosition="right"
              primary
              icon
              type="submit"
            >
              {onSubmitButtonText}
              <Icon name="building" />
            </Button>
          </FormField>
        </Form>
      )}
    </Formik>
  )
}

const UploadContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const UploadIcon = styled(Icon)`
  font-size: 50px !important;
  height: 40px !important;
  margin-top: 10px !important;
  margin-right: 0 !important;
  opacity: 0.7 !important;
  color: #4d5065;
`

const FormFieldsContainer = styled.div`
  padding-bottom: 20px;
`

const FormField = styled.div`
  margin-bottom: 20px;
`

const LocationWrapper = styled.div``

const LocationTitle = styled.div`
  color: #555;
  font-weight: 500;
  font-size: 16px;
  margin: 12px 0;
`

export default LawfirmForm
