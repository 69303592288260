import styled from '@emotion/styled'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import GreenCheckSvg from 'assets/green-check.svg'
import { useWizardModal } from 'components/modals/WizardModal'
import { Button, Icon } from 'components/theme'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'

const ProductAddedSuccess = () => {
  const marketplaceUrl = useMarketplaceUrl(true)
  const { goNextStep } = useWizardModal()

  return (
    <Root>
      <Title>New Product Added Successfully</Title>
      <CheckIcon src={GreenCheckSvg} />
      <ButtonWrapper>
        <Button onClick={goNextStep}>
          Continue my Listing <Icon src={faCaretRight} />
        </Button>
      </ButtonWrapper>
      <a href={`${marketplaceUrl}returnTo=/admin/product`} target="_blank">
        View my product library
      </a>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 28px;
`

const CheckIcon = styled.img`
  max-width: 80px;
`

const ButtonWrapper = styled.div`
  padding: 28px 0 16px;
`

export default ProductAddedSuccess
