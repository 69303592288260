import * as yup from 'yup'
import styled from '@emotion/styled'
import { TextInput, FormField } from 'components/theme/form'
import { FlexLoading } from 'components/PulsatingLoader'
import Button from 'components/theme/Button'
import { Formik, Form, Field } from 'formik'
import _ from 'lodash'
import PdfUploader from 'components/fields/PdfUploader'
import PromptIfDirty from 'components/forms/PromptIfDirty'
import { useUploadPdf } from 'hooks/utility/useUploadPdf'
import { useMutateProductDetails } from 'data/hooks'
import { useOrg } from 'data/organization/useOrg'
import { Product } from 'data/types'

type Props = {
  initialValues: any
  onComplete: () => void
  product: Product
}

type Values = {
  privacyPolicyUrl: string
  publicTermsUrl: string
  eulaUrl: string
  eulaPdf: any
  pickedTerms: string
}

const validationSchema = yup.object().shape({
  eulaUrl: yup.string(),
  privacyPolicyUrl: yup.string(),
  publicTermsUrl: yup.string(),
})

function LegalForm({ initialValues, onComplete, product }: Props) {
  const mutateProductDetails = useMutateProductDetails()
  const { organizationLoading } = useOrg()
  const uploadPdf = useUploadPdf()

  const onSubmit = async ({
    privacyPolicyUrl,
    publicTermsUrl,
    eulaUrl,
    eulaPdf,
    pickedTerms,
  }: Values) => {
    if (!product) return

    let newEulaPdf = null

    // TODO: can you refactor this so that we don't need the conditional
    if (eulaPdf && !_.has(eulaPdf, 'url')) {
      newEulaPdf = await uploadPdf(eulaPdf)
    } else if (eulaPdf && _.has(eulaPdf, 'url')) {
      newEulaPdf = eulaPdf
    }

    const details = {
      ...product?.details,
      eulaUrl,
      eulaPdf: newEulaPdf,
      privacyPolicyUrl,
      publicTermsUrl,
      pickedTerms,
    }

    mutateProductDetails.mutate(
      {
        productId: product.id,
        details,
      },
      {
        onSuccess: () => onComplete(),
      }
    )
  }

  if (organizationLoading) {
    return <FlexLoading />
  }

  return (
    <Root>
      <Formik
        validateOnMount
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <PromptIfDirty />
            <FormField></FormField>
            <FormField>
              <Flex>
                <Radio type="radio" name="pickedTerms" value="eulaPdf" />
                <PdfUploader name="eulaPdf" />
              </Flex>
            </FormField>
            <FormField>
              <Flex>
                <Radio type="radio" name="pickedTerms" value="privacyPolicyUrl" />
                <TextInput
                  label="Privacy Policy URL"
                  placeholder="https://example.com/privacy"
                  name="privacyPolicyUrl"
                />
              </Flex>
            </FormField>
            <FormField>
              <Flex>
                <Radio type="radio" name="pickedTerms" value="publicTermsUrl" />
                <TextInput
                  label="Public Terms and Conditions"
                  placeholder="https://example.com/terms"
                  name="publicTermsUrl"
                />
              </Flex>
            </FormField>
            <FormField>
              <ButtonContainer>
                <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                  UPDATE
                </Button>
              </ButtonContainer>
            </FormField>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  max-width: 760px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const Radio = styled(Field)`
  margin-right: 16px;
  transform: translateY(12px);
`

export default LegalForm
