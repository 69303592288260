import React from 'react'
import ImageBodyLink from './ImageBodyLink'
import styled from '@emotion/styled'
import image from 'assets/marketing/people-flying-rafiki.png'
import InviteModal from 'components/modals/InviteModal'
import { useModal } from 'components/Modal'

const InviteTeam = () => {
  const { showModal, toggle } = useModal()
 
  const ElementLink = <StyledHref onClick={toggle}>Invite Members</StyledHref>

  return (
    <>
      <InviteModal open={showModal} onModalClose={toggle} />
      <ImageBodyLink
        image={image}
        title="Invite your Team Members"
        Message="Invite additional users and admins who support your organization"
        element={ElementLink}
      />
    </>
  )
}

const StyledHref = styled.a`
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  margin-left: 16px;
`

export default InviteTeam
