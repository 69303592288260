import { FullPageLoading } from 'components/PulsatingLoader'
import { useOrg } from 'data/hooks'
import { useLazyApi } from 'hooks/useApi'
import { useRedirect } from 'hooks/useRedirect'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'

// https://dashboard.stripe.com/test/connect/accounts/acct_1J5xz9R2G9iM79CE/products/prod_LKgkYnS3WStOQX
// const ACCOUNT_ID = 'acct_1J5xz9R2G9iM79CE' // TheoremLTS test account

const StripeCheckout = () => {
  const location = useLocation()
  const { organization } = useOrg()
  const redirect = useRedirect()
  const [getStripeSession] = useLazyApi('stripe/connect/checkout-url')

  useEffect(() => {
    ;(async () => {
      try {
        const locationState = location.state as any

        const priceId = locationState.priceId
        const productId = locationState.productId
        const listingType = locationState.listingType
        const isTheoremLvnDiscount = locationState.isTheoremLvnDiscount
        const isClocDiscount = locationState.isClocDiscount // NOTE: this hard code is fine
        const accountId = locationState.accountId
        const associationId = locationState.associationId

        const trialEnd = organization?.appMetadata?.clocListing?.expiredDate
        const trialPeriodDays = organization?.appMetadata?.hasClocTrial ? 90 : undefined

        const { url } = await getStripeSession({
          priceId,
          productId,
          organizationId: organization?.id,
          associationId,
          accountId,
          listingType,
          trialEnd,
          trialPeriodDays,
          isTheoremLvnDiscount,
          isClocDiscount,
        })

        if (!url) {
          toast.error("We'er sorry, there was an issue. Please contact support.")

          redirect('product-listing')

          return
        }

        window.location.replace(url)
      } catch (e) {
        console.log(e)
        debugger
      }
    })()
  }, [])

  return <FullPageLoading />
}

export default StripeCheckout
