import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { GqlViewerAppMetadataQuery } from 'generated/graphql'
import { useGql } from 'hooks/useGql'

export const useAppMetadata = () => {
  const request = useGql()
  return useQuery<any>(
    ['viewer', 'appMetadata'],
    async () => {
      const data = await request<GqlViewerAppMetadataQuery>(VIEWER_APP_METADATA)

      return data.viewer?.appMetadata
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  )
}

const VIEWER_APP_METADATA = gql`
  query ViewerAppMetadata {
    viewer {
      appMetadata
    }
  }
`
