import styled from '@emotion/styled'
import ComingSoon from 'components/layout/ComingSoon'
import TechubComingSoon from 'assets/marketing/techhub-comingsoon.png'

const Techhub = () => {
  return (
    <Root>
      <ComingSoon title="Coming Soon" image={TechubComingSoon} />
    </Root>
  )
}

const Root = styled.div``

export default Techhub
