import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useNavigate, useLocation } from 'react-router-dom'
import { useLazyApi } from 'hooks/useApi'
import { useSetUserStatus } from 'hooks/graphql'
import { FullPageLoading } from 'components/PulsatingLoader'
import { UserStatus } from 'generated/graphql'
import { useOrg } from 'data/organization/useOrg'

const getQueryParameter = (param: string) =>
  new URLSearchParams(document.location.search.substring(1)).get(param)

function LawfirmCheckout() {
  const location = useLocation()
  const [createSubscription] = useLazyApi('stripe/organization/handle-lawfirm-checkout')
  const [getStripeSession] = useLazyApi('stripe/organization/lawfirm-checkout')
  const { setUserStatus } = useSetUserStatus()
  const { organization, organizationLoading } = useOrg()
  const navigate = useNavigate()

  const locationState = location.state as any

  useEffect(() => {
    ;(async () => {
      const sessionId =
        getQueryParameter('session_id') || localStorage.getItem('stripeCheckoutSessionId')

      if (sessionId) {
        localStorage.setItem('stripeCheckoutSessionId', sessionId) // store session id if still loading

        if (organizationLoading) return

        await createSubscription({ organizationId: organization?.id, sessionId })

        localStorage.removeItem('stripeCheckoutSessionId')
        await setUserStatus(UserStatus.CreateUser)
        navigate('/setup/lawfirm-user')
      } else {
        const { url } = await getStripeSession({ priceId: locationState.priceId })
        window.location.replace(url)
      }
    })()
  }, [organizationLoading])

  return (
    <Root>
      <FullPageLoading />
    </Root>
  )
}

const Root = styled.div``

export default LawfirmCheckout
