import styled from '@emotion/styled'
import { useLogout } from 'hooks/useLogout'
import Button from './Button'

const LogoutButton = ({ isWhite = false }: { isWhite?: boolean }) => {
  const logout = useLogout()

  return (
    <LogoutButtonComponent onClick={logout} type="button" isWhite={isWhite}>
      Logout
    </LogoutButtonComponent>
  )
}

const LogoutButtonComponent = styled(Button)<{ isWhite: boolean }>`
  position: absolute;
  top: 20px;
  right: 20px;
  ${({ isWhite }) =>
    isWhite
      ? `
    color: white;
    background: transparent;
    border-color: white;

    &:hover {
    background: rgba(255,255,255,.3);
    }
  `
      : ''}
`

export default LogoutButton
