import { useQuery } from '@tanstack/react-query'
import { GqlAllProductsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useAllProducts = () => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['products'],
    async () => {
      const response = await request<GqlAllProductsQuery>(GET_PRODUCTS)

      return response.products
    },
    { staleTime: 60 * 60 * 1000 }
  )

  return { products: data || [], ...other }
}

export const GET_PRODUCTS = gql`
  query AllProducts {
    products(orderBy: NAME_ASC) {
      id
      excerpt
      details
      description
      createdAt
      name
      slug
      state
      updatedAt
    }
  }
`
