import styled from '@emotion/styled'
import { ProductData } from 'interfaces/graphql'
import { Icon } from 'components/theme'

interface Props {
  product: ProductData
}

const ECOSYSTEM_PRODUCT_URL = 'https://ecosystem.theoremlegal.com/product'

const ModalHeader = ({ product }: Props) => {
  const { name, slug, details, excerpt } = product

  return (
    <Root>
      <Left>
        <SmallLogo src={details.squareLogoUrl} />
        <Content>
          <Name>{name}</Name>
          <OutLink href={`${ECOSYSTEM_PRODUCT_URL}/${slug}`} target="blank">
            View Listing
            <OutIcon name="external-link-alt" />
          </OutLink>
        </Content>
      </Left>
      <Right>{excerpt}</Right>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #efefef;
  padding: 30px;
  border-bottom: 1px solid #ddd;
`

const Left = styled.div`
  display: flex;
`

const Right = styled.div`
  max-width: 300px;
  margin-left: 80px;
  margin-right: 20px;
  background: rgba(144, 19, 254, 0.08);
  padding: 12px;
  border-radius: 6px;
  border: 1px dashed #9013fe;
  letter-spacing: 0.01rem;
`

const Name = styled.h3`
  margin-bottom: 4px;
`

const Content = styled.div`
  padding-left: 16px;
`

const SmallLogo = styled.img`
  width: 100%;
  max-width: 64px;
  height: auto;
`

const OutLink = styled.a`
  font-weight: bold;
  white-space: nowrap;
`

const OutIcon = styled(Icon)`
  margin-left: 6px;
  color: #498de6;
`

export default ModalHeader
