import React from 'react'
import styled from '@emotion/styled'
import { useInstantSearch, useSearchBox } from 'react-instantsearch-hooks-web'
import SearchResults from './SearchResults'
import { useAlgoliaTechstack } from './AlgoliaTechstackContext'

function SearchBar() {
  const { dispatch } = useAlgoliaTechstack()
  const { refine, isSearchStalled } = useSearchBox()
  const { results } = useInstantSearch()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    refine(value)
    dispatch && dispatch({ type: 'ON_KEY_DOWN', payload: { searchValue: value } })
  }

  const value = results.query

  return (
    <Root>
      <ClickOut isBig={!!value} onClick={() => refine('')} />
      <Form noValidate action="" role="search">
        <Bar type="search" onChange={onChange} placeholder="Search & select apps" />
        {isSearchStalled ? 'My search is stalled' : ''}
        <SearchResults />
      </Form>
    </Root>
  )
}

const Form = styled.form`
  z-index: 2;
  position: relative;
`

const Root = styled.div`
  position: relative;
  z-index: 500;
`

const ClickOut = styled.div<{ isBig: boolean }>`
  position: fixed;
  ${({ isBig }) => (isBig ? 'top: 0; right: 0; left: 0; bottom: 0;' : '')}
  z-index: 1;
`

const Bar = styled.input`
  width: 100%;
  padding: 9.5px 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease, border-color 0.1s ease;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  flex: 1 0 auto;

  &::-webkit-input-placeholder {
    color: #616161;
  }

  &:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

export default SearchBar
