import { useState, useEffect } from 'react'
import { useField } from 'formik'
import Label from 'components/theme/form/Label'
import Dropdown from 'react-dropdown-select'
import styled from '@emotion/styled'
import { v4 } from 'uuid'

const Select = ({
  label,
  required = false,
  options,
  onChange = undefined,
  portal = false,
  itemRenderer = undefined,
  ...props
}: any) => {
  const [, meta, helpers] = useField(props)
  const root = document.getElementsByClassName('main-container-wrap')[0]
  const [id, setId] = useState<any>(null)

  useEffect(() => {
    const newId = v4()
    setId(newId)
  }, [])

  const errorText = meta.error && meta.touched ? meta.error : ''

  const handleChange = (values: any) => {
    helpers.setValue(values)

    if (onChange) {
      onChange(values)
    }
  }

  const handleDropdownOpen = () => {
    if (!portal) return

    const element = document.getElementsByClassName(
      'react-dropdown-select-dropdown react-dropdown-select-dropdown-position-bottom'
    )[0]
    if (!element) return
    const root: any = document.getElementById(id)
    element.setAttribute('style', `top:${root.getBoundingClientRect().top + 74}px;`)
  }

  return (
    <div id={id}>
      {label && <Label required={required}>{label}</Label>}
      <Dropdown
        style={{
          color: 'rgba(0,0,0,0.87)',
          padding: '9.5px 14px',
          width: '100%',
          border: '1px solid rgba(34,36,38,0.15)',
        }}
        onDropdownOpen={handleDropdownOpen}
        itemRenderer={itemRenderer}
        values={meta.value}
        portal={portal ? root as any : undefined}
        options={options}
        onChange={handleChange}
      />
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </div>
  )
}

const ErrorText = styled.div`
  color: #f44336;
  font-weight: 400;
  margin-top: 6px;
`

export default Select
