import { breakpoint } from 'services/theming'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { Multiselect, FormField } from 'components/theme/form'
import { Button } from 'components/theme'
import { useQueryProductsDropdown } from 'data/hooks'
import { useMutateCompetitors } from './useMutateCompetitors'

type Props = {
  initialValues: any
  productId?: number
  onComplete: () => void
}

const CompetitorsForm: React.FC<Props> = ({ initialValues, productId, onComplete }) => {
  const mutateCompetitors = useMutateCompetitors(productId)
  const { data: productOptions } = useQueryProductsDropdown()

  const onSubmit = async ({ competitorIds }: any) => {
    mutateCompetitors
    mutateCompetitors.mutate({ competitorIds, initialValues })

    onComplete()
  }

  return (
    <Root>
      <Formik initialValues={{ competitorIds: initialValues }} onSubmit={onSubmit}>
        {({ values }) => (
          <Form>
            <FormField>
              <Multiselect
                name="competitorIds"
                label="Add Competitors"
                options={productOptions}
                value={values.competitorIds}
              />
            </FormField>
            <Button type="submit">Submit</Button>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div`
  position: relative;

  ${breakpoint.mobile} {
    padding: 20px 0 60px;
  }
`

export default CompetitorsForm
