import styled from '@emotion/styled'
import React from 'react'
import { Dropdown } from 'semantic-ui-react'

interface Props {
  currencyValue: string | undefined
  onCurrencyChange: (value?: string) => void
}

const CurrencyField: React.FC<Props> = ({ currencyValue, onCurrencyChange }) => {
  return (
    <Root>
      <Dropdown
        value={currencyValue}
        options={currencyOptions}
        onChange={(_e, { value }) => {
          if (typeof value === 'string' || typeof value === 'undefined') onCurrencyChange(value)
        }}
        fluid
        selection
      />
    </Root>
  )
}

const currencyOptions = [
  {
    value: 'USD',
    text: 'USD',
    longText: 'US Dollar',
  },
  {
    value: 'CAD',
    text: 'CAD',
    longText: 'Canadian Dollar',
  },
  {
    value: 'EUR',
    text: 'EUR',
    longText: 'Euro',
  },
  {
    value: 'GBP',
    text: 'GBP',
    longText: 'British Pound',
  },
  {
    value: 'CNY',
    text: 'CNY',
    longText: 'Chinese Yuan Renminbi',
  },
  {
    value: 'SGD',
    text: 'SGD',
    longText: 'Singapore Dollar',
  },
  {
    value: 'JPY',
    text: 'JPY',
    longText: 'Japanese Yen',
  },
  {
    value: 'AUD',
    text: 'AUD',
    longText: 'Australian Dollar',
  },
]

const Root = styled.div`
  display: flex;
  height: 38px;
  border-radius: 4px;
  border: 0;
  outline: 0;
  width: 100%;
  display: block;
  cursor: pointer;
`

export default CurrencyField
