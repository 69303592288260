import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'
import UserRoleSelect from './UserRoleSelect'
import Avatar from 'components/user/Avatar'
import { ViewerSubscriptions } from 'interfaces/user/user.interface'
import TeamUpgradeCta from './TeamUpgradeCta'
import SubscriptionBoxes from './SubscriptionBoxes'
import InviteModal from 'components/modals/InviteModal'
import Pagination from 'components/DiscoverOrganization/Pagination'
import { SearchInput } from 'components/UserStories/SearchBar'
import { Icon, LineItemTable } from '@theoremlts/theorem-design'
import ActionMenu from './ActionMenu'
import SuperAdmins from './SuperAdmins'
import { useLazyApi } from 'hooks/useApi'
import { useOrg } from 'data/organization/useOrg'
import { MemberStatus, OrganizationType, UserRole } from 'generated/graphql'
import { useViewer } from 'context/viewerContext'
import { useQueryClient } from '@tanstack/react-query'
import { Member } from 'data/types'

interface SearchableMember extends Member {
  searchableInfo: string[]
}

const Team = () => {
  const [getOrgInvites] = useLazyApi('auth0/get-org-invites')
  const { organization } = useOrg()
  const { viewer, isAdmin, subscription } = useViewer()
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [query, setQuery] = useState<string>('')
  const [visibleTeam, setVisibleTeam] = useState<SearchableMember[]>(
    organization?.members as SearchableMember[]
  )
  const [allTeam, setAllTeam] = useState<SearchableMember[]>([])
  const [refreshCounter, setRefreshCounter] = useState(0)
  const queryClient = useQueryClient()

  const id = organization?.id

  const parseTeamMembers = (team = organization?.members) =>
    team?.map((member) => {
      return {
        ...member,
        searchableInfo: `${member.id || ''}, ${member.status}, ${member.email || ''}, ${
          member.jobTitle || ''
        }, ${member.first || ''}, ${member.last || ''}`
          .toLowerCase()
          .split(', '),
      }
    }) || []

  useEffect(() => {
    setQuery('')
    const parsedTeam = parseTeamMembers()

    ;(async () => {
      if (organization?.type !== OrganizationType.Association || !organization.auth0OrgId) {
        setAllTeam(parsedTeam)
        return
      }

      try {
        const result = await getOrgInvites()
        const auth0InvitedMembers: SearchableMember[] = result.map((auth0User: any) => ({
          id: auth0User.id,
          role: UserRole.User,
          status: MemberStatus.Invited,
          email: '',
          first: '',
          last: '',
          name: auth0User.invitee.email,
          headshot: '',
          activity: '',
          user: {},
          searchableInfo: `${auth0User.id || ''}, INVITED, ${auth0User.email || ''}`
            .toLowerCase()
            .split(', '),
        }))
        setAllTeam([...auth0InvitedMembers, ...parsedTeam])
      } catch (e) {
        setAllTeam(parsedTeam)
      }
    })()
  }, [refreshCounter])

  const splitQuery = (query: string) => {
    const checkForHangingComma = (queries: string[]) => {
      const i = queries.length - 1
      if (queries[i][queries[i].length - 1] === ',') {
        queries[i] = queries[i].slice(0, queries[i].length - 1)
      }
    }

    let queries = [] as string[]
    if (query.includes(', ')) {
      queries = query.split(', ')
    } else if (query.includes(' ')) {
      queries = query.split(' ')
    } else {
      queries = [query]
    }
    checkForHangingComma(queries)
    queries = queries.map((query) => query.toLowerCase())
    return queries
  }

  useEffect(() => {
    if (query) {
      const queries = splitQuery(query)
      const results = allTeam.filter(({ searchableInfo }) =>
        queries.every((query) => searchableInfo.some((value) => value.includes(query)))
      )
      setPage(1)
      setVisibleTeam(results)
    } else {
      setVisibleTeam(allTeam)
    }
  }, [query, allTeam])

  if (!organization) return null

  const printTeam = () =>
    visibleTeam
      .filter(({ role }) => {
        if (
          viewer.role === UserRole.SuperAdmin &&
          organization?.id !== 'dc6d31c7-40b1-4616-9d00-36695e03d515'
        )
          return true
        return role !== 'SUPER_ADMIN'
      })
      .map((member: any, index: number) => (
        <TableRow key={index}>
          <PersonItem>
            {member.user ? (
              <Avatar user={member.user} />
            ) : (
              <NoAvatar>
                <NoAvatarIcon name="user" />
              </NoAvatar>
            )}
            <PersonDetails>
              {member.name ? (
                <>
                  <PersonName>{member.name}</PersonName>
                  <PersonEmail>{member.email}</PersonEmail>
                </>
              ) : (
                <PersonName>{member.email}</PersonName>
              )}
            </PersonDetails>
          </PersonItem>
          <TypeItem>
            <UserRoleSelect member={member} />
          </TypeItem>
          <ActiveItem>{member.activity}</ActiveItem>
          <StatusItem emphasis={member.status !== 'ACTIVE'}>{member.status}</StatusItem>
          {isAdmin && (
            <td>
              <ActionMenu
                member={member}
                onConfirmReject={rejectRequestUser}
                onConfirmAllow={addRequestUser}
              />
            </td>
          )}
        </TableRow>
      ))
      .slice((page - 1) * 25, (page - 1) * 2 + 25)

  const addRequestUser = () => {
    queryClient.invalidateQueries(['org'])
  }

  const rejectRequestUser = () => {
    setRefreshCounter((prevVal) => prevVal + 1)
    queryClient.invalidateQueries(['org'])
  }

  const onInviteUsers = () => {
    queryClient.invalidateQueries(['org'])
  }

  const toggleInviteModal = () => {
    setInviteModalOpen((prevVal) => !prevVal)
  }

  return (
    <Root>
      <ButtonWrapper>
        <SearchInput
          placeholder="Search Team Members"
          onChange={(event) => setQuery(event.target.value)}
        />
        <AddMemberButton onClick={toggleInviteModal}>+ Add Member</AddMemberButton>
      </ButtonWrapper>
      {organization?.type === OrganizationType.Lawfirm && (
        <>
          {subscription === ViewerSubscriptions.Free && (
            <TeamUpgradeCta
              memberCount={allTeam.filter(({ role }) => role !== UserRole.SuperAdmin).length}
            />
          )}
          <SubscriptionBoxes members={allTeam.filter(({ role }) => role !== UserRole.SuperAdmin)} />
        </>
      )}
      <LineItemTable
        headerItems={['Person', 'Type', 'Last Activity', 'Status', ' ']}
        lineItems={printTeam()}
      />
      {viewer.role === UserRole.SuperAdmin && id === 'dc6d31c7-40b1-4616-9d00-36695e03d515' && (
        <SuperAdmins />
      )}
      <InviteModal
        open={inviteModalOpen}
        onModalClose={toggleInviteModal}
        onInvite={onInviteUsers}
      />
      {organization.members.length / 25 > 1 && (
        <Pagination pageCount={visibleTeam.length / 25} onChange={(page) => setPage(page)} />
      )}
    </Root>
  )
}

const NoAvatar = styled.div`
  transition: width 1000ms, height 1000ms, border-radius 1000ms, top 1000ms, right 1000ms;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #90a4ae;
  overflow: hidden;
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 32px;
`

const NoAvatarIcon = styled(Icon)`
  position: absolute;
  font-size: 16px;
`

const Root = styled.div``

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
`

const TableRow = styled.tr``

const PersonItem = styled.td`
  display: flex;
  align-items: center;
`

const TypeItem = styled.td``

const ActiveItem = styled.td``

const StatusItem = styled.td<{ emphasis?: boolean }>`
  ${({ emphasis }) => emphasis && 'font-weight: bold; color: #9013FE;'}
`

const PersonDetails = styled.div`
  padding-left: 8px;
`

const PersonName = styled.div`
  font-weight: 500;
`

const PersonEmail = styled.div`
  font-size: 12px;
  color: #6f7782;
`

const AddMemberButton = styled(Button)`
  white-space: nowrap;
  padding: 4px 16px;
  height: 32px;
  margin: 8px 0;
`

export default Team
