import { useState, useEffect } from 'react'
import { useTrackingChartContext } from 'context/trackingChartContext'
import NumberCard from 'components/charts/NumberCard'
import { useLazyApi } from 'hooks/useApi'
import _ from 'lodash'

function TotalCtaHits() {
  const {
    startDate,
    endDate,
    currentLoadingQueue,
    setCurrentLoadingQueue,
    productToAnalyze: product,
  } = useTrackingChartContext()
  const [number, setNumber] = useState(0)
  const [getProductCta] = useLazyApi('tracking/amplitude/product-cta')

  useEffect(() => {
    const init = async () => {
      const result = await getProductCta({ startDate, endDate, id: product })

      const series = _.get(result, 'series', [])
      const totalHits = series
        .map((val: number[]) => val.reduce((a, b) => a + b, 0))
        .reduce((a: number, b: number) => a + b, 0)

      setNumber(totalHits)
      setCurrentLoadingQueue((prevVal: number) => prevVal + 1)
    }

    if (currentLoadingQueue === 2) {
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentLoadingQueue])

  return <NumberCard title="Total Pricing Hits" number={number} />
}

export default TotalCtaHits
