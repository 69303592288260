import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { newDeletedList } from 'services/helper'
import { gql } from 'graphql-request'
import { useOrg } from 'data/organization/useOrg'
import { useViewer } from 'context/viewerContext'
import { UserRole } from 'generated/graphql'

interface Variables {
  competitorIds: number[]
  initialValues: number[]
}
export const useMutateCompetitors = (productId?: number) => {
  const request = useGql()
  const queryClient = useQueryClient()
  const { vendor } = useOrg()
  const { viewer, isSuperAdmin } = useViewer()

  return useMutation(
    async (variables: Variables) => {
      const { competitorIds, initialValues } = variables

      const [newCompetitorIds, deletedCompetitorIds] = newDeletedList(initialValues, competitorIds)

      if (newCompetitorIds.length > 0) {
        for (const competitorId of newCompetitorIds) {
          /* eslint-disable no-await-in-loop */
          await request(CREATE_COMPETITOR, {
            competitorId,
            productId,
            organizationId: !isSuperAdmin ? vendor?.id : null,
            locked: viewer.role === UserRole.SuperAdmin,
          })
        }
      }

      if (deletedCompetitorIds.length > 0) {
        for (const competitorId of deletedCompetitorIds) {
          await request(DELETE_PRODUCT_TO_COMPETITOR, {
            competitorId,
            productId,
            locked: viewer.role === UserRole.SuperAdmin,
          })
        }
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

export const CREATE_COMPETITOR = gql`
  mutation CreateCompetitor(
    $competitorId: Int!
    $productId: Int!
    $organizationId: UUID
    $locked: Boolean!
  ) {
    createProductToCompetitor(
      input: {
        productToCompetitor: {
          productId: $productId
          competitorId: $competitorId
          organizationId: $organizationId
          locked: $locked
        }
      }
    ) {
      clientMutationId
    }
  }
`

export const DELETE_PRODUCT_TO_COMPETITOR = gql`
  mutation DeleteProductToCompetitor($productId: Int!, $competitorId: Int!, $locked: Boolean!) {
    deleteProductToCompetitor(
      input: { productId: $productId, competitorId: $competitorId, locked: $locked }
    ) {
      clientMutationId
    }
  }
`
