import { useState } from 'react'
import useUpdateEffect from 'hooks/utility/useUpdateEffect'
import { useViewer } from 'context/viewerContext'
import { useGetOrgByDomain } from 'data/hooks'

export const useMatchedOrg = () => {
  const { viewer } = useViewer()
  const { organization, isLoading } = useGetOrgByDomain(viewer.email)
  const [matchedOrg, setMatchedOrg] = useState<any>()
  const [loading, setLoading] = useState(true)

  useUpdateEffect(() => {
    if (!organization || isLoading) return

    const usersOrganizations = viewer.organizations

    if (!usersOrganizations.some((org) => org.id === organization.id)) setMatchedOrg(matchedOrg)
    setLoading(false)
  }, [JSON.stringify(organization), isLoading])

  return { matchedOrg, loading }
}
