import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { ProductState } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  productId: number
  associationId: string
  type: string
  state: ProductState
}

export const useCreateProductListing = () => {
  const request = useGql()
  const { organization } = useOrg()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { associationId, productId, state, type } = variables
      await request(CREATE_PRODUCT_LISTING, {
        organizationId: organization?.id,
        associationId,
        productId,
        state,
        type,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listings', organization?.id])
        queryClient.invalidateQueries(['product-listing', organization?.id])
      },
    }
  )
}

const CREATE_PRODUCT_LISTING = gql`
  mutation CreateProductListing(
    $associationId: UUID!
    $organizationId: UUID!
    $productId: Int!
    $state: ProductState = DRAFT
    $type: String
  ) {
    createProductListing(
      input: {
        productListing: {
          associationId: $associationId
          organizationId: $organizationId
          productId: $productId
          state: $state
          type: $type
        }
      }
    ) {
      clientMutationId
    }
  }
`
