import styled from '@emotion/styled'
import { useWizardModal } from 'components/modals/WizardModal'
import ProductPlans from './ProductPlans'
import CompanyPlans from './CompanyPlans'

const SelectPlan = () => {
  const { data } = useWizardModal()

  const { listingType } = data

  return (
    <Root>
      {listingType === 'lawfirm' || listingType === 'company' ? <CompanyPlans /> : <ProductPlans />}
    </Root>
  )
}

const Root = styled.div``

export default SelectPlan
