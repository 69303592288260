import { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import Modules, {
  CompanyStack,
  CreateRfp,
  InviteTeam,
  VisitLegalTechMarket,
  MemberPortalSetup,
  HelpAndSupport,
  ListProducts,
} from './modules'
import { useLocation } from 'react-router'
import WelcomeModal from './WelcomeModal'
import { useRedirect } from 'hooks/useRedirect'
import { useMutateViewer } from 'data/viewer/useMutateViewer'
import { useViewer } from 'context/viewerContext'

const LawfirmGettingStarted = () => {
  const [isShowModal, setIsShowModal] = useState(true)
  const { isAdmin } = useViewer()
  const mutateViewer = useMutateViewer()

  const topSections = isAdmin ? [CompanyStack, ListProducts] : [ListProducts]

  const modules = [
    ...topSections,
    MemberPortalSetup,
    VisitLegalTechMarket,
    CreateRfp,
    InviteTeam,
    HelpAndSupport,
  ]
  const location = useLocation()
  const redirect = useRedirect()

  const locationState = location.state as any
  const showWelcome = locationState?.showWelcome || ''

  const stopShowingPage = async () => {
    mutateViewer.mutate(
      {
        appMetadata: {
          hideLawfirmGettingStarted: true,
        },
      },
      {
        onSuccess: () => redirect(),
      }
    )
  }

  const hideModal = useCallback(() => {
    setIsShowModal(false)
  }, [])

  return (
    <Wrapper>
      <Modules modules={modules} />
      <StopShowingPage onClick={stopShowingPage}>
        Stop showing the Getting Started page
      </StopShowingPage>
      {showWelcome && <WelcomeModal isShow={isShowModal} hide={hideModal} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: -32px;
`

const StopShowingPage = styled.a`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  font-size: 18px;
  text-align: center;
`

export default LawfirmGettingStarted
