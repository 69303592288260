import styled from '@emotion/styled'
import CurrentVendorStackshare from './CurrentVendorStackshare'
import FindVendorStackshare from './FindVendorStackshare'

const ListVendorStackshare = () => {
  return (
    <Root>
      <CurrentVendorStackshare />
      <FindVendorStackshare />
    </Root>
  )
}

const Root = styled.div``

export default ListVendorStackshare
