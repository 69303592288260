import { useHasOrganizationListings, useProductListings } from 'data/hooks'
import { ProductType } from 'generated/graphql'
import { createContext, ReactNode, useContext } from 'react'

type ListingStepperState = {
  orgListings?: Array<{
    __typename?: 'OrganizationListing'
    associationId: any
    association: { __typename?: 'Organization'; slug: string; name: string; id: any; details: any }
  }>
  productListings?: Array<{
    __typename?: 'ProductListing'
    id: number
    type: string
    association: { __typename?: 'Organization'; id: any; name: string; slug: string }
    product: {
      __typename?: 'Product'
      name: string
      slug: string
      type: ProductType
      id: number
      details: any
    }
  }>
}

const ListingStepperContext = createContext({} as ListingStepperState)

export const ListingStepperProvider = ({ children }: { children: ReactNode }) => {
  const { orgListings } = useHasOrganizationListings()
  const { productListings } = useProductListings()

  // check if company purchase has been made
  // if company purchase has been made then do not allow user to repurchase company listing
  // if company purchase has been made then allow for product + service listings to be FREE
  // check for product/service listings

  return (
    <ListingStepperContext.Provider value={{ orgListings, productListings }}>
      {children}
    </ListingStepperContext.Provider>
  )
}

export const useListingStepper = () => useContext(ListingStepperContext)
