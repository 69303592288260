import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import LoadingPng from 'assets/purple-logo.png'
import LogoutButton from 'components/theme/LogoutButton'

const LoadingPage = ({ withTopBar }: { withTopBar?: boolean }) => {
  return (
    <Root withTopBar={withTopBar}>
      {!withTopBar && <LogoutButton />}
      <LoadingImage src={LoadingPng} />
    </Root>
  )
}

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: 0;
  }
`

const Root = styled.div<{ withTopBar?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => (props.withTopBar ? 'calc(100vh - 155px)' : '100vh')};
`

const LoadingImage = styled.img`
  width: 94px;
  animation: ${fadeInOut} 2s infinite;
`

export default LoadingPage
