import styled from '@emotion/styled'
import { BoxWrapper, MainTitle } from 'components/theme'
import AssociationTechstackTable from './AssociationTechstackTable'
import AssociationFilters from './AssociationFilters'
import { AssociationStackshareProvider } from './associationStackshareContext'
import YourAppsPopup from './YourAppsPopup'

const AssociationMemberApps = () => {
  return (
    <AssociationStackshareProvider>
      <Root>
        <Title>
          Stack Share{' '}
          <YourAppsPopup
            text={'Peer-driven insights. A place to share and help other’s make stack decisions.'}
          />
        </Title>
        <BoxWrapper>
          <AssociationFilters />
          <AssociationTechstackTable />
        </BoxWrapper>
      </Root>
    </AssociationStackshareProvider>
  )
}

const Root = styled.div`
  width: 100%;
  padding-bottom: 60px;
`

const Title = styled(MainTitle)`
  margin-bottom: 20px !important;
  font-size: 24px;
  letter-spacing: 0;
  display: flex;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto 20px !important;

  ${({ theme }) => theme.breakpoint.mobile} {
    text-align: center;
  }
`

export default AssociationMemberApps
