/** @jsxRuntime classic */
import React, { useState, useEffect } from 'react'
import FileUpload from 'components/fields/FileUpload'
import styled from '@emotion/styled'
import { Form } from 'semantic-ui-react'
import { Field } from 'formik'
import Label from 'components/theme/form/Label'
import { css } from '@emotion/react'

const ImageBox = ({ selectedImage, deleteImage }: any) => {
  if (typeof selectedImage === 'string') {
    return (
      <ImageContainer>
        <DeleteButton onClick={deleteImage} />
        <Logo src={selectedImage} alt="Logo" />
      </ImageContainer>
    )
  }
  if (typeof selectedImage === 'object' && selectedImage.length > 0) {
    return (
      <ImageContainer>
        <DeleteButton onClick={deleteImage} />
        <Logo src={URL.createObjectURL(selectedImage[0].file)} alt="Logo" />
      </ImageContainer>
    )
  }

  return null
}

function UploadSingleAsset({
  name,
  label = 'Upload Logo',
  required = false,
  selectedImage = [],
  fieldProps = {},
  fluidWidth = false,
  imageStyles = css``,
  styles = css``,
  children,
  helpContent = null,
}: any) {
  const [files, setFiles] = useState(selectedImage)
  let applySetFiles: any

  // There is an error when selected image is an empty string. This turns it into an array
  const mySelectedImage = selectedImage || []

  useEffect(() => {
    applySetFiles(files)
  }, [files, applySetFiles])

  const deleteImage = () => {
    setFiles([])
  }

  const acceptFiles = (form: any) => (acceptedFiles: any) => {
    form.setFieldValue(name, acceptedFiles, true)
  }

  const addFile = (file: any) => {
    setFiles([{ file }])
  }

  return (
    <>
      <Label helpContent={helpContent} required={required}>
        {label}
      </Label>
      {mySelectedImage && mySelectedImage.length !== 0 && (
        <ImageWrapper fluidWidth={fluidWidth} css={imageStyles}>
          <ImageBox selectedImage={mySelectedImage} deleteImage={deleteImage} />
        </ImageWrapper>
      )}
      <Field name={name}>
        {({ form }: any) => {
          const error = form.touched[name] && form.errors[name]
          applySetFiles = acceptFiles(form)

          return (
            <Form.Field error={!!error} {...fieldProps}>
              {!!mySelectedImage && mySelectedImage.length === 0 && (
                <FileUpload css={styles} fluidWidth={fluidWidth} addFile={addFile}>
                  {children}
                </FileUpload>
              )}
            </Form.Field>
          )
        }}
      </Field>
    </>
  )
}

const Logo = styled.img`
  max-width: 100%;
  max-height: 130px;
  width: 100vw;
`

const DeleteButton = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 1px;
  right: 1px;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  cursor: pointer;
  display: none;

  &:before,
  &:after {
    top: 50%;
    left: 50%;
    content: '';
    width: 14px;
    height: 3px;
    background: #333;
    position: absolute;
    border-radius: 10px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

const ImageWrapper = styled.div<{ fluidWidth: boolean }>`
  display: flex;
  padding: 10px;
  width: ${({ fluidWidth }) => (fluidWidth ? 'auto' : '150px')};
  height: 150px;
  border: 1px dashed rgba(34, 36, 38, 0.5);
  background: #fcfcfc;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
`

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    ${DeleteButton} {
      display: block;
    }
  }
`

export default React.memo(UploadSingleAsset)
