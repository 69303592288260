import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  testDriveId: number
  details: any
}
export const useUpdateTestDrive = () => {
  const request = useGql()
  return useMutation(async (variables: Variables) => {
    const { testDriveId, details } = variables
    await request(UPDATE_TEST_DRIVE, {
      id: testDriveId,
      details,
    })
  })
}

export const UPDATE_TEST_DRIVE = gql`
  mutation UpdateTestDrive($id: Int!, $details: JSON) {
    updateTestDrive(input: { patch: { details: $details }, id: $id }) {
      clientMutationId
    }
  }
`
