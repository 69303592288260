import { useEffect } from 'react'
import { useTrackingChartContext } from 'context/trackingChartContext'
import { useChartJs } from 'hooks/useChartJs'
import { interpolateColors, rgbToHex } from 'services/color'
import ChartCard from 'components/charts/ChartCard'
import { useLazyApi } from 'hooks/useApi'
import _ from 'lodash'

function CardClickChart() {
  const {
    startDate,
    endDate,
    currentLoadingQueue,
    setCurrentLoadingQueue,
    productToAnalyze: product,
  } = useTrackingChartContext()
  const { ctx, setChartData } = useChartJs({ type: 'bar', options })
  const [getCardClicks] = useLazyApi('tracking/amplitude/card-select')

  useEffect(() => {
    const init = async () => {
      const result = await getCardClicks({ startDate, endDate, id: product })

      const series = _.get(result, 'series[0]', [])
      const barCount = series.length || 1
      const colors =
        barCount > 1
          ? interpolateColors('rgb(101,220,196)', 'rgb(0,183,147)', barCount)
          : ['#2A5BCA']
      const hexColors = barCount > 1 ? colors.map(([r, g, b]) => rgbToHex(r, g, b)) : colors
      const datasets = [
        {
          data: series,
          borderWidth: 1,
          backgroundColor: hexColors,
        },
      ]

      setChartData({ datasets, labels: result.xValues })
      setCurrentLoadingQueue((prevVal: number) => prevVal + 1)
    }

    if (currentLoadingQueue === 4) {
      init()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentLoadingQueue])

  return <ChartCard title="Card Hits" ctx={ctx} />
}

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

export default CardClickChart
