import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import { useViewer } from 'context/viewerContext'
import AuthWrapper from './AuthWrapper'

import Signup from 'views/Auth/Signup'
import Logout from 'views/Auth/Logout'
import HomepageRedirectTemp from 'views/HomepageRedirectTemp'

import PersonalRoutes from './PersonalRoutes'
import VendorRoutes from './VendorRoutes'
import LawfirmRoutes from './LawfirmRoutes'
import AssociationRoutes from './AssociationRoutes'
import SetupRoutes from './SetupRoutes'
import InviteScreen from 'views/InviteScreen'
import SelectOrganization from 'views/SelectOrganization'
import AdminProfile from 'views/vendors/VendorProfile/AdminProfile'
import AccLogin from 'views/associations/logins/AccLogin'
import ClocLogin from 'views/associations/logins/cloc/Login'
import EltaLogin from 'views/associations/logins/elta/Login'
import InHouseConnectLogin from 'views/associations/logins/in-house-connect/Login'
import ArtificialLawyerLogin from 'views/associations/logins/artificial-lawyer/Login'
import CompanyRoutes from './CompanyRoutes'
import NoMatch from 'views/404'
import LoadingPage from 'components/global/LoadingPage'
import { useAuth0 } from '@auth0/auth0-react'
import VerifyEmail from 'components/global/VerifyEmail'
import BlockedEmail from 'components/global/BlockedEmail'
import SuperAdmin from 'views/superAdmin/SuperAdmin'

function TheoremRoutes() {
  const { viewerLoading } = useViewer()
  const { error } = useAuth0()
  const [searchParams] = useSearchParams()

  if (error) {
    const errorType = searchParams.get('error')
    const errorDescription = searchParams.get('error_description')

    if (errorType === 'unauthorized' && errorDescription === 'VERIFY_EMAIL') {
      return <VerifyEmail />
    }

    if (
      errorType === 'access_denied' &&
      (errorDescription === 'NO_PUBLIC_DOMAIN' ||
        errorDescription === 'SIGN_UP_UNAVAILABLE' ||
        errorDescription === 'VENDOR_USER_NETWORK')
    ) {
      return <BlockedEmail />
    }
  }

  // NOTE: This should be the ONLY loading screen for the entire app
  if (viewerLoading) return <LoadingPage />

  return (
    <Routes>
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Logout />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="acc-login/*" element={<AccLogin />} />
      <Route path="cloc-login/*" element={<ClocLogin />} />
      <Route path="elta-login/*" element={<EltaLogin />} />
      <Route path="in-house-connect-login/*" element={<InHouseConnectLogin />} />
      <Route path="artificial-lawyer-login/*" element={<ArtificialLawyerLogin />} />

      <Route element={<AuthWrapper />}>
        <Route path="/" element={<HomepageRedirectTemp />} />
        <Route path="/setup" element={<SetupRoutes />} />
        <Route path="/:orgType/:organizationId/edit-products" element={<AdminProfile />} />
        <Route path="/invite" element={<InviteScreen />} />
        <Route path="select-organization" element={<SelectOrganization />} />
        <Route path="vendor/:organizationId/*" element={<VendorRoutes />} />
        <Route path="lawfirm/:organizationId/*" element={<LawfirmRoutes />} />
        <Route path="company/:organizationId/*" element={<CompanyRoutes />} />
        <Route path="association/:organizationId/*" element={<AssociationRoutes />} />
        <Route path="/setup/*" element={<SetupRoutes />} />
        <Route path="/personal/*" element={<PersonalRoutes />} />
        <Route path="/super-admin/*" element={<SuperAdmin />} />
        <Route path="/cloc" element={<Navigate to="/" />} />
        <Route path="/acc" element={<Navigate to="/" />} />
      </Route>

      <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

/*
 * 
      <PrivateRoute exact wrapInSidebarTemplate path="/home" component={HomepageRedirectTemp} />

      <PrivateRoute path="/techstack/update" component={UpdateTechStack} />

      <PrivateRoute
        path="/:orgType/:organizationId/edit-products"
        component={AdminProfile}
        wrapInSidebarTemplate
      />

      <PrivateRoute path="/integrations/create" component={CreateIntegrations} />

      <PrivateRoute path="/select-organization" component={SelectOrganization} />


      <PrivateRoute wrapInSidebarTemplate path="/your-apps" component={YourApps} />

      <PrivateRoute path="/invite" component={InviteScreen} />

      <Route path="/personal">
        <PersonalRoutes />
      </Route>

      <Route path="/setup">
        <SetupRoutes />
      </Route>

      <UnauthorizedRoute path="/signup" component={Signup} />

      <PrivateRoute path="/login" component={Logout} />

      <PrivateRoute path="/logout" component={Logout} />

      <UnauthorizedRoute path="/join/:organizationId" component={Join} />

      <Route element={<NoMatch />} />
*/

export default TheoremRoutes
