import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { breakpoint } from 'services/theming'
import LogoImg from 'assets/logo.png'

function ComingSoonWidget({ title, subtitle, content, icon }: any) {
  return (
    <Box>
      <Top>
        <IconWrapper>{icon ? <BoxIcon name={icon} /> : <Logo src={LogoImg} />}</IconWrapper>
        <TopContent>
          <Title>{title}</Title>
          <Type>{subtitle}</Type>
        </TopContent>
      </Top>
      <Text>{content}</Text>
      <Bottom>
        <ComingSoon>Waitlist</ComingSoon>
      </Bottom>
    </Box>
  )
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  ${breakpoint.desktop} {
    margin-right: 20px;
  }
`

const BoxIcon = styled(Icon)`
  font-size: 60px;
  color: #4e4f5a;
`

const Box = styled.div`
  background: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  order: 3;
`

const TopContent = styled.div`
  font-size: 16px;
  line-height: 1.5;
`

const Title = styled.strong``

const Type = styled.div``

const Top = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
`

const ComingSoon = styled.div`
  color: #555;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
`

const Logo = styled.img`
  width: 100%;
  max-width: 110px;
`

const Text = styled.p`
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  width: 90%;
  max-width: 350px;
`

export default ComingSoonWidget
