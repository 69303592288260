import React from 'react'
import styled from '@emotion/styled'
import eltaBlack from 'assets/networks/elta/elta-black.png'
import { Link } from 'react-router-dom'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useViewer } from 'context/viewerContext'
import { useEnterpriseListingCheckout } from '../useEnterpriseListingCheckout'

// const networkPriceIds = [
//   {
//     network: 'cloc',
//     priceIds: [
//       {
//         name: 'essential',
//         production: 'price_1KeQl2CldCjodC13wMzwDACb',
//         development: 'price_1KjrmCHIw3FF5Jrc2CWeynkm',
//       },
//       {
//         name: 'premium',
//         production: 'price_1KeQmDCldCjodC13DePzyHad',
//         development: 'price_1KjrpbHIw3FF5JrcDx0lnY5j',
//       },
//       {
//         name: 'enterprise',
//         production: 'price_1L5YqMCldCjodC13FXwF40GA',
//         development: 'price_1L5YFiHIw3FF5JrcjqGZpMPr',
//       },
//     ],
//   },
// ]

const isLocalDev = process.env.NODE_ENV === 'development'

const ENTERPRISE_PRICE_ID = !isLocalDev
  ? 'price_1Mboh5DVZW692Fnq7HobnMxP' // prod
  : 'price_1McAgPDVZW692FnqCytvMpuw' // dev

// const ASSOCIATION_ID = 'd3691636-3f0b-4ac4-9c2a-8bc61dee5ed5'
// const ACCOUNT_ID = isUsingProductionServer ? 'acct_1LmhKjEPUy7BhonO' : 'acct_1LmhKjEPUy7BhonO' // TheoremLTS test account

const EltaCompanyPlans = () => {
  const baseOrgUrl = useBaseOrgUrl()
  const enterpriseListingCheckout = useEnterpriseListingCheckout()
  const { paymentStatus } = useViewer()

  const isActiveTheorem = paymentStatus === 'ACTIVE'

  const onEnterpriseClick = async () => {
    await enterpriseListingCheckout({ priceId: ENTERPRISE_PRICE_ID })
  }

  return (
    <Root>
      <Container>
        <MainTitle>Select a Listing Plan</MainTitle>
        {isActiveTheorem ? (
          <ActiveTheoremDiscount>
            Congrats! You still Save 15% with your PRO membership.
          </ActiveTheoremDiscount>
        ) : (
          <TheoremProDiscount>
            Or, <Link to={`${baseOrgUrl}/upgrade`}>Save 15% with Theorem PRO</Link>
          </TheoremProDiscount>
        )}
        <Boxes>
          <Column>
            <Box onClick={onEnterpriseClick}>
              <Logo src={eltaBlack} />
              <Title>Enterprise</Title>
              <List>
                <Item>Everything in Premium, Plus;</Item>
                <Item>Unlimited listings for all your products and services</Item>
                <Item>Top-of-Search Placement</Item>
                <Item>Featured Home Page Placement</Item>
                <Item>Top of Category Results</Item>
                <Item>Priority support</Item>
                <Item>White glove onboarding &amp; profile setup</Item>
                <Item>Contact Us to find out more!</Item>
              </List>
              {isActiveTheorem ? (
                <>
                  <PriceWrapper>
                    <DiscountPrice>&euro;4,250</DiscountPrice>
                    <OldPrice>&euro;5,000</OldPrice>
                  </PriceWrapper>
                  <Discount>15% Off Applied!</Discount>
                </>
              ) : (
                <PriceWrapper>
                  <Price>&euro;5,000</Price>
                </PriceWrapper>
              )}
            </Box>
          </Column>
        </Boxes>
      </Container>
    </Root>
  )
}

const Root = styled.div``

const Container = styled.div`
  max-width: 880px;
  margin: 0 auto;
`

const TheoremProDiscount = styled.p`
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 16px;
  color: #43ba1a;
  margin-top: 8px;

  a {
    color: #43ba1a;
    text-decoration: underline;

    &:hover {
      color: #333;
    }
  }
`

const ActiveTheoremDiscount = styled.div`
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 8px;
  font-size: 16px;
  color: #43ba1a;
`

const MainTitle = styled.h2`
  text-align: center;
  margin-bottom: 0px;
`

const Boxes = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -16px;
  margin-right: -16px;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: block;
  }
`

const Column = styled.div`
  display: flex;
  padding: 0 16px;
  width: 50%;

  ${({ theme }) => theme.breakpoint.mobile} {
    width: 100%;
    margin: 0 auto 24px;
    max-width: 300px;
  }
`

const Logo = styled.img`
  max-width: 80px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 20px;
  cursor: pointer;
  border: transparent 2px solid;
  transition: border 250ms;

  &:hover {
    border: #4cd0d1 2px solid;
  }
`

const Title = styled.h4`
  font-size: 20px;
  margin-top: 16px;
`

const List = styled.ul`
  padding-left: 16px;
  margin-bottom: 20px;
`

const Item = styled.li`
  font-size: 14px;
  margin-bottom: 4px;
  letter-spacing: 0.02rem;
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-bottom: 4px;
`

const Price = styled.div`
  font-size: 20px;
  font-weight: 600;
`

const DiscountPrice = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-right: 12px;
`

const OldPrice = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-decoration: line-through;
  color: grey;
`

const Discount = styled.div`
  color: #43ba1a;
  font-weight: 500;
`

export default EltaCompanyPlans
