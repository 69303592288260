import { InputHTMLAttributes } from 'react'
import styled from '@emotion/styled'
import { Icon } from '@theoremlts/theorem-design'

const Searchbar = (props: InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <Root>
      <Input placeholder="Search ..." {...props} />
      <SearchIcon name="search" />
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  position: relative;
`

const SearchIcon = styled(Icon)`
  color: #898897;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
`

const Input = styled.input`
  padding: 12px 12px 12px 32px;
  border-radius: 6px;
  font-size: 16px;
  box-shadow: none;
  border: 1px solid #c5c6cf;
  width: 100%;
  transition: border 200ms;

  &:hover {
    border: 1px solid #9d9eaf;
  }

  &:focus {
    border: 1px solid #9d9eaf;

    & + ${SearchIcon} {
      color: #555;
    }
  }
`

export default Searchbar
