import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'

interface Props {
  pages: {
    title: string
    link: string
    isOutLink?: boolean
  }[]
  isAlignRight?: boolean
  closeMobileMenu?: () => void
}
const SecondaryMenu = ({ pages, isAlignRight, closeMobileMenu }: Props) => {
  return (
    <Root isAlignRight={isAlignRight}>
      <Wrapper>
        <Container>
          {pages.map(({ title, link, isOutLink }, index) =>
            isOutLink ? (
              <AnchorItem key={index + title} href={link}>
                {title}
              </AnchorItem>
            ) : (
              <MenuItem key={index + title} to={link} onClick={closeMobileMenu}>
                {title}
              </MenuItem>
            )
          )}
        </Container>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div<{ isAlignRight?: boolean }>`
  display: none;
  position: absolute;
  top: ${({ theme }) => theme.topBarHeight};
  padding-top: 8px;
  ${({ isAlignRight }) => (isAlignRight ? 'right: 0' : 'left: 0')};
  min-width: 250px;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: block;
    position: static;
    padding-top: 0;
    padding-bottom: 8px;
    padding-left: 32px;
    border-top: 1px solid #dbdbdb;
  }
`

const anchorCss = ({ theme }: { theme: Theme }) => css`
  font-size: 14px;
  font-weight: 500;
  display: block;
  color: #585a5f;
  padding: 8px 20px;
  transition: 250ms background, 250ms color;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;

  &:hover {
    background: #eee;
    color: #222;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  ${theme.breakpoint.mobile} {
    padding-left: 8px;
  }
`

const MenuItem = styled(NavLink)`
  ${anchorCss}
`

const AnchorItem = styled.a`
  ${anchorCss}
`

const Wrapper = styled.div`
  min-width: 250px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid #d5d5d5;

  ${({ theme }) => theme.breakpoint.mobile} {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    min-width: none;
  }
`

const Container = styled.div`
  border-radius: 6px;
  overflow: hidden;

  ${({ theme }) => theme.breakpoint.mobile} {
    border-radius: 0;
  }
`

export default SecondaryMenu
