import styled from '@emotion/styled'
import { BoxWrapper, Button, Icon } from 'components/theme'
import { useRedirect } from 'hooks/useRedirect'
import _ from 'lodash'

const ServiceSuccess = () => {
  const redirect = useRedirect()
  return (
    <BoxWrapper>
      <Root>
        <CheckCircle>
          <CheckIcon name="check" />
        </CheckCircle>
        <Title>Success! Your Account's been updated</Title>
        <Wrapper>
          <Content>
            <strong>Next Steps:</strong> The next step is to make sure your listing details are up
            to date. Visit your product library to update your global product information or
            continue to the listing to add details specific to that product.
          </Content>
          <ButtonsWrapper>
            <ButtonFill onClick={() => redirect('products')}>Edit Product Library</ButtonFill>
            <Button onClick={() => redirect('listing-stepper')}>Continue to Listing</Button>
          </ButtonsWrapper>
        </Wrapper>
      </Root>
    </BoxWrapper>
  )
}

const Root = styled.div``

const buttonColor = ({ color, theme }: any) => color || _.get(theme, 'color.primaryBlue', '#498DE6')
const ButtonFill = styled(Button)`
  background: ${buttonColor};
  color: white;
  margin-right: 20px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 460px;
`

const Title = styled.h1`
  text-align: center;
`

const Content = styled.div``

const CheckCircle = styled.div`
  width: 70px;
  height: 70px;
  border: 5px solid #57d20a;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

const CheckIcon = styled(Icon)`
  color: #0ecc00;
  font-size: 40px;
`

export default ServiceSuccess
