import { useOrg } from 'data/organization/useOrg'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'
import { GqlGetAssocToOrgByOrgIdQuery } from 'generated/graphql'

export const useAssocToOrgByOrgId = () => {
  const request = useGql()
  const { organization } = useOrg()

  const { data, ...other } = useQuery(
    ['org', 'assocToOrgByOrgId'],
    async () => {
      const results = await request<GqlGetAssocToOrgByOrgIdQuery>(GET_ASSOC_TO_ORG, {
        organizationId: organization?.id,
      })

      return results.associationToOrganizations
    },
    {
      staleTime: Infinity,
    }
  )

  return { assocToOrg: data, ...other }
}

const GET_ASSOC_TO_ORG = gql`
  query GetAssocToOrgByOrgId($organizationId: UUID) {
    associationToOrganizations(condition: { organizationId: $organizationId }) {
      associationId
      association {
        id
        name
        slug
        details
      }
      status
      createdAt
      updatedAt
    }
  }
`
