import styled from '@emotion/styled'

interface Props {
  title: string
  content: string | React.ReactNode
  image: string
}
const LargeContentImage = ({ title, content, image }: Props) => {
  return (
    <Root>
      <Title>{title}</Title>
      <Row>
        <Content>{content}</Content>
        <ImageWrapper>
          <Image src={image} />
        </ImageWrapper>
      </Row>
    </Root>
  )
}

const Root = styled.div`
  padding: 60px 20px;
`

const Title = styled.h2`
  font-size: 32px;
  text-align: center;
  margin-bottom: 32px;
`

const Row = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
`

const Content = styled.div`
  width: 50%;

  ${({ theme }) => theme.breakpoint.mobile} {
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  width: 50%;
  text-align: center;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: none;
  }
`

const Image = styled.img`
  max-width: 450px;
`

export default LargeContentImage
