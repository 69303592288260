import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { GqlGetAllOrganizationsQuery } from 'generated/graphql'

export const useGetAllCompanies = ({
  offset = 0,
  first = 10,
  search = '',
  type,
}: {
  offset?: number
  first?: number
  search?: string
  type?: string
}) => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['companies', offset, first, type, search],
    async () => {
      const response = type
        ? await request<GqlGetAllOrganizationsQuery>(GET_ALL_COMPANIES_BY_TYPE, {
            offset,
            first,
            search,
            type,
          })
        : await request<GqlGetAllOrganizationsQuery>(GET_ALL_COMPANIES, {
            offset,
            first,
            search,
          })

      return {
        organizations: response.organizationsConnection.nodes,
        totalCount: response.organizationsConnection.totalCount,
      }
    },
    {
      staleTime: 1000 * 60 * 10,
    }
  )

  return { companies: data?.organizations, totalCount: data?.totalCount, ...other }
}

const GET_ALL_COMPANIES = gql`
  query GetAllOrganizations($offset: Int! = 0, $first: Int = 20, $search: String = "") {
    organizationsConnection(
      offset: $offset
      first: $first
      filter: { name: { includesInsensitive: $search } }
      orderBy: CREATED_AT_DESC
    ) {
      nodes {
        id
        name
        slug
        type
        details
        domain
        createdAt
      }
      totalCount
    }
  }
`

const GET_ALL_COMPANIES_BY_TYPE = gql`
  query GetAllOrganizationsByType(
    $offset: Int! = 0
    $first: Int = 20
    $search: String = ""
    $type: OrganizationType!
  ) {
    organizationsConnection(
      offset: $offset
      first: $first
      filter: { name: { includesInsensitive: $search }, type: { equalTo: $type } }
      orderBy: CREATED_AT_DESC
    ) {
      nodes {
        id
        name
        slug
        type
        details
        domain
        createdAt
      }
      totalCount
    }
  }
`
