import { useMutation } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { UserRole } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  organizationId: string
  role?: UserRole
  jobTitle?: number
}
export const useCreateUserToOrg = () => {
  const request = useGql()
  const { viewer } = useViewer()
  return useMutation(async (variables: Variables) => {
    const { organizationId, role = UserRole.User, jobTitle } = variables
    await request(CREATE_USER_TO_ORGANIZATION, {
      userId: viewer.id,
      organizationId,
      role,
      jobTitle,
    })
  })
}

export const CREATE_USER_TO_ORGANIZATION = gql`
  mutation CreateUserToOrganization(
    $userId: Int!
    $organizationId: UUID!
    $role: UserRole
    $jobTitle: Int
  ) {
    createUsersToOrganization(
      input: {
        usersToOrganization: {
          userId: $userId
          organizationId: $organizationId
          role: $role
          jobTitle: $jobTitle
        }
      }
    ) {
      organization {
        id
        type
      }
    }
  }
`
