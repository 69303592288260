import React from 'react'
import { FieldArray } from 'formik'
import Button from 'components/theme/Button'
import styled from '@emotion/styled'
import { TextInput, Label } from 'components/theme/form'
import { UnderlineTitle } from 'components/theme'
import { Dropdown } from 'semantic-ui-react'

function ResourcesField({ value, setFieldValue }: any) {
  return (
    <FieldArray
      name="resources"
      render={(arrayHelpers) => (
        <Root>
          <UnderlineTitle helpContent="Add resources such as links to API docs, awards or case studies">
            Resources
          </UnderlineTitle>
          {value && (
            <>
              {value.map((resource: any, index: number) => (
                <Resource key={`${JSON.stringify(resource)}`}>
                  <ResourceText>
                    <Label required>Type</Label>
                    <Dropdown
                      fluid
                      selection
                      value={resource.type}
                      onChange={(_e: any, { value }: any) =>
                        setFieldValue(`resources[${index}].type`, value)
                      }
                      options={RESOURCE_TYPE}
                      name="type"
                    />
                  </ResourceText>
                  <ResourceText>
                    <TextInput
                      label="Text"
                      placeholder="Resource Name"
                      name={`resources[${index}].text`}
                    />
                  </ResourceText>
                  <ResourceUrl>
                    <TextInput
                      label="URL"
                      placeholder="example.com"
                      name={`resources[${index}].url`}
                    />
                  </ResourceUrl>
                  <ResourceButton>
                    <Button type="button" onClick={() => arrayHelpers.remove(index)}>
                      -
                    </Button>
                  </ResourceButton>
                </Resource>
              ))}
            </>
          )}
          <AddButton
            type="button"
            onClick={() => arrayHelpers.push({ text: '', url: '', type: '' })}
          >
            + Add Resource
          </AddButton>
        </Root>
      )}
    />
  )
}

const RESOURCE_TYPE = [
  {
    value: 'LINK',
    text: 'Link',
  },
  {
    value: 'WHITE_PAPER',
    text: 'Whitepaper',
  },
  {
    value: 'PDF',
    text: 'PDF',
  },
  {
    value: 'IMAGE',
    text: 'Image',
  },
  {
    value: 'VIDEO',
    text: 'Video',
  },
]

const Root = styled.div``

const Resource = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 12px;
`

const ResourceText = styled.div`
  flex: 1 1 0px;
  margin-right: 15px;
`

const ResourceUrl = styled.div`
  flex: 1 1 0px;
  margin-right: 15px;
`

const ResourceButton = styled.div`
  flex: 0 0 auto;
  text-align: right;
`

const AddButton = styled(Button)`
  padding: 4px 16px;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  margin-top: 24px;
`

export default ResourcesField
