import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  productIds: number | number[]
  status: string
}

export const useUpdateProductStatus = () => {
  const request = useGql()
  return useMutation(async (variables: Variables) => {
    const { productIds, status } = variables
    if (typeof productIds === 'number') {
      await request(UPDATE_PRODUCT_STATUS, { productId: productIds, status })
    } else {
      await Promise.all(
        productIds.map(async (productId) => {
          await request(UPDATE_PRODUCT_STATUS, { productId, status })

          return
        })
      )
    }
  })
}

const UPDATE_PRODUCT_STATUS = gql`
  mutation UpdateProductStatus($productId: Int!, $status: ProductStatus) {
    updateProduct(input: { patch: { status: $status }, id: $productId }) {
      clientMutationId
    }
  }
`
