import { Route, Routes } from 'react-router-dom'
import ListVendorStackshare from './ListVendorStackshare'
import EditVendorStackshare from './EditVendorStackshare'

const VendorStackshare = () => {
  return (
    <Routes>
      <Route path="/list" element={<ListVendorStackshare />} />
      <Route path="/edit/:orgId" element={<EditVendorStackshare />} />
    </Routes>
  )
}

export default VendorStackshare
