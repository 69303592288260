import styled from '@emotion/styled'
import WizardModal, { WizardStep } from 'components/modals/WizardModal'
import ListingNetworks from '../ListingNetworks'
import ListingType from '../ListingType'
import AddListingItem from './AddListingItem'
import ProductAddedSuccess from './ProductAddedSuccess'
import SelectPlan from './SelectPlan'

const steps: WizardStep[] = [
  {
    title: 'Select Network',
    subtitle: 'Where you will be listing',
    component: <ListingNetworks />,
  },
  {
    title: 'Listing Type',
    subtitle: 'What you want to list',
    component: <ListingType />,
  },
  {
    title: 'Listing Details',
    subtitle: 'Add Listing Item',
    component: [<AddListingItem />, <ProductAddedSuccess />],
  },
  {
    title: 'Select Plan',
    subtitle: 'Available Plans',
    component: <SelectPlan />,
  },
]

const ListingWizard = ({ closeModal }: { closeModal: () => void }) => {
  const onClose = () => {
    closeModal()
  }

  return (
    <Root>
      <WizardModal steps={steps} onClose={onClose} />
    </Root>
  )
}

const Root = styled.div``

export default ListingWizard
