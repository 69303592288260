import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'
import { breakpoint } from 'services/theming'
import TermScoutProductsModal from './TermScoutProductsModal'

function TermScoutBox() {
  const [isShowModal, setIsShowModal] = useState(false)

  const toggleModal = () => {
    setIsShowModal((prevVal) => !prevVal)
  }

  return (
    <>
      <Box>
        <Top>
          <IconWrapper>
            <Logo src="https://res.cloudinary.com/dh1giwabb/image/upload/v1612299989/gfhk1i5mmworaw5av5gc.svg" />
          </IconWrapper>
          <TopContent>
            <Title>Contract Analyzer</Title>
          </TopContent>
        </Top>
        <Text>
          See how your contract stacks up against others in the market. Firm's are looking for
          vendors who can deliver under acceptable terms &amp; conditions.
        </Text>
        <Bottom>
          <CtaButton onClick={toggleModal}>PURCHASE</CtaButton>
        </Bottom>
      </Box>
      <TermScoutProductsModal isShowModal={isShowModal} toggleModal={toggleModal} />
    </>
  )
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  ${breakpoint.desktop} {
    margin-right: 20px;
  }
`

const Box = styled.div`
  background: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  order: 3;

  ${breakpoint.desktop} {
    order: 2;
  }
`

const TopContent = styled.div`
  font-size: 16px;
  line-height: 1.5;
`

const Title = styled.strong``

const Top = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
`

const Logo = styled.img`
  width: 100%;
  max-width: 110px;
`

const Text = styled.p`
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  width: 90%;
  max-width: 350px;
`

const CtaButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
`

export default TermScoutBox
