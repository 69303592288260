import { UserStatus } from 'generated/graphql'
import { Step } from 'interfaces'

import AssociationStack from './association/AssociationStack'
import AssociationUser from './association/AssociationUser'

export const assocUserStep: Step = {
  component: AssociationUser,
  userStatus: UserStatus.AssocUser,
  title: 'Your Basic Information',
  subtitle: 'You can update this information later',
  icon: 'user',
  iconTitle: 'Create User',
}

export const assocStackStep: Step = {
  component: AssociationStack,
  userStatus: UserStatus.AssocStack,
  title: 'Select Your Stack',
  subtitle: 'You can update this information later',
  icon: 'tools',
  iconTitle: 'Your Apps',
}
