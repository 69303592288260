import { useRef } from 'react'
import Features from './Features'
import PlanSelections from './PlanSelections'
import PlansHero from './PlansHero'

const LawfirmMarketing = () => {
  const ref = useRef<any>(null)

  const executeScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div>
      <PlansHero executeScroll={executeScroll} />
      <Features />
      <PlanSelections sectionRef={ref} />
    </div>
  )
}

export default LawfirmMarketing
