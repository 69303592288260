import styled from '@emotion/styled'
import React, { useState } from 'react'
import { BoxWrapper } from '.'

interface View {
  tabName: string | React.ReactNode
  component: React.ReactNode
}

interface Props {
  views: View[]
}

const BoxWrapperTabs: React.FC<Props> = ({ views }) => {
  const [selectedView, setSelectedView] = useState(0)

  const switchView = (index: number) => () => {
    setSelectedView(index)
  }

  return (
    <Root>
      <Tabs>
        {views.map(({ tabName }, index) => (
          <Tab
            key={`${tabName}--BoxWrapperTabs`}
            onClick={switchView(index)}
            isActive={selectedView === index}
          >
            {tabName}
          </Tab>
        ))}
      </Tabs>
      <BoxWrapper>{views[selectedView].component}</BoxWrapper>
    </Root>
  )
}

const Root = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const Tabs = styled.div`
  display: flex;
  padding: 4px 8px 0;
  overflow: hidden;
`

const Tab = styled.div<{ isActive: boolean }>`
  background: ${({ isActive }) =>
    isActive
      ? `linear-gradient(
      180deg,
      rgb(255 255 255) 0%,
      rgb(255 255 255) 85%,
      rgb(255 255 255) 100%
    )`
      : 'linear-gradient(180deg, rgb(255 255 255) 0%, rgb(251 251 251) 85%, rgb(239 239 239) 100%)'};
  ${({ isActive }) => (isActive ? 'color: #a333c8;' : '')}
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 4px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  cursor: pointer;
  box-shadow: 0 2px 7px rgb(0 0 0 / 20%);
  transition: background 300ms;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:hover {
    background: linear-gradient(
      180deg,
      rgb(255 255 255) 0%,
      rgb(255 255 255) 85%,
      rgb(255 255 255) 100%
    );
  }
`

export default BoxWrapperTabs
