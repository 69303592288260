import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const AuthSelect = ({ auth0OrgId, logo }: { auth0OrgId: string; logo: string }) => {
  const { loginWithRedirect } = useAuth0()

  return (
    <Root>
      <Logo src={logo} />
      <LoginButton onClick={() => loginWithRedirect({ organization: auth0OrgId })}>
        Login
      </LoginButton>
      <Link to={'signup'}>
        <SignupButton>Sign up</SignupButton>
      </Link>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`

const Logo = styled.img`
  max-width: 300px;
  margin-bottom: 48px;
`

const buttonStyles = css`
  color: white;
  text-align: center;
  min-width: 110px;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  letter-spacing: 1px;
  cursor: pointer;
`

const LoginButton = styled.div`
  ${buttonStyles}
  margin-bottom: 16px;
  background: ${({ theme }) => theme.color.primaryBlue};
`

const SignupButton = styled.div`
  ${buttonStyles}
  background: ${({ theme }) => theme.color.primaryPurple};
`

export default AuthSelect
