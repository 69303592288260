import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  terms: number[]
  story: number
}
export const useAddUserStoryTerms = () => {
  const response = useGql()

  return useMutation(async (variables: Variables) => {
    const { terms, story } = variables

    await response(ADD_TERMS, {
      terms,
      story,
    })
  })
}

const ADD_TERMS = gql`
  mutation addTermsToStory($terms: [Int]!, $story: Int) {
    addUserStoryTerms(input: { terms: $terms, story: $story }) {
      clientMutationId
    }
  }
`
