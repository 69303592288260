import { Route, Routes } from 'react-router'
import CheckoutSuccess from './CheckoutSuccess'
import StripeCheckout from './StripeCheckout'

const Checkout = () => {
  return (
    <Routes>
      <Route path="/" element={<StripeCheckout />} />
      <Route path="success" element={<CheckoutSuccess />} />
    </Routes>
  )
}

export default Checkout
