import React from 'react'
import { useOrg } from 'data/organization/useOrg'

const UserStoryInstructions: React.FC = () => {
  const { organization } = useOrg()
  return (
    <div>
      <h3>Instructions</h3>
      {organization?.type === 'LAWFIRM' ? (
        <p>
          User Stories help your team to learn about why you use the tools you do, and how you
          benefit from them.
        </p>
      ) : (
        <p>
          This editor will guide you through the process of preparing your product use cases for
          prospective client identification.
        </p>
      )}
      <p>
        As you complete each question, your User Story will appear on the right. We've provided a
        sample User Story for you here.
      </p>
    </div>
  )
}

export default UserStoryInstructions
