import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

type Props = {
  image?: string
  title: string
  Message: string | React.FC
  element?: React.ReactElement
  link?: { to: string; text: string }
  routerLink?: { to: string; text: string }
}

const ImageBodyLink: React.FC<Props> = ({ image, title, Message, link, routerLink, element }) => {
  return (
    <Module>
      {image && <Image src={image} alt={title} />}
      <Text>
        <Body>
          <H3>{title}</H3>
          <Content>{typeof Message === 'string' ? <>{Message}</> : <Message />}</Content>
        </Body>
        {!!element ? (
          <>{element}</>
        ) : !!routerLink ? (
          <StyledLink to={routerLink.to}>{routerLink.text}</StyledLink>
        ) : link ? (
          <StyledHref href={link.to} target="_blank" rel="noreferrer">
            {link.text}
          </StyledHref>
        ) : (
          <></>
        )}
      </Text>
    </Module>
  )
}

const H3 = styled.h3`
  margin-bottom: 8px !important;
`

const Module = styled.div`
  display: flex;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Content = styled.div`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 8px;
`

const Image = styled.img`
  width: 100px;
  height: 100px;
`

const Body = styled.div`
  margin: 16px 16px 8px;
`

const StyledHref = styled.a`
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  margin-left: 16px;
`

const StyledLink = styled(Link)`
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  margin-left: 16px;
`

export default ImageBodyLink
