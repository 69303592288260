import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  deletedTermIds: number[]
  createdTermIds: number[]
  organizationId?: string
}
export const useMutateTermsToOrg = () => {
  const request = useGql()
  const { organization } = useOrg()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { createdTermIds, deletedTermIds, organizationId } = variables

      console.log(organizationId)
      await request(CREATE_TERMS_TO_ORG, {
        termIds: createdTermIds,
        organizationId: organizationId || organization?.id,
      })
      await request(DELETE_TERMS_TO_ORG, {
        termIds: deletedTermIds,
        organizationId: organizationId || organization?.id,
      })

      return organizationId
    },
    {
      onSuccess: (organizationId) => {
        queryClient.invalidateQueries(['org'])
        queryClient.invalidateQueries(['companies'])
        queryClient.invalidateQueries(['company', organizationId])
      }, // TODO: figure out how to optimize this so that we don't target the entire viewer
    }
  )
}

const CREATE_TERMS_TO_ORG = gql`
  mutation CreateTermsToOrganization($termIds: [Int!]!, $organizationId: UUID!) {
    createTermsToOrganization(input: { termIds: $termIds, organizationId: $organizationId }) {
      clientMutationId
    }
  }
`

const DELETE_TERMS_TO_ORG = gql`
  mutation DeleteTermsToOrganization($termIds: [Int!]!, $organizationId: UUID!) {
    deleteTermsToOrganization(input: { termIds: $termIds, organizationId: $organizationId }) {
      clientMutationId
    }
  }
`
