import styled from '@emotion/styled'
import PurpleLogoPng from 'assets/purple-logo.png'
import { useLocation } from 'react-router'
import LineDividerSvg from 'assets/networks/line-divider.svg'

const EmailSent = ({ logo }: { logo: string }) => {
  const location = useLocation()

  const locationState = location.state as any

  const { email } = locationState

  return (
    <Root>
      <LogoConnect>
        <TheoremLogo src={PurpleLogoPng} />
        <LineImage src={LineDividerSvg} />
        <AccLogo src={logo} />
      </LogoConnect>
      <Title>Check your email!</Title>
      <Subtitle>
        We've emailed a special link to <strong>{email}</strong>. Click the link to confirm your
        address and get started.
      </Subtitle>
      <BottomText>
        Not receiving emails? Check your folders and make sure to add{' '}
        <strong>theoremlegal.com</strong> to your list of safe senders.
      </BottomText>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const LogoConnect = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const TheoremLogo = styled.img`
  max-height: 40px;
  width: auto;
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
`

const LineImage = styled.img`
  width: 50px;
`

const AccLogo = styled.img`
  position: absolute;
  height: 40px;
  width: auto;
  left: 70px;
  top: 50%;
  transform: translateY(-50%);
`

const Title = styled.h1`
  font-weight: bold;
`

const Subtitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  max-width: 600px;
  text-align: center;
  color: #3c494e;
`

const BottomText = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 1.4;
  max-width: 520px;
`

export default EmailSent
