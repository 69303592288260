import React from 'react'
import StepModal from 'components/modals/StepModal'
import GreetComponent from './GreetComponent'
import OrganizationSlide from './OrganizationSlide'
import { useOrg } from 'data/organization/useOrg'

interface Props {
  isShow: boolean
  hide: () => void
}

const WelcomeModal: React.FC<Props> = ({ isShow, hide }) => {
  const { organization } = useOrg()

  if (!organization) return null

  return (
    <StepModal
      steps={[
        {
          component: GreetComponent,
          title: `Welcome to ${organization.name}`,
          content: `${organization.name} is now on Theorem, your single portal for legal tech access. The world of legal tech awaits!`,
        },
        {
          component: OrganizationSlide,
        },
      ]}
      isShow={isShow}
      onSkip={hide}
      onFinish={hide}
    />
  )
}

export default WelcomeModal
