import HighlightBox from 'components/theme/HighlightBox'
import CsvTechstack, { GqlUpdate } from 'components/forms/CsvTechstack/CsvTechstack'
import { parseISO, isWithinInterval, addMonths } from 'date-fns'
import { BoxWrapper } from 'components/theme'
import { useLazyApi } from 'hooks/useApi'
import styled from '@emotion/styled'
import { useUpdateTechstack } from 'hooks/lawfirm/useUpdateTechstack'
import { useOrg } from 'data/organization/useOrg'
import { useQueryClient } from '@tanstack/react-query'

const TechstackManagement = () => {
  const [updateOrgTechstack] = useLazyApi('company/update-org-techstack')
  const { fullOrganizationTechstack, loading } = useUpdateTechstack()
  const queryClient = useQueryClient()
  const { organization } = useOrg()

  const onSubmit = async (techstackUpdates: GqlUpdate[]) => {
    try {
      await updateOrgTechstack({ techstackUpdates, organizationId: organization?.id })
      queryClient.invalidateQueries(['org', organization?.id, 'techstack'])
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const totalValue = () => {
    const cents = fullOrganizationTechstack.reduce((total, { techstack }) => {
      total += techstack.metadata.contractValue || 0
      return total
    }, 0)
    const total = cents / 100
    return total
  }

  const howManyRenewalDates = () =>
    fullOrganizationTechstack.filter(({ techstack }) => {
      const renewal = techstack.metadata.renewalDate
      if (!renewal) return false
      const today = new Date()
      return isWithinInterval(parseISO(renewal), { start: today, end: addMonths(today, 3) })
    }).length

  return (
    <BoxWrapper>
      <TopSection>
        <Title>Upload your company’s tools</Title>
        <Subtitle>your users can add these tools to their personal stacks</Subtitle>
        <BlueBox>
          <BlueBoxTitle>Admins | Compare your tech stack with others in your industry</BlueBoxTitle>
          <BlueBoxText>
            See how your Tech Stack compares with other organizations like yours. This feature will
            become available one we have enough data from our network.
          </BlueBoxText>
        </BlueBox>
      </TopSection>
      <Boxes>
        <HighlightBox
          label="Total Suppliers"
          count={fullOrganizationTechstack.length}
          width="25%"
          loading={loading}
        />
        <HighlightBox
          label="Total SaaS Spend"
          count={totalValue()}
          width="25%"
          loading={loading}
          currency
        />
        <HighlightBox label="Stack Overlap" count="n/a" width="25%" />
        <HighlightBox
          label="Upcoming Renewals"
          count={howManyRenewalDates()}
          width="25%"
          loading={loading}
        />
      </Boxes>
      <CsvTechstack onSubmit={onSubmit} isAdvancedView />
    </BoxWrapper>
  )
}

const Boxes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1000px;
  margin: 0 auto 20px;
`

const TopSection = styled.div`
  margin-bottom: 28px;
`

const Title = styled.h3`
  font-size: 28px;
  text-align: center;
  margin-bottom: 0;
`

const Subtitle = styled.h4`
  font-size: 18px;
  text-align: center;
  margin-top: 0;
  font-weight: 500;
`

const BlueBox = styled.div`
  background: #dfebff;
  border: 1px solid #9d9df9;
  border-radius: 4px;
  padding: 8px 16px;
`

const BlueBoxTitle = styled.h5`
  margin-bottom: 0;
`

const BlueBoxText = styled.p``

export default TechstackManagement
