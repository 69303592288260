import { useState, useEffect } from 'react'
import { newDeletedList } from 'services/helper'
import { useViewer } from 'context/viewerContext'
import { useMutateUsersToTechstacks } from 'data/usersToTechstack/hooks/useMutateUsersToTechstacks'
import { useCreateTechstack, useTechstack } from 'data/hooks'
import { OrgTechstackGql } from 'data/types'
import { TechstackAccess } from 'generated/graphql'

export type Access = 'USER' | 'ORGANIZATION' | 'REVIEW' | 'AUTHORIZED' | 'HIDE'
export type Stage =
  | 'INVESTIGATION'
  | 'UNDER_REVIEW'
  | 'PILOT'
  | 'CONTRACT_REVIEW'
  | 'PRACTICE_ROLLOUT'
  | 'REGION_ROLLOUT'
  | 'ACTIVE'

type OrgTechstackIds = {
  id: number
  productId: number
}

export type OrgTechstack = {
  techstack: {
    id: number
    metadata: {
      [key: string]: any
      contractValue?: number
      renewalDate?: string
    }
    access: TechstackAccess
  }
  product: {
    id: number
    name: string
    slug: string
    details: {
      [key: string]: any
      squareLogo: string
      logoUrl: string
    }
  }
}

interface TechstackMutationPayload {
  __typename?: 'Techstack'
  id: number
  productId: number
}
export const useUpdateTechstack = () => {
  const { viewer } = useViewer()
  const [canRedirect, setCanRedirect] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [organizationTechstack, setOrganizationTechstack] = useState<OrgTechstackIds[]>([])
  const [fullOrganizationTechstack, setFullOrganizationTechstack] = useState<OrgTechstack[]>([])
  const [loading, setLoading] = useState(true)
  const mutateUserToTechstack = useMutateUsersToTechstacks()
  const { techstack: orgTechstack, isLoading: orgTechstackLoading } = useTechstack()
  const createTechstack = useCreateTechstack()

  useEffect(() => {
    if (orgTechstackLoading) return

    const orgTechstackIds: OrgTechstackIds[] = parseOrgTechstackIds(orgTechstack)
    const fixedOrgTechstack = parseOrgTechstacks(orgTechstack)
    setOrganizationTechstack(orgTechstackIds)
    setFullOrganizationTechstack(fixedOrgTechstack)
    setLoading(false)
  }, [JSON.stringify(orgTechstack), orgTechstackLoading])

  const { techstack } = viewer
  const userProductIds = techstack.length > 0 ? techstack.map((product) => product.id) : []

  const onSubmit = async (selectedProductIds: number[]) => {
    setSubmitting(true)
    const [newProducts, deletedProducts] = newDeletedList(userProductIds, selectedProductIds)

    const newOrgTechstackProductIds = newProducts.filter(
      (id) => !organizationTechstack.some(({ productId }) => productId === id)
    )

    const newTechstack: TechstackMutationPayload[] = []
    if (newOrgTechstackProductIds.length > 0) {
      for (const productId of newOrgTechstackProductIds) {
        const techstack = await createTechstack.mutateAsync(productId)

        if (techstack) newTechstack.push(techstack)
      }
    }

    const matchedOrgTechstack = organizationTechstack.filter(({ productId }) =>
      newProducts.some((id) => productId === id)
    )

    const createdTechstackIds = setCreateTechstackIds({ newTechstack, matchedOrgTechstack })
    const deletedTechstackIds = setDeleteTechstackIds({ deletedProducts, techstack })

    await mutateUserToTechstack.mutateAsync({ deletedTechstackIds, createdTechstackIds })

    setCanRedirect(true)
    setSubmitting(false)
  }

  interface SetCreateTechstackIds {
    newTechstack: TechstackMutationPayload[]
    matchedOrgTechstack: OrgTechstackIds[]
  }
  const setCreateTechstackIds = ({ newTechstack, matchedOrgTechstack }: SetCreateTechstackIds) => {
    const userToOrgTechstack = [...newTechstack, ...matchedOrgTechstack]
    const createdTechstackIds = userToOrgTechstack.map(({ id }) => id)
    return createdTechstackIds
  }

  interface SetDeleteTechstackIds {
    deletedProducts: number[]
    techstack: { id: number; techstackId: number }[]
  }
  const setDeleteTechstackIds = ({ deletedProducts, techstack }: SetDeleteTechstackIds) => {
    const deletedTechstackIds = deletedProducts
      .map((productId: number) => {
        const matchedTechstack = techstack.find(({ id }) => id === productId)
        return matchedTechstack?.techstackId
      })
      .filter((techstackId: number | undefined): techstackId is number => techstackId !== undefined)

    return deletedTechstackIds
  }

  return {
    onSubmit,
    canRedirect,
    userProductIds,
    organizationTechstack,
    fullOrganizationTechstack,
    loading: loading || orgTechstackLoading || submitting,
  }
}

const parseOrgTechstackIds = (orgTechstacksGql?: OrgTechstackGql[]) => {
  const orgTechstackIds = orgTechstacksGql
    ? orgTechstacksGql
        .map(({ id, product }) => {
          if (!product) return
          return {
            id,
            productId: product.id,
          }
        })
        .filter(
          (orgTechstackIds): orgTechstackIds is OrgTechstackIds => orgTechstackIds !== undefined
        )
    : []

  return orgTechstackIds
}

const parseOrgTechstacks = (orgTechstacksGql?: OrgTechstackGql[]): OrgTechstack[] => {
  if (!orgTechstacksGql) return [] as OrgTechstack[]

  return orgTechstacksGql
    .map(({ id, product, metadata, access, stage, ownedBy, userByAddedBy }) => {
      if (!product) return
      return {
        techstack: { id, metadata, access, stage, ownedBy, seats: metadata.seats },
        addedBy: userByAddedBy,
        product: {
          id: product.id,
          name: product.name,
          slug: product.slug,
          details: product.details,
        },
      } as OrgTechstack
    })
    .filter(
      (techstack: OrgTechstack | undefined): techstack is OrgTechstack => techstack !== undefined
    )
}
