// dependencies
import React from 'react'
import styled from '@emotion/styled'
import { Form, Formik, FieldArray } from 'formik'
// components
import { Button } from 'components/theme'
import { DispatchFieldArrayInput } from '.'
import { FormField, Multiselect } from 'components/theme/form'
import { FullPageLoading } from 'components/PulsatingLoader'
import { DispatchInput } from '.'
import Label from 'components/theme/form/Label'
// definitions
import { ProvisionProps } from '.'
import { useTaxonomyDropdown } from 'hooks/useTaxonomyDropdown'
import { useAllProducts } from 'data/hooks'

const RfpProvisions: React.FC<ProvisionProps> = ({
  updateStepper,
  FormStepperLinks,
  updateProvision,
  removeProvision,
  addProvision,
  onSubmit,
  initialValues,
}) => {
  const { products, isLoading: productsLoading } = useAllProducts()
  const { options: deploymentOptions, isLoading: deploymentLoading } = useTaxonomyDropdown({
    taxonomyId: 7,
  })

  const getProductOptions = (products: any) => {
    return products.map((product: any) => ({
      key: `products-${product.id}`,
      text: product.name,
      value: { name: product.name, id: product.id },
    }))
  }

  const productOptions = getProductOptions(products)

  if (productsLoading || deploymentLoading) return <FullPageLoading />

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, isSubmitting }) => (
          <Form>
            <FormField>
              <Label children="Use Case/Notes" />
              <DispatchInput
                name="notes"
                updateStepper={updateStepper}
                placeholder="Please describe the use case and feel free to provide any additional info you think will be helpful"
              />
            </FormField>
            <FormField>
              <Multiselect
                name="deployments"
                label="Desired Deployment(s)"
                options={deploymentOptions}
                value={values.deployments}
                updateStepper={(value: string) => updateStepper('deployments', value)}
              />
            </FormField>
            <FormField>
              <Multiselect
                name="products"
                label="Any providers you'd like to hear from"
                options={productOptions}
                value={values.products}
                updateStepper={(value: string) => updateStepper('products', value)}
              />
            </FormField>
            <ProvisionsWrapper>
              <Label
                helpContent="Special or non-negotiable terms"
                children="Provisions & Special Requests"
              />
              <FieldArray
                name={`requiredProvisions`}
                render={(arrayHelpers) => (
                  <>
                    {values.requiredProvisions &&
                      values.requiredProvisions.map((provision, index) => (
                        <FieldItem key={`provision-${index}`}>
                          <InputWrapper>
                            <DispatchFieldArrayInput
                              index={index}
                              updateProvision={updateProvision}
                              placeholder="Required Provision"
                              name={`requiredProvisions[${index}]`}
                            />
                          </InputWrapper>
                          <ButtonWrapper>
                            <Button
                              type="button"
                              onClick={() => {
                                arrayHelpers.remove(index)
                                removeProvision(index)
                              }}
                            >
                              -
                            </Button>
                          </ButtonWrapper>
                        </FieldItem>
                      ))}
                    <AddButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.push('')
                        addProvision()
                      }}
                    >
                      + Add Provision
                    </AddButton>
                  </>
                )}
              />
            </ProvisionsWrapper>
            <FormStepperLinks isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </>
  )
}

const AddButton = styled.a`
  padding: 4px 16px;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  margin-top: 4px;
  cursor: pointer;
`

const FieldItem = styled.div`
  display: flex;
  padding: 0 0px 8px;
`

const InputWrapper = styled.div`
  flex: 1 1 0px;
  margin-right: 20px;
`

const ButtonWrapper = styled.div`
  flex: 0 0 auto;
`

const ProvisionsWrapper = styled.div`
  margin-bottom: 24px;
`

export default RfpProvisions
