import { useQuery } from '@tanstack/react-query'
import { useGql } from 'hooks/useGql'
import { GqlGetFeaturesQuery } from 'generated/graphql'
import { gql } from 'graphql-request'

export const useFeaturesDropdown = () => {
  const request = useGql()

  return useQuery(
    ['features-dropdown'],
    async () => {
      const data = await request<GqlGetFeaturesQuery>(GET_FEATURES)

      const featuresDropdown = getFeatureOptions(data)

      return featuresDropdown
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  )
}

const getFeatureOptions = (featuresData: GqlGetFeaturesQuery) => {
  const features = featuresData?.features || []
  return features.map((feature) => ({
    key: `feature-${feature.id}`,
    text: feature.name,
    value: feature.id,
  }))
}

export const GET_FEATURES = gql`
  query GetFeatures {
    features {
      id
      name
      nodeId
      slug
    }
  }
`
