import { useOrg } from 'data/organization/useOrg'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'
import { GqlGetAssocToOrgQuery } from 'generated/graphql'

interface Variables {
  associationId: string
  organizationId?: string
}

export const useGetAssocToOrg = ({ associationId, organizationId }: Variables) => {
  const request = useGql()
  const { organization } = useOrg()

  const { data, ...other } = useQuery(
    ['org', 'assocToOrg'],
    async () => {
      const result = await request<GqlGetAssocToOrgQuery>(GET_ASSOC_TO_ORG, {
        organizationId: organizationId || organization?.id,
        associationId,
      })

      return result?.associationToOrganizations
    },
    {
      staleTime: Infinity,
      enabled: !!associationId,
    }
  )

  return { assocToOrg: data, ...other }
}

const GET_ASSOC_TO_ORG = gql`
  query GetAssocToOrg($associationId: UUID, $organizationId: UUID) {
    associationToOrganizations(
      condition: { associationId: $associationId, organizationId: $organizationId }
    ) {
      associationId
      association {
        id
        name
        slug
        details
      }
      status
      createdAt
      updatedAt
    }
  }
`
