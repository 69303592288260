import { useState } from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/theme'
import noProductsSvg from 'assets/no-products.svg'
import ProductTypeModal from 'components/modals/ProductTypeModal'

const NoProducts = () => {
  const [showModal, setShowModal] = useState(false)

  const createProduct = async () => {
    setShowModal(true)
  }

  return (
    <Root>
      <Row>
        <Left>
          <h2>Create your first product!</h2>
          <Content>
            This is where you can create and edit your product library to be paired with listings,
            deals and offers.
          </Content>
          <MoreInfo>
            Want to learn more?{' '}
            <a
              href="https://help.theoremlegal.com/kb/en/account-setup-287026/vendor-accounts"
              target="_blank"
            >
              Read this article
            </a>
          </MoreInfo>
        </Left>
        <ImageWrapper>
          <Image src={noProductsSvg} />
        </ImageWrapper>
      </Row>
      <Button onClick={createProduct}>Create Product</Button>
      {showModal && (
        <ProductTypeModal toggleModal={() => setShowModal((prevVal: boolean) => !prevVal)} />
      )}
    </Root>
  )
}

const Root = styled.div`
  text-align: center;
`

const Row = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  margin-bottom: 20px;
`

const Content = styled.p`
  font-size: 16px;
`

const MoreInfo = styled.p`
  font-weight: 500;
`

const Left = styled.div`
  width: 60%;
`

const Image = styled.img`
  max-width: 130px;
  width: 100%;
`

const ImageWrapper = styled.div`
  width: 40%;
  text-align: center;
`

export default NoProducts
