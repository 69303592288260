import React from 'react'
import styled from '@emotion/styled'
import TakeOff from './take-off.svg'
import { Icon } from 'components/theme'

import { Link } from 'react-router-dom'
import { useOrg } from 'data/organization/useOrg'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const SetUpComplete: React.FC = () => {
  const { vendor } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()

  const selectedProduct = vendor?.products[0]

  return (
    <Container>
      <Title>You're officially enrolled in our Seller Program!</Title>
      <Subtitle>Now follow the steps below</Subtitle>
      <Image src={TakeOff} />
      <CtaBox>
        <CtaTitle>Next Steps</CtaTitle>
        <InstructionsWrapper>
          <Instructions>
            <CircleIcon name="arrow-circle-right" />
            <Text>
              Go to the{' '}
              <StripeLink href="https://dashboard.stripe.com/" target="_blank">
                stripe dashboard
              </StripeLink>{' '}
              and add your products.
            </Text>
          </Instructions>
          <Instructions>
            <CircleIcon name="arrow-circle-right" />
            <Text>
              Input the Stripe API ID from the created product to each of the corresponding Plans
              under the{' '}
              {selectedProduct ? (
                <SalesLink to={`${baseOrgUrl}/product/settings/${selectedProduct?.id}/sales`}>
                  sales tab
                </SalesLink>
              ) : (
                <b>sales tab</b>
              )}{' '}
              in your Theorem product editor.
            </Text>
          </Instructions>
          <Instructions>
            <CircleIcon name="arrow-circle-right" />
            <Text>
              Change the <i>action link</i> to <strong>Checkout</strong>.
            </Text>
          </Instructions>
        </InstructionsWrapper>
      </CtaBox>
      <BottomText>
        For more information or help <a>Contact Us</a>
      </BottomText>
    </Container>
  )
}

const StripeLink = styled.a`
  font-weight: 500;
`

const SalesLink = styled(Link)`
  font-weight: 500;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 20px;
`

const Subtitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #333;
`

const Image = styled.img`
  width: 100%;
  max-width: 350px;
`

const CtaBox = styled.div`
  border: 1px solid green;
  border-radius: 6px;
  padding: 20px;
  background: #24ab1417;
  margin-top: 32px;
`

const CtaTitle = styled.h1`
  font-size: 24px;
  color: #61a75d;
`

const InstructionsWrapper = styled.div`
  max-width: 670px;
  margin: 0 auto;
`

const Instructions = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
  text-align: left;
  display: flex;
`

const CircleIcon = styled(Icon)`
  color: #61a75d;
  transform: translateY(3px);
`

const Text = styled.div`
  margin-left: 8px;
  line-height: 1.4;
`

const BottomText = styled.div`
  margin-top: 20px;
  font-size: 16px;
`

export default SetUpComplete
