import React from 'react'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import { useStories } from 'context/userStoriesContext'
import CardContainer from './CardContainer'
import SearchBar from './SearchBar'
import { Link } from 'react-router-dom'
import EmptyEditorList from './EmptyEditorList'

import { BoxWrapper, Button } from 'components/theme'
import { useOrg } from 'data/organization/useOrg'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

const EditorList: React.FC = () => {
  const { userStories, loading, productSpecificStories, searchUserStories } = useStories()
  const { organization } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()
  const { productId } = useParams<{ productId?: string }>()

  if (loading) return <></>

  const whichStories = () => (productId ? productSpecificStories(Number(productId)) : userStories)

  if (whichStories().length === 0) return <EmptyEditorList />

  return (
    <>
      {whichStories().length !== 0 && (
        <Header>
          <LeftSide>
            <BoxTitle>Your User Stories &nbsp;</BoxTitle>
            <Subtitle> (who, what, when, where, why use a product)</Subtitle>
            <Description>
              {organization?.type !== 'VENDOR'
                ? 'Post use cases to help others understand what tools to use and how you use them.'
                : 'This will help with the searchability of your products'}
            </Description>
          </LeftSide>
          <ActionContainer>
            <Link
              to={
                productId
                  ? `${baseOrgUrl}/product/settings/${productId}/user-stories/create`
                  : `${baseOrgUrl}/user-stories/create`
              }
            >
              <Button>Create a User Story</Button>
            </Link>
            <SearchBar onChange={searchUserStories} />
          </ActionContainer>
        </Header>
      )}
      <Cards>
        {whichStories().map((Story, i) => {
          const Card = (
            <CardContainer key={`story-${i}`} selectable={!!Story.product?.slug}>
              <Story.PrintElement withPills boldedKeywords />
            </CardContainer>
          )
          if (Story.product?.slug) {
            return (
              <StyledLink
                href={`https://ecosystem.theoremlegal.com/product/${Story.product.slug}`}
                key={`story-link-${i}`}
                target="_blank"
                rel="noreferrer"
              >
                {Card}
              </StyledLink>
            )
          } else {
            return Card
          }
        })}
      </Cards>
    </>
  )
}

export const WrappedEditorList: React.FC = () => (
  <BoxWrapper>
    <EditorList />
  </BoxWrapper>
)

const StyledLink = styled.a`
  width: auto;
  color: black;
  width: 45%;

  &:hover {
    color: black;
  }

  &::visited {
    color: black;
  }
`

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftSide = styled.div``

const BoxTitle = styled.div`
  display: flex;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #545454;
  white-space: nowrap;
`

const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  white-space: nowrap;
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  display: flex;
`

const Description = styled.div`
  font-size: 16px;
  margin: 16px 0 16px 0;
  color: #636363;
`

export default EditorList
