import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useUpdateShowOrgListing = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async ({
      organizationId,
      associationId,
      show,
    }: {
      organizationId: string
      associationId: string
      show: boolean
    }) => {
      await request(UPDATE_SHOW_LISTING, {
        organizationId,
        associationId,
        show,
      })

      return organizationId
    },
    {
      onSuccess: (organizationId) => queryClient.invalidateQueries(['listings', organizationId]),
    }
  )
}

const UPDATE_SHOW_LISTING = gql`
  mutation UpdateShowOrgListing($show: Boolean, $organizationId: UUID!, $associationId: UUID!) {
    updateOrganizationListing(
      input: {
        patch: { show: $show }
        organizationId: $organizationId
        associationId: $associationId
      }
    ) {
      clientMutationId
    }
  }
`
