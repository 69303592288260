import styled from '@emotion/styled'
import CompanyForm from 'components/forms/CompanyForm'
import { useOrg } from 'data/hooks'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useNavigate } from 'react-router'
import { useSetUserStatus } from 'hooks/graphql'
import { UserStatus } from 'generated/graphql'
import { useAppMetadata } from 'data/viewer/useAppMetadata'
import SetupHeader from 'components/global/SetupHeader'
import { useLazyApi } from 'hooks/useApi'

const CompanySetupForm = () => {
  const { organization } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()
  const navigate = useNavigate()
  const { setUserStatus } = useSetUserStatus()
  const { data: appMetadata } = useAppMetadata()
  const [welcomeEmail] = useLazyApi('company/welcome')

  const onSubmit = async () => {
    setUserStatus(UserStatus.Active)

    const pathname = appMetadata.assocOrg
      ? '/setup/network-success'
      : `${baseOrgUrl}/getting-started`

    await welcomeEmail({ networkName: appMetadata.assocOrg && appMetadata.assocOrg.name })

    navigate(pathname, { state: { showSuccess: true } })
  }

  const initialValues = {
    name: organization?.name || '',
    logo: organization?.details?.logo || [],
    website: organization?.details?.website || '',
    companySize: organization?.details?.companySize || '',
  }

  return (
    <Root>
      <SetupHeader />
      <FormContainer>
        <CompanyForm onComplete={onSubmit} initialValues={initialValues} />
      </FormContainer>
    </Root>
  )
}

const Root = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const FormContainer = styled.div`
  width: 100%;
  max-width: 760px;
  padding: 40px;
  margin: 0 auto;
`

export default CompanySetupForm
