import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Seller } from './types'
import { Icon } from 'components/theme'
import { fixCurrencyDisplay } from 'services/helper'
import { format } from 'date-fns'
import { Invoice, Invoices, StripeProducts } from './types'
import { useLazyApi } from 'hooks/useApi'
import Tooltip from 'components/theme/Tooltip'
import InvoiceTooltip from './InvoiceTooltip'

interface Props {
  seller: Seller
  removeSeller: () => void
}

const SellerDetails: React.FC<Props> = ({ seller, removeSeller }) => {
  const [getProductsByProductIds] = useLazyApi('stripe/connect/get-products-by-product-ids')
  const [stripeProducts, setStripeProducts] = useState<StripeProducts>([])
  const [loading, setLoading] = useState(true)
  const { invoices, accountId } = seller

  useEffect(() => {
    ;(async () => {
      const productIds = getProductIds(invoices)
      const products: StripeProducts = await getProductsByProductIds({ productIds, accountId })

      setStripeProducts(products)
      setLoading(false)
    })()
  }, [])

  return (
    <Root>
      <BackButton onClick={removeSeller}>
        <BackIcon name="angle-left" /> Back
      </BackButton>
      <Title>Invoices from {seller.organization.name}</Title>
      <InvoicesList>
        {loading && (
          <LoadingWrapper>
            <Loader name="spinner" spin pulse />
          </LoadingWrapper>
        )}
        {!loading &&
          invoices.data.map((invoice: any) => (
            <InvoiceBlock key={invoice.id}>
              <div>
                <DateTitle>{format(invoice.period_start * 1000, 'MMMM yyyy')}</DateTitle>
                <ProductNames>{getProductNames(invoice, stripeProducts)}</ProductNames>
              </div>
              <PriceTitle>{fixCurrencyDisplay(invoice.total)}</PriceTitle>
              <Tooltip
                placement="bottom-end"
                trigger="click"
                tooltip={
                  <InvoiceTooltip pdf={invoice.invoice_pdf} url={invoice.hosted_invoice_url} />
                }
              >
                <MenuButton>
                  <MenuIcon name="ellipsis-v" />
                </MenuButton>
              </Tooltip>
            </InvoiceBlock>
          ))}
      </InvoicesList>
    </Root>
  )
}

const getProductIds = (invoices: Invoices) => {
  const productIds = invoices.data.flatMap((invoice: any) => {
    return invoice.lines.data.map((lineItem: any) => lineItem.price?.product as string)
  })

  const uniqueProductIds = [...new Set(productIds)]
  return uniqueProductIds
}

const getProductNames = (invoice: Invoice, stripeProducts: StripeProducts) => {
  return invoice.lines.data.map((item: any) => {
    const stripeProduct = stripeProducts.find(
      (stripeProduct: any) => stripeProduct.id === item.price?.product
    )

    return stripeProduct?.name
  })
}

const Root = styled.div``

const InvoicesList = styled.div`
  overflow: hidden;
  border: 1px solid #cacaca;
  border-radius: 6px;
  max-width: 750px;
`

const InvoiceBlock = styled.div`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cacaca;

  &:last-of-type {
    border-bottom: none;
  }
`

const Title = styled.h2`
  font-size: 22px;
  margin-bottom: 20px;
`

const BackButton = styled.button`
  display: flex;
  align-items: center;
  color: #333;
  background: transparent;
  font-weight: 500;
  letter-spacing: 0.02rem;
  font-size: 16px;
  border: none;

  &:hover {
    color: #666;
  }
`

const BackIcon = styled(Icon)`
  font-size: 14px;
  margin-right: 8px;
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
`

const Loader = styled(Icon)`
  font-size: 20px;
  color: #888;
`

const DateTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.02rem;
`

const ProductNames = styled.div`
  font-style: italic;
  color: #454a54;
  padding-top: 2px;
`

const PriceTitle = styled.div`
  margin-left: auto;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.02rem;
  margin-right: 16px;
`

const MenuIcon = styled(Icon)`
  font-size: 16px;
  color: #4c4c52;
`

const MenuButton = styled.button`
  padding: 4px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #cacaca;
  box-shadow: none;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f3f3f3;
  }
`

export default SellerDetails
