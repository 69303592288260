import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation } from '@tanstack/react-query'

interface Variables {
  associationId: string
  organizationId: string
}
export const useDeleteAssocToOrg = () => {
  const request = useGql()

  return useMutation(async (variables: Variables) => {
    try {
      await request(DELETE_ASSOC_TO_ORG, variables)
    } catch (e) {
      console.log(e)
      debugger
      throw e
    }
  })
}

const DELETE_ASSOC_TO_ORG = gql`
  mutation DeleteAssociationToOrganization($associationId: UUID!, $organizationId: UUID!) {
    deleteAssociationToOrganization(
      input: { associationId: $associationId, organizationId: $organizationId }
    ) {
      clientMutationId
    }
  }
`
