import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const FormLabel = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 4px;
  letter-spacing: 0.1px;
`

export const InputStyle = css`
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 36px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
  width: 100%;
  border: none;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgb(50 151 211 / 30%), 0 1px 1px 0 rgb(0 0 0 / 7%),
      0 0 0 4px rgb(50 151 211 / 30%);
  }

  .MyCardElement {
    width: 100%;
  }
`

export const FieldGroup = css`
  padding-bottom: 16px;
`
