import BasicProductInfo from './forms/BasicProductInfo'
import Categories from './forms/Categories'
import Description from './forms/Description'
import ContactInfo from './forms/ContactInfo'
import Integrations from './forms/Integrations'
import Competitors from './forms/Competitors'
import Extra from './forms/Extra'
import Sales from './forms/Sales'
import Features from './forms/Features'
import Legal from './forms/Legal'
import { EditorList, StoryEditor } from 'components/UserStories'

export const editUseCaseRoutes = [
  {
    text: 'User Stories',
    url: 'user-stories/create',
    component: StoryEditor,
    exact: true,
  },
  {
    text: 'User Stories',
    url: 'user-stories/:storyId/edit',
    component: StoryEditor,
    exact: true,
  },
]

export const servicesRoutes = [
  {
    text: 'Basic Info',
    url: '',
    component: BasicProductInfo,
    exact: true,
  },
  {
    text: 'Description',
    url: 'description',
    component: Description,
  },
  {
    text: 'Search Terms',
    url: 'search-terms',
    component: Categories,
  },
  {
    text: 'Sub Services',
    url: 'features',
    component: Features,
  },
  {
    text: 'Media',
    url: 'media',
    component: Extra,
  },
  {
    text: 'Support',
    url: 'support',
    component: ContactInfo,
  },
  {
    text: 'Pricing',
    url: 'sales',
    component: Sales,
  },
  /*
  {
    text: 'Legal',
    url: 'legal',
    component: Legal,
  },
  */
  {
    text: 'Tech Partners',
    url: 'integrations',
    component: Integrations,
  },
  {
    text: 'Competitors',
    url: 'competitors',
    component: Competitors,
  },
  // {
  //   text: 'Use Cases',
  //   url: 'use-cases',
  //   component: UseCases,
  //   exact: true,
  // },
]

export default [
  {
    text: 'Basic Info',
    url: '',
    component: BasicProductInfo,
    exact: true,
  },
  {
    text: 'Description',
    url: 'description',
    component: Description,
  },
  {
    text: 'Search Terms',
    url: 'search-terms',
    component: Categories,
  },
  {
    text: 'Features',
    url: 'features',
    component: Features,
  },
  {
    text: 'Media',
    url: 'media',
    component: Extra,
  },
  {
    text: 'Support',
    url: 'support',
    component: ContactInfo,
  },
  {
    text: 'Pricing',
    url: 'sales',
    component: Sales,
  },
  {
    text: 'Legal',
    url: 'legal',
    component: Legal,
  },
  {
    text: 'Integrations',
    url: 'integrations',
    component: Integrations,
  },
  {
    text: 'Competitors',
    url: 'competitors',
    component: Competitors,
  },
  // {
  //   text: 'Use Cases',
  //   url: 'use-cases',
  //   component: UseCases,
  //   exact: true,
  // },
  {
    text: 'User Stories',
    url: 'user-stories',
    component: EditorList,
    exact: true,
  },
]

//  <PrivateRoute
//     wrapInSidebarTemplate
//     title="Create a new Use Case"
//
//   />

//   <PrivateRoute
//     wrapInSidebarTemplate
//     title="Edit a Use Case"
//     exact path={`${path}/use-cases/:useCaseId/edit`}
//     component={EditUseCase}
//   />
