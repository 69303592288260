import { Dropdown } from 'semantic-ui-react'
import { useField } from 'formik'
import Label from 'components/theme/form/Label'
// import { useStripeContext } from 'context/stripeContext'
import styled from '@emotion/styled'

function PriceTypeDropdown({ ...props }: any) {
  const [, meta, helpers] = useField(props)
  return (
    <Root>
      <Label>Term</Label>
      <Dropdown
        selection
        fluid
        name="priceType"
        onChange={(_e, { value }) => helpers.setValue(value)}
        value={meta.value}
        options={options}
      />
    </Root>
  )
}

const Root = styled.div`
  min-width: 180px;
  z-index: 1000;
  margin-bottom: 16px;
  position: relative;

  .menu {
    max-height: 160px !important;
  }
`

const options = [
  { key: 'monthly', text: 'Monthly', value: 'monthly' },
  { key: '3months', text: 'Every 3 months', value: '3months' },
  { key: '6months', text: 'Every 6 months', value: '6months' },
  { key: 'annuallyOnly', text: 'Annually', value: 'annuallyOnly' },
  { key: 'annually', text: 'Annual/Monthly', value: 'annually' },
  { key: 'dual', text: 'Dual', value: 'dual' },
  { key: 'tiered', text: 'Graduated', value: 'tiered' },
  { key: 'oneTime', text: 'One Time', value: 'oneTime' },
  { key: 'custom', text: 'Custom', value: 'custom' },
  { key: 'free', text: 'Free', value: 'free' },
]

/* const limitedOptions = [
  { key: 'free', text: 'Free', value: 'free' },
  { key: 'callForPricing', text: 'Contact Us', value: 'callForPricing' },
] */

export default PriceTypeDropdown
