import React, { useState } from 'react'
import styled from '@emotion/styled'
import PricePanel from './PricePanel'
import { breakpoint } from 'services/theming'
import { FullPageLoading } from 'components/PulsatingLoader'

// type Plan = {
//   priceId: string
//   name: string
//   tagline: string
//   features: string[]
//   button: string
//   color: string
//   amount: number
//   interval: string
//   tiersMode: string
//   trialPeriodDays?: number
//   hasDiscountCode?: boolean
//   discountCode?: string
//   userPrice: number | null
// }

// type PlanList = {
//   monthly: Plan
//   yearly: Plan
// }

type Props = {
  title: string
  subtitle: string
  planLists: any
  // planLists: PlanList
  onPriceClick: (priceId: string) => void
  hasIntervalSelector?: boolean
}

function SelectPlans({
  title = '',
  subtitle = '',
  planLists = [],
  onPriceClick,
  hasIntervalSelector = false,
}: Props) {
  const [isChecked, setIsChecked] = useState(false)

  const toggleCheck = () => setIsChecked((prevVal) => !prevVal)

  return (
    <>
      {planLists.length === 0 ? (
        <FullPageLoading />
      ) : (
        <>
          <Header>
            <Title>{title}</Title>
            <Text>{subtitle}</Text>
          </Header>
          {hasIntervalSelector && (
            <SwitchOuter>
              <SwitchText>Monthly</SwitchText>
              <Wrapper isChecked={isChecked}>
                <CheckboxInput type="checkbox" checked={isChecked} onChange={toggleCheck} />
                <SwitchToggle isChecked={isChecked} />
              </Wrapper>
              <SwitchText>Yearly</SwitchText>
            </SwitchOuter>
          )}
          <Plans>
            {planLists.length > 0 &&
              planLists.map((plans: any, index: number) => {
                if (isChecked) {
                  return <PricePanel key={index} plan={plans.yearly} onClick={onPriceClick} />
                } else {
                  return <PricePanel key={index} plan={plans.monthly} onClick={onPriceClick} />
                }
              })}
          </Plans>
        </>
      )}
    </>
  )
}

const Plans = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: auto;

  ${breakpoint.desktop} {
    margin-left: -20px;
    margin-right: -20px;
    flex-direction: column;
  }
`

const Header = styled.div`
  padding: 0 0 20px;
  text-align: center;

  ${breakpoint.mobile} {
    padding: 0;
  }
`

const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 16px;
  line-height: 1;
`

const Text = styled.div`
  font-size: 18px;
  line-height: 1.5;
`

const SwitchOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SwitchText = styled.div`
  font-weight: bold;
  padding: 0 12px;
  color: #50505d;
`

const SwitchToggle = styled.div<{ isChecked: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 400px;
  background: ${(props) => (props.isChecked ? '#4cd0d1' : '#b69bc7')};
  transform: translateX(${(props) => (props.isChecked ? '20px' : '0')});
  transition: background 350ms, transform 250ms;
`

const Wrapper = styled.label<{ isChecked: boolean }>`
  width: 48px;
  display: block;
  background: ${(props) => (props.isChecked ? '#ececec' : '#dadada')};
  cursor: pointer;
  border-radius: 400px;
  border: 2px solid #888;
  padding: 2px;
  transition: background 250ms;

  &:hover {
    background: #ccc;

    ${SwitchToggle} {
      background: ${(props) => (props.isChecked ? '#1e9d9e' : '#a273bf')};
    }
  }
`

const CheckboxInput = styled.input`
  display: none;
`

export default SelectPlans
