import React from 'react'
import styled from '@emotion/styled'

import { TermsOfService, PaymentSetup, SetupComplete } from './SetupSteps'
import { BoxWrapper } from 'components/theme'
import { useParams } from 'react-router-dom'

const TermsAndPayment:React.FC = () => {
    const { stepNumber } = useParams<{stepNumber: string}>()

    const ChildSwitch = () => {
      switch(stepNumber) {
        case '1':
          return <TermsOfService />
        case '2': 
          return <PaymentSetup />
        case '3':
          return <SetupComplete />
        default: 
          return <TermsOfService />
      }
    }

    return (
      <BoxWrapper>
        <TopLine>
          <StepTab selected={stepNumber === '1'}>
            <StepNumber selected={stepNumber === '1'}>1</StepNumber> agree to terms
          </StepTab>
          <StepTab selected={stepNumber === '2'}>
            <StepNumber selected={stepNumber === '2'}>2</StepNumber> set up payments
          </StepTab>
        </TopLine>
        <Container>
          {ChildSwitch()}
        </Container>
      </BoxWrapper>
    )
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const TopLine = styled.div`
  color: #b9b9b9;
  width: 32em;
  margin: -16px auto 32px;
  display: flex;
  justify-content: space-between;
`

const StepTab = styled.div<{ selected: boolean }>`
    justify-content: flex-start;
    align-items: center;
    display: flex;
    width: 80%;
    padding: 8px;
    marin-right: 16px;
    ${({ selected }) => !selected && 'opacity: .6;'}
    ${({ selected }) => selected && 'border-top: 1px solid #b9b9b9;'}
`

const StepNumber = styled.div<{ selected: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${({ selected }) => (selected ? 'white' : '#b9b9b9')};
  border: solid 2px #b9b9b9;
  color: ${({ selected }) => (selected ? '#b9b9b9' : 'white')};
  width: 32px;
  height: 32px;
  border-radius: 50px;
  font-size: 20px;
  line-height: 0;
  margin-right: 8px;
`

export default TermsAndPayment