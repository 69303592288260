import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useDeleteVendorStackshareLogo = () => {
  const request = useGql()

  return useMutation(async (id: number) => {
    await request(DELETE_VENDOR_STACKSHARE_LOGO, { id })
  })
}

const DELETE_VENDOR_STACKSHARE_LOGO = gql`
  mutation DeleteVendorStackshareLogo($id: Int!) {
    deleteVendorStackshareLogo(input: { id: $id }) {
      clientMutationId
    }
  }
`
