import React from 'react'
import styled from '@emotion/styled'
import { TextInput, FormField } from 'components/theme/form'
import { Button } from 'components/theme'
import UploadSingleAsset from 'components/fields/UploadSingleAsset'
import { Formik, Form } from 'formik'
import { extractPictureUrl } from 'services/image'
import * as yup from 'yup'
import TaxDropdown from './TaxDropdown'
import { Icon } from 'semantic-ui-react'
import { useOrg } from 'data/organization/useOrg'
import { useMutateTermsToOrg, useUpdateOrg } from 'data/hooks'
import { newDeletedList } from 'services/helper'

const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Required'),
  logo: yup.mixed(),
})

interface Values {
  name: string
  website: string
  logo: any
  practiceGroup: number[]
  roles: number[]
}

const defaultValues: Values = {
  name: '',
  website: '',
  logo: [],
  practiceGroup: [],
  roles: [],
}

interface Props {
  initialValues: Values
  onComplete: () => void
}

const AssociationForm: React.FC<Props> = ({ initialValues = defaultValues, onComplete }) => {
  const { association } = useOrg()
  const updateOrg = useUpdateOrg()
  const mutateTermsToOrg = useMutateTermsToOrg()

  const onSubmit = async ({ name, website, logo, practiceGroup, roles }: Values) => {
    const logoUrl = await extractPictureUrl(logo)

    if (!association) return

    await updateOrg.mutateAsync({
      name,
      details: {
        ...association.details,
        website,
        logo: logoUrl,
      },
    })

    const initialList = [...initialValues.roles, ...initialValues.practiceGroup]
    const currentList = [...roles, ...practiceGroup]

    const [createdTermIds, deletedTermIds] = newDeletedList(initialList, currentList)

    await mutateTermsToOrg.mutateAsync({ createdTermIds, deletedTermIds })

    onComplete()
  }

  return (
    <Root>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <ImageFieldWrapper>
              <FormField>
                <UploadSingleAsset label="Company Logo" name="logo" selectedImage={values.logo}>
                  <UploadContent>
                    <UploadIcon name="building" />
                    <span>Add Company Logo</span>
                  </UploadContent>
                </UploadSingleAsset>
              </FormField>
            </ImageFieldWrapper>

            <FormField>
              <TextInput label="Company Name" name="name" placeholder="My Company Inc." required />
            </FormField>
            <FormField>
              <TextInput label="Website" name="website" placeholder="https://www.example.com" />
            </FormField>
            <FormField>
              <TaxDropdown
                value={values.practiceGroup}
                multiple
                setFieldValue={setFieldValue}
                taxonomyId={13}
                name="practiceGroup"
                search
                label="Practice Groups"
              />
            </FormField>
            <FormField>
              <TaxDropdown
                value={values.roles}
                multiple
                setFieldValue={setFieldValue}
                taxonomyId={8}
                name="roles"
                search
                label="Typical Roles"
              />
            </FormField>
            <ButtonContainer>
              <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                Update
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div`
  max-width: 760px;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

const ImageFieldWrapper = styled.div`
  display: flex;

  > * {
    margin-right: 16px;
  }
`

const UploadContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
`

const UploadIcon = styled(Icon)`
  font-size: 50px !important;
  height: 40px !important;
  margin-top: 10px !important;
  margin-right: 0 !important;
  opacity: 0.7 !important;
  color: #4d5065;
`

export default AssociationForm
