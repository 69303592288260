import { useViewer } from 'context/viewerContext'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { newDeletedList } from 'services/helper'

interface Variables {
  productIds: number[]
  initialProductIds: number[]
}

export const useMutatePersonalTechstack = () => {
  const request = useGql()
  const { viewer } = useViewer()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { productIds, initialProductIds } = variables
      const [createProductIds, deletedProductIds] = newDeletedList(initialProductIds, productIds)

      if (createProductIds.length > 0) {
        await request(CREATE_PERSONAL_TECHSTACKS, {
          productIds: createProductIds,
          userId: viewer.id,
        })
      }

      if (deletedProductIds.length > 0) {
        await request(DELETE_PERSONAL_TECHSTACKS, {
          productIds: deletedProductIds,
          userId: viewer.id,
        })
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['viewer']),
    }
  )
}

const CREATE_PERSONAL_TECHSTACKS = gql`
  mutation CreatePersonalTechstacks($productIds: [Int!]!, $userId: Int!) {
    createPersonalTechstacks(input: { productIds: $productIds, userId: $userId }) {
      clientMutationId
    }
  }
`

const DELETE_PERSONAL_TECHSTACKS = gql`
  mutation DeletePersonalTechstacks($productIds: [Int!]!, $userId: Int!) {
    deletePersonalTechstacks(input: { productIds: $productIds, userId: $userId }) {
      clientMutationId
    }
  }
`
