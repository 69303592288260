import styled from '@emotion/styled'
import MobileLogoPng from 'assets/purple-logo.png'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/hooks'
import { OrganizationType } from 'generated/graphql'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import GlobalMenu from './GlobalMenu'
import MenuItem from './GlobalMenu/MenuItem'
import MenuIcons from './MenuIcons'
import TempButtons from './TempButtons'
import UserMenu from './UserMenu'

interface Props {
  title?: string
}
const TopBar = ({ title }: Props) => {
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false)
  const { organization } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()
  const marketplaceUrl = useMarketplaceUrl(true)
  const { isSuperAdmin } = useViewer()

  const toggleMobileMenu = () => {
    setIsShowMobileMenu((prevVal) => !prevVal)
  }

  const closeMobileMenu = () => {
    setIsShowMobileMenu(false)
  }

  return (
    <Root isShowMobileMenu={isShowMobileMenu}>
      {organization?.type === OrganizationType.Vendor ? (
        <ExternalLogoLink href={`${marketplaceUrl}returnTo=/getting-started`}>
          <MobileLogo src={MobileLogoPng} alt="Logo" />
        </ExternalLogoLink>
      ) : (
        <LogoLink to={`${baseOrgUrl}/getting-started`}>
          <MobileLogo src={MobileLogoPng} alt="Logo" />
        </LogoLink>
      )}
      <GlobalMenu
        title={title}
        isShowMobileMenu={isShowMobileMenu}
        closeMobileMenu={closeMobileMenu}
        toggleMobileMenu={toggleMobileMenu}
      />
      <Right>
        <TempButtons />
        <MenuIcons />
        <UserMenu />
      </Right>
    </Root>
  )
}

const Root = styled.div<{ isShowMobileMenu: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  background: white;
  padding-left: 16px;
  width: 100%;
  height: ${({ theme }) => theme.topBarHeight};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  ${({ isShowMobileMenu }) =>
    isShowMobileMenu &&
    `
    border-bottom: 1px solid #aaa;
  `}
`

const ExternalLogoLink = styled.a`
  display: inherit;
`

const LogoLink = styled(Link)`
  display: inherit;
`

const MobileLogo = styled.img`
  height: 28px;

  ${({ theme }) => theme.breakpoint.menuLogoSwitch} {
    display: block;
  }
`

const Right = styled.div`
  margin-left: auto;
  display: flex;
`

export default TopBar
