import styled from '@emotion/styled'
import UserForm from 'components/forms/UserForm'
import { newDeletedList } from 'services/helper'
import { useViewer } from 'context/viewerContext'
import { useMutateViewer, useMutateTermsToViewer } from 'data/hooks'
import { toast } from 'react-toastify'

function ProfileUserForm() {
  const { viewer } = useViewer()
  const mutateViewer = useMutateViewer()
  const mutateTermsToViewer = useMutateTermsToViewer()

  console.log(viewer.details)

  const defaultValues = {
    firstName: viewer.first,
    lastName: viewer.last,
    jobTitle: viewer.orgJobTitle,
    fileUrl: viewer.details.imageUrl,
    practiceGroup:
      viewer.practiceGroups.length > 0 ? viewer.practiceGroups.map(({ id }) => id) : [],
    industry: viewer.industry.length > 0 ? viewer.industry.map(({ id }) => id) : [],
    twitter: viewer.details.twitter || '',
    linkedin: viewer.details.linkedin || '',
  }

  const onSubmit = async ({
    firstName,
    lastName,
    fileUrl,
    jobTitle,
    twitter,
    linkedin,
    practiceGroup,
    industry,
  }: any) => {
    const [createdTermIds, deletedTermIds] = newDeletedList(
      [...defaultValues.practiceGroup, ...defaultValues.industry],
      [...practiceGroup, ...industry]
    )

    await mutateTermsToViewer.mutateAsync({ createdTermIds, deletedTermIds })
    await mutateViewer.mutateAsync({
      first: firstName,
      last: lastName,
      orgJobTitle: jobTitle,
      twitter,
      fileUrl,
      linkedin,
    })

    toast.success('Your profiles has been successfully updated')
  }

  return (
    <Root>
      <UserForm defaultValues={defaultValues} submitButtonText="Update User" onSubmit={onSubmit} />
    </Root>
  )
}

const Root = styled.div``

export default ProfileUserForm
