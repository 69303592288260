import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { TechstackProduct } from './types'
import { Avatar } from 'components/user'
import { Button, Icon } from 'components/theme'
import { Tooltip } from 'components/theme'
import { TechstackTooltip } from '.'
import ProductProfileModal from 'components/modals/ProductProfileModal'
import TestDriveModal from 'components/modals/TestDriveModal'
import { Product, AssociationTechstack, User } from 'interfaces'
import { isUsingStagingServer } from 'services/helper'
import { useModal } from 'components/Modal'
import { useNavigate } from 'react-router-dom'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

interface Props {
  product: TechstackProduct
}

type TestDrive = {
  product: Product
  stripeProductId: string
  priceId: string
  priceId2?: string
  freeTrialDays?: number
  features: string[]
  accountId?: string
  description: string
  isVendorToVendor: boolean
}

const SHOW_AVATAR_LIMIT = 3

const FirmAppTableRow: React.FC<Props> = ({ product }) => {
  const { showModal: showProductModal, toggle: toggleProductModal } = useModal()
  const [selectedTestDrive, setSelectedTestDrive] = useState<TestDrive | undefined>()
  const [isShowModal, setIsShowModal] = useState(false)
  const baseOrgUrl = useBaseOrgUrl()
  const navigate = useNavigate()

  const { name, details, access, techstackId, usersToTechstacks } = product
  const { squareLogoUrl, logoUrl } = details

  const hasTestDrive = product.testDrives.length > 0

  useEffect(() => {
    if (!hasTestDrive) return

    const environment = isUsingStagingServer ? 'test' : 'production'
    const testDrive = product.testDrives[0]

    if (!testDrive) return
    const { details, termToTestDrives } = testDrive

    const formattedTestDrive = {
      product: {
        ...product,
        bestFor:
          product.termToProducts
            .filter(({ term }) => term.taxonomyId === 16)
            .map(({ term }) => ({ id: term.id, slug: term.slug, name: term.name })) || [],
        practiceGroups:
          product?.termToProducts
            ?.filter(({ term }) => term.taxonomyId === 13)
            .map(({ term }) => ({ id: term.id, slug: term.slug, name: term.name })) || [],
      },
      stripeProductId: details?.stripe?.[environment]?.productId,
      priceId: details?.stripe?.[environment]?.priceId,
      priceId2: details?.stripe?.[environment]?.priceId2,
      freeTrialDays: details?.stripe?.freeTrialDays,
      accountId: details?.stripe?.accountId,
      description: details?.description,
      features: details?.features?.map(({ name }: any) => name) || [],
      isVendorToVendor: details?.isVendorToVendor,
      terms: getTerms(termToTestDrives),
    }

    setSelectedTestDrive(formattedTestDrive)
  }, [hasTestDrive])

  const allUsers: User[] = usersToTechstacks.map(({ user }: any) => ({
    id: user.id,
    details: user.details,
    email: user.email,
    name: `${user.first} ${user.last}`,
    first: user.first,
    last: user.last, // adding these so it can be closer to a User type for Avatars
  }))
  const users: User[] = allUsers.length > 0 ? [...allUsers].slice(0, SHOW_AVATAR_LIMIT) : []

  const extra =
    usersToTechstacks.length > SHOW_AVATAR_LIMIT ? usersToTechstacks.length - SHOW_AVATAR_LIMIT : 0

  const foundMainCategory = product.termToProducts.find(({ term }) => term.taxonomyId === 14)
  const mainCategory = foundMainCategory ? foundMainCategory.term.name : ''

  const associationTecstack: AssociationTechstack = {
    product,
    users: allUsers,
    practiceGroups: [],
    roles: [],
  }

  const onModalButtonClick = () => {
    navigate(`${baseOrgUrl}/test-drive/plan-details`, {
      state: {
        productId: selectedTestDrive?.product.id,
        stripeProductId: selectedTestDrive?.stripeProductId || '',
        priceId: selectedTestDrive?.priceId || '',
        freeTrialDays: selectedTestDrive?.freeTrialDays,
        accountId: selectedTestDrive?.accountId,
        isVendorToVendor: selectedTestDrive?.isVendorToVendor,
      },
    })
  }

  const showModal = () => {
    setIsShowModal(true)
  }

  const toggleModal = () => {
    setIsShowModal((prevIsShowModal) => !prevIsShowModal)
  }

  return (
    <Root>
      <Td>
        <AppDetails onClick={toggleProductModal}>
          <Logo src={squareLogoUrl || logoUrl || ''} />
          <AppName>{name}</AppName>

          <OutIcon name="external-link-alt" />
        </AppDetails>
      </Td>
      <Td>{mainCategory}</Td>
      <Td>
        <UserDetails>
          {users.map((user: any, index: number) => (
            <AvatarWrapper key={`${user.name}--${index}`}>
              <Avatar user={user} popUp />
            </AvatarWrapper>
          ))}
          {extra !== 0 && <ExtraUsers>+{extra}</ExtraUsers>}
        </UserDetails>
      </Td>
      <Td>
        <SmallButton onClick={toggleProductModal}>View</SmallButton>
        {hasTestDrive && <SmallButton onClick={showModal}>Test Drive</SmallButton>}
      </Td>
      <Td>
        <Tooltip
          placement="bottom-end"
          trigger="click"
          tooltip={
            <TechstackTooltip techstackId={techstackId} access={access} productName={name} />
          }
          interactive={true}
          delayHide={1000}
        >
          <OptionsButton>
            <Icon name="ellipsis-v" />
          </OptionsButton>
        </Tooltip>
      </Td>

      {showProductModal && (
        <ProductProfileModal
          showModal={showProductModal}
          toggleModal={toggleProductModal}
          productProfile={associationTecstack}
        />
      )}
      {isShowModal && (
        <TestDriveModal
          onClick={onModalButtonClick}
          isShowing={isShowModal}
          toggleModal={toggleModal}
          product={selectedTestDrive?.product}
          features={selectedTestDrive?.features}
          freeTrialDays={selectedTestDrive?.freeTrialDays}
        />
      )}
    </Root>
  )
}

type TermsData = {
  term: {
    id: number
    name: string
    slug: string
    taxonomyId: number
    taxonomy: {
      id: number
      name: string
      slug: string
    }
  }
}

const getTerms = (terms: TermsData[]) => {
  return terms.map(({ term }) => term)
}

const Root = styled.tr``

const Td = styled.td`
  padding: 16px 0;
  border-bottom: 1px solid #eee;
`

const AppDetails = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Logo = styled.img`
  width: 40px;
  margin-right: 16px;
`

const AppName = styled.div`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.02rem;
`

const UserDetails = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`

const SmallButton = styled(Button)`
  margin-left: 8px;
  padding: 4px 16px;
`

const AvatarWrapper = styled.div`
  margin-right: 8px;
  position: relative;
`

const OutIcon = styled(Icon)`
  margin-left: 12px;
  color: #498de6;
`

const OptionsButton = styled.button`
  padding: 4px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #cacaca;
  box-shadow: none;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin: 0;
  }

  &:hover {
    background: #f3f3f3;
  }
`

const ExtraUsers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  border: 1px solid #ccc;
  font-size: 12px;
  font-weight: 500;
`

export default FirmAppTableRow
