import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/hooks'
import { useRoutes } from 'react-router-dom'
import getSettingsRoutes from './settingsRoutes'

const Settings = () => {
  const { organization } = useOrg()
  const { viewer } = useViewer()
  const element = useRoutes(getSettingsRoutes(organization?.type, viewer.role))

  return element
}

export default Settings
