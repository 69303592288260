import EditUser from 'views/associations/Settings/EditUser'
import EditAssociation from 'views/associations/Settings/EditAssociation'
import EditBranding from 'views/associations/Settings/EditBranding'
import EditTeam from 'components/settings/Team'
import { MenuRoute } from './settingsRoutes'
import { UserRole } from 'generated/graphql'

const associationSettingRoutes: MenuRoute[] = [
  {
    index: true,
    text: 'User',
    element: <EditUser />,
  },
  {
    path: 'company',
    text: 'Company',
    element: <EditAssociation />,
    roleAccess: [UserRole.Admin, UserRole.Owner, UserRole.SuperAdmin],
  },
  {
    path: 'team',
    text: 'Members',
    element: <EditTeam />,
    roleAccess: [UserRole.Admin, UserRole.Owner, UserRole.SuperAdmin],
  },
  {
    path: 'branding',
    text: 'Branding',
    element: <EditBranding />,
    roleAccess: [UserRole.Admin, UserRole.Owner, UserRole.SuperAdmin],
  },
]

export default associationSettingRoutes
