import React from 'react'
import styled from '@emotion/styled'
import { UserProfile, getTaxonomyTerms } from '.'
import Avatar from '../Avatar'
import { Icon } from 'components/theme'
import { css } from '@emotion/react'

const Header: React.FC<{ user: UserProfile }> = ({ user }) => {
  const { first, last, email, terms, location, orgJobTitle } = user
  const printedPracticeGroups = getTaxonomyTerms(terms, 13)
    .map(({ name }) => name)
    .join(' | ')
  return (
    <Head>
      <Left>
        <Avatar user={user} large />
        <Center>
          <Name>
            {first} {last}
          </Name>
          {orgJobTitle && <JobTitle>{orgJobTitle}</JobTitle>}
          <ContactLinks>
            <SocialWrapper href={`mailto:${email}`}>
              <EmailIcon name="envelope" />
            </SocialWrapper>
            {user.details.linkedin && (
              <SocialWrapper href={user.details.linkedin} target="_blank" rel="noreferrer">
                <SocialIcon name="linkedin" />
              </SocialWrapper>
            )}
            {user.details.twitter && (
              <SocialWrapper
                href={`http://www.twitter.com/${user.details.twitter}`}
                target="_blank"
                rel="noreferrer"
              >
                <SocialIcon name="twitter" />
              </SocialWrapper>
            )}
          </ContactLinks>
        </Center>
      </Left>
      <Right>
        <div>{printedPracticeGroups}</div>
        {location && (
          <div>
            {location.city && `${location.city}, `}
            {location.state && `${location.state}, `}
            {location.country && location.country}
          </div>
        )}
      </Right>
    </Head>
  )
}

const ContactLinks = styled.div`
  display: flex;
  height: 14px;
  margin: 2px 0;
`

const SocialWrapper = styled.a`
  display: flex;
  align-items: center;
  padding-right: 16px;
`

const JobTitle = styled.div`
  font-size: 14px;
  color: #6f7782;
  margin-bottom: 4px;
  white-space: nowrap;
`

const iconStyles = css`
  font-size: 18px;
`

const EmailIcon = styled(Icon)`
  ${iconStyles}
`

const SocialIcon = styled(Icon)`
  ${iconStyles}
`

// const SocialIcon = styled(Icon)`
//   margin-right: 4px;
// `

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  background: #efefef;
  padding: 30px;
  border-bottom: 1px solid #ddd;
`

const Left = styled.div`
  display: flex;
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`

const Right = styled.div`
  padding-left: 20px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 80%;
`

const Name = styled.h3`
  margin-top: 4px;
  margin-bottom: 2px;
  white-space: nowrap;
`

export default Header
