import React from 'react'
import { Button, Icon } from 'components/theme'
import { useViewer } from 'context/viewerContext'
import styled from '@emotion/styled'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'

function UpgradeToPremiumBox() {
  const baseOrgUrl = useBaseOrgUrl()
  const { paymentStatus, accountId } = useViewer()

  const isUpgraded = paymentStatus === 'ACTIVE'

  let title = 'Upgrade to Premium'
  let content: JSX.Element | string | null = (
    <>
      Get premium features <br /> &amp; priority access
    </>
  )
  let button: string | null = 'Upgrade Now'
  let color = 'purple'
  let icon = 'crown'
  let link = `${baseOrgUrl}/upgrade`

  if (!accountId && isUpgraded) {
    // !accountId && upgraded
    title = 'Action Required'
    content = 'Complete your seller account'
    button = 'SETUP'
    color = 'red'
    icon = 'exclamation-triangle'
    link = `${baseOrgUrl}/upgrade/setup/1`
  } else if (accountId && isUpgraded) {
    title = 'Pro LVN'
    content = 'You are fully upgraded'
    button = null
    color = 'green'
    icon = 'check-circle'
    // accountId && upgraded
  }

  return (
    <Box>
      <TopGraphic color={color}>
        <IconWrapper>
          <BoxIcon name={icon} color={color} />
        </IconWrapper>
      </TopGraphic>
      <PremTitle color={color}>{title}</PremTitle>
      <PremContent>{content}</PremContent>
      <PremButtonWrapper>
        {color !== 'green' && (
          <PremButton component="link" to={link} color={color}>
            {button}
          </PremButton>
        )}
      </PremButtonWrapper>
    </Box>
  )
}

const colorPicker = ({ color }: { color: string }) =>
  color === 'red' ? '#ff1744' : color === 'purple' ? '#a333c8' : '#4caf50'

const Box = styled.div`
  background: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  order: 3;
`

const PremTitle = styled.div<{ color: string }>`
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: ${colorPicker};
  padding-bottom: 24px;
`

const PremContent = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  padding-bottom: 24px;
`

const PremButton = styled(Button)<{ color: string }>`
  background: ${colorPicker};
  border-color: ${colorPicker};
  color: white;
`

const PremButtonWrapper = styled.div`
  text-align: center;
`

const TopGraphic = styled.div<{ color: string }>`
  border-bottom: 2px solid ${colorPicker};
  max-width: 140px;
  margin: 4px auto 28px;
`

const BoxIcon = styled(Icon)<{ color: string }>`
  color: ${colorPicker};
  font-size: 20px;
`

const IconWrapper = styled.div`
  margin: 0 auto;
  background: white;
  width: 36px;
  transform: translateY(50%);
  text-align: center;
`

export default UpgradeToPremiumBox
