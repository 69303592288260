import React from 'react'
import ImageBodyLink from './ImageBodyLink'
import image from 'assets/marketing/social-growth-rafiki.png'

const CreateRfp = () => {
  return (
    <ImageBodyLink
      image={image}
      title="Help & Support"
      Message="Check out our help center and explore all of our features with video guidance."
      link={{
        to: 'https://help.theoremlegal.com/kb/en',
        text: 'Visit Help Center',
      }}
    />
  )
}

export default CreateRfp
