import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { newDeletedList } from 'services/helper'

interface Variables {
  terms: number[]
  initialValue: number[]
}

export const useMutatePriceTerms = (currentProductId: number) => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { terms, initialValue } = variables
      const [newTerms, deletedTerms] = newDeletedList(initialValue, terms)

      for (const termId of newTerms) {
        await request(CREATE_PRODUCT_TO_TERM, {
          termId,
          productId: currentProductId,
        })
      }

      for (const termId of deletedTerms) {
        await request(DELETE_PRODUCT_TO_TERM, {
          termId,
          productId: currentProductId,
        })
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

const CREATE_PRODUCT_TO_TERM = gql`
  mutation CreateProductToTerm($productId: Int!, $termId: Int!) {
    createTermToProduct(input: { termToProduct: { termId: $termId, productId: $productId } }) {
      clientMutationId
    }
  }
`

const DELETE_PRODUCT_TO_TERM = gql`
  mutation DeleteProductToTerm($productId: Int!, $termId: Int!) {
    deleteTermToProduct(input: { termId: $termId, productId: $productId }) {
      clientMutationId
    }
  }
`
