import { parseTerms } from 'data/taxonomy/parseTerms'
import { ProductState } from 'generated/graphql'
import { ViewerTechstack } from './viewerTypes'

interface GqlViewerTechstack {
  logins: number
  updatedAt: any
  techstack?: {
    organizationId: any
    id: number
    product?: {
      nodeId: string
      id: number
      name: string
      slug: string
      details: any
      state: ProductState
      show: boolean
      excerpt: string
      updatedAt: any
      createdAt: any
      testMode: boolean
      termToProducts: Array<{
        term?: {
          nodeId: string
          id: number
          name: string
          slug: string
          order?: number
          taxonomyId: number
        }
      }>
    }
  }
}

export const parseViewerTechstack = (
  userTechstackData: GqlViewerTechstack[],
  organizationId?: string
): ViewerTechstack[] => {
  if (!organizationId) return []

  return (
    userTechstackData
      .filter(({ techstack }) => techstack?.organizationId === organizationId)
      // .map(({ techstack }) => ({ ...techstack.product, techstackId: techstack.id }))
      .map(({ techstack, logins, updatedAt: lastLogin }) => {
        if (!techstack) return
        const { id: techstackId, product } = techstack

        if (!product) return
        const { id, name, details, slug, show, termToProducts } = product

        const terms = parseTerms(termToProducts)

        return {
          id,
          name,
          details,
          slug,
          show,
          terms,
          techstackId,
          logins,
          lastLogin,
        }
      })
      .filter(
        (viewerTechstack: ViewerTechstack | undefined): viewerTechstack is ViewerTechstack =>
          !!viewerTechstack
      )
  )
}
