import ReactDOM from 'react-dom'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'

export interface StepComponent {
  onNext: () => void
  onSkip: () => void
  onFinish: () => void
}

interface ModalStep {
  component: React.ElementType<StepComponent & any>
  [key: string]: any
}

interface Props {
  steps: ModalStep[]
  isShow: boolean
  boxStyles?: string
  onSkip: () => void
  onFinish: () => void
}

const StepModal: React.FC<Props> = ({ steps, isShow, onSkip, onFinish, boxStyles }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const onNext = () => {
    setCurrentStepIndex((prevStepIndex) => {
      if (prevStepIndex < steps.length - 1) return prevStepIndex + 1
      return prevStepIndex
    })
  }

  const { component, ...props } = steps[currentStepIndex]

  const Component = component

  return isShow
    ? ReactDOM.createPortal(
        <Root>
          <ContentWrapper onClick={(e) => e.stopPropagation()} boxStyles={boxStyles}>
            <Component onSkip={onSkip} onFinish={onFinish} onNext={onNext} {...props} />
          </ContentWrapper>
        </Root>,
        document.body
      )
    : null
}

const Root = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const ContentWrapper = styled.div<{ boxStyles?: string }>`
  position: relative;
  background: rgb(237, 227, 255);
  background: linear-gradient(180deg, rgba(237, 227, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
  border-radius: 5px;
  max-width: 100vw;
  max-height: 100vh;
  width: 765px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.3);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${breakpoint.mobile} {
    padding: 0 0 60px;
  }

  ${({ boxStyles }) => boxStyles && boxStyles};
`

export default StepModal
