import { Button } from 'components/theme'
import { Formik, Form } from 'formik'
import { FormField, TextInput } from 'components/theme/form'
import { useAssocHome, useUpdateAssocHome } from 'data/hooks'
import PulsatingLoader from 'components/PulsatingLoader'

interface Props {
  assocHomeId: number
  onSuccess: () => void
}

interface Values {
  title: string
  subtitle: string
}
const BasicForm = ({ assocHomeId, onSuccess }: Props) => {
  const { assocHome, isLoading } = useAssocHome(assocHomeId)
  const updateAssocHome = useUpdateAssocHome()

  const onSubmit = async (values: Values) => {
    await updateAssocHome.mutateAsync({ id: assocHomeId, details: values })
    onSuccess()
  }

  if (isLoading) return <PulsatingLoader />

  return (
    <Formik initialValues={assocHome?.details} onSubmit={onSubmit}>
      {() => (
        <Form>
          <FormField>
            <TextInput label="Title" name="title" />
          </FormField>
          <FormField>
            <TextInput label="Subtitle" name="subtitle" />
          </FormField>

          <Button type="submit">Update</Button>
        </Form>
      )}
    </Formik>
  )
}

export default BasicForm
