import styled from '@emotion/styled'
import { Formik, Form } from 'formik'
import { Button } from 'components/theme'
import { FieldRow, TextInput, Radio, TextArea, Select } from 'components/theme/form'
import * as yup from 'yup'
import { useWizardContext } from '../prevalentContext'
import { useOrg } from 'data/organization/useOrg'
import PulsatingLoader from 'components/PulsatingLoader'

const validationSchema = yup.object().shape({
  productName: yup.array().required('Required'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  companyName: yup.string().required('Required'),
  title: yup.string().required('Required'),
  phone: yup.string().required('Required'),
})

function Second() {
  const { nextStep, getStepData, updateWizardData, loading } = useWizardContext()
  const step = getStepData()
  const { vendor } = useOrg()

  const productOptions = vendor?.products.map((product) => ({
    value: product.id,
    label: product.name,
  }))

  const onSuccess = (values: any) => {
    updateWizardData(values)
    nextStep()
  }

  return (
    <Root>
      {loading ? (
        <PulsatingLoader />
      ) : (
        <Formik
          initialValues={step.formData}
          validationSchema={validationSchema}
          onSubmit={onSuccess}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <FieldRow>
                <Select
                  label="Select Product"
                  name="productName"
                  options={productOptions}
                  required
                />
              </FieldRow>
              <FieldRow>
                <TextInput name="firstName" label="First Name" required />
              </FieldRow>
              <FieldRow>
                <TextInput name="lastName" label="Last Name" required />
              </FieldRow>
              <FieldRow>
                <TextInput name="companyName" label="Company Name" required />
              </FieldRow>
              <FieldRow>
                <TextInput name="title" label="Title" required />
              </FieldRow>
              <FieldRow>
                <TextInput name="phone" label="Business Phone" required />
              </FieldRow>
              <FieldRow>
                <RadioText>Do you have a current TPRM project?</RadioText>
                <RadiosWrapper>
                  <RadioWrapper>
                    <Radio name="currentTprm" value="yes" label="yes" />
                  </RadioWrapper>
                  <RadioWrapper>
                    <Radio name="currentTprm" value="no" label="no" />
                  </RadioWrapper>
                </RadiosWrapper>
              </FieldRow>
              <FieldRow>
                <RadioText>Are you using any other TPRM tools?</RadioText>
                <RadiosWrapper>
                  <RadioWrapper>
                    <Radio name="usingTprm" value="yes" label="yes" />
                  </RadioWrapper>
                  <RadioWrapper>
                    <Radio name="usingTprm" value="no" label="no" />
                  </RadioWrapper>
                </RadiosWrapper>
              </FieldRow>
              {values.usingTprm === 'yes' && (
                <FieldRow>
                  <TextArea name="tprmDescription" label="If Yes, what:" />
                </FieldRow>
              )}
              <Button type="submit" disabled={isSubmitting}>
                Continue
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Root>
  )
}

const Root = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

const RadioText = styled.div`
  margin-right: 8px;
`
const RadiosWrapper = styled.div`
  display: flex;
`

const RadioWrapper = styled.div`
  padding: 4px;
`

export default Second
