import { useMutation } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlCreateCertMutation } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  taxName?: string
  certNumber?: string
  description?: string
  expirationDate?: string
  name?: string
  provider?: string
}
export const useCreateDiversityCert = () => {
  const request = useGql()
  const { organization } = useOrg()

  return useMutation(async (variables: Variables) => {
    const response = await request<GqlCreateCertMutation>(CREATE_CERTIFICATION, {
      ...variables,
      orgId: organization?.id,
    })

    return response?.createDiversityCertification.diversityCertification.id
  })
}

const CREATE_CERTIFICATION = gql`
  mutation CreateCert(
    $orgId: UUID!
    $taxName: String
    $certNumber: String
    $description: String
    $expirationDate: String
    $name: String
    $provider: String
  ) {
    createDiversityCertification(
      input: {
        diversityCertification: {
          organizationId: $orgId
          businessTaxName: $taxName
          certNumber: $certNumber
          description: $description
          expirationDate: $expirationDate
          name: $name
          provider: $provider
        }
      }
    ) {
      diversityCertification {
        id
      }
    }
  }
`
