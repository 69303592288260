import { useState, useEffect } from 'react'
import { useTrackingChartContext } from 'context/trackingChartContext'
import NumberCard from 'components/charts/NumberCard'
import { useLazyApi } from 'hooks/useApi'
import _ from 'lodash'

function UniquePageHits() {
  const {
    startDate,
    endDate,
    currentLoadingQueue,
    setCurrentLoadingQueue,
    productToAnalyze: product
  } = useTrackingChartContext()
  const [number, setNumber] = useState(0)
  const [getProductPageHit] = useLazyApi('tracking/amplitude/product-page-hit')

  useEffect(() => {
    const init = async () => {
      const result = await getProductPageHit({
        startDate,
        endDate,
        id: product,
        metrics: 'uniques',
      })

      const series = _.get(result, 'series[0]', [])
      const totalHits = series.reduce((a: number, b: number) => a + b, 0)

      setNumber(totalHits)
      setCurrentLoadingQueue((prevVal: number) => prevVal + 1)
    }

    if (currentLoadingQueue === 1) {
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentLoadingQueue])

  return <NumberCard title="Unique Page Hits" number={number} />
}

export default UniquePageHits
