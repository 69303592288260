import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import LawfirmForm from 'components/forms/LawfirmForm'
import { useCreateLawfirm } from './useCreateLawfirm'
import { useUserSetup } from 'context/userSetupContext'

import { useOrg } from 'data/organization/useOrg'
import { useAppMetadata } from 'data/hooks'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import SetupHeader from 'components/global/SetupHeader'
import { useLazyApi } from 'hooks/useApi'

function CreateLawfirm() {
  const { setupLawfirmData } = useCreateLawfirm()
  const { organization, lawfirm } = useOrg()
  const navigate = useNavigate()
  const { amplitude } = useUserSetup()
  const { data: appMetadata } = useAppMetadata()
  const baseOrgUrl = useBaseOrgUrl()
  const [welcomeEmail] = useLazyApi('company/welcome')

  const initialValues = {
    name: organization?.name || '',
    lawfirmType: organization?.terms.find(({ taxonomyId }) => taxonomyId === 17)?.id || undefined,
    lawfirmSize: organization?.details?.lawfirmSize || '',
    specialty: lawfirm?.mainTermId || undefined,
    logoUrl: organization?.details?.logo || [],
    jurisdictionRegion: organization?.details?.jurisdictionRegion || 'US',
    city: '',
    state: '',
    country: '',
  }

  const onSubmit = async (values: any) => {
    try {
      await setupLawfirmData(values, {
        initialLawfirmType: initialValues.lawfirmType,
        initialSpecialty: initialValues.specialty,
      })
      amplitude?.logEventWithSavedProps('set_firm_info', {
        action: 'update_information',
        is_owner: true,
      })

      await welcomeEmail({ networkName: appMetadata.assocOrg && appMetadata.assocOrg.name })

      const pathname = appMetadata.assocOrg
        ? '/setup/network-success'
        : `${baseOrgUrl}/getting-started`

      navigate(pathname, { state: { showSuccess: true } })
    } catch (e) {
      console.error(e)
      debugger
    }
  }

  const showLocation = !organization || organization?.locations.length === 0

  return (
    <Root>
      <SetupHeader />
      <FormContainer>
        <LawfirmForm
          defaultValues={initialValues}
          onSubmit={onSubmit}
          showLocation={showLocation}
        />
      </FormContainer>
    </Root>
  )
}

const Root = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const FormContainer = styled.div`
  width: 100%;
  max-width: 760px;
  padding: 40px;
  margin: 0 auto;
`

export default CreateLawfirm
