import {
  useCreateTermToVendorStackshare,
  useDeleteTermToVendorStackshare,
  useUpdateTermToVendorStackshare,
} from 'data/hooks'
import { Term } from './EditVendorStackshareForm'

export const useMutateTermToVendorStackshare = (orgId?: string) => {
  const createTermToVendorStackshare = useCreateTermToVendorStackshare()
  const updateTermToVendorStackshare = useUpdateTermToVendorStackshare()
  const deleteTermToVendorStackshare = useDeleteTermToVendorStackshare()

  const mutateTermToVendorStackshare = async (initialTerms: Term[], terms: Term[]) => {
    if (!orgId) return

    const deletedTerms = initialTerms.filter(
      ({ termToVendorStackshareId }) =>
        !terms.find((term) => termToVendorStackshareId === term.termToVendorStackshareId)
    )

    const fixTerms = terms.map((term, index) => ({
      ...term,
      order: index,
    }))

    const createdTerms = fixTerms.filter(
      ({ termToVendorStackshareId }) => !termToVendorStackshareId
    )
    const updatedTerms = fixTerms.filter(({ termToVendorStackshareId }) => termToVendorStackshareId)

    await Promise.all([
      ...createdTerms.map((term) => {
        if (!term.termId) return

        return createTermToVendorStackshare.mutateAsync({
          organizationId: orgId,
          termId: term.termId,
          order: term.order,
        })
      }),
      ...updatedTerms.map((term) => {
        if (!(term.termToVendorStackshareId && term.termId)) return

        return updateTermToVendorStackshare.mutateAsync({
          id: term.termToVendorStackshareId,
          termId: term.termId,
          order: term.order,
        })
      }),
      ...deletedTerms.map(({ termToVendorStackshareId }) => {
        if (!termToVendorStackshareId) return
        return deleteTermToVendorStackshare.mutateAsync(termToVendorStackshareId)
      }),
    ])
  }

  return mutateTermToVendorStackshare
}
