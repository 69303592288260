import { Term } from 'data/types'

const isTerm = (term: Term | undefined): term is Term => !!term

interface Terms {
  term?: Term
}
export const parseTerms = (terms: Terms[]): Term[] =>
  terms
    .map(({ term }) => {
      if (!term) return

      const { id, name, slug, order, taxonomyId, createdAt, updatedAt } = term

      return {
        id,
        name,
        slug,
        order: order || 0,
        taxonomyId,
        createdAt,
        updatedAt,
      }
    })
    .filter(isTerm) as Term[]
