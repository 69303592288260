import styled from '@emotion/styled'
import Switch from 'components/theme/Switch'
import { useOrg } from 'data/organization/useOrg'
import { useToggleShowProduct } from 'data/product/hooks/useToggleShowProduct'
import { OrganizationType } from 'generated/graphql'

function EditProducts() {
  const { organization } = useOrg()

  const toggleShowProduct = useToggleShowProduct()
  if (
    !organization ||
    !(
      organization.type === OrganizationType.Lawfirm ||
      organization.type === OrganizationType.Vendor
    )
  )
    return null

  const products = organization.products

  const toggleProduct = (productId: number, show: boolean) => {
    toggleShowProduct.mutate({ productId, show })
  }

  return (
    <Root>
      <Description>Toggle your apps below to hide/show them in the ecosystem</Description>
      <ShowHideList>
        <ShowHideListHeader>
          <HeaderItem>Product Name</HeaderItem>
          <HeaderItem>Show</HeaderItem>
        </ShowHideListHeader>
        <ShowHideListBody>
          {products &&
            products.map((product) => (
              <ProductLine key={product.id}>
                {product.name}{' '}
                <Switch isChecked={product.show} value={product.id} onChange={toggleProduct} />
              </ProductLine>
            ))}
        </ShowHideListBody>
      </ShowHideList>
    </Root>
  )
}

const Root = styled.div``

const Description = styled.div`
  font-weight: 500;
  padding-bottom: 20px;
`

const ShowHideList = styled.div`
  border: 1px solid #ccc;
  max-width: 760px;
  border-radius: 6px;
  overflow: hidden;
`

const ProductLine = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;

  &:last-of-type {
    border-bottom: none;
  }
`

const ShowHideListBody = styled.div``

const ShowHideListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background: #efefef;
  border-bottom: 1px solid #ccc;
  padding: 12px;
`

const HeaderItem = styled.div`
  font-weight: bold;
`

export default EditProducts
