import React, { ReactElement } from 'react'
import { Button } from 'components/theme'
import { LocationFormComponent } from 'components/forms/LocationForm2'
import styled from '@emotion/styled'
import { useConfirmationModal } from 'hooks/useConfirmationModal'
// import { Checkbox } from 'semantic-ui-react'

export default function LocationItem({
  index,
  onDelete,
  isHeadquarter,
}: {
  index: number
  onDelete: (index: number) => void
  isHeadquarter: boolean
  updateHeadquarters: (index: number) => void
}): ReactElement {
  const { setOpen, Modal } = useConfirmationModal({
    text: 'Are you sure you want to delete this location? This might affect your lawfirm users.',
    onConfirm: onDeleteConfirm,
  })

  function onDeleteConfirm() {
    onDelete(index)
  }

  // const toggleHeadquarters = (index: number) => () => {
  // updateHeadquarters(index)
  // }

  return (
    <LocationBlock key={index}>
      <Header>
        <Count>{index + 1}</Count>
        {isHeadquarter && <HeadquarterLabel>Headquarters</HeadquarterLabel>}

        {/*
        <Checkbox
          label="Is headquarters"
          checked={isHeadquarter}
          onChange={toggleHeadquarters(index)}
        />
        */}

        {!isHeadquarter && (
          <DeleteButton type="button" onClick={() => setOpen(true)}>
            -
          </DeleteButton>
        )}
      </Header>
      <Fields>
        <LocationFormComponent
          cityName={`locations.${index}.city`}
          countryName={`locations.${index}.country`}
          stateName={`locations.${index}.state`}
        />
      </Fields>
      <Modal />
    </LocationBlock>
  )
}

const HeadquarterLabel = styled.span``

const LocationBlock = styled.div`
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  overflow: hidden;
`

const Fields = styled.div`
  padding: 20px 20px 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
`

const Header = styled.div`
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #bdbdbd;
`

const Count = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #b9b9b9;

  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 900;
  line-height: 0;
`

const DeleteButton = styled(Button)`
  text-align: center;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  color: #e53935;
  border-color: #e53935;
  &:hover {
    background: #e53935;
    border-color: #e53935;
  }
`
