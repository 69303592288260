import { useQuery } from '@tanstack/react-query'
import { GqlGetTaxonomyTermsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useTaxonomyTerm = (taxonomyId: number) => {
  const request = useGql()
  const { data, ...other } = useQuery(
    ['taxonomyTerms', taxonomyId],
    async () => {
      const response = await request<GqlGetTaxonomyTermsQuery>(GET_TAXONOMY_TERMS, { taxonomyId })

      return response.terms
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  )

  return { terms: data, ...other }
}

const GET_TAXONOMY_TERMS = gql`
  query GetTaxonomyTerms($taxonomyId: Int!) {
    terms(condition: { taxonomyId: $taxonomyId }) {
      id
      name
      slug
      taxonomyId
      order
    }
  }
`
