const proLvnFeatures = [
  'Annual Commitment',
  'Theorem Network Listing',
  'Featured Vendor Profile w/ Analytics',
  'Seller Account, Portal, Payment Processing',
  'RFP Access & Insights',
  'Contract Assessment',
]

const venderEnterpriseFeatures = [
  'Annual Commitment',
  'Dedicated Client Success Analyst',
  'Everything in Pro; plus',
  'Unlimited Products & Listings',
  'Priority Support & Onboarding',
  'Security Assessment',
  'Custom Conversion Tools',
  'Security & Contract Assessments',
  'Priority Alerts & Access to New Releases',
  'Theorem Certified Badge*',
]

export const developmentData = [
  {
    monthly: {
      priceId: 'price_1I7UbPJl1YuJqgKxQk5zGkWk',
      name: 'Vendor LVN',
      features: proLvnFeatures,
      button: '',
      tiersMode: null,
      amount: 5000,
      interval: 'month',
      userPrice: null,
    },
    yearly: {
      priceId: 'price_1I7UbPJl1YuJqgKxdTtNs8L4',
      name: 'Vendor LVN',
      features: proLvnFeatures,
      button: '',
      tiersMode: null,
      amount: 50000,
      interval: 'year',
      userPrice: null,
    },
  },
  {
    monthly: {
      priceId: 'price_1I7V8cJl1YuJqgKxcjnZecFg',
      name: 'Vendor Enterprise',
      features: venderEnterpriseFeatures,
      button: '',
      tiersMode: null,
      amount: 50000,
      interval: 'month',
      userPrice: null,
    },
    yearly: {
      priceId: 'price_1I7V8cJl1YuJqgKxoWUXZhx7',
      name: 'Vendor Enterprise',
      features: venderEnterpriseFeatures,
      button: '',
      tiersMode: null,
      amount: 500000,
      interval: 'year',
      userPrice: null,
    },
  },
]

export const productionData = [
  {
    monthly: {
      priceId: 'price_1I7UbPJl1YuJqgKxQk5zGkWk',
      name: 'PRO LVN',
      tagline: 'Perfect for growth businesses',
      features: proLvnFeatures,
      button: 'Select this offer',
      tiersMode: null,
      amount: 10000,
      interval: 'month',
      userPrice: null,
    },
    yearly: {
      priceId: 'price_1I7UbPJl1YuJqgKxdTtNs8L4',
      name: 'PRO LVN',
      tagline: 'Perfect for growth businesses',
      features: proLvnFeatures,
      button: 'Select this offer',
      tiersMode: null,
      amount: 100000,
      interval: 'year',
      userPrice: null,
    },
  },
  {
    monthly: {
      priceId: 'price_1I7V8cJl1YuJqgKxcjnZecFg',
      name: 'Enterprise',
      tagline: 'For established enterprises',
      features: venderEnterpriseFeatures,
      button: 'Purchase',
      tiersMode: null,
      amount: 50000,
      interval: 'month',
      userPrice: null,
    },
    yearly: {
      priceId: 'price_1I7V8cJl1YuJqgKxoWUXZhx7',
      name: 'Enterprise',
      tagline: 'For established enterprises',
      features: venderEnterpriseFeatures,
      button: 'Purchase',
      tiersMode: null,
      amount: 500000,
      interval: 'year',
      userPrice: null,
    },
  },
]
