import { useState } from 'react'
import styled from '@emotion/styled'
import { useWizardModal } from 'components/modals/WizardModal'
import { useOrg, useProductListings } from 'data/hooks'
import { Product } from 'data/types'
import { ProductType } from 'generated/graphql'
import SelectProduct from './SelectProduct'
import CreateService from './CreateService'

const ServiceFlow = () => {
  const { data } = useWizardModal()
  const { productListings } = useProductListings()
  const { vendor, lawfirm } = useOrg()
  const [isCreateService, setIsCreateService] = useState(false)

  const products = vendor ? vendor.products : lawfirm?.products

  const options: Product[] | undefined = products?.filter(
    ({ id }) =>
      !productListings?.some(
        ({ product, association }) => product.id === id && association.slug === data.slug
      )
  )

  const hasService = options?.some(({ type }) => ProductType.Software === type)

  const clickCreateService = () => {
    setIsCreateService(true)
  }

  return (
    <Root>
      {hasService && !isCreateService ? (
        <SelectProduct setCreateProduct={clickCreateService} />
      ) : (
        <CreateService />
      )}
    </Root>
  )
}

const Root = styled.div``

export default ServiceFlow
