import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrg } from 'data/organization/useOrg'
import { GqlCreateTestDriveMutation } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  productId: number
  details: any
}
export const useCreateTestDrive = () => {
  const request = useGql()
  const { organization } = useOrg()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { productId, details } = variables
      const response = await request<GqlCreateTestDriveMutation>(CREATE_TEST_DRIVE, {
        organizationId: organization?.id,
        productId,
        details,
      })

      return response.createTestDrive.testDrive.id
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['testDrive']),
    }
  )
}

export const CREATE_TEST_DRIVE = gql`
  mutation CreateTestDrive($productId: Int!, $organizationId: UUID, $details: JSON) {
    createTestDrive(
      input: {
        testDrive: { productId: $productId, details: $details, organizationId: $organizationId }
      }
    ) {
      testDrive {
        id
      }
    }
  }
`
