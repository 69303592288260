import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useOrg } from '../useOrg'

interface Variables {
  name?: string
  details?: any
  mainTermId?: number
}
export const useUpdateOrg = () => {
  const request = useGql()
  const { organization } = useOrg()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      if (!organization) return

      const { name, details, mainTermId } = variables

      await request(UPDATE_ORG, {
        id: organization.id,
        name,
        details,
        mainTermId,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['org']),
    }
  )
}

const UPDATE_ORG = gql`
  mutation UpdateOrganization($id: UUID!, $name: String, $details: JSON, $mainTermId: Int) {
    updateOrganization(
      input: { patch: { details: $details, name: $name, mainTermId: $mainTermId }, id: $id }
    ) {
      organization {
        id
      }
    }
  }
`
