import React from 'react'
import styled from '@emotion/styled'
import { useHits, useSearchBox } from 'react-instantsearch-hooks-web'
import { useSelectTechStackContext } from './SelectTechStackContext'

const SearchResults = ({ onSelect, withoutTechstack }: any) => {
  const { hits, results } = useHits()
  const { refine } = useSearchBox()

  const value = results?.query

  const { state, dispatch } = withoutTechstack
    ? { state: { selectedApps: [] }, dispatch: () => console.log('work') }
    : useSelectTechStackContext()

  if (hits.length === 0 || !value || value === '') return <></>

  const searchResults = hits.map(({ objectID, title, logo }: any) => {
    const toggleSelectedApp = () => {
      dispatch &&
        dispatch({
          type: 'TOGGLE_APP_SELECTION',
          payload: { id: parseInt(objectID, 10), title, logo },
        })
    }

    if (state.selectedApps.some(({ id }: any) => id === parseInt(objectID, 10))) return <></>

    return (
      <Result
        key={`search-${objectID}`}
        onClick={() => {
          onSelect(Number(objectID))
          toggleSelectedApp()
          refine('')
        }}
      >
        {logo && <Logo src={logo} alt={`${title}'s logo`} />}
        {title}
      </Result>
    )
  })

  return (
    <Results>
      {searchResults.length > 0 ? (
        searchResults
      ) : (
        <>
          Can't find an app?{' '}
          <SmallButton href="https://airtable.com/shrGDfEJnwsDh70Xf" target="_blank">
            Suggest a product
          </SmallButton>
        </>
      )}
    </Results>
  )
}

const Results = styled.div`
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #999aa2;
  padding: 8px;
  border-radius: 5px;
  margin-top: 2px;
  cursor: pointer;
  z-index: 2;
`

const SmallButton = styled.a`
  color: white;
  display: inline-block;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 100px;
  background: ${({ theme }) => theme.color.primaryBlue};
  margin-left: 12px;

  &:hover {
    color: white;
  }
`

const Result = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 6px 4px;
  border-radius: 2px;
  &:hover {
    background-color: #ddd;
  }
  z-index: 1000;
`

const Logo = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 4px;
`

export default SearchResults
