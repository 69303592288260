import styled from '@emotion/styled'
import TotalPageHits from './charts/TotalPageHits'
import UniquePageHits from './charts/UniquePageHits'
import TotalCtaHits from './charts/TotalCtaHits'
import CardClickChart from './charts/CardClickChart'
import ProductPageHitChart from './charts/ProductPageHitChart'
import CategorySelectChart from './charts/CategorySelectChart'
import PlatformChart from './charts/PlatformChart'
import ProductCtaChart from './charts/ProductCtaChart'
import DmaChart from './charts/DmaChart'

function AnalyticCharts() {
  return (
    <Root>
      <TotalPageHits />
      <UniquePageHits />
      <TotalCtaHits />
      <ProductPageHitChart />
      <CardClickChart />
      <CategorySelectChart />
      <PlatformChart />
      <ProductCtaChart />
      <DmaChart />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
`

export default AnalyticCharts
