import { useMutation } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { useAllTerms } from 'data/taxonomy/hooks'
import { GqlCreateRfpMutation } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useLazyApi } from 'hooks/useApi'
import { useGql } from 'hooks/useGql'
import type { Rfp } from '../rfpTypes'

export const useCreateRfp = () => {
  const request = useGql()
  const { viewer } = useViewer()
  const { organization } = useOrg()
  const { terms } = useAllTerms()
  const [sendRfpApi] = useLazyApi('auth0/rfp')

  const sendRfp = async (rfp: Rfp) => {
    const {
      title,
      companyDescription,
      categories,
      products,
      budget,
      responses,
      seatsRequested,
      practiceGroups,
      requiredProvisions,
      notes,
      deployments,
      location,
      startDate,
      endDate,
    } = rfp

    const userDetails = {
      firstName: viewer.first,
      lastName: viewer.last,
      email: viewer.email,
      companyName: organization?.name || '',
    }

    const formData = {
      title,
      categories: terms?.find((category: any) => category.id === parseInt(categories))?.name,
      companyDescription,
      products: products.map(({ name }) => name).join(', '),
      budget,
      seatsRequested,
      responses,
      practiceGroups: practiceGroups
        .map((group: string) => terms?.find((term: any) => term.id === parseInt(group))?.name)
        .join(', '),
      requiredProvisions,
      notes,
      deployments: deployments.join(', '),
      location,
      startDate,
      endDate,
    }

    await sendRfpApi({ formData, userDetails })
  }

  const createRfp = async (rfp: Rfp) => {
    const rfpResponse = await request<GqlCreateRfpMutation>(CREATE_RFP, {
      details: rfp,
      userId: viewer.id,
    })

    return rfpResponse.createRfp?.rfp?.id
  }

  const createTermsToRfp = async (rfpId: number, termIds: number[]) => {
    await request(CREATE_TERMS_TO_RFP, {
      termIds,
      rfpId,
    })
  }

  const createRfpToProducts = async (rfpId: number, productIds: number[]) => {
    await request(CREATE_RFP_TO_PRODUCTS, {
      productIds,
      rfpId,
    })
  }

  return useMutation(async (rfp: Rfp) => {
    const rfpId = await createRfp(rfp)

    if (!rfpId) return

    const { practiceGroups, categories, products } = rfp

    const termIds = [...practiceGroups, categories].map((term) => parseInt(term, 10))
    await createTermsToRfp(rfpId, termIds)

    const productIds = products.map(({ id }) => id)
    await createRfpToProducts(rfpId, productIds)

    await sendRfp(rfp)
  })
}

const CREATE_RFP = gql`
  mutation CreateRfp($details: JSON, $userId: Int) {
    createRfp(input: { rfp: { details: $details, userId: $userId } }) {
      rfp {
        id
      }
    }
  }
`

const CREATE_TERMS_TO_RFP = gql`
  mutation CreateTermsToRfp($termIds: [Int!]!, $rfpId: Int!) {
    createTermsToRfp(input: { termIds: $termIds, rfpId: $rfpId }) {
      clientMutationId
    }
  }
`

const CREATE_RFP_TO_PRODUCTS = gql`
  mutation CreateRfpToProducts($productIds: [Int!]!, $rfpId: Int!) {
    createRfpToProducts(input: { productIds: $productIds, rfpId: $rfpId }) {
      clientMutationId
    }
  }
`
