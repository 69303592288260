import { useState } from 'react'
import styled from '@emotion/styled'
import { useSelectTechStackContext } from './SelectTechStackContext'
import { Icon } from 'components/theme'

function SubmitTechStackButton({ onSubmit }: any) {
  const [loading, setLoading] = useState(false)
  const { state } = useSelectTechStackContext()

  const onClick = async () => {
    setLoading(true)
    const selectedAppIds = state.selectedApps.map((app: any) => app.id)
    await onSubmit(selectedAppIds)
    setLoading(false)
  }

  return (
    <Root>
      <div>
        <Button onClick={onClick} disabled={loading}>
          {!loading ? 'Save' : <Icon name="spinner" spin />}
        </Button>
      </div>
    </Root>
  )
}

const Root = styled.div``

const Button = styled.button`
  background: #498de6;
  color: white;
  padding: 12px 20px;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: background 250ms;

  &:hover {
    background: #2e70c7;
  }
`

export default SubmitTechStackButton
