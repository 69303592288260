import { Button } from 'components/theme'
import { Formik, Form } from 'formik'
import { Multiselect, FormField } from 'components/theme/form'
import { useAssocHome, useUpdateAssocHome } from 'data/hooks'
import { useCategoryDropdown } from 'hooks/useCategoryDropdown'
import styled from '@emotion/styled'
import PulsatingLoader from 'components/PulsatingLoader'
import { parseInt } from 'lodash'

interface Props {
  assocHomeId: number
  onSuccess: () => void
}

interface Values {
  categoryIds: string[]
}
const CategoryListForm = ({ assocHomeId, onSuccess }: Props) => {
  const { assocHome, isLoading } = useAssocHome(assocHomeId)
  const updateAssocHome = useUpdateAssocHome()
  const options = useCategoryDropdown()

  const onSubmit = async (values: Values) => {
    const categoryIds = values.categoryIds.map((categoryIds) => {
      const categoryIdArray = categoryIds.split(' ')
      return categoryIdArray.map((categoryId) => parseInt(categoryId, 10))
    })
    await updateAssocHome.mutateAsync({ id: assocHomeId, details: { ...values, categoryIds } })
    onSuccess()
  }

  if (isLoading) return <PulsatingLoader />

  const categoryIds = assocHome?.details?.categoryIds
    ? assocHome?.details?.categoryIds.map((categoryId: number[]) => categoryId.join(' '))
    : ''

  return (
    <Formik initialValues={{ ...assocHome?.details, categoryIds }} onSubmit={onSubmit}>
      {({ values }) => (
        <Form>
          <FormField>
            <MultiSelectWrapper>
              <Multiselect
                label="Categories"
                name="categoryIds"
                options={options}
                values={values}
              />
            </MultiSelectWrapper>
          </FormField>

          <Button type="submit">Update</Button>
        </Form>
      )}
    </Formik>
  )
}

const MultiSelectWrapper = styled.div`
  padding-bottom: 170px;
`

export default CategoryListForm
