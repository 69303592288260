import { useEffect } from 'react'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useAuth0 } from '@auth0/auth0-react'
import { logEvent } from '@theoremlts/theorem-design'

function Logout() {
  const { logout } = useAuth0()

  useEffect(() => {
    logEvent('log_out')
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }, [])

  return <FullPageLoading />
}

export default Logout
