import React, { useState } from 'react'
import { Button, Modal, Input, Icon } from 'semantic-ui-react'
import CustomMessage from 'components/CustomMessage'
import PulsatinLoader from 'components/PulsatingLoader'
import { useLazyApi } from 'hooks/useApi'
import { Formik, Form, FieldArray } from 'formik'
import { v4 } from 'uuid'
import styled from '@emotion/styled'
import UserRoleDropdown from './UserRoleDropdown'
import { useOrg } from 'data/organization/useOrg'
import { useQueryClient } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { UserRole } from 'generated/graphql'

const MAX_INVITE = 10

interface Props {
  onModalClose: () => void
  open: boolean
  onInvite?: () => void
}
const InviteModal = ({
  onModalClose,
  open,
  onInvite = () => console.log('Teammates invited!'),
}: Props) => {
  const { organization, organizationLoading } = useOrg()
  const queryClient = useQueryClient()
  const [error] = useState(null)
  const [loading, setloading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [inviteMembers] = useLazyApi('stripe/organization/invite-teammate')
  const { subscription, isAdmin } = useViewer()

  const onSendClick = async ({ members }: any) => {
    setloading(true)
    try {
      const invites = members
        .filter(({ email }: any) => !!email)
        .map(({ email, role }: any) => ({ email: email.trim().toLowerCase(), role }))
      await inviteMembers({ members: invites })
      await queryClient.invalidateQueries(['org'])
      setSuccess(true)
      onInvite && onInvite()
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  if (organizationLoading) return <div></div>
  if (!organization) return null

  const currentTeamLength = organization.members.length

  const canShowAddButton = (addedMemberLength: number) => {
    if (organization.type === 'LAWFIRM' && subscription === 'FREE') {
      const totalMembers = currentTeamLength + addedMemberLength
      if (totalMembers >= MAX_INVITE) return false
    }

    return true
  }

  return (
    <Modal size="small" closeIcon onClose={onModalClose} open={open}>
      <Modal.Content>
        <Formik
          onSubmit={onSendClick}
          initialValues={{ members: [{ key: v4(), email: '', role: 'USER' }] }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FieldArray
                name="members"
                render={(arrayHelpers) => (
                  <ContentContainer>
                    <Icon size="big" name="users" />
                    <h3>Invite your colleague</h3>
                    <p>Your teammates will get an email that gives them access to your team.</p>
                    {!loading && (
                      <>
                        {error && <StyledCustomMessage title="" error text={error} />}
                        {!error && success && (
                          <StyledCustomMessage title="" success text="Invites successfully sent." />
                        )}
                        {values.members &&
                          values.members.length > 0 &&
                          values.members.map((member, index) => (
                            <ItemWrapper key={member.key}>
                              <MemberItem
                                onCancelClick={(i: any) => arrayHelpers.remove(i)}
                                setFieldValue={setFieldValue}
                                index={index}
                              />
                              {isAdmin && (
                                <RoleWrapper>
                                  {/* <RoleLabel>ROLE</RoleLabel> */}
                                  <UserRoleDropdown
                                    allowVendorRole
                                    role={member.role as UserRole}
                                    onChange={(value: any) => {
                                      setFieldValue(`members[${index}].role`, value)
                                    }}
                                  />
                                </RoleWrapper>
                              )}
                            </ItemWrapper>
                          ))}
                        {organization.type === 'LAWFIRM' && subscription === 'FREE' && (
                          <TeammateLimit>
                            <span>free account limit*</span>{' '}
                            {values.members.filter(({ email }) => email !== '').length +
                              currentTeamLength}
                            /10
                          </TeammateLimit>
                        )}
                        <ButtonContainer>
                          {canShowAddButton(values.members.length) && (
                            <AddButton
                              content="+ Add Member"
                              type="button"
                              basic
                              onClick={() =>
                                arrayHelpers.push({ key: v4(), email: '', role: 'USER' })
                              }
                            />
                          )}
                          <Button content="Invite" icon="send" primary type="submit" />
                        </ButtonContainer>
                      </>
                    )}
                    {loading && <PulsatinLoader />}
                  </ContentContainer>
                )}
              ></FieldArray>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

const MemberItem = ({ index, setFieldValue, onCancelClick }: any) => (
  <MemberItemContainer>
    <StyledInput
      name={`members[${index}]`}
      label={{ basic: true, content: 'Email' }}
      onChange={(_e, { value }) => {
        setFieldValue(`members[${index}].email`, value)
      }}
      icon={<Icon name="times" link onClick={() => onCancelClick(index)} />}
    />
  </MemberItemContainer>
)

const StyledCustomMessage = styled(CustomMessage)`
  width: 360px;
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`

const RoleWrapper = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  height: 38px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  padding: 0 11px 0 15px;
  border-radius: 0 5px 5px 0;
  border-left: none;
  margin-left: -4px;
`

// const RoleLabel = styled.div`
//   display: flex;
//   align-items: center;
//   font-weight: bold;
//   padding-right: 11px;
//   margin-right: 11px;
//   height: 100%;
//   border-right: 1px solid rgba(34, 36, 38, 0.15);
// `

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-top: 32px;
  width: 360px;
  margin-top: auto;
  max-height: 72px;
  justify-content: space-between;
`

const AddButton = styled(Button)`
  box-shadow: none !important;
`

const StyledInput = styled(Input)`
  width: 360px;
`

const MemberItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  width: 360px;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  min-height: 340px;
  align-items: center;
`

const TeammateLimit = styled.div`
  margin-top: 8px;
  text-align: right;
  font-weight: bold;
  width: 360px;
  letter-spacing: 0.02rem;

  span {
    color: #555;
    font-weight: normal;
    font-style: italic;
  }
`

export default InviteModal
