import styled from '@emotion/styled'
import Icon from 'components/theme/Icon'
import { Link } from 'react-router-dom'
import _ from 'lodash'

function Button({
  children,
  loading = false,
  component = 'button',
  color = '',
  isSuccess = false,
  ...props
}: any) {
  if (component === 'a') {
    return (
      <ButtonLink color={color} {...props}>
        {children}
      </ButtonLink>
    )
  }
  if (component === 'link') {
    return (
      <ButtonRouterLink color={color} {...props}>
        {children}
      </ButtonRouterLink>
    )
  }

  return (
    <Root color={color} isSuccess={isSuccess} {...props}>
      {loading ? <Icon name="spinner" spin pulse /> : isSuccess ? <Icon name="check" /> : children}
    </Root>
  )
}

const buttonColor = ({ color, theme }: any) => color || _.get(theme, 'color.primaryBlue', '#498DE6')
const diabledColor = '#dedede'

const Root = styled.button<{ isSuccess?: boolean }>`
  display: inline-block;
  color: ${buttonColor};
  border: 2px solid ${buttonColor};
  background: ${buttonColor};
  border-radius: 40px;
  padding: 8px 20px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: white;
  transition: color 250ms, background 250ms, border 250ms, width 250ms;
  line-height: 1;
  outline: 0;
  cursor: pointer;

  &:hover {
    background: ${buttonColor};
    color: white;
  }

  &:disabled,
  &[disabled] {
    border: 2px solid ${diabledColor};
    background: ${diabledColor};
    color: white;

    &:hover {
      background: ${diabledColor};
      cursor: not-allowed;
    }
  }

  ${({ isSuccess }) =>
    isSuccess ? `background: #8BC34A !important; border: 2px solid #8BC34A !important;` : ''}
`

const ButtonLink = Root.withComponent('a')
const ButtonRouterLink = Root.withComponent(Link)

export default Button
