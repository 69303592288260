import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

function FileUpload({ addFile, styles = css``, fluidWidth, children }: any) {
  const onDrop = useCallback(
    ([acceptedFiles]: any) => {
      addFile(acceptedFiles)
    },
    [addFile]
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <Root css={styles} {...getRootProps()} fluidWidth={fluidWidth}>
      <input {...getInputProps()} />
      {children}
    </Root>
  )
}

const Root = styled.div<{ fluidWidth: boolean }>`
  max-width: ${({ fluidWidth }) => (fluidWidth ? 'none' : '150px')};
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgba(34, 36, 38, 0.5);
  background: #fcfcfc;
  color: #646571;
  border-radius: 3px;
  cursor: copy;
`

export default React.memo(FileUpload)
