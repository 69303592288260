import React, { useState } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useOrg } from 'data/organization/useOrg'

const EmptyEditorList: React.FC = () => {
  const { organization } = useOrg()
  const baseOrgUrl = useBaseOrgUrl()
  const [goCreate, setGoCreate] = useState(false)
  const { productId } = useParams<{ productId?: string }>()

  const vendorParagraph = (
    <>
      <p>User Stories will help with the searchability of your products.</p>
      <p>You don't have any User Stories for this product yet.</p>
    </>
  )

  const firmParagraph = (
    <>
      <p>
        Share User Stories for the products you use with other members of your organization to
        encourage the adoption of new tools!
      </p>
      <p>Any Use Cases you create will appear here.</p>
    </>
  )

  if (goCreate)
    return organization?.type !== 'VENDOR' ? (
      <Navigate to={`${baseOrgUrl}/user-stories/create`} />
    ) : (
      <Navigate to={`${baseOrgUrl}/product/settings/${productId}/user-stories/create`} />
    )
  return (
    <ZeroState>
      <Text>
        <h3>Create your first User Story!</h3>
        {organization?.type !== 'VENDOR' ? firmParagraph : vendorParagraph}
      </Text>
      <OutLink onClick={() => setGoCreate(true)}>Create a User Story</OutLink>
    </ZeroState>
  )
}

const buttonColor = ({ theme }: any) => theme.color.primaryBlue

const ZeroState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
    text-align: center;
  }
`

const OutLink = styled.div`
  margin: 0 auto;
  display: inline-block;
  color: ${buttonColor};
  border: 2px solid ${buttonColor};
  border-radius: 40px;
  padding: 8px 20px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: transparent;
  transition: color 250ms, background 250ms;
  line-height: 1;
  cursor: pointer;

  &:hover {
    background: ${buttonColor};
    border: 2px solid ${buttonColor};
    color: white;
  }
`

export default EmptyEditorList
