import styled from '@emotion/styled'
import { Button } from 'components/theme'
import { useAuth0 } from '@auth0/auth0-react'
import { useOrg } from 'data/hooks'
import { useAssocHomeEditor } from './assocHomeEditorContext'

const ViewHomeButton = () => {
  const { sections } = useAssocHomeEditor()
  const { organization } = useOrg()
  const { user: auth0User } = useAuth0()

  const domain =
    process.env.NODE_ENV === 'development'
      ? `https://ecosystem.theorem.local:3300`
      : 'https://ecosystem.theoremlegal.com'
  let loginUrl = `${domain}/api/auth/login?`

  if (auth0User?.org_id) {
    loginUrl = `${loginUrl}organization=${auth0User?.org_id}&`
  }

  if (!sections || sections.length === 0) return null

  return (
    <Container>
      <Button component="a" href={`${loginUrl}returnTo=/a/${organization?.slug}`} target="_blank">
        View Home Page
      </Button>
    </Container>
  )
}

const Container = styled.div`
  max-width: 800px;
  text-align: right;
  margin: 0 auto 30px;
`

export default ViewHomeButton
