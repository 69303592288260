import { useMutation } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  logins: number
  techstackId: number
}
export const useUpdateLogins = () => {
  const request = useGql()
  const { viewer } = useViewer()
  return useMutation(async (variables: Variables) => {
    const { logins, techstackId } = variables

    await request(UPDATE_LOGINS, {
      logins,
      userId: viewer.id,
      techstackId,
    })
  })
}

const UPDATE_LOGINS = gql`
  mutation updateLogins($logins: Int!, $userId: Int!, $techstackId: Int!) {
    updateUsersToTechstack(
      input: { patch: { logins: $logins }, userId: $userId, techstackId: $techstackId }
    ) {
      clientMutationId
    }
  }
`
