import React, { useState } from 'react'
import { useForm, useFieldArray, SubmitHandler, Controller } from 'react-hook-form'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import SortableItem from './SortableItem'
import { useTaxonomyTerm } from 'data/hooks'
import Select from 'react-dropdown-select'
import DropzoneField from 'components/theme/formField/DropzoneField'
import styled from '@emotion/styled'

export interface Term {
  id?: string
  termToVendorStackshareId?: number
  termId?: number
  taxonomyId: number
}

export interface Company {
  id?: string
  logoId?: number
  logo: any
  name: string
}

export interface FormValues {
  stackshareDemoRequest: string
  companies: Company[]
  terms: Term[]
}

const TAXONOMY_OPTIONS = [
  { label: 'Category', value: 14 },
  { label: 'Subcategory', value: 15 },
]

function EditVendorStackshareForm({
  initialValues,
  onSubmit,
}: {
  initialValues: FormValues
  onSubmit: (data: FormValues) => Promise<void>
}) {
  const { terms } = useTaxonomyTerm(14)
  const { terms: termsSubcategory } = useTaxonomyTerm(15)
  const { control, register, handleSubmit } = useForm<FormValues>({
    defaultValues: initialValues,
  })
  const { fields, append, remove, update, move } = useFieldArray({
    control,
    name: 'terms',
  })

  const {
    fields: imageFields,
    append: imageAppend,
    remove: imageRemove,
    move: imageMove,
  } = useFieldArray({
    control,
    name: 'companies',
  })

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const onFormSubmit: SubmitHandler<FormValues> = async (data) => {
    await onSubmit(data)
    console.log(data)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = fields.findIndex((field) => field.id === active.id)
      const newIndex = fields.findIndex((field) => field.id === over.id)

      move(oldIndex, newIndex)
    }
  }

  const handleImageDragEnd = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = imageFields.findIndex((field) => field.id === active.id)
      const newIndex = imageFields.findIndex((field) => field.id === over.id)

      imageMove(oldIndex, newIndex)
    }
  }

  const handleFileChange = (files: File[]) => {
    console.log(files)
  }

  if (!terms || !termsSubcategory) return null

  const termMainCateogryOptions = terms.map((term) => ({
    label: term.name,
    value: term.id,
    taxonomyId: term.taxonomyId,
  }))
  const termSubcategoryOptions = termsSubcategory.map((term) => ({
    label: term.name,
    value: term.id,
    taxonomyId: term.taxonomyId,
  }))

  const termOptions = [...termMainCateogryOptions, ...termSubcategoryOptions]

  console.log({ imageFields, fields })
  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <FieldWrapper>
        <h3>Demo Request Link</h3>
        <input placeholder="https://example.com" {...register('stackshareDemoRequest')} />
      </FieldWrapper>
      <FieldWrapper>
        <h3>Category</h3>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext id="terms" items={fields} strategy={verticalListSortingStrategy}>
            {fields.map((field, index) => (
              <CategoryRow key={field.id}>
                <SortableItem id={field.id} removeClick={() => remove(index)}>
                  <SelectFields>
                    <CategoryColumn>
                      <Select
                        name={`terms.${index}.termId` as const}
                        options={termOptions.filter((term) => term.taxonomyId === field.taxonomyId)}
                        values={termOptions.filter((term) => term.value === field.termId)}
                        onChange={(values) => {
                          update(index, { ...field, termId: values[0].value })
                        }}
                      />
                    </CategoryColumn>
                    <Select
                      name={`terms.${index}.taxonomyId` as const}
                      options={TAXONOMY_OPTIONS}
                      values={TAXONOMY_OPTIONS.filter(
                        (option) => option.value === field.taxonomyId
                      )}
                      onChange={(values) => {
                        update(index, { ...field, termId: undefined, taxonomyId: values[0].value })
                      }}
                    />
                  </SelectFields>
                </SortableItem>
              </CategoryRow>
            ))}
          </SortableContext>
          <button
            type="button"
            onClick={() =>
              append({ termToVendorStackshareId: undefined, termId: undefined, taxonomyId: 14 })
            }
          >
            Add Term
          </button>
        </DndContext>
      </FieldWrapper>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleImageDragEnd}
      >
        <FieldWrapper>
          <h3>Client Logos</h3>
          <SortableContext id="images" items={imageFields} strategy={verticalListSortingStrategy}>
            {imageFields.map((image, index) => (
              <ImageRow>
                <SortableItem key={image.id} id={image.id} removeClick={() => imageRemove(index)}>
                  <ImageWrapper>
                    <Controller
                      name={`companies.${index}.logo` as const}
                      control={control}
                      defaultValue={image.logo}
                      render={({ field }) => (
                        <DropzoneField
                          onFileChange={(files) => {
                            field.onChange(files)
                            handleFileChange(files)
                          }}
                          defaultValue={image.logo}
                        >
                          Square Logo
                        </DropzoneField>
                      )}
                    />
                  </ImageWrapper>
                </SortableItem>
              </ImageRow>
            ))}
          </SortableContext>
          <button
            type="button"
            onClick={() => imageAppend({ logoId: undefined, name: '', logo: undefined })}
          >
            Add Client Logo
          </button>
        </FieldWrapper>
      </DndContext>
      <div>
        <input type="submit" value="Submit" />
      </div>
    </form>
  )
}

const ImageWrapper = styled.div`
  padding-bottom: 12px;
`

const FieldWrapper = styled.div`
  margin-bottom: 28px;
`

const SelectFields = styled.div`
  display: flex;
`

const CategoryRow = styled.div`
  max-width: 750px;
`

const ImageRow = styled.div`
  max-width: 750px;
`

const CategoryColumn = styled.div`
  width: 300px;
`

export default EditVendorStackshareForm
