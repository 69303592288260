import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  id: number
  order?: number
  details?: any
}
export const useUpdateAssocHome = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { id, details, order } = variables
      await request(UPDATE_ASSOC_HOME, { id, details, order })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['assocHome']),
    }
  )
}

const UPDATE_ASSOC_HOME = gql`
  mutation UpdateAssocHome($id: Int!, $details: JSON, $order: Int) {
    updateAssocHomeById(input: { patch: { details: $details, order: $order }, id: $id }) {
      clientMutationId
    }
  }
`
