// dependencies
import React from 'react'
import { Form, Formik } from 'formik'
// components
import { FullPageLoading } from 'components/PulsatingLoader'
import { FormField, Multiselect, Dropdown } from 'components/theme/form'
import { DispatchInput } from '.'
import Label from 'components/theme/form/Label'
// definitions
import { FormProps } from '.'
import { useTaxonomyDropdown } from 'hooks/useTaxonomyDropdown'

const RfpTerms: React.FC<FormProps> = ({
  FormStepperLinks,
  updateStepper,
  onSubmit,
  initialValues,
}) => {
  const { options: practiceGroupOptions, isLoading: practiceGroupsLoading } = useTaxonomyDropdown({
    taxonomyId: 13,
  })
  const { options: categoryOptions, isLoading: categoriesLoading } = useTaxonomyDropdown({
    taxonomyId: 14,
  })

  if (practiceGroupsLoading || categoriesLoading) return <FullPageLoading />
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <FormField>
              <Dropdown
                name="categories"
                label="What type of product are you looking for"
                search
                options={categoryOptions}
                value={values.categories}
                setFieldValue={setFieldValue}
                onChange={(_e: any, { value }: any) => {
                  setFieldValue('categories', value)
                  updateStepper('categories', value)
                }}
              />
            </FormField>
            <FormField>
              <Label children="Seats Requested" />
              <DispatchInput
                name="seatsRequested"
                updateStepper={updateStepper}
                placeholder="# of seats/licenses requested"
              />
            </FormField>
            <FormField>
              <Label children="Budget" />
              <DispatchInput
                name="budget"
                updateStepper={updateStepper}
                placeholder="amount per seat, total budget"
              />
            </FormField>
            <FormField>
              <Label children="Maximum # of Responses" />
              <DispatchInput
                name="responses"
                updateStepper={updateStepper}
                placeholder="limit responses to [#]"
              />
            </FormField>
            <FormField>
              <Multiselect
                name="practiceGroups"
                label="Practice/Group for Deployment"
                options={practiceGroupOptions}
                value={values.practiceGroups}
                updateStepper={(value: number) => updateStepper('practiceGroups', value)}
              />
            </FormField>
            <FormStepperLinks />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default RfpTerms
