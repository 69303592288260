import { GqlCurrentOrgFragment, OrganizationType } from 'generated/graphql'
import { parseTerms } from 'data/taxonomy/parseTerms'
import { Personal } from './types/orgType'
import { parseMembers } from './parseMembers'

export const parsePersonal = (associationData: GqlCurrentOrgFragment): Personal => {
  const {
    id,
    name,
    slug,
    createdAt,
    updatedAt,
    details,
    locations,
    testMode,
    termToOrganizations,
    usersToOrganizations,
    appMetadata,
  } = associationData

  return {
    id,
    name,
    slug,
    type: OrganizationType.Personal,
    createdAt,
    updatedAt,
    details,
    locations,
    testMode,
    terms: parseTerms(termToOrganizations),
    members: parseMembers(usersToOrganizations), // TODO: fix this is incorrect
    appMetadata,
  }
}
