import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { Link } from 'react-router-dom'
import BaseModal from './BaseModal'

interface Props {
  toggleModal: () => void
  previewUrl: string
}
const PreviewListingCtaModal = ({ toggleModal, previewUrl }: Props) => {
  const baseOrgUrl = useBaseOrgUrl()

  return (
    <BaseModal toggleModal={toggleModal}>
      <Root>
        <Title>You're almost there!</Title>
        <Subtitle>Add this product to a listing</Subtitle>
        <RequiredText>(Product Requires Listing)</RequiredText>
        <ImageWrapper>
          <Image src="https://res.cloudinary.com/dh1giwabb/image/upload/v1651106907/Team_goals-rafiki_cxs3ic.svg" />
        </ImageWrapper>
        <ButtonsWrapper>
          <PurchaseButton to={`${baseOrgUrl}/product-listing/networks`}>
            Purchase Listing +
          </PurchaseButton>
          <ContinueButton href={previewUrl} target="_blank">
            continue to preview
          </ContinueButton>
        </ButtonsWrapper>
      </Root>
    </BaseModal>
  )
}

const Root = styled.div`
  padding: 32px 20px;
  width: 550px;
`

const Title = styled.h3`
  font-size: 32px;
  margin-bottom: 0;
  text-align: center;
`

const Subtitle = styled.h4`
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 4px;
  text-align: center;
`

const RequiredText = styled.div`
  color: red;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.02rem;
`

const ImageWrapper = styled.div`
  text-align: center;
`

const Image = styled.img`
  max-width: 300px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const buttonStyles = css`
  border-radius: 100px;
  padding: 8px 20px;
  margin: 0 8px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02rem;
`

const PurchaseButton = styled(Link)`
  ${buttonStyles}
  background: #00A2FF;
  color: white;

  &:hover {
    color: white;
  }
`

const ContinueButton = styled.a`
  ${buttonStyles}
  background: #d8d8d8;
  color: #333;

  &:hover {
    color: #333;
  }
`

export default PreviewListingCtaModal
