import { useQuery } from '@tanstack/react-query'
import { useViewer } from 'context/viewerContext'
import { GqlGetInvitedOrgIdsQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

export const useGetInviteOrg = () => {
  const { viewer } = useViewer()
  const request = useGql()
  const { data, ...other } = useQuery(['org-invite'], async () => {
    const response = await request<GqlGetInvitedOrgIdsQuery>(GET_INVITED_ORG, { userId: viewer.id })

    if (!response || (response && response.usersToOrganizations?.length === 0)) return

    const userToOrg = response.usersToOrganizations?.[0]

    if (!userToOrg) return

    return {
      id: userToOrg.organizationId,
      name: userToOrg.organization?.name,
      type: userToOrg.organization?.type,
      termsPdf: userToOrg.organization?.details.termsPdf?.url,
    }
  })

  return { inviteDetails: data, ...other }
}

export const GET_INVITED_ORG = gql`
  query GetInvitedOrgIds($userId: Int!) {
    usersToOrganizations(first: 1, condition: { userId: $userId, status: INVITED }) {
      organizationId
      organization {
        name
        type
        details
      }
    }
  }
`
