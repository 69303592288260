import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation } from '@tanstack/react-query'

export const useCreateEmptyProduct = () => {
  const request = useGql()
  return useMutation(async (type: string) => {
    await request(CREATE_EMPTY_PRODUCT, { type })
  }, {})
}

const CREATE_EMPTY_PRODUCT = gql`
  mutation CreateEmptyProduct($type: ProductType = SOFTWARE) {
    createProduct(input: { product: { type: $type } }) {
      clientMutationId
    }
  }
`
