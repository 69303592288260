import React from 'react'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import UserOrgTechstack, { useUserOrgTechstack } from 'components/techstack/UserOrgTechstack'

const UserFirmTechstack: React.FC = () => {
  const { onSubmit, userTechstackIds } = useUserOrgTechstack()

  const submitUserFirmTechstack = async (selectedTechstackIds: number[]) => {
    try {
      await onSubmit(selectedTechstackIds)
      toast.success('Success!', { toastId: 'firmstack-submit ' })
    } catch (error) {
      console.error(error)
      toast.error('Something went wrong', { toastId: 'firmstack-submit-error' })
    }
  }

  return (
    <Root>
      <UserOrgTechstack userTechstackIds={userTechstackIds} onSubmit={submitUserFirmTechstack} />
    </Root>
  )
}

const Root = styled.div`
  max-width: 1000px;
`

export default UserFirmTechstack
