import styled from '@emotion/styled'
import { useAlgoliaTechstack } from './AlgoliaTechstackContext'
import { Icon } from 'components/theme'
import { useHits } from 'react-instantsearch-hooks-web'

const SearchResults = () => {
  const { hits, results } = useHits()
  const { state, dispatch } = useAlgoliaTechstack()

  const searchItems = hits.map(({ objectID, title, logo }: any) => {
    const toggleSelectedApp = () => {
      dispatch &&
        dispatch({
          type: 'TOGGLE_APP_SELECTION',
          payload: { id: parseInt(objectID, 10), title, logo },
        })
    }

    const isChecked = state.selectedApps.some(({ id }: any) => id === parseInt(objectID, 10))

    return (
      <Result
        key={`search-${objectID}`}
        onClick={() => {
          toggleSelectedApp()
        }}
        ischecked={isChecked.toString()}
      >
        <CheckBoxBox ischecked={isChecked.toString()}>
          <CheckIcon name="check" ischecked={isChecked.toString()} />
        </CheckBoxBox>
        {logo && <Logo src={logo} alt={`${title}'s logo`} />}
        {title}
      </Result>
    )
  })

  if (hits?.length === 0 && (!results?.query || results?.query === '')) return null

  return (
    <Results>
      {searchItems.length > 0 ? (
        searchItems
      ) : (
        <NoResults>
          Can't find an app?{' '}
          <SmallButton href="https://airtable.com/shrGDfEJnwsDh70Xf" target="_blank">
            Suggest a product
          </SmallButton>
        </NoResults>
      )}
    </Results>
  )
}

const activeBlue = '#498de6'
const grey = '#999999'

const NoResults = styled.div`
  padding: 16px;
`
const CheckBoxBox = styled.div<{ ischecked: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 3px solid ${({ ischecked }) => (ischecked === 'true' ? activeBlue : grey)};
  border-radius: 4px;
  margin-right: 16px;
  box-shadow: 0 0 0 0 rgba(73, 141, 230, 0);
  transition: box-shadow 250ms, border 200ms;

  &:hover {
    box-shadow: 0 0 1px 4px rgba(73, 141, 230, 0.4);
  }
`

const CheckIcon = styled(Icon)<{ ischecked: string }>`
  color: #498de6;
  font-size: 12px;
  transition: opacity 200ms;
  opacity: ${({ ischecked }) => (ischecked === 'true' ? 1 : 0)};
`

const Results = styled.div`
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #999aa2;
  border-radius: 5px;
  margin-top: 2px;
  cursor: pointer;
  z-index: 2;
`

const SmallButton = styled.a`
  color: white;
  display: inline-block;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 100px;
  background: ${({ theme }) => theme.color.primaryBlue};
  margin-left: 12px;

  &:hover {
    color: white;
  }
`

const Result = styled.div<{ ischecked: string }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 2px;
  z-index: 1000;
  transition: background 200ms;

  &:hover {
    background-color: #fafafa;

    ${CheckIcon} {
      opacity: ${({ ischecked }) => (ischecked === 'true' ? 1 : 0.5)};
    }
  }
`

const Logo = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 4px;
`

export default SearchResults
