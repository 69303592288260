import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
// import ConnectToStripe from './ConnectToStripe'
// import ProductOverview from './ProductOverview'
import { toast } from 'react-toastify'
import { Routes, Route, useLocation } from 'react-router-dom'
// import { useStripeContext } from 'context/stripeContext'
import { BoxWrapper } from 'components/theme'
import { FullPageLoading } from 'components/PulsatingLoader'
import ProductMenu from './ProductMenu'
import defaultMenuItems, { editUseCaseRoutes, servicesRoutes } from './productSettingPages'
import { useProductSettings } from './useProductSettings'
import { useViewer } from 'context/viewerContext'
import PreviewListingCtaModal from 'components/modals/PreviewListingCtaModal'
import { useAuth0 } from '@auth0/auth0-react'
// import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useProductListings } from 'data/hooks'
import _ from 'lodash'

function ProductSettings() {
  const { product, isService, loading } = useProductSettings()
  const location = useLocation()
  const { paymentStatus } = useViewer()
  const showSuccess = _.get(location, 'showSuccess', false)
  const [ready, setReady] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { user: auth0User } = useAuth0()
  // const baseOrgUrl = useBaseOrgUrl()
  const { productListings } = useProductListings()

  useEffect(() => {
    if (!loading && !ready) setReady(true)
  }, [loading])

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  if (showSuccess)
    toast.success('Congrats! Your product has been created. 🎉', {
      toastId: 'productCreated',
    })

  const allRoutes = isService ? servicesRoutes : [...defaultMenuItems, ...editUseCaseRoutes]

  if (!ready) return <FullPageLoading />

  const SettingsSwitch = () => (
    <Routes>
      {allRoutes.map((item, index) => (
        <Route key={index} path={item.url} element={<item.component />} />
      ))}
    </Routes>
  )

  const toggleModal = () => {
    setShowModal((prevVal) => !prevVal)
  }

  const domain =
    process.env.NODE_ENV === 'development'
      ? `https://ecosystem.theorem.local:3300`
      : 'https://ecosystem.theoremlegal.com'

  let marketplaceUrl = `${domain}/api/auth/login?returnTo=/${isService ? 'service' : 'product'}/${
    product?.slug
  }`

  if (auth0User?.org_id) {
    marketplaceUrl = `${marketplaceUrl}&organization=${auth0User?.org_id}`
  }

  return (
    <>
      {/*
      <Cta>
        Creating a product DOES NOT create a listing. Preview available before purchase - NOT ACTIVE
        UNTIL PURCHASE <Link to={`${baseOrgUrl}/product-listing/networks`}>View Pricing</Link>
      </Cta>
    */}
      <BoxWrapper>
        <TopOfWrapper>
          <Title>{isService ? 'Service' : 'Product'} Builder</Title>
          {paymentStatus === 'NOT_ACTIVE' && (!productListings || productListings.length === 0) ? (
            <NotActivePayment>
              <NotActiveText>UNPUBLISHED</NotActiveText>
              <OutLink onClick={toggleModal}>Preview &amp; Publish</OutLink>
            </NotActivePayment>
          ) : (
            <OutLink href={marketplaceUrl} target="_blank">
              View Live Listing
            </OutLink>
          )}
        </TopOfWrapper>
        <ProductMenu />
        {SettingsSwitch()}
      </BoxWrapper>
      {showModal && (
        <PreviewListingCtaModal toggleModal={toggleModal} previewUrl={marketplaceUrl} />
      )}
    </>
  )
}

const NotActivePayment = styled.div`
  display: flex;
  align-items: center;
`

const TopOfWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.h3``

const NotActiveText = styled.span`
  color: #df1515;
  margin-right: 16px;
`

const OutLink = styled.a<{ theme?: any }>`
  display: inline-block;
  color: ${({ theme }) => theme.color.primaryPurple};
  border: 2px solid ${({ theme }) => theme.color.primaryPurple};
  border-radius: 40px;
  padding: 8px 20px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: transparent;
  transition: color 250ms, background 250ms;
  line-height: 16px;

  &:hover {
    background: ${({ theme }) => theme.color.primaryPurple};
    border: 2px solid ${({ theme }) => theme.color.primaryPurple};
    color: white;
  }
`

export default ProductSettings
