import { useRef } from 'react'
import styled from '@emotion/styled'
import PlanHeader from './PlanHeader'
import PlanFeatures from './PlanFeatures'
import PlanSelections from './PlanSelections'
import PersonalFaq from './PersonalFaq'

// const PREMIUM_PLAN = 'price_1I4As4Jl1YuJqgKxLsHoTexB'
// const USER_PLAN = 'firm_basic'

function LawfirmPlans() {
  const ref = useRef<any>(null)

  const executeScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <Root>
      <PlanHeader executeScroll={executeScroll} />
      <PlanFeatures />
      <PlanSelections sectionRef={ref} />
      <PersonalFaq />
    </Root>
  )
}

const Root = styled.div`
  margin-top: 40px;
`

export default LawfirmPlans
