import { isUsingProductionServer } from 'services/helper'

// TermScout Standard
export const TS_STANDARD_PRODUCT_ID = isUsingProductionServer
  ? 'prod_Ixoc6sjKVzf2W4'
  : 'prod_Ixr8OeU8KZrjF0'
export const TS_STANDARD_PRICE_ID = isUsingProductionServer
  ? 'price_1ILszPJl1YuJqgKxf4Gk9HBB'
  : 'price_1ILvQTJl1YuJqgKxcufIrGKa'

export const TS_STANDARD_FEATURES = [
  'Comprehensive review of your standard customer contract',
  'Overall customer-fairness rating',
  'Fairness ratings for each topic',
  'Identification of material ambiguities',
  'Overall rating publishes on Theorem and termscout.com immediately',
]

// TermScout Advanced
export const TS_ADVANCED_PRODUCT_ID = isUsingProductionServer
  ? 'prod_JDCg3Rf2Hez4RD'
  : 'prod_JCrryCeFdv1hiT'
export const TS_ADVANCED_PRICE_ID = isUsingProductionServer
  ? 'price_1IamHHJl1YuJqgKxTsVAGWPW'
  : 'price_1IaS8FJl1YuJqgKxND7gXqWk'
export const TS_ADVANCED_FEATURES = [
  'Comprehensive review of your standard customer contract',
  'Overall customer-fairness rating',
  'Fairness ratings for each topic',
  'Identification of material ambiguities',
  'Overall rating publishes on Theorem and termscout.com after 30 days (incorporating any updates to the contract during that period)',
]

// TermScout Pro
export const TS_PRO_PRODUCT_ID = isUsingProductionServer
  ? 'prod_JDChUle4cRNHRY'
  : 'prod_JCrtwH2tTtaGzC'
export const TS_PRO_PRICE_ID = isUsingProductionServer
  ? 'price_1IamI5Jl1YuJqgKxGf1IlE1a'
  : 'price_1IaS9wJl1YuJqgKxV4wVfL13'
export const TS_PRO_FEATURES = [
  'Everything in Advanced Review',
  'Report and TermScout platform access, showing (a) how to improve your ratings, and (b) how your provisions compare to competitors',
]
