import React from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router'
import { lawfirmOwnerSteps, lawfirmUserSteps } from '../steps'
import HeaderStepper from 'components/theme/HeaderStepper'
import UserOrgTechstack, { useUserOrgTechstack } from 'components/techstack/UserOrgTechstack'
import { useUserSetup } from 'context/userSetupContext'
import { UserRole } from 'generated/graphql'
import { useViewer } from 'context/viewerContext'

const LawfirmUserTechstack: React.FC = () => {
  const { onSubmit, userTechstackIds } = useUserOrgTechstack()
  const navigate = useNavigate()
  const { viewer } = useViewer()
  const { amplitude } = useUserSetup()

  const submitUserFirmTechstack = async (selectedTechstackIds: number[]) => {
    await onSubmit(selectedTechstackIds)
    amplitude?.logEventWithSavedProps('set_personal_techstack', {
      organization_type: 'LAWFIRM',
      action: 'update_information',
    })
    navigate('/setup/lawfirm-personal-stack')
  }

  const notOwner = viewer.role === UserRole.User

  return (
    <>
      <HeaderStepper
        steps={notOwner ? lawfirmUserSteps : lawfirmOwnerSteps}
        currentStep={notOwner ? 1 : 3}
      />
      <Root>
        <UserOrgTechstack userTechstackIds={userTechstackIds} onSubmit={submitUserFirmTechstack} />
      </Root>
    </>
  )
}

const Root = styled.div`
  margin: 0 auto;
  max-width: 800px;
  padding: 40px 20px 60px;
`

export default LawfirmUserTechstack
