import styled from '@emotion/styled'
import { Icon } from 'components/theme'
import { Avatar } from 'components/user'
import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/organization/useOrg'
import { Link } from 'react-router-dom'

const UserInfoHeader = () => {
  const { viewer } = useViewer()
  const { organization } = useOrg()

  return (
    <Root>
      <Wrapper>
        <Avatar user={viewer} profileDisabled large />
        <Content>
          <Name>{viewer.name}</Name>
          <Email>{viewer.email}</Email>
          {organization && <OrgName>{organization.name}</OrgName>}
        </Content>
      </Wrapper>
      <ButtonWrapper>
        <SwitchButton to="/select-organization">
          Switch Organization <SwitchIcon name="exchange-alt" />
        </SwitchButton>
      </ButtonWrapper>
    </Root>
  )
}

const Root = styled.div`
  background: #f6f6f7;
  padding: 16px;
  border-bottom: 1px solid #d3d3d3;
`

const Wrapper = styled.div`
  display: flex;
  padding-bottom: 16px;
`

const Content = styled.div`
  padding-left: 16px;
`

const Name = styled.div`
  color: #4a4b4d;
  font-weight: 500;
  font-size: 16px;
`

const Email = styled.div`
  color: #6e7a8d;
  font-size: 14px;
  margin-bottom: 4px;
`

const OrgName = styled.div`
  color: #616b7c;
  font-size: 14px;
  font-weight: 500;
`

const SwitchButton = styled(Link)`
  display: inline-block;
  color: #63676f;
  background: white;
  border: 1px solid #bcbcbc;
  border-radius: 999px;
  padding: 4px 16px;
  font-weight: 500;
  transition: 250ms color, 250ms background;
  letter-spacing: 0.02rem;

  &:hover {
    color: #46494e;
    background: rgba(255, 255, 255, 0.35);
  }
`

const SwitchIcon = styled(Icon)`
  font-size: 16px;
`

const ButtonWrapper = styled.div`
  text-align: center;
`

export default UserInfoHeader
