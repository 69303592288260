import { useEffect } from 'react'
import { useTrackingChartContext } from 'context/trackingChartContext'
import { useChartJs } from 'hooks/useChartJs'
import ChartCard from 'components/charts/ChartCard'
import { useLazyApi } from 'hooks/useApi'
import _ from 'lodash'
import { useOrg } from 'data/organization/useOrg'

function CategorySelectChart() {
  const {
    startDate,
    endDate,
    currentLoadingQueue,
    setCurrentLoadingQueue,
    productToAnalyze: product,
  } = useTrackingChartContext()
  const { ctx, setChartData } = useChartJs({ type: 'doughnut', options: {} })
  const { vendor } = useOrg()
  const [getCategorySelect] = useLazyApi('tracking/amplitude/category-select')
  const productId = product

  useEffect(() => {
    const init = async () => {
      const product = _.find(vendor?.products, { id: productId })

      const categories = _.get(product, 'categories', [])
      const categoryIds = categories.map((category: any) => category.id)
      const result = await getCategorySelect({ startDate, endDate, ids: categoryIds })

      const series = _.get(result, 'series', [])
      const seriesLabels = _.get(result, 'seriesLabels', [])

      const totalCount = series.map((val: any) => val.reduce((a: any, b: any) => a + b, 0))
      const datasets = [
        {
          label: 'Page Views',
          data: totalCount,
          borderWidth: 1,
          backgroundColor: ['#5062D5', '#9446D3', '#3ACFB2', '#122AC0', '#6C09BD', '#00B793'],
        },
      ]
      const labels = seriesLabels.map((val: any) => val[1])
      setChartData({ datasets, labels })
      setCurrentLoadingQueue((prevVal: any) => prevVal + 1)
    }

    if (currentLoadingQueue === 5) {
      init()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentLoadingQueue])

  return <ChartCard title="Category Hits" ctx={ctx} />
}

export default CategorySelectChart
