import React, { useState } from 'react'
import SelectTechStack from 'components/forms/SelectTechStack'
import { Navigate } from 'react-router-dom'
import { useUserSetup } from 'context/userSetupContext'
import styled from '@emotion/styled'
import { useSetUserStatus } from 'hooks/graphql'
import { useViewer } from 'context/viewerContext'
import { UserStatus } from 'generated/graphql'
import { useCreateProductToUser } from 'data/productToUser/hooks'

function CreatePersonalTechStack() {
  const { viewer } = useViewer()
  const [redirect, setRedirect] = useState(false)
  const { amplitude } = useUserSetup()

  const createProductToUser = useCreateProductToUser()
  const { setUserStatus } = useSetUserStatus()

  const { personalTechstack } = viewer

  const userProductIds =
    personalTechstack.length > 0 ? personalTechstack.map((product) => product.id) : []

  const onSubmit = async (selectedProductIds: number[]) => {
    for (const productId of selectedProductIds) {
      await createProductToUser.mutateAsync(productId)
      setUserStatus(UserStatus.PersonalPayment)
    }
    amplitude?.logEventWithSavedProps('set_personal_techstack', {
      action: 'update_information',
      organization_type: 'PERSONAL',
    })
    setRedirect(true)
  }

  if (redirect) return <Navigate to="/setup/personal-payment" />

  return (
    <Root>
      <Wrapper>
        <SelectTechStack initialSelectedAppIds={userProductIds} onSubmit={onSubmit} />
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  min-height: 100vh;
  padding: 0 20px;
`

const Wrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 100px;
`

export default CreatePersonalTechStack
