import styled from '@emotion/styled'
import { useViewer } from 'context/viewerContext'
import { OrganizationType } from 'generated/graphql'
import OrgBlock from './OrgBlock'

const OrgBlocks = () => {
  const { viewer } = useViewer()

  const organizations = viewer.organizations.filter(({ type }) => OrganizationType.Company !== type)

  return (
    <Root>
      {organizations.map(({ id, name, type, role, details }) => (
        <OrgBlock
          key={`OrgBlock--${id}`}
          id={id}
          name={name}
          type={type}
          role={role}
          logo={details.logo}
        />
      ))}
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  margin: 0 auto;
  background: white;
  box-shadow: 0px 3px 15px #0000001a;
  margin-bottom: 28px;
`

export default OrgBlocks
