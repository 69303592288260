import styled from '@emotion/styled'
import { Button } from 'components/theme'
import { TextInput } from 'components/theme/formField'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios from 'axios'
import { isUsingProductionServer } from 'services/helper'
import PurpleLogoPng from 'assets/purple-logo.png'
import LineDividerSvg from 'assets/networks/line-divider.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { BLACK_LIST_EMAIL_DOMAINS } from 'services/blackListEmailDomains'

const getApi = (isLocal = false) => {
  if (process.env.NODE_ENV !== 'development' && isUsingProductionServer) {
    return 'https://m0qbfdtry8.execute-api.us-west-2.amazonaws.com/dev'
  }

  if (isLocal) {
    return 'http://localhost:3001'
  }

  return 'https://qrr3vzquxi.execute-api.us-west-2.amazonaws.com/staging'
}

interface Values {
  email: string
}

const schema = yup
  .object({
    email: yup
      .string()
      .required()
      .lowercase()
      .email(
        'The email address must match a valid company email address. Please try another email.'
      )
      .test(
        'is-not-blacklist',
        'The email address must match a valid company email address. Please try another email.',
        (value) => {
          if (value) {
            const currentDomain = value.substring(value.indexOf('@') + 1, value.indexOf('.'))

            return !BLACK_LIST_EMAIL_DOMAINS.includes(currentDomain)
          }
        }
      ),
  })
  .required()

const Signup = ({ auth0OrgId, logo }: { auth0OrgId: string; logo: string }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<Values>({ resolver: yupResolver(schema) })
  const { loginWithRedirect } = useAuth0()
  const navigate = useNavigate()

  const onSubmit = handleSubmit(async ({ email }) => {
    const result = await axios({
      method: 'post',
      url: `${getApi()}/auth0/users-org-status`,
      data: { email, auth0OrgId },
    })

    if (result.data.isPartOfOrg) {
      setError('email', {
        type: 'existingEmail',
        message: 'Sign up invalid. User may already exist. Try logging in.',
      })
      return
    }

    await axios({
      method: 'post',
      url: `${getApi()}/auth0/org-invite`,
      data: { email, auth0OrgId, networkName: 'CLOC' },
    })

    navigate('../email-sent', { state: { email } })
  })

  return (
    <Root>
      <LogoConnect>
        <TheoremLogo src={PurpleLogoPng} />
        <LineImage src={LineDividerSvg} />
        <Logo src={logo} />
      </LogoConnect>
      <Title>Sign up to Theorem to access CLOC</Title>
      <Form onSubmit={onSubmit}>
        <FormField>
          <Label>Work Email:</Label>
          <LargeTextInput
            {...register('email')}
            placeholder="name@work-email.com"
            aria-invalid={errors.email ? 'true' : 'false'}
          />
          {errors.email?.message && errors.email?.type !== 'existingEmail' && (
            <Error>{errors.email.message}</Error>
          )}
          {errors.email?.type && errors.email?.type === 'existingEmail' && (
            <Error>
              Sign up invalid. User may already exist.{' '}
              <SmallLoginButton onClick={() => loginWithRedirect({ organization: auth0OrgId })}>
                Try logging in
              </SmallLoginButton>
              .
            </Error>
          )}
        </FormField>

        <SubmitButton
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting || isSubmitSuccessful}
          isSuccess={isSubmitSuccessful}
        >
          Submit
        </SubmitButton>
      </Form>
      <OrLine>
        <OrText>OR</OrText>
      </OrLine>
      <ContinueWithClocButton onClick={() => loginWithRedirect({ organization: auth0OrgId })}>
        <SmallLogo src={logo} />
        Continue with CLOC
      </ContinueWithClocButton>
      <NotLoggedInWrapper>
        <OnTheorem>Already on Theorem?</OnTheorem>
        <SignInLink onClick={() => loginWithRedirect({ organization: auth0OrgId })}>
          Sign in to an existing account
        </SignInLink>
      </NotLoggedInWrapper>
    </Root>
  )
}

const SmallLogo = styled.img`
  max-width: 20px;
  position: absolute;
  top: 50%;
  left: 26px;
  transform: translateX(-50%) translateY(-50%);
`

const ContinueWithClocButton = styled.button`
  position: relative;
  border: 1px solid #c9cace;
  height: 52px;
  padding: 0 8px 0 52px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  background: white;
  transition: box-shadow 250ms;

  &:hover {
    box-shadow: inset 0 0 0 150px rgb(0 0 0 / 10%);
  }
`

const OrLine = styled.div`
  border-bottom: 1px solid #c2c8d0;
  width: 100%;
  max-width: 500px;
  margin: 8px auto 28px;
  text-align: center;
`

const OrText = styled.div`
  display: inline-block;
  background: white;
  transform: translateY(50%);
  padding: 0 8px;
`

const NotLoggedInWrapper = styled.div`
  margin: 20px auto 0;
  text-align: center;
  font-weight: 500;
`

const SubmitButton = styled(Button)`
  font-size: 18px;
  width: 100%;
`

const SignInLink = styled.span`
  color: #4183c4;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: #1e70bf;
    text-decoration: underline;
  }
`

const SmallLoginButton = styled.span`
  color: #4183c4;
  cursor: pointer;

  &:hover {
    color: #1e70bf;
    text-decoration: underline;
  }
`

const LargeTextInput = styled(TextInput)`
  font-size: 18px;
  padding: 12px 20px;
`

const OnTheorem = styled.div`
  margin-top: 20px;
  font-size: 18px;
  margin-bottom: 8px;
`

const Label = styled.div`
  display: flex;
  color: #616161;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
`

const Form = styled.form`
  width: 100%;
  max-width: 500px;
`

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`

const FormField = styled.div`
  margin-bottom: 20px;
`

const Error = styled.div`
  color: ${({ theme }) => theme.color.red};
  font-weight: 500;
  margin-top: 8px;
  border: 1px solid #ff1744;
  border-radius: 4px;
  padding: 8px 20px;
  background: #ff17440b;
`

const Title = styled.h2`
  font-weight: bold;
  font-size: 36px;
  padding-bottom: 20px;
`

const LogoConnect = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const TheoremLogo = styled.img`
  max-height: 40px;
  width: auto;
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
`

const LineImage = styled.img`
  width: 50px;
`

const Logo = styled.img`
  position: absolute;
  height: 40px;
  width: auto;
  left: 70px;
  top: 50%;
  transform: translateY(-50%);
`

export default Signup
