import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Formik, Form } from 'formik'
import { FormField, Label, TextInput } from 'components/theme/form'
import { Button } from 'components/theme'
import * as yup from 'yup'
import { Dropdown } from 'semantic-ui-react'
import { useAllProducts } from 'data/hooks'

type SelectOption = {
  key: number
  text: string
  value: string | number
}

interface Props {
  initialValues: Values
  onSubmit: (values: Values) => void
  uniqueProductOptions?: SelectOption[]
  isVendorToVendor?: boolean
}

type Values = {
  selectedProduct: string | number
  firstName: string
  lastName: string
  email: string
  companyName: string
  jobTitle: string
  phone: string
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  companyName: yup.string().required('Required'),
})

function StripePlanDetails({
  initialValues,
  uniqueProductOptions,
  isVendorToVendor = false,
  onSubmit,
}: Props) {
  const { products, isLoading: productsLoading } = useAllProducts()
  const [productOptions, setProductOptions] = useState(uniqueProductOptions || [])

  useEffect(() => {
    if (productsLoading || uniqueProductOptions) return
    const fixedProductOptions = products.map((product: any) => ({
      key: product.id,
      text: product.name,
      value: product.id,
    }))

    setProductOptions(fixedProductOptions)
  }, [productsLoading])

  return (
    <Root>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            {isVendorToVendor && (
              <FormField>
                <Label>Select Product for Assessment</Label>
                <Dropdown
                  selection
                  fluid
                  search
                  name="selectedProduct"
                  placeholder="Select Product for assessment"
                  label="Select Product for assessment"
                  onChange={(_e, { value }) => setFieldValue('selectedProduct', value)}
                  options={productOptions}
                  value={values.selectedProduct}
                />
              </FormField>
            )}
            <FormField>
              <TextInput name="firstName" label="First Name" placeholder="" required />
            </FormField>
            <FormField>
              <TextInput name="lastName" label="Last Name" placeholder="" required />
            </FormField>
            <FormField>
              <TextInput name="email" label="Email" placeholder="" required />
            </FormField>
            <FormField>
              <TextInput name="companyName" label="Company Name" placeholder="" required />
            </FormField>
            <FormField>
              <TextInput name="jobTitle" label="Job Title" placeholder="" required />
            </FormField>
            <FormField>
              <TextInput name="phone" label="Business Phone" placeholder="" required />
            </FormField>
            <ButtonContainer>
              <Button disabled={isSubmitting || !isValid} loading={isSubmitting} type="submit">
                Next
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

const Root = styled.div``

const ButtonContainer = styled.div`
  margin-top: 40px;
`

export default StripePlanDetails
