import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'

interface Variables {
  termIds: number[]
  testDriveId: number
}
export const useDeleteTermsToTestDrive = () => {
  const request = useGql()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      const { termIds, testDriveId } = variables
      await request(DELETE_TERMS_TO_TEST_DRIVE, { termIds, testDriveId })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['testDrive']),
    }
  )
}

const DELETE_TERMS_TO_TEST_DRIVE = gql`
  mutation DeleteTermsToTestDrive($termIds: [Int!]!, $testDriveId: Int!) {
    deleteTermsToTestDrive(input: { termIds: $termIds, testDriveId: $testDriveId }) {
      clientMutationId
    }
  }
`
