import styled from '@emotion/styled'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'components/theme'
import { logEvent } from '@theoremlts/theorem-design'

const BlockedEmail = () => {
  const { logout } = useAuth0()

  return (
    <Root>
      <LogoutButton
        onClick={() => {
          logEvent('log_out')
          logout()
        }}
      >
        Logout
      </LogoutButton>
      <Box>
        <Title>We’re Sorry, Something went wrong ✋</Title>
        <Text>
          Our system flagged your login, this could be for one or more reasons, please contact us at{' '}
          <a href="mailto: support@theoremlegal.com">support@theoremlegal.com</a>.
        </Text>
        <SmallBox>
          👋 Or, click here to request access
          <a href="https://share.hsforms.com/1pylb9NU7Re6M_C6scaUaIQ3p9i3">
            <Join>Request Access</Join>
          </a>
        </SmallBox>
      </Box>
    </Root>
  )
}

const Join = styled.button`
  color: white;
  background: #9013fe;
  border-radius: 20px;
  border: none;
  font-weight: 600;
  padding: 8px 12px;
  line-height: 1;
  letter-spacing: 1px;
  font-size: 12px;
  cursor: pointer;
`

const SmallBox = styled.div`
  background-color: #f8f6fe;
  font-size: 18px;
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 15px;
  align-items: center;
`

const Text = styled.p`
  font-size: 16px;
  color: #6f717d;
  margin-top: 20px;
  width: 550px;
  text-align: center;
  line-height: 24px;
  margin-bottom: 24px;
`

const Root = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efefef;
  position: relative;
`

const Box = styled.div`
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  border-radius: 6px;
  text-align: center;
`

const LogoutButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
`

export default BlockedEmail
