import { useEffect } from 'react'
import { useTrackingChartContext } from 'context/trackingChartContext'
import { useChartJs } from 'hooks/useChartJs'
import { interpolateColors, rgbToHex } from 'services/color'
import ChartCard from 'components/charts/ChartCard'
import _ from 'lodash'
import { useLazyApi } from 'hooks/useApi'

function ProductPageHitChart() {
  const {
    startDate,
    endDate,
    currentLoadingQueue,
    setCurrentLoadingQueue,
    productToAnalyze: product,
  } = useTrackingChartContext()
  const [getProductPageHit] = useLazyApi('tracking/amplitude/product-page-hit')
  const { ctx, setChartData } = useChartJs({ type: 'bar', options })

  useEffect(() => {
    const init = async () => {
      const result = await getProductPageHit({ startDate, endDate, id: product })
      const series = _.get(result, 'series[0]', [])
      const barCount = series.length
      const colors =
        barCount > 1
          ? interpolateColors('rgb(206,147,216)', 'rgb(105,27,153)', barCount)
          : ['#8477E1']
      const hexColors = barCount > 1 ? colors.map(([r, g, b]) => rgbToHex(r, g, b)) : colors
      const datasets = [
        {
          label: 'Page Views',
          data: series,
          borderWidth: 1,
          backgroundColor: hexColors,
        },
      ]

      setChartData({ datasets, labels: result.xValues })
      setCurrentLoadingQueue((prevVal: any) => prevVal + 1)
    }

    if (currentLoadingQueue === 3) {
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentLoadingQueue, product.id])

  return <ChartCard title="Page Views" ctx={ctx} />
}

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

export default ProductPageHitChart
