import styled from '@emotion/styled'

interface Props {
  title: string
  blocks: {
    image: string
    title: string
    content: string
  }[]
}
const ThreeBlocks = ({ title, blocks }: Props) => {
  return (
    <Root>
      <Title>{title}</Title>
      <Row>
        {blocks.map(({ image, title, content }) => (
          <Column>
            <Block>
              <Image src={image} />
              <SmallTitle>{title}</SmallTitle>
              <Text>{content}</Text>
            </Block>
          </Column>
        ))}
      </Row>
    </Root>
  )
}

const Root = styled.div`
  padding: 60px 0;
  background: #f4f4f4;
`

const Title = styled.h2`
  font-size: 32px;
  text-align: center;
  margin-bottom: 36px;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 0 auto;
`

const Column = styled.div`
  width: 33.3333%;
  padding: 0 20px;

  ${({ theme }) => theme.breakpoint.mobile} {
    width: 100%;
  }
`

const Block = styled.div`
  max-width: 300px;
  margin: 0 auto;
`

const Image = styled.img``

const SmallTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
  text-align: center;
`

const Text = styled.p`
  font-size: 16px;
  text-align: center;
`

export default ThreeBlocks
