import styled from '@emotion/styled'
import { useViewer } from 'context/viewerContext'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import MenuItem from './MenuItem'
import MenuOutLink from './MenuOutLink'
import SecondaryMenu from './SecondaryMenu'
import { Icon } from 'components/theme'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
import MarketplaceMenu from './MarketplaceMenu'

interface Props {
  closeMobileMenu: () => void
}
const AssociationMenu = ({ closeMobileMenu }: Props) => {
  const baseOrgUrl = useBaseOrgUrl()
  const { isAdmin } = useViewer()
  const marketplaceUrl = useMarketplaceUrl()

  const techstackSecondaryMenu = [
    {
      title: 'My Stack (Personal)',
      link: `${baseOrgUrl}/edit-techstack`,
      // link: `${marketplaceUrl}returnTo=/stackshare/network/${organization?.slug}`,
      // isOutLink: true,
    },
    {
      title: 'StackShare (Community)',
      link: `${baseOrgUrl}/your-apps`,
    },
  ]

  return (
    <Root>
      <MenuLi>
        <MenuItem to={`${baseOrgUrl}/your-apps`} onClick={closeMobileMenu}>
          Tech Stacks <DownCaret name="caret-down" />
        </MenuItem>
        <SecondaryMenu closeMobileMenu={closeMobileMenu} pages={techstackSecondaryMenu} />
      </MenuLi>

      <MenuLi>
        <MenuItem to={`${baseOrgUrl}/rfp`} onClick={closeMobileMenu}>
          Solutions <DownCaret name="caret-down" />
        </MenuItem>
        <SecondaryMenu
          closeMobileMenu={closeMobileMenu}
          pages={[{ title: 'RFP', link: `${baseOrgUrl}/rfp` }]}
        />
      </MenuLi>

      <MenuLi>
        <MenuOutLink href={marketplaceUrl} onClick={closeMobileMenu}>
          Marketplace <DownCaret name="caret-down" />
        </MenuOutLink>
        <MarketplaceMenu />
      </MenuLi>

      {isAdmin && (
        <MenuLi>
          <MenuItem to={`${baseOrgUrl}/admin`} onClick={closeMobileMenu}>
            Admin <DownCaret name="caret-down" />
          </MenuItem>
          <SecondaryMenu
            closeMobileMenu={closeMobileMenu}
            pages={[{ title: 'Edit Directory Landing Page', link: `${baseOrgUrl}/edit-home` }]}
          />
        </MenuLi>
      )}
    </Root>
  )
}

const Root = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: block;
    width: 100%;
  }
`

const DownCaret = styled(Icon)`
  font-size: 16px;
  margin-left: 4px;
`

const MenuLi = styled.li`
  position: relative;

  &:hover {
    & > a:after {
      transform: scaleY(1);
    }

    & > div:first-of-type {
      display: block;
    }
  }

  ${({ theme }) => theme.breakpoint.mobile} {
    &:first-of-type a {
      border-top: 0;
    }
  }
`

export default AssociationMenu
