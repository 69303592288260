import React, { ReactNode, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import { breakpoint } from 'services/theming'
import { Icon } from './theme'

export const useModal = (initialShow = false) => {
  const [showModal, setShowModal] = useState(initialShow)

  function toggle() {
    setShowModal((prevShowModal) => !prevShowModal)
  }

  return {
    showModal,
    toggle,
  }
}

interface Props {
  children: ReactNode
  isShowing: boolean
  toggleModal: () => void
  width?: string
  showClose?: boolean
  removePadding?: boolean
  boxStyles?: any
  outerWrapperClose?: boolean
}
const Modal = ({
  isShowing = false,
  toggleModal,
  width = '500px',
  children,
  showClose = true,
  removePadding = false,
  boxStyles = '',
  outerWrapperClose = true,
}: Props) => {
  return isShowing
    ? ReactDOM.createPortal(
        <Root onClick={() => outerWrapperClose && toggleModal()}>
          <ContentWrapper
            onClick={(e) => e.stopPropagation()}
            width={width}
            removePadding={removePadding}
            boxStyles={boxStyles}
          >
            {showClose && <CloseButton onClick={toggleModal} name="times" />}
            {children}
          </ContentWrapper>
        </Root>,
        document.body
      )
    : null
}

const Root = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const ContentWrapper = styled.div<{ removePadding: boolean; width: string; boxStyles: any }>`
  position: relative;
  background: white;
  border-radius: 5px;
  padding: ${({ removePadding }) => (removePadding ? '0' : '30px')};
  width: ${(prop) => prop.width};
  max-width: 100vw;
  max-height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${breakpoint.mobile} {
    padding: 0 0 60px;
  }

  ${({ boxStyles }) => boxStyles && boxStyles};
`

const CloseButton = styled(Icon)`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  font-size: 18px;
  z-index: 1;
`

export default Modal
