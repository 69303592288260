import styled from '@emotion/styled'
import CompanyForm from 'components/forms/CompanyForm'
import { useOrg } from 'data/hooks'
import { toast } from 'react-toastify'

const CompanySettingsForm = () => {
  const { organization } = useOrg()

  const onSubmit = () => {
    toast.success('Lawfirm details updated')
  }

  const initialValues = {
    name: organization?.name || '',
    logo: organization?.details?.logo || [],
    website: organization?.details?.website || '',
    companySize: organization?.details?.companySize || '',
  }

  return (
    <Root>
      <FormContainer>
        <CompanyForm onComplete={onSubmit} initialValues={initialValues} />
      </FormContainer>
    </Root>
  )
}

const Root = styled.div``

const FormContainer = styled.div`
  max-width: 760px;
`

export default CompanySettingsForm
