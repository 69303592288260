import { useState } from 'react'
import { useViewer } from 'context/viewerContext'
import { useMutatePersonalTechstack } from './useMutatePersonalTechstack'

export const usePersonalTechstack = () => {
  const mutatePersonalTechstack = useMutatePersonalTechstack()
  const [loading, setLoading] = useState(false)
  const { viewer } = useViewer()
  const userProductIds: number[] = viewer.personalTechstack.map(({ id }) => id)

  const onSubmit = async (selectedProductIds: number[]) => {
    if (loading) return
    setLoading(true)

    await mutatePersonalTechstack.mutateAsync({
      productIds: selectedProductIds,
      initialProductIds: userProductIds,
    })

    setLoading(false)
  }

  return { onSubmit, loading, userProductIds }
}
