import { useViewer } from 'context/viewerContext'
import { useOrg } from 'data/hooks'
import { UserRole } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface Variables {
  role: UserRole
  userId?: number
}
export const useUpdateUserRole = () => {
  const request = useGql()
  const { viewer } = useViewer()
  const { organization } = useOrg()
  const queryClient = useQueryClient()

  return useMutation(
    async (variables: Variables) => {
      if (!organization) return

      const { role, userId } = variables

      await request(UPDATE_USER_ROLE, {
        userId: userId || viewer.id,
        organizationId: organization.id,
        role,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['viewer']),
    }
  )
}

const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($userId: Int!, $organizationId: UUID!, $role: UserRole) {
    updateUsersToOrganization(
      input: { patch: { role: $role }, userId: $userId, organizationId: $organizationId }
    ) {
      clientMutationId
    }
  }
`
