import { useOrg } from 'data/organization/hooks'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'

export const useTeam = (first = 15, offset = 0) => {
  const request = useGql()
  const { organization } = useOrg()

  const { data, ...other } = useQuery(
    ['team', first, offset],
    async () => {
      if (!organization) return

      const response = await request(GET_TEAM, {
        first,
        offset,
        organizationId: organization.id,
      })

      return response
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  )

  return { team: data, ...other }
}

export const TEAM_FRAGMENT = gql`
  fragment TeamFragment on UsersToOrganization {
    jobTitle
    role
    status
    userId
    user {
      id
      email
      details
      first
      last
      updatedAt
    }
    createdAt
    updatedAt
  }
`

const GET_TEAM = gql`
  ${TEAM_FRAGMENT}
  query GetTeam($first: Int, $offset: Int, $organizationId: UUID!) {
    usersToOrganizationsConnection(
      first: $first
      offset: $offset
      condition: { organizationId: $organizationId }
    ) {
      totalCount
      nodes {
        ...TeamFragment
      }
    }
  }
`
