import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router'
import ProductSvg from './product.svg'
import ServiceSvg from './service.svg'
import CompanySvg from './company.svg'
import { useListingStepper } from '../ListingStepperContext'
import ListingFaq from './ListingFaq'
import { useOrg } from 'data/hooks'
import { Product } from 'data/types'
import { OrganizationType } from 'generated/graphql'
import { useWizardModal } from 'components/modals/WizardModal'

const ListingType = () => {
  const navigate = useNavigate()
  const { slug } = useParams()
  const { productListings, orgListings } = useListingStepper()
  const { vendor, lawfirm, organization } = useOrg()
  const { goNextStep, addData } = useWizardModal()

  const hasOrgListing = orgListings?.some(({ association }) => slug === association.slug)

  const products = vendor ? vendor.products : lawfirm?.products

  const options: Product[] | undefined = products?.filter(
    ({ id }) =>
      !productListings?.some(
        ({ product, association }) => product.id === id && association.slug === slug
      )
  )

  const listingTypeClick = (listingType: string) => () => {
    addData({ listingType })
    goNextStep()
  }

  return (
    <Root>
      <Title>Select a Listing Type</Title>
      <ViewPricingWrapper>
        {slug === 'acc' && (
          <a href="https://www.partnerdirectory.acc.com/pricing" target="_blank">
            View pricing for ACC
          </a>
        )}
        {slug === 'cloc' && (
          <a href="https://www.theoremlegal.com/cloc-pricing" target="_blank">
            View pricing for CLOC
          </a>
        )}
      </ViewPricingWrapper>
      <CardsWrapper>
        {!hasOrgListing && (
          <Card
            onClick={listingTypeClick(
              organization?.type === OrganizationType.Lawfirm ? 'lawfirm' : 'enterprise'
            )}
          >
            <Logo src={CompanySvg} />
            <ContentWrapper>
              <BoxTitle>
                {organization?.type === OrganizationType.Lawfirm ? 'Lawfirm' : 'Enterprise'}
              </BoxTitle>
              <Content>
                {organization?.type === OrganizationType.Vendor
                  ? 'For all your solutions'
                  : 'Feature your business'}
              </Content>
            </ContentWrapper>
          </Card>
        )}
        <Card onClick={listingTypeClick('product')}>
          <Logo src={ProductSvg} />
          <ContentWrapper>
            <BoxTitle>Product Listing</BoxTitle>
            <Content>Showcase your product</Content>
            {hasOrgListing && <FreeText>Included in Plan</FreeText>}
          </ContentWrapper>
        </Card>
        {organization?.type !== OrganizationType.Lawfirm && (
          <Card onClick={listingTypeClick('service')}>
            <Logo src={ServiceSvg} />
            <ContentWrapper>
              <BoxTitle>Service Listing</BoxTitle>
              <Content>Professional Services</Content>
              {hasOrgListing && <FreeText>Included in Plan</FreeText>}
            </ContentWrapper>
          </Card>
        )}
      </CardsWrapper>
      <ListingFaq />
    </Root>
  )
}

const Root = styled.div``

const ViewPricingWrapper = styled.div`
  text-align: center;
  margin-bottom: 36px;

  a {
    font-weight: bold;
    font-size: 16px;
  }
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 4px;
`

const CardsWrapper = styled.div`
  margin: 0 -16px 32px;
  margin: 0 auto;
  max-width: 900px;
  padding-bottom: 40px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  padding-left: 12px;
`

const Content = styled.div`
  font-size: 16px;
  margin-top: 4px;
`

const Card = styled.div`
  position: relative;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 6px;
  align-items: center;
  padding: 12px 20px;
  transition: background 250ms;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    background: #edf4fd;
  }
`

const Logo = styled.img`
  width: 50px;
  height: 50px;
`

const BoxTitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
`

const FreeText = styled.div`
  font-weight: 600;
  color: #4fbd03;
  font-size: 16px;
  margin: 8px 0 4px;
  letter-spacing: 0.02rem;
`

export default ListingType
