import { useEffect } from 'react'
import { FullPageLoading } from 'components/PulsatingLoader'
import { useNavigate } from 'react-router-dom'
import { useViewer } from 'context/viewerContext'
import { useMutateViewer } from 'data/viewer/useMutateViewer'

function SuccessfulCheckout() {
  const { viewer } = useViewer()
  const mutateViewer = useMutateViewer()
  const navigate = useNavigate()

  useEffect(() => {
    const init = async () => {
      await mutateViewer.mutateAsync({
        details: {
          paymentStatus: 'PAID',
        },
      })

      navigate('/your-apps', { replace: true })
    }

    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('session_id')) {
      init()
    } else {
      console.log('no session id')
    }
  }, [history, viewer.details, viewer.id])

  return <FullPageLoading />
}

export default SuccessfulCheckout
