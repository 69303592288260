import React from 'react'
import styled from '@emotion/styled'

interface Props {
  title: string
  content: string
  price: string
  image: string
  onClick: () => void
}

const PrevalentProductBox: React.FC<Props> = ({ title, content, price, image, onClick }) => {
  return (
    <Root onClick={onClick}>
      <Box>
        <Image src={image} alt="Prevalent Logo" title="Prevalent Logo" />
        <Title>{title}</Title>
        <Content>{content}</Content>
        <PriceWrapper>
          <Price>${price}</Price>
        </PriceWrapper>
      </Box>
    </Root>
  )
}

const Root = styled.div`
  width: 33.3333%;
  padding: 16px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px;
  padding: 20px;
  height: 100%;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border 250ms;

  &:hover {
    border: 2px solid rgb(76, 208, 209);
  }
`

const Title = styled.h3`
  font-size: 22px;
`

const Content = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: 0.02rem;
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  line-height: 1;
  margin-top: auto;
`

const Price = styled.div`
  font-size: 20px;
  font-weight: 600;
`

const Image = styled.img`
  width: 75px;
`

export default PrevalentProductBox
