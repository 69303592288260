import { UserRole, MemberStatus, OrganizationType } from 'generated/graphql'
import { ViewerToOrganization } from './viewerTypes'

interface GqlUsersToOrganization {
  userId: number
  organizationId: any
  role: UserRole
  jobTitle?: number
  status: MemberStatus
  createdAt: any
  updatedAt: any
  organization?: {
    nodeId: string
    id: any
    slug: string
    details: any
    name: string
    type: OrganizationType
    domain?: string
    updatedAt: any
    createdAt: any
    testMode: boolean
    auth0OrgId?: string
    usersToOrganizations: Array<{
      status: MemberStatus
      role: UserRole
    }>
  }
}
export const parseViewerToOrg = (
  usersToOrganizations: GqlUsersToOrganization[]
): ViewerToOrganization[] => {
  return usersToOrganizations
    .map(({ organization, role, jobTitle, status }) => {
      if (!organization) return
      const { id, name, slug, type, details, usersToOrganizations, createdAt, updatedAt } =
        organization

      return {
        id,
        name,
        slug,
        type,
        details,
        createdAt,
        updatedAt,
        status,
        // User details
        role,
        jobTitle,
        memberCount: usersToOrganizations.reduce(
          (total, member) =>
            member.status === 'ACTIVE' && member.role !== 'SUPER_ADMIN' ? total + 1 : total,
          0
        ),
      }
    })
    .filter(
      (viewerToOrg: ViewerToOrganization | undefined): viewerToOrg is ViewerToOrganization =>
        !!viewerToOrg
    ) as ViewerToOrganization[]
}
