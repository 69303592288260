import React from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import styled from '@emotion/styled'
import { CheckoutValues, Coupon } from 'interfaces'
import { Formik, Form, FormikHelpers } from 'formik'
import CardInputs from './CardInputs'
import CountrySelect from './CountrySelect'
import QuantityInput from './QuantityInput'
import TextInput from './TextInput'
import CouponInput from './CouponInput'
import { fixCurrencyDisplay } from 'services/helper'
import { Icon } from 'components/theme'
import moment from 'moment'
import * as yup from 'yup'
import { PRIVACY_POLICY, SUBSCRIPTION_AGREEMENT, TERMS_AND_CONDITIONS } from 'services/globalAssets'

type Props = {
  accountId?: string
  price: number
  interval: string
  initialValues?: CheckoutValues
  onSubmit: (values: CheckoutValues, formikBag: FormikHelpers<CheckoutValues>) => Promise<void>
  onConfirmCoupon: (coupon: Coupon) => void
  onQuantityChange: (quantity: number) => void
  legalComponent?: React.ReactNode
  freeTrialDays?: number
  allowQuantityField: boolean
}

const defaultValues = {
  country: 'US',
  name: '',
  quantity: 1,
  isCardApproved: false,
  freeTrialDays: 0,
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
  country: yup.string().required('Required'),
  isCardApproved: yup.boolean().oneOf([true]),
})

function CheckoutForm({
  accountId,
  price,
  interval,
  freeTrialDays,
  onSubmit,
  onQuantityChange,
  onConfirmCoupon,
  allowQuantityField,
  legalComponent,
  initialValues = defaultValues,
}: Props) {
  const stripe = useStripe()

  return (
    <Formik
      initialValues={{ ...initialValues, freeTrialDays: freeTrialDays || 0 }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Wrapper>
            <Header>
              <HeaderText>Purchase with card</HeaderText>
            </Header>
            {allowQuantityField && (
              <QuantityInput name="quantity" onQuantityChange={onQuantityChange} />
            )}
            <TextInput label="Name on the card" name="name" />
            <CardInputs />
            <CountrySelect name="country" />
            <CouponInput accountId={accountId} onConfirmCoupon={onConfirmCoupon} name="coupon" />
            <LegalText>
              {legalComponent ? (
                legalComponent
              ) : (
                <>
                  By confirming your new plan, you agree to Theorem Legal Technology Services Inc.{' '}
                  <a href={SUBSCRIPTION_AGREEMENT} target="_blank" rel="noopener noreferrer">
                    <strong>Subscription Agreement</strong>
                  </a>
                  ,{' '}
                  <a href={TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">
                    <strong>Terms of Service</strong>
                  </a>
                  , and{' '}
                  <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                    <strong>Privacy Policy</strong>
                  </a>
                  .
                </>
              )}
            </LegalText>
            <ConfirmButton
              type="submit"
              status={status}
              disabled={!stripe || isSubmitting || !isValid || status === 'success'}
            >
              {isSubmitting ? (
                <Icon name="spinner" spin pulse />
              ) : status === 'success' ? (
                <SuccessIcon name="check-circle" />
              ) : freeTrialDays ? (
                'Start trial'
              ) : (
                'Pay'
              )}
            </ConfirmButton>
            {freeTrialDays && (
              <FreeTrialText>
                If you don&rsquo;t cancel your trial, you will be charged{' '}
                {fixCurrencyDisplay(price)} per {interval}, starting{' '}
                {moment().add(freeTrialDays, 'days').format('MMMM Do YYYY')}.
              </FreeTrialText>
            )}
          </Wrapper>
        </Form>
      )}
    </Formik>
  )
}

const Wrapper = styled.div`
  max-width: 500px;
  padding-left: 60px;
`

const LegalText = styled.div`
  margin-top: 20px;
  color: #4c4d50;
  font-size: 13px;
  line-height: 1.6;
  letter-spacing: 0.15px;

  a {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
  }
`

const Header = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 20px;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
  }
`

const FreeTrialText = styled.div`
  font-size: 14px;
  margin-top: 12px;
  line-height: 1.5;
  text-align: center;
  color: #5a5a58;
  letter-spacing: 0.01rem;
`

const HeaderText = styled.span`
  display: inline-block;
  padding: 0 12px;
  position: relative;
  z-index: 1;
  background: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02rem;
`

const ConfirmButton = styled.button<{ status: string | undefined }>`
  width: 100%;
  display: block;
  margin-top: 20px;
  border-radius: 6px;
  border: none;
  font-size: 18px;
  height: 44px;
  box-shadow: inset 0 0 0 1px rgb(50 50 93 / 10%), 0 2px 5px 0 rgb(50 50 93 / 10%),
    0 1px 1px 0 rgb(0 0 0 / 7%);
  background: #9d43d4;
  color: white;
  transition: background 250ms, color 250ms;
  letter-spacing: 0.02rem;

  &:hover {
    background: #8930bf;
  }

  &:disabled,
  &[disabled] {
    background: #a287b3;
    color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed;

    &:hover {
      background: #a287b3;
    }
  }

  ${({ status }) =>
    status === 'success' &&
    `
     background: #41c300 !important;
     color: white !important;
     `}
`

const SuccessIcon = styled(Icon)`
  color: white;
  width: 28px;
  padding-top: 4px;
`

export default CheckoutForm
