import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { BoxWrapper, Button, Icon } from 'components/theme'
import {
  useAppMetadata,
  useListings,
  useMutateViewer,
  useOrg,
  useUpdateShowOrgListing,
  useUpdateShowProductListing,
} from 'data/hooks'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useListingStepper } from '../ListingStepperContext'
// import CompanyListings from './CompanyListings'
import Faqs from './Faqs'
import ListingGetStarted from './ListingGetStarted'
import MainNetworks from './MainNetworks'
import ListingOfferModal from 'components/modals/ListingOfferModal'
import Switch from 'components/theme/formField/Switch'
import StatusPill from './StatusPill'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { Popup } from 'semantic-ui-react'
import { useMarketplaceUrl } from 'hooks/useMarketplaceUrl'
import { Link } from 'react-router-dom'
import UpsellTheoremPro from './UpsellTheoremPro'
import ListingWizard from '../ListingWizard'
import { useGetUnlistedProducts } from './useGetUnlistedProducts'
import TheoremProListingWizard from '../TheoremProListingWizard'
import { useViewer } from 'context/viewerContext'

interface Listing {
  id: number | string
  slug: string
  network: string
  networkSlug: string
  networkId: string
  name: string
  listingType: string
  listingId?: number | string
  type: string
  status: string
  show: boolean
}

const CurrentListings = () => {
  const { organization } = useOrg()
  const { data: appMetadata } = useAppMetadata()
  const { paymentStatus } = useViewer()
  const mutateViewer = useMutateViewer()
  const [showOfferModal, setShowOfferModal] = useState(!appMetadata.hideListingOfferModal)
  const { productListings, organizationListings, isLoading } = useListings()
  const [listings, setListings] = useState<Listing[]>([])
  const updateShowProductListing = useUpdateShowProductListing()
  const updateShowOrgListing = useUpdateShowOrgListing()
  const marketplaceUrl = useMarketplaceUrl(true)
  const [openModal, setOpenModal] = useState(false)
  const [upgradeProModalData, setUpgradeProModalData] = useState<any>(null)
  const { unlistedProducts } = useGetUnlistedProducts()

  useEffect(() => {
    if (isLoading) return
    let listing: Listing[] = []

    if (organization && organizationListings && organizationListings?.length > 0) {
      const parsedOrganizationListing = organizationListings.map(
        ({ association, status, type, show }) => ({
          id: organization.id,
          name: organization.name,
          slug: organization.slug,
          listingType: 'COMPANY',
          network: association.name,
          networkId: association.id,
          networkSlug: association.slug,
          listingId: organization.id,
          type,
          status,
          show,
        })
      )

      listing = [...listing, ...parsedOrganizationListing]
    }

    if (productListings && productListings?.length > 0) {
      const parsedProductListing = productListings.map(
        ({ id, association, product, type, show, state }) => ({
          id: product.id,
          name: product.name,
          slug: product.slug,
          listingId: id,
          type,
          network: association.name,
          networkId: association.id,
          networkSlug: association.slug,
          listingType: product.type,
          status: state,
          show,
        })
      )

      listing = [...listing, ...parsedProductListing]
    }

    setListings(listing)
  }, [isLoading, JSON.stringify(productListings), JSON.stringify(organizationListings)])

  const hasProductListing = productListings && productListings.length > 0
  // const hasProductListing = false
  // const hasCompanyListing = orgListings && orgListings.length > 0

  const hideModal = () => {
    mutateViewer.mutate({
      appMetadata: {
        hideListingOfferModal: true,
      },
    })
    setShowOfferModal((prevVal) => !prevVal)
  }

  const toggleListingShow =
    (id?: number | string, associationId?: string) => async (isCheck: boolean) => {
      if (typeof id === 'number') {
        await updateShowProductListing.mutateAsync({
          id,
          show: isCheck,
        })
      }

      if (typeof id === 'string' && associationId) {
        await updateShowOrgListing.mutateAsync({
          organizationId: id,
          associationId,
          show: isCheck,
        })
      }
    }

  // if a theorem pro account has been purchased, then add the products without listing as a basic plan
  return (
    <Root>
      <UpsellTheoremPro />
      {openModal && <ListingWizard closeModal={() => setOpenModal(false)} />}
      {!!upgradeProModalData && (
        <TheoremProListingWizard
          {...upgradeProModalData}
          closeModal={() => setUpgradeProModalData(null)}
        />
      )}
      {hasProductListing ? (
        <BoxWrapper>
          <Wrapper>
            {hasProductListing && (
              <>
                <ButtonWrapper>
                  <Title>Listings</Title>
                  <Button onClick={() => setOpenModal(true)}>Create New Listing</Button>
                </ButtonWrapper>
                <Table>
                  <thead>
                    <TopRow>
                      <th>What's Listed?</th>
                      <th>Listing</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Enhance</th>
                      <th>Show</th>
                      <th>Actions</th>
                    </TopRow>
                  </thead>
                  <tbody>
                    {listings?.map(
                      ({
                        id,
                        name,
                        slug,
                        type,
                        listingId,
                        listingType,
                        status,
                        network,
                        networkSlug,
                        networkId,
                        show,
                      }) => (
                        <tr key={`${id}-${networkSlug}`}>
                          <td>
                            <NetworkName>{networkSlug}</NetworkName>
                          </td>
                          <td>{name}</td>
                          <td>{listingType}</td>
                          <td>
                            <StatusPill status={status === 'DRAFT' ? 'draft' : type} />
                          </td>
                          <td>
                            {type === 'premium' ? (
                              <UpgradedText>Upgraded</UpgradedText>
                            ) : (
                              <UpgradeLink
                                onClick={() => {
                                  setUpgradeProModalData({
                                    networkSlug,
                                    listingType: listingType.toLowerCase(),
                                    productId: id,
                                    hasBasicListing: type === 'basic' || type === 'essential',
                                  })
                                }}
                              >
                                Upgrade
                              </UpgradeLink>
                            )}
                          </td>
                          <td>
                            {status !== 'DRAFT' ? (
                              <Switch
                                isCheck={show}
                                onSwitch={toggleListingShow(listingId, networkId)}
                              />
                            ) : (
                              <Switch isCheck={false} disabled={true} />
                            )}
                          </td>
                          <td>
                            <Popup
                              trigger={
                                <IconButton>
                                  <MenuIcon name={faEllipsisVertical} />
                                </IconButton>
                              }
                              position="bottom center"
                              on="click"
                              interactive={true}
                              delayHide={1000}
                            >
                              <Popup.Content>
                                {listingType === 'COMPANY' ? (
                                  <Options>
                                    <a
                                      href={`${marketplaceUrl}returnTo=/admin/edit-company-listing/basic`}
                                    >
                                      Edit
                                    </a>
                                    <a href={`${marketplaceUrl}returnTo=/company/${slug}`}>View</a>
                                  </Options>
                                ) : (
                                  <Options>
                                    <a
                                      href={`${marketplaceUrl}returnTo=/admin/product/${id}/edit/basic`}
                                    >
                                      Edit
                                    </a>
                                    <a
                                      href={`${marketplaceUrl}returnTo=/${networkSlug}/product/${slug}`}
                                    >
                                      View
                                    </a>
                                  </Options>
                                )}
                              </Popup.Content>
                            </Popup>
                          </td>
                        </tr>
                      )
                    )}
                    {paymentStatus === 'ACTIVE' &&
                      unlistedProducts?.map(({ network, products }) =>
                        products.map(({ id, name, type }) => (
                          <tr key={`unlisted-${id}-${network.slug}`}>
                            <td>
                              <NetworkName>{network.slug}</NetworkName>
                            </td>
                            <td>{name}</td>
                            <td>{type}</td>
                            <td>
                              <StatusPill status="pending" />
                            </td>
                            <td>
                              <UpgradeLink
                                onClick={() => {
                                  setUpgradeProModalData({
                                    networkSlug: network.slug,
                                    listingType: 'product',
                                    productId: id,
                                  })
                                }}
                              >
                                Upgrade
                              </UpgradeLink>
                            </td>
                            <td>
                              <Switch isCheck={false} disabled={true} />
                            </td>
                            <td></td>
                          </tr>
                        ))
                      )}
                  </tbody>
                </Table>
              </>
            )}
          </Wrapper>
        </BoxWrapper>
      ) : (
        <BoxWrapper>
          <ListingGetStarted />
        </BoxWrapper>
      )}
      <MainNetworks />
      <Faqs />
      {showOfferModal && <ListingOfferModal toggleModal={hideModal} />}
    </Root>
  )
}

const NetworkName = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  font-weight: 600;
`

const Wrapper = styled.div`
  margin: 0 auto;
`

const UpgradedText = styled.div`
  color: #2ab100;
  font-weight: 500;
  font-size: 16px;
`

const UpgradeLink = styled.div`
  color: #d89a27;
  text-decoration: underline;
  font-weight: bold;
  letter-spacing: 0.02rem;
  font-size: 16px;
  cursor: pointer;
`

const Title = styled.h3`
  margin-bottom: 8px;
`

const TopRow = styled.tr`
  background: #f5f5f5;

  th {
    white-space: nowrap;
    text-align: left;
    font-weight: 500;
  }
`

const Root = styled.div``

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  border-top: 2px solid #9013fe;
  border-radius: 4px;

  th,
  td {
    padding: 12px 16px;
  }

  th {
    border-bottom: 1px solid #d1d1d1;
  }
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-around;
  margin: -0.833em -1em;

  a {
    padding: 10px 15px;
    border: none;
    border-bottom: 1px solid #ddd;
    background: white;
    transition: background 0.3s;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    min-width: 100px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.02rem;

    &:hover {
      background: #eee;
      color: #4183c4;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: right;
  margin-bottom: 20px;
`

const MenuIcon = styled(Icon)`
  font-size: 18px;
`

const IconButton = styled.div`
  width: 36px;
  height: 40px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  cursor: pointer;
`

export default CurrentListings
