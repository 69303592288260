import { OrganizationType, UserRole } from 'generated/graphql'
import UserStory from './UserStory'

export type Permissions = { edit: boolean; view: boolean }
export type PermissionsMeta = {
  orgId: string
  userId: number
  organizationType: OrganizationType
  userRole?: UserRole
}
export type StoryUpdates = {
  [key: string]: any
  when?: string
  to?: string
  stage?: string[]
  feature?: { type: number; name: string }
  product?: { name: string; id: number }
  firmType?: { id: number; name: string } | number
  practiceGroup?: { id: number; name: string } | number
  role?: { id: number; name: string } | number
}

export type BlankStory = {
  [key: string]: any
  id: 0
  when: string
  to: string
  details: {
    [key: string]: any
  }
  organization?: {
    associationToOrganizations: Array<{
      associationId: string
      status: string
    }>
  }
  organizationId: string
  ownerCreated: boolean
  userByAuthor: {
    [key: string]: any
    id: number
  }
}

export const createBlankStory = (userInfo: PermissionsMeta) =>
  new UserStory(
    {
      id: 0,
      when: '',
      to: '',
      details: {},
      organizationId: userInfo.orgId,
      ownerCreated: false, // NEEDS A PERMISSIONS META UPDATE
      userByAuthor: {
        id: userInfo.userId,
      },
    },
    userInfo
  )
