import { Routes, Route } from 'react-router-dom'

import VendorProfile from 'views/vendors/VendorProfile'
import VendorCheckoutSuccess from 'views/vendors/payment/VendorCheckoutSuccess'
import PaymentSetup from 'views/vendors/payment/TermsAndPayment'
import CreateProductWizard from 'views/vendors/CreateProductWizard'
// import UpgradeSubscription from 'views/vendors/payment/UpgradeSubscription'
import VendorCheckout from 'views/vendors/payment/VendorCheckout'
import StripeConnectAuthRedirect from 'views/vendors/VendorProfile/connect/AuthRedirect'
import StripeConnectSuccess from 'views/vendors/VendorProfile/connect/ConnectSuccess'
import ProceedConfirm from 'views/vendors/ProceedConfirm'
import ProductSettings from 'views/vendors/ProductSettings'
import PrevalentWizard from 'views/vendors/prevalent/PrevalentWizard'
import PrevalentPlanDetails from 'views/vendors/prevalent/PrevalentPlanDetails'
import PrevalentCheckout from 'views/vendors/prevalent/PrevalentCheckout'
import CreateNewProductWizard from 'views/vendors/CreateProductWizard/CreateNewProductWizard'
// import VendorSettings from 'views/vendors/VendorSettings'
import Analytics from 'views/vendors/ProductSettings/ProductOverview/Analytics'
import StripeAccountStatus from 'views/vendors/payment/SetupSteps/StripeAccountStatus'

import { WrappedEditorList, WrappedStoryEditor } from 'components/UserStories'

import DealsComingSoon from 'views/vendors/comingSoon/DealsComingSoon'
import RfpInbox from 'components/RfpInbox'
import WorkflowsComingSoon from 'views/vendors/comingSoon/WorkflowsComingSoon'
import TermScoutPlanDetails from 'views/vendors/termScout/TermScoutPlanDetails'
import TermScoutCheckout from 'views/vendors/termScout/TermScoutCheckout'
import TermScoutSuccess from 'views/vendors/termScout/TermScoutSuccess'
import TestDrive from 'views/vendors/testDrive/TestDrive'
import DiscoverOrganizations from 'components/DiscoverOrganization'
import VendorMarketing from 'views/vendors/payment/VendorMarketing'
import Settings from 'views/setup/settings'
import VendorGettingStarted from 'components/marketing/GettingStarted/VendorGettingStarted'
import VendorNetworks from 'views/vendors/networks/VendorNetworks'
import Products from 'views/vendors/products/Products'
import ProductListing from 'views/listing/ProductListing'
import RootContent from 'components/containers/RootContent'
import TheoremProUpgrade from 'views/TheoremProUpgrade'
import ListingStepper from 'views/listing/ListingStepper'
import CreateProduct from 'views/vendors/products/CreateProduct'
// TODO: setup routes so that it works when user is setup with vendor VS when user isn't

function VendorRoutes() {
  return (
    <Routes>
      {/* pages that don't need vendor ID */}

      <Route path="upgrade" element={<VendorMarketing />} />
      <Route element={<RootContent />}>
        <Route path="/" element={<VendorProfile />} />
        <Route path="getting-started" element={<VendorGettingStarted />} />
        <Route path="profile" element={<VendorProfile />} />
        <Route path="product-wizard" element={<CreateProductWizard />} />
        <Route path="discover-organizations/:orgType" element={<DiscoverOrganizations />} />
        <Route path="discover-organizations" element={<DiscoverOrganizations />} />
        <Route path="test-drive/*" element={<TestDrive />} />
        <Route path="theorem-pro-upgrade" element={<TheoremProUpgrade />} />
        <Route path="upgrade/checkout" element={<VendorCheckout />} />
        <Route path="upgrade/learn" element={<VendorMarketing />} />
        <Route path="upgrade/success" element={<VendorCheckoutSuccess />} />
        <Route path="upgrade/setup/:stepNumber" element={<PaymentSetup />} />
        <Route path="connect/auth-redirect" element={<StripeConnectAuthRedirect />} />
        <Route path="connect/success" element={<StripeConnectSuccess />} />
        <Route path="user-stories" element={<WrappedEditorList />} />
        <Route path="user-stories/:storyId/edit" element={<WrappedStoryEditor />} />
        <Route path="user-stories/create" element={<WrappedStoryEditor />} />
        <Route path="prevalent/plan-details" element={<PrevalentPlanDetails />} />
        <Route path="prevalent/checkout-success" element={<TermScoutSuccess />} />
        <Route path="prevalent/checkout" element={<PrevalentCheckout />} />
        <Route path="term-scout/plan-details" element={<TermScoutPlanDetails />} />
        <Route path="term-scout/checkout-success" element={<TermScoutSuccess />} />
        <Route path="term-scout/checkout" element={<TermScoutCheckout />} />
        <Route path="stripe-account-status" element={<StripeAccountStatus />} />
        <Route path="proceed-confirm" element={<ProceedConfirm />} />
        <Route path="product/settings/:productId/*" element={<ProductSettings />} />
        <Route path=":adminId/product/settings/:productId/*" element={<ProductSettings />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="product/new/*" element={<CreateNewProductWizard />} />
        <Route path="create-product" element={<CreateProduct />} />
        <Route path=":adminId/product/new/*" element={<CreateNewProductWizard />} />
        <Route path="prevalent" element={<PrevalentWizard />} />
        <Route path="prevalent/:step" element={<PrevalentWizard />} />
        <Route path="settings/*" element={<Settings />} />
        <Route path="deals-coming-soon" element={<DealsComingSoon />} />
        <Route path="rfp-inbox" element={<RfpInbox />} />
        <Route path="products" element={<Products />} />
        <Route path="workflows-coming-soon" element={<WorkflowsComingSoon />} />
        <Route path="networks" element={<VendorNetworks />} />
        <Route path="product-listing/*" element={<ProductListing />} />
        <Route path="listing-stepper/*" element={<ListingStepper />} />
      </Route>
    </Routes>
  )
}

/*
      <PrivateRoute
        exact
        wrapInSidebarTemplate
        title="Products"
        path="/"
        component={VendorProfile}
      />

      <PrivateRoute
        exact
        wrapInSidebarTemplate
        title="Getting Started"
        path="getting-started"
        component={VendorGettingStarted}
      />

      <PrivateRoute wrapInSidebarTemplate title="Home" path="profile" component={VendorProfile} />

      <PrivateRoute path="product-wizard" component={CreateProductWizard} />

      <PrivateRoute path="upgrade" exact component={VendorMarketing} />

      <PrivateRoute
        exact
        path="discover-organizations/:orgType"
        wrapInSidebarTemplate
        title="Request access to an organization"
        component={DiscoverOrganizations}
      />

      <PrivateRoute
        exact
        path="discover-organizations"
        wrapInSidebarTemplate
        title="Request access to an organization"
        component={DiscoverOrganizations}
      />

      <PrivateRoute
        title="Test Drive"
        wrapInSidebarTemplate
        path="test-drive"
        component={TestDrive}
      />

      <PrivateRoute path="upgrade/checkout" exact component={VendorCheckout} />

      <PrivateRoute path="upgrade/learn" component={VendorMarketing} />

      <PrivateRoute
        wrapInSidebarTemplate
        path="upgrade/success"
        component={VendorCheckoutSuccess}
      />

      <PrivateRoute
        wrapInSidebarTemplate
        path="upgrade/setup/:stepNumber"
        component={PaymentSetup}
      />

      <PrivateRoute
        wrapInSidebarTemplate
        path="connect/auth-redirect"
        component={StripeConnectAuthRedirect}
      />

      <PrivateRoute wrapInSidebarTemplate path="connect/success" component={StripeConnectSuccess} />

      <PrivateRoute
        title="User Story Editor"
        wrapInSidebarTemplate
        exact
        path="user-stories"
        component={WrappedEditorList}
      />

      <PrivateRoute
        title="User Story Editor"
        wrapInSidebarTemplate
        path="user-stories/:storyId/edit"
        component={WrappedStoryEditor}
      />

      <PrivateRoute
        title="User Story Editor"
        wrapInSidebarTemplate
        exact
        path="user-stories/create"
        component={WrappedStoryEditor}
      />

      <PrivateRoute path="prevalent/plan-details" component={PrevalentPlanDetails} />

      <PrivateRoute path="prevalent/checkout-success" component={TermScoutSuccess} />

      <PrivateRoute path="prevalent/checkout" component={PrevalentCheckout} />

      <PrivateRoute path="term-scout/plan-details" component={TermScoutPlanDetails} />

      <PrivateRoute path="term-scout/checkout-success" component={TermScoutSuccess} />

      <PrivateRoute path="term-scout/checkout" component={TermScoutCheckout} />

      <PrivateRoute
        wrapInSidebarTemplate
        path="stripe-account-status"
        component={StripeAccountStatus}
      />

      <PrivateRoute
        wrapInSidebarTemplate={false}
        path="proceed-confirm"
        component={ProceedConfirm}
      />

      <PrivateRoute
        title="Product Editor"
        wrapInSidebarTemplate
        path="product/settings/:productId"
        component={ProductSettings}
      />

      <PrivateRoute
        title="Product Editor"
        wrapInSidebarTemplate
        path=":adminId/product/settings/:productId"
        component={ProductSettings}
      />

      <PrivateRoute
        title="Analytics"
        wrapInSidebarTemplate
        path="analytics"
        component={Analytics}
      />

      <PrivateRoute
        wrapInSidebarTemplate
        path="product/new"
        title="Add New Product"
        component={CreateNewProductWizard}
      />

      <PrivateRoute
        wrapInSidebarTemplate
        path=":adminId/product/new"
        title="Add New Product"
        component={CreateNewProductWizard}
      />

      <PrivateRoute
        title="Prevalent"
        wrapInSidebarTemplate
        path="prevalent"
        component={PrevalentWizard}
      />

      <PrivateRoute
        title="Prevalent"
        wrapInSidebarTemplate
        path="prevalent/:step"
        component={PrevalentWizard}
      />

      <PrivateRoute title="Settings" wrapInSidebarTemplate path="settings" component={Settings} />

      <PrivateRoute
        title="Coming Soon"
        wrapInSidebarTemplate
        path="deals-coming-soon"
        component={DealsComingSoon}
      />

      <PrivateRoute title="RFP" wrapInSidebarTemplate path="rfp-inbox" component={RfpInbox} />

      <PrivateRoute
        title="Requests For Proposal"
        wrapInSidebarTemplate
        path="products"
        component={Products}
      />

      <PrivateRoute
        title="Coming Soon"
        wrapInSidebarTemplate
        path="workflows-coming-soon"
        component={WorkflowsComingSoon}
      />

      <PrivateRoute
        title="Networks"
        wrapInSidebarTemplate
        path="networks"
        component={VendorNetworks}
      />

      <PrivateRoute
        title="Listings"
        wrapInSidebarTemplate
        path="product-listing"
        component={ProductListing}
      />
 */
export default VendorRoutes
