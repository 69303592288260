import styled from '@emotion/styled'
import Modal from 'components/Modal'
import { Button } from 'components/theme'

type Props = {
  showModal: boolean
  toggleModal: () => void
  onConfirmClick: () => void
}

const DeleteModal = ({ showModal, toggleModal, onConfirmClick }: Props) => {
  return (
    <Modal isShowing={showModal} toggleModal={toggleModal} width="510px">
      <p>Are you sure you want to delete this.</p>
      <ButtonWrapper>
        <CancelButton onClick={toggleModal}>Cancel</CancelButton>
        <ConfirmButton onClick={onConfirmClick}>Confirm</ConfirmButton>
      </ButtonWrapper>
    </Modal>
  )
}

const ButtonWrapper = styled.div``

const CancelButton = styled(Button)`
  margin-right: 16px;
`

const ConfirmButton = styled(Button)``

export default DeleteModal
