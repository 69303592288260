import { useOrg } from 'data/hooks'
import { GqlOrganizationListingQuery } from 'generated/graphql'
import { gql } from 'graphql-request'
import { useGql } from 'hooks/useGql'
import { useQuery } from '@tanstack/react-query'

export const useListingDetails = (associationId?: string) => {
  const { organization } = useOrg()
  const request = useGql()

  return useQuery(
    ['org', 'listing-details', associationId],
    async () => {
      const data = await request<GqlOrganizationListingQuery>(GET_LISTING_DETAILS, {
        organizationId: organization?.id,
        associationId,
      })

      return data
    },
    {
      staleTime: 60 * 60 * 1000,
      enabled: !!associationId,
    }
  )
}

const GET_LISTING_DETAILS = gql`
  query OrganizationListing($organizationId: UUID!, $associationId: UUID!) {
    organizationListing(organizationId: $organizationId, associationId: $associationId) {
      description
      details
      excerpt
    }
  }
`
