import React from 'react'
import styled from '@emotion/styled'
import { ParseError } from '../CsvToTechstack'

const parseErrorMessage = ({ errorType, value, status }: ParseError): JSX.Element | string => {
  if (Array.isArray(value))
    return (
      <Li>
        The column(s) <b>[ {value.join(', ')} ]</b> are in your Csv. They will be ignored. If this
        is a mistake, correct the column names to one of the following:
        <br />
        <ValueList color={errorType === 'error' ? '#f44336' : '#dfaf11'}>
          Product Name, Contract Value, Number of Seats, Renewal Date, Stage, Access,
        </ValueList>
      </Li>
    )
  if (status === 'NO PRODUCTS')
    return (
      <Li>
        No products were found in your csv. Please make sure a column named <b>Product Name</b> is
        present.
      </Li>
    )
  if (!value) return <></>
  switch (status) {
    case 'MISSING PRODUCT':
      return (
        <Li>
          The product <b>{value['Product Name']}</b> was not found. Check it's spelling or message
        </Li>
      )
    case 'BAD MONEY':
      return (
        <Li>
          The contract value provided for <b>{value['Product Name']}</b> was incorrect. Make sure
          only numbers are included.
        </Li>
      )
    case 'BAD ACCESS':
      return (
        <Li>
          <b>{value['Access']}</b> was provided for <b>Access</b> on <b>{value['Product Name']}</b>.
          It will be ignored, or you can correct it to one of the following values:
          <br />
          <ValueList color={errorType === 'error' ? '#f44336' : '#dfaf11'}>
            Active, Investigation, Under Review, Pilot, Contract Review, Practice Rollout, or Region
            Rollout
          </ValueList>
        </Li>
      )
    case 'BAD STAGE':
      return (
        <Li>
          <b>{value['Stage']}</b> was probided for <b>Stage</b> on <b>{value['Product Name']}</b>.
          It will be ignored, or you can correct it to one of the following values:
          <br />
          <ValueList color={errorType === 'error' ? '#f44336' : '#dfaf11'}>
            User, Organization, Review, Authorized or Hide`
          </ValueList>
        </Li>
      )
    case 'BAD DATE':
      return (
        <Li>
          The Contract Renewal date for <b>{value['Product Name']}</b> (
          <i>{value['Renewal Date']}</i>) was incorrect. Please check that it is formatted{' '}
          <b>yyyy-mm-dd</b>
        </Li>
      )
    default:
      return <></>
  }
}

const ErrorList: React.FC<{ errorList: ParseError[] }> = ({ errorList }) => {
  const listItems = errorList.map(({ errorType, ...props }, i) => {
    return (
      <span key={`error-${i}`} style={{ color: errorType === 'error' ? '#f44336' : '#dfaf11' }}>
        {parseErrorMessage({ errorType, ...props })}
      </span>
    )
  })
  return <Ul>{listItems}</Ul>
}

const Li = styled.li`
  margin-bottom: 8px;
`

const Ul = styled.ul`
  padding: 0;
`

const ValueList = styled.div<{ color: string }>`
  border-radius: 5px;
  border: solid 1px ${({ color }) => color};
  padding: 4px 8px;
  margin: 4px 0;
  color: ${({ color }) => color};
  `

  export default ErrorList