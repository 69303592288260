import { Dispatch, SetStateAction } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ParseError } from './CsvToTechstack'

type Modals = 'ERROR_LIST' | 'HOW_TO'

type Props = {
  removeFile: () => void
  activeFile: boolean
  errorList?: ParseError[]
  setModal: Dispatch<SetStateAction<Modals | null>>
}

const CsvMenu: React.FC<Props> = ({ removeFile, activeFile, errorList = [], setModal }) => {
  return (
    <Menu>
      <Label htmlFor="file-upload">Upload CSV</Label>
      {activeFile && <Button onClick={removeFile}>Remove CSV</Button>}
      <Button>Download Sample CSV</Button>
      <Button onClick={() => setModal('HOW_TO')}>How to Upload Csv</Button>
      {errorList.length > 0 && (
        <Button style={{ color: '#ff1744' }} onClick={() => setModal('ERROR_LIST')}>
          {errorList.length} Problem{errorList.length > 1 && 's'} Uploading
        </Button>
      )}
    </Menu>
  )
}

const Menu = styled.div``

const MenuItem = css`
  display: inline-block;
  cursor: pointer;
  color: #333;
  background: none;
  letter-spacing: 0.02rem;
  border: 0;
  text-align: center;
  white-space: nowrap;
  padding: 8px 16px;
  margin: 0 !important;
  width: 100%;
  height: min-content;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  &:hover {
    color: #498de6;
  }
  border-bottom: 1px solid #ccc;
`

const Label = styled.label`
  ${MenuItem}
`

const Button = styled.button`
  ${MenuItem}

  &:last-of-type {
    border-bottom: none;
  }
`

export default CsvMenu
