function interpolateColor(color1: any, color2: any, factor: any) {
  if (arguments.length < 3) {
    factor = 0.5
  }
  const result = color1.slice()
  for (let i = 0; i < 3; i += 1) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]))
  }
  return result
}

// My function to interpolate between two colors completely, returning an array
export const interpolateColors = (color1: any, color2: any, steps: any) => {
  const stepFactor = 1 / (steps - 1)
  const interpolatedColorArray = []

  color1 = color1.match(/\d+/g).map(Number)
  color2 = color2.match(/\d+/g).map(Number)

  for (let i = 0; i < steps; i += 1) {
    interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i))
  }

  return interpolatedColorArray
}

export const rgbToHex = (r: any, g: any, b: any) => {
  r = r.toString(16)
  g = g.toString(16)
  b = b.toString(16)

  if (r.length === 1) r = `0${r}`
  if (g.length === 1) g = `0${g}`
  if (b.length === 1) b = `0${b}`

  return `#${r}${g}${b}`
}
