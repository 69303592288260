import React from 'react'
import styled from '@emotion/styled'
import clocBlueSvg from 'assets/networks/acc/acc-square.svg'
import clocGoldSvg from 'assets/networks/acc/acc-gold.svg'
import { Link, useLocation } from 'react-router-dom'
import { useBaseOrgUrl } from 'hooks/useBaseOrgUrl'
import { useViewer } from 'context/viewerContext'
import { useProductListingCheckout } from '../useProductListingCheckout'

// const networkPriceIds = [
//   {
//     network: 'cloc',
//     priceIds: [
//       {
//         name: 'essential',
//         production: 'price_1KeQl2CldCjodC13wMzwDACb',
//         development: 'price_1KjrmCHIw3FF5Jrc2CWeynkm',
//       },
//       {
//         name: 'premium',
//         production: 'price_1KeQmDCldCjodC13DePzyHad',
//         development: 'price_1KjrpbHIw3FF5JrcDx0lnY5j',
//       },
//       {
//         name: 'enterprise',
//         production: 'price_1L5YqMCldCjodC13FXwF40GA',
//         development: 'price_1L5YFiHIw3FF5JrcjqGZpMPr',
//       },
//     ],
//   },
// ]

const isLocalDev = process.env.NODE_ENV === 'development'

const BASIC_PRICE_ID = !isLocalDev
  ? 'price_1LpwfJEPUy7BhonOakNgOEBG' //prod
  : 'price_1MRhH0EPUy7BhonOcAJw1pYJ' //test

const PREMIUM_PRICE_ID = !isLocalDev
  ? 'price_1LpwfFEPUy7BhonONDmg3u1u' //prod
  : 'price_1MRhIPEPUy7BhonOvIyiptff' //dev

// const ASSOCIATION_ID = 'd3691636-3f0b-4ac4-9c2a-8bc61dee5ed5'
// const ACCOUNT_ID = isUsingProductionServer ? 'acct_1LmhKjEPUy7BhonO' : 'acct_1LmhKjEPUy7BhonO' // TheoremLTS test account

const AccPlans = () => {
  const baseOrgUrl = useBaseOrgUrl()
  const productListingCheckout = useProductListingCheckout()
  const { paymentStatus } = useViewer()
  const location = useLocation()

  const locationState = location.state as any

  const productId = locationState.productId

  const isActiveTheorem = paymentStatus === 'ACTIVE'

  const onBoxClick = (priceId: string) => async () => {
    const listingType = priceId === BASIC_PRICE_ID ? 'essential' : 'premium'

    await productListingCheckout({
      priceId,
      productId,
      listingType,
    })
  }

  return (
    <Root>
      <Container>
        <MainTitle>Select a Listing Plan</MainTitle>
        {isActiveTheorem ? (
          <ActiveTheoremDiscount>
            Congrats! You still Save 15% with your PRO membership.
          </ActiveTheoremDiscount>
        ) : (
          <TheoremProDiscount>
            Or, <Link to={`${baseOrgUrl}/upgrade`}>Save 15% with Theorem PRO</Link>
          </TheoremProDiscount>
        )}
        <Boxes>
          <Column>
            <Box onClick={onBoxClick(BASIC_PRICE_ID)}>
              <Logo src={clocBlueSvg} />
              <Title>Essentials</Title>
              <List>
                <Item>Single Basic Listing</Item>
                <Item>Listing Management Portal</Item>
                <Item>Marketplace Insights Newsletter</Item>
              </List>
              <p>i.e. “Product X Is now available through the ACC Network”</p>
              {isActiveTheorem ? (
                <>
                  <PriceWrapper>
                    <DiscountPrice>$850</DiscountPrice>
                    <OldPrice>$1,000</OldPrice>
                  </PriceWrapper>
                  <Discount>15% Off Applied!</Discount>
                </>
              ) : (
                <PriceWrapper>
                  <Price>$1,000</Price>
                </PriceWrapper>
              )}
            </Box>
          </Column>
          <Column>
            <Box onClick={onBoxClick(PREMIUM_PRICE_ID)}>
              <Logo src={clocGoldSvg} />
              <Title>Premium</Title>
              <List>
                <Item>Everything in Essential, Plus:</Item>
                <Item>Premium ACC Badge</Item>
                <Item>Enhanced Search Placement</Item>
                <Item>RFP Participation</Item>
                <Item>Create Offers &amp; Test</Item>
                <Item>Featured promotion in Theorem Outreach</Item>
              </List>
              {isActiveTheorem ? (
                <>
                  <PriceWrapper>
                    <DiscountPrice>$3,060</DiscountPrice>
                    <OldPrice>$3,600</OldPrice>
                  </PriceWrapper>
                  <Discount>15% Off Applied!</Discount>
                </>
              ) : (
                <PriceWrapper>
                  <Price>$3,600</Price>
                </PriceWrapper>
              )}
            </Box>
          </Column>
        </Boxes>
      </Container>
    </Root>
  )
}

const Root = styled.div``

const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
`

const TheoremProDiscount = styled.p`
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 16px;
  color: #43ba1a;
  margin-top: 8px;

  a {
    color: #43ba1a;
    text-decoration: underline;

    &:hover {
      color: #333;
    }
  }
`

const ActiveTheoremDiscount = styled.div`
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 8px;
  font-size: 16px;
  color: #43ba1a;
`

const MainTitle = styled.h2`
  text-align: center;
  margin-bottom: 0px;
`

const Boxes = styled.div`
  display: flex;
  margin-left: -16px;
  margin-right: -16px;

  ${({ theme }) => theme.breakpoint.mobile} {
    display: block;
  }
`

const Column = styled.div`
  display: flex;
  padding: 0 16px;
  width: 50%;

  ${({ theme }) => theme.breakpoint.mobile} {
    width: 100%;
    margin: 0 auto 24px;
    max-width: 300px;
  }
`

const Logo = styled.img`
  max-width: 80px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 20px;
  cursor: pointer;
  border: transparent 2px solid;
  transition: border 250ms;

  &:hover {
    border: #4cd0d1 2px solid;
  }
`

const Title = styled.h4`
  font-size: 20px;
  margin-top: 16px;
`

const List = styled.ul`
  padding-left: 16px;
  margin-bottom: 20px;
`

const Item = styled.li`
  font-size: 14px;
  margin-bottom: 4px;
  letter-spacing: 0.02rem;
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-bottom: 4px;
`

const Price = styled.div`
  font-size: 20px;
  font-weight: 600;
`

const DiscountPrice = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-right: 12px;
`

const OldPrice = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-decoration: line-through;
  color: grey;
`

const Discount = styled.div`
  color: #43ba1a;
  font-weight: 500;
`

export default AccPlans
