import { useEffect } from 'react'
import { useTrackingChartContext } from 'context/trackingChartContext'
import { useChartJs } from 'hooks/useChartJs'
import ChartCard from 'components/charts/ChartCard'
import { useLazyApi } from 'hooks/useApi'

function DmaChart() {
  const {
    startDate,
    endDate,
    currentLoadingQueue,
    setCurrentLoadingQueue,
    productToAnalyze: product,
  } = useTrackingChartContext()
  const { ctx, setChartData } = useChartJs({ type: 'doughnut', options: {} })
  const [getPageHitByDma] = useLazyApi('tracking/amplitude/product-page-hit-dma')

  useEffect(() => {
    const init = async () => {
      const result = await getPageHitByDma({ startDate, endDate, id: product })

      const totalCount = result.series
        ? result.series.map((val: number[]) => val.reduce((a: number, b: number) => a + b, 0))
        : []

      const datasets = [
        {
          label: 'Platform Type',
          data: totalCount,
          borderWidth: 1,
          backgroundColor: ['#3ACFB2', '#122AC0', '#6C09BD', '#00B793', '#5062D5', '#9446D3'],
        },
      ]
      const labels = result.seriesLabels ? result.seriesLabels.map((val: number[]) => val[1]) : []
      setChartData({ datasets, labels })
    }

    if (currentLoadingQueue === 2) {
      init()
      setCurrentLoadingQueue((prevVal: number) => prevVal + 1)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentLoadingQueue])

  return <ChartCard title="User DMA Location" ctx={ctx} />
}

export default DmaChart
